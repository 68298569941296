import React, { useEffect, useState } from "react";
import ApiCalling from "../../../network/ApiCalling";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Util from "../../../Util";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ChildContact(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const user = Util.getUser();
  // console.log("props::", props);
  const studentDetail = props.props.childInfo;
  const [parentData, setParentData] = useState([]);

  const parentList = async () => {
    ApiCalling.apiCallParamsGet(`parent/${studentDetail?.Student?.id}`).then(
      (res) => {
        setParentData(res.data);
      }
    );
  };
  useEffect(() => {
    if (!studentDetail) {
      history.push("/myChild");
    }
  }, [studentDetail]);
  useEffect(() => {
    parentList();
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="table-responsive">
          <table className="table table-bordered table-striped ">
            {parentData.length > 0 ? (
              <>
                <thead>
                  <tr>
                    <th>#</th>
                    <th> {t("Name")} </th>
                    <th> {t("Email")} </th>
                    <th> {t("Phone")} </th>
                    <th> {t("Invitation status")} </th>
                    <th> {t("Relation")} </th>
                  </tr>
                </thead>
                <tbody>
                {parentData.map((value, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <th>
                          <a>
                            {value.User.email == user.email
                              ? "self"
                              : value.User.firstName +
                                " " +
                                value.User.lastName}
                          </a>
                        </th>
                        <td> {value.User.email} </td>
                        <td> {value.User.mobile} </td>
                        <td>
                          {value.User.email_verified == true ? (
                            t("Registered")
                          ) : (
                            <span className="text-danger">{t("Not registered yet")}</span>
                          )}
                        </td>
                        <td> {value.StudentParentRelationType.type} </td>
                      </tr>
                    </>
                  );
                })}
                </tbody>
              </>
            ) : (
              ""
            )}
          </table>
        </div>
      </div>
    </div>
  );
}
