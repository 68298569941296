import React from "react";
import { withTranslation } from "react-i18next";
import ToastHOC from "../../../HOC/ToastHOC";
import ParentProfile from "./ParentProfile";
import ParentWork from "./ParentWork";
import ApiCalling from "../../../../network/ApiCalling";
import Util from "../../../../Util";
import GeneralDetail from "./GeneralDetail";

class EditContactTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getUserDetails: null,
    };
  }
  getUserData = async () => {
    ApiCalling.apiCallParamsGet(
      "parent/by-parentid/" + this.props.location.ParentId
    ).then((res) => {
      this.setState({ getUserDetails: res.data });
    });
  };
  componentDidMount() {
    const { setIsToast } = this.props;
    const pendingToast = this.props.location?.pendingToast;
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
    if (Util.isValidData(this.props.location.ParentId)) {
      this.getUserData();
    }else{
      this.props.history.push('/student')
    }
  
  }
  onTabChange = (e) => {
    var tab1 = document.getElementById("bacicTab-1");
    tab1.classList.remove("active");
    tab1.children[0].classList.remove("active");
    var tab2 = document.getElementById("bacicTab-2");
    tab2.classList.remove("active");
    tab2.children[0].classList.remove("active");
    var tab3 = document.getElementById("bacicTab-3");
    tab3.classList.remove("active");
    tab3.children[0].classList.remove("active");

    var actab = document.getElementById("bacicTab-" + e);
    actab.classList.remove("active");
    actab.children[0].classList.add("active");

    var tabpan1 = document.getElementById("bacicTabpan-1");
    tabpan1.classList.remove("active");
    var tabpan2 = document.getElementById("bacicTabpan-2");
    tabpan2.classList.remove("active");
    var tabpan3 = document.getElementById("bacicTabpan-3");
    tabpan3.classList.remove("active");

    var actabpab = document.getElementById("bacicTabpan-" + e);
    actabpab.classList.add("active");
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="container-fluid">
          <div className="d-flex align-items-center gap-2 mb-3">
            <a href="/Student">
              <i className="fa fa-chevron-left" aria-hidden="true"></i>
            </a>
            <a href="/Student">{t("Student")} ({this.props.location.studentName})  </a>
          </div>
          <div className="body">
            <ul className="nav nav-tabs" role="tablist">
              <li
                className="nav-item active"
                id="bacicTab-1"
                role="presentation"
                onClick={() => {
                  this.onTabChange(1);
                }}
              >
                <a className="nav-link active">{t("Relation with")} {this.props.location.studentName}</a>
              </li>

              <li
                className="nav-item mr-1"
                id="bacicTab-2"
                role="presentation"
                onClick={() => {
                  this.onTabChange(2);
                }}
              >
                <a className="nav-link" data-toggle="tab">
                {t("Profile")}
                </a>
              </li>
              <li
                className="nav-item mr-1"
                id="bacicTab-3"
                role="presentation"
                onClick={() => {
                  this.onTabChange(3);
                }}
              >
                <a className="nav-link" data-toggle="tab">
                  {t("Work Info")}
                </a>
              </li>
            </ul>

            <div className="tab-content card">
              <div id="bacicTabpan-1" className="tab-pane active">
                <GeneralDetail
                  props={this.props}
                  ParentDetails={this.state.getUserDetails}
                />
              </div>

              <div id="bacicTabpan-2" className="tab-pane">
              <ParentProfile
                  props={this.props}
                  ParentDetails={this.state.getUserDetails}
                />
              </div>
              <div id="bacicTabpan-3" className="tab-pane">
                <ParentWork props={this.props} 
                ParentDetails={this.state.getUserDetails}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ToastHOC(withTranslation()(EditContactTab));
