const BASE_URL = process.env.REACT_APP_BASE_URL;
export const imageSchoolDetails = BASE_URL + "/logo/";
export const imageMyProfile = BASE_URL + "/avatar/";
export const imageAddSchool = BASE_URL + "logo/";
export const imageSwitchSchool = BASE_URL + "/logo/";
export const imageActivity = BASE_URL + "/photo/";
export const imageStudentList = BASE_URL + "photo/";
export const studentInsurance = BASE_URL + "insurance/";
export const studentIdentity = BASE_URL + "identity/";
export const imageUserFeed = BASE_URL + "photo/";
export const imagePost = BASE_URL + "activity/";
export const imageComment = BASE_URL + "activity_comment/";
export const documentPost = BASE_URL + "activity/";
export const chatAttachment = BASE_URL + "attachment/";
export const userIdentity = BASE_URL + "user_identity/";
export const invoiceDownload = BASE_URL + "invoices/";
