import React, { useEffect, useState } from "react";
import Util from "../../../Util";
import { t } from "i18next";
import Loader from "../../../components/common/Loader";
import { Modal, Button } from "react-bootstrap";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { IconButton } from "@material-ui/core";
import { Sort } from "@material-ui/icons";
import ApiCalling from "../../../network/ApiCalling";
import ModalComponent from "../ModalComponent";
import ToastHOC from "../../HOC/ToastHOC";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

const Contactway = (props) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");

  const [contactWayList, setContactWayList] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { setIsToast } = props;
  const [editValue, setEditValue] = useState(null);
  const [typeError, setTypeError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function handleOpenModal() {
    setShow(true);
  }

  function handleCloseModal() {
    setShow(false);
    setTitle("");
    // setAcademicYearId(null);
    // setEditValue(null);
  }

  const handleSaveAndClose = async () => {
    if (!title) return;
    // if (editValue) {
    //   await handleUpdateLead();
    // } else {
    //   await handleAddLeads();
    // }
    handleCreatContactway();
  };

  const handleCreatContactway = async () => {
    const data = {
      title: title,
    };
    try {
      ApiCalling.apiCallBodyDataPost("lead-contact-way/create/", data).then(
        (res) => {
          if (res.data.status === true) {
            setIsLoading(false);
            setIsToast(res.data.message);
            setShow(false);
            getContactwayList();
          } else {
            alert(res.data.message);
          }
        }
      );
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const handleUpdateLead = async () => {
    const data = {
      id: editValue.id,
      title: title,
    };
    try {
      ApiCalling.apiCallBodyDataPost("lead-program/update/", data).then(
        (res) => {
          console.log("lead-program", res);
        }
      );
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const handleDelete = () => {
    ApiCalling.apiCallDelete(`lead-program/delete/${deleteId}`)
      .then((res) => {
        console.log("DELETED", res);
        if (res.data.status == true) {
          setIsToast(res.data.message);
        } else if (res.data.status == false) {
          setIsToast(t("Somewhere used ,You Can't Delete"));
        }
        setShowDeleteConfirmation(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEdit = (lead) => {
    setEditValue(lead);
    setTitle(lead.title);
    // setAcademicYearId(lead.academic_year_id);
    setShow(true);
  };

  const getContactwayList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("lead-contact-way/list/")
      .then((res) => {
        setContactWayList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("ERROR", err);
      });
  };

  useEffect(() => {
    getContactwayList();
  }, [show, deleteId]);

  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="header text-right mb-3 px-0">
              <button
                className={`${
                  Util.hasPermission("MASTERS_ADD")
                    ? "btn custom-primary"
                    : "no-permission"
                }`}
                type="button"
                aria-expanded="false"
                style={{ color: "#fff", padding: "5px, 10px" }}
                onClick={() => setShow(true)}
              >
                {t("Add New Admission ")}
              </button>
              <Modal
                show={show}
                onHide={handleCloseModal}
                animation={true}
                centered={true}
                dialogClassName="modal-view"
                onShow={handleOpenModal}
              >
                <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                  <h6>
                    {editValue ? t("Edit Admission") : t("Add New Admission")}
                  </h6>
                  <IconButton
                    aria-label="close"
                    size="small"
                    onClick={handleCloseModal}
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </div>
                <Modal.Body>
                  <input
                    className={`form-control mb-2 ${
                      typeError.length > 0 ? "input-error" : "custom-input"
                    }`}
                    placeholder={t("Title")}
                    type="text"
                    value={title}
                    onChange={(event) => {
                      setTitle(event.target.value);
                      setTypeError("");
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btn custom-primary"
                    onClick={handleSaveAndClose}
                  >
                    {t("Save")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="row clearfix">
            <div className="table-div overflow-hidden m-0 p-0">
              <div className="col-lg-12 col-md-12 p-0">
                <div className="body">
                  {!isLoading ? (
                    <>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">
                              {t("Title")}{" "}
                              <IconButton>
                                <UnfoldMoreIcon size="small" />
                              </IconButton>
                            </th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        {contactWayList.length > 0 ? (
                          <tbody>
                            {contactWayList.map((lead, index) => (
                              <tr key={lead.id}>
                                <td>{index + 1}</td>
                                <td>{lead.title}</td>
                                <td>
                                  <a
                                  // onClick={(e) => {
                                  //   e.preventDefault();
                                  //   handleEdit(lead);
                                  // }}
                                  // className={`${
                                  //   !Util.hasPermission("MASTERS_EDIT") &&
                                  //   "no-permission"
                                  // }`}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </a>
                                </td>
                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowDeleteConfirmation(true);
                                      setDeleteId(lead.id);
                                    }}
                                    className={`${
                                      Util.hasPermission("MASTERS_DELETE")
                                        ? "pl-5"
                                        : "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-trash-o text-danger"></i>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tfoot>
                            <tr>
                              <td colSpan={4}>
                                {t(
                                  "There are no AnnouncementType. Click the 'Add Announcement Type' button to create one."
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                      {contactWayList.length > 0 && (
                        <>
                          <div className="small text-muted mb-3 pl-3 border-top py-3">
                            <i>
                              {contactWayList.length} {t("Admission found")}.
                            </i>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
                <ModalComponent
                  show={showDeleteConfirmation}
                  onHide={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  title={t("Delete")}
                  onCancel={t("Cancel")}
                  onConfirm={t("Confirm")}
                  subTitle={t("Are you sure, to delete this Lead?")}
                  size="sm"
                  onCancelClick={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  onConfirmClick={handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(Contactway);
