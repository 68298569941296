import React, { useEffect, forwardRef, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useTranslation } from "react-i18next";
import Util from "../../Util";
import DatePicker from "react-datepicker";
import Calender from "../../assets/Icon/calendar.svg";
import ApiCalling from "../../network/ApiCalling";
import { setHours, setMinutes } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import clock from "../../assets/Icon/clock.svg";
import moment from "moment/moment";
import Invalid from "../../assets/Icon/invalid.svg";
import ExitAlert from "../../components/ExitAlert";
import Select from "react-dropdown-select";
import { Modal } from "react-bootstrap";

export default function AddEditEvent(props) {
  const show = props.show;
  const onClose = props.onClose;
  
  const onSave = props.onSave;
  const onEdit = props.onEdit;
  const datepickerRef = useRef(null);
  const { t } = useTranslation();
  const getLanguage = Util.getSelectedLanguage();
  const school = Util.getSelectedSchool();
  const [isEditEvent, setIsEditEvent] = useState(null);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const [eventDateError, setEventDateError] = useState("");
  const [gradeList, setGradeList] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [classList, setClassList] = useState([]);
  const [classId, setClassId] = useState(0);
  const [eventTypeList, setEventTypeList] = useState([]);
  const [eventTypeId, setEventTypeId] = useState(0);
  const [eventTypeIdError, setEventTypeIdError] = useState("");
  const [isFullDay, setIsFullDay] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [startTimeError, setStartTimeError] = useState("");
  const [endTime, setEndTime] = useState(null);
  const [endTimeError, setEndTimeError] = useState("");
  const [isRepeat, setIsRepeat] = useState(false);
  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);
  const [repeatedDaysError, setRepeatedDaysError] = useState(false);
  const [repeatEndDate, setRepeatEndDate] = useState(null);
  const [repeatEndDateError, setRepeatEndDateError] = useState("");
  const [description, setDescription] = useState("");
  const [alertModal, setIsAlertModal] = useState(false);
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);

  const handleTitle = (e) => {
    setTitle(e.target.value);
    setTitleError("");
    setIsStateUpdated(true);
  };
  const handleEventDate = (date) => {
    setEventDate(date);
    setEventDateError("");
    setIsStateUpdated(true);
  };
  const handleGradeId = (e) => {
    setGradeId(e.target.value);

    setIsStateUpdated(true);
  };
  const handleClassId = (e) => {
    setClassId(e.target.value);
    setIsStateUpdated(true);
  };
  const handleEventTypeId = (e) => {
    setEventTypeId(e.target.value);
    setEventTypeIdError("");
    setIsStateUpdated(true);
  };
  const handleFullDay = (e) => {
    setIsFullDay(e.target.checked);
    setIsStateUpdated(true);
  };
  const handleRepeat = (e) => {
    setIsRepeat(e.target.checked);
    setIsStateUpdated(true);
  };
  const handleStartTime = (time) => {
    setStartTime(time);
    setStartTimeError("");
    setIsStateUpdated(true);
  };
  const handleEndTime = (time) => {
    setEndTime(time);
    setEndTimeError("");
  };
  const handleRepeatEndDate = (date) => {
    setRepeatEndDate(date);
    setRepeatEndDateError("");
    setIsStateUpdated(true);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
    setIsStateUpdated(true);
  };
  const toggleDatePicker = () => {
    datepickerRef.current.setOpen(true);
  };
  const reset = () => {
    setTitle("");
    setEventDate(null);
    setClassId(0);
    setGradeId(0);
    setEventTypeId(0);
    setIsFullDay(true);
    setStartTime(null);
    setEndTime(null);
    setIsRepeat(false);
    setMonday(false);
    setTuesday(false);
    setWednesday(false);
    setThursday(false);
    setFriday(false);
    setSaturday(false);
    setSunday(false);
    setRepeatEndDate(null);
    setDescription("");
    setTitleError("");
    setEventDateError("");
    setEventTypeIdError("");
    setStartTimeError("");
    setEndTimeError("");
    setRepeatEndDateError("");
    setRepeatedDaysError("");
  };

  const handleSave = (e) => {
    setIsStateUpdated(false);
    e.preventDefault();
    let error = false;
    setTitleError("");
    setEventDateError("");
    setEventTypeIdError("");
    setStartTimeError("");
    setEndTimeError("");
    setRepeatEndDateError("");
    if (title == "") {
      setTitleError(t("Title is required"));
      error = true;
    }
    if (eventDate == null) {
      setEventDateError(t("Date is required"));
      error = true;
    }
    if (eventTypeId == 0) {
      setEventTypeIdError(t("Event Type is required"));
      error = true;
    }
    if (!isFullDay && startTime == null) {
      setStartTimeError(t("Start Time is required"));
      error = true;
    }
    if (!isFullDay && endTime == null) {
      setEndTimeError(t("End Time is required"));
      error = true;
    }
    if (isRepeat && repeatEndDate == null) {
      setRepeatEndDateError(t("End Date is required"));
      error = true;
    }
    if (
      isRepeat &&
      !(
        monday ||
        tuesday ||
        wednesday ||
        thursday ||
        friday ||
        saturday ||
        sunday
      )
    ) {
      setRepeatedDaysError(t("Repeated Day is required"));
      error = true;
    }
    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      if (isEditEvent !== null) {
        const EditData = {
          id: isEditEvent?.id,
          grade_id: parseInt(gradeId),
          classroom_id: classId,
          date: moment(eventDate).format("YYYY/MM/DD"),
          title: title,
          description: description,
          event_type_id: eventTypeId,
          isfullday: isFullDay,
          start_time: isFullDay ? null : moment(startTime).format("HH:mm"),
          end_time: isFullDay ? null : moment(endTime).format("HH:mm"),
          repeat: isRepeat,
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday,
          saturday: saturday,
          sunday: sunday,
          repeat_ends_on: repeatEndDate,
        };
        ApiCalling.apiCallBodyDataPost("event/update", EditData)
          .then((res) => {
            if (res.data.status == true) {
              setIsClickedOnce(false);
              onEdit(res.data.message);
              reset();
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      } else {
        const data = {
          grade_id: parseInt(gradeId),
          classroom_id: classId,
          date: moment(eventDate).format("YYYY/MM/DD"),
          title: title,
          description: description,
          event_type_id: eventTypeId,
          isfullday: isFullDay,
          start_time: isFullDay ? null : moment(startTime).format("HH:mm"),
          end_time: isFullDay ? null : moment(endTime).format("HH:mm"),
          repeat: isRepeat,
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday,
          saturday: saturday,
          sunday: sunday,
          repeat_ends_on: repeatEndDate,
        };

        ApiCalling.apiCallBodyDataPost("event/add", data)
          .then((res) => {
            // console.log("save event", res.data);
            if (res.data.status == true) {
              setIsClickedOnce(false);
              onSave(res.data.message);
              reset();
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
    }
  };

  const CustomInput = React.forwardRef(({ value, onClick, icon },ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
      ref={ref}
        className={`custom-input__field ${
          eventDateError.length > 0 && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  ));
  const CustomStartTimeInput = ({ value, onClick, icon }) => (
    <div className="d-flex" onClick={onClick}>
      <input
        className={`custom-input__field ${
          startTimeError.length > 0 && "datePicker-error"
        }`}
        placeholder={`${t("Select start time*")}`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  );
  const CustomEndTimeInput = ({ value, onClick, icon }) => (
    <div className="d-flex" onClick={onClick}>
      <input
        className={`custom-input__field ${
          endTimeError.length > 0 && "datePicker-error"
        }`}
        placeholder={`${t("Select End time*")}`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  );
  const CustomEndsOnInput = ({ value, onClick, icon }) => (
    <div className="d-flex" onClick={onClick}>
      <input
        className={`custom-input__field ${
          repeatEndDateError.length > 0 && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  );
  const getGradeList = async () => {
    ApiCalling.apiCallBodyDataGet("grade/list")
      .then((res) => {
        setGradeList(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getClassList = async () => {
    ApiCalling.apiCallBodyDataGet("classroom/list")
      .then((res) => {
        const modifiedClassList = res.data.map((grade) => ({
          id: grade.id,
          name: `${grade.Grade.title}-${grade.name}`,
        }));
        setClassList(modifiedClassList);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getEventTypeList = async () => {
    ApiCalling.apiCallBodyDataGet("event/types")
      .then((res) => {
        setEventTypeList(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getEditData = async () => {
    setTitle(Util.isValidData(isEditEvent?.title) ? isEditEvent?.title : "");
    setEventDate(
      Util.isValidData(isEditEvent?.date) ? new Date(isEditEvent?.date) : null
    );
    setClassId(
      Util.isValidData(isEditEvent?.classroom_id)
        ? isEditEvent?.classroom_id
        : 0
    );
    setGradeId(
      Util.isValidData(isEditEvent?.grade_id) ? isEditEvent?.grade_id : 0
    );
    setEventTypeId(
      Util.isValidData(isEditEvent?.event_type_id)
        ? isEditEvent?.event_type_id
        : 0
    );
    setIsFullDay(
      Util.isValidData(isEditEvent?.isfullday) ? isEditEvent?.isfullday : false
    );
    setStartTime(
      Util.isValidData(isEditEvent?.start_time)
        ? new Date().setHours(
            isEditEvent.start_time.split(":")[0],
            isEditEvent.start_time.split(":")[1],
            0
          )
        : null
    );
    setEndTime(
      Util.isValidData(isEditEvent?.end_time)
        ? new Date().setHours(
            isEditEvent.end_time.split(":")[0],
            isEditEvent.end_time.split(":")[1],
            0
          )
        : null
    );
    setIsRepeat(
      Util.isValidData(isEditEvent?.repeat) ? isEditEvent?.repeat : false
    );
    setMonday(isEditEvent?.monday);
    setTuesday(isEditEvent?.tuesday);
    setWednesday(isEditEvent?.wednesday);
    setThursday(isEditEvent?.thursday);
    setFriday(isEditEvent?.friday);
    setSaturday(isEditEvent?.saturday);
    setSunday(isEditEvent?.sunday);
    setRepeatEndDate(
      Util.isValidData(isEditEvent?.repeat_ends_on)
        ? new Date(isEditEvent?.repeat_ends_on)
        : null
    );
    setDescription(
      Util.isValidData(isEditEvent?.description) ? isEditEvent?.description : ""
    );
  };

  useEffect(() => {
    getGradeList();
    getClassList();
    getEventTypeList();
  }, []);

  useEffect(() => {
    if (isEditEvent !== null) {
      getEditData();
    } else {
      reset();
    }
  }, [isEditEvent]);
  useEffect(() => {
    setIsEditEvent(props.isEditEvent);
  }, [props]);

  return (
    <>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left":"right"}
        open={show}
        onClose={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            onClose();
            setIsEditEvent(null);
            reset();
            setIsStateUpdated(false);
          }
        }}
        onOpen={()=>{}}
      >
        <div
          className="box-area"
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            padding: "0px 0px 50px 0px",
          }}
        >
          <Box
            sx={{ width: "656px", padding: "20px", position: "relative" }}
            role="presentation"
          >
            <div className="d-flex align-items-center justify-content-between">
            <div className="drawer-header">
              {isEditEvent == null ? t("Add New event") : t("Edit Event")}{" "}
            </div>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => {
                if (isStateUpdated) {
                  setIsAlertModal(true);
                } else {
                  onClose();
                  setIsEditEvent(null);
                  reset();
                  setIsStateUpdated(false);
                }
              }}
            >
              <CloseIcon />
            </IconButton>

            
            </div>
            <div className="body mt-3">
              <div className="drawer-sub-header">{t("Event Details")}</div>

              <div className="row text-start mt-2">
                <div className="input-filed ">
                  <span className="basic-text  required">{t("Title")}</span>
                  <input
                    type="text"
                    className={`form-control ${
                      titleError.length > 0 ? "input-error" : "custom-input"
                    }`}
                    // placeholder="First Name"
                    onChange={handleTitle}
                    value={title}
                  />
                  {titleError.length > 0 && (
                    <span className="text-danger invalid-error">
                      <img
                        className="fa fa-exclamation-circle mr-2 invalid-svg"
                        src={Invalid}
                      />
                      {titleError}
                    </span>
                  )}
                </div>
                <div className="input-filed">
                  <span className="basic-text required">{t("Date")}</span>

                  <DatePicker
                    wrapperClassName=""
                    placeholderText="Select Date"
                    selected={eventDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={handleEventDate}
                    dateFormat={school.date_format}
                    // className="datePicker"

                    popperPlacement="bottom"
                    // maxDate={new Date()}
                    customInput={<CustomInput icon={Calender} />}
                  />

                  {eventDateError.length > 0 && (
                    <span className="text-danger invalid-error">
                      <img
                        className="fa fa-exclamation-circle mr-2 invalid-svg"
                        src={Invalid}
                      />
                      {eventDateError}
                    </span>
                  )}
                </div>

                <div className={`fancy-checkbox m-0 ${isFullDay && "mb-3"}`}>
                  <label className="basic-text">
                    <input
                      name="fullDayEvent"
                      type="checkbox"
                      checked={isFullDay}
                      onChange={handleFullDay}
                    />
                    <span tabIndex={0}>
                      <i> </i>
                      {t("FullDay Event")}
                    </span>
                  </label>
                </div>
                {!isFullDay && (
                  <>
                    <div className="col-lg-6">
                      <div className="input-filed">
                        <div className="time-picker">
                          <DatePicker
                            selected={startTime}
                            onChange={handleStartTime}
                            dateFormat={school.time_format}
                            popperPlacement="bottom"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            customInput={<CustomStartTimeInput icon={clock} />}
                            placeholderText={`${t("Select start time")}*`}
                          />
                        </div>

                        {startTimeError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {startTimeError}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed">
                        <div className="time-picker">
                          <DatePicker
                            selected={endTime}
                            onChange={handleEndTime}
                            dateFormat={school.time_format}
                            popperPlacement="bottom"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            placeholderText={`${t("Select end time")}*`}
                            minTime={startTime}
                            maxTime={setHours(setMinutes(new Date(), 0), 23)}
                            disabled={!startTime}
                            customInput={<CustomEndTimeInput icon={clock} />}
                          />
                        </div>
                        {endTimeError.length > 0 && (
                          <div className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {endTimeError}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className="input-filed ">
                  <span className="basic-text ">{t("Grade")} ({t("optional")})</span>
                  <select
                    type="text"
                    className="form-select select-dropdown custom-input"
                    onChange={handleGradeId}
                    value={gradeId}
                  >
                    <option value={0}>{t("Select Grade")}</option>
                    {gradeList.map((g) => {
                      return (
                        <option value={g.id} key={g.id}>
                          {g.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input-filed ">
                  <span className="basic-text ">
                    {t("Class Room")} ({t("optional")})
                  </span>
                  <select
                    type="text"
                    className=" form-select select-dropdown custom-input"
                    onChange={handleClassId}
                    value={classId}
                  >
                    <option value={0}>{t("Select ClassRoom")}</option>
                    {classList.map((c) => {
                      return (
                        <option value={c.id} key={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input-filed ">
                  <span className="basic-text  required">
                    {t("Event Type")}
                  </span>
                  <select
                    type="text"
                    className={`select-dropdown form-select custom-input ${
                      eventTypeIdError.length > 0 ? "input-error" : ""
                    }`}
                    onChange={handleEventTypeId}
                    value={eventTypeId}
                  >
                    <option value={0}>{t("Select type")}</option>
                    {eventTypeList.map((ev) => {
                      return (
                        <option value={ev.id} key={ev.id}>
                          {ev.type}
                        </option>
                      );
                    })}
                  </select>
                  {eventTypeIdError.length > 0 && (
                    <span className="text-danger invalid-error">
                      <img
                        className="fa fa-exclamation-circle mr-2 invalid-svg"
                        src={Invalid}
                      />
                      {eventTypeIdError}
                    </span>
                  )}
                </div>

                <div className={`fancy-checkbox m-0 ${!isRepeat && "mb-3"}`}>
                  <label className="basic-text">
                    <input
                      name="fullDayEvent"
                      type="checkbox"
                      checked={isRepeat}
                      onChange={handleRepeat}
                    />
                    <span tabIndex={0}>
                      <i> </i>
                      {t("Repeat Event")}
                    </span>
                  </label>
                </div>
                {isRepeat && (
                  <>
                    <div className="col-6">
                      <div className=" input-filed ">
                        <span className="basic-text required">
                          {t("Repeat on")}
                        </span>

                        <div className="circle-container mt-2">
                          <div>
                            <label
                              className={`circulercheckbox ${
                                sunday ? "checked" : ""
                              }`}
                              tabIndex={0}
                            >
                              <input
                                type="checkbox"
                                checked={sunday}
                                onChange={() => {
                                  setSunday(!sunday);
                                  setRepeatedDaysError("");
                                  setIsStateUpdated(true);
                                }}
                              />
                              <span
                                className={`circulercheckbox-letter ${
                                  sunday ? "checked" : ""
                                }`}
                              >
                                {t("S")}
                              </span>
                            </label>
                          </div>
                          <div>
                            <label
                              className={`circulercheckbox ${
                                monday ? "checked" : ""
                              }`}
                              tabIndex={0}
                            >
                              <input
                                type="checkbox"
                                checked={monday}
                                onChange={() => {
                                  setMonday(!monday);
                                  setRepeatedDaysError("");
                                  setIsStateUpdated(true);
                                }}
                              />
                              <span
                                className={`circulercheckbox-letter ${
                                  monday ? "checked" : ""
                                }`}
                              >
                                {t("M")}
                              </span>
                            </label>
                          </div>
                          <div>
                            <label
                              className={`circulercheckbox ${
                                tuesday ? "checked" : ""
                              }`}
                              tabIndex={0}
                            >
                              <input
                                type="checkbox"
                                checked={tuesday}
                                onChange={() => {
                                  setTuesday(!tuesday);
                                  setRepeatedDaysError("");
                                  setIsStateUpdated(true);
                                }}
                              />
                              <span
                                className={`circulercheckbox-letter ${
                                  tuesday ? "checked" : ""
                                }`}
                              >
                                {t("T")}
                              </span>
                            </label>
                          </div>
                          <div>
                            <label
                              className={`circulercheckbox ${
                                wednesday ? "checked" : ""
                              }`}
                              tabIndex={0}
                            >
                              <input
                                type="checkbox"
                                checked={wednesday}
                                onChange={() => {
                                  setWednesday(!wednesday);
                                  setRepeatedDaysError("");
                                  setIsStateUpdated(true);
                                }}
                              />
                              <span
                                className={`circulercheckbox-letter ${
                                  wednesday ? "checked" : ""
                                }`}
                              >
                                {t("W")}
                              </span>
                            </label>
                          </div>
                          <div>
                            <label
                              className={`circulercheckbox ${
                                thursday ? "checked" : ""
                              }`}
                              tabIndex={0}
                            >
                              <input
                                type="checkbox"
                                checked={thursday}
                                onChange={() => {
                                  setThursday(!thursday);
                                  setRepeatedDaysError("");
                                  setIsStateUpdated(true);
                                }}
                              />
                              <span
                                className={`circulercheckbox-letter ${
                                  thursday ? "checked" : ""
                                }`}
                              >
                                {t("T")}
                              </span>
                            </label>
                          </div>
                          <div>
                            <label
                              className={`circulercheckbox ${
                                friday ? "checked" : ""
                              }`}
                              tabIndex={0}
                            >
                              <input
                                type="checkbox"
                                checked={friday}
                                onChange={() => {
                                  setFriday(!friday);
                                  setRepeatedDaysError("");
                                  setIsStateUpdated(true);
                                }}
                              />
                              <span
                                className={`circulercheckbox-letter ${
                                  friday ? "checked" : ""
                                }`}
                              >
                                {t("F")}
                              </span>
                            </label>
                          </div>
                          <div>
                            <label
                              className={`circulercheckbox ${
                                saturday ? "checked" : ""
                              }`}
                              tabIndex={0}
                            >
                              <input
                                type="checkbox"
                                checked={saturday}
                                onChange={() => {
                                  setSaturday(!saturday);
                                  setRepeatedDaysError("");
                                  setIsStateUpdated(true);
                                }}
                              />
                              <span
                                className={`circulercheckbox-letter ${
                                  saturday ? "checked" : ""
                                }`}
                              >
                                {t("S")}
                              </span>
                            </label>
                          </div>
                        </div>
                        {repeatedDaysError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {repeatedDaysError}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className=" input-filed">
                        <span className="basic-text required">
                          {t("Ends on")}
                        </span>

                        <DatePicker
                          wrapperClassName=""
                          placeholderText="Select Date"
                          selected={repeatEndDate}
                          onChange={handleRepeatEndDate}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat={school.date_format}
                          popperPlacement="bottom"
                          customInput={<CustomEndsOnInput icon={Calender} />}
                        />

                        {repeatEndDateError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {repeatEndDateError}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="input-filed ">
                  <span className="basic-text ">{t("Description")}</span>
                  <textarea
                    style={{
                      width: "100%",
                      minHeight: "120px",
                      height: "120px",
                    }}
                    type="text"
                    className="form-control custom-input"
                    onChange={handleDescription}
                    value={description}
                  />
                </div>
              </div>
            </div>
            <div
              className="border-top text-right"
              style={{
                position: "fixed",
                width: "656px",
                bottom: "0px",
                right: getLanguage !== "ar"  && "0px",
                background: "#ffffff",
                padding: "10px",
              }}
            >
              <button
                className="secondary-modal-btn btn mr-2"
                onClick={() => {
                  onClose();
                  setIsEditEvent(null);
                  reset();
                }}
              >
                {t("Cancel")}
              </button>
              <button
                style={{ float: "right" }}
                className="btn primary-lg-btn"
                
                onClick={handleSave}
              >
                {isEditEvent == null ? t("Create Event") : t("Edit Event")}
              </button>
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
      <ExitAlert
        visible={alertModal}
        onClose={() => {
          setIsAlertModal(false);
          setIsStateUpdated(false);
        }}
        onOk={() => {
          onClose();
          setIsAlertModal(false);
          reset();
          setIsEditEvent(null);
          setIsStateUpdated(false);
        }}
      />
    </>
  );
}
