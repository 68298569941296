import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import moment from "moment/moment";
import TagButton from "../TagButton";
import {
  activeTagStyle,
  greenTagStyle,
  inactiveTagStyle,
  orangeTagStyle,
  redTagStyle,
  unpaidTagStyle,
} from "../../Dashbord/TagButtonStyle";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import GeneratePayment from "./AddPayment";
import ToastHOC from "../../HOC/ToastHOC";
import InvoiceView from "../../ParentsDashboard/Billing/InvoiceView";
import { invoiceDownload } from "../../CommonImage";
import { InvoiceStatus } from "../../../Enums";
import Loader from "../../../components/common/Loader";

function RemainingInvoices(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const updateData = props?.updateData;
  const message = props?.message;
  const { setIsToast } = props;
  const pendingToast = props.location?.pendingToast;
  const school = Util.getSelectedSchool();
  const [remainingInvoices, setRemainingInvoices] = useState([]);
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const studentId = props?.student?.id;
  const student = props?.student;
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleInvoice, setIsVisibleInvoice] = useState(false);
  const [invoiceId, setInvoiceId] = useState(0);

  const getStudentBalance = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`student/balance/${studentId}`)
      .then((res) => {
        setIsLoading(false);
        setRemainingInvoices(res.data.remaining_invoices);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getStudentBalance();
  }, [updateData]);
  useEffect(() => {
    if (!Util.isValidData(props)) {
      history.push("/billing-plan-list");
    }
  }, []);
  const sendReminder = (id) => {
    ApiCalling.apiCallBodyDataGet(`billing-invoice/send-mail/${id}`)
      .then((res) => {
        if (res.data.status == true) {
          message(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const DownloadInvoice = (id) => {
    ApiCalling.apiCallBodyDataGet(`billing-invoice/download-mail/${id}`)
      .then((res) => {
        if (res.data.status == true) {
          window.open(invoiceDownload + res.data.invoice, "_blank");
        } else {
          message(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div className="container-fluid mt-4">
      {!isLoading ? (
        <table
          className="table"
          style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
        >
          <thead style={{ backgroundColor: "#e5e7eb" }}>
            <tr>
              <th>{t("Invoice")}</th>
              <th>{t("Fee Type")}</th>
              <th>{t("Due Date")}</th>
              <th>{t("Status")}</th>
              <th>{t("Amount")}</th>
              <th>{t("Balance")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          {remainingInvoices.length > 0 ? (
            <tbody>
              {remainingInvoices.map((r, index) => {
                const LastDate = moment(r.last_payment_date).format(
                  Util.getMomentDateTimeFormat(school.date_format)
                );
                const Today = moment().format(
                  Util.getMomentDateTimeFormat(school.date_format)
                );

                return (
                  <>
                    <tr key={r.id}>
                      <td>{Util.invoiceIdFormat(r.id)}</td>
                      <td>
                        <p style={{ whiteSpace: "pre-line" }}>
                          {Util.getListDisplay(
                            r.types.map((f) => f.Fee_Type),
                            2,
                            "\n"
                          )}
                        </p>
                      </td>
                      <td className={`${LastDate < Today && "text-danger"}`}>
                        {Util.isValidData(r.last_payment_date) ? LastDate : "-"}
                        {LastDate < Today && (
                          <p className="small-text">
                            {moment(Today).diff(moment(LastDate), "days")}{" "}
                            {t("Days Past Due")}
                          </p>
                        )}
                      </td>
                      <td>
                        <TagButton
                          style={"btn btn-secondary"}
                          size={"150px"}
                          title={Util.getInvoiceStatus(r.status)}
                          buttonStyle={
                            r.status == 0
                              ? redTagStyle
                              : r.status == 1
                              ? orangeTagStyle
                              : greenTagStyle
                          }
                          icon="fa fa-circle"
                          iconSize="6px"
                        />
                      </td>
                      <td>{Util.amountFormat(r.total)}</td>
                      <td>{Util.invoiceFormat(r.due)}</td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-lg custom-primary "
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ color: "#ffffff", padding: "5px, 10px" }}
                          >
                            {t("Action")}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              {(InvoiceStatus.UNPAID == r.status ||
                                InvoiceStatus.PARTIAL_PAID == r.status) && (
                                <>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() => {
                                      setIsVisiblePayment(true);
                                      setInvoiceData(r);
                                    }}
                                  >
                                    {t("Log a Payment ")}
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() => {
                                      sendReminder(r.id);
                                    }}
                                  >
                                    {t("Send reminder")}
                                  </a>
                                </>
                              )}

                              <a
                                className="dropdown-item"
                                role="button"
                                onClick={() => {
                                  setIsVisibleInvoice(true);
                                  setInvoiceId(r.id);
                                }}
                              >
                                {t("View")}
                              </a>
                              <a
                                className="dropdown-item"
                                role="button"
                                onClick={() => {
                                  DownloadInvoice(r.id);
                                }}
                              >
                                {t("Download")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          ) : (
            <tfoot>
              <tr>
                <td colSpan={8}>{t("Not Found!")}</td>
              </tr>
            </tfoot>
          )}
        </table>
      ) : (
        <Loader />
      )}
      <GeneratePayment
        show={isVisiblePayment}
        onClose={(msg) => {
          if (msg) {
            setIsToast(msg);
          }
          setIsVisiblePayment(false);
        }}
        onSave={(msg) => {
          if (msg) {
            setIsToast(msg);
          }
          setIsVisiblePayment(false);
          getStudentBalance();
        }}
        studentIds={studentId}
        studentName={student?.first_name + " " + student?.last_name}
        invoiceDetails={invoiceData}
      />
      <InvoiceView
        show={isVisibleInvoice}
        onClose={() => {
          setIsVisibleInvoice(false);
        }}
        invoiceId={invoiceId}
      />
    </div>
  );
}
export default ToastHOC(RemainingInvoices);
