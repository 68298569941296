import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

export default function ReportingVerticalTabs({
  a11yProps,
  value,
  handleChange,
  TabPanel,
}) {
  const { t } = useTranslation();
  const tabStyle = {
    textTransform: "capitalize",
    height: "40px",
    textAlign: "left !important",
  };
  return (
    <div className="vertical-tab-setting">
      <Box>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <Tab style={tabStyle} label={t("Daily attendance report")} {...a11yProps(0)} />
          <Tab style={tabStyle} label={t("Check-in report")} {...a11yProps(1)}/>
          <Tab style={tabStyle} label={t("Age Report")}  {...a11yProps(2)}/>
          <Tab style={tabStyle} label={t("Activity Report")} {...a11yProps(3)} />
          <Tab style={tabStyle} label={t("Student Report")} {...a11yProps(4)} />
        </Tabs>
      </Box>
    </div>
  );
}
