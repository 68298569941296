import React, { useState } from "react";
import BillDashboard from "./BillDashboard";
import BillPlanLibrary from "./BillPlanLibrary";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ToastHOC from "../../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import Const from "../../../Const";

function DashboardTab(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const pendingToast = props.location.pendingToast;
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="container-fluid">
      <div className="body">
        <div className="col-lg col-md-12 p-3">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              sx={{
                fontWeight: 600,
                marginRight: "20px",
                letterSpacing: 0,
                textTransform: "capitalize",
                fontFamily: Const.fontfamily,
                padding: "5px",
                height: "40px",
                "& .MuiTabs-indicator": {
                  backgroundColor: Const.primaryColor,
                  height: 2,
                },
              }}
            >
              <Tab
                sx={{
                  fontWeight: 600,
                  marginRight: "20px",
                  letterSpacing: 0,
                  textTransform: "capitalize",
                  fontFamily: Const.fontfamily,
                  padding: "5px",
                  height: "40px",
                  "&.Mui-selected": {
                    color: Const.primaryColor,
                    fontWeight: "600",
                  },
                }}
                value="1"
                label={t("At a Glance")}
              />
              <Tab
                sx={{
                  fontWeight: 600,
                  marginRight: "20px",
                  letterSpacing: 0,
                  textTransform: "capitalize",
                  fontFamily: Const.fontfamily,
                  padding: "5px",
                  height: "40px",
                  "&.Mui-selected": {
                    color: Const.primaryColor,
                    fontWeight: "600",
                  },
                }}
                value="2"
                label={t("Billing Plan Library")}
              />
            </Tabs>
          </Box>
          {value === "1" && <BillDashboard />}
          {value === "2" && <BillPlanLibrary />}
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(DashboardTab);
