import React from "react";
import { Modal } from "react-bootstrap";
import Group from "../assets/images/Group 691314497.png";

export default function ExitAlert({ onClose, onOk, visible = false }) {
  return (
    <div>
      <Modal
        centered
        show={visible}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        style={{ borderRadius: "8px" }}
        size={"sm"}
        animation={true}
        className="custom-modal"
      >
        <Modal.Body className="p-0">
          <div className="d-flex justify-content-center">
            <img src={Group} alt="group" className="img-responsive" />
          </div>
          <div className="modal-contents text-center">
            <h3
              style={{ fontWeight: "700", fontSize: "20px", color: "#07112B" }}
            >
              Warning
            </h3>
            <span
              style={{ fontWeight: "500", fontSize: "14px", color: "#4B5563" }}
            >
              Are you sure you want to navigate from this page? All the changes
              you made will not be saved
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0 d-flex justify-content-between">
          <button className="secondary-modal-btn btn" onClick={onClose}>
            Stay on Page
          </button>
          <button className="primary-modal-lg-btn btn" onClick={onOk}>
            Leave Page
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
