import React from "react";
import { connect } from "react-redux";
// import "bootstrap/dist/css/bootstrap.min.css";

class Page404_LoggedIn extends React.Component {
  render() {
    return (
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid ">
            <div className="body  h-100 ">
              <div
                className="row h-100 "
                style={{
                  display: "flex",
                  justifyContent: "center",
                  placeItems: "center",
                  transform: "translateY(50%)",
                }}
              >
                <div className="col-lg-5 col-md-8 col-sm-10">
                  <div className="card ">
                    <div className="header">
                      <h3>
                        <span className="clearfix title">
                          <span className="number left">404 </span>
                          <span className="text">
                            Oops! <br />
                            Page Not Found
                          </span>
                        </span>
                      </h3>
                    </div>
                    <div className="body">
                      <p>
                        The page you were looking for could not be found, please{" "}
                        <a>contact us</a> to report this issue.
                      </p>
                      <div className="margin-top-30">
                        <a
                          className="btn btn-default"
                          onClick={() => {
                            this.props.history.push("/Dashboard");
                          }}
                        >
                          <i className="fa fa-arrow-left"></i>&nbsp;
                          <span>Go Back</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Page404_LoggedIn.propTypes = {};

const mapStateToProps = ({ loginReducer }) => ({});

export default connect(mapStateToProps, {})(Page404_LoggedIn);
