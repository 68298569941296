import React, { useEffect, useState } from "react";
import groupIcon from "../../assets/Icon/GroupIcon.svg";
import selectedGroupIcon from "../../assets/Icon/selectedGroup (2).svg";
import Util from "../../Util";
import check from "../../assets/Icon/check-double.svg";
import { useTranslation } from "react-i18next";

export default function GroupList(props) {
  const { t } = useTranslation();
  const groupUsers = props?.GroupList;
  const searchClick = props?.searchGroupClick;
  const [selectedGroupId, setSelectedGroupId] = useState(
    props?.selectedGroupId
  );
  // const [groupUsers, setGroupUsers] = useState([]);

  function createRoom(user1) {
    setSelectedGroupId(user1.id);
    props.startChat(user1);
  }

  return (
    <>
      {groupUsers != undefined && groupUsers.length > 0 ? (
        <ul className="list-unstyled chat-list mt-2 mb-0">
          {groupUsers
            .sort((a, b) => {
              if (a.unread > 0 || b.unread > 0)
                return b.unread > a.unread ? 1 : -1;
              else if (a.last_message_on !== "" && b.last_message_on !== "") {
                return new Date(b.last_message_on) > new Date(a.last_message_on)
                  ? 1
                  : -1;
              } else if (a.last_message_on === "" && b.last_message_on === "") {
                return a.name < b.name ? -1 : 1;
              } else {
                return b.last_message_on.length - a.last_message_on.length;
              }
            })

            .map((item) => {
              return (
                <li
                  key={item.id}
                  className={selectedGroupId == item?.id ? "active" : ""}
                >
                  <a
                    onClick={() => {
                      createRoom(item);
                    }}
                    className="d-flex"
                  >
                    <img
                      src={
                        selectedGroupId == item?.id
                          ? selectedGroupIcon
                          : groupIcon
                      }
                      height={40}
                      width={40}
                    />
                    <div
                      className={`row m-0 about ${
                        item.unread > 0 ? "unread" : ""
                      }`}
                    >
                      <div className="col-9 name text-start">{item.name}</div>
                      <div className="col-3 last-message-time text-end">
                        {Util.getDateDisplay_ChatMessage(item.last_message_on)}
                      </div>

                      <div className="d-flex justify-content-between">
                        <div
                          className="last-message text-start"
                          style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            textOverflow: "ellipsis",
                            whiteSpace: "normal", // This allows the text to wrap if it's less than two lines
                            maxHeight: "3.6em", // You can adjust this value to control the height for two lines
                          }}
                        >
                          {Util.isValidData(item.last_message) ? (
                            <>
                              {item.sent_by.firstName} : {item.last_message}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              );
            })}
        </ul>
      ) : (
        <div className="row">
          <div className="col-lg-12 offset-lg-12">
            <div className="d-flex text-center p-5">
              <i
                className=" card p-5 icon-book-open"
                style={{
                  fontSize: "50px",
                  opacity: "0.3",
                }}
              ></i>
            </div>
            <p className="text-center small text-muted">
              {t("Group Not Found")}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
