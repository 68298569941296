import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ApiCalling from "../../../network/ApiCalling";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import ModalComponent from "../ModalComponent";

export default function InActive(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { setIsToast } = props;
  const pendingToast = props.props.location?.pendingToast;
  const studentId = props.props.location.studentId;
  const [student, setStudent] = useState();
  const [showInActiveModal, setShowInActiveModal] = useState(false);
  const handleInActive = () => {
    ApiCalling.apiCallParamsPost(`student/inactive/${studentId}`)
      .then((res) => {
        setShowInActiveModal(false);
        history.push({
          pathname: "/Student",
          pendingToast: res.data.message,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getStudentData = async () => {
    ApiCalling.apiCallParamsGet("student/" + studentId).then((res) => {
      setStudent(res.data);
    });
  };
  useEffect(() => {
    getStudentData();
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  return (
    <div>
      <div>
        <div className="">
          <div className="row">
            <div>
              <div className="col-lg">
                <div className="alert alert-danger m-5 align-center">
                  <h6>{t("You are about to Inactivate the Student")}.</h6>
                  <div>
                    {t(
                      "This will hide all student related data from the portal"
                    )}
                    <br />
                    {t("Are you sure")}?
                  </div>
                  <br />
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      setShowInActiveModal(true);
                    }}
                    disabled={!Util.hasPermission("STUDENT_DELETE")}
                  >
                    {t("Inactive")} {student?.first_name}
                  </button>
                </div>
              </div>
            
              <ModalComponent
                show={showInActiveModal}
                onHide={() => {
                  setShowInActiveModal(false);
                }}
                title={t("InActive")}
                subTitle={t("Are You Sure to InActive")}
                size="sm"
                onCancel={t("Cancel")}
                onConfirm={t("Confirm")}
                onCancelClick={() => {
                  setShowInActiveModal(false);
                }}
                onConfirmClick={handleInActive}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
