import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import ToastHOC from "../../HOC/ToastHOC";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Close from "../../../assets/Icon/close.png";

function Mychildren(props) {
  const { t } = useTranslation();

  const user = Util.getUser();
  const history = useHistory();
  const { setIsToast } = props;
  const pendingToast = props.location.pendingToast;
  const [parentData, setParentData] = useState([]);
  const [showChildModal, setShowChildModal] = useState(false);
  const [characterCode, setCharacterCode] = useState("");
  const [characterCodeError, setCharacterCodeError] = useState("");
  const [relationListOfParent, setRelationListOfParent] = useState([]);
  const [relation, setRelation] = useState(0);
  const [relationError, setRelationError] = useState("");

  const handleShowModal = () => {
    setShowChildModal(true);
  };
  const handleCloseModal = () => {
    setShowChildModal(false);
  };
  const handleCharacterCodeChange = (e) => {
    setCharacterCode(e.target.value);
    setCharacterCodeError("");
  };
  const handleRelation = (e) => {
    setRelation(e.target.value);
    setRelationError("");
  };
  const handleSave = (e) => {
    e.preventDefault();
    setCharacterCodeError("");
    setRelationError("");
    let error = false;
    if (characterCode === "") {
      setCharacterCodeError(t("Please enter the code"));
      error = true;
    }
    if (relation === 0) {
      setRelationError(t("Please Select Relation"));
      error = true;
    }
    if (!error) {
      const data = {
        student_invitee: characterCode,
        student_parent_relation_type_id: relation,
      };
      ApiCalling.apiCallBodyDataPost("parent/add-parent-invitee", data)
        .then((res) => {
          if (res.data.status == true) {
            setIsToast(res.data.message);
            ApiCalling.apiCallBodyDataGet(`user/get-user-byId/${user.id}`)
              .then((res) => {
                setParentData(res.data.Parents);
              })
              .catch((error) => {
                console.log(error);
              });
            setShowChildModal(false);
            setCharacterCode("");
            setRelation(0);
            setCharacterCodeError("");
            setRelationError("");
          } else {
            if (res.data.message === "Invitee is incorrect") {
              setCharacterCodeError(res.data.message);
            } else {
              setRelationError(res.data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const parentDashBoard = () => {
    setParentData(user.Parents);
  };
  const relationList = async () => {
    ApiCalling.apiCallParamsGet("student-parent-relation-type").then((res) => {
      setRelationListOfParent(res.data);
    });
  };
  useEffect(() => {
    parentDashBoard();
    relationList();
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <div className="row py-3">
            <div className="header text-right">
              <div className="dropdown">
                <button
                  className="btn custom-primary"
                  type="button"
                  aria-expanded="false"
                  style={{ color: "#ffffff", padding: "5px, 10px" }}
                  onClick={handleShowModal}
                >
                  {t("Add Child")}
                </button>
              </div>
              <Modal
                size="md"
                animation={true}
                centered={true}
                dialogClassName="modal-view"
                show={showChildModal}
                onHide={handleCloseModal}
                onShow={() => {
                  setCharacterCode("");
                  setRelation(0);
                  setCharacterCodeError("");
                  setRelationError("");
                }}
              >
                
                <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                    <h6>
                    {t("Add Child")}
                    </h6>

                    <button className="hidden-btn" onClick={handleCloseModal}>
                      <img
                        src={Close}
                        className="svg-icon"
                        height={20}
                        width={20}
                      />
                    </button>
                  </div>
                <Modal.Body>
                  <div>
                    <label>
                      {t(
                        "Please enter the 10 character Code sent by your school"
                      )}
                      .
                    </label>
                    <input
                      className="form-control"
                      placeholder={t("enter the code")}
                      value={characterCode}
                      onChange={handleCharacterCodeChange}
                    />
                    {characterCodeError.length > 0 && (
                      <span className="text-danger">{characterCodeError}</span>
                    )}
                  </div>
                  <div className="input-filed mt-2">
                    <label>{"Relation with Student"}</label>
                    <select
                      name=""
                      id=""
                      className="select-dropdown"
                      value={relation}
                      onChange={handleRelation}
                    >
                      <option selected disabled value="0">
                        {t("Select")}
                      </option>
                      {relationListOfParent?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </select>
                    {relationError ? (
                      <span className="text-danger">{relationError}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn custom-primary" onClick={handleSave}>
                    {t("Done")}
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="body ">
            <div className="table-div mt-2 overflow-hidden">
              <div className="col-lg col-md-12 p-0 m-0">
                <div className="d-wrapper">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th scope="col"> {t("Name")} <i className="fa fa-sort ml-1" aria-hidden="true"></i></th>
                        <th scope="col">{t("School")} <i className="fa fa-sort ml-1" aria-hidden="true"></i></th>
                        <th scope="col"> {t("Relation")} <i className="fa fa-sort ml-1" aria-hidden="true"></i></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {parentData.length > 0 &&
                        parentData.map((item) => (
                          <tr key={item.id}>
                            <td>
                              {item.Student.first_name}{" "}
                              {item.Student.middle_name}
                              {item.Student.last_name}
                            </td>
                            <td>{item.Student.School.name}</td>

                            <td>{item.StudentParentRelationType.type}</td>
                            <td>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (item) {
                                    history.push({
                                      pathname: "/child-profile",
                                      childInfo: item,
                                    });
                                  }
                                }}
                              >
                                {t("Profile")}
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(Mychildren);
