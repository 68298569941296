import React, { useEffect, useState } from "react";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment/moment";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/common/Loader";

export default function UpcomingInvoices({ Student, onTabChange }) {
  const { t } = useTranslation();
  const studentId = Student?.id;
  const student = Student;
  const history = useHistory();
  const [upcomingInvoices, setUpcomingInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const school = Util.getSelectedSchool();

  const handleBillPlansClick = () => {
    onTabChange();
  };

  const getAllUpcomingInvoices = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(
      `billing-plans/upcoming-student-invoices/${studentId}`
    )
      .then((res) => {
        if (res.data) {
          setIsLoading(false);
          setUpcomingInvoices(res.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (Util.isValidData(studentId)) {
      getAllUpcomingInvoices();
    } else {
      history.push("/billing-plan-list");
    }
  }, [studentId]);
 
  return (
    <div className="container-fluid mt-4">
      {!isLoading ? (
        <>
          {upcomingInvoices.length > 0 ? (
            <>
              <div className="drawer-header">
                {t("Upcoming invoices that will post soon")}
              </div>
              <div className="pb-4 text-start">
                {t(
                  "Showing first set of upcoming invoices for each bill plan."
                )}
                <br />{" "}
                {t(
                  "You can add additional charges to an invoice only after Invoice generates."
                )}
              </div>
              <table
                className="table"
                style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
              >
                <thead style={{ backgroundColor: "#e5e7eb" }}>
                  <tr>
                    <th>{t("will generate on")}</th>
                    <th>{t("Fee Type")}</th>
                    <th>{t("Amount")}</th>
                  </tr>
                </thead>

                <tbody>
                  {upcomingInvoices.map((r, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <strong>
                              {moment(r.next_invoice_date).format(
                                Util.getMomentDateTimeFormat(school.date_format)
                              )}
                            </strong>
                            <div className="small-text">{r.name}</div>
                          </td>
                          <td>{r.type}</td>
                          <td>{Util.amountFormat(r.amount)}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <div className="row">
              <div className="col-md-8 offset-md-2 ">
                <div className="alert alert-warning text-center m-2">
                  {t("No invoices at this time.Check out this student's")}{" "}
                  <span className="link" onClick={handleBillPlansClick}>
                    {t("bill plans.")}
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
