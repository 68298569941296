import React from "react";
import ActivityCard from "../../../components/ActivityCard/ActivityCard";
import PageHeader from "../../../components/PageHeader";
import { useState } from "react";
import Util from "../../../Util";
import ApiCalling from "../../../network/ApiCalling";
import { useEffect } from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/common/Loader";
import BreadCrumbComponent from "../../../components/BreadCrumbComponent";

export default function ActivityDetailsPage(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const ParentMode = Util.getParentMode();
  const activityID = props.location.activityId;
  const [activityData, setActivityData] = useState();
  const [comment, setComment] = useState("");
  const [commentPhoto, setCommentPhoto] = useState(null);
  const [commentURL, setCommentURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLikeClicked,setIsLikeClicked] = useState(false);
  const [isClickedOnce,setIsClickedOnce] = useState(false);
  const user = Util.getUser();
  const inputRef = useRef(null);

  const handleLike = (id) => {
    if(isLikeClicked){
      return;
    }
    setIsLikeClicked(true);
    ApiCalling.apiCallBodyDataPost(`activity/like/${id}`)
      .then((res) => {
        setIsLikeClicked(false);
        const updatedData = { ...activityData };
        if (updatedData.id === id) {
          updatedData.ActivityLike.push({ created_by: user.id });
        }
        setActivityData(updatedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDisLike = (id) => {
    ApiCalling.apiCallDelete(`activity/Unlike/${id}`)
      .then((res) => {
        const updatedData = { ...activityData };
        if (updatedData.id === id) {
          updatedData.ActivityLike.map((like, index) => {
            if (like.created_by === user.id) {
              activityData.ActivityLike.splice(index, 1);
            }
          });
          setActivityData(updatedData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const sendComment = (activityData) => {
    if(isClickedOnce){
      return
    }
    setIsClickedOnce(true);
    if (comment !== "" || commentPhoto !== null) {
      const formData = new FormData();
      formData.append("activity_id", activityData);
      formData.append("comment", comment);
      if (commentPhoto) {
        formData.append("activity_comment", commentPhoto);
      }

      ApiCalling.apiCallBodyDataPost("activity/add-post-comment", formData)
        .then((res) => {
          setIsClickedOnce(false);
          // console.log("send comments::", res.data);
          getByActivity();
          setComment("");
          setCommentPhoto(null);
          setCommentURL("");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteComment = (id) => {
    ApiCalling.apiCallDelete(`activity/comment/${id}`)
      .then((res) => {
        if (res.data.status == true) {
          getByActivity();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const getByActivity = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`activity/getById/${activityID}`).then(
      (res) => {
        if (res.data) {
          setIsLoading(false);
          setActivityData(res.data);
        } else {
          setIsLoading(false);
          // if (ParentMode) {
          //   history.push("/ParentsDashboard")
          // } else {
          //   history.push("/NewsFeed");
          // }
        }
      }
    );
  };

  useEffect(() => {
    if (Util.isValidData(activityID)) {
      getByActivity();
    } else {
      if (ParentMode) {
        history.push("/ParentsDashboard")
      } else {
        history.push("/NewsFeed");
      }
    }
  }, []);

  return (
    <div>
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid">
            <div className=" mb-3">
              <BreadCrumbComponent
                Breadcrumb={[
                  {
                    name: `${t("News Feed")}`,
                    navigate: () => {
                      history.push(
                        `${
                          props.location.isParent == true
                            ? "/ParentsDashboard"
                            : "/NewsFeed"
                        }`
                      );
                    },
                  },

                  { name: `${t("News Feed Details")}` },
                ]}
              />
            </div>
            <div className="container d-wrapper">
              {!isLoading ? (
                <div className="activity-container">
                  <div className="">
                    <ActivityCard
                      activity={activityData}
                      likeOnClick={() => {
                        
                        handleLike(activityData.id);
                      }}
                      dislikeOnClick={() => {
                        handleDisLike(activityData.id);
                      }}
                      commentValue={comment}
                      commentChange={(e) => {
                        setComment(e.target.value);
                      }}
                      commentPhotoChange={(e) => {
                        setCommentPhoto(e.target.files[0]);
                        setCommentURL(URL.createObjectURL(e.target.files[0]));
                      }}
                      closeCommentPhoto={() => setCommentURL("")}
                      commentURL={commentURL}
                      sendComment={() => {
                        sendComment(activityData.id);
                      }}
                      deleteComment={(id) => {
                        deleteComment(id);
                      }}
                      commentSection={true}
                      inputRefs={inputRef}
                      activityDetailsPage={() => {
                        inputRef.current.focus();
                      }}
                    />
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
