import React from "react";
import { Box, SwipeableDrawer } from "@material-ui/core";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getSupportedLanguages } from "i18n-iso-countries";
import { t } from "i18next";

const SideDrawerContainer = ({
  title = "Add title",
  show,
  hide,
  children,
  handleChange,
}) => {
  return (
    <SwipeableDrawer
      anchor={getSupportedLanguages == "ar" ? "left" : "right"}
      open={show}
      onClose={() => hide()}
      onOpen={() => {}}
      disableEnforceFocus={true}
    >
      <div
        className="box-area"
        style={{
          width: "635px",
          padding: 25,
        }}
      >
        <Box role="presentation">
          <div className="d-flex align-items-center justify-content-between">
            <div className="drawer-header">{t(`${title} `)}</div>
            <IconButton aria-label="close" size="small" onClick={hide}>
              <CloseIcon />
            </IconButton>
          </div>
          {children}
        </Box>
      </div>
    </SwipeableDrawer>
  );
};

export default SideDrawerContainer;
