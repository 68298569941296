import React, { useCallback, useEffect, useState } from "react";
import sampleFile from "../../../assets/File/bulk student demo.xlsx";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx/xlsx.mjs";
import ApiCalling from "../../../network/ApiCalling";
import ToastHOC from "../../HOC/ToastHOC";
import Util from "../../../Util";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { Rings } from "react-loader-spinner";
import Loader from "../../../components/common/Loader";
import BreadCrumbComponent from "../../../components/BreadCrumbComponent";
import Download from "../../../../src/assets/Icon/download.jpg";
import CustomFileUpload from "../CustomFileUpload";
import Call from "../../../../src/assets/Icon/call.svg";
import moment from "moment/moment";
import Calander from "../../../../src/assets/Icon/calendar.svg";
import Sort from "../../../../src/assets/Icon/Short.svg";
import Invalid from "../../../assets/Icon/invalid.svg";
import ExitAlert from "../../../components/ExitAlert";

function BulkStudent(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const hasRoom = props.location.hasClassRoom;
  const school = Util.getSelectedSchool();
  const pendingToast = props.location?.pendingToast;
  const history = useHistory();

  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [room, setRoom] = useState(null);
  const [roomError, setRoomError] = useState("");
  const [gradeId, setGradeId] = useState(0);
  const [gradeError, setGradeError] = useState("");
  const [classId, setClassId] = useState(0);
  const [classError, setClassError] = useState("");
  const [stageList, setStageList] = useState([]);
  const [showFileData, setShowFileData] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [allStateList, setAllStateList] = useState([]);
  const [allCityList, setAllCityList] = useState([]);
  const [fields, setFields] = useState([
    {
      id: 1,
      first_name: "",
      last_name: "",
      contact: "",
      address1: "",
      address2: "",

      pinCode: "",
      country: "",
      country_id: 0,
      state: "",
      state_id: 0,
      city: "",
      city_id: 0,
      dob: null,
      birth_place: "",
      birth_place_id: 0,
      nationality: "",
      nationality_id: 0,
      parent_first_name: "",
      parent_last_name: "",
      parent_email: "",
      relation: "",
      relation_id: 0,
      days_per_week: [],
    },
  ]);
  const [fieldError, setFieldError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditFields, setIsEditFields] = useState(false);

  const [documentFileName, setDocumentFileName] = useState("");
  // const [hasClassRoom, setHasClassRoom] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(-1);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [alertModal, setIsAlertModal] = useState(false);
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: "",
  });
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [countryName, setCountryName] = useState([]);
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [StateName, setStateName] = useState([]);
  const [isCityLoading, setIsCityLoading] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);
  const [isStatesUpdated, setIsStatesUpdated] = useState(false);
  const [isStateCall, setIsStateCall] = useState(true);
  const [isCitiesUpdated, setIsCitiesUpdated] = useState(false);
  const [isCityCall, setIsCityCall] = useState(true);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };
  const sortedFields = fields.slice().sort((a, b) => {
    if (!sortConfig.key) {
      return 0;
    }

    const valueA = a[sortConfig.key];
    const valueB = b[sortConfig.key];

    if (typeof valueA === "string" && typeof valueB === "string") {
      if (sortConfig.direction === "ascending") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    } else {
      return 0;
    }
  });
  const handleEditClick = (index) => {
    const updatedFields = [...fields];
    updatedFields[index].isEditing = true;
    setFields(updatedFields);
  };
  const handleCancelClick = (index) => {
    const updatedFields = [...fields];
    updatedFields[index].isEditing = false;
    setFields(updatedFields);
  };

  const handleRemoveField = (index) => {
    setFields(fields.filter((field, i) => i !== index));
  };

  const handleDocumentDragOver = (e) => {
    e.preventDefault();
  };

  const handleDocumentDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleDocumentChange(file);
  };

  const handleDocumentChange = (file) => {
    if (file) {
      const maxSize = 50 * 1024 * 1024;
      if (file.size <= maxSize) {
        setFile(file);
        setFileURL(URL.createObjectURL(file));
      } else {
        setIsToast(t("File size exceeds the limit of 50MB"));
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleDocumentChange(file);
    setFileError("");
    setIsStateUpdated(true);
  };

  const handleInputChange = (index, event) => {
    const newFields = [...fields];
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
    setIsStateUpdated(true);
  };

  const CustomInput = React.forwardRef(({ value, onClick, data }, ref) => (
    <div
      className=" d-flex position-relative"
      style={{ marginTop: "-54px" }}
      onClick={onClick}
    >
      <input
        ref={ref}
        className={`custom-input__field ${
          fieldError && data == null && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img
          src={Calander}
          alt="Calander"
          className="svg-icon position-absolute"
          style={{ top: "10px", right: "10px" }}
        />
      </div>
    </div>
  ));

  const handleSelectCountryChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selected = countryList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["country_id"] = selected.id;
      newFields[index]["country"] = selected.name;
      
      newFields[index]["state_id"] = 0;
      newFields[index]["state"] = "";

      newFields[index]["city_id"] = 0;
      newFields[index]["city"] = "";
      setFields(newFields);
    }

    if (selected) {
      setIsStateCall(false);
      setCountryName([...countryName, selected.name]);
    }

    setIsStateUpdated(true);
  };
  const handleSelectBirthPlaceChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selected = countryList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["birth_place_id"] = selected.id;
      newFields[index]["birth_place"] = selected.name;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };
  const handleSelectNationalityChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selected = countryList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["nationality_id"] = selected.id;
      newFields[index]["nationality"] = selected.name;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };
  const handleSelectRelationChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selected = relationList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["relation_id"] = selected.id;
      newFields[index]["relation"] = selected.name;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    return string.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    });
  };

  const handleSelectStateChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selectedCountry = newFields[index]?.country;
    const formattedCountry = capitalizeFirstLetter(selectedCountry);
    if (allStateList && selectedCountry) {
      const statesOfSelectedCountry = allStateList[formattedCountry];
      console.log("statesOfSelectedCountry", statesOfSelectedCountry);
      const selectedState = statesOfSelectedCountry.find(
        (state) => state.id === Number(selectedId)
      );

      if (selectedState) {
        newFields[index]["state_id"] = selectedState.id;
        newFields[index]["state"] = selectedState.name;

        newFields[index]["city_id"] = 0;
        newFields[index]["city"] = "";
        // console.log("newFields", newFields);

        setFields(newFields);
      }

      if (selectedState.name) {
        setIsCityCall(false);
        setStateName([...StateName, selectedState.name]);
      }

      setIsStateUpdated(true);
    }
  };

  const handleSelectCityChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selectedState = newFields[index]?.state;
    const formattedState = capitalizeFirstLetter(selectedState);
    if (allCityList && selectedState) {
      const cityOfSelectedState = allCityList[formattedState];
      const selectedCity = cityOfSelectedState.find(
        (city) => city.id === Number(selectedId)
      );
      if (selectedCity) {
        newFields[index]["city_id"] = selectedCity.id;
        newFields[index]["city"] = selectedCity.name;
        setFields(newFields);
      }
    }

    setIsStateUpdated(true);
  };
  const handleDayCheckboxChange = (day, index) => {
    const updatedFields = [...fields];
    const daysPerWeek = updatedFields[index].days_per_week || [];

    if (daysPerWeek.includes(day)) {
      updatedFields[index].days_per_week = daysPerWeek.filter((d) => d !== day);
    } else {
      updatedFields[index].days_per_week = [...daysPerWeek, day];
    }

    setFields(updatedFields);
    setIsStateUpdated(true);
  };
  // const handleSelectClassRoomChange = (index, event) => {
  //   const newFields = [...fields];
  //   newFields[index]["classroom_id"] = event.target.value;
  //   newFields[index][event.target.name] = event.target.value;
  //   setFields(newFields);
  //   console.log("new", newFields);
  // };
  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  //   setFileError("");
  // };
  const handleClassChange = (e) => {
    setClassId(e.target.value);
    setClassError("");
    setIsStateUpdated(true);
  };
  const handleGradeChange = (e) => {
    setGradeId(e.target.value);
    setGradeError("");
    setIsStateUpdated(true);
  };
  const Stagelist = async () => {
    ApiCalling.apiCallBodyDataGet("stage/list")
      .then((res) => {
        setStageList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getRelationList = async () => {
    ApiCalling.apiCallParamsGet("student-parent-relation-type").then((res) => {
      setRelationList(res.data);
    });
  };
  const countrylist = async () => {
    ApiCalling.apiCallBodyDataGet("country/list").then((res) => {
      setCountryList(res.data);
    });
  };

  const getStateList = () => {
    setIsStateLoading(true);
    setIsStatesUpdated(false);
    const data = {
      countries: countryName,
    };
    return new Promise((resolve, reject) => {
      ApiCalling.apiCallBodyDataPost(`state/by-country`, data)
        .then((res) => {
          setIsStateLoading(false);
          console.log("state api call", res.data);
          setAllStateList(res.data);
          if (isStateCall) {
            setIsStatesUpdated(true);
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getCityList = () => {
    setIsCityLoading(true);
    setIsCitiesUpdated(false);
    const data = {
      states: StateName,
    };
    return new Promise((resolve, reject) => {
      ApiCalling.apiCallBodyDataPost(`city/by-state`, data)
        .then((res) => {
          setIsCityLoading(false);
          // console.log("city api call", res.data);
          setAllCityList(res.data);
          if (isCityCall) {
            setIsCitiesUpdated(true);
          }
          resolve();
        })
        .catch((error) => [reject(error)]);
    });
  };

  const days = [
    { value: "M", name: "Monday" },
    { value: "TU", name: "Tuesday" },
    { value: "W", name: "Wednesday" },
    { value: "TH", name: "Thursday" },
    { value: "F", name: "Friday" },
  ];

  const readFileDetails = (data) => {
    const countryNames = [];
    const stateNames = [];
    data.forEach((element) => {
      if (
        element.Country &&
        element.Country !== null &&
        element.Country !== undefined
      ) {
        countryNames.push(element.Country);
      }
      if (
        element.State &&
        element.State !== null &&
        element.State !== undefined
      ) {
        stateNames.push(element.State);
      }
    });
    if (countryNames.length > 0) {
      setCountryName(countryNames);
    } else {
      // console.log("updated")
      setIsStatesUpdated(true);
    }
    if (stateNames.length > 0) {
      setStateName(stateNames);
    } else {
      setIsCitiesUpdated(true);
    }
  };

  const handleFileRead = async () => {
    setFileError("");
    setRoomError("");
    setGradeError("");
    setClassError("");
    let error = false;
    if (file == "") {
      setFileError(t("please select file"));
      error = true;
    }
    if (gradeId == 0) {
      setGradeError(t("please select Grade"));
      error = true;
    }
    if (classId == 0) {
      setClassError(t("please select Classroom"));
      error = true;
    }
    if (!error) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const fileData = XLSX.utils.sheet_to_json(firstSheet);
        await setFileDetails(fileData);
        await readFileDetails(fileData);
        setIsStateCall(true);
        setIsCityCall(true);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
    if (isStatesUpdated && isCitiesUpdated) {
      if (fileDetails !== null) {
        processData(fileDetails);
      }
    }
  }, [isStatesUpdated, allStateList, isCitiesUpdated]);

  const processData = (fileData) => {
    console.log("PROCESS");
    var temp = [];
    var i = 0;

    fileData.forEach((element) => {
      var birthPlaceId = 0;
      countryList.map((b) => {
        if (b.name.toLowerCase() == element?.birth_place?.toLowerCase()) {
          birthPlaceId = b.id;
        }
      });
      var nationalityId = 0;
      countryList.map((n) => {
        if (n.name.toLowerCase() == element?.Nationality?.toLowerCase()) {
          nationalityId = n.id;
        }
      });
      var countryId = 0;
      countryList.map((c) => {
        if (c.name.toLowerCase() == element?.Country?.toLowerCase()) {
          countryId = c.id;
        }
      });
      var stateId = 0;
      Object.keys(allStateList).forEach((country) => {
        if (country.toLowerCase() === element?.Country?.toLowerCase()) {
          allStateList[country].forEach((s) => {
            if (s.name.toLowerCase() === element?.State?.toLowerCase()) {
              stateId = s.id;
            }
          });
        }
      });
      var cityId = 0;
      Object.keys(allCityList).forEach((state) => {
        if (state.toLowerCase() === element?.State?.toLowerCase()) {
          allCityList[state].forEach((c) => {
            if (c.name.toLowerCase() === element?.City?.toLowerCase()) {
              cityId = c.id;
            }
          });
        }
      });
      var relationId = 0;
      relationList.map((c) => {
        if (c.type.toLowerCase() == element?.Relation?.toLowerCase()) {
          relationId = c.id;
        }
      });
      const parseDaysString = (daysString) => {
        if (daysString && daysString.trim() !== "") {
          return daysString.split(",").map((day) => day.trim());
        }
        return [];
      };

      const daysFromFile = element.Days_per_week;

      const daysArray = parseDaysString(daysFromFile);
      const ExcelDateToJSDate = (serial) => {
        const utcDays = Math.floor(serial - 25569);
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const date = new Date(0);

        date.setUTCDate(utcDays);
        if (utcDays >= 60) {
          date.setUTCDate(date.getUTCDate() + 1);
        }

        // Adjust for the time zone offset
        const timeZoneOffset = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() - timeZoneOffset);
        return date;
      };
      const jsDate = ExcelDateToJSDate(element.Dob);

      temp.push({
        id: i,
        first_name: element.First_name,
        last_name: element.Last_name,
        contact: element.Parent_contact,
        address1: element.Address1,
        address2: element.Address2,
        city: element.City,
        city_id: cityId,
        pinCode: element.Pincode,
        country: element.Country,
        country_id: countryId,
        state: element.State,
        state_id: stateId,
        dob: element.Dob ? jsDate : null,
        birth_place_id: birthPlaceId,
        birth_place: element.birth_place,
        nationality: element.Nationality,
        nationality_id: nationalityId,
        parent_first_name: element.Parent_first_name,
        parent_last_name: element.Parent_last_name,
        parent_email: element.Parent_email_address,
        relation: element.Relation,
        relation_id: relationId,
        days_per_week: daysArray,
      });
      i++;
    });

    setShowFileData(true);
    setFields(temp);

    console.log("TEMP", temp);
  };

  const uploadFile = () => {
    setFieldError(false);
    const updatedFields = [...fields];
    var error = false;
    updatedFields.map((val, index) => {
      if (
        val.first_name == "" ||
        val.last_name == "" ||
        val.dob == null ||
        val.dob == undefined ||
        (val.pinCode !== "" &&
          val.pinCode !== undefined &&
          Util.Zip_regex.test(val.pinCode) === false) ||
        val.parent_first_name == "" ||
        val.parent_last_name == "" ||
        val.parent_email == "" ||
        val.contact == "" ||
        val.relation_id === 0
      ) {
        console.log("error", val);
        setFieldError(true);
        updatedFields[index].isEditing = true;
        error = true;
      }
    });
    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      updatedFields.map((val, index) => {
        const formData = new FormData();

        formData.append("first_name", val.first_name);
        formData.append("last_name", val.last_name);
        if (val.dob) {
          formData.append("dob", new Date(val.dob).toISOString());
        }
        if (val.contact) {
          formData.append("phone_number", val.contact);
        }

        formData.append("classroom_id", classId);

        if (gradeId) {
          formData.append("grade_id", gradeId);
        }
        if (val.address1) {
          formData.append("address1", val.address1);
        }
        if (val.address2) {
          formData.append("address2", val.address2);
        }
        if (val.city_id) {
          formData.append("city_id", val.city_id);
        }
        if (val.pinCode) {
          formData.append("pinCode", val.pinCode);
        }
        if (val.country_id) {
          formData.append("country_id", val.country_id);
        }
        if (val.state_id) {
          formData.append("state_id", val.state_id);
        }
        if (val.birth_place_id) {
          formData.append("birth_place_id", val.birth_place_id);
        }
        if (val.nationality_id) {
          formData.append("nationality_id", val.nationality_id);
        }
        if (val.parent_first_name) {
          formData.append("parent_firstName", val.parent_first_name);
        }
        if (val.parent_last_name) {
          formData.append("parent_lastName", val.parent_last_name);
        }
        if (val.parent_email) {
          formData.append("parent_email", val.parent_email);
        }
        if (val.relation_id) {
          formData.append("student_parent_relation_type_id", val.relation_id);
        }
        if (val.days_per_week) {
          days.forEach((day) => {
            const dayValue = day.value;

            const isChecked =
              val.days_per_week && val.days_per_week.includes(dayValue);
            formData.append(day.name.toLowerCase(), isChecked);
          });
        }
        ApiCalling.apiCallBodyDataPost(
          "student/create-student-parent",
          formData
        )
          .then((res) => {
            updatedFields[index].isEditing = false;
            setFile("");
            setClassId(0);
            setGradeId(0);
            setIsEditFields(false);
            setIsStateUpdated(false);
            setTriggerExit({
              path: "/student",
              onOk: true,
            });
            if (res.data.status == true) {
              setIsClickedOnce(false);
              setIsLoading(false);
              history.push({
                pathname: "/Student",
                pendingToast: t("Student Created Successfully"),
              });
            } else {
              history.push({
                pathname: "/Student",
                pendingToast: res.data.message,
              });
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      });
    }
  };

  useEffect(() => {
    Stagelist();
    getRelationList();
    countrylist();
    // allStateData();
    // allCityData();
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
    if (hasRoom == undefined || hasRoom == null) {
      history.push("/student");
    }
  }, []);

  useEffect(() => {
    if (countryName.length > 0) {
      getStateList();
    }
  }, [countryName]);
  useEffect(() => {
    if (StateName.length > 0) {
      getCityList();
    }
  }, [StateName]);

  const handleGoToNextPage = useCallback(
    (location) => history.push(location),
    [history]
  );

  useEffect(() => {
    if (triggerExit.onOk) {
      setTimeout(() => {
        handleGoToNextPage(triggerExit.path);
      }, 0);
    }
    const unblock = history.block((location) => {
      if (location.pathname !== "/BulkStudent" && isStateUpdated) {
        setIsAlertModal(true);
      } else {
        return true;
      }

      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToNextPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    isStateUpdated,
  ]);
  return (
    <div className="container-fluid">
      <div className="row mb-2 py-4">
        <BreadCrumbComponent
          Breadcrumb={[
            {
              name: "My School",
              navigate: () => {
                history.push("/Dashboard");
              },
            },
            {
              name: "Student List",
              navigate: () => {
                history.push("/student");
              },
            },
            { name: `${t("Add New Student Bulk")}` },
          ]}
        />
      </div>
      {!showFileData ? (
        <div className="container-80">
          {isLoading ? (
            <Loader />
          ) : hasRoom > 0 ? (
            <div className="body">
              <div className="col-lg mb-md-3 ">
                <h2 className="page-top-head text-left  ">
                  {t("Add New Student Bulk")}
                </h2>
              </div>
              <div className="row m-0">
                <div className="col-6">
                  <h2 className="page-head">{t("Upload Document")}</h2>
                </div>
                <div className="col-6">
                  <div className="d-flex align-items-center justify-content-end gap-1">
                    <img src={Download} />
                    <a
                      href={sampleFile}
                      target="_blank"
                      className="link-button"
                    >
                      {t("Download Template File")}
                    </a>
                  </div>
                </div>
                <div className=" text-start col-12 mt-4">
                  <div className="input-filed">
                    <span className="basic-text  required">{t("Grade")}</span>
                    <select
                      className={`select-dropdown form-select custom-input ${
                        gradeError.length > 0 ? "input-error" : ""
                      }`}
                      value={gradeId}
                      onChange={handleGradeChange}
                    >
                      <option value={0}>{t("Select Grade")}</option>
                      {stageList.map((val) => {
                        return val.Grades.map((grade) => {
                          return (
                            <option key={grade.id} value={grade.id}>
                              {grade.title}
                            </option>
                          );
                        });
                      })}
                    </select>
                    {gradeError.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {gradeError}
                      </span>
                    )}
                  </div>

                  <div className="input-filed">
                    <span className="basic-text  required">
                      {t("Classroom")}
                    </span>
                    <select
                      className={`select-dropdown form-select custom-input ${
                        classError.length > 0 ? "input-error" : ""
                      }`}
                      value={classId}
                      onChange={handleClassChange}
                    >
                      <option value={0}>{t("Select Classroom")}</option>
                      {stageList.map((val) => {
                        return val.Grades.filter((g) => g.id == gradeId).map(
                          (grade) => {
                            return grade.ClassRooms.map((classroom) => {
                              return (
                                <option
                                  selected
                                  value={classroom.id}
                                  key={classroom.id}
                                >
                                  {`${grade.title}-${classroom.name}`}
                                </option>
                              );
                            });
                          }
                        );
                      })}
                    </select>
                    {classError.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {classError}
                      </span>
                    )}
                  </div>

                  <div className="input-filed">
                    <span className="basic-text  required">
                      {t("Upload Document")}
                    </span>
                    <CustomFileUpload
                      onChange={handleFileChange}
                      fileName={file ? file.name : documentFileName}
                      showFile={fileURL.length > 0 ? fileURL : ""}
                      onDragOver={handleDocumentDragOver}
                      onDrop={handleDocumentDrop}
                      alertMessage={
                        documentFileName.length > 0 && (
                          <>
                            {t(
                              "incase you select new file it will replace old"
                            )}
                          </>
                        )
                      }
                      acceptedDocument="XLS,XLSX (max file 25mb)"
                      accept={".xlsx, .xls"}
                      className={`${
                        fileError.length > 0
                          ? "custom-file-error"
                          : "custom-file"
                      }`}
                    />
                    {fileError.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {fileError}
                      </span>
                    )}
                  </div>
                  <div className=" d-flex justify-content-between mt-4">
                    <button
                      className="btn secondary-lg-btn"
                      onClick={() => {
                        history.push("/student");
                      }}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="btn custom-primary"
                      onClick={handleFileRead}
                    >
                      {t("Check Document")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="card col-lg-4">
                <div className="header">
                  <h3>
                    <span className="text">{t("You Can't Add Student")}</span>
                  </h3>
                </div>
                <div className="body">
                  <p>{t("There is no Room Structure")}.</p>
                  <div className="margin-top-10">
                    <a
                      className="btn custom-primary"
                      href="javascript:void(0)"
                      onClick={() => {
                        history.push("/settings");
                      }}
                    >
                      <i className="fa fa-home"></i>&nbsp;
                      <span>{t("Go To Create Room")}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="row m-0">
          <div className="col-6">
            <h2 className="page-head">{t("Preview Document")}</h2>
          </div>
          <div className="col-6">
            <div className="d-flex align-items-center justify-content-end gap-1">
              <button
                className="btn btn-lg custom-secondary"
                onClick={() => {
                  setShowFileData(false);
                }}
              >
                {t("Back")}
              </button>
              <button
                className="btn btn-lg custom-primary"
                onClick={uploadFile}
              >
                {t("Save Student")}
              </button>
            </div>
          </div>
          {!isLoading ? (
            <div className="col-lg-12">
              <div className="table-div mt-2">
                <div className="table-wrapper">
                  <table className="table table-bulk-student">
                    <thead>
                      <tr>
                        <th>
                          {t("First Name")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("first_name");
                            }}
                          />
                        </th>
                        <th>
                          {t("Last Name")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("last_name");
                            }}
                          />
                        </th>
                        <th>
                          {t("Date of Birth")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("dob");
                            }}
                          />
                        </th>
                        <th>
                          {t("Birth Place")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("birth_place");
                            }}
                          />
                        </th>
                        <th>{t("Days Per Week")}</th>
                        <th>
                          {t("Parent first name")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            // onClick={() => {
                            //   handleSort("contact");
                            // }}
                          />
                        </th>
                        <th>
                          {t("Parent last name")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            // onClick={() => {
                            //   handleSort("contact");
                            // }}
                          />
                        </th>
                        <th>{t("Parent contact")}</th>
                        <th>
                          {t("Parent email address")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            // onClick={() => {
                            //   handleSort("contact");
                            // }}
                          />
                        </th>
                        <th>
                          {t("Relation")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            // onClick={() => {
                            //   handleSort("contact");
                            // }}
                          />
                        </th>
                        <th>
                          {t("Nationality")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("nationality");
                            }}
                          />
                        </th>

                        <th>
                          {t("Address 1")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("address1");
                            }}
                          />
                        </th>
                        <th>
                          {t("Address 2")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("address2");
                            }}
                          />
                        </th>

                        <th>
                          {t("Country")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("country");
                            }}
                          />
                        </th>
                        <th>
                          {t("State")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("state");
                            }}
                          />
                        </th>
                        <th>
                          {t("City")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("city");
                            }}
                          />
                        </th>
                        <th>
                          {t("ZIP Code")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("pinCode");
                            }}
                          />
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedFields.map((field, index) => {
                        const isEditing = field.isEditing;
                        return (
                          <tr key={index}>
                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.first_name)
                                    ? field.first_name
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.first_name}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("First Name")}
                                      name="first_name"
                                      className={`form-control custom-input ${
                                        fieldError &&
                                        (!Util.isValidData(field.first_name) ||
                                          field.first_name.length == 0) &&
                                        "input-error"
                                      }`}
                                    />
                                    {fieldError &&
                                      (!Util.isValidData(field.first_name) ||
                                        field.first_name.length == 0) && (
                                        <span className="text-danger invalid-error">
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                          />
                                          {t("First Name is required")}
                                        </span>
                                      )}
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.last_name)
                                    ? field.last_name
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.last_name}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("Last Name")}
                                      name="last_name"
                                      className={`form-control custom-input ${
                                        fieldError &&
                                        (!Util.isValidData(field.last_name) ||
                                          field.last_name.length == 0) &&
                                        "input-error"
                                      }`}
                                    />
                                    {fieldError &&
                                      (!Util.isValidData(field.last_name) ||
                                        field.last_name.length == 0) && (
                                        <span className="text-danger invalid-error">
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                          />
                                          {t("Last Name is required")}
                                        </span>
                                      )}
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(fields[index].dob) ? (
                                    <>
                                      <img
                                        src={Calander}
                                        alt=""
                                        className="mr-1"
                                        style={{ cursor: "pointer" }}
                                      />{" "}
                                      {moment(fields[index].dob).format(
                                        Util.getMomentDateTimeFormat(
                                          school.date_format
                                        )
                                      )}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              ) : (
                                <>
                                  <div
                                    className=""
                                    style={{ minWidth: "150px" }}
                                  >
                                    <DatePicker
                                      placeholderText="Select Date"
                                      selected={fields[index].dob}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      onChange={(date) =>
                                        handleInputChange(index, {
                                          target: { name: "dob", value: date },
                                        })
                                      }
                                      dateFormat={school.date_format}
                                      // wrapperClassName={` ${
                                      //   dateOfBirthError.length > 0 ? "datePicker-error" : ""
                                      // }`}
                                      popperPlacement="bottom"
                                      maxDate={new Date()}
                                      customInput={
                                        <CustomInput data={field.dob} />
                                      }
                                    />
                                    {fieldError && field.dob == null && (
                                      <div
                                        className="text-danger"
                                        style={{ marginTop: "-8%" }}
                                      >
                                        <img
                                          className="fa fa-exclamation-circle mr-1 invalid-svg"
                                          src={Invalid}
                                        />
                                        {t("Date Of Birth is required")}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                Util.isValidData(field.birth_place) &&
                                countryList.some(
                                  (c) =>
                                    c.name.toLowerCase() ===
                                    field?.birth_place?.toLowerCase()
                                ) ? (
                                  field.birth_place
                                ) : (
                                  "-"
                                )
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <select
                                      className="form-select select-dropdown custom-input"
                                      name="birth_place_id"
                                      onChange={(event, i) =>
                                        handleSelectBirthPlaceChange(
                                          index,
                                          event
                                        )
                                      }
                                      style={{ minWidth: "150px" }}
                                    >
                                      <option value={0}>{t("Select")}</option>
                                      {countryList.map((option) => {
                                        if (
                                          field?.birth_place?.toLowerCase() ==
                                          option.name.toLowerCase()
                                        ) {
                                          return (
                                            <option
                                              selected
                                              value={option.id}
                                              key={option.id}
                                            >
                                              {option.name}
                                            </option>
                                          );
                                        } else {
                                          return (
                                            <option
                                              key={option.id}
                                              value={option.id}
                                            >
                                              {option.name}
                                            </option>
                                          );
                                        }
                                      })}
                                    </select>
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              <div
                                className={`input-filed ${
                                  !isEditing ? "mb-0" : "mt-2"
                                }`}
                              >
                                <div className="circle-container">
                                  {["M", "TU", "W", "TH", "F"].map((day) => (
                                    <label
                                      className={`circulercheckbox ${
                                        field.days_per_week &&
                                        field.days_per_week.includes(day)
                                          ? "checked"
                                          : ""
                                      }`}
                                      tabIndex={0}
                                      key={day}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={
                                          Array.isArray(field.days_per_week) &&
                                          field.days_per_week.includes(day)
                                        }
                                        onChange={() =>
                                          handleDayCheckboxChange(day, index)
                                        }
                                      />
                                      <span
                                        className={`circulercheckbox-letter ${
                                          field.days_per_week &&
                                          field.days_per_week.includes(day)
                                            ? "checked"
                                            : ""
                                        }`}
                                      >
                                        {day}
                                      </span>
                                    </label>
                                  ))}
                                </div>
                              </div>
                            </td>
                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.parent_first_name)
                                    ? field.parent_first_name
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.parent_first_name}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("First Name")}
                                      name="parent_first_name"
                                      className={`form-control custom-input ${
                                        fieldError &&
                                        (!Util.isValidData(
                                          field.parent_first_name
                                        ) ||
                                          field.parent_first_name.length ==
                                            0) &&
                                        "input-error"
                                      }`}
                                    />
                                    {fieldError &&
                                      (!Util.isValidData(
                                        field.parent_first_name
                                      ) ||
                                        field.parent_first_name.length ==
                                          0) && (
                                        <span className="text-danger invalid-error">
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                          />
                                          {t("First Name is required")}
                                        </span>
                                      )}
                                  </div>
                                </>
                              )}
                            </td>
                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.parent_last_name)
                                    ? field.parent_last_name
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.parent_last_name}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("Last Name")}
                                      name="parent_last_name"
                                      className={`form-control custom-input ${
                                        fieldError &&
                                        (!Util.isValidData(
                                          field.parent_last_name
                                        ) ||
                                          field.parent_last_name.length == 0) &&
                                        "input-error"
                                      }`}
                                    />
                                    {fieldError &&
                                      (!Util.isValidData(
                                        field.parent_last_name
                                      ) ||
                                        field.parent_last_name.length == 0) && (
                                        <span className="text-danger invalid-error">
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                          />
                                          {t("Last Name is required")}
                                        </span>
                                      )}
                                  </div>
                                </>
                              )}
                            </td>
                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.contact) ? (
                                    <>
                                      <img
                                        src={Call}
                                        alt=""
                                        width={"16px"}
                                        className="mr-1"
                                      />
                                      {field.contact}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.contact}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("contact")}
                                      name="contact"
                                      className="form-control custom-input"
                                    />
                                  </div>
                                </>
                              )}
                            </td>
                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.parent_email)
                                    ? field.parent_email
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.parent_email}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("Email Address")}
                                      name="parent_email"
                                      className={`form-control custom-input ${
                                        fieldError &&
                                        (!Util.isValidData(
                                          field.parent_email
                                        ) ||
                                          field.parent_email.length == 0) &&
                                        "input-error"
                                      }`}
                                    />
                                    {fieldError &&
                                      (!Util.isValidData(field.parent_email) ||
                                        field.parent_email.length == 0) && (
                                        <span className="text-danger invalid-error">
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                          />
                                          {t("Email is required")}
                                        </span>
                                      )}
                                  </div>
                                </>
                              )}
                            </td>
                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.relation) &&
                                  relationList.some(
                                    (c) =>
                                      c.type.toLowerCase() ===
                                      field?.relation?.toLowerCase()
                                  )
                                    ? field.relation
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <select
                                      className={`form-select select-dropdown  ${
                                        fieldError && field.relation_id === 0
                                          ? "input-error"
                                          : "custom-input"
                                      }`}
                                      name="relation_id"
                                      onChange={(event, i) =>
                                        handleSelectRelationChange(index, event)
                                      }
                                      style={{ minWidth: "150px" }}
                                    >
                                      <option value={0}>{t("Select")}</option>
                                      {relationList.map((option) => {
                                        if (
                                          field?.relation?.toLowerCase() ===
                                          option.type.toLowerCase()
                                        ) {
                                          return (
                                            <option
                                              selected
                                              value={option.id}
                                              key={option.id}
                                            >
                                              {option.type}
                                            </option>
                                          );
                                        } else {
                                          return (
                                            <option
                                              value={option.id}
                                              key={option.id}
                                            >
                                              {option.type}
                                            </option>
                                          );
                                        }
                                      })}
                                    </select>
                                    {fieldError && field.relation_id === 0 && (
                                      <span className="text-danger invalid-error">
                                        <img
                                          className="fa fa-exclamation-circle mr-1 invalid-svg"
                                          src={Invalid}
                                        />
                                        {t("relation is required")}
                                      </span>
                                    )}
                                  </div>
                                </>
                              )}
                            </td>
                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.nationality) &&
                                  countryList.some(
                                    (c) =>
                                      c.name.toLowerCase() ===
                                      field?.nationality?.toLowerCase()
                                  )
                                    ? field.nationality
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <select
                                      className="form-select select-dropdown custom-input"
                                      name="nationality_id"
                                      onChange={(event, i) =>
                                        handleSelectNationalityChange(
                                          index,
                                          event
                                        )
                                      }
                                      style={{ minWidth: "150px" }}
                                    >
                                      <option value={0}>{t("Select")}</option>
                                      {countryList.map((option) => {
                                        if (
                                          field?.nationality?.toLowerCase() ==
                                          option.name.toLowerCase()
                                        ) {
                                          return (
                                            <option
                                              selected
                                              value={option.id}
                                              key={option.id}
                                            >
                                              {option.name}
                                            </option>
                                          );
                                        } else {
                                          return (
                                            <option
                                              key={option.id}
                                              value={option.id}
                                            >
                                              {option.name}
                                            </option>
                                          );
                                        }
                                      })}
                                    </select>
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.address1)
                                    ? field.address1
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.address1}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("address 1")}
                                      name="address1"
                                      className="form-control custom-input"
                                    />
                                  </div>
                                </>
                              )}
                            </td>
                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.address2)
                                    ? field.address2
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.address2}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("address 2")}
                                      name="address2"
                                      className="form-control custom-input"
                                    />
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.country) &&
                                  countryList.some(
                                    (c) =>
                                      c.name.toLowerCase() ===
                                      field?.country?.toLowerCase()
                                  )
                                    ? field.country
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <select
                                      className="form-select select-dropdown custom-input"
                                      name="country_id"
                                      onChange={(event, i) =>
                                        handleSelectCountryChange(index, event)
                                      }
                                      style={{ minWidth: "150px" }}
                                    >
                                      <option value="">
                                        {t("Select Country")}
                                      </option>
                                      {countryList.map((option) => {
                                        if (
                                          field?.country?.toLowerCase() ==
                                          option.name.toLowerCase()
                                        ) {
                                          return (
                                            <option
                                              selected
                                              value={option.id}
                                              key={option.id}
                                            >
                                              {option.name}
                                            </option>
                                          );
                                        } else {
                                          return (
                                            <option value={option.id}>
                                              {option.name}
                                            </option>
                                          );
                                        }
                                      })}
                                    </select>
                                  </div>
                                </>
                              )}
                            </td>
                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.state) &&
                                    Object.keys(allStateList).map((country) => {
                                      if (
                                        country.toLowerCase() ===
                                        field?.country?.toLowerCase()
                                      ) {
                                        const hasMatchingState = allStateList[
                                          country
                                        ].some(
                                          (s) =>
                                            s.name.toLowerCase() ===
                                            field?.state?.toLowerCase()
                                        );
                                        return hasMatchingState
                                          ? field.state
                                          : "-";
                                      }
                                      return null;
                                    })}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <select
                                      className="form-select select-dropdown custom-input"
                                      name="state_id"
                                      onChange={(event, i) =>
                                        handleSelectStateChange(index, event)
                                      }
                                      style={{ minWidth: "150px" }}
                                      disabled={field.country_id == 0}
                                    >
                                      <option value="">
                                        {isStateLoading
                                          ? t("Loading...")
                                          : t("Select State")}
                                      </option>
                                      {Object.keys(allStateList).map(
                                        (country, index) => {
                                          if (
                                            country.toLowerCase() ===
                                            field?.country?.toLowerCase()
                                          ) {
                                            return (
                                              <>
                                                {allStateList[country].map(
                                                  (state, stateIndex) => (
                                                    <option
                                                      selected={
                                                        state.name.toLowerCase() ===
                                                        field?.state?.toLowerCase()
                                                      }
                                                      key={stateIndex}
                                                      value={state.id}
                                                    >
                                                      {state.name}
                                                    </option>
                                                  )
                                                )}
                                              </>
                                            );
                                          }
                                          return null;
                                        }
                                      )}
                                    </select>
                                  </div>
                                </>
                              )}
                            </td>
                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.city) &&
                                    Object.keys(allCityList).map((state) => {
                                      if (
                                        state.toLowerCase() ===
                                        field?.state?.toLowerCase()
                                      ) {
                                        const hasMatchingCities = allCityList[
                                          state
                                        ].some(
                                          (c) =>
                                            c.name.toLowerCase() ===
                                            field?.city?.toLowerCase()
                                        );
                                        return hasMatchingCities
                                          ? field?.city
                                          : "-";
                                      }
                                      return null;
                                    })}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <select
                                      className="form-select select-dropdown custom-input"
                                      name="city_id"
                                      onChange={(event, i) =>
                                        handleSelectCityChange(index, event)
                                      }
                                      style={{ minWidth: "150px" }}
                                      disabled={field.state_id == 0}
                                    >
                                      <option value="">
                                        {isCityLoading
                                          ? t("Loading...")
                                          : t("Select city")}
                                      </option>
                                      {Object.keys(allCityList).map((state) => {
                                        if (
                                          state.toLowerCase() ==
                                          field?.state?.toLowerCase()
                                        ) {
                                          return (
                                            <>
                                              {allCityList[state].map(
                                                (city) => (
                                                  <option
                                                    key={city.id}
                                                    value={city.id}
                                                    selected={
                                                      city.name.toLowerCase() ===
                                                      field?.city?.toLowerCase()
                                                    }
                                                  >
                                                    {city.name}
                                                  </option>
                                                )
                                              )}
                                            </>
                                          );
                                        }
                                        return null;
                                      })}
                                    </select>
                                  </div>
                                </>
                              )}
                            </td>
                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.pinCode)
                                    ? field.pinCode
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="number"
                                      value={field.pinCode}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("PinCode")}
                                      name="pinCode"
                                      className={`form-control custom-input ${
                                        fieldError && "input-error"
                                      }`}
                                    />
                                    {fieldError &&
                                      field.pinCode !== "" &&
                                      field.pinCode !== undefined &&
                                      Util.Zip_regex.test(field.pinCode) ===
                                        false && (
                                        <span
                                          style={{ width: "150px" }}
                                          className="text-danger"
                                        >
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                          />
                                          {t(
                                            "Please enter valid zip-code(i.e: 12345 or 12345-2563)"
                                          )}
                                        </span>
                                      )}
                                  </div>
                                </>
                              )}
                            </td>
                            <td
                              style={{
                                verticalAlign: `${isEditing ? "top" : ""}`,
                              }}
                            >
                              {!isEditing ? (
                                <div className="d-flex align-items-center gap-2">
                                  <i
                                    className="fa fa-pencil"
                                    aria-hidden="true"
                                    onClick={() => {
                                      handleEditClick(index);
                                    }}
                                  ></i>
                                  <div className="dropdown">
                                    <button
                                      // className="btn btn-lg custom-primary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      style={{ backgroundColor: "#fff" }}
                                    >
                                      <i
                                        className="fa fa-ellipsis-h"
                                        aria-hidden="true"
                                      ></i>
                                    </button>

                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() =>
                                            handleRemoveField(index)
                                          }
                                        >
                                          {t("Delete")}
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              ) : (
                                <div style={{ marginTop: "25%" }}>
                                  <i
                                    className="fa fa-check mr-3 text-success"
                                    aria-hidden="true"
                                    onClick={() => {
                                      handleCancelClick(index);
                                    }}
                                  ></i>
                                  <i
                                    className="fa fa-times text-danger"
                                    aria-hidden="true"
                                    onClick={() => {
                                      handleCancelClick(index);
                                    }}
                                  ></i>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      )}
      <ExitAlert
        visible={alertModal}
        onClose={() => setIsAlertModal(false)}
        onOk={() => {
          setTriggerExit((obj) => ({
            ...obj,
            onOk: true,
          }));
          setIsAlertModal(false);
        }}
      />
    </div>
  );
}
export default ToastHOC(BulkStudent);
