import React from "react";

class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      active: false,
    };
  }
  componentDidMount() {}

  onToggleMenu = async () => {
    await this.setState({
      toggleMenu: !this.state.toggleMenu,
    });
    const { toggleMenu } = this.state;
    if (!toggleMenu) {
      document.body.classList.remove("layout-fullwidth");
    } else {
      document.body.classList.add("layout-fullwidth");
    }
  };

  render() {
    const { HeaderText, Breadcrumb } = this.props;
    return (
      <div className="block-header">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <ul className="breadcrumb">
              {Breadcrumb.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="breadcrumb-item active"
                    style={{
                      cursor: index !== Breadcrumb.length - 1 && "pointer",
                    }}
                  >
                    <a
                      style={{
                        fontSize: "14px",
                        color:
                          index === Breadcrumb.length - 1
                            ? "#4B5563"
                            : "#f07a80",

                        fontWeight:
                          index === Breadcrumb.length - 1 ? "500" : "600",
                      }}
                      onClick={item.navigate}
                    >
                      {item.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default PageHeader;
