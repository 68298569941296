import React, { useEffect, useState } from "react";
import { Rings } from "react-loader-spinner";
import ApiCalling from "../../../../network/ApiCalling";
import { useTranslation } from "react-i18next";
import Util from "../../../../Util";
import ToastHOC from "../../../HOC/ToastHOC";
import { useHistory } from "react-router-dom";
import Loader from "../../../../components/common/Loader";

function StaffResetPassword(props) {
  const history = useHistory();
  const userId = props.userId;
  const { t } = useTranslation();
  const { setIsToast } = props;
  const pendingToast = props.props.location?.pendingToast;
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");

  const [newPassError, setNewPassError] = useState("");
  const [confirmNewPassError, setConfirmNewPassError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleNewPassChange = (e) => {
    setNewPass(e.target.value);
    setNewPassError("");
  };
  const handleConfirmChange = (e) => {
    setConfirmNewPass(e.target.value);
    setConfirmNewPassError("");
  };

  const handlePasswordUpdate = () => {
    setNewPassError("");
    setConfirmNewPassError("");

    let error = false;

    if (newPass == "") {
      setNewPassError(t("NewPassword is required"));
      error = true;
    } else if (Util.password(newPass) === false) {
      setNewPassError(t("password must be at least 5 characters long"));
      error = true;
    }

    if (confirmNewPass == "") {
      setConfirmNewPassError(t("Confirm NewPassword is required"));
      error = true;
    } else if (Util.password(confirmNewPass) === false) {
      setConfirmNewPassError(t("password must be at least 5 characters long"));
      error = true;
    }
    if (newPass !== confirmNewPass) {
      setConfirmNewPassError(t("password must be same as new password"));
      error = true;
    }

    if (!error) {
      setIsLoading(true);
      const data = {
        user_id: props.userId,
        new: newPass,
      };
      ApiCalling.apiCallBodyDataPost("user/password-change-force", data)
        .then((res) => {
          if (res.data.status === true) {
            setIsLoading(false);
            history.push({
              pathname: "/staff",
              pendingToast: res.data.message,
            });
            setNewPass("");
            setConfirmNewPass("");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
 
  useEffect(() => {
    if (!userId) {
      history.push("/staff");
    }
  }, [userId]);
  useEffect(() => {
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);
  return (
    <div>
      <div className="">
        <div className="school-details my-4">
          <div className="p-4">
            {!isLoading ? (
              <div className="body">
                <div className="medium-large-text text-start font-color-primary">
                  {t("Change Password")}
                </div>
                <div className="row text-start mt-2 ">
                  <div className="col-6 col-md-6">
                    <div className="form-group">
                      <label>{t("New Password")}</label>
                      <input
                        className={`form-control custom-input ${
                          newPassError.length > 0 ? "input-error" : ""
                        }`}
                        placeholder={`${t("New Password")}`}
                        type="password"
                        value={newPass}
                        onChange={handleNewPassChange}
                      />
                      {newPassError.length > 0 && (
                        <span className="text-danger">
                          <i
                            className="fa fa-exclamation-circle mr-2"
                            aria-hidden="true"
                          ></i>
                          {newPassError}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label>{t("Confirm New Password")}</label>
                      <input
                        className={`form-control custom-input ${
                          confirmNewPassError.length > 0 ? "input-error" : ""
                        }`}
                        placeholder={`${t("Confirm New Password")}`}
                        type="password"
                        value={confirmNewPass}
                        onChange={handleConfirmChange}
                      />
                      {confirmNewPassError.length > 0 && (
                        <span className="text-danger">
                          <i
                            className="fa fa-exclamation-circle mr-2"
                            aria-hidden="true"
                          ></i>
                          {confirmNewPassError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="text-right">
                    <button
                      className="btn custom-primary"
                      type="button"
                      onClick={handlePasswordUpdate}
                    >
                      {t("Reset Password")}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(StaffResetPassword);
