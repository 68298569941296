import React, { useEffect, useState } from "react";
import { Rings } from "react-loader-spinner";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import TagButton from "../TagButton";
import { greenTagStyle, orangeTagStyle, redTagStyle } from "../TagButtonStyle";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import GeneratePayment from "./AddPayment";
import PaymentView from "../../ParentsDashboard/Billing/PaymentView";
import Loader from "../../../components/common/Loader";

export default function AllCredits(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const school = Util.getSelectedSchool();

  const student = props?.student;
  const studentId = props?.student?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [allCreditList, setAllCreditList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(5);
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);
  const [paymentId, setPaymentId] = useState(0);

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const getAllCredits = () => {
    setIsLoading(true);
    const data = {
      student_id: studentId,
      page: currentPage,
      size: recordPerPage,
    };
    ApiCalling.apiCallBodyDataPost("payment/student", data)
      .then((res) => {
        if (res.data) {
          setIsLoading(false);
          setAllCreditList(res.data.response);
          setTotalPages(res.data.total);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (Util.isValidData(studentId)) {
      getAllCredits();
    } else {
      history.push("/billing-plan-list");
    }
  }, [currentPage, recordPerPage, studentId]);

  
  return (
    <div className="container-fluid mt-4">
      {!isLoading ? (
        <table
          className="table"
          style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
        >
          <thead style={{ backgroundColor: "#e5e7eb" }}>
            <tr>
              <th>{t("Payer")}</th>
              <th>{t("Date")}</th>
              <th>{t("Status")}</th>
              <th>{t("Invoices")}</th>
              <th>{t("Amount")}</th>
              <th>{t("Balance")}</th>
              <th></th>
            </tr>
          </thead>
          {allCreditList.length > 0 ? (
            <tbody>
              {allCreditList.map((c, index) => {
                const invoice_settled_ids = c.invoice_settled.map(
                  (c) => c.invoice_id
                );

                const invoice_settled = invoice_settled_ids.map((id) => {
                  return Util.invoiceIdFormat(id);
                });

                return (
                  <>
                    <tr key={index}>
                      <td>
                        {c.payment_method}
                        <div className="small-text">
                          {t("By")} {c.User.firstName + " " + c.User.lastName}
                        </div>
                      </td>
                      <td>
                        {moment(c.date).format(
                          Util.getMomentDateTimeFormat(school.date_format)
                        )}
                      </td>
                      <td>
                        <TagButton
                          style={"btn btn-secondary"}
                          size={"150px"}
                          title={Util.getPaymentStatus(c.status)}
                          buttonStyle={
                            c.status == 0
                              ? redTagStyle
                              : c.status == 1
                              ? orangeTagStyle
                              : greenTagStyle
                          }
                          icon="fa fa-circle"
                          iconSize="6px"
                        />
                      </td>
                      <td>{Util.getListDisplay(invoice_settled, 2, ", ")}</td>
                      <td>{Util.amountFormat(c.amount)}</td>
                      <td>{Util.balanceFormat(c.not_used_amount)}</td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-lg custom-primary "
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ color: "#ffffff", padding: "5px, 10px" }}
                          >
                            {t("Action")}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  setIsVisiblePayment(true);
                                  setPaymentId(c.id);
                                }}
                              >
                                {t("View")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          ) : (
            <tfoot>
              <tr>
                <td colSpan={7}>{t("Not Found!")}</td>
              </tr>
            </tfoot>
          )}
        </table>
      ) : (
        <Loader />
      )}
     
      <PaymentView
        show={isVisiblePayment}
        onClose={() => {
          setIsVisiblePayment(false);
        }}
        PaymentId={paymentId}
      />
      <div className="d-flex justify-content-end mt-2">
        <Stack spacing={2} direction="row" alignItems="center">
          <div
            className={`link-button ${currentPage == 1 ? "disabled" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              handlePreviousClick();
            }}
            style={{ cursor: "pointer" }}
          >
            <KeyboardArrowLeftIcon />
            <span className="basic-bold-text" >
              {t("Previous")}
            </span>
          </div>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, page) => {
              setCurrentPage(page);
            }}
            size="small"
            hideNextButton
            hidePrevButton
          />
          <div
            className={`link-button ${
              currentPage == totalPages ? "disabled" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              handleNextClick();
            }}
            style={{ cursor: "pointer" }}
          >
            <span className="basic-bold-text" >
              {t("Next")}
            </span>
            <KeyboardArrowRightIcon />
          </div>
        </Stack>
      </div>
    </div>
  );
}
