import React, { useEffect, useState } from "react";
import "../../../screens/Dashbord/DashboardCustom.css";
import "react-phone-number-input/style.css";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import ActivityCard from "../../../components/ActivityCard/ActivityCard";
import { useTranslation } from "react-i18next";

const UserFeed = (props) => {
  const { t } = useTranslation();
  const StudentId = props.props.location.studentId;
  const user = Util.getUser();
  const [activityList, setActivityList] = useState([]);
  const [staff, setStaff] = useState(false);
  const ActivityListById = () => {
    const data = {
      page_size: 10,
      page_number: 1,
    };
    ApiCalling.apiCallBodyDataPost(
      `activity/getAllByStudentId/${StudentId}`,
      data
    ).then((res) => {
      if (Util.isValidData(res.data)) {
        setActivityList(res.data);
        setStaff(true);
      }
    });
  };

  const handleLike = (id) => {
    ApiCalling.apiCallBodyDataPost(`activity/like/${id}`)
      .then((res) => {
        let f = [];
        activityList.map((data, index) => {
          if (data.id === id) {
            data.ActivityLike.push({ created_by: user.id });
          }
          f.push(data);
        });
        setActivityList(f);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDisLike = (id) => {
    ApiCalling.apiCallDelete(`activity/Unlike/${id}`)
      .then((res) => {
        let f = [];
        activityList.map((data) => {
          if (data.id === id) {
            data.ActivityLike.map((like, index) => {
              if (like.created_by === user.id) {
                data.ActivityLike.splice(index, 1);
              }
            });
          }
          f.push(data);
        });
        setActivityList(f);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (StudentId > 0) {
      ActivityListById();
    }
  }, []);
  return (
    <>
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        {activityList.length > 0 ? (
          <>
            <div className="container d-wrapper">
              <div className="m-4">
                {activityList.map((data, index) => {
                  return (
                    <div key={index}>
                      <ActivityCard
                        staff={staff}
                        activity={data}
                        likeOnClick={() => {
                          handleLike(data.id);
                        }}
                        dislikeOnClick={() => {
                          handleDisLike(data.id);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
            <div className="col-lg-12" style={{paddingTop:"20%",paddingBottom:"20%"}}>
                            <p className="text-center text-secondary">
                              {t("No NewsFeed found")} :-(
                            </p>
                          </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserFeed;
