export const IS_TOGGLE = "IS_TOGGLE";

export const Toggle = (data) => (dispatch) => {
  dispatch({
    type: IS_TOGGLE,
    payload: data,
  });
};

export const SET_ACADEMIC_YEAR = "SET_ACADEMIC_YEAR";
export const SET_STUDENT_ID = "SET_STUDENT_ID ";

export const setAcademicYear = (academicYear) => ({
  type: SET_ACADEMIC_YEAR,
  payload: academicYear,
});

export const saveStudentId = (studentId) => ({
  type: SET_STUDENT_ID,
  payload: studentId,
});