import React, { useEffect, useState } from "react";
import "../../../screens/Dashbord/DashboardCustom.css";
import "react-phone-number-input/style.css";
import ApiCalling from "../../../network/ApiCalling";
import ToastHOC from "../../HOC/ToastHOC";
import AddContact from "./AddContact";
import CopyToClipboard from "react-copy-to-clipboard";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/common/Loader";

const UserContactDetails = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setIsToast } = props;
  const studentId = props.props.location.studentId;
  const [studentName, setStudentName] = useState("");
  const [invite, setInvite] = useState("");
  const [isVisibleAddContact, setIsVisibleAddContact] = useState(false);
  const [parentData, setParentData] = useState([]);
  const [contact, setContact] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    ApiCalling.apiCallDelete(`parent/${deleteId}`)
      .then((res) => {
        setIsToast(t("Contact Deleted Successfully"));
        setShowDeleteConfirmation(false);
        parentList();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getStudentData = async () => {
    ApiCalling.apiCallParamsGet("student/" + studentId).then((res) => {
      setStudentName(res.data.first_name + " " + res.data.last_name);
      setInvite(res.data.invitee);
    });
  };
  const parentList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("parent/" + studentId).then((res) => {
      setIsLoading(false);
      setParentData(res.data);
    });
  };

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  useEffect(() => {
    getStudentData();
    parentList();
  }, []);

 
  return (
    <>
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <AddContact
          isVisible={isVisibleAddContact}
          studentId={studentId}
          contact={contact}
          studentName={studentName}
          onSave={() => {
            setIsVisibleAddContact(false);
            parentList();
            setIsToast(t("Contact Saved Successfully"));
          }}
          onClose={() => {
            setIsVisibleAddContact(false);
          }}
        />
        <div>
          <div className="container-fluid">
            {!isLoading ? (
              <div className="row">
                <div>
                  <div className="col-lg-12">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h6>
                        {studentName}'s {t("unique parent invite code")}:&nbsp;
                        <CopyToClipboard text={invite} onCopy={onCopyText}>
                          <div
                            className="copy-area"
                            style={{ display: "inline-block" }}
                          >
                            <strong>{invite}</strong>
                            <i className="fa fa-copy ml-2"></i>
                            {isCopied ? (
                              <span className="text-muted mx-4">
                                {t("Copied")}!
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </CopyToClipboard>
                      </h6>
                      <button
                        className={`${
                          Util.hasPermission("STUDENT_UPDATE")
                            ? "btn custom-primary mb-3"
                            : "no-permission"
                        }`}
                        onClick={() => {
                          setContact(null);
                          setIsVisibleAddContact(true);
                        }}
                      >
                        {t("Add Contact")}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    {parentData.length > 0 && (
                      <>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> {t("Name")} </th>
                            <th> {t("Email")} </th>
                            <th> {t("Phone")} </th>
                            <th> {t("Invitation status")} </th>
                            <th> {t("Relation")} </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {parentData.map((value, index) => (
                            <tr key={index}>
                              <th>{index + 1}</th>
                              <th>
                                <a
                                  onClick={(e) => {
                                    e.preventDefault();
                                    // setContact(value);
                                    // setIsVisibleAddContact(true);
                                    history.push({
                                      pathname: "/edit-contact",
                                      ParentId: value.id,
                                      studentName: studentName,
                                    });
                                  }}
                                >
                                  {value.User.firstName} {value.User.lastName}{" "}
                                  {value.is_primary && (
                                    <>
                                      <OverlayTrigger
                                        placement="right"
                                        overlay={
                                          <Tooltip id="right">
                                            {t("Primary & Emergency Contact")}
                                          </Tooltip>
                                        }
                                      >
                                        <i
                                          className="fa fa-user"
                                          aria-hidden="true"
                                        ></i>
                                      </OverlayTrigger>
                                    </>
                                  )}
                                </a>
                              </th>
                              <td> {value.User.email} </td>
                              <td> {value.User.mobile} </td>
                              <td>
                                {value.User.email_verified ? (
                                  t("Registered")
                                ) : (
                                  <span className="text-danger">
                                    {t("Not registered yet")}
                                  </span>
                                )}
                              </td>
                              <td> {value.StudentParentRelationType.type} </td>
                              <td>
                                <a
                                  className="pl-5"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowDeleteConfirmation(true);
                                    setDeleteId(value.id);
                                  }}
                                >
                                  <i className="fa fa-trash-o text-danger"></i>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </>
                    )}
                  </table>
                </div>
                <Modal
                  size="sm"
                  animation={true}
                  centered={true}
                  dialogClassName="modal-view"
                  show={showDeleteConfirmation}
                >
                  <Modal.Body>
                    {t(
                      "The Relation between student and user will be removed permanently"
                    )}
                    .
                    <br />
                    {t("User will not be deleted")}.<br />
                    {t("Are you sure")}?
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="btn btn-danger" onClick={handleDelete}>
                      {t("Yes")}
                    </button>
                    <button
                      className="btn btn-light"
                      onClick={() => {
                        setShowDeleteConfirmation(false);
                      }}
                    >
                      {t("No")}
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ToastHOC(UserContactDetails);
