export const PaymentStatus = Object.freeze({
    USED: 2,
    UNUSED: 0,
    PARTIAL_USED: 1,
  });

  export const InvoiceStatus = Object.freeze({
    PAID: 2,
    UNPAID: 0,
    PARTIAL_PAID: 1,
  }); 

  export const StudentStatus = Object.freeze({
    All: "all",
    ACTIVE: "true",
    INACTIVE: "false",
  });
