import Const from "./Const";
import { Avatar } from "@mui/material";
import { imageMyProfile } from "./screens/CommonImage";
import secureLocalStorage from "react-secure-storage";
import i18next from "i18next";
import {
  NoteAltOutlined,
  AirlineSeatFlatOutlined,
  RestaurantOutlined,
  StreetviewOutlined,
  WcOutlined,
  WorkspacePremiumOutlined,
  HealingOutlined,
  MedicationOutlined,
  DirectionsRunOutlined,
  MonitorHeartOutlined,
  WrongLocation,
} from "@mui/icons-material";
import moment from "moment/moment";

const email_regex = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const Zip_regex = /^\d{5}([-]|\s*)?(\d{4})?$/;
const password = (data) => {
  if (data.length < 5) {
    return false;
  } else {
    return true;
  }
};

const studentIdFormat = (id) => {
  let formattedId = id.toString().padStart(6, "0");
  return formattedId;
};
const staffIdFormat = (id) => {
  let formattedId = id.toString().padStart(5, "0");
  return formattedId;
};
const invoiceIdFormat = (id) => {
  let formattedId = id.toString().padStart(6, "0");
  return `Inv #${formattedId}`;
};

const calculateAge = (selectedDate) => {
  const today = new Date();
  const birthDate = new Date(selectedDate);

  let ageYears = today.getFullYear() - birthDate.getFullYear();
  let ageMonths = today.getMonth() - birthDate.getMonth();

  if (
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() &&
      today.getDate() < birthDate.getDate())
  ) {
    ageYears--;
    ageMonths = 12 - birthDate.getMonth() + today.getMonth();
  }

  if (today.getDate() < birthDate.getDate()) {
    ageMonths--;
  }

  return { years: ageYears, months: ageMonths };
};
const getRegisteredUser = () => {
  try {
    const user = secureLocalStorage.getItem(Const.KEY_REGISTERED_USER);
    if (user !== undefined) {
      return JSON.parse(user);
    }
  } catch (error) {}
  //return JSON.parse(localStorage.getItem(Const.KEY_USER));
};
const saveRegisteredUser = (data) => {
  try {
    secureLocalStorage.setItem(Const.KEY_REGISTERED_USER, JSON.stringify(data));
  } catch (error) {}
  //localStorage.setItem(Const.KEY_USER, JSON.stringify(data));
};
const getForgotPasswordUser = () => {
  try {
    const user = secureLocalStorage.getItem(Const.KEY_FORGOT_PASSWORD_USER);
    if (user !== undefined) {
      return JSON.parse(user);
    }
  } catch (error) {}
  //return JSON.parse(localStorage.getItem(Const.KEY_USER));
};
const saveForgotPasswordUser = (data) => {
  try {
    secureLocalStorage.setItem(
      Const.KEY_FORGOT_PASSWORD_USER,
      JSON.stringify(data)
    );
  } catch (error) {}
  //localStorage.setItem(Const.KEY_USER, JSON.stringify(data));
};

const saveUser = (data) => {
  try {
    secureLocalStorage.setItem(Const.KEY_USER, JSON.stringify(data));
  } catch (error) {}
  //localStorage.setItem(Const.KEY_USER, JSON.stringify(data));
};

const getUser = () => {
  try {
    const user = secureLocalStorage.getItem(Const.KEY_USER);
    if (user !== undefined) {
      return JSON.parse(user);
    }
  } catch (error) {}
  //return JSON.parse(localStorage.getItem(Const.KEY_USER));
};

const removeUser = () => {
  try {
    secureLocalStorage.removeItem(Const.KEY_USER);
  } catch (error) {}
  //localStorage.removeItem(Const.KEY_USER);
  removeSelectedSchool();
  removeRoleInSelectedSchool();
  removeParentMode();
  removeActiveAcademicYear();
  // removeSelectedActiveYear();
};

const saveParentMode = (flag) => {
  try {
    secureLocalStorage.setItem(Const.KEY_IS_PARENT_MODE, flag);
  } catch (error) {}
  //localStorage.setItem(Const.KEY_IS_PARENT_MODE, flag);
};

const getParentMode = () => {
  try {
    return secureLocalStorage.getItem(Const.KEY_IS_PARENT_MODE) == true;
  } catch (error) {}
  //return localStorage.getItem(Const.KEY_IS_PARENT_MODE) == 'true';
};

const removeParentMode = () => {
  try {
    secureLocalStorage.removeItem(Const.KEY_IS_PARENT_MODE);
  } catch (error) {}
  //localStorage.removeItem(Const.KEY_IS_PARENT_MODE);
};
const saveAcadmicYear = (data) => {
  // console.log("save data", JSON.stringify(data));
  try {
    secureLocalStorage.setItem(Const.KEY_ACADEMIC_YEAR, JSON.stringify(data));
  } catch (error) {}
  //localStorage.setItem(Const.KEY_USER, JSON.stringify(data));
};

const getAcadmicYear = () => {
  try {
    const year = secureLocalStorage.getItem(Const.KEY_ACADEMIC_YEAR);
    // console.log("get data", year);
    if (year !== undefined) {
      return JSON.parse(year);
    }
  } catch (error) {}
  //return JSON.parse(localStorage.getItem(Const.KEY_USER));
};
const removeActiveAcademicYear = () => {
  try {
    secureLocalStorage.removeItem(Const.KEY_ACADEMIC_YEAR);
    // console.log("Academic year removed");
    // console.log("xx", getAcadmicYear());
  } catch (error) {
    console.error("Error removing academic year data:", error);
  }
};
const getSelectedYear = () => {
  try {
    const year = secureLocalStorage.getItem("selectedActive");
    // console.log("get data", year);
    if (year !== undefined) {
      return JSON.parse(year);
    }
  } catch (error) {}
  //return JSON.parse(localStorage.getItem(Const.KEY_USER));
};
const saveSelectedYear = async (selectedId) => {
  try {
    await secureLocalStorage.setItem(
      "selectedActive",
      JSON.stringify(selectedId)
    );
    console.log("Successfully saved selected  year:", selectedId);
  } catch (error) {}
};

const removeSelectedActiveYear = async () => {
  try {
    await secureLocalStorage.removeItem("selectedActive");
    // console.log("removed selectedActive");
    // const data = await getSelectedYear();
    // console.log("After removal, getSelectedYear returned:", data);
  } catch (error) {}
};

const saveSelectedSchool = (data) => {
  try {
    secureLocalStorage.setItem(Const.KEY_SELECTED_SCHOOL, JSON.stringify(data));
    localStorage.setItem("selectedLanguage", data.lng);
    i18next.changeLanguage(data.lng);
    // document.body.dir = data.lng === "ar" ? "rtl" : "ltr" || "ltr";
  } catch (error) {}
  //localStorage.setItem(Const.KEY_SELECTED_SCHOOL, JSON.stringify(data));
};

const getSelectedLanguage = () => {
  try {
    const lng = localStorage.getItem("selectedLanguage");
    if (lng !== undefined) {
      return lng;
    }
  } catch (error) {}
};

const getSelectedSchool = () => {
  try {
    const school = secureLocalStorage.getItem(Const.KEY_SELECTED_SCHOOL);
    if (school !== undefined) {
      return JSON.parse(school);
    }
  } catch (error) {}
  //if(localStorage.getItem(Const.KEY_SELECTED_SCHOOL) != 'undefined')
  // return JSON.parse(localStorage.getItem(Const.KEY_SELECTED_SCHOOL));
};

const saveSelectedStudent = (data) => {
  try {
    secureLocalStorage.setItem(
      Const.KEY_IS_SELECTED_STUDENT,
      JSON.stringify(data)
    );
    localStorage.setItem("selected_student", data);
  } catch (error) {}
};

const getSelectedStudent = () => {
  try {
    const student = secureLocalStorage.getItem(Const.KEY_IS_SELECTED_STUDENT);
    if (student !== undefined) {
      return JSON.parse(student);
    }
  } catch (error) {}
};

const removeSelectedSchool = () => {
  try {
    secureLocalStorage.removeItem(Const.KEY_SELECTED_SCHOOL);
  } catch (error) {}
  //localStorage.removeItem(Const.KEY_SELECTED_SCHOOL);
};

const saveRoleInSelectedSchool = (data) => {
  try {
    secureLocalStorage.setItem(
      Const.KEY_ROLE_IN_SELECTED_SCHOOL,
      JSON.stringify(data)
    );
  } catch (error) {}
  // localStorage.setItem(Const.KEY_ROLE_IN_SELECTED_SCHOOL, JSON.stringify(data));
};

const getRoleInSelectedSchool = () => {
  try {
    const role = secureLocalStorage.getItem(Const.KEY_ROLE_IN_SELECTED_SCHOOL);
    if (role !== undefined) {
      return JSON.parse(role);
    }
  } catch (error) {}
  // return JSON.parse(localStorage.getItem(Const.KEY_ROLE_IN_SELECTED_SCHOOL));
};

const removeRoleInSelectedSchool = () => {
  try {
    secureLocalStorage.removeItem(Const.KEY_ROLE_IN_SELECTED_SCHOOL);
  } catch (error) {}
  //localStorage.removeItem(Const.KEY_ROLE_IN_SELECTED_SCHOOL);
};

const savePermissions = (data) => {
  localStorage.setItem(Const.KEY_PERMISSIONS, JSON.stringify(data));
};

const getPermissions = () => {
  return JSON.parse(localStorage.getItem(Const.KEY_PERMISSIONS));
};

const removePermissions = () => {
  localStorage.removeItem(Const.KEY_PERMISSIONS);
};

const hasPermission = (permission) => {
  try {
    const permissionsList = getPermissions();
    if (isValidArray(permissionsList)) {
      return permissionsList.includes(permission);
    }
  } catch (e) {
    return false;
  }
};

const isValidData = (data) => {
  if (data === undefined) {
    return false;
  }
  if (data === null) {
    return false;
  }
  if (data === "") {
    return false;
  }
  return true;
};

const isValidArray = (array) => {
  if (Array.isArray(array)) {
    return true;
  } else {
    return false;
  }
};

const isArrayNotEmpty = (array) => {
  if (array.length > 0) {
    // console.log("true");
    return true;
  }
  // console.log("false");
  return false;
};

function getUserAvatar(user) {
  if (user.avatar && user.avatar.length > 0) {
    return (
      <img
        src={imageMyProfile + user.avatar}
        className="rounded-circle user-photo"
        style={{ width: "40px", height: "40px" }}
        alt=""
      />
    );
  } else {
    return (
      <Avatar aria-label="recipe">
        {user.firstName.toUpperCase()[0] + user.lastName.toUpperCase()[0]}
      </Avatar>
    );
  }
}

function getUserAvatarW(user, strSize) {
  if (user.avatar && user.avatar.length > 0) {
    return (
      <img
        src={imageMyProfile + user.avatar}
        className="rounded-circle user-photo"
        style={{ width: strSize, height: strSize }}
        alt=""
      />
    );
  } else {
    return (
      <Avatar aria-label="recipe" style={{ width: strSize, height: strSize }}>
        {user.firstName.toUpperCase()[0] + user.lastName.toUpperCase()[0]}
      </Avatar>
    );
  }
}

function getMomentDateTimeFormat(format) {
  if (format === "MMMM d, yyyy") {
    return "MMMM D, YYYY";
  } else if (format === "yyyy, MMMM, dd") {
    return "YYYY, MMMM, DD";
  } else if (format === "yyyy-MM-dd") {
    return "YYYY-MM-DD";
  } else if (format === "MM/dd/yyyy") {
    return "MM/DD/YYYY";
  } else if (format === "dd/MM/yyyy") {
    return "DD/MM/YYYY";
  } else if (format === "HH:mm") {
    return "HH:mm";
  } else if (format === "hh:mm a") {
    return "hh:mm a";
  }
}

function getListDisplay(arr, numberToShow, SeparatorString) {
  if (arr.length > 0) {
    if (arr.length == 1) {
      return arr;
    } else if (arr.length <= numberToShow) {
      return arr.join(SeparatorString);
    }
    if (arr.length > numberToShow) {
      return (
        arr.slice(0, numberToShow).join(SeparatorString) +
        SeparatorString +
        "+" +
        (arr.length - numberToShow) +
        ` more`
      );
    }
  }
}

function getGroupListDisplay(arr, numberToShow) {
  if (arr.length === 0) {
    return "you...";
  } else if (arr.length <= numberToShow) {
    return arr.join(",");
  } else {
    return arr.slice(0, numberToShow).join(", ") + ", " + "You...";
  }
}

function getShortString(str, length) {
  return str.length < length ? str : str.slice(0, length) + "...";
}

function getIconActivity(item) {
  switch (item.title) {
    case "Note":
      return (
        <Avatar className="activityicon">
          <NoteAltOutlined />
        </Avatar>
      );
    case "Nap":
      return (
        <Avatar className="activityicon">
          <AirlineSeatFlatOutlined />
        </Avatar>
      );
    case "Observation":
      return (
        <Avatar className="activityicon">
          <StreetviewOutlined />
        </Avatar>
      );
    case "Food":
      return (
        <Avatar className="activityicon">
          <RestaurantOutlined />
        </Avatar>
      );
    case "Potty":
      return (
        <Avatar className="activityicon">
          <WcOutlined />
        </Avatar>
      );
    case "Kudos":
      return (
        <Avatar className="activityicon">
          <WorkspacePremiumOutlined />
        </Avatar>
      );
    case "Incident":
      return (
        <Avatar className="activityicon">
          <HealingOutlined />
        </Avatar>
      );
    case "Meds":
      return (
        <Avatar className="activityicon">
          <MedicationOutlined />
        </Avatar>
      );
    case "Check-Out":
      return (
        <Avatar className="activityicon">
          <DirectionsRunOutlined />
        </Avatar>
      );
    case "Check-In":
      return (
        <Avatar className="activityicon">
          <DirectionsRunOutlined />
        </Avatar>
      );
    case "Health":
      return (
        <Avatar className="activityicon">
          <MonitorHeartOutlined />
        </Avatar>
      );
  }
  return <NoteAltOutlined />;
}

function getInvoiceStatus(item) {
  if (item === 0) {
    return "UNPAID";
  } else if (item === 1) {
    return "PARTIAL_PAID";
  } else if (item === 2) {
    return "PAID ";
  } else {
    return "UNKNOWN STATUS";
  }
}

function getPaymentStatus(item) {
  if (item === 0) {
    return "UNUSED";
  } else if (item === 1) {
    return "PARTIAL_USED";
  } else if (item === 2) {
    return "USED";
  } else {
    return "UNKNOWN STATUS";
  }
}
const amountFormat = (am) => {
  const currency = getSelectedSchool()?.currency;
  if (currency === "USD") {
    let formatted = am.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `$${formatted}`;
  } else if (currency === "AED") {
    let formatted = am.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `AED ${formatted}`;
  } else {
    let formatted = am.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `$${formatted}`;
  }
};
const balanceFormat = (bl) => {
  let colorClass = bl > 0 ? "positive" : bl < 0 ? "negative" : "Zero";
  return <object className={colorClass}>{amountFormat(bl)}</object>;
};
const invoiceFormat = (bl) => {
  let colorClass = bl < 0 ? "positive" : bl > 0 ? "negative" : "Zero";
  return <div className={colorClass}>{amountFormat(bl)}</div>;
};

const getDateDisplay_ChatMessage = (date) => {
  if (date === undefined || date === "") {
    return "";
  }
  const dt = new Date(date);
  // console.log("date to check: ", dt);
  if (isToday(dt)) {
    return moment(dt).format("HH:mm");
  } else if (isDateInThisWeek(dt)) {
    return dt.toLocaleString("en-US", { weekday: "short" });
  } else {
    return moment(dt).format("d MMM");
  }
};

const isToday = (dateToCheck) => {
  // Get today's date
  const today = new Date();

  // Compare the components of the dateToCheck with today's date
  const isSameDate =
    dateToCheck.getDate() === today.getDate() &&
    dateToCheck.getMonth() === today.getMonth() &&
    dateToCheck.getFullYear() === today.getFullYear();

  // Return true if the dateToCheck is today, otherwise return false
  return isSameDate;
};

function isDateInThisWeek(date) {
  const todayObj = new Date();
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  // get first date of week
  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

  // get last date of week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  // if date is equal or within the first and last dates of the week
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

function capitalizeString(inputString) {
  const words = inputString.split(" ");

  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return capitalizedWords.join(" ");
}
export const StatusLead = {
  0: "Pending",
  1: "Converted",
  2: "Closed",
};
export default {
  calculateAge,
  email_regex,
  Zip_regex,
  amountFormat,
  balanceFormat,
  invoiceFormat,
  studentIdFormat,
  staffIdFormat,
  getPaymentStatus,
  invoiceIdFormat,
  getListDisplay,
  getGroupListDisplay,
  getIconActivity,
  getShortString,
  password,
  saveRegisteredUser,
  getRegisteredUser,
  saveForgotPasswordUser,
  getForgotPasswordUser,
  saveUser,
  getUser,
  removeUser,
  saveParentMode,
  getParentMode,
  removeParentMode,
  saveSelectedSchool,
  getSelectedLanguage,
  getSelectedSchool,
  saveSelectedStudent,
  getSelectedStudent,

  removeSelectedSchool,

  removeActiveAcademicYear,
  getSelectedYear,
  saveSelectedYear,
  removeSelectedActiveYear,
  isValidData,
  isValidArray,
  isArrayNotEmpty,
  saveRoleInSelectedSchool,
  getRoleInSelectedSchool,
  removeRoleInSelectedSchool,
  savePermissions,
  getPermissions,
  removePermissions,
  hasPermission,
  getUserAvatar,
  getUserAvatarW,
  getMomentDateTimeFormat,
  getInvoiceStatus,
  getDateDisplay_ChatMessage,
  isToday,
  isDateInThisWeek,
  capitalizeString,
  saveAcadmicYear,
  getAcadmicYear,
};
