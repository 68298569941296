import React, { useEffect } from "react";
import { imageStudentList } from "../../CommonImage";
import { useHistory } from "react-router-dom";
import ChildProfileTab from "./ChildProfileTab";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";

export default function ChildProfile(props) {
  const {t}=useTranslation();
  const history = useHistory();
  const childInfo = props.location.childInfo;
  const calculatedAge = childInfo ? Util.calculateAge(new Date(childInfo?.Student?.dob)) : {}
  useEffect(() => {
    // console.log("childInfo", childInfo);
    if (!childInfo) {
      history.push("/myChild");
    }
  }, [childInfo]);

  return (
    <div>
      <div className="container-fluid">
        <div className="d-flex align-items-center gap-2 mb-3">
          <a href="myChild">
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </a>
          <a href="myChild">My Children</a>
        </div>
        <div className="body card">
          <div className="col-lg col-md-12 p-4">
            <div className="d-wrapper text-center ">
              <h1 style={{ marginBottom: "2%" }}>{t("Profile")}</h1>

              <img
                alt=""
                className="rounded-circle user-photo"
                style={{
                  width: "250px",
                  height: "250px",
                  marginTop: "5px",
                }}
                src={
                  childInfo?.Student?.photo
                    ? imageStudentList + childInfo?.Student?.photo
                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                }
              />

              <div className="mt-3">
                <h4 className="m-b-0">
                  <strong>{childInfo?.Student?.first_name}</strong>{" "}
                  {childInfo?.Student?.last_name}
                </h4>
                <span className="small-text">
                  {calculatedAge.years} {t("years")} {calculatedAge.months} {t("months")} |{" "}
                  {childInfo?.Student?.classroom?.name}
                </span>
              </div>
              <ChildProfileTab childInfo={childInfo} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
