import React, {useEffect, useState} from "react";
import Logo, { LogoWhite } from "../../components/common/Logo"
import {useHistory} from "react-router-dom";
import ApiCalling from "../../network/ApiCalling";
import ToastHOC from "../HOC/ToastHOC";
import {useTranslation} from "react-i18next";
import Util from "../../Util";

function ResetPassword(props) {
    const {t} = useTranslation();
    const history = useHistory();
    const emailAddress = Util.getForgotPasswordUser();
    const pendingToast = props.history.location.pendingToast;
    const {setIsToast} = props;
    const [codeVerify, setCodeVerify] = useState("");
    const [codeErr, setCodeErr] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError("");
    };
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError("");
    };
    const handleCodeVerify = (e) => {
        setCodeVerify(e.target.value);
        setCodeErr("");
    };
    const handleSave = (e) => {
        e.preventDefault();
        setPasswordError("");
        setCodeErr("");
        setConfirmPasswordError("");
        let error = false;
        if (codeVerify === "") {
            setCodeErr(t("code is required"));
            error = true;
        }
        if (password === "") {
            setPasswordError(t("Password is required"));
            error = true;
        } else if (Util.password(password) === false) {
            setPasswordError(t("password must be at least 5 characters long"));
            error = true;
        }
        if (confirmPassword === "") {
            setConfirmPasswordError(t("confirm Password is required"));
            error = true;
        } else if (Util.password(confirmPassword) === false) {
            setConfirmPasswordError(t("password must be at least 5 characters long"));
            error = true;
        }
        if (password !== confirmPassword) {
            setConfirmPasswordError(t("Password & Confirm Password Not Matched"));
        }
        if (!error) {
            const data = {
                email: emailAddress,
                code: codeVerify,
                password: password,
            };
            ApiCalling.apiCallBodyDataPost("user/password-reset", data)
                .then((res) => {
                    if (res.data.status === true) {
                        setIsToast(t(res.data.message));

                        setTimeout(function () {
                            window.location.replace("/");
                        }, 2000);
                    } else {
                        setCodeErr(t(res.data.message));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const sentMail = async () => {
        ApiCalling.apiCallBodyDataGet(`user/forget-password/${emailAddress}`)
            .then((res) => {
                if (res.data.status === true) {
                    setIsToast(res.data.message);
                } else {
                    setCodeErr(t(res.data.message));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        if (pendingToast && pendingToast.length > 0) {
            setIsToast(pendingToast);
        }
    }, []);
    return (
        <div className="theme-cyan">
            <div className="vertical-align-wrap">
                <div className="vertical-align-middle auth-main">
                    <div className="auth-box auth-wrapper">
                        <LogoWhite/>
                        <div className="card">
                            <div className="header">
                                <h1 className="text-center"> {t("Reset Your password")}</h1>
                            </div>
                            <div className="body">
                                <form>
                                    <div className="row ">
                                        <div className="col">
                                            <label>{t("Code sent to")} {emailAddress}</label>
                                            <input className="form-control"
                                                   placeholder={t("Verification code")}
                                                   value={codeVerify}
                                                   onChange={handleCodeVerify}/>
                                            {codeErr.length > 0 && (
                                                <span className="text-danger">{codeErr}</span>
                                            )}
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row ">
                                        <div className="col">
                                            <label>{t("New Password")}</label>
                                            <input type="password"
                                                   className="form-control"
                                                   placeholder={t("Password")}
                                                   value={password}
                                                   onChange={handlePasswordChange}/>
                                            {passwordError.length > 0 && (
                                                <span className="text-danger">{passwordError}</span>
                                            )}
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row ">
                                        <div className="col">
                                            <label>{t("Confirm Password")}</label>
                                            <input type="password"
                                                   className="form-control"
                                                   placeholder={t("Confirm Password")}
                                                   value={confirmPassword}
                                                   onChange={handleConfirmPasswordChange}/>
                                            {confirmPasswordError.length > 0 && (
                                                <span className="text-danger">
                                                    {confirmPasswordError}
                                                </span>
                                            )}
                                            <span className="float-right">
                                                <a href="javascript:void(0)" onClick={sentMail}>
                                                    {t("Resend Code")} ?
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <button
                                                className="btn btn-primary btn-lg btn-block get-started-btn"
                                                type="submit"
                                                onClick={handleSave}>
                                                {t("Reset")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-4"></div>
                                        <div className="col-8">
                                            {t("Know your password?")} <a href="login"> {t("Login")}</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ToastHOC(ResetPassword);
