import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../components/common/Loader";
import Logo, { LogoWhite } from "../../components/common/Logo";
import { useHistory } from "react-router-dom";
import Util from "../../Util";
import ApiCalling from "../../network/ApiCalling";
import { useTranslation } from "react-i18next";
import ToastHOC from "../HOC/ToastHOC";
import Invalid from "../../assets/Icon/invalid.svg";

const VerifyEmailAddress = (props) => {
  const { setIsToast } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [codeVerify, setCodeVerify] = useState("");
  const [codeErr, setCodeErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [getUserDetail,setUserDetail] = useState(null);

  const resendCode = async () => {
    ApiCalling.apiCallParamsGet("user/generate-verification-code/" + getUserDetail.email)
      .then((res) => {
        if (res.data.status == true) {
          setIsToast(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const VerifyCode = async () => {
    setIsLoading(true);
    const data = {
      email: getUserDetail?.email,
      password: getUserDetail.password,
      firstName: getUserDetail.firstName,
      lastName: getUserDetail.lastName,
      parent_staff: parseInt(getUserDetail.roleId),
      verificationCode: codeVerify,
    };

    ApiCalling.apiCallBodyDataPost("user/add-user", data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === true) {
          Util.saveUser(res.data.user);
          if (res.data.user.user_Schools.length > 0) {
            if (res.data.user.user_Schools[0].RoleId > 0) {
              Util.saveSelectedSchool(res.data.user.user_Schools[0].school);
              Util.saveParentMode(false);
              history.push("/Dashboard");
            } else {
              Util.saveParentMode(false);
              history.push("/waitingSchool");
            }
          } else {
            if (getUserDetail.roleId == 1) {
              Util.saveParentMode(false);
              history.push("/JoinSchool");
            } else {
              Util.saveParentMode(true);
              history.push("/ParentsDashboard");
            }
          }
        } else {
          setCodeErr(t(res.data.message));
        }
      })
      .catch((err) => {
        console.log("Error");
      });
  };

  const formHandler = (e) => {
    e.preventDefault();
    if (codeVerify === "") {
      setCodeErr(t("Code is required"));
    } else {
      VerifyCode();
    }
  };

  const changeCode = (e) => {
    e.preventDefault();
    setCodeVerify(e.target.value);
    setCodeErr("");
  };

  useEffect(()=>{
    const getUserData = Util.getRegisteredUser();
    if(getUserData){
      setUserDetail(getUserData)
    }
  },[])

  return (
    <div className="theme-cyan">
      <div className="vertical-align-wrap">
        <div className="vertical-align-middle auth-main">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="auth-box auth-wrapper">
              <LogoWhite />
              <div className="card">
                <div className="header">
                  <h1 className="text-center">
                    {t("Verify your Email Address")}
                  </h1>
                </div>
                <div className="body">
                  <form>
                    <div className="row ">
                      <div className="col">
                        <div className="input-filed">
                          <p>
                            {t("Code sent to")} {getUserDetail?.email}
                          </p>
                          <input
                            value={codeVerify}
                            onChange={changeCode}
                            className={`form-control custom-input ${
                                codeErr.length > 0 && "input-error"
                              }`}
                            placeholder={t("Verification code")}
                          />

                          {codeErr.length > 0 && (
                            <span className={"invalid-error"}>
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {codeErr}
                            </span>
                          )}
                          <span className="float-right" style={{cursor:"pointer"}}>
                            <a onClick={resendCode}>
                              {t("Resend Code")} ?
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <button
                          className="btn custom-primary btn-lg btn-block get-started-btn"
                          type="submit"
                          onClick={formHandler}
                        >
                          {t("Confirm")}
                        </button>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="bottom col-lg-10 offset-3">
                        <span>
                          {t("Already have an account?")}
                          <a className={"space-before-5 link-button"} href="login">
                            {t(" Login")}
                          </a>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(VerifyEmailAddress);
