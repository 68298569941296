import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import * as XLSX from "xlsx/xlsx.mjs";
import ApiCalling from "../../../../network/ApiCalling";
import Loader from "../../../../components/common/Loader";
import moment from "moment/moment";
import Util from "../../../../Util";
import { useTranslation } from "react-i18next";
import Select from "react-dropdown-select";

export default function StudentReport() {
  const { t } = useTranslation();
  const [availableFields, setAvailableFields] = useState([
    { id: 1, name: "Student Id", value: "id" },
    { id: 2, name: "Student First Name", value: "first_name" },
    { id: 3, name: "Student Last Name", value: "last_name" },
    { id: 4, name: "Student DOB", value: "dob" },
    { id: 5, name: "Student Grade", value: "grade.title" },
    { id: 6, name: "Student Class Room", value: "classroom.name" },
    { id: 7, name: "Student Race", value: "Race.race" },
    { id: 8, name: "Student Nationality", value: "Nationality.name" },
    { id: 9, name: "Student Ethnicity", value: "Ethnicity.ethnicity" },
    { id: 10, name: "Student Terms", value: "Term.title" },
    { id: 11, name: "Student Timing", value: "timing.title" },
    { id: 12, name: "Student BirthPlace", value: "Birth_Place.name" },
    { id: 13, name: "Student Insurance Number", value: "insurance_number" },
    { id: 14, name: "Student Insurance Provider", value: "insurance_provider" },
    { id: 15, name: "Student BloodGroup", value: "blood_group" },
    { id: 16, name: "Student Allergies", value: "allergies" },
    { id: 16, name: "Student contact Number", value: "contact" },
  ]);
  const school = Util.getSelectedSchool();

  const [selectedField, setSelectedField] = useState(null);
  const [studentDetails, setStudentDetails] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [gradeData, setGradeData] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [roomData, setRoomData] = useState([]);
  const [roomId, setRoomId] = useState(0);
  const [studentStatus, setStudentStatus] = useState("all");

  const handleNext = () => {
    if (selectedField) {
      setSelectedFields([...selectedFields, selectedField]);
      setAvailableFields(
        availableFields.filter((student) => student.id !== selectedField.id)
      );
      setSelectedField(null);
    }
  };

  const handlePrevious = () => {
    if (selectedFields.length > 0) {
      const lastSelected = selectedFields[selectedFields.length - 1];
      setSelectedFields(selectedFields.slice(0, -1));
      setAvailableFields([...availableFields, lastSelected]);
    }
  };
  const changeGrade = (data) => {
    setGradeId(data[0].id);
  };

  const changeRoom = (data) => {
    setRoomId(data[0].id);
  };
  const changeStatus = (data) => {
    setStudentStatus(data[0].value);
  };
  const statusList = [
    { value: "all", title: "All" },
    { value: true, title: "Active" },
    { value: false, title: "Inactive" },
  ];
  const gradeList = async () => {
    ApiCalling.apiCallParamsGet("grade/list")
      .then((res) => {
        setGradeData(res.data);
      })
      .catch((err) => {
        return err;
      });
  };

  const roomList = async () => {
    ApiCalling.apiCallParamsGet("classroom/list")
      .then((res) => {
        const modifiedClassList = res.data.map((c) => ({
          id: c.id,
          name: c.name,
        }));
        setRoomData(modifiedClassList);
      })
      .catch((err) => {
        return err;
      });
  };

  const getStudentDetails = () => {
    setIsLoading(true);
    const data = {
      grade_id: gradeId,
      isActive: studentStatus,
      classroom_id: roomId,
    };
    ApiCalling.apiCallBodyDataPost(`student/get-multiple-students`, data)
      .then((res) => {
        // console.log("get-multiple-students", res.data);
        setIsLoading(false);
        setStudentDetails(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const exportToExcel = () => {
    const filteredDataForExport = studentDetails.map((student) => {
      const filteredStudent = {};

      selectedFields.forEach((field) => {
        if (field.value.includes(".")) {
          const nestedData = field.value.split(".");
          let nestedValue = student;
          for (const prop of nestedData) {
            nestedValue = nestedValue ? nestedValue[prop] : null;
          }
          filteredStudent[field.value] = nestedValue || "";
        } else {
          if (field.value === "dob" && student[field.value]) {
            filteredStudent[field.value] = moment(student[field.value]).format(
              Util.getMomentDateTimeFormat(school.date_format)
            );
          } else {
            filteredStudent[field.value] = student[field.value];
          }
        }
      });

      return filteredStudent;
    });

    const headers = selectedFields.map((field) => field.name);

    const dataForExport = filteredDataForExport.map((student) =>
      selectedFields.map((field) => student[field.value])
    );

    dataForExport.unshift(headers);
    const worksheet = XLSX.utils.json_to_sheet(dataForExport, {
      skipHeader: true,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Student Report");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const newTab = window.open(url, "_blank");
    newTab.addEventListener("load", () => {
      URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    gradeList();
    roomList();
  }, []);
  useEffect(() => {
    getStudentDetails();
  }, [studentStatus, gradeId, roomId]);
  // const handleFieldSelection = (selectedField) => {
  //   setSelectedFields([...selectedFields, selectedField]);
  //   setAvailableFields(
  //     availableFields.filter((field) => field.id !== selectedField.id)
  //   );
  // };
  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-fluid summary p-2">
        <div className="d-flex flex-wrap align-items-center gap-2 mb-4">
          <div>
            <Select
              className="select-custom"
              options={[{ id: 0, title: "Grade" }, ...gradeData]}
              labelField="title"
              valueField="id"
              values={[{ id: 0, title: "Grade" }]}
              onChange={(values) => changeGrade(values)}
            />
          </div>
          <div>
            <Select
              className="select-custom"
              options={[{ id: 0, name: "Room" }, ...roomData]}
              labelField="name"
              valueField="id"
              values={[{ id: 0, name: "Room" }]}
              onChange={(values) => changeRoom(values)}
            />
          </div>
          <div>
            <Select
              className="select-custom"
              options={[...statusList]}
              labelField="title"
              valueField="value"
              values={[{ value: "all", title: "Student Status" }]}
              onChange={(values) => changeStatus(values)}
            />
          </div>
        </div>
        {!isLoading ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {t("Choose Your Field & Export")}
                    </Typography>
                    <List>
                      {availableFields.map((s, index) => (
                        <ListItem
                          key={s.id}
                          button
                          selected={selectedField && s.id === selectedField.id}
                          onClick={() => setSelectedField(s)}
                        >
                          <ListItemText primary={s.name} />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xs={1}
                container
                alignItems="center"
                justifyContent="center"
              >
                <Grid container direction="column" alignItems="center">
                  <IconButton onClick={handleNext}>
                    <KeyboardDoubleArrowRightIcon />
                  </IconButton>
                  <IconButton onClick={handlePrevious}>
                    <KeyboardDoubleArrowLeftIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item xs={7}>
                <Card>
                  <CardContent>
                    <Typography>
                      {selectedFields.length > 0 ? (
                        <List>
                          {selectedFields.map((field) => (
                            <ListItem key={field.id}>
                              <ListItemText primary={field.name} />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        t("No Field selected")
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <button
              className="btn btn-lg custom-primary m-2 float-end"
              type="button"
              onClick={exportToExcel}
              disabled={selectedFields.length == 0}
            >
              {t("Export")}
            </button>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}
