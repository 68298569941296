import React, { useEffect, useState } from "react";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Select from "react-dropdown-select";

export default function ParentsGetAnnouncement() {
  const { t } = useTranslation();
  const user = Util.getUser();
  const [selectStudentId, SetSelectedStudentId] = useState(0);
  const [Students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [announcementData, setAnnouncementData] = useState([]);

  const handleStudentChange = (e) => {
    SetSelectedStudentId(e.target.value);
    announcementList();
  };
  const announcementList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet(
      `announcement/getMyAnnouncement/${selectStudentId}`
    )
      .then((res) => {
        setAnnouncementData(res.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (user.Parents.length > 0) {
      SetSelectedStudentId(user.Parents[0].Student.id);
      setStudents(user.Parents);
    }
  }, []);
  useEffect(() => {
    if (selectStudentId !== 0) {
      announcementList();
    }
  }, [selectStudentId]);


  return (
    <div>
      <div className="container-fluid">
        <div className="body">
        <div className="row py-3">
          <div className="col-lg-12">
            <div className="heading col-lg-2 float-right mb-2">
              {user.Parents.length > 1 && (
                <select
                  className="form-select"
                  value={selectStudentId}
                  onChange={handleStudentChange}
                >
                  {Students.map((item) => {
                    return (
                      <>
                        <option value={item.Student.id} key={item.Student.id}>
                          {item.Student.first_name}
                          {""} {item.Student.last_name}
                        </option>
                      </>
                    );
                  })}
                </select>
              )}
            </div>
            {/* <div className="col-lg-9">
              <span>{t("Type")}:</span>
              <Select
                className="select-custom"
                options={[{ id: 0, type: "All" }]}
                labelField="type"
                values={[{ id: 0, type: "All" }]}
                valueField="id"
              />
              <span>{t("Grade")}:</span>
              <Select
                className="select-custom"
                options={[{ id: 0, title: "All" }]}
                labelField="title"
                values={[{ id: 0, title: "All" }]}
                valueField="id"
              />
              <span className="ml-3">{t("Room")}:</span>
              <Select
                className="select-custom"
                options={[{ id: 0, name: "All" }]}
                labelField="name"
                values={[{ id: 0, name: "All" }]}
                valueField="id"
              />
            </div> */}
           
              <div className="table-div overflow-hidden mt-2">
              <div className="col-lg-12 p-0 m-0">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{t("Name")} <i className="fa fa-sort ml-1" aria-hidden="true"></i></th>
                      <th scope="col">{t("Description")} <i className="fa fa-sort ml-1" aria-hidden="true"></i></th>
                      <th scope="col">{t("Type")} <i className="fa fa-sort ml-1" aria-hidden="true"></i></th>
                    </tr>
                  </thead>
                  {announcementData.length > 0 ? (
                    <tbody>
                      {announcementData.map((item) => {
                        return (
                          <>
                            <tr key={item.id}>
                              <th>
                                <a>{item.title}</a>
                              </th>
                              <td>{item.description}</td>
                              <td>{item.announcement_type}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tfoot>
                      <tr>
                        <td colSpan={4}>{t("There are no announcements.")}</td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              </div>
              </div>
              </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}
