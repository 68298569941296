import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import ToastHOC from "../../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { studentInsurance } from "../../CommonImage";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function ChildAbout(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;

  const history = useHistory();
  const user = Util.getUser();
  const studentDetails = props.studentDetails;
  const schoolId = props?.props?.childInfo?.Student?.School?.id;

  const [childInfo, setChildInfo] = useState(props?.props?.childInfo);
  const [pickupList, setPickupList] = useState([]);
  const [pickupInfoId, setPickupInfoId] = useState(null);
  const [pickupInfoIdError, setPickupInfoIdError] = useState(0);
  const [phone, setPhone] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [cityId, setCityId] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  const [stateId, setStateId] = useState(0);
  const [countryId, setCountryId] = useState(0);
  const [country, setCountry] = useState([]);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [state, setState] = useState([]);
  const [healthNote, setHealthNote] = useState("");
  const [dietary, setDietary] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [doctorClinic, setDoctorClinic] = useState("");
  const [doctorPhone, setDoctorPhone] = useState("");
  const [doctorMobile, setDoctorMobile] = useState("");
  const [doctorAddress1, setDoctorAddress1] = useState("");
  const [doctorAddress2, setDoctorAddress2] = useState("");
  const [doctorCityId, setDoctorCityId] = useState("");
  const [doctorPinCode, setDoctorPinCode] = useState("");
  const [doctorPinCodeError, setDoctorPinCodeError] = useState("");
  const [doctorStateId, setDoctorStateId] = useState(0);
  const [doctorCountryId, setDoctorCountryId] = useState(0);
  const [doctorState, setDoctorState] = useState([]);
  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [insuranceProvider, setInsuranceProvider] = useState("");
  const [insuranceFile, setInsuranceFile] = useState("");
  const [insuranceURL, setInsuranceURL] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("");
  const [city, setCity] = useState([]);
  const [doctorCity, setDoctorCity] = useState([]);
  const [isInsuranceDelete,setIsInsuranceDelete]=useState(false);

  const pickuplist = async () => {
    ApiCalling.apiCallBodyDataGet("pickup-info/list", schoolId).then((res) => {
      
      setPickupList(res.data);
    });
  };
  const changePickupInfoId = (e) => {
    setPickupInfoId(e.target.value);
  };
  const changePhone = (e) => {
    e.preventDefault();
    setPhone(e.target.value);
  };
  const changeAddress1 = (e) => {
    setAddress1(e.target.value);
  };
  const changeAddress2 = (e) => {
    setAddress2(e.target.value);
  };
  const changeCity = (e) => {
    setCityId(e.target.value);
  };
  const changePinCode = (e) => {
    setPinCode(e.target.value);
    setPinCodeError("");
  };
  const changeState = (e) => {
    setStateId(e.target.value);
  };
  const changeCountry = (e) => {
    setCountryId(e.target.value);
  };
  const changeHealthNote = (e) => {
    setHealthNote(e.target.value);
  };
  const changeDietary = (e) => {
    setDietary(e.target.value);
  };
  const changeDoctorName = (e) => {
    setDoctorName(e.target.value);
  };
  const changeDoctorClinic = (e) => {
    setDoctorClinic(e.target.value);
  };
  // const changeDoctorPhone = (e) => {
  //   setDoctorPhone(e.target.value);
  // };
  // const changeDoctorMobile = (e) => {
  //   setDoctorMobile(e.target.value);
  // };
  const changeDoctorAddress1 = (e) => {
    e.preventDefault();
    setDoctorAddress1(e.target.value);
  };
  const changeDoctorAddress2 = (e) => {
    e.preventDefault();
    setDoctorAddress2(e.target.value);
  };
  const changeDoctorCity = (e) => {
    e.preventDefault();
    setDoctorCityId(e.target.value);
  };
  const changeDoctorState = (e) => {
    e.preventDefault();
    setDoctorStateId(e.target.value);
  };
  const changeDoctorCountry = (e) => {
    e.preventDefault();
    setDoctorCountryId(e.target.value);
  };
  const changeDoctorPinCode = (e) => {
    e.preventDefault();
    setDoctorPinCode(e.target.value);
    setDoctorPinCodeError("");
  };
  const changeInsuranceNumber = (e) => {
    setInsuranceNumber(e.target.value);
  };
  const changeInsuranceProvider = (e) => {
    setInsuranceProvider(e.target.value);
  };
  const changeInsuranceFile = (e) => {
    setInsuranceFile(e.target.files[0]);
    setInsuranceURL(URL.createObjectURL(e.target.files[0]));
    setIsInsuranceDelete(false);
  };
  const countryList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet("country").then((res) => {
     
      setIsCountryLoading(false);
      setCountry(res.data);
    });
  };
  const stateList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${countryId}`).then(
      (res) => {
        
        setIsCountryLoading(false);
        setState(res.data);
      }
    );
  };
  const cityList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      
      setIsCountryLoading(false);
      setCity(res.data);
    });
  };
  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);
  const doctorStateList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${doctorCountryId}`).then(
      (res) => {
        
        setIsCountryLoading(false);
        setDoctorState(res.data);
      }
    );
  };
  const doctorCityList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${doctorStateId}`).then((res) => {
      
      setIsCountryLoading(false);
      setDoctorCity(res.data);
    });
  };
  useEffect(() => {
    if (doctorStateId) {
      doctorCityList();
    }
  }, [doctorStateId]);
  const handlePickupSave = (e) => {
    e.preventDefault();
    setPickupInfoIdError("");
    let error = false;
    if (pickupInfoId === null) {
      setPickupInfoIdError(t("please select pickup"));
      error = true;
    }
    if (!error) {
      ApiCalling.apiCallBodyDataGet(
        `student/edit-Pickup-info/${childInfo?.Student?.id}/${pickupInfoId}`
      )
        .then((res) => {
          if (res.data.status === true) {
            setIsToast(res.data.message);
            ApiCalling.apiCallBodyDataGet(`user/get-user-byId/${user.id}`)
              .then((res) => {
                Util.saveUser(res.data);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  useEffect(() => {
    if (studentDetails) {
      setPhone(
        Util.isValidData(studentDetails.contact) ? studentDetails.contact : ""
      );
      setAddress1(studentDetails.address1);
      setAddress2(studentDetails.address2);
      setCityId(studentDetails.city_id);
      setPinCode(studentDetails.pincode);
      setCountryId(studentDetails.country_id);
      setStateId(studentDetails.state_id);
      setDoctorName(studentDetails.doctor_name);
      setDoctorClinic(studentDetails.doctor_clinic);
      setDoctorPhone(
        Util.isValidData(studentDetails.doctor_contact)
          ? studentDetails.doctor_contact
          : ""
      );
      setDoctorMobile(
        Util.isValidData(studentDetails.doctor_mobile)
          ? studentDetails.doctor_mobile
          : ""
      );
      setDoctorAddress1(studentDetails.doctor_address1);
      setDoctorAddress2(studentDetails.doctor_address2);
      setDoctorCityId(studentDetails.doctor_city_id);
      setDoctorPinCode(studentDetails.doctor_zipcode);
      setDoctorCountryId(studentDetails.doctor_country_id);
      setDoctorStateId(studentDetails.doctor_state_id);
      setDietary(studentDetails.dietary_consideration);
      setHealthNote(studentDetails.health_notes);
      setInsuranceNumber(studentDetails.insurance_number);
      setInsuranceProvider(studentDetails.insurance_provider);
      setInsuranceURL(studentInsurance + studentDetails.insurance);
    }
  }, [studentDetails]);

  const handlePersonalInfoSave = (e) => {
    e.preventDefault();
    setPinCodeError("");
    let error = false;
    if (pinCode && Util.Zip_regex.test(pinCode) === false) {
      setPinCodeError(t("Please enter valid zip-code(i.e: 12345 or 12345-2563)"));
      error = true;
    }
    if(!error){
      const data = {
        id: childInfo.Student.id,
        contact: Util.isValidData(phone) ? phone : "",
        address1: address1,
        address2: address2,
        city_id: cityId,
        pincode: pinCode,
        state_id: stateId,
        country_id: countryId,
      };
      ApiCalling.apiCallBodyDataPost("student/update-student", data, schoolId)
        .then((res) => {
          if (res.data.status == true) {
            history.push({
              pathname: "/myChild",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    
  };

  const handleHealthInfoSave = (e) => {
    e.preventDefault();
    setDoctorPinCodeError("");
    let error = false;
    if (doctorPinCode && Util.Zip_regex.test(doctorPinCode) === false) {
      setDoctorPinCodeError(t("Please enter valid zip-code(i.e: 12345 or 12345-2563)"));
      error = true;
    }
    if (!error) {
      const formData = new FormData();
      formData.append("id", childInfo?.Student?.id);
      formData.append("health_notes", healthNote);
      formData.append("dietary_consideration", dietary);

      formData.append("doctor_name", doctorName);
      if (doctorClinic) {
        formData.append("doctor_clinic", doctorClinic);
      }
      if (doctorPhone) {
        formData.append("doctor_contact", doctorPhone);
      }
      if (doctorMobile) {
        formData.append("doctor_mobile", doctorMobile);
      }
      formData.append("doctor_address1", doctorAddress1);
      formData.append("doctor_address2", doctorAddress2);
      formData.append("doctor_city_id", doctorCityId);
      if (stateId) {
        formData.append("doctor_state_id", doctorStateId);
      }
      if (countryId) {
        formData.append("doctor_country_id", doctorCountryId);
      }
      formData.append("doctor_zipcode", doctorPinCode);

      formData.append("insurance", insuranceFile);
      formData.append("insurance_number", insuranceNumber);
      if (insuranceProvider) {
        formData.append("insurance_provider", insuranceProvider);
      }
      if (isInsuranceDelete) {
        formData.append("isDeleteInsurance", isInsuranceDelete);
      }
      ApiCalling.apiCallBodyDataPost(
        `student/update-health-parameters`,
        formData,
        schoolId
      )
        .then((res) => {
          if (res.data.status === true) {
            history.push({
              pathname: "/myChild",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    pickuplist();
    setPickupInfoId(childInfo?.Student?.pickup_info_id);
    countryList();
  }, []);
  useEffect(() => {
    if (countryId) {
      stateList();
    }
  }, [countryId]);
  useEffect(() => {
    if (doctorCountryId) {
      doctorStateList();
    }
  }, [doctorCountryId]);
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="d-wrapper">
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as="button"
                  variant="link"
                  className="btn btn-link"
                  eventKey="0"
                >
                  <i className="fa fa-car fa-fw mr-3" aria-hidden="true"></i>{" "}
                  Pickup info
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0" className="card-body">
                <Card.Body>
                  <div className="d-flex align-items-center gap-2">
                    <select
                      name=""
                      id=""
                      className="select-dropdown"
                      value={pickupInfoId}
                      onChange={changePickupInfoId}
                    >
                      <option selected disabled value="">
                        {t("Select PickupInfo")}
                      </option>
                      {pickupList.map((pick) => {
                        return (
                          <option key={pick.id} value={pick.id}>
                            {pick.title}
                          </option>
                        );
                      })}
                    </select>

                    <a onClick={handlePickupSave}>
                      <i
                        className="fa fa-floppy-o fa-lg"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                  {pickupInfoIdError.length > 0 && (
                    <span className="text-danger float-left">
                      {pickupInfoIdError}
                    </span>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as="button"
                  variant="link"
                  className="btn btn-link"
                  eventKey="1"
                >
                  <i
                    className="fa fa-id-card fa-fw mr-3"
                    aria-hidden="true"
                  ></i>
                  {t("Personal info")}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1" className="card-body">
                <Card.Body className="text-left">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="p-3">
                        <div className="form-group">
                          {/* <div className="input-filed">
                            <label>{t("Emergency Contact No")}</label>
                            <input
                              className="form-control"
                              type="text"
                              value={phone}
                              onChange={changePhone}
                            />
                          </div> */}
                          <label>{t("Emergency Contact No")}</label>
                          <div className="form-control" tabIndex={0}>
                            <PhoneInput
                            tabIndex="-1"
                              international
                              defaultCountry={defaultCountry}
                              value={phone}
                              onChange={(value) => {
                                setPhone(value);
                              }}
                            />
                          </div>
                          <div className="input-filed">
                            <label>{t("Address 1")}</label>
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Address Line 1"
                              value={address1}
                              onChange={changeAddress1}
                            />
                          </div>
                          <div className="input-filed">
                            <label>{t("Address 2")}</label>
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Address Line 2"
                              value={address2}
                              onChange={changeAddress2}
                            />
                          </div>
                          <div className="input-filed">
                            <label>{t("Zipcode")}</label>
                            <input
                              className="form-control"
                              // placeholder="Pin Code"
                              value={pinCode}
                              onChange={changePinCode}
                            />
                            {pinCodeError.length > 0 && (
                              <span className="text-danger">
                                {pinCodeError}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="p-3">
                        <div className="form-group">
                          <div className="input-filed">
                            <label>{t("Country")}</label>
                            <select
                              className="select-dropdown"
                              value={countryId}
                              onChange={changeCountry}
                            >
                              <option selected value="">
                                {t("Select Country")}
                              </option>

                              {country.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="input-filed">
                            <label>{t("State")}</label>
                            <select
                              className="select-dropdown"
                              value={stateId}
                              onChange={changeState}
                              disabled={!countryId}
                            >
                              <option selected value="">
                                {isCountryLoading
                                  ? t("Loading...")
                                  : t("Select State")}
                              </option>
                              {state.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="input-filed">
                            <label>{t("City")}</label>
                            <select
                              className="select-dropdown"
                              value={cityId}
                              onChange={changeCity}
                              disabled={!stateId}
                            >
                              <option selected value="">
                                {isCountryLoading
                                  ? t("Loading...")
                                  : t("Select city")}
                              </option>
                              {city.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mx-3">
                      <div className="col-lg-12 text-right ">
                        <button
                          className="btn custom-primary btn-lg"
                          style={{ color: "#fff", width: "100px" }}
                          onClick={handlePersonalInfoSave}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as="button"
                  variant="link"
                  className="btn btn-link"
                  eventKey="2"
                >
                  <i
                    className="fa fa-plus-square fa-fw mr-3"
                    aria-hidden="true"
                  ></i>
                  {t("Health info")}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2" className="card-body">
                <Card.Body className="text-left">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="p-3">
                        <div className="input-filed">
                          <label>{t("Doctor Name")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Doctor Name")}
                            value={doctorName}
                            onChange={changeDoctorName}
                          />
                        </div>
                        <div className="input-filed">
                          <label>{t("Doctor Clinic")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Doctor Clinic")}
                            value={doctorClinic}
                            onChange={changeDoctorClinic}
                          />
                        </div>
                        <label>{t("Doctor Phone")}</label>
                        <div className="form-control" tabIndex={0}>
                          <PhoneInput
                          tabIndex="-1"
                            international
                            placeholder={`${t("Doctor Phone")}*`}
                            defaultCountry={defaultCountry}
                            value={doctorPhone}
                            onChange={(value) => {
                              setDoctorPhone(value);
                            }}
                          />
                        </div>

                        {/* <div className="input-filed">
                          <label>{t("Doctor Mobile")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Doctor Mobile")}
                            value={doctorMobile}
                            onChange={changeDoctorMobile}
                          />
                        </div> */}
                        <label>{t("Doctor Mobile")}</label>
                        <div className="form-control" tabIndex={0}>
                          <PhoneInput
                          tabIndex="-1"
                            international
                            placeholder={`${t("Doctor Mobile")}*`}
                            defaultCountry={defaultCountry}
                            value={doctorMobile}
                            onChange={(value) => {
                              setDoctorMobile(value);
                            }}
                          />
                        </div>
                        <div className="input-filed">
                          <label>{t("Address 1")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Address 1")}
                            value={doctorAddress1}
                            onChange={changeDoctorAddress1}
                          />
                        </div>
                        <div className="input-filed">
                          <label>{t("Address 2")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Address 2")}
                            value={doctorAddress2}
                            onChange={changeDoctorAddress2}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="p-3">
                        <div className="input-filed">
                          <label>{t("Country")}</label>
                          <select
                            className="select-dropdown"
                            value={doctorCountryId}
                            onChange={changeDoctorCountry}
                          >
                            <option selected value="">
                              {t("Select Country")}
                            </option>

                            {country.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="input-filed">
                          <label>{t("State")}</label>
                          <select
                            className="select-dropdown"
                            value={doctorStateId}
                            onChange={changeDoctorState}
                            disabled={!doctorCountryId}
                          >
                            <option selected value="">
                              {isCountryLoading
                                ? t("Loading...")
                                : t("Select State")}
                            </option>
                            {doctorState.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="input-filed">
                          <label>{t("City")}</label>
                          <select
                            className="select-dropdown"
                            value={doctorCityId}
                            onChange={changeDoctorCity}
                            disabled={!doctorStateId}
                          >
                            <option selected value="">
                              {isCountryLoading
                                ? t("Loading...")
                                : t("Select city")}
                            </option>
                            {doctorCity.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="input-filed">
                          <label>{t("zipcode")}</label>
                          <input
                            className="form-control"
                            placeholder={t("zipcode")}
                            value={doctorPinCode}
                            onChange={changeDoctorPinCode}
                          />
                          {doctorPinCodeError.length > 0 && (
                            <span className="text-danger">
                              {doctorPinCodeError}
                            </span>
                          )}
                        </div>
                        <div className="input-filed">
                          <label>{t("Special Health Note")}</label>
                          <textarea
                            rows="3"
                            className="form-control auto-height"
                            placeholder={t("Special Health Note")}
                            value={healthNote}
                            onChange={changeHealthNote}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="p-3">
                        <div className="input-filed">
                          <label>{t("Insurance Number")}</label>
                          <div className="form-line">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Insurance Number")}
                              value={insuranceNumber}
                              onChange={changeInsuranceNumber}
                            />
                          </div>
                        </div>
                        <label>{t("insurance")}</label>
                        {insuranceURL === "" ? (
                          <>
                            <div
                              className="custom-file"
                              style={{ marginBottom: "3%" }}
                            >
                              <input
                                type="file"
                                className="custom-file-input"
                                id="customFileLangHTML"
                                onChange={changeInsuranceFile}
                              />
                              <label
                                className="custom-file-label"
                                for="customFileLangHTML"
                                data-browse="choose File"
                              >
                                {t("Choose Insurance File")}
                              </label>
                            </div>
                          </>
                        ) : (
                          <div
                            className="input-filed"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              // gap: 350,
                              alignItems: "center",
                            }}
                          >
                            <a
                              href={insuranceURL}
                              className="btn btn-link"
                              target="_blank"
                            >
                              {t("Download")}
                            </a>
                            <CloseIcon
                              onClick={() => {
                                setInsuranceURL("");
                                setInsuranceFile("");
                                setIsInsuranceDelete(true);
                              }}
                            />
                          </div>
                        )}
                        <div className="form-group">
                        <label>{t("Insurance Provider")}</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder={t("Insurance Provider")}
                            value={insuranceProvider}
                            onChange={changeInsuranceProvider}
                          />
                        </div>
                        <div className="input-filed">
                          <label>{t("Dietary Consideration")}</label>
                          <textarea
                            rows="3"
                            className="form-control auto-height"
                            placeholder={t("Dietary Consideration")}
                            value={dietary}
                            onChange={changeDietary}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mx-3">
                      <div className="col-lg-12 text-right ">
                        <button
                          className="btn custom-primary btn-lg"
                          style={{ color: "#fff", width: "100px" }}
                          onClick={handleHealthInfoSave}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            {/* <Card>
              <Card.Header>
                <Accordion.Toggle
                  as="button"
                  variant="link"
                  className="btn btn-link"
                  eventKey="3"
                >
                  <i className="fa fa-user-plus fa-fw mr-3" aria-hidden="true"></i>
                  {t("Registration info")}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3" className="card-body">
                <Card.Body>{t("Registration info")}</Card.Body>
              </Accordion.Collapse>
            </Card> */}
          </Accordion>
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(ChildAbout);
