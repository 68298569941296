
import React, { useEffect, useState } from "react";

function DotLoader() {
  const [dots, setDots] = useState(".");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length >= 3 ? "." : prevDots + "."));
    }, 500); 

    return () => clearInterval(interval);
  }, []);

  return <div>{dots}</div>;
}

export default DotLoader;