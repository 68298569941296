import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader";
import { Modal, Button } from "react-bootstrap";
import ToastHOC from "../../HOC/ToastHOC";
import ApiCalling from "../../../network/ApiCalling";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import Sort from "../../../assets/Icon/Short.svg";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import Close from "../../../assets/Icon/close.png";
import Loader from "../../../components/common/Loader";

const MealPlans = (props) => {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [mealPlanList, setMealPlanList] = useState([]);
  const [newMealName, setNewMealName] = useState("");
  const [newMealNameError, setNewMealNameError] = useState("");
  const [newMealDescription, setNewMealDescription] = useState("");
  const [editValue, setEditValue] = useState(null);
  const [showMealPlanModal, setShowMealPlanModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [sortColumn, setSortColumn] = useState("MealPlan");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedMealPlanList = [...mealPlanList].sort((a, b) => {
    if (sortColumn === "MealPlan") {
      return sortDirection === "asc"
        ? a.title.localeCompare(b.title)
        : b.title.localeCompare(a.title);
    } else if (sortColumn === "Description") {
      return sortDirection === "asc"
        ? a.description.localeCompare(b.description)
        : b.description.localeCompare(a.description);
    } else if (sortColumn === "Student opted") {
      return sortDirection === "asc"
        ? a.Students.length - b.Students.length
        : b.Students.length - a.Students.length;
    }
    return 0;
  });

  const handleAddClose = () => {
    setShowMealPlanModal(false);
    setNewMealName("");
    setNewMealDescription("");
    setNewMealNameError("");
  };

  const mealHandleSaveAndClose = () => {
    setNewMealNameError("");
    let error = false;
    if (newMealName == "") {
      setNewMealNameError(t("Title is required"));
      error = true;
    }

    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      if (editValue !== null) {
        const data = {
          id: editValue.id,
          title: newMealName,
          modified_by: 1,
          description: newMealDescription,
        };

        ApiCalling.apiCallBodyDataPost("mealplan/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              setIsToast(res.data.message);
              setShowMealPlanModal(false);
              mealplanlist();
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      } else {
        const data = {
          title: newMealName,
          school_id: 1,
          description: newMealDescription,
        };
        ApiCalling.apiCallBodyDataPost("mealplan/add", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              setIsToast(res.data.message);
              setShowMealPlanModal(false);
              mealplanlist();
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      }
    }
  };

  const mealHandleShow = () => {
    setShowMealPlanModal(true);
    setEditValue(null);
    setNewMealName("");
    setNewMealDescription("");
    setNewMealNameError("");
  };

  const mealplanlist = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("mealplan/list")
      .then((res) => {
        setMealPlanList(res.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    mealplanlist();
  }, []);

  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`mealplan/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setIsToast(t("MealPlan Deleted Successfully"));
        setShowDeleteConfirmation(false);
        mealplanlist();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  
  return (
    <>
      <div
        style={{ flex: 1 }}
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        
          <div className="container-fluid">
            <div className="row">
              <div className="header text-right mb-3 px-0">
                <button
                  className={`${
                    Util.hasPermission("MASTERS_ADD")
                      ? "btn custom-primary"
                      : "no-permission"
                  }`}
                  type="button"
                  aria-expanded="false"
                  style={{ color: "#fff", padding: "5px, 10px" }}
                  onClick={mealHandleShow}
                >
                  {t("Register new MealPlan")}
                </button>

                <Modal
                  show={showMealPlanModal}
                  onHide={handleAddClose}
                  animation={true}
                  centered={true}
                  dialogClassName="modal-view"
                  onShow={() => {
                    setIsClickedOnce(false);
                    if (editValue !== null) {
                      setNewMealDescription(editValue.description);
                      setNewMealName(editValue.title);
                    }
                  }}
                >
                  <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                    <h6>
                      {editValue !== null
                        ? t("Edit MealPlan")
                        : t("Register MealPlan")}
                    </h6>

                    <button className="hidden-btn" onClick={handleAddClose}>
                      <img
                        src={Close}
                        className="svg-icon"
                        height={20}
                        width={20}
                      />
                    </button>
                  </div>
                  <Modal.Body>
                    {!isLoading ? (
                      <>
                        <div className="input-filed text-start">
                          <label className="basic-text required">
                            {t("MealPlan Title")}
                          </label>
                          <input
                            className={`form-control custom-input ${
                              newMealNameError.length > 0 && "input-error"
                            }`}
                            type="text"
                            value={newMealName}
                            onChange={(event) => {
                              setNewMealName(event.target.value);
                              setNewMealNameError("");
                            }}
                          />
                          {newMealNameError.length > 0 && (
                            <span className="text-danger invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {newMealNameError}
                            </span>
                          )}
                        </div>
                        <div className="form-group text-start">
                          <label className="basic-text">
                            {t("Description")}
                          </label>
                          <textarea
                            className="form-control custom-input"
                            id="exampleFormControlTextarea1"
                            value={newMealDescription}
                            onChange={(event) => {
                              setNewMealDescription(event.target.value);
                            }}
                            rows="3"
                            cols="10"
                          ></textarea>
                        </div>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn custom-primary"
                      onClick={mealHandleSaveAndClose}
                    >
                      {t("Save")}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>

            <div className="row clearfix ">
              <div className="table-div overflow-hidden  p-0">
                <div className="col-lg-12 col-md-12 p-0 m-0 ">
                 
                    <div className="body">
                      {!isLoading ? (
                        <>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th scope="col">
                                  {t("MealPlan")}
                                  <img
                                    src={Sort}
                                    className="ml-1 fa-sort"
                                    alt=""
                                    onClick={() => {
                                      handleSort("MealPlan");
                                    }}
                                  />
                                </th>
                                <th scope="col">
                                  {t("Description")}{" "}
                                  <img
                                    src={Sort}
                                    className="ml-1 fa-sort"
                                    alt=""
                                    onClick={() => {
                                      handleSort("Description");
                                    }}
                                  />
                                </th>
                                <th scope="col">
                                  {t("Student opted")}{" "}
                                  <img
                                    src={Sort}
                                    className="ml-1 fa-sort"
                                    alt=""
                                    onClick={() => {
                                      handleSort("Student opted");
                                    }}
                                  />
                                </th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            {mealPlanList.length > 0 ? (
                              <>
                                <tbody>
                                  {sortedMealPlanList.map((item, index) => (
                                    <tr key={item.id}>
                                      <td>{index + 1}</td>
                                      <td>{item.title}</td>
                                      <td>{item.description}</td>
                                      <td>{item.Students.length}</td>
                                      <td className="text-right">
                                        <a
                                         role="button"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setEditValue(item);
                                            setShowMealPlanModal(true);
                                          }}
                                          className={`${
                                            !Util.hasPermission(
                                              "MASTERS_EDIT"
                                            ) && "no-permission"
                                          }`}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </a>
                                      </td>
                                      <td>
                                        {item.Students.length === 0 ? (
                                          <span
                                          role="button"
                                            className={`${
                                              !Util.hasPermission(
                                                "MASTERS_DELETE"
                                              ) && "no-permission"
                                            }`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setShowDeleteConfirmation(true);
                                              setDeleteId(item.id);
                                            }}
                                          >
                                            <i className="fa fa-trash-o text-danger"></i>
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </>
                            ) : (
                              <>
                                <tfoot>
                                  <tr>
                                    <td colSpan={7}>
                                      {t(
                                        "There are no Mealplan. Click the'Register new Mealplan' button to create one."
                                      )}
                                    </td>
                                  </tr>
                                </tfoot>
                              </>
                            )}
                          </table>
                          {mealPlanList.length > 0 && (
                            <div className="text-muted pl-3 mb-3 border-top py-3">
                              <i>
                                {mealPlanList.length} {t("Mealplan found")}.
                              </i>
                            </div>
                          )}
                        </>
                      ) : (
                        <Loader />
                      )}
                    </div>
                    <ModalComponent
                      show={showDeleteConfirmation}
                      onHide={() => {
                        setShowDeleteConfirmation(false);
                      }}
                      title={t("Delete")}
                      onCancel={t("Cancel")}
                      onConfirm={t("Confirm")}
                      subTitle={t("Are you sure, to delete this MealPlan?")}
                      size="sm"
                      onCancelClick={() => {
                        setShowDeleteConfirmation(false);
                      }}
                      onConfirmClick={handleDelete}
                    />
                  
                </div>
              </div>
            </div>
          </div>
      
      </div>
    </>
  );
};

export default ToastHOC(MealPlans);
