import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import CloseIcon from "@mui/icons-material/Close";
import Util from "../../Util";
import ApiCalling from "../../network/ApiCalling";
import { Modal } from "react-bootstrap";
import { imageAddSchool } from "../../screens/CommonImage";
import { useTranslation } from "react-i18next";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import unknownImg from "../../assets/images/Frame 1000004000.png";
import DefaultSchool from "../../assets/Icon/defaultSchool.png";
import camera from "../../assets/Icon/camera-icon.svg";
import ToastHOC from "../../screens/HOC/ToastHOC";
import Close from "../../assets/Icon/close.svg";
import IconButton from "@mui/material/IconButton";
import EditPhotoModal from "../../screens/Dashbord/EditPhotoModal";
import Invalid from "../../assets/Icon/invalid.svg";
import Loader from "../common/Loader";

function AddSchool(props) {
  const { setIsToast } = props;
  const isVisible = props.isVisible;
  const isClose = props.isClose;
  const isSave = props.isSave;
  const notification = props.notification;
  const updateSchoolList = props.updateSchoolList;
  const getLanguage = Util.getSelectedLanguage();
  const { t } = useTranslation();
  const user = Util.getUser();
  const history = useHistory();
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [schoolType_id, setSchoolType_id] = useState("");
  const [schoolType_idError, setSchoolType_idError] = useState("");
  const [schoolType, setSchoolType] = useState([]);
  const [schoolName, setSchoolName] = useState("");
  const [schoolNameError, setSchoolNameError] = useState("");
  const [logo, setLogo] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [email, setEmail] = useState(user.email);
  const [emailError, setEmailError] = useState("");
  const [affiliationNumber, setAffiliationNumber] = useState("");
  const [capacity, setCapacity] = useState("");
  const [capacityError, setCapacityError] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [getCountryData, setCountryData] = useState([]);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [cityId, setCityId] = useState("");
  const [city, setCity] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);

  const handleSchoolNameChange = (e) => {
    setSchoolName(e.target.value);
    setSchoolNameError("");
  };
  const handleLogoChange = (e) => {
    if (e.target.files[0]) {
      setShowEditModal(true);
    }
    setPhotoURL(URL.createObjectURL(e.target.files[0]));
    setLogo(e.target.files[0]);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };
  const handleAffilationNumberChange = (e) => {
    setAffiliationNumber(e.target.value);
  };
  const handleCapacityChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setCapacity(value);
    setCapacityError("");
  };
  const handleAddressLine1Change = (e) => {
    setAddressLine1(e.target.value);
  };
  const handleAddressLine2Change = (e) => {
    setAddressLine2(e.target.value);
  };
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };
  const changeState = (e) => {
    e.preventDefault();
    setStateId(e.target.value);
  };
  const handleCityChange = (e) => {
    setCityId(e.target.value);
  };
  const handleZipCodeChange = (e) => {
    setZipCode(e.target.value);
    setPinCodeError("");
  };
  const resetDrawer = () => {
    setContactNumber("");
    setContactNumberError("");
    setSchoolType_id("");
    setSchoolName("");
    setAffiliationNumber("");
    setCapacity("");
    setAddressLine1("");
    setAddressLine2("");
    setCityId("");
    setCountry(null);
    setStateId(null);
    setZipCode("");
    setLogo("");
    setPhotoURL("");
    setSchoolNameError("");
    setSchoolType_idError("");
    setCapacityError("");
    setPinCodeError("");
  };
  const handleAddSchool = (e) => {
    e.preventDefault();
    setSchoolNameError("");
    setSchoolType_idError("");
    setCapacityError("");
    setEmailError("");
    setContactNumberError("");
    setPinCodeError("");
    e.preventDefault();
    var error = false;
    if (schoolType_id == "") {
      setSchoolType_idError(t("Type is required"));
      error = true;
    }

    if (schoolName === "") {
      setSchoolNameError(t("Name is required"));
      error = true;
    }
    if (email === "") {
      setEmailError(t("Email is required"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    }

    if (contactNumber === "") {
      setContactNumberError(t("Contact Number is required"));
      error = true;
    }
    if (capacity === "") {
      setCapacityError(t("Capacity is required"));
      error = true;
    }
    if (zipCode && Util.Zip_regex.test(zipCode) === false) {
      setPinCodeError(
        t("Please enter valid zip-code(i.e: 12345 or 12345-2563)")
      );
      error = true;
    }
    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      const formData = new FormData();
      if (schoolType_id) {
        formData.append("school_type_id", parseInt(schoolType_id));
      }

      formData.append("name", schoolName);
      formData.append("capacity", capacity);
      formData.append("contact_phone", contactNumber);
      formData.append("contact_email", email);
      formData.append("address1", addressLine1);
      formData.append("address2", addressLine2);
      if (cityId) {
        formData.append("city_id", cityId);
      }
      if (stateId) {
        formData.append("state_id", stateId);
      }
      if (country) {
        formData.append("country_id", country);
      }
      formData.append("pincode", zipCode);
      formData.append("affiliation_number", affiliationNumber);
      formData.append("logo", logo);

      ApiCalling.apiCallBodyDataPost("school/add-school", formData)
        .then((res) => {
          if (res.data.status == true) {
            setIsClickedOnce(false);
            setIsLoading(false);
            notification(t("School success added"));
            isSave(res.data.school);
            resetDrawer();
            const user = Util.getUser();
            ApiCalling.apiCallBodyDataGet(`user/get-user-byId/${user.id}`)
              .then((res) => {
                Util.saveUser(res.data);
                isClose();
                updateSchoolList(res.data);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getSchoolTypeId = async () => {
    ApiCalling.apiCallBodyDataGet("school_type")
      .then((res) => {
        setSchoolType(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCountry = async () => {
    ApiCalling.apiCallBodyDataGet("country")
      .then((res) => {
        setCountryData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const stateList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${country}`).then((res) => {
      setIsCountryLoading(false);
      setState(res.data);
    });
  };
  useEffect(() => {
    if (country) {
      stateList();
    }
  }, [country]);
  const cityList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res.data);
    });
  };
  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);
  useEffect(() => {
    getCountry();
    getSchoolTypeId();
  }, []);
  // useEffect(() => {
  //   const browserLanguage = navigator.language || navigator.userLanguage;
  //   let countryCode = "";
  //   if (browserLanguage) {
  //     if (browserLanguage.includes("-")) {
  //       countryCode = browserLanguage.split("-")[1].toUpperCase();
  //     } else {
  //       countryCode = browserLanguage.toUpperCase();
  //     }
  //     setDefaultCountry(countryCode);
  //   }
  // }, []);

  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={isVisible}
        onClose={() => {
          isClose();
          resetDrawer();
        }}
        disableEnforceFocus={true}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{
            position: "relative",
            width: "100%",
            height: "calc(100vh - 70px)",
          }}
        >
          <Box
            sx={{ position: "relative", width: "656px", padding: "20px" }}
            role="presentation"
          >
            {/* <i
            className="fa fa-times float-right"
            onClick={() => {
              isClose();
              resetDrawer();
            }}
          ></i> */}
            {!isLoading ? (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="drawer-header">{t("Add New School")}</div>
                  <IconButton
                    aria-label="close"
                    size="small"
                    onClick={() => {
                      isClose();
                      resetDrawer();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="body mt-3">
                  <div className="drawer-sub-header">
                    {t("School Information")}
                  </div>
                  <div className="mt-3">
                    <div className="text-start basic-text mb-1">
                      {t("School Logo")}
                    </div>
                    {photoURL.length > 0 ? (
                      <div
                        className="profile-area"
                        style={{
                          position: "relative",
                          height: "120px",
                          width: "120px",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          alt="User"
                          className="rounded-circle user-photo img-responsive  mt-1 "
                          src={photoURL}
                          style={{
                            height: "120px",
                            width: "120px",
                            borderRadius: "50%",
                          }}
                        />{" "}
                        <span
                          className="choose-profile"
                          onClick={(e) => {
                            e.preventDefault();
                            // document.getElementById("photo").click();
                            setShowEditModal(true);
                          }}
                        >
                          <img src={camera} alt="" />
                        </span>
                      </div>
                    ) : (
                      <div
                        className="profile-area"
                        style={{
                          position: "relative",
                          height: "120px",
                          width: "120px",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          alt="User"
                          className="img-responsive  mt-1"
                          style={{
                            height: "120px",
                            width: "120px",
                            borderRadius: "50%",
                            position: "relative",
                          }}
                          src={DefaultSchool}
                        />
                        <span
                          className="choose-profile"
                          onClick={(e) => {
                            e.preventDefault();
                            document.getElementById("photo").click();
                            // setShowEditModal(true);
                          }}
                        >
                          <img src={camera} alt="" />
                        </span>
                      </div>
                    )}

                    <input
                      className="sr-only"
                      id="photo"
                      type="file"
                      accept="image/*"
                      onChange={handleLogoChange}
                    />
                  </div>

                  <EditPhotoModal
                    show={showEditModal}
                    onClose={() => {
                      setShowEditModal(false);
                      setPhotoURL("");
                      setLogo("");
                    }}
                    title={t("Add Photo")}
                    subTitle={t("Add a Logo for your School")}
                    des={t("supported forms include PNG,JPG,SVG")}
                    onChange={t("Replace Image")}
                    onSave={t("Save Photo")}
                    // Id={studentId}
                    onConfirmClick={() => setShowEditModal(false)}
                    photoURL={photoURL.length > 0 ? photoURL : DefaultSchool}
                    handlePhotoChange={handleLogoChange}
                    handleSavePhoto={() => {
                      setShowEditModal(false);
                    }}
                  />
                  <div className="row text-start mt-3">
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text required">
                          {t("School Name")}
                        </span>
                        <input
                          className={`form-control ${
                            schoolNameError.length > 0
                              ? "input-error"
                              : "custom-input"
                          }`}
                          value={schoolName}
                          onChange={handleSchoolNameChange}
                        />
                        {schoolNameError.length > 0 && (
                          <span className=" invalid-error ">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {schoolNameError}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text required">
                          {t("Capacity")}
                        </span>
                        <input
                          type="text"
                          className={`form-control custom-input ${
                            capacityError.length > 0 ? "input-error" : ""
                          }`}
                          inputMode="numeric"
                          value={capacity}
                          onChange={handleCapacityChange}
                        />
                        {capacityError.length > 0 ? (
                          <span className="invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {capacityError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text required">
                          {t("Email")}
                        </span>
                        <input
                          className={`form-control ${
                            emailError.length > 0
                              ? "input-error"
                              : "custom-input"
                          }`}
                          value={email}
                          onChange={handleEmailChange}
                        />
                        {emailError.length > 0 && (
                          <span className="invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {emailError}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text required">
                          {t("Phone Number")}
                        </span>

                        <div
                          className={`form-control ${
                            contactNumberError.length > 0
                              ? "input-error"
                              : "custom-input"
                          }`}
                          tabIndex={0}
                        >
                          <PhoneInput
                            tabIndex="-1"
                            international
                            defaultCountry={"AE"}
                            value={contactNumber}
                            onChange={(value) => {
                              setContactNumber(value);
                              setContactNumberError("");
                            }}
                          />
                        </div>
                        {contactNumberError.length > 0 ? (
                          <span className="invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {contactNumberError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text">
                          {t("Affiliation Number")}
                        </span>
                        <input
                          className="form-control custom-input"
                          value={affiliationNumber}
                          onChange={handleAffilationNumberChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text required">{t("Type")}</span>
                        <select
                          className={`form-select select-dropdown custom-input ${
                            schoolType_idError.length > 0 ? "input-error" : ""
                          }`}
                          value={schoolType_id || ""}
                          onChange={(e) => {
                            setSchoolType_id(e.target.value);
                            setSchoolType_idError("");
                          }}
                        >
                          <option disabled value="">
                            {t("Select")}
                          </option>
                          {schoolType.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.type}
                              </option>
                            );
                          })}
                        </select>
                        {schoolType_idError.length > 0 ? (
                          <span className="invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {schoolType_idError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-start font-color medium-large-text">
                    {t("Address")}
                  </div>

                  <div className="row text-start mt-4">
                    <div className="col-6">
                      <div className="input-filed ">
                        <span className="basic-text">{t("Address 1")}</span>
                        <input
                          className="form-control custom-input"
                          value={addressLine1}
                          onChange={handleAddressLine1Change}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-filed ">
                        <span className="basic-text">{t("Address 2")}</span>
                        <input
                          className="form-control custom-input"
                          value={addressLine2}
                          onChange={handleAddressLine2Change}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text">{t("Country")}</span>
                        <select
                          style={{ paddingInline: "10px" }}
                          className="form-select select-dropdown custom-input"
                          value={country || ""}
                          onChange={handleCountryChange}
                        >
                          <option disabled value="">
                            {t("Select")}
                          </option>
                          {getCountryData.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text">{t("State")}</span>
                        <div className="form-group">
                          <select
                            className="select-dropdown form-select custom-input"
                            value={stateId || ""}
                            onChange={changeState}
                            disabled={!country}
                          >
                            <option disabled value="">
                              {isCountryLoading
                                ? t("Loading...")
                                : t("Select State")}
                            </option>
                            {state.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text">{t("City")}</span>
                        <select
                          className="select-dropdown form-select custom-input"
                          value={cityId}
                          onChange={handleCityChange}
                          disabled={!stateId}
                        >
                          <option value={0}>
                            {isCountryLoading
                              ? t("Loading...")
                              : t("Select City")}
                          </option>
                          {city.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text">{t("PIN Code")}</span>
                        <input
                          className={`form-control custom-input ${
                            pinCodeError.length > 0 ? "input-error" : ""
                          }`}
                          value={zipCode}
                          onChange={handleZipCodeChange}
                        />
                        {pinCodeError.length > 0 && (
                          <span className="invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {pinCodeError}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Loader />
            )}

            <div
              className="border-top"
              style={{
                position: "fixed",
                width: "656px",
                bottom: "0px",
                right: getLanguage !== "ar" && "0px",
                background: "#ffffff",
                padding: "10px",
              }}
            >
              <button
                style={{ float: "right" }}
                className="primary-lg-btn btn "
                onClick={handleAddSchool}
              >
                {t("Save School")}
              </button>
              <button
                style={{ float: "right" }}
                className="secondary-md-btn btn mr-2"
                onClick={() => {
                  isClose();
                  resetDrawer();
                }}
              >
                {t("Cancel")}
              </button>
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
export default ToastHOC(AddSchool);
