import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import BreadCrumbComponent from "../../../components/BreadCrumbComponent";
import ToastHOC from "../../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import StudentDetails from "./StudentDetails";
import ApiCalling from "../../../network/ApiCalling";
import MediaLibrary from "./MediaLibrary";
import Reactive from "./Reactive";
import InActive from "./InActive";
import UserFeed from "./UserFeed";
import Util from "../../../Util";
import Const from "../../../Const";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProfileTabs = (props) => {
  const { setIsToast } = props;
  const location = useLocation();
  const pendingToast = props.location.pendingToast;
  const studentId = props.history.location.studentId;
  // const studentId = useSelector((state) => state?.studentReducer?.studentId);
  // console.log('SSSS', studentId);

  const history = useHistory();
  const { t } = useTranslation();
  const [tabSelection, setTabSelection] = useState("General Information");
  const [getStudentDetails, setStudentDetails] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleTabChange = (event, newSelection) => {
    setTabSelection(newSelection);
  };

  const getStudentData = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("student/" + studentId).then((res) => {
      setStudentDetails(res.data);
      setIsActive(res.data.active);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (studentId == undefined && studentId > 0) {
      getStudentData();
    }
  }, [studentId]);

  useEffect(() => {
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  useEffect(() => {
    if (studentId === undefined) {
      history.push("/student");
    }
  }, [studentId]);

  return (
    <div className="container-fluid">
      {studentId > 0 && (
        <div className="container-80 mt-3">
          {studentId > 0 && (
            <BreadCrumbComponent
              Breadcrumb={[
                {
                  name: "My School",
                  navigate: () => {
                    history.push("/Dashboard");
                  },
                },
                {
                  name: "Student List",
                  navigate: () => {
                    history.push("/student");
                  },
                },
                { name: "Student Details" },
              ]}
            />
          )}
          <div className="row mb-4 mt-2 ">
            <div className="col-md-6">
              <h2 className="page-head">{t("Student Details")}</h2>
            </div>
          </div>

          <Box sx={{ width: "100%", marginBottom: "2%" }}>
            <Tabs
              value={tabSelection}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#f07a80",
                  height: 2,
                },
              }}
            >
              <Tab
                sx={{
                  letterSpacing: 0,
                  textTransform: "capitalize",
                  fontFamily: Const.fontfamily,
                  "&.Mui-selected": {
                    color: "#f07a80",
                    fontWeight: "600",
                  },
                }}
                value="General Information"
                label={t("General Information")}
              />
              <Tab
                sx={{
                  letterSpacing: 0,
                  textTransform: "capitalize",
                  fontFamily: Const.fontfamily,
                  "&.Mui-selected": {
                    color: "#f07a80",
                    fontWeight: "600",
                  },
                }}
                value="Feed"
                label={t("Feed")}
              />
              <Tab
                sx={{
                  letterSpacing: 0,
                  textTransform: "capitalize",
                  fontFamily: Const.fontfamily,
                  "&.Mui-selected": {
                    color: "#f07a80",
                    fontWeight: "600",
                  },
                }}
                value="Media Library"
                label={t("Media Library")}
              />
              {Util.hasPermission("STUDENT_DELETE") && isActive === true && (
                <Tab
                  sx={{
                    letterSpacing: 0,
                    textTransform: "capitalize",
                    fontFamily: Const.fontfamily,
                    "&.Mui-selected": {
                      color: "#f07a80",
                      fontWeight: "600",
                    },
                  }}
                  value="Inactive"
                  label={t("Inactive")}
                />
              )}
              {Util.hasPermission("STUDENT_DELETE") && isActive === false && (
                <Tab
                  sx={{
                    letterSpacing: 0,
                    textTransform: "capitalize",
                    fontFamily: Const.fontfamily,
                    "&.Mui-selected": {
                      color: "#f07a80",
                      fontWeight: "600",
                    },
                  }}
                  value="Reactive"
                  label={t("Reactive")}
                />
              )}
            </Tabs>
          </Box>
          {tabSelection === "General Information" && (
            <StudentDetails
              props={props}
              studentDetails={getStudentDetails}
              active={isActive}
              studentId={studentId}
            />
          )}
          {tabSelection === "Feed" && <UserFeed props={props} />}
          {tabSelection === "Media Library" && <MediaLibrary props={props} />}
          {tabSelection === "Inactive" && <InActive props={props} />}
          {tabSelection === "Reactive" && <Reactive props={props} />}
        </div>
      )}
    </div>
  );
};
export default ToastHOC(ProfileTabs);

// import React from "react";
// import UserFeed from "./UserFeed";
// import { useHistory } from "react-router-dom";
// import ToastHOC from "../../HOC/ToastHOC";
// import InActive from "./InActive";
// import ApiCalling from "../../../network/ApiCalling";
// import { withTranslation } from "react-i18next";
// import Util from "../../../Util";
// import Reactive from "./Reactive";
// import StudentDetails from "./StudentDetails";
// import MediaLibrary from "./MediaLibrary";
// import BreadCrumbComponent from "../../../components/BreadCrumbComponent";

// class ProfileTabs extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       getStudentDetails: null,
//       isActive: null,
//       isLoading: false,
//     };
//   }
//   getStudentData = async () => {
//     this.setState({ isLoading: true });
//     ApiCalling.apiCallParamsGet(
//       "student/" + this.props.location?.studentId
//     ).then((res) => {
//       this.setState({ getStudentDetails: res.data });
//       // this.setState({ activeStudent: res.data.active.data });
//       this.setState({ isActive: res.data.active });
//       this.setState({ isLoading: false });
//     });
//   };

//   componentDidMount() {
//     const { setIsToast } = this.props;
//     const pendingToast = this.props.location?.pendingToast;
//     if (pendingToast && pendingToast.length > 0) {
//       setIsToast(pendingToast);
//     }
//     if (this.props.location?.studentId > 0) {
//       this.getStudentData();
//     }
//   }

//   onTabChange = (e) => {
//     var tab1 = document.getElementById("bacicTab-1");
//     tab1.classList.remove("active");
//     tab1.children[0].classList.remove("active");
//     var tab2 = document.getElementById("bacicTab-2");
//     tab2.classList.remove("active");
//     tab2.children[0].classList.remove("active");
//     var tab3 = document.getElementById("bacicTab-3");
//     tab3.classList.remove("active");
//     tab3.children[0].classList.remove("active");
//     var tab4 = document.getElementById("bacicTab-4");
//     tab4.classList.remove("active");
//     tab4.children[0].classList.remove("active");

//     var actab = document.getElementById("bacicTab-" + e);
//     actab.classList.remove("active");
//     actab.children[0].classList.add("active");

//     // var tab6 = document.getElementById("bacicTab-6");
//     // tab6.classList.remove("active");
//     // tab6.children[0].classList.remove("active");

//     var tabpan1 = document.getElementById("bacicTabpan-1");
//     tabpan1.classList.remove("active");
//     var tabpan2 = document.getElementById("bacicTabpan-2");
//     tabpan2.classList.remove("active");
//     var tabpan3 = document.getElementById("bacicTabpan-3");
//     tabpan3.classList.remove("active");
//     var tabpan4 = document.getElementById("bacicTabpan-4");
//     tabpan4.classList.remove("active");

//     var actabpab = document.getElementById("bacicTabpan-" + e);
//     actabpab.classList.add("active");

//     // var tabpan6 = document.getElementById("bacicTabpan-6");
//     // tabpan6.classList.remove("active");
//   };

//   render() {
//     const { t } = this.props;
//     const studentId = this.props.location?.studentId;
//     const primaryContact = this.props.location.contact;
//     const { history } = this.props;
//     return (
//       <div>
//         <div className="container-fluid">
//           {/* <div className="d-flex align-items-center gap-2 mb-3">
//             <a
//               href="#"
//               onClick={(e) => {
//                 e.preventDefault();
//                 this.props.history.push("/student");
//               }}
//             >
//               <i className="fa fa-chevron-left" aria-hidden="true"></i>
//             </a>
//             <a
//               href="#"
//               onClick={(e) => {
//                 e.preventDefault();
//                 this.props.history.push("/student");
//               }}
//               className="link-button"
//             >
//               {t("Students")}
//             </a>
//           </div> */}

//           <div className="container-80 mt-3">
//             {studentId > 0 && (
//               <BreadCrumbComponent
//                 Breadcrumb={[
//                   { name: "My School", navigate: () => {
//                     history.push("/Dashboard");
//                   } },
//                   { name: "Student List", navigate: () => {
//                     history.push("/student");
//                   } },
//                   { name: "Student Details" },
//                 ]}
//               />
//             )}
//           </div>

//           <div className="body">
//             {studentId > 0 ? (
//               <div className="container-80">
//                 <div className="row mb-4 mt-2 ">
//                   <div className="col-md-6">
//                     <h2 className="page-head">{t("Student Details")}</h2>
//                   </div>
//                 </div>
//                 <ul className="nav nav-tabs " role="tablist">
//                   <li
//                     className={`nav-item mr-1 ${
//                       primaryContact ? "" : "active"
//                     }`}
//                     id="bacicTab-1"
//                     role="presentation"
//                     onClick={() => {
//                       this.onTabChange(1);
//                     }}
//                   >
//                     <a className={`nav-link ${primaryContact ? "" : "active"}`}>
//                       {t("General Information")}
//                     </a>
//                   </li>
//                   <li
//                     className={`nav-item mr-1 ${
//                       primaryContact ? "active" : ""
//                     }`}
//                     id="bacicTab-2"
//                     role="presentation"
//                     onClick={() => {
//                       this.onTabChange(2);
//                     }}
//                   >
//                     <a
//                       className={`nav-link ${primaryContact ? "active" : ""}`}
//                       data-toggle="tab"
//                     >
//                       {t("Feed")}
//                     </a>
//                   </li>
//                   <li
//                     className="nav-item mr-1"
//                     id="bacicTab-3"
//                     role="presentation"
//                     onClick={() => {
//                       this.onTabChange(3);
//                     }}
//                   >
//                     <a className="nav-link" data-toggle="tab">
//                       {t("Media Library")}
//                     </a>
//                   </li>
//                   <li
//                     className={`${
//                       Util.hasPermission("STUDENT_DELETE")
//                         ? "nav-item mr-1"
//                         : "no-permission"
//                     }`}
//                     id="bacicTab-4"
//                     role="presentation"
//                     onClick={() => {
//                       this.onTabChange(4);
//                     }}
//                   >
//                     <a
//                       className={`nav-link ${primaryContact ? "active" : ""}`}
//                       data-toggle="tab"
//                     >
//                       {!this.state.isLoading && (
//                         <>
//                           {this.state.isActive == 1
//                             ? t("Inactive")
//                             : t("Reactive")}
//                         </>
//                       )}
//                     </a>
//                   </li>
//                 </ul>
//               </div>
//             ) : (
//               <></>
//             )}

//             <div className="tab-content">
//               <div
//                 id="bacicTabpan-1"
//                 className={`tab-pane ${!primaryContact ? "active" : ""} `}
//               >
//                 {/* <AddStudent
//                   props={this.props}
//                   studentDetails={this.state.getStudentDetails}
//                   active={this.state.isActive}
//                 /> */}
//                 <StudentDetails
//                   props={this.props}
//                   studentDetails={this.state.getStudentDetails}
//                   active={this.state.isActive}
//                 />
//               </div>

//               {/* <div
//                 id="bacicTabpan-6"
//                 // className={`tab-pane ${!primaryContact ? "active" : ""} `}
//               >
//                 <StudentDetails
//                   props={this.props}
//                   studentDetails={this.state.getStudentDetails}
//                   active={this.state.isActive}
//                 />
//               </div> */}

//               {studentId > 0 ? (
//                 <>
//                   <div
//                     id="bacicTabpan-2"
//                     className={`tab-pane ${primaryContact ? "active" : ""} `}
//                   >
//                     <UserFeed props={this.props} />
//                   </div>
//                   <div
//                     id="bacicTabpan-3"
//                     className={`tab-pane ${primaryContact ? "active" : ""} `}
//                   >
//                     <MediaLibrary props={this.props} />
//                   </div>

//                   {this.state.isActive == 1 ? (
//                     <div id="bacicTabpan-4" className="tab-pane">
//                       <InActive props={this.props} />
//                     </div>
//                   ) : (
//                     <div id="bacicTabpan-4" className="tab-pane">
//                       <Reactive props={this.props} />
//                     </div>
//                   )}
//                 </>
//               ) : (
//                 ""
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default ToastHOC(withTranslation()(ProfileTabs));
