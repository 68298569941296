import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import PhoneInput from "react-phone-number-input";
import ApiCalling from "../../../network/ApiCalling";
import moment from "moment";
import Loader from "../../../components/common/Loader";

const StaffSchoolInfo = ({
  data,
  userId,
  notification,
  staffSchoolEdit,
  onCancel,
}) => {
  const { t } = useTranslation();

  const [staffType, setStaffType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState("");
  const [notes, setNotes] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [selectedLang, setSelectedLang] = useState("");
  const [preferredLanguageId, setPreferredLanguageId] = useState(0);
  const [preferredLanguageList, setPreferredLanguageList] = useState([]);

  const LanguageList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("preferred-language/list")
      .then((res) => {
        setIsLoading(false);
        setPreferredLanguageList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const handleSaveSchoolDetails = (e) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("id", userId);

    if (contactNumber) {
      formData.append("mobile", contactNumber);
    }
    if (preferredLanguageId) {
      formData.append("preferred_language_id", preferredLanguageId);
    }
    if (notes) {
      formData.append("notes", notes);
    }

    ApiCalling.apiCallBodyDataPost("user/dynamic-update", formData)
      .then((res) => {
        if (res.data.status == true) {
          setIsLoading(false);
          notification(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    LanguageList();
  }, []);

  useEffect(() => {
    if (userId > 0) {
      if (data !== null) {
        console.log("data",data)
        setStaffType(data?.role)
        setContactNumber(data?.mobile);
        setNotes(data?.notes);
        setSelectedLang(data?.language);
        setPreferredLanguageId(
          Util.isValidData(data?.preferred_language_id)
            ? data?.preferred_language_id
            : 0
        );
      }
    }
  }, [data]);
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);
  return (
    <>
      {!isLoading ? (
        <div className="row text-start">
          <div className="col-lg-6">
            <div className="input-filed ">
              <span
                className="basic-text "
              >
                {t("Staff Type")}
              </span>
              {!staffSchoolEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(staffType) ? staffType : "-"}
                </div>
              ) : (
                <>
                  <input
                    className="form-control custom-input"
                    value={staffType}
                    onChange={(e) => {
                      setStaffType(e.target.value);
                    }}
                    disabled
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text">
                {t("Preferred Language")}
              </span>
              {!staffSchoolEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(selectedLang) ? selectedLang : "-"}
                </div>
              ) : (
                <>
                  <select
                    name=""
                    id=""
                    className="select-dropdown form-select custom-input"
                    value={preferredLanguageId}
                    onChange={(e) => {
                      setPreferredLanguageId(e.target.value);
                    }}
                  >
                    <option value={0}>{t("Select Preferred Language")}</option>
                    {preferredLanguageList?.map((item) => (
                      <option
                        placeholder="Language"
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text" >
                {t("Phone Number")}
              </span>
              {!staffSchoolEdit ? (
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#07112b",
                  }}
                >
                  {Util.isValidData(contactNumber) ? contactNumber : "-"}
                </div>
              ) : (
                <>
                  <div
                    className={`form-control ${
                      contactNumberError.length > 0
                        ? "input-error"
                        : "custom-input"
                    }`}
                    tabIndex={0}
                  >
                    <PhoneInput
                    tabIndex="-1"
                      international
                      defaultCountry={defaultCountry}
                      value={contactNumber}
                      onChange={(value) => {
                        setContactNumber(value);
                        setContactNumberError("");
                      }}
                    />
                  </div>
                  {contactNumberError.length > 0 && (
                    <span className="text-danger mt-2">
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"
                      ></i>
                      {contactNumberError}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>

          <div className={`${staffSchoolEdit ? "col-lg-12" : "col-lg-6"}`}>
            <div className="input-filed ">
              <span className="basic-text">
                {t("Special Notes")}
              </span>
              {!staffSchoolEdit ? (
                <div className="large-bold-text" style={{ whiteSpace: "pre-line" }}>
                  {Util.isValidData(notes) ? notes : "-"}
                </div>
              ) : (
                <>
                  <textarea
                    style={{ width: "100%", minHeight: "120px" }}
                    className="form-control custom-input"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </>
              )}
            </div>
          </div>
          {staffSchoolEdit ? (
            <div className="text-end">
              {userId > 0 && (
                <button
                  className="btn secondary-lg-btn rounded-btn mr-2 m-0 px-2"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  {t("Cancel")}
                </button>
              )}
              <button
                className=" btn primary-lg-btn rounded-btn"
                onClick={handleSaveSchoolDetails}
              >
                {t("Save")}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default StaffSchoolInfo;
