import React, { useEffect, useState } from "react";
import Util from "../../Util";
import check from "../../assets/Icon/check-double.svg";
import { useTranslation } from "react-i18next";
import Loader from "../../components/common/Loader";

export default function StaffList(props) {
  const { t } = useTranslation();
  const staffUsers = props?.StaffList;
  const onlineUsersList = props?.onlineUsersList;
  // const [staffUsers,setStaffUsers]=useState([]);
  const [selectedUserId, setSelectedUserId] = useState(props?.selectedUserId);
  const [isStaffList, setIsStaffList] = useState(false);
  const searchClick = props?.searchStaffClick;
  function createRoom(user1) {
    setSelectedUserId(user1.id);
    props.startChat(user1);
  }

  return (
    <>
      {staffUsers !== undefined && staffUsers.length > 0 ? (
        <ul className="list-unstyled chat-list mt-2 mb-0 position-relative">
          {staffUsers
            .sort((a, b) => {
              if (a.unread > 0 || b.unread > 0)
                return b.unread > a.unread ? 1 : -1;
              else if (a.last_message_on !== "" && b.last_message_on !== "") {
                return new Date(b.last_message_on) > new Date(a.last_message_on)
                  ? 1
                  : -1;
              } else if (a.last_message_on === "" && b.last_message_on === "") {
                return a.user.firstName < b.user.firstName ? -1 : 1;
              } else {
                return b.last_message_on.length - a.last_message_on.length;
              }
            })

            ?.map((item) => {
              const name = Util.getShortString(
                item.user.firstName + " " + item.user.lastName,
                17
              );
              return (
                  <li
                    key={item.user.id}
                    className={selectedUserId === item.user.id ? "active" : ""}
                  >
                    <a
                      onClick={() => {
                        createRoom(item);
                      }}
                      className="d-flex"
                    >
                      
                      {Util.getUserAvatar(item.user)}
                     
                      <div
                        className={`row about m-0 ${
                          item.unread > 0 ? "unread" : ""
                        }`}
                      >
                        <div className="col-9 name text-start">{name}</div>

                        <div className="col-3 last-message-time text-end">
                          {Util.getDateDisplay_ChatMessage(
                            item.last_message_on
                          )}
                        </div>

                        <div
                          className="col-10 last-message text-start"
                          // style={{
                          //   overflow: "hidden",
                          //   textOverflow: "ellipsis",
                          //   whiteSpace: "nowrap",
                          // }}
                          style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            textOverflow: "ellipsis",
                            whiteSpace: "normal", // This allows the text to wrap if it's less than two lines
                            maxHeight: "3.6em", // You can adjust this value to control the height for two lines
                          }}
                        >
                          {Util.isValidData(item.last_message) &&
                          item.last_message.length > 0
                            ? item.last_message
                            : item.user.user_Schools[0].role.role}
                        </div>
                        {item.isOnline && (
                          <div className="col-2 online text-right">
                            <img src={check} />
                          </div>
                        )}
                      </div>
                    
                    </a>
                  </li>
              );
            })}
        </ul>
      ) : (
        <>
        <Loader/>
        </>
      )}
    </>
  );
}
