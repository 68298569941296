import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ApiCalling from "../../../network/ApiCalling";
import Loader from "../../../components/common/Loader";
import TagButton from "../TagButton";
import { greenTagStyle, orangeTagStyle } from "../TagButtonStyle";
import SideDrawerContainer from "../../../components/SideDrawerContainer";
import ToastHOC from "../../HOC/ToastHOC";

const StudentAcademicYears = ({
  data,
  setIsToast,
  studentId,
  onSave,
  isStudentUpdate,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [stageList, setStageList] = useState([]);
  const [stageId, setStageId] = useState(0);
  const [gradeId, setGradeId] = useState(0);
  const [roomId, setRoomId] = useState(0);
  const [editId, setEditId] = useState(null);
  const [academicData, setAcademicData] = useState("");
  const [academicId, setAcademicId] = useState(0);
  const [academicYearData, setAcademicYearData] = useState([]);
  const [existMsg, setExistMsg] = useState("");
  const [academicLoading, setAcademicLoading] = useState(false);
  const [academicdata, setAcademicdata] = useState([]);

  const [error, setError] = useState({
    stageId: "",
    gradeId: "",
    roomId: "",
  });

  const getStage = (id) => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`stage/section-by-academic/` + id)
      .then((res) => {
        // console.log("getStage", res);
        setIsLoading(false);
        setStageList(res?.data);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleEdit = (item) => {
    setEditId(item?.id);
    setAcademicId(item?.academic_year_id);
    setStageId(item?.grade?.Stage?.id);
    setGradeId(item?.grade?.id);
    setRoomId(item?.classroom_id && item?.classroom_id);
    setAcademicData(item);
    setShowModal(true);
  };

  useEffect(() => {
    if (editId) {
      getStage(academicId);
    }
  }, [academicId]);

  const onClose = () => {
    setShowModal(false);
    setAcademicId(0);
    setStageId(0);
    setGradeId(0);
    setRoomId(0);
    setEditId(null);
    setError("");
    setExistMsg("");
  };

  const handleAddStudentClass = () => {
    setShowModal(true);
    setEditId(null);
  };

  const handleSubmit = () => {
    const isValidate = validate();

    if (isValidate) {
      const formData = {
        academic_year_id: parseInt(academicId),
        student_id: studentId,
        classroom_id: parseInt(roomId),
        grade_id: parseInt(gradeId),
        id: editId ? editId : null,
      };
      try {
        ApiCalling.apiCallBodyDataPost(
          `student-academic-year/${editId ? "update" : "create"}`,
          formData
        ).then((res) => {
          if (res?.data?.status === true) {
            setAcademicLoading(false);
            setIsToast(t(res?.data?.message));
            setShowModal(false);
            getStage(academicId);
            onSave(res?.data?.message);
          }
          if (res?.data?.status === false) {
            // setIsToast(t(res?.data?.message));
            setExistMsg(res?.data?.message);
          }
        });
      } catch (error) {
        console.error("ERROR");
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    const AcademicList = () => {
      setIsLoading(true);
      ApiCalling.apiCallBodyDataGet("academic-year/getBySchoolId")
        .then((res) => {
          if (isMounted) {
            setAcademicYearData(res?.data);
            setIsLoading(false);
          }
          // console.log(res);
        })
        .catch((error) => {
          console.log(error);
          if (isMounted) {
            console.log(error);
          }
        });
    };
    AcademicList();

    return () => {
      isMounted = false;
    };
  }, []);

  const validate = () => {
    let isValid = true;

    const newError = { ...error };

    if (!academicId) {
      newError.academicId = "Academic is requied";
      isValid = false;
    } else {
      newError.academicId = " ";
    }

    if (!stageId) {
      newError.stageId = "Stage is requied";
      isValid = false;
    } else {
      newError.stageId = " ";
    }
    if (!gradeId) {
      newError.gradeId = "Grade is requied";
      isValid = false;
    } else {
      newError.gradeId = " ";
    }
    if (!roomId) {
      newError.roomId = "Classroom is requied";
      isValid = false;
    } else {
      newError.roomId = " ";
    }
    setError(newError);
    setAcademicLoading(true);
    return isValid;
  };

  useEffect(() => {
    if (data !== null) {
      setAcademicdata(data?.StudentAcademicYears || []);
    }
  }, [data]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="medium-large-text font-color-primary ">
          {t("Student Class ")}
        </div>
        <button className="link-button" onClick={handleAddStudentClass}>
          <i className="fas fa-plus mr-1"></i> {t("Add New")}
        </button>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div
            className="table-wrapper table-div"
            style={{ overflowX: "auto" }}
          >
            <table className="table">
              <thead>
                <tr className="text-nowrap">
                  <th width="26px"></th>
                  <th scope="col">{t("Academic year")}</th>
                  <th scope="col">{t("Level")}</th>
                  <th scope="col">{t("Grade")}</th>
                  <th scope="col">{t("Classroom")}</th>

                  <th scope="col">{t("Status")} </th>
                </tr>
              </thead>

              <>
                {academicdata?.length === 0 ? (
                  <tfoot>
                    <tr>
                      <td colSpan={5}>{t("No Academic data found")}</td>
                    </tr>
                  </tfoot>
                ) : (
                  <tbody>
                    {academicdata?.map((item, index) => (
                      <tr
                        className="selectedAcc "
                        role="button"
                        onClick={() => handleEdit(item)}
                        key={item.id}
                      >
                        <td></td>
                        <td>{item.Academic_year?.title}</td>
                        <td>{item?.grade?.Stage?.stage}</td>
                        <td>{item?.grade?.title}</td>
                        <td>
                          {item?.classroom?.name ? item?.classroom?.name : "-"}
                        </td>
                        <td>
                          <TagButton
                            style={"btn btn-secondary"}
                            size={"100px"}
                            title={`${
                              item?.Academic_year?.active
                                ? "Active"
                                : "Inactive"
                            }`}
                            buttonStyle={
                              item?.Academic_year?.active
                                ? greenTagStyle
                                : orangeTagStyle
                            }
                            icon="fa fa-circle"
                            iconSize="6px"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </>
            </table>
          </div>

          <SideDrawerContainer
            show={showModal}
            handleChange={handleSubmit}
            hide={onClose}
            title={`${editId ? "Edit" : "Add"} Student Class`}
          >
            {!isLoading ? (
              <>
                <div className="row pt-3">
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text ">{t("Academic Year")}</span>
                      {editId ? (
                        <input
                          className={`form-control custom-input `}
                          disabled
                          value={academicData?.Academic_year?.title}
                        />
                      ) : (
                        <>
                          <select
                            className={`select-dropdown form-select custom-input `}
                            value={academicId}
                            onChange={(e) => {
                              setAcademicId(e.target.value);
                              getStage(e.target.value);
                              setGradeId("");
                              setRoomId("");
                              isStudentUpdate();
                            }}
                          >
                            <option value={0}>{t("Select Academic")}</option>
                            {academicYearData?.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item?.title}
                                </option>
                              );
                            })}
                          </select>
                          {error?.academicId && (
                            <span className="text-danger">
                              {error?.academicId}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text ">{t("Level")}</span>
                      <select
                        className={`select-dropdown form-select custom-input `}
                        value={stageId}
                        onChange={(e) => {
                          setStageId(e.target.value);
                          setGradeId("");
                          setRoomId("");
                          isStudentUpdate();
                        }}
                      >
                        <option value={0}>{t("Select Level")}</option>
                        {stageList?.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item?.stage}
                            </option>
                          );
                        })}
                      </select>
                      {error?.stageId && (
                        <span className="text-danger"> {error?.stageId}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text ">{t("Grade")}</span>
                      <select
                        value={gradeId}
                        onChange={(e) => {
                          setGradeId(e.target.value);
                          // setGradeIdError("");
                          // isStudentUpdate();
                          isStudentUpdate();
                        }}
                        disabled={stageId === 0}
                        className={`select-dropdown form-select custom-input `}
                      >
                        <option value={0}>{t("Select Grade")}</option>
                        {stageList
                          ?.find((stage) => stage?.id === parseInt(stageId))
                          ?.Grades?.map((grade) => (
                            <option key={grade.id} value={grade?.id}>
                              {grade?.title}
                            </option>
                          ))}
                      </select>
                      {error?.gradeId && (
                        <>
                          <span className="text-danger">{error?.gradeId}</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-filed ">
                      <span className="basic-text ">{t("Class Room")}</span>
                      <select
                        className={`select-dropdown form-select custom-input `}
                        value={roomId}
                        onChange={(e) => {
                          setRoomId(e.target.value);
                          // setRoomIdError("");

                          isStudentUpdate();
                        }}
                        disabled={gradeId === 0}
                      >
                        <option>{t("Select Room")}</option>
                        {stageList
                          .find((stage) => stage.id === parseInt(stageId))
                          ?.Grades.find(
                            (grade) => grade.id === parseInt(gradeId)
                          )
                          ?.ClassRooms?.map((classroom) => (
                            <option key={classroom.id} value={classroom.id}>
                              {classroom?.name}
                            </option>
                          ))}
                      </select>
                      {error?.roomId && (
                        <span className="text-danger">{error?.roomId}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="selectedAcc ">{existMsg && existMsg}</div>
                <div
                  className="col-12 text-right mt-2  "
                  style={{
                    position: "absolute",
                    paddingBottom: "10px",
                    left: 0,
                    right: 0,
                    transform: "translateY(50px)",
                    bottom: 100,
                  }}
                >
                  <button
                    className="secondary-lg-btn btn mr-2"
                    onClick={onClose}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    disabled={isLoading}
                    className="primary-lg-btn btn"
                    onClick={handleSubmit}
                  >
                    {t(`${editId ? "Update" : "Save"}`)}
                  </button>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </SideDrawerContainer>
        </div>
      </div>
    </>
  );
};

export default ToastHOC(StudentAcademicYears);
