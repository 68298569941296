import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Group from "../../assets/images/Group 691314497.png";
import Util from "../../Util";
import Close from "../../assets/Icon/close.png";
import { t } from "i18next";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { IconButton } from "@material-ui/core";
import { close } from "../Admission/Admission";
const ModalComponent = ({
  size,
  show,
  onHide,
  title,
  subTitle,
  onCancel,
  onConfirm,
  onCancelClick,
  onConfirmClick,
  onShow,
  header,
  children,
}) => {
  const { t } = useTranslation();
  const getLanguage = Util.getSelectedLanguage();
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        style={{ borderRadius: "8px" }}
        size={size}
        onHide={onHide}
        show={show}
        animation={true}
        centered={true}
        className="custom-modal"
        onShow={onShow}
      >
        <div className="d-flex justify-content-between align-items center p-1 border-0">
          <h6>{header}</h6>

          <button className="hidden-btn" onClick={onHide}>
            <img src={Close} className="svg-icon" height={20} width={20} />
          </button>
        </div>

        <Modal.Body className="p-0">
          <div className="d-flex justify-content-center">
            <img src={Group} alt="group" className="img-responsive" />
          </div>
          <div className="modal-contents text-center">
            <h3
              style={{ fontWeight: "700", fontSize: "20px", color: "#07112B" }}
            >
              {title}
            </h3>
            <span
              style={{ fontWeight: "500", fontSize: "14px", color: "#4B5563" , }}
            >
              {subTitle}
            </span>
            {children}
          </div>
        </Modal.Body>

        <Modal.Footer className="border-0 p-0 d-flex justify-content-between mt-3">
          <button className="secondary-modal-btn btn" onClick={onCancelClick}>
            {onCancel}
          </button>
          <button className="primary-modal-lg-btn btn" onClick={onConfirmClick}>
            {onConfirm}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ModalComponent;

export function ModalView({
  show,
  handleCloseModal,
  handleOpenModal,
  handleSaveAndClose,
  children,
  title = "Add Title",
}) {
  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      animation={true}
      centered={true}
      dialogClassName="modal-view"
      onShow={handleOpenModal}
    >
      <div className="d-flex justify-content-between align-items center p-4 px-1 border-bottom">
        <h6>{title}</h6>
        <button className="hidden-btn" onClick={handleCloseModal}>
          <img src={close} className="svg-icon " height={24} width={24} />
        </button>
      </div>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button className="btn custom-primary" onClick={handleSaveAndClose}>
          {t("Save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
