import React, { useEffect, useState } from "react";
import AllSchoolInvoices from "./AllSchoolInvoices";
import AllSchoolCredits from "./AllSchoolCredits";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "../../Dashbord/DashboardCustom.css";
import ToastHOC from "../../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import Const from "../../../Const";

function BillingReports(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const [value, setValue] = React.useState("Invoices");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="container-fluid">
      <div className="body">
        <div className="col-lg col-md-12 p-3">
          <div className="row py-3">
            <div className="col-md-4">
              <h2 className="page-head">{t("Reports")}</h2>
            </div>
            <div className="col-md-8"></div>
          </div>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: Const.primaryColor,
                  height: 2,
                },
              }}
            >
              <Tab
                sx={{
                  fontWeight: 600,
                  marginRight: "20px",
                  letterSpacing: 0,
                  textTransform: "capitalize",
                  fontFamily: Const.fontfamily,
                  padding: "5px",
                  height: "40px",
                  "&.Mui-selected": {
                    color: Const.primaryColor,
                    fontWeight: "600",
                  },
                }}
                value="Invoices"
                label={t("Invoices")}
              />
              <Tab
                sx={{
                  fontWeight: 600,
                  marginRight: "20px",
                  letterSpacing: 0,
                  textTransform: "capitalize",
                  fontFamily: Const.fontfamily,
                  padding: "5px",
                  height: "40px",
                  "&.Mui-selected": {
                    color: Const.primaryColor,
                    fontWeight: "600",
                  },
                }}
                value="Credits & Payments"
                label={t("Credits & Payments")}
              />
            </Tabs>
          </Box>
          {value === "Invoices" && (
            <AllSchoolInvoices
              message={(msg) => {
                setIsToast(msg);
              }}
            />
          )}
          {value === "Credits & Payments" && <AllSchoolCredits />}
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(BillingReports);
