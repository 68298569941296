import React, { useEffect, useState } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Search from "../../assets/Icon/Search.svg";
import { Avatar } from "@mui/material";
import ApiCalling from "../../network/ApiCalling";
import { imageMyProfile } from "../CommonImage";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Util from "../../Util";
import { SeparateDot } from "../../components/common/separateDot";
import student from "../../assets/Icon/user.svg";
import emptyCheck from "../../assets/Icon/check-box-empty.svg";
import check from "../../assets/Icon/check-box.svg";
import ExitAlert from "../../components/ExitAlert";
import Close from "../../assets/Icon/close.png";

export default function NewGroup({
  show,
  onClose,
  onGroupClick,
  onSave,
  onCreateGroup,
}) {
  const { t } = useTranslation();
  const user = Util.getUser();
  const getLanguage = Util.getSelectedLanguage();
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [staffList, setStaffList] = useState([]);
  const [filteredStaffList, setFilteredStaffList] = useState([]);
  const [parentList, setParentList] = useState([]);
  const [filteredParentList, setFilteredParentList] = useState([]);
  const [selectedStaffId, setSelectedStaffId] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState([]);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupNameError, setGroupNameError] = useState("");
  const [alertModal, setIsAlertModal] = useState(false);
  const [isStateUpdated, setIsStateUpdated] = useState(false);

  const StaffChecked = (event) => {
    const id = Number(event.target.value);
    if (event.target.checked) {
      setSelectedStaffId([...selectedStaffId, id]);
    } else {
      const data = selectedStaffId.filter((s) => s !== id);
      setSelectedStaffId(data);
    }
  };
  const ParentChecked = (event) => {
    const id = Number(event.target.value);
    if (event.target.checked) {
      setSelectedParentId([...selectedParentId, id]);
    } else {
      const data = selectedParentId.filter((s) => s !== id);
      setSelectedParentId(data);
    }
  };
  const handleStaffRowClick = (id) => {
    setIsStateUpdated(true);
    if (!selectedStaffId.includes(id)) {
      setSelectedStaffId([...selectedStaffId, id]);
    } else {
      const data = selectedStaffId.filter((s) => s !== id);
      setSelectedStaffId(data);
    }
  };
  const handleParentRowClick = (id) => {
    setIsStateUpdated(true);
    if (!selectedParentId.includes(id)) {
      setSelectedParentId([...selectedParentId, id]);
    } else {
      const data = selectedParentId.filter((s) => s !== id);
      setSelectedParentId(data);
    }
  };
  const handleGroupName = (e) => {
    setIsStateUpdated(true);
    setGroupName(e.target.value);
    setGroupNameError("");
  };

  const reset = () => {
    setSelectedParentId([]);
    setSelectedStaffId([]);
    setGroupName("");
    setGroupNameError("");
  };
  const handleSaveGroup = () => {
    let error = false;
    setGroupNameError("");
    if (groupName == "") {
      setGroupNameError(t("Name is required"));
      error = true;
    }
    if (!error) {
      const selectedStaffIds = selectedStaffId;
      const selectedParentIds = selectedParentId;
      const data = {
        name: groupName,
        user_ids: [...selectedParentIds, ...selectedStaffIds].join(","),
      };

      ApiCalling.apiCallBodyDataPost("group/add-chatroom", data)
        .then((res) => {
          if (res.data.status == true) {
            onGroupClick(res.data.group);
            onSave(res.data.message);
          }
          setIsGroupModalOpen(false);
          reset();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const getStaffList = () => {
    ApiCalling.apiCallBodyDataGet("chatroom/get-staff")
      .then((res) => {
        setStaffList(res.data);
        setFilteredStaffList(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getParentList = () => {
    ApiCalling.apiCallBodyDataGet("chatroom/get-parent")
      .then((res) => {
        setParentList(res.data);
        setFilteredParentList(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getStaffList();
    getParentList();
    if (user.user_Schools[0].role !== null) {
      setSelectedStaffId([user.id]);
    } else {
      setSelectedParentId([user.id]);
    }
  }, []);
  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={show}
        onClose={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            onClose();
            reset();
            setIsStateUpdated(false);
          }
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">{t("Create New Group")}</div>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    onClose();
                    reset();
                    setIsStateUpdated(false);
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="body mt-3">
              <div className="row mt-2">
                <div className="input-group">
                  <input
                    className="form-control search "
                    placeholder="Search"
                    type="text"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      const searchValue = e.target.value;
                      if (searchValue.trim() === "") {
                        setFilteredStaffList(staffList);
                        setFilteredParentList(parentList);
                      } else {
                        const filteredStaff = staffList.filter((s) =>
                          s.firstName
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        );
                        const filteredParent = parentList.filter((s) =>
                          s.firstName
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        );
                        setFilteredStaffList(filteredStaff);
                        setFilteredParentList(filteredParent);
                      }
                    }}
                  />
                  <div className="input-group-prepend">
                    <div className="search-icon">
                      <img src={Search} className="svg-icon" alt="" />
                    </div>
                  </div>
                </div>
                <div className="chat-group-label text-start">{t("Staff")}</div>
                {filteredStaffList.length > 0 &&
                filteredStaffList !== undefined ? (
                  <div className="chat-table-container">
                    <table className="table">
                      <tbody>
                        {filteredStaffList
                          .filter((value) => {
                            if (searchClick == "") {
                              return value;
                            } else if (
                              value.firstName
                                .toLowerCase()
                                .includes(searchClick.toLocaleLowerCase())
                            ) {
                              return value;
                            }
                            return;
                          })
                          .map((item, index) => {
                            return (
                              <tr
                                key={index}
                                onClick={() => {
                                  handleStaffRowClick(item.id);
                                }}
                                style={{
                                  backgroundColor: `${
                                    selectedStaffId.includes(item.id)
                                      ? "rgba(100, 24, 195, 0.04)"
                                      : ""
                                  }`,
                                }}
                              >
                                <td style={{ cursor: "pointer" }}>
                                  <img
                                    src={
                                      selectedStaffId.includes(item.id)
                                        ? check
                                        : emptyCheck
                                    }
                                  />
                                  {/* <label className="fancy-checkbox element-left">
                                    <input
                                      type="checkbox"
                                      name="allselect"
                                      onChange={StaffChecked}
                                      value={item.id}
                                      checked={selectedStaffId.includes(
                                        item.id
                                      )}
                                    />
                                    <span></span>
                                  </label> */}
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {item.avatar ? (
                                      <img
                                        className="rounded-circle "
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        src={imageMyProfile + item.avatar}
                                        alt=""
                                      />
                                    ) : (
                                      <Avatar
                                        aria-label="recipe"
                                        className="avatar-font"
                                      >
                                        {item.firstName.toUpperCase()[0] +
                                          item.lastName.toUpperCase()[0]}
                                      </Avatar>
                                    )}
                                    <a className="ml-3">
                                      {item.firstName} {item.lastName}
                                      <div className="d-flex align-items-center wrapTwoLine">
                                        {Util.isValidData(item.last_message) &&
                                        item.last_message.length > 0
                                          ? item.last_message
                                          : item.role}
                                      </div>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-lg-12 offset-lg-12">
                      <div className="d-flex text-center p-5">
                        <i
                          className="card p-5 icon-book-open"
                          style={{
                            fontSize: "50px",
                            opacity: "0.3",
                          }}
                        ></i>
                      </div>
                      <p className="text-center small text-muted">
                        {t("Data Not Found")}
                      </p>
                    </div>
                  </div>
                )}

                <div className="chat-group-label text-start mt-0">
                  {t("Parent")}
                </div>
                {filteredParentList.length > 0 ? (
                  <div className="chat-table-container ">
                    <table className="table">
                      <tbody>
                        {filteredParentList
                          .filter((value) => {
                            if (searchClick == "") {
                              return value;
                            } else if (
                              value.firstName
                                .toLowerCase()
                                .includes(searchClick.toLocaleLowerCase())
                            ) {
                              return value;
                            }
                            return;
                          })
                          .map((item, index) => {
                            return (
                              <tr
                                key={index}
                                style={{
                                  backgroundColor: `${
                                    selectedParentId.includes(item.id)
                                      ? "rgba(100, 24, 195, 0.04)"
                                      : ""
                                  }`,
                                }}
                                onClick={() => handleParentRowClick(item.id)}
                              >
                                <td style={{ cursor: "pointer" }}>
                                  <img
                                    src={
                                      selectedParentId.includes(item.id)
                                        ? check
                                        : emptyCheck
                                    }
                                  />
                                  {/* <label className="fancy-checkbox element-left">
                                    <input
                                      type="checkbox"
                                      name="allselect"
                                      onChange={ParentChecked}
                                      value={item.id}
                                      checked={selectedParentId.includes(
                                        item.id
                                      )}
                                    />
                                    <span></span>
                                  </label> */}
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {item.avatar ? (
                                      <img
                                        className="rounded-circle "
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        src={imageMyProfile + item.avatar}
                                        alt=""
                                      />
                                    ) : (
                                      <Avatar
                                        aria-label="recipe"
                                        className="avatar-font"
                                      >
                                        {item.firstName.toUpperCase()[0] +
                                          item.lastName.toUpperCase()[0]}
                                      </Avatar>
                                    )}
                                    <a className="ml-3">
                                      {item.firstName} {item.lastName}
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                          <img
                                            className="mr-1"
                                            src={student}
                                            height={12}
                                            width={12}
                                          />
                                          {item.child[0].Student.first_name +
                                            " " +
                                            item.child[0].Student.last_name}
                                          <SeparateDot />
                                          {t("Class")}:{" "}
                                          {item.child[0].Student.classroom !==
                                            null &&
                                            item.child[0].Student.classroom
                                              .name}
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-lg-12 offset-lg-12">
                      <div className="d-flex text-center p-5">
                        <i
                          className="card p-5 icon-book-open"
                          style={{
                            fontSize: "50px",
                            opacity: "0.3",
                          }}
                        ></i>
                      </div>
                      <p className="text-center small text-muted">
                        {t("Data Not Found")}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="border-top "
              style={{
                position: "fixed",
                width: "656px",
                bottom: "0px",
                right: getLanguage !== "ar" && "0px",
                background: "#ffffff",
                padding: "10px",
              }}
            >
              <button
                style={{ height: "48px" }}
                className="cancel-btn btn mr-2"
                onClick={() => {
                  onClose();
                }}
              >
                {t("Cancel")}
              </button>

              <button
                style={{ height: "48px", float: "right" }}
                className={`${
                  Util.hasPermission("GROUP_EDIT")
                    ? "confirm-btn btn"
                    : "no-permission"
                }`}
                disabled={
                  selectedParentId.length == 0 && selectedStaffId.length == 0
                }
                onClick={() => {
                  setIsGroupModalOpen(true);
                  onCreateGroup();
                }}
              >
                {t("Create Group")}
              </button>
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
      <Modal
        show={isGroupModalOpen}
        onHide={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            setIsGroupModalOpen(false);
            reset();
            setIsStateUpdated(false);
          }
        }}
        size="md"
        animation={true}
        centered={true}
        dialogClassName="modal-view"
      >
        <div className="d-flex justify-content-between align-items center p-4 border-0">
          <h6>{t("Create Group")}</h6>

          <button
            className="hidden-btn"
            onClick={() => {
              if (isStateUpdated) {
                setIsAlertModal(true);
              } else {
                setIsGroupModalOpen(false);
                reset();
                setIsStateUpdated(false);
              }
            }}
          >
            <img src={Close} className="svg-icon" height={20} width={20} />
          </button>
        </div>
        <Modal.Body>
          <div className="input-filed text-start">
            <span className="basic-text required">{t("Group Name")}</span>
            <input
              type="text"
              className={`form-control ${
                groupNameError.length > 0 ? "input-error" : "custom-input"
              }`}
              onChange={handleGroupName}
              value={groupName}
            />
            {groupNameError.length > 0 && (
              <span className="text-danger">
                <i
                  className="fa fa-exclamation-circle mr-2"
                  aria-hidden="true"
                ></i>
                {groupNameError}
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button
            className={`${
              Util.hasPermission("GROUP_EDIT")
                ? "btn custom-primary"
                : "no-permission"
            }`}
            onClick={handleSaveGroup}
          >
            {t("Save")}
          </button>
        </Modal.Footer>
      </Modal>
      <ExitAlert
        visible={alertModal}
        onClose={() => setIsAlertModal(false)}
        onOk={() => {
          setIsGroupModalOpen(false);
          onClose();
          reset();
          setIsAlertModal(false);
        }}
      />
    </div>
  );
}
