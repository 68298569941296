import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Search from "../../assets/Icon/Search.svg";
import { Avatar } from "@mui/material";
import history from "../../assets/Icon/history.svg";
import ApiCalling from "../../network/ApiCalling";
import { imageStudentList } from "../CommonImage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Util from "../../Util";
import closeImg from "../../assets/Icon/close.svg";

const SearchModal = ({
  size,
  show,
  onHide,
  title,
  subTitle,
  onCancel,
  onConfirm,
  onCancelClick,
  onConfirmClick,
}) => {
  const { t } = useTranslation();
  const history1 = useHistory();
  const getLanguage = Util.getSelectedLanguage();
  const [studentList, setStudentList] = useState([]);
  const [filteredStudentList, setFilteredStudentList] = useState([]);
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState({
    studentId: 0,
    first_name: "",
    last_name: "",
    photo: "",
  });
  const [searchHistoryList, setSearchHistoryList] = useState([]);
  // const [searchStudent,setSearchStudent] = useState([])[]

  const handleRemoveSingleSearch = (id) => {
    const updatedSearchHistoryList = searchHistoryList.filter(
      (str) => str.studentId !== id
    );
    setSearchHistoryList(updatedSearchHistoryList);

    // Optionally, update the localStorage as well
    localStorage.setItem(
      "searchHistory",
      JSON.stringify(updatedSearchHistoryList)
    );
  };
  const getStudent = async () => {
    ApiCalling.apiCallBodyDataGet(`student/all?str=${search}`).then((res) => {
      // console.log("student list", res.data);
      setStudentList(res.data);
      // setFilteredStudentList(res.data);
    });
  };

  const getSearchHistory = () => {
    const updatedSearchHistory = [...searchHistoryList];
    const isStudentAlreadyInHistory = updatedSearchHistory.some(
      (item) => item.studentId === searchClick.studentId
    );
    if (!isStudentAlreadyInHistory) {
      updatedSearchHistory.unshift(searchClick);
      if (updatedSearchHistory.length > 10) {
        updatedSearchHistory.pop();
      }
      setSearchHistoryList(updatedSearchHistory);
      localStorage.setItem(
        "searchHistory",
        JSON.stringify(updatedSearchHistory)
      );
    }
  };

  useEffect(() => {
    getStudent();
  }, [search]);

  useEffect(() => {
    if (searchClick.studentId > 0) {
      getSearchHistory();
    }
  }, [searchClick]);
  useEffect(() => {
    const searchHistory =
      JSON.parse(localStorage.getItem("searchHistory")) || [];
    setSearchHistoryList(searchHistory);
  }, []);

  return (
    <div className="modal-view">
      <Modal
        id="modal-bx"
        style={{
          border: "0px",
          display: "block",
          margin: "0 auto",
          borderRadius: "8px",
        }}
        // size={size}
        size="lg"
        onHide={() => {
          onHide();
          setSearch("");
        }}
        show={show}
        animation={true}
        centered={false}
        dialogClassName="modal-view"
        onShow={() => {
          setSearch("");
        }}
      >
        <span
          style={{
            width: "30px",
            height: "30px",
            backgroundColor: "#ffffff",
            position: "absolute",
            top: "-5px",
            transform: `rotate(45deg)`,
            right: 100,
          }}
        ></span>
        <Modal.Header className="border-0 ">
          <div
            className="serach-wrap custom-input"
            style={{
              position: "relative",
              width: "100%",
              overflow: "hidden",
              height: "40px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="input-group" style={{ width: "90%" }}>
              <input
                className="form-control "
                style={{ border: "none" }}
                placeholder="Search"
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  // const searchValue = e.target.value;
                  // if (searchValue.trim() === "") {
                  //   setFilteredStudentList(studentList);
                  // } else {
                  //   const filteredStudent = studentList.filter((s) =>
                  //     s.first_name
                  //       .toLowerCase()
                  //       .includes(searchValue.toLowerCase())
                  //   );
                  //   setFilteredStudentList(filteredStudent);
                  // }
                }}
              />
            </div>
            <img
              src={Search}
              alt="Search"
              className="img-responsive logo"
              width={"20px"}
              style={{
                position: "absolute",
                right:getLanguage !== "ar" && "10px",
                top: "8px",
                cursor: "pointer",
                left:getLanguage == "ar" && "10px"
              }}
            />
          </div>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: "0 " }}>
          <div className="search-list">
            <div className="d-flex align-items-center justify-content-between">
              <span style={{ fontSize: "12px" }}>
                {search.length == 0 &&
                searchHistoryList.some((s) => s.studentId > 0)
                  ? "Last Search"
                  : search.length > 0 && filteredStudentList.length == 0
                  ? "No match found"
                  : ""}
              </span>
              {search.length == 0 &&
                searchHistoryList.some((s) => s.studentId > 0) && (
                  <span
                    style={{ fontSize: "12px", cursor: "pointer" }}
                    onClick={() => {
                      localStorage.setItem("searchHistory", JSON.stringify([]));
                      setSearchHistoryList([]);
                    }}
                  >
                    Clear all
                  </span>
                )}
            </div>

            <div className="search-container">
              {search.length > 0 ? (
                <>
                  {studentList.map((stu, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          className="d-flex align-items-baseline mt-2"
                        >
                          <div>
                            <img
                              src={Search}
                              alt="Search"
                              className="img-responsive logo"
                              width={"20px"}
                            />
                          </div>
                          <div>
                            {stu.photo ? (
                              <img
                                className="mx-2 rounded-circle"
                                width={"40px"}
                                height={"40px"}
                                src={imageStudentList + stu.photo}
                                alt=""
                              />
                            ) : (
                              <Avatar
                                aria-label="recipe"
                                className="avatar-font mx-2 rounded-circle"
                              >
                                {stu.first_name.toUpperCase()[0] +
                                  stu.last_name.toUpperCase()[0]}
                              </Avatar>
                            )}
                          </div>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#07112B",
                              fontWeight: "400",
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              await setSearchClick({
                                studentId: stu.id,
                                first_name: stu.first_name,
                                last_name: stu.last_name,
                                photo: stu.photo,
                              });
                              await history1.push({
                                pathname: "profileTabs",
                                studentId: stu.id,
                              });
                              await onHide();
                            }}
                          >
                            {stu.first_name + " " + stu.last_name}
                          </p>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  {searchHistoryList.map((str, index) => {
                    return (
                      <>
                        {str.studentId > 0 && (
                          <div
                            key={index}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div className="d-flex align-items-baseline mt-2">
                              <div>
                                <img
                                  src={history}
                                  alt="Search"
                                  className="img-responsive logo mr-2"
                                  width={"20px"}
                                />
                              </div>
                              <div>
                                {Util.isValidData(str.photo) ? (
                                  <img
                                    className="rounded-circle mr-1"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                    }}
                                    src={imageStudentList + str.photo}
                                    alt=""
                                  />
                                ) : (
                                  <Avatar
                                    aria-label="recipe"
                                    className="avatar-font"
                                  >
                                    {str.first_name.toUpperCase()[0] +
                                      str.last_name.toUpperCase()[0]}
                                  </Avatar>
                                )}
                              </div>
                              <p
                                className="ml-1"
                                style={{
                                  fontSize: "14px",
                                  color: "#07112B",
                                  fontWeight: "400",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  history1.push({
                                    pathname: "profileTabs",
                                    studentId: str.studentId,
                                  });
                                  onHide();
                                }}
                              >
                                {str.first_name + " " + str.last_name}
                              </p>
                            </div>
                            <img
                              style={{ cursor: "pointer" }}
                              src={closeImg}
                              onClick={() => {
                                handleRemoveSingleSearch(str.studentId);
                              }}
                            />
                          </div>
                        )}
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default SearchModal;
