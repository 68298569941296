import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import ApiCalling from "../../../network/ApiCalling";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useHistory } from "react-router-dom";
import Util from "../../../Util";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Close from "../../../assets/Icon/close.png";
import Loader from "../../../components/common/Loader";

export default function AddContact({
  studentId,
  isVisible,
  onSave,
  onClose,
  studentName,
}) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [LastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [relation, setRelation] = useState(0);
  const [relationError, setRelationError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [relationListOfParent, setRelationListOfParent] = useState([]);
  const [isPrimary, setIsPrimary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameError("");
  };
  const handleLastName = (e) => {
    setLastName(e.target.value);
    setLastNameError("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };
  const handleRelation = (e) => {
    setRelation(e.target.value);
    setRelationError("");
  };
  const handleIsPrimaryContact = (e) => {
    setIsPrimary(e.target.checked);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setRelationError("");
    let error = false;

    if (firstName === "") {
      setFirstNameError(t("First Name is required"));
      error = true;
    }
    if (lastName === "") {
      setLastNameError(t("Last Name is required"));
      error = true;
    }
    if (email === "") {
      setEmailError(t("Email is required"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    }
    if (relation === 0) {
      setRelationError("Please Select Relation");
      error = true;
    }
    if (phone === "") {
      setPhoneError(t("Phone Number is required"));
      error = true;
    }

    if (!error) {
      setIsLoading(true);
      const Data = {
        student_id: studentId,
        first_name: firstName,
        last_name: lastName,
        email: email,
        student_parent_relation_type_id: relation,
        phone: phone,
        is_primary: isPrimary ? "true" : "false",
      };
      ApiCalling.apiCallBodyDataPost(`parent/add-parent`, Data)
        .then((res) => {

          if (res.data.status == true) {
            setIsLoading(false);
            onSave();
          } else {
            setEmailError(res.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  const relationList = async () => {
    ApiCalling.apiCallParamsGet("student-parent-relation-type").then((res) => {
      setRelationListOfParent(res.data);
    });
  };

  

  useEffect(() => {
    relationList();
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={isVisible}
        onHide={onClose}
        animation={true}
        centered={true}
        dialogClassName="modal-view"
        onShow={() => {
          setFirstNameError("");
          setLastNameError("");
          setEmailError("");
          setPhoneError("");
          setRelationError("");

          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setRelation(0);
          setIsPrimary(false);
        }}
      >
       
        <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                    <h6>
                    {t(" Add New Contact of ") + studentName}
                    </h6>

                    <button className="hidden-btn" onClick={onClose}>
                      <img
                        src={Close}
                        className="svg-icon"
                        height={20}
                        width={20}
                      />
                    </button>
                  </div>
        <Modal.Body>
          <form action="#">
            {!isLoading ? (
              <>
                <div className="form-group">
                  <div className="input-filed">
                    <label>{t("First Name")}*</label>
                    <input
                      type="text"
                      className="form-control"
                      // placeholder="First Name"
                      onChange={handleFirstName}
                      value={firstName}
                    />
                    {firstNameError ? (
                      <span className="text-danger">{firstNameError}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="input-filed">
                    <label>{t("Last Name")}*</label>
                    <input
                      className="form-control"
                      // placeholder="Last Name"
                      type="text"
                      onChange={handleLastName}
                      value={lastName}
                    />
                    {LastNameError ? (
                      <span className="text-danger">{LastNameError}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="input-filed">
                    <label>{t("Email")}*</label>
                    <input
                      className="form-control"
                      // placeholder="Email"
                      type="email"
                      onChange={handleEmail}
                      value={email}
                    />
                    {emailError ? (
                      <span className="text-danger">{emailError}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="input-filed">
                    <label>{t("Relation with Student")}*</label>
                    <select
                      name=""
                      id=""
                      className="select-dropdown"
                      value={relation}
                      onChange={handleRelation}
                    >
                      <option selected disabled value="0">
                        {t("Select")}
                      </option>
                      {relationListOfParent?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </select>
                    {relationError ? (
                      <span className="text-danger">{relationError}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="input-filed">
                    <label>{t("Phone")}*</label>

                    <div
                      className={`form-control ${
                        phoneError.length > 0 ? "input-error" : "phone-input"
                      }`}
                      tabIndex={0}
                    >
                      <PhoneInput
                      tabIndex="-1"
                        international
                        defaultCountry="IN"
                        onChange={(value) => {
                          setPhone(value);
                          setPhoneError("");
                        }}
                        // disabled={true}
                        value={phone}
                        countryCallingCodeEditable={false}
                        flags={false}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  {phoneError ? (
                    <span className="text-danger ">{phoneError}</span>
                  ) : (
                    ""
                  )}
                  <div className="fancy-checkbox">
                    <label>
                      <input
                        name="primary"
                        type="checkbox"
                        checked={isPrimary}
                        onChange={handleIsPrimaryContact}
                      />
                      <span tabIndex={0}>
                        <strong>{t("is Primary Contact")}</strong>
                      </span>
                    </label>
                  </div>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn custom-primary btn-lg"
            type="button"
            onClick={handleSave}
          >
            {t("Save Contact")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
