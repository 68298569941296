import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ToastHOC from "../../../HOC/ToastHOC";
import { useHistory } from "react-router-dom";
import ApiCalling from "../../../../network/ApiCalling";
import Util from "../../../../Util";
import { Rings } from "react-loader-spinner";
import ModalComponent from "../../ModalComponent";

function StaffInactive(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { setIsToast } = props;
  const pendingToast = props.props.location?.pendingToast;
  const getUserSchoolId = props?.getUserSchoolId;
  const [showInActiveModal, setShowInActiveModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInActive = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`user/remove-user-school/${getUserSchoolId}`)
      .then((res) => {
        setIsLoading(false);
        setShowInActiveModal(false);
        history.push({
          pathname: "/staff",
          pendingToast: res.data.message,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);
  return (
    <div>
      <div className="container">
        <div className="row">
          <div>
            <div className="col-lg-12">
              <div className="alert alert-danger m-5 align-center">
                <h6>{t("You are about to Inactivate the Staff")}.</h6>
                <div>
                  {t("This will hide all Staff related data from the portal")}
                  <br />
                  {t("Are you sure")}?
                </div>
                <br />
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setShowInActiveModal(true);
                  }}
                >
                  {t("Inactive")}
                </button>
              </div>
            </div>
            <ModalComponent
              show={showInActiveModal}
              onHide={() => {
                setShowInActiveModal(false);
              }}
              title={t("InActive")}
              subTitle={t("Are You Sure to InActive")}
              size="sm"
              onCancel={t("Cancel")}
              onConfirm={t("Confirm")}
              onCancelClick={() => {
                setShowInActiveModal(false);
              }}
              onConfirmClick={handleInActive}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(StaffInactive);
