import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ApiCalling from "../../../network/ApiCalling";
import * as XLSX from "xlsx/xlsx.mjs";
import PageHeader from "../../../components/PageHeader";
import { Button, Dropdown } from "react-bootstrap";
import ToastHOC from "../../HOC/ToastHOC";
import { useHistory } from "react-router-dom";
import Util from "../../../Util";
import sampleFile from "../../../assets/File/sample Add-Staff.xlsx";
import { useTranslation } from "react-i18next";

const AddStaff = (props) => {
  const {t}=useTranslation();
  const isBulkUpload = props.location.isBulkUpload;
  const { setIsToast } = props;
  const [tableData, setTableData] = useState([]);
  const [role, setRole] = useState([]);
  const [file, setFile] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [saveClicked, setSaveClicked] = useState(false);
  const [Errors, setErrors] = useState([]);
  const history = useHistory();
  const [fields, setFields] = useState([
    {
      id: 1,
      firstname: "",
      lastname: "",
      email: "",
      roledata: "",
      roleid: 0,
      message: "",
    },
  ]);

  const handleAddField = () => {
    setSaveClicked(false);
    setFields([
      ...fields,
      {
        id: fields.length + 1,
        firstname: "",
        lastname: "",
        email: "",
        roledata: "",
        roleid: 0,
        message: "",
      },
    ]);
  };

  const handleRemoveField = (index) => {
    setFields(fields.filter((field, i) => i !== index));
  };

  const handleInputChange = (index, event) => {
    const newFields = [...fields];
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
  };

  const handleSelectRoleChange = (index, event) => {
    const newFields = [...fields];
    newFields[index]["roleid"] = event.target.value;
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const getData = async () => {
    ApiCalling.apiCallParamsGet("role/list")
      .then((res) => {
        setRole(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleFileRead = () => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const fileData = XLSX.utils.sheet_to_json(firstSheet);
      var temp = [];
      var i = 0;
      fileData.forEach((element) => {
        var RoleId = 0;
        role.map((r) => {
          if (r.role == element.Role) {
            RoleId = r.id;
          }
        });
        temp.push({
          id: i,
          firstname: element.FName,
          lastname: element.LName,
          email: element.Email,
          roledata: element.Role,
          roleid: RoleId,
        });
        i++;
      });
      setFields(temp);
      setFileData(fileData);
    };

    reader.readAsArrayBuffer(file);
  };
  const handleSave = (event) => {
    event.preventDefault();
    setSaveClicked(true);
    var error = false;
    fields.map((val, index) => {
      if (
        val.firstname.length == 0 ||
        val.lastname.length == 0 ||
        val.email.length == 0 ||
        val.roleid == 0 ||
        Util.email_regex.test(val.email) === false
      ) {
        error = true;
      }
    });
    if (!error) {
      fields.map((field, index) => {
        const data = {
          firstName: field.firstname,
          lastName: field.lastname,
          email: field.email,
          roleId: field.roleid,
        };

        ApiCalling.apiCallBodyDataPost("user/add-staff", data)
          .then((res) => {
            const msg = res.data.message;
            const array = [...fields];
            array[index].message = msg;
            setFields(array);
          })
          .catch((err) => {
            console.log("Error", err);
          });
      });
      setIsToast(t("Staff adding Completed"));
    }
  };

  function getStaffRow(field, index) {
    return (
      <>
        <tr key={index}>
          <td>
            <input
              style={{ fontSize: "13px" }}
              type="text"
              value={field.firstname}
              placeholder="First Name"
              onChange={(event) => handleInputChange(index, event)}
              name="firstname"
              className="form-control"
            />
            { field.firstname.length == 0 && saveClicked && (
              <div className="text-danger">{t("First Name Required")}</div>
            )}
            {!isBulkUpload && field.message.length > 0 ? (
              <div className="text-success">{field.message}</div>
            ) : (
              ""
            )}
          </td>

          <td>
            <input
              style={{ fontSize: "13px" }}
              type="text"
              value={field.lastname}
              placeholder="Last Name"
              onChange={(event) => handleInputChange(index, event)}
              name="lastname"
              className="form-control"
            />
            { field.lastname.length == 0 && saveClicked && (
              <div className="text-danger">{t("Last Name Required")}</div>
            )}
          </td>
          <td>
            <input
              style={{ fontSize: "13px" }}
              type="text"
              placeholder="Email"
              value={field.email}
              onChange={(event) => handleInputChange(index, event)}
              name="email"
              className="form-control"
            />
            { field.email.length == 0 && saveClicked && (
              <div className="text-danger">{t("Email Required")}</div>
            )}
            {
              field.email.length > 0 &&
              Util.email_regex.test(field.email) === false &&
              saveClicked && <div className="text-danger">{t("Invalid Email")}</div>}
          </td>
          <td>
            <select
              style={{ fontSize: "13px", color: "gray" }}
              className="form-select"
              name="roledata"
              onChange={(event, i) => handleSelectRoleChange(index, event)}
            >
              <option value={0}>{t("Select Role")}</option>
              {role.map((option) => {
                if (field.roledata == option.role) {
                  return (
                    <>
                      <option selected value={option.id} key={option.id}>
                        {option.role}
                      </option>
                    </>
                  );
                } else {
                  return (
                    <>
                      <option key={option.id} value={option.id}>{option.role}</option>
                    </>
                  );
                }
              })}
            </select>
            { field.roleid == 0 && saveClicked && (
              <div className="text-danger">{t("Role is Required")}</div>
            )}
          </td>
          <td>
            <a className="text-danger" onClick={() => handleRemoveField(index)}>
              <DeleteIcon />
            </a>
          </td>
        </tr>
      </>
    );
  }

  return (
    <div>
      <div style={{ flex: 1 }}>
        <div>
          <div className="ng-star-inserted">
            <div className="container-fluid">
              <div className="d-flex align-items-center gap-2 mb-3">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/staff");
                  }}
                >
                  <i className="fa fa-chevron-left" aria-hidden="true"></i>
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/staff");
                  }}
                >
                  {t("Staff")}
                </a>
              </div>

              <div className="body card">
                {!isBulkUpload || fileData.length > 0 ? (
                  <table className="table table-hover ">
                    <tr>
                      <th> {t("First Name")}* </th> <th> {t("Last Name")}* </th> <th> {t("Email")}* </th>{" "}
                      <th> {t("Role")}* </th>
                      <th></th>
                    </tr>
                    {fields.map((field, index) => {
                      return getStaffRow(field, index);
                    })}
                    <tr>
                      <td colSpan={5}>
                        {!file ? (
                          <a className="text-success" onClick={handleAddField}>
                            + {t("Add more Staff")}
                          </a>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  </table>
                ) : null}
                {isBulkUpload && fileData.length == 0 ? (
                  <div className="row p-4">
                    <div className="col-12">
                      <div className="alert alert-secondary">
                        <div className="pb-4">
                          <strong>
                            {t("Choose Excel File To Upload Staff in Bulk")}
                          </strong>
                        </div>
                        <div className="col-12">
                          <input
                           accept=".xlsx, .xls"
                            type="file"
                            className="form-group"
                            onChange={handleFileChange}
                          />
                        </div>
                        <div className="col-12 text-right">
                          <button
                            className="btn custom-primary"
                            onClick={handleFileRead}
                          >
                            {t("Upload")}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 pt-4 text-right">
                        <a href={sampleFile} target="_blank">
                          <button className="btn btn-link">
                            <i className="fa fa-download"></i> {t("Download sample template")}
                          </button>
                        </a>
                    </div>
                  </div>
                ) : null}
                {!isBulkUpload || fileData.length > 0 ? (
                  <div className="text-center pb-4">
                    <Button
                      style={{
                        width: "20%",
                      }}
                      onClick={handleSave}
                    >
                      {t("Save")}
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(AddStaff);
