import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import ApiCalling from "../../network/ApiCalling";
import Util from "../../Util";
import moment from "moment";
import Loader from "../../components/common/Loader";
import ModalComponent, { ModalView } from "../Dashbord/ModalComponent";
import Invalid from "../../assets/Icon/invalid.svg";

import DatePicker from "react-datepicker";
import { CustomInput } from "./NewAdmisson";
import ToastHOC from "../HOC/ToastHOC";
import TagButton from "../Dashbord/TagButton";
import {
  browneTagStyle,
  greenTagStyle,
  orangeTagStyle,
} from "../Dashbord/TagButtonStyle";
import { Sort } from "./Admission";

const Activity = (props) => {
  const { data } = props;
  console.log("DATA", props);
  const [activitylist, setActivityList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const school = Util.getSelectedSchool();
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState("");
  const [date, setDate] = useState(null);
  const [contactway, setContactway] = useState([]);
  const [contactId, setContactId] = useState(null);
  const [editId, setEditId] = useState(null);
  const datepickerRef = useRef(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [contactwayErr, setContactwayErr] = useState("");
  const [dateErr, setDateErr] = useState("");

  const [commentErr, setCommentErr] = useState("");

  const { setIsToast } = props;
  const lead_id = props?.lead_id;

  const handleCloseModal = () => {
    setShow(false);
    reset();
    setEditId(null);
    setDeleteId(null);
    setCommentErr("");
    setContactwayErr("");
    setDateErr("");
  };
  const handleOpenModal = () => {
    setShow(true);
  };

  function reset() {
    setComment("");
    setContactId(null);
    setDate(null);
  }

  useEffect(() => {
    reset();
  }, []);

  function getActivity() {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("lead-activity/list/", { lead_id })
      .then((res) => {
        console.log("response", res);
        if (res?.data?.status) {
          setIsLoading(false);
          setActivityList(res?.data?.response);
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }
  const getContactWay = () => {
    ApiCalling.apiCallBodyDataGet("lead-contact-way/list/")
      .then((res) => {
        if (res.status) {
          setContactway(res?.data);
        } else {
          console.error("ERROR");
        }
      })
      .catch((error) => {
        console.error("ERROR", error);
      });
  };
  useEffect(() => {
    getActivity();
    getContactWay();
  }, []);

  const handleSaveAndClose = async () => {
    setCommentErr("");
    setContactwayErr("");
    setDateErr("");
    let error = false;

    if (comment === "") {
      setCommentErr("Comment is required");
      error = true;
    }
    if (contactId === null) {
      setContactwayErr("Contact via required");
      error = true;
    }
    if (date === null || date === "") {
      setDateErr("Date is required");
      error = true;
    }
    if (error) {
      return;
    }
    if (editId) {
      await handleUpdateActivity();
    } else {
      await handleCreateActivity();
    }
    handleCloseModal();
  };
  function handleCreateActivity() {
    const data = {
      lead_id: lead_id,
      comment: comment,
      contact_way_id: contactId,
      activity_date: date,
    };

    ApiCalling.apiCallBodyDataPost("lead-activity/create/", data)
      .then((res) => {
        console.log(res);
        if (res?.status) {
          setIsToast("created activity successfully");
          getActivity();
          handleCloseModal();
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }
  function handleEditActivity(data) {
    setComment(data?.comment);
    setContactId(data?.Contact_Way?.id);
    setDate(data?.activity_date ? new Date(data?.activity_date) : null);
    handleOpenModal();
    setEditId(data.id);
  }
  async function handleUpdateActivity() {
    const data = {
      id: editId,
      comment: comment,
      contact_way_id: contactId,
      activity_date: date,
    };
    try {
      ApiCalling.apiCallBodyDataPost("lead-activity/update/", data).then(
        (res) => {
          if (res?.status) {
            console.log("update", res);
            setIsLoading(false);
            setIsToast(res.data.message);
            getActivity();
            handleCloseModal();
          }
        }
      );
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  function handleDeleteActivity() {
    ApiCalling.apiCallDelete(`lead-activity/delete/${deleteId}`)
      .then((res) => {
        console.log(res);
        if (res.status) {
          setIsToast(res?.data?.message);
          setShowDeleteConfirmation(false);
          getActivity();
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  return (
    <>
      <div className="inside-grays w-100">
        <div className="row text-start text-nowrap">
          <div className="col">
            <p>{t("Name")}</p>

            <span className="text-bold">
              {Util.capitalizeString(
                data?.student_first_name + " " + data?.student_last_name
              )}
            </span>
          </div>
          <div className="col">
            <p>{t("Program ")}</p>
            <span className="text-bold">{data?.Lead_Program?.title}</span>
          </div>

          <div className="col">
            <p className="">{t("Status")}</p>

            <TagButton
              size={"100px"}
              title={
                data?.lead_status === 0
                  ? "Pending"
                  : data?.lead_status === 1
                  ? "Converted"
                  : data?.lead_status === 2
                  ? "Closed"
                  : ""
              }
              buttonStyle={
                data?.lead_status === 0
                  ? greenTagStyle
                  : data?.lead_status === 1
                  ? orangeTagStyle
                  : data?.lead_status === 2
                  ? browneTagStyle
                  : ""
              }
              icon="fa fa-circle"
              iconSize="6px"
            />
          </div>
        </div>
      </div>

      <hr style={{ backgroundColor: "#E5E7EB" }} className="mt-4" />
      <div className="container-fluid">
        <div className="row">
          <div className="header text-right mb-3 px-0">
            <button
              className={`${
                Util.hasPermission("MASTERS_ADD")
                  ? "btn custom-primary"
                  : "no-permission"
              }`}
              type="button"
              aria-expanded="false"
              style={{ color: "#fff", padding: "5px, 10px" }}
              onClick={handleOpenModal}
            >
              {t("Add New Activity ")}
            </button>
          </div>

          <div className="table-div overflow-hidden m-0 p-0">
            <div className="col-lg-12 col-md-12 p-0">
              <div className="body">
                {!isLoading ? (
                  <>
                    <table className="table" id="activityTable">
                      <thead>
                        <tr className="text-nowrap">
                          <th scope="col"> {t("Comment")}</th>
                          <th scope="col">{t("Contact Via")}</th>
                          <th scope="col">{t("Next Date")} </th>
                          <th scope="col">{t("Create on Date")} </th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      {activitylist?.length > 0 ? (
                        <tbody>
                          <>
                            {activitylist?.map((item) => (
                              <tr key={item.id}>
                                <td
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "normal",
                                    maxWidth: "650px",
                                    wordWrap: "break-word",
                                    minHeight: "40px !important",
                                  }}
                                >
                                  <div
                                    style={{
                                      textJustify: "inter-word",
                                      alignItems: "stretch",
                                    }}
                                  >
                                    {item?.comment}
                                  </div>
                                </td>
                                <td>{item?.Contact_Way?.title}</td>

                                <td>
                                  {Util.isValidData(item?.activity_date)
                                    ? moment(item?.activity_date).format(
                                        Util.getMomentDateTimeFormat(
                                          school.date_format
                                        )
                                      )
                                    : "-"}
                                </td>
                                <td>
                                  {Util.isValidData(item?.created_on)
                                    ? moment(item?.created_on).format(
                                        Util.getMomentDateTimeFormat(
                                          school.date_format
                                        )
                                      )
                                    : "-"}
                                </td>

                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleEditActivity(item);
                                    }}
                                    className={`${
                                      !Util.hasPermission("MASTERS_EDIT") &&
                                      "no-permission "
                                    }`}
                                  >
                                    <i className="fa pl-2 fa-edit cursor"></i>
                                  </a>

                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowDeleteConfirmation(true);
                                      setDeleteId(item.id);
                                    }}
                                    className={`${
                                      Util.hasPermission("MASTERS_DELETE")
                                        ? ""
                                        : "no-permission"
                                    }`}
                                  >
                                    <i className="fa ml-3 fa-trash-o text-danger cursor"></i>
                                  </a>
                                </td>
                                <td></td>
                              </tr>
                            ))}
                          </>
                        </tbody>
                      ) : (
                        <tfoot>
                          <tr>
                            <td colSpan={4}>
                              {t(
                                "There are no activity. Click the 'Add activity' button to create one."
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      )}
                    </table>
                    {activitylist?.length > 0 && (
                      <>
                        <div className="small text-muted mb-3 pl-3 border-top py-3">
                          <i>
                            {activitylist?.length} {t("conversation done")}.
                          </i>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <Loader />
                )}
              </div>
              <ModalComponent
                show={showDeleteConfirmation}
                onHide={() => {
                  setShowDeleteConfirmation(false);
                }}
                title={t("Delete")}
                onCancel={t("Cancel")}
                onConfirm={t("Confirm")}
                subTitle={t("Are you sure, to delete this activity?")}
                size="sm"
                onCancelClick={() => {
                  setShowDeleteConfirmation(false);
                }}
                onConfirmClick={handleDeleteActivity}
              />
            </div>
          </div>
        </div>
        <ModalView
          show={show}
          handleCloseModal={handleCloseModal}
          title={editId ? "Edit Activity" : "Add Activity"}
          handleSaveAndClose={handleSaveAndClose}
        >
          <div className="input-filed">
            <span className="basic-text required">{t("Contact via")}</span>
            <select
              value={contactId}
              onChange={(e) => {
                setContactId(e.target.value);
              }}
              className={`select-dropdown form-select custom-input ${
                contactwayErr.length > 0 ? "input-error" : ""
              }`}
            >
              <option value="">{t("Select Contact")}</option>
              {contactway?.map((item) => (
                <option key={item.id} value={item?.id}>
                  {item?.title}
                </option>
              ))}
            </select>
            {contactwayErr.length > 0 && (
              <span className=" invalid-error">
                <img
                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                  src={Invalid}
                />
                {contactwayErr}
              </span>
            )}
          </div>
          <div className="input-filed">
            <span className="basic-text required">{t("Next Date")}</span>

            <DatePicker
              ref={datepickerRef}
              className={`select-dropdown form-select custom-input ${
                dateErr?.length > 0 ? "input-error" : ""
              }`}
              placeholderText="Select Date"
              selected={date}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={(date) => setDate(date)}
              dateFormat={school?.date_format}
              popperPlacement="bottom"
              // maxDate={new Date()}
              customInput={<CustomInput error={dateErr} />}
            />
            {dateErr?.length > 0 && (
              <span className=" invalid-error">
                <img
                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                  src={Invalid}
                />
                {dateErr}
              </span>
            )}
          </div>
          <div className="input-filed ">
            <span className="basic-text required">{t("Comment")}</span>
            <>
              <textarea
                wrap="soft"
                className={`form-control custom-input ${
                  commentErr.length > 0 ? "input-error" : ""
                }`}
                value={comment}
                rows={50}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
              {commentErr.length > 0 && (
                <span className="text-danger">
                  <img
                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                    src={Invalid}
                  />
                  {commentErr}
                </span>
              )}
            </>
          </div>
        </ModalView>
      </div>
    </>
  );
};

export default ToastHOC(Activity);
