import React, { useContext, useEffect, useState } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Search from "../../assets/Icon/Search.svg";
import { Avatar } from "@mui/material";
import ApiCalling from "../../network/ApiCalling";
import { imageMyProfile } from "../CommonImage";
import check from "../../assets/Icon/check-double.svg";
import Util from "../../Util";
import { SocketContext } from "../../context/SocketContext";
import { useTranslation } from "react-i18next";

export default function NewStaffChat({ show, onClose, onStaffClick }) {
  const getLanguage = Util.getSelectedLanguage();
  const { t } = useTranslation();
  const [search_Staff, setSearch_Staff] = useState("");
  const [filteredStaffList, setFilteredStaffList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [onlineUsersList, setOnlineUsersList] = useState([]);
  const socket = useContext(SocketContext);

  useEffect(() => {
    const tempStaffList = staffList;
    let newTempStaffList = tempStaffList.map((obj) => {
      var online = false;
      online = onlineUsersList.some(
        (onlineUsers) => onlineUsers.userId === obj?.user?.id
      );
      return { ...obj, isOnline: online };
    });
    setStaffList(newTempStaffList);
  }, [onlineUsersList]);
  useEffect(() => {
    let isGetUSerMounted = true
    socket.on("get-users", (users) => {
      // console.log("get-users");
      if(isGetUSerMounted){

        setOnlineUsersList(users);
      }
    });

    const handleFocus = async () => {
      // socket.emit("new-user-add", user.id);
      socket.on("get-users", (users) => {
        // console.log("get-users on handle Focus");
        setOnlineUsersList(users);
      });
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
      isGetUSerMounted = false
    };
  }, []);

  useEffect(() => {
    let staffListMounted = true;
    const getStaffList = () => {
      ApiCalling.apiCallBodyDataGet("chatroom/get-staff")
        .then((res) => {
          if (staffListMounted) {
            const tempStaffList = res.data;

            let newTempStaffList = tempStaffList.map((obj) => {
              var online = false;
              online = onlineUsersList.some(
                (onlineUsers) => onlineUsers.userId === obj?.id
              );
              return { ...obj, isOnline: online };
            });
            setStaffList(newTempStaffList);
            setFilteredStaffList(newTempStaffList);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    getStaffList();
    return () => {
      staffListMounted = false;
    };
  }, []);

  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={show}
        onClose={onClose}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">{t("New Chat Staff")}</div>
              <IconButton aria-label="close" size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="body mt-3">
              <div className="row mt-2">
                <div className="input-group">
                  <input
                    className="form-control search "
                    placeholder="Search"
                    type="text"
                    value={search_Staff}
                    onChange={(e) => {
                      // setSearch_Staff(e.target.value);
                      setSearch_Staff(e.target.value);
                      const searchValue = e.target.value;
                      if (searchValue.trim() === "") {
                        setFilteredStaffList(staffList);
                      } else {
                        const filteredStaff = staffList.filter((s) =>
                          s.firstName
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        );
                        setFilteredStaffList(filteredStaff);
                      }
                    }}
                  />
                  <div className="input-group-prepend">
                    <div className="search-icon">
                      <img src={Search} className="svg-icon" alt="" />
                    </div>
                  </div>
                </div>
                {filteredStaffList.length > 0 ? (
                  <div className="chat-staff-container mt-3">
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredStaffList.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onStaffClick(item);
                                  }}
                                >
                                  <div className="d-flex align-items-center justify-content-start">
                                    <div>
                                      {item.avatar ? (
                                        <img
                                          className="rounded-circle "
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                          }}
                                          src={imageMyProfile + item.avatar}
                                          alt=""
                                        />
                                      ) : (
                                        <Avatar
                                          aria-label="recipe"
                                          className="avatar-font"
                                          // style={{
                                          //   backgroundColor: "#E5E7Eb",
                                          //   color: "#07112B",
                                          //   fontWeight: "600",
                                          //   fontSize: "14px",
                                          // }}
                                        >
                                          {item.firstName.toUpperCase()[0] +
                                            item.lastName.toUpperCase()[0]}
                                        </Avatar>
                                      )}
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <a
                                        className="ml-3"
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          // onStaffClick(item);
                                        }}
                                      >
                                        {item.firstName} {item.lastName}
                                        <div
                                          className="d-flex align-items-center"
                                          style={{
                                            overflow: "hidden",
                                            display: "-webkit-box",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "normal", // This allows the text to wrap if it's less than two lines
                                            maxHeight: "3.6em", // You can adjust this value to control the height for two lines
                                          }}
                                        >
                                          {Util.isValidData(item.last_message) &&
                                          item.last_message.length > 0
                                            ? item.last_message
                                            : item.role}
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                  <div>
                                    <div>
                                      {Util.getDateDisplay_ChatMessage(
                                        item.last_message_on
                                      )}
                                    </div>
                                    {item.isOnline && <img src={check} />}
                                  </div>
                                  {/* <div className="about">
                                    <div className="col-9 name">
                                      {item.firstName} {item.lastName}
                                    </div>

                                    <div className="col-3 last-message-time text-right">
                                      {Util.getDateDisplay_ChatMessage(
                                        item.last_message_on
                                      )}
                                    </div>

                                    <div
                                      className="col-10 last-message"
                                      // style={{
                                      //   overflow: "hidden",
                                      //   textOverflow: "ellipsis",
                                      //   whiteSpace: "nowrap",
                                      // }}
                                      style={{
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "normal", // This allows the text to wrap if it's less than two lines
                                        maxHeight: "3.6em", // You can adjust this value to control the height for two lines
                                      }}
                                    >
                                      {item.last_message !== null &&
                                      item.last_message.length > 0
                                        ? item.last_message
                                        : item.user.user_Schools[0].role.role}
                                    </div>
                                    {item.isOnline && (
                                      <div className="col-2 online text-right">
                                        <img src={check} />
                                      </div>
                                    )}
                                  </div> */}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-lg-12 offset-lg-12">
                      <div className="d-flex text-center p-5">
                        <i
                          className="card p-5 icon-book-open"
                          style={{
                            fontSize: "50px",
                            opacity: "0.3",
                          }}
                        ></i>
                      </div>
                      <p className="text-center small text-muted">
                        {t("Data Not Found")}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
