import { getSupportedLanguages } from "i18n-iso-countries";
import { t } from "i18next";
import React, { useState, useRef, useEffect, useCallback } from "react";
import Util from "../../Util";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PhoneInput from "react-phone-number-input";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import { useTranslation } from "react-i18next";
import ApiCalling from "../../network/ApiCalling";

import "react-datepicker/dist/react-datepicker.css";
import Calander from "../../assets/Icon/calendar.svg";
import ToastHOC from "../HOC/ToastHOC";
import DatePicker from "react-datepicker";
import Invalid from "../../assets/Icon/invalid.svg";
import axios from "axios";
import ExitAlert from "../../components/ExitAlert";
import Const from "../../Const";
import Loader from "../../components/common/Loader";

const NewAdmisson = (props) => {
  const history = useHistory();
  const { setIsToast } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [indexPage, setIndexPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [stdId, setStdId] = useState(null);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [schoolId, setSchoolId] = useState(null);
  const school = Util.getSelectedSchool();
  const datepickerRef = useRef(null);

  const [dateOfBirth, setDate] = useState(null);

  const [leadProgramId, setLeadProgramId] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [internalComments, setInternalComments] = useState("");
  const [generatedFrom, setGeneratedFrom] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [contactway, setContactway] = useState([]);
  const [leadProgram, setLeadProgram] = useState([]);
  const [pincode, setPincode] = useState(null);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [fatherPhoneNo, setFatherPhoneNo] = useState("");
  const [fatherPhoneNoErr, setFatherPhoneNoErr] = useState("");
  const [motherPhoneNo, setMotherPhoneNo] = useState("");
  const [fatherEmail, setFatherEmail] = useState("");
  const [fatherEmailError, setFatherEmailError] = useState("");

  const [motherEmail, setMotherEmail] = useState("");
  const [motherEmailError, setMotherEmailError] = useState("");

  const [fatherWork, setFatherWork] = useState("");
  const [motherWork, setMotherWork] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [contactwayErr, setContactwayErr] = useState("");
  const [programErr, setProgramErr] = useState("");
  const [dobErr, setDobErr] = useState(null);
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState(null);
  const [state, setState] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [isMotherEmailValid, setIsMotherEmailValid] = useState(true);
  const [isFatherMotherFatherEmailValid, setIsFatherEmailValid] =
    useState(true);
  const [motherPhoneNoErr, setMotherPhoneNoErr] = useState("");

  const [city, setCity] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState("AE");
  const [cityId, setCityId] = useState(null);
  const [alertModal, setIsAlertModal] = useState(false);
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: "",
  });

  const steps = [
    {
      label: `${t("Personal Information")}`,
    },

    {
      label: `${t("Parent Information")}`,
    },
    {
      label: `${t("Address")}`,
    },
  ];

  const handleBack = () => {
    if (indexPage === 0) {
      history.push("/admission");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setIndexPage((indexPage) => indexPage - 1);
    }
  };
  const handleReset = () => {
    setActiveStep(0);
    setIndexPage(0);
  };
  const handleNext = () => {
    let error = false;

    // Reset errors
    setFirstNameError("");
    setLastNameError("");
    setDobErr("");
    setProgramErr("");
    setContactwayErr("");
    setFatherEmailError("");
    setMotherEmailError("");
    setFatherPhoneNoErr("");

    // Validate inputs
    if (indexPage === 0) {
      if (firstName === "") {
        setFirstNameError("First name is required");
        error = true;
      }
      if (lastName === "") {
        setLastNameError("Last name is required");
        error = true;
      }
      if (dateOfBirth === null || "") {
        setDobErr("DOB is required");
        error = true;
      }
      if (leadProgramId === null || "") {
        setProgramErr("Program is required");
        error = true;
      }
      if (contactId === null || "") {
        setContactwayErr("Contact way is required");
        error = true;
      }
    }

    if (indexPage === 1) {
      if (!fatherPhoneNo && !motherPhoneNo) {
        setMotherPhoneNoErr(
          "Either Mother's or Father's mobile number is required."
        );
        return false;
      } else {
        setMotherPhoneNoErr("");
      }
    }

    if (!error) {
      if (indexPage === 2) {
        handleSubmitAdmission();
      } else {
        setIndexPage(indexPage + 1);
      }
      setActiveStep(activeStep + 1);
    }
  };

  const handleSubmitAdmission = () => {
    const formData = {
      student_first_name: firstName,
      student_last_name: lastName,
      contact_way_id: contactId,
      date: dateOfBirth,
      lead_program_id: leadProgramId,
      internal_comments: internalComments,
      father_name: fatherName,
      mother_name: motherName,
      father_phone_number: fatherPhoneNo,
      mother_phone_number: motherPhoneNo,
      father_work_title: fatherWork,
      mother_work_title: motherWork,
      father_email: fatherEmail,
      mother_email: motherEmail,
      pincode: pincode,
      address1: address1,
      address2: address2,
      country_id: countryId,
      state_id: stateId,
      city_id: cityId,
      contact_way_id: contactId,
      lead_program_id: leadProgramId,
    };
    // console.log("formData", formData);
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("lead/create/", formData)
      .then((res) => {
        // console.log("RESPONSE", res);
        if (res?.data?.status === true) {
          setIsLoading(false);

          setIsToast(res?.data?.message);
          setTimeout(() => {
            history.push("/admission");
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  };

  const handleFatherEmailChange = (e) => {
    const email = e.target.value;
    setFatherEmail(email);
    if (Util.email_regex.test(email)) {
      setIsFatherEmailValid(true);
    } else {
      setIsFatherEmailValid(false);
    }
  };
  const handleMotherEmailChange = (e) => {
    const email = e.target.value;
    setMotherEmail(email);
    if (Util.email_regex.test(email)) {
      setIsMotherEmailValid(true);
    } else {
      setIsMotherEmailValid(false);
    }
  };

  const changeDate = (date) => {
    setDate(date);
    setIsStateUpdated(true);
  };

  const getContactWay = () => {
    ApiCalling.apiCallBodyDataGet("lead-contact-way/list/")
      .then((res) => {
        // console.log("CONTACT WAY", res);
        if (res.status === 200) {
          setContactway(res.data);
        } else {
          console.error("ERROR");
        }
      })
      .catch((error) => {
        console.error("ERROR", error);
      });
  };

  const getLeadProgram = () => {
    ApiCalling.apiCallBodyDataGet("lead-program/list/")
      .then((res) => {
        if (res.status === 200) {
          setLeadProgram(res.data);
        }
      })
      .catch((error) => console.log(error));
  };

  const countryList = () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet("country").then((res) => {
      // console.log("COUNTRY", res);
      setIsCountryLoading(false);
      setCountry(res?.data);
    });
  };
  const cityList = (id) => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res?.data);
    });
  };

  useEffect(() => {
    if (countryId) {
      let isStateMounted = true;
      const stateList = (id) => {
        setIsCountryLoading(true);
        ApiCalling.apiCallBodyDataGet(`state/get-states/${countryId}`).then(
          (res) => {
            if (isStateMounted) {
              setIsCountryLoading(false);
              setState(res?.data);
            }
          }
        );
      };
      stateList();
      return () => {
        isStateMounted = false;
      };
    }
  }, [countryId]);
  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((res) => {
        // console.log("FETCH COUNTRY:::", res);
        setDefaultCountry(res.data.country);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);

  useEffect(() => {
    getContactWay();
    getLeadProgram();
    countryList();
  }, []);

  const handleGoToNextPage = useCallback(
    (location) => history.push(location),
    [history]
  );

  useEffect(() => {
    if (triggerExit.onOk) {
      setTimeout(() => {
        handleGoToNextPage(triggerExit.path);
      }, 0);
    }
    const unblock = history.block((location) => {
      if (location.pathname !== "/new-admission" && isStateUpdated) {
        setIsAlertModal(true);
      } else {
        return true;
      }

      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToNextPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    isStateUpdated,
  ]);
  return (
    <>
      {!isLoading ? (
        <>
          <div className="container">
            <div className="body">
              <div className="col-lg col-md-12 p-3">
                <div className="row py-3">
                  <BreadCrumbComponent
                    Breadcrumb={[
                      {
                        name: "Admission",
                        navigate: () => {
                          history.push("/admission");
                        },
                      },

                      { name: `${t("Add New Lead")}` },
                    ]}
                  />
                  <div className="col-md-4 text-start">
                    <h2 className="page-head">{t("Add New Lead")}</h2>
                  </div>
                </div>
                <div className="row mt-0">
                  <div className="col-lg-3">
                    <VerticalLinearStepper
                      activeStep={activeStep}
                      handleNext={handleNext}
                      handleBack={handleBack}
                      handleReset={handleReset}
                    />
                  </div>

                  <div className="col-lg-9">
                    {indexPage === 0 && (
                      <>
                        <h2 className="page-head mb-3">
                          {t("General Information")}
                        </h2>
                        <div className="row  ">
                          <div className="col-6  ">
                            <div className="input-filed">
                              <span className="basic-text required">
                                {t("Program")}
                              </span>
                              <select
                                value={leadProgramId}
                                onChange={(e) => {
                                  setLeadProgramId(e.target.value);
                                  setIsStateUpdated(true);
                                }}
                                className={`select-dropdown form-select custom-input ${
                                  programErr.length > 0 ? "input-error" : ""
                                }`}
                              >
                                <option value="">{t("Select Program ")}</option>
                                {leadProgram.length === 0 && (
                                  <option value="">{t("No data")}</option>
                                )}
                                {leadProgram?.map((item) => (
                                  <option key={item.id} value={item?.id}>
                                    {item?.title}
                                  </option>
                                ))}
                              </select>
                              {programErr.length > 0 && (
                                <span className=" invalid-error">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {programErr}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-6  ">
                            <div className="input-filed">
                              <span className="basic-text required">
                                {t("Contact via")}
                              </span>

                              <select
                                value={contactId}
                                onChange={(e) => {
                                  setContactId(e.target.value);
                                  setIsStateUpdated(true);
                                }}
                                className={`select-dropdown form-select custom-input ${
                                  contactwayErr.length > 0 ? "input-error" : ""
                                }`}
                              >
                                <option value="">{t("Select Contact")}</option>
                                {contactway.length === 0 && (
                                  <option value="">{t("No data")}</option>
                                )}
                                {}
                                {contactway?.map((item) => (
                                  <option key={item.id} value={item?.id}>
                                    {item?.title}
                                  </option>
                                ))}
                              </select>

                              {contactwayErr.length > 0 && (
                                <span className=" invalid-error">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {contactwayErr}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row  ">
                          <div className="col-6  ">
                            <div className="input-filed">
                              <span className="basic-text">
                                {t("First Name*")}
                              </span>
                              <input
                                className={`form-control custom-input ${
                                  firstNameError.length > 0 ? "input-error" : ""
                                }`}
                                value={firstName}
                                onChange={(e) => {
                                  setIsStateUpdated(true);
                                  setFirstName(e.target.value);
                                }}
                              />
                              {firstNameError.length > 0 && (
                                <span className=" invalid-error mb-2">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {firstNameError}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-6  ">
                            <div className="input-filed">
                              <span className="basic-text">
                                {t("Last Name*")}
                              </span>
                              <input
                                className={`form-control custom-input ${
                                  lastNameError.length > 0 ? "input-error" : ""
                                }`}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                              {lastNameError.length > 0 && (
                                <span className=" invalid-error">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {lastNameError}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row  ">
                          <div className="col-6  ">
                            <div className="input-filed">
                              <span className="basic-text">
                                {t("Date of Birth*")}
                              </span>
                              <DatePicker
                                ref={datepickerRef}
                                className={`form-control custom-input ${
                                  dobErr?.length > 0 ? "input-error" : ""
                                }`}
                                placeholderText="Select Date"
                                selected={dateOfBirth}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onChange={changeDate}
                                dateFormat={school.date_format}
                                popperPlacement="bottom"
                                maxDate={new Date()}
                                customInput={<CustomInput error={dobErr} />}
                              />
                              {dobErr?.length > 0 && (
                                <span className=" invalid-error">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {dobErr}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-12  ">
                            <div className="input-filed">
                              <span className="basic-text">
                                {t("Internal Comments")}
                              </span>

                              <textarea
                                rows={20}
                                className={`form-control custom-input `}
                                value={internalComments}
                                onChange={(e) =>
                                  setInternalComments(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          {/* <div className="col-6">
                        <input
                          className={`form-control custom-input `}
                          value={contactId}
                          onChange={(e) => setContactId(e.target.value)}
                          placeholder="Contact Id"
                        />
                      </div> */}
                          {/* <div className="col-6">
                        <input
                          className={`form-control custom-input `}
                          value={internalComments}
                          onChange={(e) => setInternalComments(e.target.value)}
                          placeholder="Internal Comments"
                        />
                      </div> */}
                        </div>
                      </>
                    )}

                    {indexPage === 1 && (
                      <>
                        <h2 className="page-head mb-3">
                          {t("Parent Information")}
                        </h2>
                        <div className="row mb-3">
                          <div className="col-6">
                            <span className="basic-text ">
                              {t("Father Name")}
                            </span>
                            <input
                              className={`form-control custom-input `}
                              value={fatherName}
                              onChange={(e) => {
                                setFatherName(e.target.value);
                                setIsStateUpdated(true);
                              }}
                            />
                          </div>
                          <div className="col-6">
                            <span className="basic-text ">
                              {t("Mother Name")}
                            </span>
                            <input
                              className={`form-control custom-input `}
                              value={motherName}
                              onChange={(e) => {
                                setMotherName(e.target.value);
                                setIsStateUpdated(true);
                              }}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-6">
                            <span className="basic-text ">
                              {t("Father's occupation")}
                            </span>
                            <input
                              className={`form-control custom-input `}
                              value={fatherWork}
                              onChange={(e) => setFatherWork(e.target.value)}
                            />
                          </div>
                          <div className="col-6">
                            <span className="basic-text ">
                              {t("Mother's occupation")}
                            </span>
                            <input
                              className={`form-control custom-input `}
                              value={motherWork}
                              onChange={(e) => setMotherWork(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-6">
                            <span className="basic-text ">
                              {t("Father's Email")}
                            </span>

                            <input
                              className={`form-control custom-input ${
                                isFatherMotherFatherEmailValid
                                  ? ""
                                  : "is-invalid"
                              }`}
                              value={fatherEmail}
                              onChange={handleFatherEmailChange}
                            />
                            {!isFatherMotherFatherEmailValid && (
                              <span className="invalid-error">
                                Please enter a valid email address.
                              </span>
                            )}
                          </div>
                          <div className="col-6">
                            <span className="basic-text ">
                              {t("Mother's Email")}
                            </span>

                            <input
                              className={`form-control custom-input ${
                                isMotherEmailValid ? "" : "is-invalid"
                              }`}
                              value={motherEmail}
                              onChange={handleMotherEmailChange}
                            />
                            {!isMotherEmailValid && (
                              <span className="invalid-error">
                                Please enter a valid email address.
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-6">
                            <span className="basic-text ">
                              {t("Father Mobile No.")}
                            </span>
                            <div className={`form-control `}>
                              <PhoneInput
                                tabIndex="-1"
                                international
                                limitMaxLength={10}
                                defaultCountry={defaultCountry}
                                value={fatherPhoneNo}
                                onChange={(value) => {
                                  setFatherPhoneNo(value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <span className="basic-text ">
                              {t("Mother Mobile No.")}
                            </span>
                            <div className={`form-control `}>
                              <PhoneInput
                                tabIndex="-1"
                                international
                                defaultCountry={defaultCountry}
                                value={motherPhoneNo}
                                onChange={(value) => {
                                  setMotherPhoneNo(value);
                                }}
                              />
                            </div>
                            {motherPhoneNoErr.length > 0 && (
                              <span className="invalid-error">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                  alt="Invalid Icon"
                                />
                                {motherPhoneNoErr}
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {indexPage === 2 && (
                      <>
                        <h2 className="page-head mb-3">{t("Address")}</h2>

                        <div className="row mb-3">
                          <div className="col-6">
                            <span className="basic-text ">{t("Address1")}</span>
                            <input
                              className={`form-control custom-input `}
                              value={address1}
                              onChange={(e) => setAddress1(e.target.value)}
                            />
                          </div>
                          <div className="col-6">
                            <span className="basic-text ">{t("Address2")}</span>
                            <input
                              className={`form-control custom-input `}
                              value={address2}
                              onChange={(e) => setAddress2(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-6">
                            <span className="basic-text ">{t("Country")}</span>
                            <select
                              value={countryId}
                              onChange={(e) => setCountryId(e.target.value)}
                              className={`select-dropdown form-select custom-input `}
                            >
                              <option value="">{t("Select Country ")}</option>
                              {country?.map((item) => (
                                <option key={item.id} value={item?.id}>
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-lg-6">
                            <div className="input-filed ">
                              <span className="basic-text ">{t("State")}</span>
                              <select
                                className="select-dropdown form-select custom-input"
                                value={stateId || ""}
                                onChange={(e) => {
                                  setStateId(e.target.value);
                                  setIsStateUpdated(true);
                                }}
                                disabled={!countryId}
                              >
                                <option value="">
                                  {isCountryLoading
                                    ? t("Loading...")
                                    : t("Select State")}
                                </option>
                                {state?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-6">
                            <span className="basic-text ">{t("City")}</span>
                            <select
                              className="select-dropdown form-select custom-input"
                              value={cityId}
                              onChange={(e) => {
                                setCityId(e.target.value);
                                setIsStateUpdated(true);
                              }}
                              disabled={!stateId}
                            >
                              <option value={0}>
                                {isCountryLoading
                                  ? "Loading..."
                                  : "Select City"}
                              </option>
                              {city?.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-6">
                            <span className="basic-text ">{t("Pincode")}</span>
                            <input
                              className={`form-control custom-input `}
                              value={pincode}
                              onChange={(e) => setPincode(e.target.value)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {indexPage !== steps.length ? (
                    <>
                      <div className="row">
                        <div className="d-flex  align-items-center mt-3 justify-content-between">
                          <div>
                            <button
                              className="btn secondary-lg-btn"
                              onClick={handleBack}
                            >
                              {indexPage === 0 ? t("Cancel") : t("Previous")}
                            </button>
                          </div>

                          <div>
                            <button
                              className="btn primary-btn "
                              onClick={handleNext}
                            >
                              {activeStep === steps.length - 1
                                ? t("Finish")
                                : t("Continue")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <ExitAlert
            visible={alertModal}
            onClose={() => setIsAlertModal(false)}
            onOk={() => {
              setTriggerExit((obj) => ({
                ...obj,
                onOk: true,
              }));
              setIsAlertModal(false);
            }}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ToastHOC(NewAdmisson);

function VerticalLinearStepper({
  activeStep,
  handleBack,
  handleNext,
  handleReset,
}) {
  const { t } = useTranslation();
  const steps = [
    {
      label: `${t("General Information")}`,
    },

    {
      label: `${t("Parent Information")}`,
    },
    {
      label: `${t("Address")}`,
    },
  ];

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper
        style={{ fontFamily: Const.fontFamily }}
        activeStep={activeStep}
        orientation="vertical"
        className="stepper-style"
      >
        {steps.map((step, index) => (
          <Step key={step.label} style={{ fontFamily: Const?.fontfamily }}>
            <StepLabel
              style={{ fontFamily: Const.fontfamily }}

              // optional={
              //   index === 2 ? (
              //     <Typography variant="caption">Last step</Typography>
              //   ) : null
              // }
            >
              {step.label}
            </StepLabel>
            <StepContent></StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export const CustomInput = React.forwardRef(
  ({ error, value, onClick }, ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
        ref={ref}
        className={`custom-input__field ${
          error?.length > 0 ? "input-error" : ""
        }`}
        value={value}
        readOnly
      />
      <div className={`custom-input__icon`}>
        <img src={Calander} alt="Calander" className="svg-icon calender-icon" />
      </div>
    </div>
  )
);
