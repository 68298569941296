import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import { useHistory } from "react-router-dom";
import ApiCalling from "../../../network/ApiCalling";
import ModalComponent from "../ModalComponent";

export default function Reactive(props) {
  const history = useHistory();
  const [showReActiveModal, setShowReActiveModal] = useState(false);
  const studentId = props.props.location.studentId;
  const [student, setStudent] = useState();
  const { t } = useTranslation();

  const handleReActive = () => {
    ApiCalling.apiCallParamsPost(`student/reactive/${studentId}`)
      .then((res) => {
        setShowReActiveModal(false);
        history.push({
          pathname: "/Student",
          pendingToast: res.data.message,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  useEffect(() => {
    let isStudentDataMounted = true;
    const getStudentData = async () => {
      ApiCalling.apiCallParamsGet("student/" + studentId).then((res) => {
        if(isStudentDataMounted){
          setStudent(res.data);
        }
      });
    };
    getStudentData();
    return()=>{
      isStudentDataMounted = false;
    }
  }, []);
  return (
    <div>
      <div className="">
        <div className="row">
          
            <div className="col-lg">
              <div className="alert alert-success m-5 align-center">
                <h6>{t("You are about to Reactivate the Student")}.</h6>
                <div>
                  {t(
                    "This will revive all student related data from the portal"
                  )}
                  <br />
                  {t("Are you sure")}?
                </div>
                <br />
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setShowReActiveModal(true);
                  }}
                  disabled={!Util.hasPermission("STUDENT_DELETE")}
                >
                  {t("Reactive")} {student?.first_name}
                </button>
              </div>
            </div>
            
            <ModalComponent
                show={showReActiveModal}
                onHide={() => {
                  setShowReActiveModal(false);
                }}
                title={t("ReActive")}
                subTitle={t("Are You Sure to ReActive")}
                size="sm"
                onCancel={t("Cancel")}
                onConfirm={t("Confirm")}
                onCancelClick={() => {
                  setShowReActiveModal(false);
                }}
                onConfirmClick={handleReActive}
              />
          </div>
       
      </div>
    </div>
  );
}
