import React from "react";

export default function CustomInvoiceDetailsCard({ title, bgColor, count }) {
  return (
    <div>
      <div className="d-flex align-items-center gap-1">
      <div className=" custom-circle" style={{ backgroundColor: bgColor }} />
        
        <div className="text-secondary">{title}</div>
      </div>
      <div className="large-bold-text ml-2">{count}</div>
    </div>
  );
}
