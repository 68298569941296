import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import ApiCalling from "../../../network/ApiCalling";
import moment from "moment/moment";
import { greenTagStyle, orangeTagStyle, redTagStyle } from "../TagButtonStyle";
import TagButton from "../TagButton";
import { Rings } from "react-loader-spinner";
import PaymentView from "../../ParentsDashboard/Billing/PaymentView";
import Loader from "../../../components/common/Loader";

export default function StudentPaymentList(props) {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const [credits, setCredits] = useState([]);
  const studentId = props?.student?.id;
  const student = props?.student;
  const [isLoading, setIsLoading] = useState(false);
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);
  const [paymentId, setPaymentId] = useState(0);

  const getStudentBalance = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`student/balance/${studentId}`)
      .then((res) => {
       
        setIsLoading(false);
        setCredits(res.data.remaining_payments);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getStudentBalance();
  }, []);
  
  return (
    <div className="container-fluid mt-4">
      {!isLoading ? (
        <table
          className="table"
          style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
        >
          <thead style={{ backgroundColor: "#e5e7eb" }}>
            <tr>
              <th>{t("Payer")}</th>

              <th>{t("Date")}</th>
              <th>{t("Status")}</th>
              <th>{t("Invoices")}</th>
              <th>{t("Amount")}</th>
              <th>{t("Balance")}</th>
              <th></th>
            </tr>
          </thead>
          {credits.length > 0 ? (
            <tbody>
              {credits.map((c, index) => {
                const invoice_settled_ids = c.invoice_settled.map(
                  (c) => c.invoice_id
                );
                const invoice_settled = invoice_settled_ids.map((id) => {
                  return Util.invoiceIdFormat(id);
                });

                return (
                  <>
                    <tr key={index}>
                      <td>
                        {c.payment_method}
                        <div className="small-text">
                          {t("By")} {c.User.firstName + " " + c.User.lastName}
                        </div>
                      </td>
                      <td>
                        {moment(c.date).format(
                          Util.getMomentDateTimeFormat(school.date_format)
                        )}
                      </td>
                      <td>
                        <TagButton
                          style={"btn btn-secondary"}
                          size={"150px"}
                          title={Util.getPaymentStatus(c.status)}
                          buttonStyle={
                            c.status == 0
                              ? redTagStyle
                              : c.status == 1
                              ? orangeTagStyle
                              : greenTagStyle
                          }
                          icon="fa fa-circle"
                          iconSize="6px"
                        />
                      </td>
                      <td>{Util.getListDisplay(invoice_settled, 2, ", ")}</td>
                      <td>{Util.amountFormat(c.amount)}</td>
                      <td>{Util.balanceFormat(c.not_used_amount)}</td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-lg custom-primary "
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ color: "#ffffff", padding: "5px, 10px" }}
                          >
                            {t("Action")}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  setIsVisiblePayment(true);
                                  setPaymentId(c.id);
                                }}
                              >
                                {t("View")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <PaymentView
                      show={isVisiblePayment}
                      onClose={() => {
                        setIsVisiblePayment(false);
                      }}
                      PaymentId={paymentId}
                    />
                  </>
                );
              })}
            </tbody>
          ) : (
            <tfoot>
              <tr>
                <td colSpan={8}>{t("Not Found!")}</td>
              </tr>
            </tfoot>
          )}
        </table>
      ) : (
        <Loader />
      )}
    </div>
  );
}
