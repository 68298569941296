import React, { useState } from "react";
import ApiCalling from "../../network/ApiCalling";
import Util from "../../Util";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import ToastHOC from "../HOC/ToastHOC";
import Invalid from "../../assets/Icon/invalid.svg";
import Loader from "../../components/common/Loader";

function Password(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const [code, setCode] = useState("");
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const [codeError, setCodeError] = useState("");
  const [currentPassError, setCurrentPassError] = useState("");
  const [newPassError, setNewPassError] = useState("");
  const [confirmNewPassError, setConfirmNewPassError] = useState("");
  const [changePass, setChangePass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCodeChange = (e) => {
    setCode(e.target.value);
    setCodeError("");
  };
  const handleCurrentPassChange = (e) => {
    setCurrentPass(e.target.value);
    setCurrentPassError("");
  };
  const handleNewPassChange = (e) => {
    setNewPass(e.target.value);
    setNewPassError("");
  };
  const handleConfirmChange = (e) => {
    setConfirmNewPass(e.target.value);
    setConfirmNewPassError("");
  };
  const handleOTP = () => {
    setChangePass(true);
    ApiCalling.apiCallBodyDataGet("user/send-password-change-otp")
      .then((res) => {
        if (res.data.status === true) {
          setIsToast(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePasswordUpdate = () => {
    setCurrentPassError("");
    setNewPassError("");
    setConfirmNewPassError("");
    setCodeError("");
    let error = false;

    if (changePass === true) {
      if (code === "") {
        setCodeError(t("OTP is required"));
        error = true;
      }
    } else {
      if (currentPass === "") {
        setCurrentPassError(t("CurrentPassword is required"));
        error = true;
      } else if (Util.password(currentPass) === false) {
        setCurrentPassError(t("password must be at least 5 characters long"));
        error = true;
      }
    }

    if (newPass === "") {
      setNewPassError(t("NewPassword is required"));
      error = true;
    } else if (Util.password(newPass) === false) {
      setNewPassError(t("password must be at least 5 characters long"));
      error = true;
    }

    if (confirmNewPass === "") {
      setConfirmNewPassError(t("ConfirmNewPassword is required"));
      error = true;
    } else if (Util.password(confirmNewPass) === false) {
      setConfirmNewPassError(t("password must be at least 5 characters long"));
      error = true;
    }
    if (newPass !== confirmNewPass) {
      setConfirmNewPassError(t("password must be same as new password"));
      error = true;
    }

    if (!error) {
      if (changePass === true) {
        const OTPData = {
          new: newPass,
          code: code,
        };
        ApiCalling.apiCallBodyDataPost("user/password-change-otp", OTPData)
          .then((res) => {
            setIsLoading(false);
            if (res.data.status === true) {
              setIsToast(res.data.message);
              setConfirmNewPass("");
              setNewPass("");
              setCode("");
            } else {
              setCodeError(res.data.message);
            }
          })
          .catch((error) => console.log(error));
      } else {
        const data = {
          current: currentPass,
          new: newPass,
        };
        ApiCalling.apiCallBodyDataPost("user/password-change", data)
          .then((res) => {
            setIsLoading(false);
            if (res.data.status === true) {
              setIsToast(res.data.message);
              setCurrentPass("");
              setNewPass("");
              setConfirmNewPass("");
            } else {
              setCurrentPassError(res.data.message);
            }
          })
          .catch((error) => console.log(error));
      }
    }
  };
  
  return (
    <div className="container-80">
      <div className="school-details my-4">
        <div className="p-4">
          {!isLoading ? (
            <div className="body">
              <div className="row text-start ">
                <div className="col-6 col-md-6">
                  {/* {changePass === true ? (
                <h6>{t("Change Password Using OTP")}</h6>
              ) : (
                <h6>{t("Change Password")}</h6>
              )} */}
                  {changePass === true ? (
                    <>
                      <div className="input-field ">
                        <label className="basic-text required">{t("Enter OTP")}</label>
                        <input
                          className={`form-control custom-input ${codeError.length > 0 && "input-error"}`}
                          placeholder={t("Enter OTP")}
                          defaultValue=""
                          type="text"
                          onChange={handleCodeChange}
                          value={code}
                        />
                        {codeError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                            {codeError}
                            </span>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input-field ">
                        <label className="basic-text required">{t("Current Password")}</label>
                        <input
                          className={`form-control custom-input ${currentPassError.length > 0 && "input-error"}`}
                          placeholder={t("Current Password")}
                          type="password"
                          value={currentPass}
                          onChange={handleCurrentPassChange}
                        />
                        {currentPassError.length > 0 && (
                          <span className="text-danger invalid-error">
                          <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                          {currentPassError}
                          </span>
                        )}
                      </div>
                    </>
                  )}

                  <div className="input-filed mt-3">
                    <label className="basic-text required">{t("New Password")}</label>
                    <input
                    className={`form-control custom-input ${newPassError.length > 0 && "input-error"}`}
                      placeholder={t("New Password")}
                      type="password"
                      value={newPass}
                      onChange={handleNewPassChange}
                    />
                    {newPassError.length > 0 && (
                       <span className="text-danger invalid-error">
                       <img
                           className="fa fa-exclamation-circle mr-2 invalid-svg"
                           src={Invalid}
                         />
                       {newPassError}
                       </span>
                    )}
                  </div>

                  <div className="input-filed mt-2">
                    <label className="basic-text required">{t("Confirm New Password")}</label>
                    <input
                     className={`form-control custom-input ${confirmNewPassError.length > 0 && "input-error"}`}
                      placeholder={t("Confirm New Password")}
                      type="password"
                      value={confirmNewPass}
                      onChange={handleConfirmChange}
                    />
                    {confirmNewPassError.length > 0 && (
                      <span className="text-danger invalid-error">
                      <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                      {confirmNewPassError}
                      </span>
                    )}
                    
                  </div>
                  {changePass === true && (
                      <span className="float-right">
                        <a href="javascript:void(0)" onClick={handleOTP}>
                          {t("Resend Code")} ?
                        </a>
                      </span>
                    )}
                </div>
                {changePass === true ? (
                  <>
                    <div className="col-6 col-md-6 mt-4 ">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setChangePass(false);
                        }}
                      >
                        {t("Using Current Password")}
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-6 col-md-6 mt-4">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOTP();
                        }}
                      >
                        {t("Using OTP")}
                      </a>
                    </div>
                  </>
                )}
              </div>

              <button
                className="btn custom-primary"
                type="button"
                onClick={handlePasswordUpdate}
              >
                {t("Update")}
              </button>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(Password);
