import { useTranslation } from "react-i18next";
import { PictureAsPdf } from "@mui/icons-material";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { chatAttachment } from "../CommonImage";
import Util from "../../Util";
import check from "../../../src/assets/Icon/check-double.svg";

export default function ChatMessage(props) {
  const message = props?.message;
  const user = props?.currentUser;
  const { t } = useTranslation();
  const isChatType = props?.isChatType;

  function updateMessage(item) {
    props.readAMessage(item);
  }

  const isImage = (attachment) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const extension = attachment.split(".").pop();
    return imageExtensions.includes(extension.toLowerCase());
  };

  const isVideo = (attachment) => {
    const videoExtensions = ["mp4"];
    const extension = attachment.split(".").pop();
    return videoExtensions.includes(extension.toLowerCase());
  };

  const isDocument = (attachment) => {
    const docExtensions = ["pdf", "doc", "docx", "xls", "xlsx"];
    const extension = attachment.split(".").pop();
    return docExtensions.includes(extension.toLowerCase());
  };
  const getDocumentIcon = (attachment) => {
    const extension = attachment.split(".").pop().toLowerCase();
    switch (extension) {
      case ("xls", "xlsx"):
        return <ListAltIcon fontSize="large" className="text-color-primary" />;
      case "pdf":
        return <PictureAsPdf fontSize="large" className="text-color-primary" />;
      case ("doc", "docx"):
        return (
          <FolderCopyIcon fontSize="large" className="text-color-primary" />
        );

      default:
        return (
          <FolderCopyIcon fontSize="large" className="text-color-primary" />
        );
    }
  };

  return (
    <>
      {message != undefined && message != null ? (
        <>
          <li className="clearfix" key={message.id}>
            <div
              className={message.sent_by === user.id ? "message my" : "message"}
            >
              {isChatType === "GROUP" && (
                <>
                  {message.sent_by !== user.id && (
                    <>{Util.getUserAvatar(message.Sent_by)}</>
                  )}
                </>
              )}
              {/* <div className="mgs-wrap"> */}
              <div
                className="message-text"
                onFocus={
                  message.sent_by !== user.id ? updateMessage(message) : null
                }
              >
                {message.attachment ? (
                  <>
                    {isImage(message.attachment) ? (
                      <img
                        className="img-responsive"
                        style={{
                          maxHeight: "150px",
                          borderRadius: "10px",
                          padding: "5px",
                        }}
                        src={
                          chatAttachment + message.id + "/" + message.attachment
                        }
                        alt="Image Attachment"
                      />
                    ) : isVideo(message.attachment) ? (
                      <video
                        className="video-responsive"
                        style={{
                          maxHeight: "150px",
                          borderRadius: "10px",
                          padding: "5px",
                        }}
                        src={
                          chatAttachment + message.id + "/" + message.attachment
                        }
                        controls
                      />
                    ) : isDocument(message.attachment) ? (
                      <>
                        <a
                          href={
                            chatAttachment +
                            message.id +
                            "/" +
                            message.attachment
                          }
                          target="_blank"
                        >
                          {getDocumentIcon(message.attachment)}
                          <br />
                          {message.attachment}
                        </a>
                      </>
                    ) : (
                      <span>
                        {t("Unsupported file type")}: {message.attachment}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {isChatType === "GROUP" && (
                      <>
                        {message.sent_by !== user.id && (
                          <div
                            className="basic-bold-text "
                            
                          >
                            {message.Sent_by.firstName +
                              " " +
                              message.Sent_by.lastName}
                          </div>
                        )}
                      </>
                    )}

                    <div>{message.message}</div>
                  </>
                )}
                <div className="Time d-flex">
                  {Util.getDateDisplay_ChatMessage(message.sent_on)}
                  {message.read_on && message.sent_by === user.id && (
                    <img src={check} className="ml-1" />
                  )}
                </div>
              </div>
            </div>

            {/* </div> */}
          </li>
        </>
      ) : (
        ""
      )}
      {/* {Util.isValidData(unread) && unread > 0 && <>{unread}messages</>} */}
    </>
  );
}
