import { Box, SwipeableDrawer } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Util from "../../../Util";
import ApiCalling from "../../../network/ApiCalling";
import moment from "moment/moment";
import TagButton from "../TagButton";
import { greenTagStyle, orangeTagStyle, redTagStyle } from "../TagButtonStyle";
import { imageStudentList } from "../../CommonImage";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Calander from "../../../assets/Icon/calendar.svg";
import ExitAlert from "../../../components/ExitAlert";
import Invalid from "../../../assets/Icon/invalid.svg";
import Loader from "../../../components/common/Loader";
import Const from "../../../Const";

export default function AddManualInvoice({
  show,
  onClose,
  onSave,
  studentIds,
  student,
  updateData,
  invoiceDetails,
}) {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const getLanguage = Util.getSelectedLanguage();
  const datepickerRef = useRef(null);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [invoiceDateError, setInvoiceDateError] = useState("");
  const [lastPaymentDate, setLastPaymentDate] = useState(null);
  const [lastPaymentDateError, setLastPaymentDateError] = useState("");
  const [feeTypeList, setFeeTypeList] = useState([]);
  const [fields, setFields] = useState([
    {
      id: 1,
      feeTypeId: 0,
      amount: "",
    },
  ]);
  const [fieldError, setFieldError] = useState(false);
  const [subTotalAmount, setSubTotalAmount] = useState(0);

  const [discount, setDiscount] = useState(false);
  const [discountType, setDiscountType] = useState("");
  const [discountTypeError, setDiscountTypeError] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountAmountError, setDiscountAmountError] = useState("");

  const [totalAmount, setTotalAmount] = useState(0);
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [alertModal, setIsAlertModal] = useState(false);
  const [isLoading,setIsLoading] = useState(false);

  const CustomInput = ({ value, onClick, icon }) => (
    <div className="d-flex" onClick={onClick}>
      <input
        className={`custom-input__field ${
          invoiceDateError.length > 0 && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  );
  const CustomLastDateInput = ({ value, onClick, icon }) => (
    <div className="d-flex" onClick={onClick}>
      <input
        className={`custom-input__field ${
          lastPaymentDateError.length > 0 && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  );

  const handleInvoiceDate = (date) => {
    setInvoiceDate(date);
    setInvoiceDateError("");
    setIsStateUpdated(true);
  };

  const handleLastPaymentDate = (date) => {
    setLastPaymentDate(date);
    setLastPaymentDateError("");
    setIsStateUpdated(true);
  };

  const toggleDatePicker = () => {
    datepickerRef.current.setOpen(true);
  };

  const handleAddField = () => {
    setIsStateUpdated(true);
    setFields([
      ...fields,
      {
        id: fields.length + 1,
        feeTypeId: 0,
        amount: "",
      },
    ]);
  };
  const handleRemoveField = (index) => {
    setIsStateUpdated(true);
    setFields(fields.filter((field, i) => i !== index));
  };

  const handleInputChange = (index, event) => {
    setIsStateUpdated(true);
    const newFields = [...fields];
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);

    const sum = newFields.reduce((acc, field) => acc + Number(field.amount), 0);
    setSubTotalAmount(sum);
    setTotalAmount(sum);
  };

  const handleFeeTypeId = (index, event) => {
    setIsStateUpdated(true);
    const newFields = [...fields];
    newFields[index]["feeTypeId"] = event.target.value;
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
  };
  const handleDiscount = (e) => {
    setDiscount(e.target.checked);
    setIsStateUpdated(true);
  };
  const handleDiscountType = (e) => {
    setDiscountType(e.target.value);
    setDiscountTypeError("");
    setIsStateUpdated(true);
  };
  const handleDiscountAmount = (e) => {
    const amount = e.target.value;
    setDiscountAmount(amount);
    setDiscountAmountError("");
    setTotalAmount(subTotalAmount - Number(amount));
    setIsStateUpdated(true);
  };

  const generateInvoice = (e) => {
    
    setIsStateUpdated(false);
    e.preventDefault();
    var error = false;
    setInvoiceDateError("");
    setDiscountTypeError("");
    setDiscountAmountError("");
    setFieldError(false);
    fields.map((f) => {
      if (f.feeTypeId == 0 || f.amount == "") {
        setFieldError(true);
        error = true;
      }
    });
    if (invoiceDate == null) {
      setInvoiceDateError(t("Date is required"));
      error = true;
    }
    if (lastPaymentDate == null) {
      setLastPaymentDateError(t("Last payment date is required"));
      error = true;
    }
    if (discount && discountType == "") {
      setDiscountTypeError(t("Discount Type is required"));
      error = true;
    }
    if (discount && discountAmount == 0) {
      setDiscountAmountError(t("Discount Amount is required"));
      error = true;
    }
    if (!error) {
      setIsLoading(true)
      const studentId = studentIds.length > 0 ? studentIds.join(",") : studentIds.toString();
      const ConceptData = fields.map((item) => ({
        fee_type_id: Number(item.feeTypeId),
        total: Number(item.amount),
      }));
      const data = {
        student_ids: studentId,
        date: invoiceDate,
        sub_total: Number(subTotalAmount),
        discount_type: discountType,
        discount_amount: Number(discountAmount),
        total: totalAmount,
        concepts: ConceptData,
        last_payment_date: lastPaymentDate,
      };
      ApiCalling.apiCallBodyDataPost("billing-invoice/add-manual", data)
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            onSave(res.data.message);
            updateData();
            reset();
          }else{
            onClose(res.data.message)
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const feeTypeData = () => {
    ApiCalling.apiCallBodyDataGet("fee-types/list")
      .then((res) => {
        setFeeTypeList(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    feeTypeData();
  }, []);

  useEffect(() => {
    if (Util.isValidData(invoiceDetails)) {
      setInvoiceDate(new Date(invoiceDetails.date));
      setFields((prevFields) =>
        invoiceDetails.types.map((t, index) => ({
          id: index,
          feeTypeId: t?.fee_type_id,
          amount: t?.amount,
        }))
      );
      if (
        Util.isValidData(invoiceDetails.discount_amount) &&
        Util.isValidData(invoiceDetails.discount_type)
      ) {
        setDiscount(true);
      }
      setDiscountType(invoiceDetails.discount_type);
      setDiscountAmount(invoiceDetails.discount_amount);
      setSubTotalAmount(invoiceDetails.sub_total);
      setTotalAmount(invoiceDetails.total);
    }
  }, [invoiceDetails]);
  const reset = () => {
    setFields([
      {
        id: 1,
        feeTypeId: 0,
        amount: "",
      },
    ]);
    setSubTotalAmount(0);
    setLastPaymentDate(null);
    setDiscount(false);
    setDiscountType("");
    setDiscountAmount(0);
    setTotalAmount(0);
    setInvoiceDateError("");
    setDiscountTypeError("");
    setDiscountAmountError("");
    setFieldError(false);
    setLastPaymentDateError("");
  };
  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={show}
        onClose={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            setIsStateUpdated(false);
            if (Util.isValidData(invoiceDetails)) {
              onClose();
              setDiscount(false);
            } else {
              reset();
              onClose();
            }
          }
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
          <div className="d-flex align-items-center justify-content-between">
          <div className="drawer-header">
              {Util.isValidData(invoiceDetails)
                ? Util.invoiceIdFormat(invoiceDetails?.id)
                : t("Add a one-time charge")}
            </div>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => {
                if (isStateUpdated) {
                  setIsAlertModal(true);
                } else {
                  setIsStateUpdated(false);
                  if (Util.isValidData(invoiceDetails)) {
                    onClose();
                    setDiscount(false);
                  } else {
                    onClose();
                    reset();
                  }
                }
              }}
            >
              <CloseIcon />
            </IconButton>
            
            </div>
            {
              !isLoading ?
            <div className="body mt-3">
              <div className="row text-start mt-2" style={{ paddingBottom: "50px" }}>
                <div className="mt-2 pb-3">
                  <div>{t("Student selected")}</div>
                  {Util.isValidArray(student) &&
                    student.map((s,index) => {
                      return (
                        <span className="badge badge-students mt-2" key={index}>
                          <img
                            className="rounded-circle mr-2"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                            src={
                              s.photo
                                ? imageStudentList + s.photo
                                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                            }
                            alt=""
                          />
                          {s.first_name + " " + s.last_name}
                        </span>
                      );
                    })}
                </div>

                <div className="input-filed pb-2">
                  <span className="basic-text required">
                    {t("Invoice generated on")}
                  </span>

                  <DatePicker
                    // placeholderText="select Date"
                    ref={datepickerRef}
                    selected={invoiceDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={handleInvoiceDate}
                    dateFormat={school.date_format}
                    popperPlacement="top"
                    disabled={Util.isValidData(invoiceDetails)}
                    customInput={<CustomInput icon={Calander} />}
                  />

                  {invoiceDateError.length > 0 && (
                    <span className="text-danger invalid-error">
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                      {invoiceDateError}
                    </span>
                  )}
                </div>

                <div className="input-filed pb-2">
                  <span className="basic-text required">
                    {t("Last payment date")}
                  </span>

                  <DatePicker
                    ref={datepickerRef}
                    selected={lastPaymentDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={handleLastPaymentDate}
                    dateFormat={school.date_format}
                    minDate={invoiceDate}
                    popperPlacement="top"
                    disabled={Util.isValidData(invoiceDetails)}
                    customInput={<CustomLastDateInput icon={Calander} />}
                  />

                  {lastPaymentDateError.length > 0 && (
                    <span className="text-danger invalid-error">
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                      {lastPaymentDateError}
                    </span>
                  )}
                </div>

                <div className="input-filed row pb-2">
                  <span className="basic-text required">
                    {t("Select Charges")}
                  </span>
                  {fields.map((f, index) => {
                    return (
                      <>
                        <div className="col-8 mt-2" key={index}>
                          {index + 1} {". "}
                          <select
                            name="feeTypeId"
                            id="feeTypeId"
                            style={{ display: "inline-block", width: "95%" }}
                            className="select-dropdown form-select custom-input"
                            value={f.feeTypeId}
                            onChange={(event, i) =>
                              handleFeeTypeId(index, event)
                            }
                            disabled={Util.isValidData(invoiceDetails)}
                          >
                            <option selected value={0}>
                              {t("Select Charge")}
                            </option>
                            {feeTypeList?.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.type}
                              </option>
                            ))}
                          </select>
                          {fieldError && f.feeTypeId == 0 && (
                           <span className="text-danger invalid-error">
                           <img
                             className="fa fa-exclamation-circle mr-2 invalid-svg"
                             src={Invalid}
                           />
                              {t("Charge is required")}
                            </span>
                          )}
                        </div>
                        <div className="input-filed col-3 mt-2">
                          <input
                            placeholder="Amount *"
                            name="amount"
                            type="number"
                            className="form-control custom-input"
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            value={f.amount}
                            disabled={Util.isValidData(invoiceDetails)}
                          />
                          {fieldError && f.amount == "" && (
                            <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                              {t("Amount is required")}
                            </span>
                          )}
                        </div>
                        {fields.length > 1 && (
                          <button
                            className="col-1 mt-3"
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveField(index);
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "transparent",
                            }}
                            disabled={Util.isValidData(invoiceDetails)}
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </button>
                        )}
                      </>
                    );
                  })}
                </div>

                <button
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: Const.primaryColor,
                    cursor: "pointer",
                    backgroundColor: "transparent",
                    textAlign: "left",
                  }}
                  onClick={handleAddField}
                  disabled={Util.isValidData(invoiceDetails)}
                >
                  + {t("Add")}
                </button>
                <div className="row">
                  <div className="col-8 text-right text-bold">
                    {t("Sub Total")}
                  </div>
                  <div className="col-3">
                    {Util.amountFormat(subTotalAmount)}
                  </div>
                </div>

                <div className="d-flex justify-content-start gap-4 mt-1">
                  <div className="input-filed mt-3">
                    <label style={{ fontSize: "16px", fontWeight: "normal" }}>
                      <span className="mr-2">
                        <i></i>
                        {t("Apply discount?")}
                      </span>
                      <input
                        name="holiday"
                        type="checkbox"
                        checked={discount}
                        onChange={handleDiscount}
                        disabled={Util.isValidData(invoiceDetails)}
                      />
                      <span tabIndex={0}></span>
                    </label>
                  </div>

                  {discount && (
                    <div className="d-flex align-items-center gap-5">
                      <div className="input-filed">
                        <input
                          type="text"
                          placeholder="Type / Reference"
                          className={`form-control ${
                            discountTypeError.length > 0
                              ? "input-error"
                              : "custom-input"
                          }`}
                          onChange={handleDiscountType}
                          value={discountType}
                          disabled={Util.isValidData(invoiceDetails)}
                        />
                        {discountTypeError.length > 0 && (
                          <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                            {discountTypeError}
                          </span>
                        )}
                      </div>
                      <div className="input-filed">
                        <input
                          type="number"
                          placeholder="disco  unt"
                          className={`form-control ${
                            discountAmountError.length > 0
                              ? "input-error"
                              : "custom-input"
                          }`}
                          onChange={handleDiscountAmount}
                          value={discountAmount}
                          disabled={Util.isValidData(invoiceDetails)}
                        />
                        {discountAmountError.length > 0 && (
                          <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                            {discountAmountError}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="row pb-2">
                  <div className="col-8 text-bold text-right">
                    <span
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      {t("Total")}
                    </span>
                  </div>
                  <div className="col-3">
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#07112b",
                      }}
                    >
                      {Util.amountFormat(totalAmount)}
                    </span>
                  </div>
                </div>
                {!Util.isValidData(invoiceDetails) && (
                  <div
                    className="border-top mt-2"
                    style={{
                      position: "fixed",
                      width: "656px",
                      bottom: "0px",
                      right: getLanguage !== "ar" && "0px",
                      background: "#ffffff",
                      padding: "10px",
                    }}
                  >
                    <button
                      style={{ height: "48px" }}
                      className="secondary-md-btn btn mr-2"
                      onClick={() => {
                        if (isStateUpdated) {
                          setIsAlertModal(true);
                        } else {
                          onClose();
                          reset();
                          setIsStateUpdated(false);
                        }
                      }}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      style={{ float: "right" }}
                      className="primary-md-btn btn"
                      onClick={generateInvoice}
                    >
                      {t("Save")}
                    </button>
                  </div>
                )}
              </div>

              {Util.isValidData(invoiceDetails) && (
                <div className="d-flex mb-4 mt-2">
                  <strong>Payment status</strong>:
                  <TagButton
                    style={"btn btn-secondary"}
                    size={"150px"}
                    title={Util.getInvoiceStatus(invoiceDetails.status)}
                    buttonStyle={
                      invoiceDetails.status == 0
                        ? redTagStyle
                        : invoiceDetails.status == 1
                        ? orangeTagStyle
                        : greenTagStyle
                    }
                    icon="fa fa-circle"
                    iconSize="6px"
                  />
                </div>
              )}

              {Util.isValidData(invoiceDetails) && (
                <div className="box-area">
                  <span className="basic-text">
                    {t("Apply Payment against Invoices ")}
                  </span>
                  <table
                    className="table"
                    style={{
                      border: "1px solid #e5e7eb",
                      borderRadius: "12px",
                    }}
                  >
                    <thead style={{ backgroundColor: "#e5e7eb" }}>
                      <tr>
                        <th>{t("Payer")}</th>
                        <th>{t("Date")}</th>
                        <th>{t("Total paid")}</th>
                        <th>{t("Amount Adjusted")}</th>
                      </tr>
                    </thead>
                    {invoiceDetails.payment_settled.length > 0 ? (
                      <>
                        {invoiceDetails.payment_settled.map((In, index) => {
                          return (
                            <tbody key={index}>
                              <tr>
                                <td>
                                  {" "}
                                  {In.method}
                                  <div className="small-text">
                                    {t("By")} {In.paid_by}
                                  </div>
                                </td>
                                <td>
                                  {moment(In.date).format(
                                    Util.getMomentDateTimeFormat(
                                      school.date_format
                                    )
                                  )}
                                </td>
                                <td>{In.deposit}</td>
                                <td>{In.amount_adjusted}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </>
                    ) : (
                      <tfoot>
                        <tr>
                          <td colSpan={4}>{t("No record found!")}</td>
                        </tr>
                      </tfoot>
                    )}
                  </table>
                </div>
              )}
            </div>
               :
               <Loader/>
            }
          </Box>
        </div>
      </SwipeableDrawer>
      <ExitAlert
        visible={alertModal}
        onClose={() => {
          setIsAlertModal(false);
          setIsStateUpdated(false);
        }}
        onOk={() => {
          onClose();
          reset();
          setIsAlertModal(false);
          setIsStateUpdated(false);
        }}
      />
    </div>
  );
}
