import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";



export default function StaffStepper({
  activeStep,
  handleBack,
  handleNext,
  handleReset,
}) {

  const { t } = useTranslation();

  const steps = [
    {
      label: `${t("Personal Information")}`,
    },
    {
      label: `${t("Address Information")}`,
    },
    {
      label: `${t("School Information")}`,
    },
  ];
  return (
    <div>
      <Box sx={{ maxWidth: 400 }}>
        <Stepper
          style={{ fontFamily: "Manrope" }}
          activeStep={activeStep}
          orientation="vertical"
          className="stepper-style"
        >
          {steps.map((step, index) => (
            <Step key={step.label} style={{ fontFamily: "Manrope" }}>
              <StepLabel style={{ fontFamily: "Manrope" }}>
                {step.label}
              </StepLabel>
              <StepContent></StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
}
