import React from "react";

export default function UpcomingEventCard({
  month,
  date,
  title,
  description,
  bgColor,
  onEventClick,
}) {
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={onEventClick}
      className=" event-data-row  m-3 d-flex justify-content-between  align-items-center"
    >
      <div className="col-2 p-0">
        <p
          style={{
            fontSize: "14px",
            fontWeight: "500",
            margin: 0,
            padding: 0,
            textAlign: "center",
          }}
        >
          {month}
        </p>
        <p
          style={{
            fontSize: "18px",
            fontWeight: "600",
            textAlign: "center",
            margin: 0,
            padding: 0,
          }}
        >
          {date}
        </p>
      </div>
      <div className="col-9 p-0">
        <div className="d-flex align-items-center gap-2 inside-bg ">
          <span
            style={{
              width: "5px",
              height: "43px",
              backgroundColor: bgColor,
              borderRadius: "10px",
            }}
          ></span>
          <div className="w-100  mt-3">
            <span className="basic-bold-text">{title}</span>
            <p className="event-mini-text sm-text wrapTwoLine">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
