import React, { useEffect, useState } from "react";
import PendingInvoices from "./PendingInvoices";
import PendingCredits from "./PendingCredits";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import "../../Dashbord/DashboardCustom.css";
import { Rings } from "react-loader-spinner";
import ToastHOC from "../../HOC/ToastHOC";
import InvoiceCard, { CardDetail } from "./InvoiceCard/InvoiceCard";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/common/Loader";
import Const from "../../../Const";

function BillDashboard(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const school = Util.getSelectedSchool();
  const schoolId = school?.id;
  const [value, setValue] = React.useState("Open Invoices");
  const [depositList, setDepositList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceCount, setInvoiceCount] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const SchoolDeposit = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`payment/school-deposit-credit/${schoolId}`)
      .then((res) => {
        setIsLoading(false);
        setDepositList(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    SchoolDeposit();
  }, []);

  return (
    <div>
      <div className="row py-3">
        <div className="col-md-4">
          <h2 className="page-head text-start">{t("At a Glance")}</h2>
        </div>
        <div className="col-md-8"></div>
      </div>
      {/* <div className={"table-div"}> */}
      {!isLoading ? (
        <div>
          <div className="row ">
            <div className="col-lg-4 col-md-6">
              <InvoiceCard
                header={`Today's Invoice
              ${
                Util.isValidData(depositList?.dues?.todays_count) &&
                `(${depositList?.dues?.todays_count})`
              }`}
                amount={
                  Util.isValidData(depositList?.dues?.todays_due) &&
                  Util.amountFormat(depositList?.dues?.todays_due)
                }
                contentColor={"#EAA12E"}
              ></InvoiceCard>
            </div>
            <div className="col-lg-4 col-md-6">
              <InvoiceCard
                header={`${t("Total Pending Invoice")}
                ${
                  Util.isValidData(depositList?.dues?.pending_count) &&
                  `(${depositList?.dues?.pending_count})`
                }`}
                amount={
                  depositList?.dues?.total_due !== undefined
                    ? Util.amountFormat(depositList?.dues?.total_due)
                    : t("N/A")
                }
                contentColor={"#ff4367"}
              >
                <CardDetail
                  title={`${t("Partial Paid")}
                ${
                  Util.isValidData(depositList?.dues?.partial_paid_count) &&
                  `(${depositList?.dues?.partial_paid_count})`
                }`}
                  amount={
                    depositList?.dues?.partial_paid !== undefined
                      ? Util.amountFormat(depositList?.dues?.partial_paid)
                      : t("N/A")
                  }
                  circle={"#ff4367"}
                  textColor={"#ff4367"}
                />
                <CardDetail
                  title={` ${t("Unpaid")}
                ${
                  Util.isValidData(depositList?.dues?.unpaid_count) &&
                  `(${depositList?.dues?.unpaid_count})`
                }`}
                  amount={
                    depositList?.dues?.unpaid !== undefined
                      ? Util.amountFormat(depositList?.dues?.unpaid)
                      : t("N/A")
                  }
                  circle={"#a9a9a9"}
                  textColor={"#a9a9a9"}
                />
              </InvoiceCard>
            </div>
            <div className="col-lg-4 col-md-6">
              <InvoiceCard
                header={` ${t("Payments in last 35 days")}
                ${
                  Util.isValidData(depositList?.payments?.total_count) &&
                  `(${depositList?.payments?.total_count})`
                }`}
                amount={
                  depositList?.payments?.total_amount !== undefined
                    ? Util.amountFormat(depositList?.payments?.total_amount)
                    : t("N/A")
                }
                contentColor={"#40b5ad"}
              >
                <CardDetail
                  title={`${t("Cash")}
                ${
                  Util.isValidData(depositList?.payments?.offline_count) &&
                  `(${depositList?.payments?.offline_count})`
                }`}
                  amount={
                    depositList?.payments?.offline !== undefined
                      ? Util.amountFormat(depositList?.payments?.offline)
                      : t("N/A")
                  }
                  circle={"#40b5ad"}
                  textColor={"#40b5ad"}
                />
                <CardDetail
                  title={`${t("Online")}
                ${
                  Util.isValidData(depositList?.payments?.online_count) &&
                  `(${depositList?.payments?.online_count})`
                }`}
                  amount={
                    depositList?.payments?.online !== undefined
                      ? Util.amountFormat(depositList?.payments?.online)
                      : t("N/A")
                  }
                  circle={"#40b5ad"}
                  textColor={"#40b5ad"}
                />
                <CardDetail
                  title={t("Bank Channel")}
                  amount={"00.00"}
                  circle={"#40b5ad"}
                  textColor={"#40b5ad"}
                />
              </InvoiceCard>
            </div>
          </div>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#f07a80",
                  height: 2,
                },
              }}
            >
              <Tab
                sx={{
                  letterSpacing: 0,
                  textTransform: "capitalize",
                  fontFamily: Const.fontfamily,
                  "&.Mui-selected": {
                    color: "#f07a80",
                    fontWeight: "600",
                  },
                }}
                value="Open Invoices"
                label={`${t("Open Invoices")} (${invoiceCount})`}
              />
              <Tab
                sx={{
                  letterSpacing: 0,
                  textTransform: "capitalize",
                  fontFamily: Const.fontfamily,
                  "&.Mui-selected": {
                    color: "#f07a80",
                    fontWeight: "600",
                  },
                }}
                value="Available Credits & Payments"
                label={t("Available Credits & Payments")}
              />
            </Tabs>
          </Box>

          {value === "Open Invoices" && (
            <PendingInvoices
              InvoiceCount={(n) => {
                setInvoiceCount(n);
              }}
              message={(msg) => {
                setIsToast(msg);
              }}
            />
          )}
          {value === "Available Credits & Payments" && <PendingCredits />}
        </div>
      ) : (
        <Loader />
      )}
      {/* </div> */}
    </div>
  );
}
export default ToastHOC(BillDashboard);
