import React from "react";

export default function InvoiceCard({
  header,
  amount,
  contentColor,
  children,
}) {
  return (
    <div>
      <div className="card" style={{ minHeight: "390px" }}>
        <div className="body">
          <div className="post-header">
            <div className="post-details">
              <h4 className="large-text">{header}</h4>
            </div>
          </div>
          <hr />
          <div className="card-content-details">
            <h2 className="text-start">{amount}</h2>
            <div
              className="invoice-content"
              style={{ backgroundColor: contentColor }}
            ></div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
export function CardDetail({ title, amount, circle, textColor }) {
  return (
    <>
      <div className="card-detail">
        <div className="d-flex align-items-center gap-3">
          <div className="invoice-card-circle" style={{ backgroundColor: circle }}></div>
          <div className="invoice-card-text" style={{ color: textColor }}>
            {title}
          </div>
        </div>
        <strong>{amount}</strong>
      </div>
    </>
  );
}
