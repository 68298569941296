import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Util from "../../../Util";
import ApiCalling from "../../../network/ApiCalling";
import moment from "moment";
import Calender from "../../../assets/Icon/calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-dropdown-select";
import Loader from "../../../components/common/Loader";
import ActivityCell from "../../Activity/ActivityCell";
import { useSelector } from "react-redux";

export default function ChildActivity() {
  const { t } = useTranslation();
  const history = useHistory();
  const user = Util.getUser();
  const selected = useSelector((state) => state.SelectedStudentReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [endOfList, setEndOfList] = useState(false);
  const [date, setDate] = useState(null);
  const [isNoData, setIsNoData] = useState(false);

  const [activityCategoryList, setActivityCategoryList] = useState([]);
  const [activityCategoryId, setActivityCategoryId] = useState(0);

  const recordPerPage = 20;
  const prevSelected = useRef(selected);

  const CustomInput = React.forwardRef(({ value, onClick, icon },ref) => (
    <div className=" d-flex" onClick={onClick}>
      <input
      ref={ref}
        className="custom-input__field"
        value={value}
        readOnly
        placeholder="Select Date"
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon calender-icon" />
      </div>
    </div>
  ));

  const changeDate = (dt) => {
    setCurrentPage(1);
    setEndOfList(false);
    setActivityList([]);
    setDate(dt);
  };
  const changeCheck = (data) => {
    setCurrentPage(1);
    setActivityList([]);
    setEndOfList(false);
    setActivityCategoryId(data[0].id);
  };

  const groupActivitiesByDate = (activityList) => {
    const groupedActivities = {};

    activityList.sort((a, b) => {
      const momentA = moment(a.created_on, "DD/MM/YYYY");
      const momentB = moment(b.created_on, "DD/MM/YYYY");

      return momentB - momentA;
    });

    activityList.forEach((activity) => {
      const createdDate = moment(activity.created_on).format("MM/DD/YYYY");

      if (!groupedActivities[createdDate]) {
        groupedActivities[createdDate] = [];
      }

      groupedActivities[createdDate].push(activity);
    });
    return groupedActivities;
  };
  const ActivityList = async () => {
    setIsLoading(true);
    const data = {
      page_size: recordPerPage,
      page_number: currentPage,
      activity_category_id: activityCategoryId,
      startDate: date !== null ? new Date(date) : null,
      endDate: date !== null ? new Date(date) : null,
      grade_id: 0,
      room_id: 0,
      student_id: selected !== null && selected.selectedStudent.id,
    };
    ApiCalling.apiCallBodyDataPost(
      "activity/getStudentActivities",
      data,
      selected.selectedStudent.student.School.id
    )
      .then((res) => {
        if (res.data.data.length > 0) {
          setActivityList((activityList) => ({
            ...activityList,
            ...groupActivitiesByDate(res.data.data),
          }));
          // debugger;
          setTotalPages(res.data.totalPages);
          if (res.data.totalPages === res.data.page_number) {
            setEndOfList(true);
          }
          setIsLoading(false);
          setIsNoData(false);
        } else {
          setIsNoData(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getActivityCategoryList = async () => {
    ApiCalling.apiCallBodyDataGet(
      "activity-category/list",
      selected.selectedStudent.student.School.id
    ).then((res) => {
      setActivityCategoryList(res.data);
    });
  };

  useEffect(() => {
    if (totalPages === currentPage) {
      setEndOfList(true);
      setIsNoData(false);
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage <= totalPages) {
      const handleScroll = () => {
        const windowHeight =
          "innerHeight" in window
            ? window.innerHeight
            : document.documentElement.offsetHeight;

        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        );

        const windowBottom = windowHeight + window.pageYOffset;
        // console.log("windowBottom & docheight", windowBottom, docHeight);

        if (windowBottom >= docHeight - 50 && !isLoading && !endOfList) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isLoading, endOfList, currentPage]);

  useEffect(() => {
    if (Util.isValidData(selected) && selected.selectedStudent.id > 0) {
      getActivityCategoryList();
    }
  }, [selected]);

  useEffect(() => {
    // setIsLoading(true);
    if (selected !== null && selected.selectedStudent.id > 0) {
      ActivityList();
    }
  }, [selected, activityCategoryId, currentPage, date]);

  useEffect(() => {
    if (Util.isValidData(selected) && selected !== prevSelected.current) {
      prevSelected.current = selected;
      setCurrentPage(1);
      setActivityList([]);
    }
  }, [selected]);

  return (
    <div>
      <div className="container-fluid">
        <div className="body">
          <div className="activity-container col-md-12 p-3">
            <div className="row mb-2 py-2">
              <div className="col-md-6">
                <h2 className="page-head">{t("Activity")}</h2>
              </div>
            </div>
            <div className=" d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex gap-2 pt-4 pl-0">
                <div className="datePickerContainer position-relative">
                  <DatePicker
                    placeholderText="Select Date"
                    selected={date}
                    onChange={changeDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="datePicker"
                    popperPlacement="bottom"
                    customInput={<CustomInput icon={Calender} />}
                    maxDate={new Date()}
                  />
                </div>
                <div>
                  <Select
                    className="select-custom "
                    options={[
                      { id: 0, title: "Category" },
                      ...activityCategoryList,
                    ]}
                    onChange={(values) => changeCheck(values)}
                    labelField="title"
                    values={[{ id: 0, title: "Category" }]}
                    valueField="id"
                  />
                </div>
              </div>
            </div>
            {isNoData ? (
              <div className="row">
                <div
                  className="col-lg-12"
                  style={{ paddingTop: "20%", paddingBottom: "20%" }}
                >
                  <p className="text-center text-secondary">
                    {t("No Activity found")} :-(
                  </p>
                </div>
              </div>
            ) : (
              <div className="row">
                {Object.keys(activityList).map((dt,index) => (
                  <div key={index}>
                    <div
                      className="d-flex align-items-center gap-2 mt-4 mb-3 p-0"
                      key={index}
                    >
                      <div className="calender-picker">
                        <img
                          src={Calender}
                          alt=""
                          style={{ cursor: "pointer" }}
                          width={20}
                          height={20}
                        />
                      </div>
                      <div className="large-bold-text">
                        {moment(dt).format("MMMM DD, YYYY")}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-md-12 ml-2 ">
                        {activityList[dt].map((data, index) => {
                          return (
                            <div key={index}>
                              <ActivityCell activity={data} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ))}

                {isLoading && <Loader />}
                {endOfList && (
                  <div className="extra-small-text align-center text-muted pb-2">
                    ----{t("End")}----
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
