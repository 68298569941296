import { t } from "i18next";
import React, { useEffect, useState } from "react";
import TagButton from "../Dashbord/TagButton";
import { getSupportedLanguages } from "i18n-iso-countries";
import Select from "react-dropdown-select";
import Loader from "../../components/common/Loader";
import Util from "../../Util";

import { Pagination } from "@mui/material";
import Search from "../../assets/Icon/Search.svg";
import close from "../../assets/Icon/close.svg";
import Sort from "../../assets/Icon/Short.svg";

import ApiCalling from "../../network/ApiCalling";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Stack from "@mui/material/Stack";
import moment from "moment";
import {
  browneTagStyle,
  greenTagStyle,
  orangeTagStyle,
  redTagStyle,
} from "../Dashbord/TagButtonStyle";

const statusList = [
  { value: "all", title: "All" },
  { value: 0, title: "Pending" },
  { value: 1, title: "Converted" },
  { value: 2, title: "Closed" },
];

const Admission = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const school = Util.getSelectedSchool();
  const [leadList, setLeadList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [searchClick, setSearchClick] = useState("");
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [programList, setProgramList] = useState([]);
  const [status, setStatus] = useState(0);
  const [hasClassRoom, setHasClassRoom] = useState();

  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const history = useHistory();

  const getLeadList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("lead/list/", {
      size: recordPerPage,
      page: currentPage,
      str_search: searchClick,
      lead_program_id: selectedProgramId,
      lead_status: status,
      ascdesc: sortOrder ? "desc" : "asc",
      order_by: sortBy,
    })
      .then((res) => {
        // console.log("lead list", res);
        setLeadList(res?.data?.response);
        setTotalPages(res?.data?.total);
        setHasClassRoom(res.data.length);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getLeadList();
  }, [
    searchClick,
    currentPage,
    recordPerPage,
    sortBy,
    sortOrder,
    selectedProgramId,
    status,
  ]);

  const searchClicked = () => {
    if (search.length > 0) {
      setSearch("");
      setSearchClick("");
    } else {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };
  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleSort = (field) => {
    console.log(field);
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const handleAllSelectedLeads = (e) => {
    if (e.target.checked) {
      setSelectedLeadId(leadList.map((item) => item.id));
    } else {
      setSelectedLeadId([]);
    }
  };

  const leadChecked = (event) => {
    const id = Number(event.target.value);
    if (event.target.checked) {
      setSelectedLeadId([...selectedLeadId, id]);
    } else {
      const data = selectedLeadId.filter((s) => s !== id);
      setSelectedLeadId(data);
    }
  };

  const getProgramList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("lead-program/list/")
      .then((res) => {
        const formattedData = res?.data?.map((program) => ({
          value: program.id,
          title: program.title,
        }));
        formattedData.unshift({ value: "all", title: "All" });
        setProgramList(formattedData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("ERROR", err);
        setIsLoading(false);
      });
  };

  // const changeStatus = (data) => {
  //   console.log(data);
  //   setCurrentPage(1);
  //   setStatus(data[0].value);
  // };

  const changeProgram = (selectedOption) => {
    const selectedValue = selectedOption[0].value;
    setCurrentPage(1);
    setSelectedProgramId(selectedValue === "all" ? null : selectedValue);
  };

  const changeStatus = (selectedOption) => {
    const selectedValue = selectedOption[0].value;
    setCurrentPage(1);
    setStatus(selectedValue === "all" ? null : selectedValue);
  };
  useEffect(() => {
    getProgramList();
  }, []);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-fluid">
        <div className="body">
          <div className="col-lg col-md-12 p-3">
            <div className="row py-3">
              <div className="col-md-4 text-start">
                <h2 className="page-head">{t("Admission Lead")}</h2>
              </div>
              <div
                className={`col-md-8  ${
                  getSupportedLanguages !== "ar" && "text-end"
                }`}
              >
                <div className="d-flex justify-content-end align-items-center gap-2">
                  <button
                    className={`${
                      Util.hasPermission("STUDENT_ADD")
                        ? "btn primary-sm-btn"
                        : "no-permission"
                    }`}
                    type="button"
                    onClick={() => {
                      history.push({
                        pathname: "/new-admission",
                      });
                    }}
                  >
                    <i class="fas fa-plus px-2"></i>
                    {t("Add New Lead")}
                  </button>
                </div>
              </div>
            </div>
            <div className={"table-div"}>
              <div className="d-flex gap-20 align-items-center table-sort-bar">
                <div className="custom-search-wrap">
                  <div className="input-group">
                    <input
                      className="form-control search "
                      placeholder={t("Search Lead")}
                      type="text"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      onKeyPress={(e) => EnterHandler(e)}
                    />
                    <div className="input-group-prepend">
                      <div className="search-icon">
                        <img
                          src={search?.length > 0 ? close : Search}
                          className="svg-icon"
                          alt=""
                          // onClick={searchClicked}
                          onClick={searchClicked}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mr-2 ">
                  <Select
                    searchable={false}
                    className="select-custom text-nowrap"
                    options={[...programList]}
                    onChange={changeProgram}
                    style={{ width: "100%" }}
                    labelField="title"
                    valueField="value"
                    values={
                      selectedProgramId
                        ? [selectedProgramId]
                        : [{ value: "all", title: "Program" }]
                    }
                  />
                </div>
                <div className="mr-2 ">
                  <Select
                    searchable={false}
                    className="select-custom text-nowrap"
                    options={[...statusList]}
                    onChange={changeStatus}
                    style={{ width: "100%" }}
                    labelField="title"
                    valueField="value"
                    color={!status ? "green" : "black"}
                    values={
                      status
                        ? [status]
                        : [
                            {
                              value: status === 0 ? 0 : "all",
                              title: status === 0 ? "Pending" : "Status",
                            },
                          ]
                    }
                  />
                </div>
              </div>

              {/* table start */}
              {isLoading ? (
                <Loader />
              ) : (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-wrapper">
                      <table className="table">
                        <thead>
                          <tr className="text-nowrap">
                            <th
                              className="check-col"
                              scope="col"
                              style={{
                                paddingRight: "0px",
                                width: 0,
                              }}
                            >
                              <label className="fancy-checkbox element-left">
                                {/* <input
                                  type="checkbox"
                                  name="allselect"
                                  onChange={handleAllSelectedLeads}
                                  checked={
                                    leadList?.length > 0 &&
                                    leadList?.length === selectedLeadId?.length
                                  }
                                />
                                <span></span> */}
                              </label>
                            </th>
                            <th
                              scope="col"
                              style={{
                                paddingLeft: "10px",
                                width: 0,
                              }}
                            >
                              {t("Name")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("student_first_name");
                                }}
                              />
                            </th>

                            <th scope="col">{t("Contact No.")}</th>
                            <th scope="col">
                              {t("Program")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("lead_program_id");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Inquired Date")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("created_on");
                                }}
                              />
                            </th>

                            <th scope="col">
                              {t("Status")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("lead_status");
                                }}
                              />
                            </th>
                          </tr>
                        </thead>
                        {leadList?.length > 0 ? (
                          <tbody>
                            {leadList?.map((item) => (
                              <>
                                <tr key={item?.id}>
                                  <td
                                    className="pl-0"
                                    style={{
                                      paddingRight: "0px",
                                      paddingLeft: "0px !important",
                                      width: 0,
                                    }}
                                  >
                                    {/* <label className="fancy-checkbox element-left">
                                      <input
                                        type="checkbox"
                                        name={item.first_name}
                                        onChange={leadChecked}
                                        value={item.id}
                                        checked={selectedLeadId?.includes(
                                          item.id
                                        )}
                                      />
                                      <span></span>
                                    </label> */}
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          history.push({
                                            pathname: "/admissionDetails",
                                            state: {
                                              studentId: item.id,
                                              hasClassRoom: hasClassRoom,
                                            },
                                          });
                                        }}
                                        style={{ fontWeight: "600" }}
                                      >
                                        {Util.capitalizeString(
                                          item?.student_first_name
                                        ) +
                                          " " +
                                          Util.capitalizeString(
                                            item?.student_last_name
                                          )}
                                      </a>
                                    </div>
                                  </td>

                                  {/* <td>{item?.father_name}</td> */}
                                  <td>
                                    {item?.father_phone_number
                                      ? item.father_phone_number
                                      : item.mother_phone_number}
                                  </td>

                                  {/* <td>
                                    {!item?.father_email
                                      ? "-"
                                      : item?.father_email}
                                  </td> */}
                                  <td>{item?.Lead_Program?.title}</td>
                                  <td>
                                    {" "}
                                    {Util.isValidData(item.created_on)
                                      ? moment(item.created_on).format(
                                          Util.getMomentDateTimeFormat(
                                            school.date_format
                                          )
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    <TagButton
                                      size={"100px"}
                                      title={
                                        item?.lead_status === 0
                                          ? "Pending"
                                          : item.lead_status === 1
                                          ? "Converted"
                                          : item.lead_status === 2
                                          ? "Closed"
                                          : ""
                                      }
                                      buttonStyle={
                                        item.lead_status === 0
                                          ? greenTagStyle
                                          : item.lead_status === 1
                                          ? orangeTagStyle
                                          : item.lead_status === 2
                                          ? browneTagStyle
                                          : ""
                                      }
                                      icon="fa fa-circle"
                                      iconSize="6px"
                                    />
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        ) : (
                          <tfoot>
                            <tr>
                              <td colSpan={8}>
                                {t(
                                  "No Lead Found! Please recheck the filter or Create One"
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    </div>
                    {totalPages > 0 && (
                      <div className="row m-0 table-footer border-top">
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ height: "72px" }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <div className="basic-text">
                              {t("Leads Per Page")}
                            </div>
                            <select
                              style={{ width: "72px", cursor: "pointer" }}
                              className="select-dropdown custom-input"
                              value={recordPerPage}
                              onChange={(e) => {
                                setRecordPerPage(e.target.value);
                              }}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </div>

                          <Stack
                            spacing={2}
                            direction="row"
                            alignItems="center"
                          >
                            <div
                              className={`link-button ${
                                currentPage == 1 ? "disabled" : ""
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                handlePreviousClick();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <KeyboardArrowLeftIcon />
                              <span className="basic-bold-text">
                                {t("Previous")}
                              </span>
                            </div>
                            <Pagination
                              count={totalPages}
                              page={currentPage}
                              onChange={(event, page) => {
                                setCurrentPage(page);
                              }}
                              size="small"
                              hideNextButton
                              hidePrevButton
                            />
                            <div
                              className={`link-button ${
                                currentPage == totalPages ? "disabled" : ""
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleNextClick();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <span className="basic-bold-text">
                                {t("Next")}
                              </span>
                              <KeyboardArrowRightIcon />
                            </div>
                          </Stack>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admission;
export { Search, close, Sort };
