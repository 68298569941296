import React from "react";
import CustomStepper from "./CustomStepper";
import Select from "react-dropdown-select";
import { useTranslation } from "react-i18next";

export default function NewsFeedOverview({
  TotalPostCount,
  eventCount,
  postCount,
  announcementCount,
  photosCount,
  videosCount,
  children,
}) {
  const {t} = useTranslation();
  return (
    <div>
      <div className="mt-3 news-feed-card">
        <div className="d-flex justify-content-between m-0 mb-2 mt-2">
          <div className=" large-bold-text">{t("News Feed Overview")} </div>
          {children}
        </div>
        <div className="text-sm-secondary">{t("Total Posts")}</div>
        <div className="extensive-large-text">{TotalPostCount}</div>
        <div className="d-flex align-items-center justify-content-between mt-1">
          <div className="col-2 p-0">
            <CustomStepper bgColor={"#4070FA"} />
          </div>
          <div className="col-2 p-0">
            <CustomStepper bgColor={"#EAA12E"} />
          </div>
          <div className="col-2 p-0">
            <CustomStepper bgColor={"#15A84B"} />
          </div>
          <div className="col-2 p-0">
            <CustomStepper bgColor={"#f07a80"} />
          </div>
          <div className="col-2 p-0">
            <CustomStepper bgColor={"#9FA9B6"} />
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <div
              className="custom-circle"
              style={{ backgroundColor: "#4070FA" }}
            ></div>
            <div className="text-lg-secondary">{t("Number of Announcement")}</div>
          </div>
          <div className="large-bold-text mr-1">{announcementCount}</div>
        </div>
        <hr className="dotted-hr"/>
        <div className="d-flex justify-content-between mt-2">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <div
              className="custom-circle"
              style={{ backgroundColor: "#EAA12E" }}
            ></div>
            <div className="text-lg-secondary">{t("Number of Post")}</div>
          </div>
          <div className="large-bold-text mr-1">{postCount}</div>
        </div>
        <hr className="dotted-hr"/>
        <div className="d-flex justify-content-between mt-2">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <div
              className="custom-circle"
              style={{ backgroundColor: "#15A84B" }}
            ></div>
            <div className="text-lg-secondary">{t("Number of Events")}</div>
          </div>
          <div className="large-bold-text mr-1">{eventCount}</div>
        </div>
        <hr className="dotted-hr"/>
        <div className="d-flex justify-content-between mt-2">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <div
              className="custom-circle"
              style={{ backgroundColor: "#f07a80" }}
            ></div>
            <div className="text-lg-secondary">{t("Photos")}</div>
          </div>
          <div className="large-bold-text mr-1">{photosCount}</div>
        </div>
        <hr className="dotted-hr"/>
        <div className="d-flex justify-content-between ">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <div
              className="custom-circle"
              style={{ backgroundColor: "#9FA9B6" }}
            ></div>
            <div className="text-lg-secondary">{t("Videos")}</div>
          </div>
          <div className="large-bold-text mr-1">{videosCount}</div>
        </div>
      </div>
    </div>
  );
}
