import React, { useEffect, useState } from "react";
import ToastHOC from "../../../HOC/ToastHOC";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ApiCalling from "../../../../network/ApiCalling";
import Util from "../../../../Util";
import StaffDetails from "../StaffDetails";
import BreadCrumbComponent from "../../../../components/BreadCrumbComponent";
import StaffMedia from "./StaffMedia";
import StaffResetPassword from "./StaffResetPassword";
import StaffInactive from "./StaffInactive";
import Const from "../../../../Const";

function StaffTab(props) {
  const { setIsToast } = props;
  const pendingToast = props.location.pendingToast;
  const userId = props.location.userId;
  const history = useHistory();
  const { t } = useTranslation();

  const user = Util.getUser();

  const [tabSelection, setTabSelection] = useState("General Information");
  const [getUserDetails, setGetUserDetails] = useState(null);
  const [getUserEmail, setUserEmail] = useState("");
  const [getUserSchoolId, setUserSchoolId] = useState(0);

  const handleTabChange = (event, newSelection) => {
    setTabSelection(newSelection);
  };

  const getUserData = async () => {
    ApiCalling.apiCallParamsGet("user/get-userdetail-byid/" + userId).then(
      (res) => {
        setGetUserDetails(res?.data?.user);
        setUserEmail(res?.data?.user?.email);
        setUserSchoolId(res?.data?.user?.user_Schools[0].id);
      }
    );
  };

  useEffect(() => {
    if (userId > 0) {
      getUserData();
    }
  }, [userId]);

  useEffect(() => {
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="container-80 mt-3">
        <BreadCrumbComponent
          Breadcrumb={[
            {
              name: "My School",
              navigate: () => {
                history.push("/Dashboard");
              },
            },
            {
              name: "Staff List",
              navigate: () => {
                history.push("/staff");
              },
            },
            { name: "Staff Details" },
          ]}
        />

        <div className="row mb-4 mt-2 ">
          <div className="col-md-6">
            <h2 className="page-head">{t("Staff Details")}</h2>
          </div>
        </div>

        <Box sx={{ width: "100%", marginBottom: "2%" }}>
          <Tabs
            value={tabSelection}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#f07a80",
                height: 2,
              },
            }}
          >
            <Tab
              sx={{
                letterSpacing: 0,
                textTransform: "capitalize",
                fontFamily: Const.fontfamily,
                "&.Mui-selected": {
                  color: "#f07a80",
                  fontWeight: "600",
                },
              }}
              value="General Information"
              label={t("General Information")}
            />
            <Tab
              sx={{
                letterSpacing: 0,
                textTransform: "capitalize",
                fontFamily: Const.fontfamily,
                "&.Mui-selected": {
                  color: "#f07a80",
                  fontWeight: "600",
                },
              }}
              value="Media Library"
              label={t("Media Library")}
            />
            <Tab
              sx={{
                letterSpacing: 0,
                textTransform: "capitalize",
                fontFamily: Const.fontfamily,
                "&.Mui-selected": {
                  color: "#f07a80",
                  fontWeight: "600",
                },
              }}
              value="Reset Password"
              label={t("Reset Password")}
            />
            {user.email !== getUserEmail &&
            Util.hasPermission("STAFF_DELETE") ? (
              <Tab
                sx={{
                  letterSpacing: 0,
                  textTransform: "capitalize",
                  fontFamily: Const.fontfamily,
                  "&.Mui-selected": {
                    color: "#f07a80",
                    fontWeight: "600",
                  },
                }}
                value="Inactive"
                label={t("Inactive")}
              />
            ) : (
              ""
            )}
          </Tabs>
        </Box>
        {tabSelection === "General Information" && (
          <StaffDetails
            props={props}
            userId={userId}
            // userDetails={getUserDetails}
          />
        )}
        {tabSelection === "Media Library" && (
          <StaffMedia props={props} userId={userId} />
        )}
        {tabSelection === "Reset Password" && (
          <StaffResetPassword props={props} userId={userId} />
        )}
        {tabSelection === "Inactive" && (
          <StaffInactive props={props} getUserSchoolId={getUserSchoolId} />
        )}
      </div>
    </div>
  );
}

export default ToastHOC(StaffTab);

// import React from "react";
// import { withTranslation } from "react-i18next";
// import StaffResetPassword from "./StaffResetPassword";
// import StaffInactive from "./StaffInactive";
// import ToastHOC from "../../../HOC/ToastHOC";
// import StaffProfile from "./StaffProfile";
// import Util from "../../../../Util";
// import ApiCalling from "../../../../network/ApiCalling";
// import StaffDetails from "../StaffDetails";
// import BreadCrumbComponent from "../../../../components/BreadCrumbComponent";
// import StaffMedia from "./StaffMedia";

// class StaffTab extends React.Component {
//   constructor(props) {
//     super(props);
//     this.user = Util.getUser();
//     this.state = {
//       getUserDetails: null,
//       getUserEmail: "",
//       getUserSchoolId: 0,
//     };
//   }

//   getUserData = async () => {
//     ApiCalling.apiCallParamsGet(
//       "user/get-userdetail-byid/" + this.props.location.userId
//     ).then((res) => {
//       this.setState({
//         getUserDetails: res?.data?.user,
//         getUserEmail: res?.data?.user?.email,
//         getUserSchoolId: res?.data?.user?.user_Schools[0].id,
//       });
//     });
//   };

//   componentDidMount() {
//     const { setIsToast } = this.props;
//     const pendingToast = this.props.location?.pendingToast;
//     if (pendingToast && pendingToast.length > 0) {
//       setIsToast(pendingToast);
//     }
//     this.getUserData();
//   }
//   onTabChange = (e) => {
//     var tab1 = document.getElementById("bacicTab-1");
//     tab1.classList.remove("active");
//     tab1.children[0].classList.remove("active");
//     var tab2 = document.getElementById("bacicTab-2");
//     tab2.classList.remove("active");
//     tab2.children[0].classList.remove("active");
//     var tab3 = document.getElementById("bacicTab-3");
//     tab3.classList.remove("active");
//     tab3.children[0].classList.remove("active");
//     var tab4 = document.getElementById("bacicTab-4");
//     tab4.classList.remove("active");
//     tab4.children[0].classList.remove("active");

//     var actab = document.getElementById("bacicTab-" + e);
//     actab.classList.remove("active");
//     actab.children[0].classList.add("active");

//     var tabpan1 = document.getElementById("bacicTabpan-1");
//     tabpan1.classList.remove("active");
//     var tabpan2 = document.getElementById("bacicTabpan-2");
//     tabpan2.classList.remove("active");
//     var tabpan3 = document.getElementById("bacicTabpan-3");
//     tabpan3.classList.remove("active");
//     var tabpan4 = document.getElementById("bacicTabpan-4");
//     tabpan4.classList.remove("active");

//     var actabpab = document.getElementById("bacicTabpan-" + e);
//     actabpab.classList.add("active");
//   };

//   render() {
//     const { t } = this.props;
//     const { history } = this.props;
//     return (
//       <div>
//         <div className="container-fluid">
//           {/* <div className="d-flex align-items-center gap-2 mb-3">
//             <a href="/staff">
//               <i className="fa fa-chevron-left" aria-hidden="true"></i>
//             </a>
//             <a href="/staff">{t("Staff")}</a>
//           </div> */}
//           <div className="container-80 mt-3">
//             <BreadCrumbComponent
//               Breadcrumb={[
//                 {
//                   name: "My School",
//                   navigate: () => {
//                     history.push("/Dashboard");
//                   },
//                 },
//                 {
//                   name: "Staff List",
//                   navigate: () => {
//                     history.push("/staff");
//                   },
//                 },
//                 { name: "Staff Details" },
//               ]}
//             />
//           </div>
//           <div className="body">
//             <div className="container-80 ">
//               <div className="row mb-4 mt-3">
//                 <div className="col-md-6">
//                   <h2 className="page-head">{t("Staff Details")}</h2>
//                 </div>
//               </div>
//               <ul className="nav nav-tabs" role="tablist">
//                 <li
//                   className="nav-item active"
//                   id="bacicTab-1"
//                   role="presentation"
//                   onClick={() => {
//                     this.onTabChange(1);
//                   }}
//                 >
//                   <a className="nav-link active">{t("General Information")}</a>
//                 </li>

//                 <li
//                   className="nav-item mr-1"
//                   id="bacicTab-2"
//                   role="presentation"
//                   onClick={() => {
//                     this.onTabChange(2);
//                   }}
//                 >
//                   <a className="nav-link" data-toggle="tab">
//                     {t("Media Library")}
//                   </a>
//                 </li>

//                 <li
//                   id="bacicTab-3"
//                   role="presentation"
//                   onClick={() => {
//                     this.onTabChange(3);
//                   }}
//                   className={`${
//                     Util.hasPermission("STAFF_EDIT")
//                       ? "nav-item mr-1"
//                       : "no-permission"
//                   }`}
//                 >
//                   <a className="nav-link" data-toggle="tab">
//                     {t("Reset Password")}
//                   </a>
//                 </li>
//                 {this.user.email !== this.state.getUserEmail ? (
//                   <li
//                     id="bacicTab-4"
//                     role="presentation"
//                     onClick={() => {
//                       this.onTabChange(4);
//                     }}
//                     className={`${
//                       Util.hasPermission("STAFF_DELETE")
//                         ? "nav-item mr-1"
//                         : "no-permission"
//                     }`}
//                   >
//                     <a className="nav-link" data-toggle="tab">
//                       {t("Inactive")}
//                     </a>
//                   </li>
//                 ) : (
//                   ""
//                 )}
//               </ul>
//             </div>

//             <div className="tab-content">
//               <div id="bacicTabpan-1" className="tab-pane active">
//                 <StaffDetails
//                   props={this.props}
//                   // userDetails={this.state.getUserDetails}
//                   userId={this.props.location.userId}
//                 />
//               </div>

//               <div id="bacicTabpan-2" className="tab-pane">
//                 <StaffMedia
//                   props={this.props}
//                   userId={this.props.location.userId}
//                 />
//               </div>

//               <div id="bacicTabpan-3" className="tab-pane">
//                 <StaffResetPassword
//                   props={this.props}
//                   userId={this.props.location.userId}
//                 />
//               </div>
//               {this.user.email !== this.state.getUserEmail ? (
//                 <div id="bacicTabpan-4" className="tab-pane">
//                   <StaffInactive
//                     props={this.props}
//                     getUserSchoolId={this.state.getUserSchoolId}
//                   />
//                 </div>
//               ) : (
//                 ""
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default ToastHOC(withTranslation()(StaffTab));
