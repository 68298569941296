import React, { useEffect, useState } from "react";
import ToastHOC from "../../HOC/ToastHOC";
import Sort from "../../../assets/Icon/Short.svg";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import Close from "../../../assets/Icon/close.png";
import { useTranslation } from "react-i18next";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import Loader from "../../../components/common/Loader";
import { Modal, Button } from "react-bootstrap";

function AnnouncementType(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [announcementTypeList, setAnnouncementTypeList] = useState([]);
  const [type, setType] = useState("");
  const [typeError, setTypeError] = useState("");
  const [editValue, setEditValue] = useState(null);
  const [showAnnouncementTypeModal, SetShowAnnouncementTypeModal] =
    useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [sortColumn, setSortColumn] = useState("AnnouncementType");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedAnnouncementTypeList = [...announcementTypeList].sort((a, b) => {
    if (sortColumn === "AnnouncementType") {
      return sortDirection === "asc"
        ? a.type.localeCompare(b.type)
        : b.type.localeCompare(a.type);
    }
    return 0;
  });

  const handleModalClose = () => {
    SetShowAnnouncementTypeModal(false);
    setType("");
    setTypeError("");
  };
  const handleSaveAndClose = () => {
    setTypeError("");
    let error = false;
    if (type === "") {
      setTypeError(t("Title is required"));
      error = true;
    }
    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      if (editValue !== null) {
        const data = {
          id: editValue.id,
          type: type,
        };

        ApiCalling.apiCallBodyDataPost("announcement-type/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              getAnnouncementType();
              setIsToast(res.data.message);
              SetShowAnnouncementTypeModal(false);
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      } else {
        const data = {
          type: type,
        };
        ApiCalling.apiCallBodyDataPost("announcement-type/add", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              getAnnouncementType();
              setIsToast(res.data.message);
              SetShowAnnouncementTypeModal(false);
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
    }
  };

  const handleAddModalShow = () => {
    SetShowAnnouncementTypeModal(true);
    setEditValue(null);
    setType("");
    setTypeError("");
  };

  const getAnnouncementType = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("announcement-type/list").then((res) => {
      setIsLoading(false);
      setAnnouncementTypeList(res.data);
    });
  };

  const handleDelete = () => {
    console.log("NO API Call");
    // setIsLoading(true);
    // ApiCalling.apiCallDelete(`fee-types/delete/${deleteId}`)
    //   .then((res) => {
    //     setIsLoading(false);
    //     setShowDeleteConfirmation(false);
    //     if (res.data.status == true) {
    //       setIsToast(t("Fee Type Deleted Successfully"));
    //       getData();
    //     } else if (res.data.status == false) {
    //       setIsToast(t("Somewhere used ,You Can't Delete"));
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  useEffect(() => {
    getAnnouncementType();
  }, []);

  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="header text-right mb-3 px-0">
              <button
                className={`${
                  Util.hasPermission("MASTERS_ADD")
                    ? "btn custom-primary"
                    : "no-permission"
                }`}
                type="button"
                aria-expanded="false"
                style={{ color: "#fff", padding: "5px, 10px" }}
                onClick={handleAddModalShow}
              >
                {t("Add Announcement Type")}
              </button>
              <Modal
                show={showAnnouncementTypeModal}
                onHide={handleModalClose}
                animation={true}
                centered={true}
                dialogClassName="modal-view"
                onShow={() => {
                  setIsClickedOnce(false);
                  if (editValue !== null) {
                    setType(editValue.type);
                  }
                }}
              >
                <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                  <h6>
                    {editValue !== null
                      ? t("Edit Announcement Type")
                      : t("Add Announcement Type")}
                  </h6>

                  <button className="hidden-btn" onClick={handleModalClose}>
                    <img
                      src={Close}
                      className="svg-icon"
                      height={20}
                      width={20}
                    />
                  </button>
                </div>
                <Modal.Body>
                  {!isLoading ? (
                    <div className="form-group text-start">
                      <label className="basic-text required">{t("Type")}</label>
                      <input
                        className={`form-control ${
                          typeError.length > 0 ? "input-error" : "custom-input"
                        }`}
                        placeholder={t("Type")}
                        type="text"
                        value={type}
                        onChange={(event) => {
                          setType(event.target.value);
                          setTypeError("");
                        }}
                      />
                      {typeError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {typeError}
                        </span>
                      )}
                    </div>
                  ) : (
                    <Loader />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btn custom-primary"
                    onClick={handleSaveAndClose}
                  >
                    {t("Save")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="row clearfix">
            <div className="table-div overflow-hidden m-0 p-0">
              <div className="col-lg-12 col-md-12 p-0">
                <div className="body">
                  {!isLoading ? (
                    <>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">
                              {t("Announcement Type")}{" "}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("AnnouncementType");
                                }}
                              />
                            </th>
                            {/* <th scope="col">{t("Student opted")}</th> */}
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        {announcementTypeList.length > 0 ? (
                          <tbody>
                            {sortedAnnouncementTypeList?.map((item, index) => (
                              <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.type}</td>
                                <td>
                                  <a
                                    role="button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEditValue(item);
                                      SetShowAnnouncementTypeModal(true);
                                    }}
                                    className={`${
                                      !Util.hasPermission("MASTERS_EDIT") &&
                                      "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </a>
                                </td>
                                <td>
                                  <a
                                    role="button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowDeleteConfirmation(true);
                                      setDeleteId(item.id);
                                    }}
                                    className={`${
                                      Util.hasPermission("MASTERS_DELETE")
                                        ? "pl-5"
                                        : "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-trash-o text-danger"></i>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tfoot>
                            <tr>
                              <td colSpan={4}>
                                {t(
                                  "There are no AnnouncementType. Click the 'Add Announcement Type' button to create one."
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                      {announcementTypeList.length > 0 && (
                        <div className="small text-muted mb-3 pl-3 border-top py-3">
                          <i>
                            {announcementTypeList.length}{" "}
                            {t("Announcement Types found")}.
                          </i>
                        </div>
                      )}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
                <ModalComponent
                  show={showDeleteConfirmation}
                  onHide={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  title={t("Delete")}
                  onCancel={t("Cancel")}
                  onConfirm={t("Confirm")}
                  subTitle={t(
                    "Are you sure, to delete this Announcement Type?"
                  )}
                  size="sm"
                  onCancelClick={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  onConfirmClick={handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
export default ToastHOC(AnnouncementType);
