import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import "echarts-gl";
import echarts from "echarts/lib/echarts";
import ApiCalling from "../../../network/ApiCalling";
import { retrieve } from "echarts-gl";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import Util from "../../../Util";

export default function ChildAttendence(props) {
  const { t } = useTranslation();
  // console.log("props::", props);
  const history = useHistory();
  const studentDetail = props?.props?.childInfo;
  const schoolId = props?.props?.childInfo?.Student?.School?.id;
  const [attendanceList, setAttendanceList] = useState([]);
  const [parameterList, setParameterList] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [holidayId, setHolidayId] = useState(0);
  const [holidaysDates, setHolidaysDates] = useState([]);
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");

  const options = {
    grid: { top: 20, right: 40, bottom: 20, left: 40 },
    xAxis: {
      type: "category",
      data: generateMonthsBetween(startDate, endDate),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [400, 300, 350, 200, 280],
        type: "bar",
        smooth: true,
        itemStyle: {
          color: "#6ebdd1",
        },
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  function generateMonthsBetween(startDate, endDate) {
    const months = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      months.push(currentDate.toLocaleString("default", { month: "short" }));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return months;
  }
  const eventTypeList = async () => {
    ApiCalling.apiCallBodyDataGet("event/types", schoolId)
      .then((res) => {
        res.data
          .filter((ele) => ele.id == 2)
          .map((val) => {
            setHolidayId(val.id);
          });
      })
      .catch((err) => {
        return err;
      });
  };

  const getEvents = () => {
    const start = moment(eventStartDate).format("YYYY/MM/DD");
    const end = moment(eventEndDate).format("YYYY/MM/DD")
    const data = {
      start_date: start,
      end_date: end,
      grade_id: 0,
      room_id: 0,
      event_type_id: 0,
      only_public: "false",
    };
    ApiCalling.apiCallBodyDataPost("event/list", data, schoolId)
      .then((res) => {
        const dates = res.data
          .filter((val) => val.event.EventType.id === holidayId)
          .map((val) => val.dd);
        setHolidaysDates(dates);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (
      Util.isValidData(holidayId) &&
      Util.isValidData(eventStartDate) &&
      Util.isValidData(eventEndDate)
    ) {
      setTimeout(()=>{
        getEvents();
      },100)
    
    }
  }, [holidayId, eventStartDate, eventEndDate]);

  const attendanceDetails = () => {
    ApiCalling.apiCallBodyDataGet(
      `attendance/get-student-attendance/${studentDetail?.Student?.id}`
    )
      .then((res) => {
        setAttendanceList(res.data.attendances);
        setParameterList(res.data.parameters);
        setEndDate(Date.parse(res.data.end));
        setStartDate(Date.parse(res.data.start));
        setEventStartDate(new Date(res.data.start));
        setEventEndDate(new Date(res.data.end));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const calculateTotalAttendancePercentage = () => {
    const presentday = attendanceList.filter(
      (att) => att.AttendanceParameter.reference === "PRESENT"
    ).length;

    const today = new Date();

    if (!isNaN(today) && !isNaN(startDate) && !isNaN(endDate)) {
      let daysBetween = 0;

      if (endDate > today) {
        const timeDiff = Math.abs(today - startDate);
        daysBetween = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      } else {
        const timeDiff = Math.abs(endDate - startDate);
        daysBetween = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      }

      const holidays = holidaysDates.map(
        (holidayDate) => new Date(holidayDate)
      );
      const totalDays = daysBetween - holidays.length;

      const attendancePercentage = (presentday / totalDays) * 100;
      return attendancePercentage.toFixed(2);
    } else {
      return "Invalid";
    }
  };

  const calculateTotalPresentDays = () => {
    const presentday = attendanceList.filter(
      (att) => att.AttendanceParameter.reference == "PRESENT"
    ).length;
    const today = new Date();
    if (!isNaN(today) && !isNaN(startDate) && !isNaN(endDate)) {
      let daysBetween = 0;
      if (endDate > today) {
        const timeDiff = Math.abs(today - startDate);
        daysBetween = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      } else {
        const timeDiff = Math.abs(endDate - startDate);
        daysBetween = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      }
      return `${presentday}/${daysBetween}`;
    } else {
      return "Invalid date";
    }
  };

  useEffect(() => {
    attendanceDetails();
    eventTypeList();
  }, []);
  useEffect(() => {
    if (!studentDetail) {
      history.push("/myChild");
    }
  }, [studentDetail]);
  return (
    <div>
      <div className="row clearfix">
        <div className="col-lg-6 col-md-12 mx-auto">
          <div className="card">
            <div className="header col-lg-2 float-right p-2">
              <select className="select-dropdown">
                <option>2023</option>
              </select>
            </div>
            <div className="body">
              <ReactEcharts
                option={options}
                opts={{ renderer: "svg" }} // use svg to render the chart.
              />
            </div>
          </div>

          <div className="card p-2 ">
            {parameterList.map((value) => {
              const total = attendanceList.filter(
                (att) => att.attendance_parameter_id === value.id
              ).length;
              return (
                <div
                  className="text-start d-flex align-items-center gap-3"
                  key={value.id}
                >
                  <div
                    className="box"
                    style={{
                      width: "15px",
                      height: "15px",
                      backgroundColor: "#6ebdd1",
                      border: "1px ",
                      borderRadius: "2px",
                    }}
                  ></div>
                  <div key={value.id}>
                    {value.name} : {total}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="card p-2 text-start ">
            {t("Total Attendance")} : {calculateTotalAttendancePercentage()}%
          </div>
          <div className="card p-2 text-start ">
            {t("Total Present days")} : {calculateTotalPresentDays()}
          </div>
        </div>
      </div>
    </div>
  );
}
