import React, { useEffect, useState } from "react";
import ApiCalling from "../../../../network/ApiCalling";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Select from "react-dropdown-select";
import Loader from "../../../../components/common/Loader";
import { useTranslation } from "react-i18next";
import Sort from "../../../../assets/Icon/Short.svg";
import moment from "moment";
import Util from "../../../../Util";
import * as XLSX from "xlsx/xlsx.mjs";

export default function ActivityReport() {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [gradeData, setGradeData] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [roomData, setRoomData] = useState([]);
  const [roomId, setRoomId] = useState(0);
  const [studentStatus, setStudentStatus] = useState("All");
  const [studentId, setStudentId] = useState(0);
  const [studentList, setStudentList] = useState([]);
  const [activityCategoryList, setActivityCategoryList] = useState([]);
  const [activityCategoryId, setActivityCategoryId] = useState(0);
  const [activityCategoryName, setActivityCategoryName] = useState("");
  const [studentActivityList, setStudentActivityList] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const statusList = [
    { value: "All", title: "All" },
    { value: true, title: "Active" },
    { value: false, title: "Inactive" },
  ];
  const ChangeDateRange = (range) => {
    if (range) {
      setStartDate(new Date(range[0]));
      setEndDate(new Date(range[1]));
    }
  };
  const handleCleanDate = () => {
    setStartDate();
    setEndDate();
  };

  const changeGrade = (data) => {
    setGradeId(data[0].id);
  };

  const changeRoom = (data) => {
    setRoomId(data[0].id);
  };
  const changeStatus = (data) => {
    setStudentStatus(data[0].value);
  };
  const changeStudent = (data) => {
    setStudentId(data[0].id);
  };
  const changeCheck = (data) => {
    setActivityCategoryId(data[0].id);
    setActivityCategoryName(data[0].title);
  };
  const gradeList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("grade/list")
      .then((res) => {
        setIsLoading(false);
        setGradeData(res.data);
      })
      .catch((err) => {
        return err;
      });
  };

  const roomList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("classroom/list")
      .then((res) => {
        setIsLoading(false);
        const modifiedClassList = res.data.map((c) => ({
          id: c.id,
          name: c.name,
        }));
        setRoomData(modifiedClassList);
      })
      .catch((err) => {
        return err;
      });
  };
  const getActivityCategoryList = async () => {
    ApiCalling.apiCallParamsGet("activity-category/list").then((res) => {
      setActivityCategoryList(res.data);
    });
  };
  const getStudentList = () => {
    if (gradeId > 0 || roomId > 0) {
      setIsLoading(true);
      let data = {
        page_size: 1000,
        page_number: 1,
        classroom_id: roomId,
        grade_id: gradeId,
        str_search: "",
        active: "true",
        ascdesc: "asc",
        order_by: "",
      };
      ApiCalling.apiCallBodyDataPost("student/list", data).then((res) => {
        const modifiedStudentList = res.data.data.map((student) => ({
          id: student.id,
          name: `${student.first_name} ${student.last_name}`,
        }));
        setStudentList(modifiedStudentList);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
    }
  };

  useEffect(() => {
    if (roomId > 0) {
      setStudentId(0);
      getStudentList();
    }
  }, [roomId]);

  const getStudentActivityList = () => {
    const data = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      isActive: studentStatus,
      grade_id: gradeId,
      classroom_id: roomId,
      student_id: studentId,
      activity_category_id: activityCategoryId,
      ascdesc: sortOrder ? "desc" : "asc",
      order_by: sortBy,
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("activity/get-student-reports", data)
      .then((res) => {
        setIsLoading(false);
        setStudentActivityList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const exportToExcel = () => {
    const dataForExport = studentActivityList.map((stu) => ({
      startDate: moment(startDate).format("DD/MM/YYYY"),
      endDate: moment(endDate).format("DD/MM/YYYY"),
      StudentStatus:
        studentStatus == true
          ? "Active"
          : studentStatus == false
          ? "InActive"
          : "All",
      activityCategory:
        activityCategoryName.length == 0 ? "All" : activityCategoryName,
      date: stu.created_on,
      StudentID: stu.id,
      StudentName: stu.first_name + "" + stu.last_name,
      startTime:
        stu?.start_time !== null ? moment(stu.start_time).format("HH:mm") : "-",
      endTime:
        stu?.end_time !== null ? moment(stu.end_time).format("HH:mm") : "-",

      category: stu.category,
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataForExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Student Activity Report"
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const newTab = window.open(url, "_blank");

    newTab.addEventListener("load", () => {
      URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    if (startDate && endDate !== null) {
      getStudentActivityList();
    }
  }, [
    startDate,
    endDate,
    gradeId,
    roomId,
    studentStatus,
    studentId,
    sortBy,
    sortOrder,
    activityCategoryId,
  ]);

  useEffect(() => {
    gradeList();
    roomList();
    getActivityCategoryList();
  }, []);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-fluid summary">
        <div className="row">
          <div className="row">
            <div className="col-lg-10 col-md-10 p-1">
              <div className="d-flex flex-wrap align-items-center gap-2">
                <div>
                  <div>
                    <DateRangePicker
                      className="custom-range-picker"
                      onChange={(range) => ChangeDateRange(range)}
                      onClean={handleCleanDate}
                      placeholder="Select Date"
                    />
                  </div>
                </div>
                <div>
                  <Select
                    className="select-custom"
                    options={[{ id: 0, title: "Grade" }, ...gradeData]}
                    labelField="title"
                    valueField="id"
                    values={[{ id: 0, title: "Grade" }]}
                    onChange={(values) => changeGrade(values)}
                  />
                </div>
                <div>
                  <Select
                    className="select-custom"
                    options={[{ id: 0, name: "Room" }, ...roomData]}
                    labelField="name"
                    valueField="id"
                    values={[{ id: 0, name: "Room" }]}
                    onChange={(values) => changeRoom(values)}
                  />
                </div>
                <div>
                  <Select
                    className="select-custom"
                    options={[...statusList]}
                    labelField="title"
                    valueField="value"
                    values={[{ value: "All", title: "Student Status" }]}
                    onChange={(values) => changeStatus(values)}
                  />
                </div>
                <div>
                  <Select
                    className="select-custom"
                    placeholder="Student"
                    options={[{ id: 0, name: "Student" }, ...studentList]}
                    onChange={(values) => changeStudent(values)}
                    style={{ width: "100%" }}
                    labelField="name"
                    values={[{ id: 0, name: "Student" }]}
                    valueField="id"
                  />
                </div>
                <div>
                  <Select
                    className="select-custom "
                    options={[
                      { id: 0, title: "Category" },
                      ...activityCategoryList,
                    ]}
                    onChange={(values) => changeCheck(values)}
                    labelField="title"
                    values={[{ id: 0, title: "Category" }]}
                    valueField="id"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 text-right">
              <button
                className="btn btn-lg text-nowrap custom-primary m-1"
                type="button"
                onClick={exportToExcel}
                disabled={startDate == null}
              >
                {t("Export Excel")}
              </button>
            </div>
          </div>
          <div className="table-div overflow-hidden mt-3 ">
            <div className="col-lg-12 col-md-12 p-0 m-0 ">
              <div className="">
                <div className="body">
                  {!isLoading ? (
                    <>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              {t("Date")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("created_on");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Student Id")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("id");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Student Name")}{" "}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("first_name");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Activity Start Time")}{" "}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("start_time");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Activity End Time")}{" "}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("end_time");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Activity Category")}{" "}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("category");
                                }}
                              />
                            </th>
                          </tr>
                        </thead>
                        {startDate && endDate !== null ? (
                          <>
                            {studentActivityList.length > 0 ? (
                              <>
                                {studentActivityList.map((val, index) => {
                                  return (
                                    <tbody key={index}>
                                      <tr>
                                        <td>
                                          {moment(val.created_on).format(
                                            Util.getMomentDateTimeFormat(
                                              school.date_format
                                            )
                                          )}
                                        </td>
                                        <td>{Util.studentIdFormat(val.id)}</td>
                                        <td>
                                          {val.first_name + " " + val.last_name}
                                        </td>
                                        <td>
                                          {val.start_time !== null
                                            ? moment(val.start_time).format(
                                                Util.getMomentDateTimeFormat(
                                                  school.time_format
                                                )
                                              )
                                            : "-"}
                                        </td>
                                        <td>
                                          {val.end_time !== null
                                            ? moment(val.end_time).format(
                                                Util.getMomentDateTimeFormat(
                                                  school._format
                                                )
                                              )
                                            : "-"}
                                        </td>
                                        <td>{val.category}</td>
                                      </tr>
                                    </tbody>
                                  );
                                })}
                              </>
                            ) : (
                              <tfoot>
                                <tr>
                                  <td colSpan={7}>{t("No Records found")}</td>
                                </tr>
                              </tfoot>
                            )}
                          </>
                        ) : (
                          <tfoot>
                            <tr>
                              <td colSpan={10}>
                                {t(
                                  "Please select period to generate the report"
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
