import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ApiCalling from "../../../../network/ApiCalling";
import { Rings } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-number-input";
import Util from "../../../../Util";
import { imageMyProfile } from "../../../CommonImage";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import ToastHOC from "../../../HOC/ToastHOC";
import CloseIcon from "@mui/icons-material/Close";

function ParentWork(props) {
  const { t } = useTranslation();
  const ParentDetails = props.ParentDetails;
  const { setIsToast } = props;
  const [companyName, setCompanyName] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [workAlternatePhone, setWorkAlternatePhone] = useState("");
  const [workAddress1, setWorkAddress1] = useState("");
  const [workAddress2, setWorkAddress2] = useState("");
  const [workCityId, setWorkCityId] = useState("");
  const [workZipCode, setWorkZipCode] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  const [workCountryId, setWorkCountryId] = useState(null);
  const [workStateId, setWorkStateId] = useState(null);
  const [notes, setNotes] = useState("");
  const [identityFile, setIdentityFile] = useState("");
  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [insuranceProvider, setInsuranceProvider] = useState("");
  const [identityURL, setIdentityURL] = useState("");
  const [employer, setEmployer] = useState("");
  const [familyIncome, setFamilyIncome] = useState("");
  const [workCountry, setWorkCountry] = useState([]);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [workState, setWorkState] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState("");
  const [city, setCity] = useState([]);

  const changeCompanyName = (e) => {
    setCompanyName(e.target.value);
  };
  const changeWorkAddress1 = (e) => {
    setWorkAddress1(e.target.value);
  };
  const changeWorkAddress2 = (e) => {
    setWorkAddress2(e.target.value);
  };
  const changeWorkCity = (e) => {
    setWorkCityId(e.target.value);
  };
  const changeNote = (e) => {
    setNotes(e.target.value);
  };
  const changeIdentityFile = (e) => {
    setIdentityFile(e.target.files[0]);
    setIdentityURL(URL.createObjectURL(e.target.files[0]));
  };
  const changeInsuranceNumber = (e) => {
    setInsuranceNumber(e.target.value);
  };
  const changeInsuranceProvider = (e) => {
    setInsuranceProvider(e.target.value);
  };
  const changeWorkZipCode = (e) => {
    setWorkZipCode(e.target.value);
    setPinCodeError("");
  };
  const changeWorkCountryId = (e) => {
    setWorkCountryId(e.target.value);
  };
  const changeWorkStateId = (e) => {
    setWorkStateId(e.target.value);
  };
  const changeEmployer = (e) => {
    setEmployer(e.target.value);
  };
  const changeFamilyIncome = (e) => {
    setFamilyIncome(e.target.value);
  };

  useEffect(() => {
    if (ParentDetails) {
      setCompanyName(
        Util.isValidData(ParentDetails.User.work_company)
          ? ParentDetails.User.work_company
          : ""
      );
      setWorkPhone(
        Util.isValidData(ParentDetails.User.work_phone)
          ? ParentDetails.User.work_phone
          : ""
      );
      setWorkAlternatePhone(
        Util.isValidData(ParentDetails.User.work_phone_alternate)
          ? ParentDetails.User.work_phone_alternate
          : ""
      );

      setWorkAddress1(
        Util.isValidData(ParentDetails.User.work_address1)
          ? ParentDetails.User.work_address1
          : ""
      );
      setWorkAddress2(
        Util.isValidData(ParentDetails.User.work_address2)
          ? ParentDetails.User.work_address2
          : ""
      );
      setWorkCityId(
        Util.isValidData(ParentDetails.User.work_city_id)
          ? ParentDetails.User.work_city_id
          : ""
      );
      setWorkZipCode(
        Util.isValidData(ParentDetails.User.work_zip)
          ? ParentDetails.User.work_zip
          : ""
      );
      setWorkCountryId(
        Util.isValidData(ParentDetails.User.work_country_id)
          ? ParentDetails.User.work_country_id
          : null
      );
      setWorkStateId(
        Util.isValidData(ParentDetails.User.work_state_id)
          ? ParentDetails.User.work_state_id
          : null
      );
      setIdentityURL(
        Util.isValidData(ParentDetails.User.identity)
          ? ParentDetails.User.identity
          : ""
      );
      setNotes(
        Util.isValidData(ParentDetails.User.notes)
          ? ParentDetails.User.notes
          : ""
      );
      setInsuranceNumber(
        Util.isValidData(ParentDetails.User.insurance_number)
          ? ParentDetails.User.insurance_number
          : ""
      );
      setInsuranceProvider(
        Util.isValidData(ParentDetails.User.insurance_provider)
          ? ParentDetails.User.insurance_provider
          : ""
      );
      setEmployer(
        Util.isValidData(ParentDetails.User.employer)
          ? ParentDetails.User.employer
          : ""
      );
      setFamilyIncome(
        Util.isValidData(ParentDetails.User.income_family)
          ? ParentDetails.User.income_family
          : ""
      );
    }
  }, [ParentDetails]);

  const handleSave = (e) => {
    e.preventDefault();
    let error = false;
    setPinCodeError("");
    if (workZipCode && Util.Zip_regex.test(workZipCode) === false) {
      setPinCodeError(t("Please enter valid zip-code(i.e: 12345 or 12345-2563)"));
      error = true;
    }
    if (!error) {
      const formData = new FormData();
      formData.append("user_id", ParentDetails.user_id);
      formData.append("work_company", companyName);
      formData.append("work_phone", workPhone);
      formData.append("work_phone_alternate", workAlternatePhone);
      formData.append("work_address1", workAddress1);
      formData.append("work_address2", workAddress2);
      if (workCityId) {
        formData.append("work_city_id", workCityId);
      }
      formData.append("work_zip", workZipCode);
      formData.append("work_country_id", workCountryId);
      formData.append("work_state_id", workStateId);
      formData.append("notes", notes);
      if (identityFile) {
        formData.append("user_identity", identityFile);
      }
      formData.append("employer", employer);
      formData.append("income_family", familyIncome);
      formData.append("insurance_number", insuranceNumber);
      formData.append("insurance_provider", insuranceProvider);

      ApiCalling.apiCallBodyDataPost("user/edit-parent-workdata", formData)
        .then((res) => {
          if (res.data.status == true) {
            setIsToast(t("work details updated successfully"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const WorkCountryList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet("country").then((res) => {
      setIsCountryLoading(false);
      setWorkCountry(res.data);
    });
  };
  const workStateList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${workCountryId}`).then(
      (res) => {
        setIsCountryLoading(false);
        setWorkState(res.data);
      }
    );
  };
  const cityList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${workStateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res.data);
    });
  };
  useEffect(() => {
    if (workStateId) {
      cityList();
    }
  }, [workStateId]);
  useEffect(() => {
    WorkCountryList();
  }, []);
  useEffect(() => {
    if (workCountryId) {
      workStateList();
    }
  }, [workCountryId]);
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="body">
              <h6 className="mt-2">{t("Work Info")}</h6>
              <div className="row">
                <div className="col-lg-6 col-md-12 mt-2">
                  <div className="form-group">
                    <label>{t("Company Name")}</label>
                    <input
                      className="form-control"
                      placeholder={t("Company Name")}
                      type="text"
                      value={companyName}
                      onChange={changeCompanyName}
                    />
                  </div>
                  <label>{t("Work Phone Number")}</label>
                  <div className="form-control" tabIndex={0}>
                    <PhoneInput
                    tabIndex="-1"
                      international
                      defaultCountry={defaultCountry}
                      value={workPhone}
                      onChange={(value) => {
                        setWorkPhone(value);
                      }}
                    />
                  </div>
                  <label className="mt-3">{t("Alternate Phone Number")}</label>
                  <div className="form-control" tabIndex={0}>
                    <PhoneInput
                    tabIndex="-1"
                      international
                      defaultCountry={defaultCountry}
                      value={workAlternatePhone}
                      onChange={(value) => {
                        setWorkAlternatePhone(value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mt-3">{t("Address Line 1")}</label>
                    <input
                      className="form-control"
                      placeholder={t("Address Line 1")}
                      type="text"
                      value={workAddress1}
                      onChange={changeWorkAddress1}
                    />
                  </div>
                  <div className="form-group">
                    <label>{t("Address Line 2")}</label>
                    <input
                      className="form-control"
                      placeholder={t("Address Line 2")}
                      type="text"
                      value={workAddress2}
                      onChange={changeWorkAddress2}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 mt-2">
                  <div className="form-group">
                    <label>{t("Country")}</label>
                    <select
                      className="select-dropdown"
                      value={workCountryId}
                      onChange={changeWorkCountryId}
                    >
                      <option selected value="">
                        {t("Select Country")}
                      </option>

                      {workCountry.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>{t("State")}</label>
                    <select
                      className="select-dropdown"
                      value={workStateId}
                      onChange={changeWorkStateId}
                      disabled={!workCountryId}
                    >
                      <option selected value="">
                        {isCountryLoading ? t("Loading...") : t("Select State")}
                      </option>
                      {workState.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>{t("city")}</label>
                    <select
                      className="select-dropdown"
                      value={workCityId}
                      onChange={changeWorkCity}
                      disabled={!workStateId}
                    >
                      <option selected value="">
                        {isCountryLoading ? t("Loading...") : t("Select city")}
                      </option>
                      {city.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>{t("Zipcode")}</label>
                    <input
                      className="form-control"
                      placeholder={t("Zipcode")}
                      value={workZipCode}
                      onChange={changeWorkZipCode}
                    />
                    {pinCodeError.length > 0 && (
                      <span className="text-danger">{pinCodeError}</span>
                    )}
                  </div>
                </div>
              </div>

              <h6 className="mt-2">{t("Financial Info")}</h6>
              <div className="row">
                <div className="col-lg-6 col-md-12 mt-2">
                  <div className="form-group">
                    <label>{t("Employer")}</label>
                    <input
                      className="form-control"
                      placeholder={t("Employer")}
                      type="text"
                      value={employer}
                      onChange={changeEmployer}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 mt-2">
                  <div className="form-group">
                    <label>{t("Family Income")}</label>
                    <input
                      className="form-control"
                      placeholder={t("Family Income")}
                      type="text"
                      value={familyIncome}
                      onChange={changeFamilyIncome}
                    />
                  </div>
                </div>
              </div>
              <h6 className="mt-2">{t("Health Info")}</h6>
              <div className="row">
                <div className="col-lg-6 col-md-12 mt-2">
                  <div className="form-group">
                    <label>{t("Insurance Number")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Insurance Number")}
                      value={insuranceNumber}
                      onChange={changeInsuranceNumber}
                    />
                  </div>
                  <label>{t("Insurance Provider")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Insurance Provider")}
                    value={insuranceProvider}
                    onChange={changeInsuranceProvider}
                  />
                </div>
                <div className="col-lg-6 col-md-12 mt-2">
                  <div className="form-group">
                    <label>{t("Identity File")}</label>
                    {identityURL === "" ? (
                      <>
                        <div
                          className="custom-file"
                          style={{ marginBottom: "3%" }}
                        >
                          <input
                            accept=".pdf"
                            type="file"
                            className="custom-file-input"
                            id="customFileLangHTML"
                            onChange={changeIdentityFile}
                          />
                          <label
                            className="custom-file-label"
                            for="customFileLangHTML"
                            data-browse="choose File"
                          >
                            {t("Choose Identity File")}
                          </label>
                        </div>
                      </>
                    ) : (
                      <div
                        className="input-filed"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // gap: 350,
                          alignItems: "center",
                        }}
                      >
                        <a
                          href={identityURL}
                          className="btn btn-link"
                          target="_blank"
                        >
                          {t("Download")}
                        </a>
                        <CloseIcon
                          onClick={() => {
                            setIdentityURL("");
                            setIdentityFile("");
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>{t("Notes")}</label>
                <textarea
                  rows="3"
                  className="form-control auto-height"
                  placeholder={t("Notes")}
                  value={notes}
                  onChange={changeNote}
                />
              </div>
              <button
                className="btn custom-primary"
                type="button"
                onClick={handleSave}
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(ParentWork);
