import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../components/common/Loader";

import { useHistory } from "react-router-dom";
import LoadingHOC from "./HOC/LoadingHOC";
import Util from "../Util";
import { useGoogleLogin } from "@react-oauth/google";

import ApiCalling from "../network/ApiCalling";
import axios from "axios";
import Invalid from "../assets/Icon/invalid.svg";
import { useTranslation } from "react-i18next";
import { LogoWhite } from "../components/common/Logo";
import { useSelector } from "react-redux";

const Login = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [passError, setPassError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [user, setUser] = useState(null);

 

  const history = useHistory();

  useEffect(() => {
    const user = Util.getUser();
    if (Util.isValidData(user) && Util.isValidData(user.id)) {
      if (Util.getParentMode()) {
        history.push("/ParentsDashboard");
      } else {
        if (Util.isValidData(Util.getSelectedSchool())) {
          history.push("/Dashboard");
        } else {
          history.push("/JoinSchool");
        }
      }
    }
  }, []);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      setIsLoading(false);
    },
    onError: (error) => {
      setIsLoading(false);
    },
  });

  const handleGoogleLogin = () => {
    setIsLoading(true);
    login();
  };

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          const data = {
            email: res.data.email,
          };
          ApiCalling.apiCallBodyDataPost("user/google-login", data)
            .then((res) => {
              if (res.data.status === true) {
                makeEnviornment(res.data.user);
              } else {
                setLoginError(t(res.data.message));
              }
            })
            .catch((err) => {
              console.log("Error", err);
            });
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const getLogin = async () => {
    const data = {
      email: email,
      password: password,
    };

    ApiCalling.apiCallBodyDataPost("user/login", data)
      .then((res) => {
        // console.log("LOGGGGN", res.data.user);
        setIsLoading(false);
        if (res === "ERR_NETWORK") {
          setLoginError(t("Server is not working"));
        } else if (res.data.status === true) {
          makeEnviornment(res?.data?.user);
        } else {
          setLoginError(t(res?.data?.message));
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  function makeEnviornment(user) {
    if (Util.isValidData(user)) {
      Util.saveUser(user);
      Util.saveAcadmicYear(user?.academic_year);

      if (user.is_staff) {
        if (Util.isArrayNotEmpty(user.user_Schools)) {
          if (user.user_Schools[0].RoleId) {
            Util.saveRoleInSelectedSchool(user.user_Schools[0].RoleId);
            Util.saveSelectedSchool(user.user_Schools[0].school);
            Util.saveParentMode(false);

            history.push("/Dashboard");
          } else {
            Util.saveParentMode(false);
            history.push("/waitingSchool");
          }
        } else {
          Util.saveParentMode(false);
          history.push("/JoinSchool");
        }
      } else {
        if (user.is_parent) {
          if (user.Parents.length > 0) {
            Util.saveSelectedStudent(user.Parents[0].Student);
          }
          Util.saveParentMode(true);
          Util.removeSelectedSchool();
          Util.removeActiveAcademicYear()
          history.push("/ParentsDashboard");
        } else {
          Util.saveParentMode(false);
          history.push("/JoinSchool");
        }
      }
    } else {
      setLoginError(t("Not getting data of user"));
    }
  }

  const loginHandler = (e) => {
    setLoginError("");
    setPassError("");
    let error = false;

    if (email === "") {
      setEmailError(t("Email is required"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPassError(t("Password is required"));
      error = true;
    } else if (Util.password(password) === false) {
      setPassError(t("password must be at least 5 characters long"));
      error = true;
    } else {
      setPassError("");
    }
    if (!error) {
      setIsLoading(true);
      getLogin();
    }
  };

  const checkEmail = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const checkPassword = (e) => {
    setPassword(e.target.value);
    setPassError("");
  };

  return (
    <div className="theme-cyan">
      <div className="hide-border">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            {isLoading ? (
              <Loader />
            ) : (
              <div className="auth-box">
                <LogoWhite />

                <div className="card">
                  <div className="header">
                    <h1 className="lead">{t("Welcome back!")} </h1>
                  </div>

                  <div className="body">
                    {loginError != "" && (
                      <div className="text-danger m-2 pb-2 text-center">
                        {loginError}
                      </div>
                    )}
                    <div className="form-auth-small">
                      <div className="input-filed">
                        <input
                          type="email"
                          value={email}
                          onChange={checkEmail}
                          className={`form-control custom-input ${
                            emailError.length > 0 && "input-error"
                          }`}
                          placeholder={t("Email address")}
                        />
                        {emailError.length > 0 && (
                          <span className=" invalid-error ">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {emailError}
                          </span>
                        )}
                      </div>
                      <div className="input-filed ">
                        <input
                          className={`form-control custom-input ${
                            passError.length > 0 && "input-error"
                          }`}
                          id="signin-password"
                          placeholder={t("Password")}
                          type="password"
                          value={password}
                          onChange={checkPassword}
                        />

                        {passError.length > 0 && (
                          <span className={"cl-input-error invalid-error"}>
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {passError}
                          </span>
                        )}

                        <span
                          className="helper-text"
                          style={{ marginTop: "15px" }}
                        >
                          <a href={`${process.env.PUBLIC_URL}/forgotpassword`}>
                            {t("Forgot password?")}
                          </a>
                        </span>
                      </div>

                      <div className="row mt-4">
                        <div className="col">
                          <button
                            className="btn custom-primary btn-lg btn-block get-started-btn"
                            type="submit"
                            onClick={loginHandler}
                          >
                            {t("Login")}
                          </button>
                        </div>
                      </div>
                      <p className="text-center text-separator mt-3">
                        {t("Or")}
                      </p>
                      <div className="row mb-2">
                        <div className="col">
                          <button
                            className="btn btn-signin-social"
                            onClick={handleGoogleLogin}
                          >
                            <i className="fa fa-google"></i>{" "}
                            {t("Sign in with Google")}
                          </button>
                        </div>
                      </div>
                      <div className="bottom">
                        <span>
                          {t("Don't have an account?")}
                          <a href="registration" className={"space-before-5"}>
                            {" "}
                            {t("Register")}
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingHOC(Login);
