import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { ReactPhotoCollage } from "react-photo-collage";
import {
  FavoriteBorderOutlined,
  Favorite,
  Add,
  ModeCommentOutlined,
  PictureAsPdf,
} from "@mui/icons-material";
import moment from "moment/moment";
import Util from "../../Util";
import {
  documentPost,
  imageComment,
  imageMyProfile,
  imagePost,
} from "../../screens/CommonImage";
import { useHistory } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import announcementIcon from "../../assets/Icon/megaphone-alt.svg";
import clock from "../../assets/Icon/clock.svg";

const CardAnnouncement = (props) => {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const is_staff = props.staff;
  const [data, setData] = useState(null);
  const [likeId, setLikeId] = useState([]);
  const [likeModal, setLikeModal] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState(0);
  const [deleteActivityPopup, setDeleteActivityPopup] = useState(false);
  const user = Util.getUser();
  const history = useHistory();
  
  const handleShow = () => {
    setLikeModal(true);
  };
  const handleClose = () => {
    setLikeModal(false);
  };
  const handleShowDelete = () => {
    setDeletePopup(true);
  };
  const handleCloseDelete = () => {
    setDeletePopup(false);
  };
  const handleShowDeleteActivity = () => {
    setDeleteActivityPopup(true);
  };
  const handleCloseDeleteActivity = () => {
    setDeleteActivityPopup(false);
  };

  useEffect(() => {
    setData(props.activity);
  }, []);

  useEffect(() => {
    setData(props.activity);
  }, [props]);

  

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
    </a>
  ));
  function getIconClass(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "xls":
        return "fa fa-file-excel-o text-success";
      case "xlsx":
        return "fa fa-file-excel-o text-success";
      case "pdf":
        return "fa fa-file-pdf-o text-color-primary";
      case "doc":
        return "fa fa-file-word-o text-primary";
      case "docx":
        return "fa fa-file-word-o text-primary";
      case "mkv":
      case "mp4":
        return "fa fa-file-video-o text-info";
      default:
        return "fa fa-file text-info";
    }
  }
  return (
    <div>
      {data ? (
        <>
          <div className="activity-card mb-3">
            <div className="post-header d-flex justify-content-start align-items-center">
              
                <img src={announcementIcon} height={24} width={24} />
              

              <div className="post-details ">
                <h4 className="medium-lg-text mx-2">
                  {data.CreatedBy.firstName + " " + data.CreatedBy.lastName+" "}
                   - {t("Announcement")}
                </h4>
               
                <div className="d-flex align-items-center mx-2 mt-1">
                  <img src={clock} height={"16px"} width={"16px"} />
                  <span className="sm-text mx-1">
                    {moment(data.created_on).format("hh:mm A")}
                  </span>
                </div>
                </div>
              {/* <div className="dots-button">
                {is_staff === true ? (
                  <>
                    {data.created_by === user.id ? (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          as={CustomToggle}
                          id="dropdown-basic"
                          className="user-name"
                        ></Dropdown.Toggle>

                        <Dropdown.Menu
                          className="dropdown-menu-right account"
                          style={{
                            backgroundColor: "white",
                          }}
                        >
                          <Dropdown.Item onClick={props.Edit}>
                            <i className="fa-regular fa-edit mr-2"></i>
                            {t("Edit")}
                          </Dropdown.Item>
                          <hr color="black" />
                          <Dropdown.Item
                            onClick={() => {
                              handleShowDeleteActivity();
                            }}
                          >
                            <i className="fa-regular fa-trash-can mr-2"></i>
                            {t("Delete")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div> */}
            </div>

            <div className="card-content-details">
              {/* <div>
                  {data?.ActivityStudents.map((students) => {
                    return (
                      <>
                        {students.Student !== null ? (
                          <>
                            <span className="badge badge-primary mb-4">
                              <i className="icon-user mr-1"></i>
                              {students.Student.first_name}
                              {""}
                              {students.Student.last_name}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </div> */}
              <div className="basic-text activity-description" style={{ whiteSpace: "pre-wrap" }}>
                {data.description}
              </div>

            
                {data?.ActivityMedia.length > 0 &&
                <div className="justify-content-center">
                {
                   data?.ActivityMedia.some((media) => 
                   media.name.match(/\.(jpg|jpeg|png|gif)$/i)
                 ) ? (
                   <>
                     <ReactPhotoCollage
                     
                       width="100%"
                       height={["300px", "134px"]}
                       layout={
                         data?.ActivityMedia.length > 2
                           ? [2, 4]
                           : data?.ActivityMedia.length == 2
                           ? [2]
                           : [1]
                       }
                       photos={data?.ActivityMedia.map((photo) => ({
                         source: `${imagePost}${photo.name}`,
                       }))}
                       showNumOfRemainingPhotos={true}
                     />
                   </>
                 ) : data?.ActivityMedia.some((media) =>
                     media.name.match(/\.(mkv|mp4)$/i)
                   ) ? (
                   <>
                     {data?.ActivityMedia.map((media,index) => {
                       return (
                         <>
                           <video
                             key={index}
                             src={documentPost + media.name}
                             width="400"
                             controls
                           >
                             <source
                               src={documentPost + media.name}
                               type="video/*"
                             />
                           </video>
                         </>
                       );
                     })}
                   </>
                 ) : (
                   <>
                     <div className="align-left p-2">
                       {data?.ActivityMedia.map((media,index) => {
                         return (
                           <a
                             href={documentPost + media.name}
                             target="_blank"
                             key={index}
                           >
                             <PictureAsPdf
                               fontSize="large"
                               className="text-color-primary"
                             />
                           </a>
                         );
                       })}
                     </div>
                   </>
                 )
                }
                </div>
               }
              
            </div>
            {/* <div className="footer d-flex justify-content-between hr-line">
                  <div className="">
                    <span
                      className="extra-small-text"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (data.ActivityLike.length > 0) {
                          handleShow();
                          setLikeId(data.ActivityLike);
                        }
                      }}
                    >
                      {data.ActivityLike.length > 1
                        ? data.ActivityLike.length + " Likes"
                        : data.ActivityLike.length + " Like"}
                    </span>
                  </div>
                  <div className="">
                    <span
                      className="text-right extra-small-text"
                      style={{ cursor: "pointer" }}
                      onClick={
                        data.ActivityComments.length > 0 && props.activityDetails
                      }
                    >
                      {data.ActivityComments.length > 1
                        ? "View All " + data.ActivityComments.length + " Comments"
                        : data.ActivityComments.length > 0
                        ? "View 1 Comment"
                        : "0 Comment"}
                    </span>
                  </div>
                </div>
                <div className="action-button d-flex justify-content-start mt-2">
                  <div className="like-bx">
                    {data.ActivityLike.some((element) => {
                      return element.created_by === user.id;
                    }) ? (
                      <>
                        <Favorite
                          fontSize="small"
                          className="text-color-primary"
                          onClick={props.dislikeOnClick}
                        />
                      </>
                    ) : (
                      <>
                        <FavoriteBorderOutlined
                          fontSize="small"
                          onClick={props.likeOnClick}
                        />
                      </>
                    )}
                  </div>
                  <div className="mx-5">
                    <ModeCommentOutlined
                      fontSize="small"
                      style={{ cursor: "pointer" }}
                      onClick={props.activityDetailsPage}
                    />
                  </div>
                </div> */}
          </div>

          <Modal
            size="md"
            show={deleteActivityPopup}
            onHide={handleCloseDeleteActivity}
            animation={true}
            centered={true}
            dialogClassName="modal-view"
          >
            <Modal.Header closeButton>
              <h6>{t("Delete Post")}?</h6>
            </Modal.Header>

            <Modal.Body>
              <strong>{t("Are you sure you want to delete this Post")}?</strong>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-link"
                onClick={handleCloseDeleteActivity}
              >
                {t("No")}
              </button>
              <button
                className="btn custom-primary"
                onClick={() => {
                  props.deleteActivity();
                  handleCloseDeleteActivity();
                }}
              >
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            size="md"
            show={likeModal}
            onHide={handleClose}
            animation={true}
            centered={true}
            dialogClassName="modal-view"
          >
            <Modal.Header closeButton>
              <div className="d-flex align-items-center gap-1">
                <Favorite fontSize="small" className=" text-color-primary" />
                <strong>{likeId.length}</strong>
              </div>
            </Modal.Header>
            {likeId.map((likedUser,index) => {
              return (
                <>
                  <Modal.Body key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      key={likedUser.CreatedBy.firstName}
                    >
                      <img
                        src={imageMyProfile + likedUser.CreatedBy.avatar}
                        className="rounded-circle user-photo"
                        style={{ width: "40px", height: "40px" }}
                        alt=""
                      />
                      <Favorite
                        fontSize="small"
                        className="mt-4 text-color-primary"
                        style={{ marginLeft: "-2%" }}
                      />
                      <h6 style={{ color: "black" }}>
                        {likedUser.CreatedBy.firstName +
                          " " +
                          likedUser.CreatedBy.lastName}
                      </h6>
                    </div>
                  </Modal.Body>
                </>
              );
            })}
          </Modal>
          <Modal
            size="sm"
            show={deletePopup}
            onHide={handleCloseDelete}
            animation={true}
            centered={true}
            dialogClassName="modal-view"
          >
            <Modal.Header closeButton>
              <strong>{t("Delete Comment")}?</strong>
            </Modal.Header>

            <Modal.Body>
              <div>{t("Are you sure you want to delete this comment")}?</div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn custom-primary"
                onClick={handleCloseDelete}
              >
                {t("No")}
              </button>
              <button
                className="btn custom-primary"
                onClick={() => {
                  props.deleteComment(deleteCommentId);
                  handleCloseDelete();
                }}
              >
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
export default CardAnnouncement;
