import React, { useContext, useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { googleLogout } from "@react-oauth/google";
import Util from "../Util";
import Add from "../assets/Icon/add.svg";
import Edit from "../assets/Icon/Edit.svg";
import Setting from "../assets/Icon/setting.svg";
import Exchange from "../assets/Icon/exchange-line.svg";

import activeIndicator from "../assets/Icon/radio-selected.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Searchwhite from "../assets/Icon/Search-white.svg";
import Search from "../assets/Icon/Search.svg";
import notificationWhite from "../assets/Icon/notification-unread-white.svg";
import notification from "../assets/Icon/notification-unread.svg";
import { SocketContext } from "../context/SocketContext";
import UserComponent from "./UserComponent";
import BuildingSchool from "../assets/Icon/building-school.svg";
import Select from "react-dropdown-select";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SchoolSetting from "./SchoolDetails/SchoolSetting";
import { switchSchool } from "../actions/SwitchSchoolAction";
import { useDispatch, useSelector } from "react-redux";
import NotificationModal from "../screens/Dashbord/NotificationModal";
import SearchModal from "../screens/Dashbord/SearchModal";
import ModalComponent from "../screens/Dashbord/ModalComponent";
import AddSchool from "./SchoolDetails/AddSchool";
import ToastHOC from "../screens/HOC/ToastHOC";
import { setAcademicYear, Toggle } from "../actions/ToggleAction";
import ApiCalling from "../network/ApiCalling";
import { Box, SwipeableDrawer } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Loader from "./common/Loader";

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;

  :hover {
    background: #f2f2f2;
  }
`;
function Topbar(props) {
  const { setIsToast, setIsSubToast } = props;
  const school = Util.getSelectedSchool();
  const user = Util.getUser();
  const history = useHistory();
  const socket = useContext(SocketContext);
  const getLanguage = Util.getSelectedLanguage();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState([]);
  const location = useLocation();

  const [showSwitchSchoolModal, setShowSwitchSchoolModal] = useState(false);
  const [selectedSchoolName, setSelectedSchoolName] = useState("");
  const [getSchool, setGetSchool] = useState(null);
  const [showAddSchoolPopup, setShowAddSchoolPopup] = useState(false);
  const [showSchoolSettingPopup, setShowSchoolSettingPopup] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [confirmParentLogin, setConfirmParentLogin] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [academicYearData, setAcademicYearData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [acadamicId, setAcadamicId] = useState(null);

  const activeAcademicYear = useSelector(
    (state) => state?.academicReducer?.value
  );

  const isShow = useSelector((state) => state.ToggleReducer);
  const dropdownRef = useRef(null);

  const tabletSidebar = () => {
    const leftSidebar = document.getElementById("left-sidebar");

    if (getLanguage === "ar") {
      if (leftSidebar.style.right === "-260px") {
        leftSidebar.style.right = "0";

        if (toggleMenu) {
          document.body.classList.remove("layout-fullwidth");
        } else {
          document.body.classList.add("layout-fullwidth");
        }
      }
    } else {
      if (leftSidebar.style.left === "-260px") {
        leftSidebar.style.left = "0";

        if (toggleMenu) {
          document.body.classList.remove("layout-fullwidth");
        } else {
          document.body.classList.add("layout-fullwidth");
        }
      }
    }
    setToggleMenu(!toggleMenu);
    dispatch(Toggle(false));
  };
  const mainSidebar = () => {
    if (getLanguage === "ar") {
      document.getElementById("left-sidebar").style.right = "0";
    } else {
      document.getElementById("left-sidebar").style.left = "0";
    }

    let origionalSize = "100%";
    let reduceSize = "260px";
    let size = `calc(${origionalSize} - ${reduceSize})`;
    document.getElementById("main-content").style.width = size;
    dispatch(Toggle(false));
  };
  useEffect(() => {
    if (getLanguage === "ar") {
      document.getElementById("main-content").style.float = "left";
    } else {
      document.getElementById("main-content").style.float = "right";
    }
  }, [getLanguage]);

  const customItemRenderer = ({ item, itemIndex, props, state, methods }) => (
    <StyledItem>
      <div
        onClick={() => {
          handleSchoolDropdown(item);
          // if (methods && typeof methods.dropDown === "function") {
          //   console.log("dropdownRenderer",methods.dropDown(true))
          //   methods.dropDown(true);
          // }
        }}
        className={item.icon === Add ? "option-seperator" : ""}
      >
        <div className="d-flex justify-content-between align-items-center ml-1">
          <div className="d-flex justify-content-start align-items-center gap-3">
            <img src={item.icon} />
            {item.name}
          </div>

          {item.id == Util.getSelectedSchool()?.id ? (
            <img src={activeIndicator} height={20} width={20} />
          ) : (
            ""
          )}
        </div>
      </div>
    </StyledItem>
  );
  const handleSchoolDropdown = (selectedOption) => {
    const selectedId = selectedOption.id;
    setSelectedSchoolName(selectedOption.name);
    const getSchoolID = Util.getSelectedSchool();

    if (selectedId > 0) {
      const selectedSchool = schoolNameList.find(
        (school) =>
          school.school.id === selectedId && school.school.id != getSchoolID.id
      );

      if (selectedSchool) {
        setGetSchool(selectedSchool.school);
        setShowSwitchSchoolModal(true);
      }
      // else {
      //   if (selectedId === getSchool.id) {
      //     this.props.history.push("/school-details");
      //   }
      // }
    } else {
      if (selectedId === -1) {
        setShowAddSchoolPopup(true);
      } else if (selectedId === -2) {
        setShowSchoolSettingPopup(true);
      } else if (selectedId === -3) {
        return history.push("/school-details");
      } else if (selectedId === -4) {
        setShowModal(true);
      }
    }
  };
  const handleSwitchSchool = async () => {
    setShowSwitchSchoolModal(false);
    await Util.removeUser();
    await Util.saveUser(user);
    await Util.saveParentMode(false);
    await Util.saveSelectedSchool(getSchool);
    dispatch(switchSchool(false));
    history.push("/Dashboard");
  };
  const loginAsParent = async () => {
    const student = user.Parents[0].Student;
    Util.removeSelectedSchool();
    Util.removeRoleInSelectedSchool();
    Util.saveParentMode(true);
    Util.saveSelectedStudent(student);
    setConfirmParentLogin(false);
    history.push("/ParentsDashboard");
  };
  const makeUserOffline = async () => {
    if (Util.isValidData(user)) {
      socket.emit("offline", user?.id);
    }
  };
  const handleBlur = async () => {
    //console.log("onBlur ***************");
    await makeUserOffline();
  };

  useEffect(() => {
    window.addEventListener("blur", handleBlur);
    setSchoolNameList(user.user_Schools);
    const cleanup = async () => {
      await makeUserOffline();
      window.removeEventListener("blur", handleBlur);
    };

    cleanup();
    return () => {};
  }, []);
  const options = [
    ...schoolNameList.map((school) => ({
      id: school.school.id,
      name: school.school.name,
      icon: BuildingSchool,
    })),
    Util.hasPermission("SCHOOL_ADD") && {
      id: -1,
      name: "Add New School",
      icon: Add,
    },
    { id: -3, name: "School Profile", icon: Edit },
    { id: -2, name: "School Settings", icon: Setting },
    { id: -4, name: "Change academic year", icon: Exchange },
  ].filter(Boolean);

  const AcademicList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("academic-year/getBySchoolId")
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        setAcademicYearData(res.data);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    AcademicList();
  }, []);

  useEffect(() => {
    const data = Util.getAcadmicYear();
    if (data && data) {
      setAcadamicId(data?.id);
    }
  }, [activeAcademicYear]);

  const handleSelectedAcademic = async (selected) => {
    if (selected) {
      try {
        await dispatch(setAcademicYear(selected));
      } catch (error) {
        console.log("ERROR", error);
      }
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      if (location.pathname === "/teacher") {
        history.push("/section");
      } else if (location.pathname === "/profileTabs") {
        history.push("/student");
      } else {
        history.push("/");
      }
    }
  }, [activeAcademicYear]);

  return (
    <div className=" header-border  sticky-top topbar">
      <div className="top-header">
        <div
          className={`d-flex justify-content-between align-items-center ${
            getLanguage == "ar" ? "ml-3" : "mr-3"
          }`}
        >
          {isShow.Clicked == true && (
            <div id="barIcon">
              <IconButton
                aria-label="close"
                size="small"
                className="btn-toggle-offcanvas collops-main"
                onClick={mainSidebar}
              >
                <MenuIcon />
              </IconButton>

              <IconButton
                aria-label="close"
                size="small"
                className="btn-toggle-offcanvas collops-tablet"
                onClick={tabletSidebar}
              >
                <MenuIcon />
              </IconButton>
            </div>
          )}

          <div id="navbar-search" className="mt-3 ml-3">
            <Select
              className="select-custom select-school"
              options={options}
              searchable={false}
              placeholder={Util.getSelectedSchool()?.name}
              valueField="id"
              itemRenderer={customItemRenderer}
              closeOnSelect={true}
            />
          </div>

          <div className="d-flex justify-content-end align-items-center gap-2 mt-2">
            <a
              className={"icon-menu d-none d-sm-block mr-3"}
              onClick={() => {
                setShowSearch(true);
              }}
            >
              {showSearch ? (
                <div className="search-active">
                  <img
                    src={Searchwhite}
                    alt="Search"
                    width={"22px"}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ) : (
                <img
                  src={Search}
                  alt="Search"
                  className="img-responsive logo svg-icon"
                  width={"24px"}
                  style={{ cursor: "pointer" }}
                />
              )}
            </a>
            <a
              className={`dropdown-toggle icon-menu ${
                showNotification && "notification-circle"
              }`}
              onClick={() => {
                setShowNotification(true);
              }}
            >
              {showNotification ? (
                <img
                  src={notificationWhite}
                  width={"20px"}
                  className="img-responsive logo svg-icon"
                  style={{ marginLeft: "6px", marginTop: "6px" }}
                />
              ) : (
                <img
                  src={notification}
                  alt="notification"
                  width={"24px"}
                  style={{ cursor: "pointer" }}
                />
              )}
            </a>
            <span className="divider"></span>
            <UserComponent
              LoginAsParent={() => {
                setConfirmParentLogin(true);
              }}
              onLogoutClick={(e) => {
                e.preventDefault();
                history.push("/");
                makeUserOffline();

                Util.removeUser();
                googleLogout();
              }}
            />
          </div>
        </div>
      </div>
      {showSchoolSettingPopup == true && (
        <SchoolSetting
          isVisible={showSchoolSettingPopup}
          isClose={() => {
            setShowSchoolSettingPopup(false);
          }}
          notification={(msg) => {
            if (msg) {
              setIsToast(msg);
              setIsSubToast("You have successfully updated a school setting");
            }
          }}
        />
      )}

      {showAddSchoolPopup == true && (
        <AddSchool
          props={props}
          isSave={(school) => {
            // this.setState({ addSchool: school });
          }}
          isVisible={showAddSchoolPopup}
          isClose={() => {
            setShowAddSchoolPopup(false);
          }}
          notification={(msg) => {
            if (msg) {
              setIsToast(msg);
              setIsSubToast("You have successfully added a New School");
            }
          }}
          updateSchoolList={(list) => {
            setSchoolNameList(list.user_Schools);
          }}
        />
      )}
      {showSwitchSchoolModal == true && (
        <ModalComponent
          show={showSwitchSchoolModal}
          onHide={() => {
            setShowSwitchSchoolModal(false);
          }}
          title={"Switch School"}
          subTitle={
            "You will be redirected to the" +
            " " +
            selectedSchoolName +
            " " +
            "after logout from this school"
          }
          size="sm"
          onCancel="Cancel"
          onConfirm="Confirm"
          onCancelClick={() => {
            setShowSwitchSchoolModal(false);
          }}
          onConfirmClick={() => {
            handleSwitchSchool();
          }}
        />
      )}
      {confirmParentLogin == true && (
        <ModalComponent
          show={confirmParentLogin}
          onHide={() => {
            setConfirmParentLogin(false);
          }}
          title={"Switch As Parent"}
          size="sm"
          onCancel="Cancel"
          onConfirm="Confirm"
          onCancelClick={() => {
            setConfirmParentLogin(false);
          }}
          onConfirmClick={loginAsParent}
        >
          <span>
            {t("Are you sure to Logout from this School")}?
            <br /> {t("You will be redirected to the ")}{" "}
            <b>{t("Parent Dashboard")}</b> {t("after logout from this school")}.
          </span>
        </ModalComponent>
      )}
      {showSearch == true && (
        <SearchModal
          show={showSearch}
          onHide={() => {
            setShowSearch(false);
          }}
        />
      )}
      {showNotification == true && (
        <NotificationModal
          show={showNotification}
          onHide={() => {
            setShowNotification(false);
          }}
        />
      )}
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onOpen={() => {}}
        disableEnforceFocus={true}
      >
        <div
          className="box-area"
          style={{
            width: "635px",
            padding: 25,
          }}
        >
          <Box role="presentation">
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">{t("Change Academic Year")}</div>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <table className="table mt-4">
              {!isLoading ? (
                <tbody>
                  {academicYearData.length > 0 ? (
                    academicYearData?.map((item, index) => (
                      <tr
                        className="selectedAcc"
                        style={{
                          backgroundColor:
                            item.id === acadamicId ? "#6418c30a" : "#fff",
                        }}
                        key={item.id}
                        onClick={() => handleSelectedAcademic(item)}
                        role="button"
                      >
                        <td>{item?.title}</td>
                        <td>
                          {moment(item?.start_on).format(
                            Util.getMomentDateTimeFormat(school?.date_format)
                          )}
                        </td>
                        <td>
                          {moment(item?.end_on).format(
                            Util.getMomentDateTimeFormat(school?.date_format)
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <table className="table">
                      <td>
                        <p>
                          Academic Year not found! Please create by click School
                          Setting
                        </p>
                      </td>
                    </table>
                  )}
                </tbody>
              ) : (
                <Loader />
              )}
            </table>
          </Box>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
export default ToastHOC(Topbar);
