import React from "react";
import Util from "../../Util";

const TagButton = ({
  border,
  title,
  size,
  icon,
  className,
  iconSize,
  buttonStyle
}) => {
  const getLanguage = Util.getSelectedLanguage();
  return (
    <>
      <button
        className={className}
        style={{
          borderRadius: "80px",
          padding: "4px 8px!important",
          backgroundColor: "transparent",
          border: `1px solid ${border}`,
          color: `${border}`,
          margin: "0 5px 0 0",
          width: `${size}`,
          display: "flex",
          height: '28px',
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          ...buttonStyle,
          cursor:'default'
        }}
      >
        <i
          className={`${icon} `}
          style={{
            fontSize: iconSize,
            padding:getLanguage == "ar" && "4px 0px 0px 5px"
                
          }}
        ></i>
        {title}
      </button>
    </>
  );
};

export default TagButton;
