import React from "react";
import Logo, { LogoWhite } from "../../components/common/Logo"
import {useTranslation} from "react-i18next";
import Util from "../../Util";
import {googleLogout} from "@react-oauth/google";

const WaitingSchoolConfirmation = () => {
  const {t}= useTranslation();
    return (
        <div className="theme-cyan">
            <div className="vertical-align-wrap">
                <div className="vertical-align-middle auth-main">
                    <div className="auth-box auth-wrapper">
                        <LogoWhite/>
                        <div className="card">
                            <div className="header">
                                <div className="header">
                                    <h2 className="text-center">
                                        {t("We send the verification to school, Please wait till they activate your account.")}
                                    </h2>
                                </div>
                
                                <div className="row mt-4">
                                    <div className="bottom col-lg-4 offset-4">
                                        <span>
                                            {t("Want To ReLogin?")}{" "}
                                            <a href="/" onClick={()=>{
                                                Util.removeUser();
                                                googleLogout();
                                            }}>{t("Login")}</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
  );
};

export default WaitingSchoolConfirmation;
