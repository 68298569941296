import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import NotificationAlert from "../Dashbord/NotificationAlert";

const ToastHOC = (WrappedComponent) => {
  function HOC(props) {
    const [showNotification, setShowNotification] = useState(false);
    const [isToast, setIsToast] = useState("");
    const [isSubToast, setIsSubToast] = useState("");

    const setToastState = (isComponent) => {
      setIsToast(isComponent);
      setShowNotification(true);
    };

    const setSubToastState = (isComponent) => {
      setIsSubToast(isComponent);
    };

    useEffect(() => {
      if (isToast.length > 0) {
        setShowNotification(true);
        const timer = setTimeout(() => {
          setIsToast("");
          setIsSubToast("");
          setShowNotification(false);
        }, 5000);

        return () => clearTimeout(timer);
      }
    }, [isToast, setIsToast]);

    return (
      <>
        {showNotification && (
          <NotificationAlert
          
            icon="fa fa-check"
            title={isToast}
            subTitle={isSubToast.length > 0 && isSubToast}
            onClose={() => {
              setIsToast("");
              setIsSubToast("");
              setShowNotification(false);
            }}
          />
        )}

        <WrappedComponent
          {...props}
          setIsToast={setToastState}
          setIsSubToast={setSubToastState}
        />
      </>
    );
  }

  return HOC;
};

export default ToastHOC;
