import React, { useState } from 'react'
import Util from '../../Util';
import check from "../../assets/Icon/check-double.svg"
import { useTranslation } from 'react-i18next';
import Loader from '../../components/common/Loader';

export default function ParentsTeacherList(props) {

  const { t } = useTranslation();
  
  const staffUsers = props?.StaffList;
  const onlineUsersList = props?.onlineUsersList;
  // const [staffUsers,setStaffUsers]=useState([]);
  const [selectedUserId, setSelectedUserId] = useState(props?.selectedUserId);

  function createRoom(user1) {
    setSelectedUserId(user1.user_id);
    props.startChat(user1);
  }
  return (
    <>
    {staffUsers !== undefined && staffUsers.length > 0 ? (
      <ul className="list-unstyled chat-list mt-2 mb-0 position-relative">
        {staffUsers
          .sort((a, b) => {
            if (a.unread > 0 || b.unread > 0)
              return b.unread > a.unread ? 1 : -1;
            else if (a.lastMessage_on !== "" && b.lastMessage_on !== "") {
              return new Date(b.lastMessage_on) > new Date(a.lastMessage_on)
                ? 1
                : -1;
            } else if (a.lastMessage_on === "" && b.lastMessage_on === "") {
              return a.firstName < b.firstName ? -1 : 1;
            } else {
              return b.lastMessage_on.length - a.lastMessage_on.length;
            }
          })

          ?.map((item,idx) => {
            const name = Util.getShortString(
              item.firstName + " " + item.lastName,
              17
            );
            return (
              
                <li
                  key={idx}
                  className={selectedUserId === item.user_id ? "active" : ""}
                >
                  <a
                    onClick={() => {
                      createRoom(item);
                    }}
                    className="d-flex"
                  >
                    {Util.getUserAvatar(item)}
                    <div
                      className={`row about ${
                        item.unread > 0 ? "unread" : ""
                      }`}
                    >
                      <div className="col-9 name">{name}</div>

                      <div className="col-3 last-message-time text-right">
                        {Util.getDateDisplay_ChatMessage(item.lastMessage_on)}
                      </div>

                      <div
                        className="col-10 last-message"
                        style={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                          maxHeight: "3.6em",
                        }}
                      >
                        {item.lastMessage.length > 0
                          ? item.lastMessage
                          : item.designation}
                      </div>
                      {item.isOnline && (
                        <div className="col-2 online text-right">
                          <img src={check} />
                        </div>
                      )}
                    </div>
                  </a>
                </li>
              
            );
          })}
      </ul>
    ) : (
     <Loader/>
    )}
    </>
  )
}
