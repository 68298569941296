import React, { useCallback, useEffect, useState } from "react";
import ProfileComponent from "./ProfileComponent";
import StaffPersonalInfo from "./StaffPersonalInfo";
import StaffSchoolInfo from "./StaffSchoolInfo";
import StaffAddress from "./StaffAddress";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import Loader from "../../../components/common/Loader";
import ToastHOC from "../../HOC/ToastHOC";
import Edit from "../../../assets/Icon/Edit-blue.svg";
import ExitAlert from "../../../components/ExitAlert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

export const Divider = () => {
  return (
    <div>
      <hr style={{ backgroundColor: "#E5E7EB", margin: "30px 0" }} />
    </div>
  );
};

const StaffDetails = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setIsToast, setIsSubToast } = props;
  const userId = props.userId;

  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [personalInfoEdit, setPersonalInfoEdit] = useState(
    userId > 0 ? false : true
  );
  const [AddressEdit, setAddressEdit] = useState(userId > 0 ? false : true);
  const [staffSchoolEdit, setStaffSchoolEdit] = useState(
    userId > 0 ? false : true
  );
  const [showPersnoalAcc, setShowPersonalAcc] = useState(true);
  const [showaddressAcc, setShowAddressAcc] = useState(
    userId > 0 ? false : true
  );
  const [showSchoolAcc, setShowSchoolAcc] = useState(userId > 0 ? false : true);
  const [alertModal, setIsAlertModal] = useState(false);
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: "",
  });

  const getUserData = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("user/get-userdetail-byid/" + userId).then(
      (res) => {
        if (Util.isValidData(res.data)) {
          setIsLoading(false);
          setUserDetails(res?.data?.user);
        }
      }
    );
  };

  useEffect(() => {
    if (userId > 0) {
      getUserData();
    }
  }, [userId]);
  const handleGoToNextPage = useCallback(
    (location) => history.push(location),
    [history]
  );

  useEffect(() => {

    if (triggerExit.onOk) {
      setTimeout(() => {
        handleGoToNextPage(triggerExit.path);
      }, 0);
    }
    const unblock = history.block((location) => {
      if (
        (location.pathname !== "/profileTabs" && personalInfoEdit ||
        staffSchoolEdit ||
        AddressEdit)
      ) {
        setIsAlertModal(true);
      } else {
        return true;
      }

      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToNextPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    personalInfoEdit,
    staffSchoolEdit,
    AddressEdit,
  ]);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="">
        <div className="school-details my-4">
          <div className="p-4">
            <ProfileComponent
              data={userDetails}
              userId={userId > 0 ? userId : "-1"}
              notification={(msg) => {
                if (msg) {
                  setIsToast(msg);
                  setIsSubToast(t("Photo saved successfully!"));
                  getUserData();
                }
              }}
            />
            <hr style={{ backgroundColor: "#E5E7EB" }} />
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="medium-large-text font-color-primary py-2">
                  {t("Personal Details")}
                </div>

                {userId > 0 && (
                  <>
                    {!personalInfoEdit && (
                      <div className="d-flex justify-content-center align-items-center">
                        <span
                          // style={{
                          //   fontSize: "14px",
                          //   fontWeight: "600",
                          //   color: "#6418C3",
                          //   cursor: "pointer",
                          // }}
                          className={`${
                            Util.hasPermission("STAFF_EDIT")
                              ? "link-button"
                              : "no-permission"
                          }`}
                          onClick={() => {
                            setPersonalInfoEdit(true);
                            setAddressEdit(false);
                            setStaffSchoolEdit(false);
                            setShowPersonalAcc(true);
                          }}
                        >
                          <img src={Edit} alt="" className="edit-svg " />
                          {t("Edit")}
                        </span>
                        <div className="vertical-bar"></div>
                        <div
                          className="accoridan-btn"
                          onClick={() => setShowPersonalAcc(!showPersnoalAcc)}
                        >
                          <i
                            className={
                              showPersnoalAcc
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {showPersnoalAcc ? (
                <>
                  <StaffPersonalInfo
                    personalInfoEdit={personalInfoEdit}
                    data={userDetails}
                    userId={userId > 0 ? userId : "-1"}
                    notification={(msg) => {
                      if (msg) {
                        setIsToast(msg);
                        setIsSubToast(t("Personal Details saved successfully!"));
                        getUserData();
                        setPersonalInfoEdit(false);
                      }
                    }}
                    onCancel={() => {
                      setPersonalInfoEdit(false);
                      setShowPersonalAcc(false);
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>
            <hr style={{ backgroundColor: "#E5E7EB" }} />
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="medium-large-text font-color-primary py-2">
                  {t("Address")}
                </div>

                {userId > 0 && (
                  <>
                    {!AddressEdit && (
                      <div className="d-flex justify-content-center align-items-center">
                        <span
                          // style={{
                          //   fontSize: "14px",
                          //   fontWeight: "600",
                          //   color: "#6418C3",
                          //   cursor: "pointer",
                          // }}
                          className={`${
                            Util.hasPermission("STAFF_EDIT")
                              ? "link-button"
                              : "no-permission"
                          }`}
                          onClick={() => {
                            setAddressEdit(true);
                            setPersonalInfoEdit(false);
                            setStaffSchoolEdit(false);
                            setShowAddressAcc(true);
                          }}
                        >
                          <img src={Edit} alt="" className="edit-svg " />
                          {t("Edit")}
                        </span>
                        <div className="vertical-bar"></div>
                        <div
                          className="accoridan-btn"
                          onClick={() => setShowAddressAcc(!showaddressAcc)}
                        >
                          <i
                            className={
                              showaddressAcc
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {showaddressAcc ? (
                <StaffAddress
                  AddressEdit={AddressEdit}
                  data={userDetails}
                  userId={userId > 0 ? userId : "-1"}
                  notification={(msg) => {
                    if (msg) {
                      setIsToast(msg);
                      setIsSubToast(t("Address Details saved successfully!"));
                      getUserData();
                      setAddressEdit(false);
                    }
                  }}
                  onCancel={() => {
                    setAddressEdit(false);
                    setShowAddressAcc(false);
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <hr style={{ backgroundColor: "#E5E7EB" }} />
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="medium-large-text font-color-primary py-2">
                 {t("School Information")}
                </div>

                {userId > 0 && (
                  <div className="d-flex justify-content-center align-items-center">
                    <span
                      // style={{
                      //   fontSize: "14px",
                      //   fontWeight: "600",
                      //   color: "#6418C3",
                      //   cursor: "pointer",
                      // }}
                      className={`${
                        Util.hasPermission("STAFF_EDIT")
                          ? "link-button"
                          : "no-permission"
                      }`}
                      onClick={() => {
                        setStaffSchoolEdit(true);
                        setPersonalInfoEdit(false);
                        setAddressEdit(false);
                        setShowSchoolAcc(true);
                      }}
                    >
                      <img src={Edit} alt="" className="edit-svg " />
                      {t("Edit")}
                    </span>
                    <div className="vertical-bar"></div>
                    <div
                      className="accoridan-btn"
                      onClick={() => setShowSchoolAcc(!showSchoolAcc)}
                    >
                      <i
                        className={
                          showSchoolAcc ? "fa fa-angle-up" : "fa fa-angle-down"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                )}
              </div>
              {showSchoolAcc ? (
                <StaffSchoolInfo
                  staffSchoolEdit={staffSchoolEdit}
                  data={userDetails}
                  userId={userId > 0 ? userId : "-1"}
                  notification={(msg) => {
                    if (msg) {
                      setIsToast(msg);
                      setIsSubToast(t("school Details saved successfully!"));
                      getUserData();
                      setStaffSchoolEdit(false);
                    }
                  }}
                  onCancel={() => {
                    setStaffSchoolEdit(false);
                    setShowSchoolAcc(true);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <ExitAlert
            visible={alertModal}
            onClose={() => setIsAlertModal(false)}
            onOk={() => {
              setTriggerExit((obj) => ({
                ...obj,
                onOk: true,
              }));
              setIsAlertModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(StaffDetails);
