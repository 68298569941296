import React, { useEffect, useState } from "react";
import "../Register/Custom.css";
import Logo, { LogoWhite } from "../../components/common/Logo";
import { useHistory, useNavigate } from "react-router-dom";
import ApiCalling from "../../network/ApiCalling";
import Util from "../../Util";
import { useTranslation } from "react-i18next";
import { googleLogout } from "@react-oauth/google";
import Loader from "../../components/common/Loader";
import Invalid from "../../assets/Icon/invalid.svg";

const JoinSchool = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [selectedOption, setSelectedOption] = useState("1");
  const [showDropdown, setShowDropdown] = useState(false);
  const [namelist, setNameList] = useState([]);
  const [schoolId, setSchoolId] = useState(0);
  const [schoolErr, setSchoolErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getSchoolList = async () => {
    ApiCalling.apiCallParamsGet("school/get-school-list")
      .then((res) => {
        setNameList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSchoolList();
  }, []);

  const JoinSchool = async () => {
    if (schoolId > 0) {
      const data = {
        userId: Util.getUser().id,
        schoolId: schoolId,
      };
      ApiCalling.apiCallBodyDataPost("user/add-user-school", data)
        .then((res) => {
          Util.saveParentMode(false);
          history.push("/waitingSchool");
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      setSchoolErr(t("Choose a School"));
    }
  };

  const confirmData = (e) => {
    e.preventDefault();
    if (selectedOption == 2) {
      JoinSchool();
    } else {
      history.push("/CreateSchool");
      setIsLoading(false);
    }
  };

  return (
    <div className="theme-cyan">
      <div className="vertical-align-wrap">
        <div className="vertical-align-middle auth-main">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="auth-box auth-wrapper">
              <LogoWhite />
              <div className="card">
                <div className="header">
                  <h1 className="text-center">
                    {t("What would you like to do")}?
                  </h1>
                </div>
                <div className="body">
                  <form>
                    <div className="row ">
                      <div className="col">
                        <select
                          className="form-select select-dropdown custom-input mb-3"
                          onChange={(e) => {
                            setSelectedOption(e.target.value);
                            setShowDropdown(!showDropdown);
                          }}
                        >
                          <option selected value="1">
                            {t("Create a new school")}
                          </option>
                          <option value="2"> {t("Join a school")} </option>
                        </select>
                        {showDropdown && (
                          <div className="input-filed">
                            <select
                              className={`form-select select-dropdown custom-input ${
                                schoolErr.length > 0 && "input-error"
                              }`}
                              onChange={(e) => {
                                setSchoolId(e.target.value);
                                setSchoolErr("");
                              }}
                            >
                              <option selected disabled value={0}>
                                {t("Select School from List")}
                              </option>
                              {namelist.map((val) => {
                                return (
                                  <option value={val.id} key={val.id}>
                                    {" "}
                                    {val.name}{" "}
                                  </option>
                                );
                              })}
                            </select>
                            {schoolErr.length > 0 && (
                              <span className={"invalid-error"}>
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {schoolErr}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <button
                          className="btn custom-primary btn-lg btn-block get-started-btn"
                          type="submit"
                          onClick={confirmData}
                        >
                          {t("Confirm")}
                        </button>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="bottom col-lg-10 offset-3">
                        <span>
                          {t("Not you")}?
                          <a
                            className={"space-before-5"}
                            href="/"
                            onClick={() => {
                              Util.removeUser();
                              googleLogout();
                            }}
                          >
                            {t("Login with another user")}
                          </a>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JoinSchool;
