import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../../Util";
import { useHistory } from "react-router-dom";
import ApiCalling from "../../../../network/ApiCalling";
import "react-phone-number-input/style.css";
import camera from "../../../../assets/Icon/cameraIcon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SchoolDetailsCard from "../../Student/SchoolDetailsCard";
import ContactDetailsCard from "../../Student/ContactDetailsCard";
import DoctorDetailsCard from "../../Student/DoctorDetailsCard";
import HealthDetailsCard from "../../Student/HealthDetailsCard";
import Calander from "../../../../assets/Icon/calendar.svg";
import { imageStudentList, studentIdentity } from "../../../CommonImage";
import EditPhotoModal from "../../EditPhotoModal";
import moment from "moment/moment";
import Loader from "../../../../components/common/Loader";
import Edit from "../../../../assets/Icon/Edit-blue.svg";

import * as faceapi from "face-api.js";
import StudentAvtar from "../../../../assets/Icon/StudentAvtar.svg";
import ToastHOC from "../../../HOC/ToastHOC";
import BreadCrumbComponent from "../../../../components/BreadCrumbComponent";

export const SeprateDot = () => {
  return <span className="sepration-dot"></span>;
};

function AttendanceDetails(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const datepickerRef = useRef(null);
  const { setIsSubToast } = props;
  const { setIsToast } = props;
  const school = Util.getSelectedSchool();
  const studentId = props.location.studentId;

  const [birthPlaceId, setBirthPlaceId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDate] = useState(null);

  const [age, setAge] = useState(0);
  const [countryId, setCountryId] = useState(0);
  const [BirthPlace, setBirthPlace] = useState("");
  const [RaceList, setRaceList] = useState([]);
  const [raceId, setRaceId] = useState(0);
  const [ethnicityList, setEthnicityList] = useState([]);
  const [ethnicityId, setEthnicityId] = useState(0);
  const [selectedNationality, setSelectedNationality] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [getCountryData, setCountryData] = useState([]);
  const [getAllCountry, setAllCountry] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [getStateData, setStateData] = useState([]);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [cityId, setCityId] = useState("");
  const [city, setCity] = useState();
  const [zipCode, setZipCode] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  const [selectedEthnicity, setSelectedEthnicity] = useState();

  const [personalDetailEdit, setPersonalDetailEdit] = useState(
    studentId > 0 ? false : true
  );
  const [AddressDetailEdit, setAddressDetailEdit] = useState(
    studentId > 0 ? false : true
  );
  const [healthDetailsEdit, setHealthDetailsEdit] = useState(
    studentId > 0 ? false : true
  );
  const [doctorDetailsEdit, setDoctorDetailsEdit] = useState(
    studentId > 0 ? false : true
  );
  const [schoolDetailsEdit, setSchoolDetailsEdit] = useState(
    studentId > 0 ? false : true
  );
  const [nationalityId, setNationalityId] = useState(0);

  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [pincode, setPincode] = useState("");
  const [selectedRace, setSelectedRace] = useState();
  const [selectedDob, setSelectedDob] = useState("");

  const [showEditModal, setShowEditModal] = useState(false);
  const [studentObject, setStudentObject] = useState(null);

  const [photoURL, setPhotoURL] = useState("");
  const [photo, setPhoto] = useState("");
  const [isFaceDetection, setIsFaceDetection] = useState(false);
  const [faceError, setfaceError] = useState(false);
  const [faceDetail, setFaceDetail] = useState(null);
  const [faceDetection, setFaceDetection] = useState(null);
  const canvasREF = React.useRef();
  const photoREF = React.useRef();
  const [index, setIndex] = useState(0);
  const [showAccordian, setShowAccordian] = useState(true);
  const [addressAcc, setShowAddressAcc] = useState(false);
  const [healthAcc, setShowHealthAcc] = useState(false);
  const [doctorAcc, setShowDoctorAcc] = useState(false);
  const [schoolCardAcc, setShowSchoolAcc] = useState(false);
  const [contactAcc, setShowContactAcc] = useState(false);

  const toggleDatePicker = () => {
    datepickerRef.current.setOpen(true);
  };

  const handleImage = async () => {
    const detection = await faceapi
      .detectSingleFace(photoREF.current, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
      .withFaceDescriptor();
    setIsFaceDetection(false);
    // let p = new faceapi.LabeledFaceDescriptors(`${firstName}`,[detection.descriptor])
    // setFaceDetail(p.toJSON());

    if (detection !== undefined) {
      setFaceDetail(detection.descriptor.toString());
      canvasREF.current.innerHtml = faceapi.createCanvasFromMedia(
        photoREF.current
      );
      faceapi.matchDimensions(canvasREF.current, {
        width: 300,
        height: 300,
      });
      const resized = faceapi.resizeResults(detection, {
        width: 300,
        height: 300,
      });
      faceapi.draw.drawDetections(canvasREF.current, resized);
    } else {
      setfaceError(
        "No Faces Detected in Selected Picture. Please upload a clear and sharp Picture."
      );
    }
  };
  useEffect(() => {
    if (photo !== "") {
      setfaceError("");
      setIsFaceDetection(true);
      const loadModels = () => {
        Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
          faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
          faceapi.nets.faceExpressionNet.loadFromUri("/models"),
        ]).then((values) => {
          handleImage();
        });
      };
      photoREF && loadModels();
    }
  }, [photo]);

  const handleSavePhoto = () => {
    if (faceDetail !== null) {
      // console.log("faceDetail",faceDetail.detection)
      setFaceDetection(faceDetail);
    }
    const formData = new FormData();
    formData.append("id", studentId);
    if (photo) {
      formData.append("photo", photo);
    }
    ApiCalling.apiCallBodyDataPost("student/dynamic-update", formData)
      .then((res) => {
        if (res.data.status == true) {
          setIsSubToast(t("Photo saved successfully!"));
          setIsToast(res.data.message);
          setShowEditModal(false);
          StudentDetails();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]);
    setPhotoURL(URL.createObjectURL(e.target.files[0]));
  };

  const StudentDetails = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("student/details/" + studentId).then(
      (res) => {
        if (Util.isValidData(res.data)) {
          setIsLoading(false);
          setStudentObject(res.data);
          const data = res?.data;
          setFirstName(data?.first_name);
          setLastName(data?.last_name);
          setBirthPlaceId(
            Util.isValidData(data?.birth_place_id) ? data?.birth_place_id : 0
          );
          setBirthPlace(data?.birth_place);
          setDate(data?.dob ? new Date(data.dob) : null);
          setSelectedDob(data?.dob);
          setSelectedEthnicity(data?.ethnicity);
          setSelectedCountryName(data?.Country?.name);
          setSelectedCityName(data?.City?.name);
          setSelectedStateName(data?.State?.name);
          setPhotoURL(
            Util.isValidData(data?.photo) ? imageStudentList + data?.photo : ""
          );
          if (data?.dob !== null) {
            const calculatedAge = Util.calculateAge(new Date(data?.dob));
            setAge(calculatedAge);
          } else {
            setAge(0);
          }
          setEthnicityId(
            Util.isValidData(data?.ethnicity_id) ? data?.ethnicity_id : 0
          );
          setNationalityId(
            Util.isValidData(data?.nationality_id) ? data?.nationality_id : 0
          );
          setRaceId(Util.isValidData(data?.race_id) ? data?.race_id : 0);
          setSelectedRace(data?.race);
          setAddressLine1(data?.address1);
          setAddressLine2(data?.address2);
          setCityId(Util.isValidData(data?.city_id) ? data?.city_id : 0);
          setCountryId(
            Util.isValidData(data?.country_id) ? data?.country_id : 0
          );
          setSelectedNationality(data?.nationality);
          setStateId(Util.isValidData(data?.state_id) ? data?.state_id : 0);
          setPincode(data?.pincode);
        }
      }
    );
  };

  useEffect(() => {
    if (studentId > 0) {
      StudentDetails();
    }
  }, [studentId]);
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameError("");
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameError("");
  };

  const changeBirthPlace = (e) => {
    setBirthPlaceId(e.target.value);
  };
  const changeEthnicityId = (e) => {
    e.preventDefault();
    setEthnicityId(e.target.value);
  };

  const changeDate = (date) => {
    setDate(date);
    const calculatedAge = Util.calculateAge(date);
    setAge(calculatedAge);
  };

  const racelist = async () => {
    ApiCalling.apiCallBodyDataGet("race")
      .then((res) => {
        setRaceList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ethnicitylist = async () => {
    ApiCalling.apiCallBodyDataGet("ethnicity")
      .then((res) => {
        setEthnicityList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const countryList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet("country").then((res) => {
      setIsCountryLoading(false);
      setCountryData(res?.data);
    });
  };

  const getAllCountryList = () => {
    ApiCalling.apiCallBodyDataGet("country/list").then((res) => {
      setAllCountry(res?.data);
    });
  };

  const stateList = async (id) => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${countryId}`).then(
      (res) => {
        setIsCountryLoading(false);
        setStateData(res?.data);
      }
    );
  };
  const cityList = async (id) => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res?.data);
    });
  };

  const handleAddressLine1Change = (e) => {
    setAddressLine1(e.target.value);
  };

  const handleAddressLine2Change = (e) => {
    setAddressLine2(e.target.value);
  };

  const resetPersonalDetails = () => {
    setPersonalDetailEdit(false);
    // setFirstName("");
    // setLastName("");
    // setRaceId(0);
    // setEthnicityId(0);
    // setBirthPlaceId(0);
    // setNationalityId(0);
    // setDate(null);
    setFirstNameError("");
    setLastNameError("");
  };

  const resetAddressDetails = () => {
    setAddressDetailEdit(false);
    // setAddressLine1("");
    // setAddressLine2("");
    // setStateId(0);
    // setCityId(0);
    // setCountryId(0);
    // setPincode("");
    setPinCodeError("");
  };

  const CustomInput = ({ value, onClick }) => (
    <div className=" d-flex" onClick={onClick}>
      <input className="custom-input__field" value={value} readOnly />
      <div className="custom-input__icon">
        <img src={Calander} alt="Calander" className="svg-icon calender-icon" />
      </div>
    </div>
  );

  const handleSavePersonalDetails = (e) => {
    e.preventDefault();
    setFirstNameError("");
    setLastNameError("");
    let error = false;
    if (firstName == "") {
      setFirstNameError(t("First Name is required"));
      error = true;
    }
    if (lastName == "") {
      setLastNameError(t("Last Name is required"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const formData = new FormData();

      if (firstName) {
        formData.append("first_name", firstName);
      }
      if (lastName) {
        formData.append("last_name", lastName);
      }
      if (birthPlaceId) {
        formData.append("birth_place_id", birthPlaceId);
      }
      if (dateOfBirth) {
        formData.append("dob", dateOfBirth);
      }
      if (raceId) {
        formData.append("race_id", raceId);
      }
      if (ethnicityId) {
        formData.append("ethnicity_id", ethnicityId);
      }
      if (nationalityId) {
        formData.append("nationality_id", nationalityId);
      }
      // if (photo) {
      //   formData.append("photo", photo);
      // }
      if (studentId > 0) {
        formData.append("id", studentId);
        ApiCalling.apiCallBodyDataPost("student/dynamic-update", formData).then(
          (res) => {
            if (res.data.status == true) {
              setIsLoading(false);
              setIsToast(res.data.message);
              setIsSubToast("Personal Details Updated Successfully!");
              setPersonalDetailEdit(false);
              StudentDetails();
            }
          }
        );
      } else {
        ApiCalling.apiCallBodyDataPost(`student/add`, formData)
          .then((res) => {
            setIsLoading(false);
            if (res.data.status == true) {
              history.push({
                pathname: "/Student",
                pendingToast: "Student Created Successfully",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleSaveAddressEdit = (e) => {
    e.preventDefault();
    let error = false;
    setPinCodeError("");
    if (pincode && Util.Zip_regex.test(pincode) === false) {
      setPinCodeError(
        t("Please enter valid Pin-code(i.e: 12345 or 12345-2563)")
      );
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const data = {
        id: studentId,
        address1: addressLine1,
        address2: addressLine2,
        city_id: cityId,
        pincode: pincode,
        state_id: stateId,
        country_id: countryId,
      };
      ApiCalling.apiCallBodyDataPost("student/dynamic-update", data)
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            setIsToast(res.data.message);
            setIsSubToast("Address Details Updated Successfully!");
            setAddressDetailEdit(false);
            StudentDetails();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  useEffect(() => {
    ethnicitylist();
    racelist();
    countryList();
    getAllCountryList();
  }, []);
  useEffect(() => {
    if (countryId) {
      stateList();
    }
  }, [countryId]);
  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);
  return (
    <div>
      {" "}
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div className="container-80">
          <BreadCrumbComponent
            Breadcrumb={[
              {
                name: "My School",
                navigate: () => {
                  history.push("/Dashboard");
                },
              },
              {
                name: "Attendance Log",
                navigate: () => {
                  history.push("/attendance-log");
                },
              },
              { name: "Attendance Details" },
            ]}
          />

          {!isLoading ? (
            <>
              <div className="school-details mt-3">
                <div className="p-4">
                  <div
                    className="d-flex align-items-center gap-4 "
                    style={{ marginBottom: "24px" }}
                  >
                    {photoURL.length > 0 ? (
                      <div
                        className="profile-area "
                        style={{
                          position: "relative",
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          alt="User"
                          className="rounded-circle user-photo img-responsive  mt-1 "
                          src={photoURL}
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                          }}
                        />{" "}
                        <img
                          alt="User"
                          className="img-responsive  mt-1"
                          style={{
                            height: "32px",
                            width: "32px",
                            borderRadius: "50%",
                            position: "absolute",
                            bottom: "-4px",
                            right: "7px",
                            cursor: "pointer",
                          }}
                          src={camera}
                          onClick={(e) => {
                            e.preventDefault();
                            // document.getElementById("photo").click();
                            setShowEditModal(true);
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className="profile-area"
                        style={{
                          position: "relative",
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          alt="User"
                          className="img-responsive  mt-1"
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                            position: "relative",
                          }}
                          src={StudentAvtar}
                        />

                        <img
                          alt="User"
                          className="img-responsive  mt-1"
                          style={{
                            height: "32px",
                            width: "32px",
                            borderRadius: "50%",
                            position: "absolute",
                            bottom: "-4px",
                            right: "7px",
                            cursor: "pointer",
                          }}
                          src={camera}
                          onClick={(e) => {
                            e.preventDefault();
                            // document.getElementById("photo").click();
                            setShowEditModal(true);
                          }}
                        />
                      </div>
                    )}

                    <div>
                      {studentId > 0 ? (
                        <>
                          <h2 className="extra-large-text font-color-primary">
                            {studentObject?.first_name +
                              " " +
                              studentObject?.last_name}
                          </h2>

                          <span className="d-flex flex-direction-row align-items-center justify-content-between">
                            {t("Grade")}: {studentObject?.grade?.title}{" "}
                            <SeprateDot /> {t("Class")}:{" "}
                            {studentObject?.classroom?.name} <SeprateDot />{" "}
                            {t("Age")}: {age?.years} {t("years")} {age?.months}{" "}
                            {t("months")}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <EditPhotoModal
                      show={showEditModal}
                      onClose={() => {
                        setShowEditModal(false);
                      }}
                      title={t("Edit Photo")}
                      subTitle={t(
                        "Show profile picture the best version of yourself"
                      )}
                      onChange={t("Change Image")}
                      onSave={t("Save Photo")}
                      Id={studentId}
                      onConfirmClick={() => setShowEditModal(false)}
                      ProfilePhoto={photoURL}
                      isFaceDetection={isFaceDetection}
                      photoURL={photoURL}
                      photoREF={photoREF}
                      canvasREF={canvasREF}
                      handlePhotoChange={handlePhotoChange}
                      handleSavePhoto={handleSavePhoto}
                      faceDetail={faceDetail}
                      faceError={faceError}
                      defaultAvtar={StudentAvtar}
                      clearFaceError={() => {
                        if (faceError) {
                          setfaceError();
                        }
                      }}
                    />
                  </div>
                  <hr style={{ backgroundColor: "#E5E7EB" }} />
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="medium-large-text font-color-primary py-2">
                        {t("Personal Details")}
                      </div>
                      {studentId > 0 && (
                        <>
                          {!personalDetailEdit && (
                            <div className="d-flex justify-content-center align-items-center">
                              <span
                                role="button"
                                onClick={() => {
                                  setPersonalDetailEdit(true);
                                  setAddressDetailEdit(false);
                                  setHealthDetailsEdit(false);
                                  setDoctorDetailsEdit(false);
                                  setSchoolDetailsEdit(false);
                                  setShowAccordian(true);
                                }}
                              >
                                <img src={Edit} alt="" className="edit-svg " />
                                {t("Edit")}
                              </span>
                              <div className="vertical-bar"></div>

                              <div
                                className="accoridan-btn"
                                onClick={() => setShowAccordian(!showAccordian)}
                              >
                                <i
                                  className={
                                    showAccordian
                                      ? "fa fa-angle-up"
                                      : "fa fa-angle-down"
                                  }
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    {showAccordian ? (
                      <>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="input-filed ">
                              <span className="basic-text ">
                                {t("First Name")}
                              </span>
                              {!personalDetailEdit ? (
                                <div className="large-bold-text">
                                  {Util.isValidData(firstName)
                                    ? firstName
                                    : "-"}
                                </div>
                              ) : (
                                <>
                                  <input
                                    className={`form-control custom-input ${
                                      firstNameError.length > 0
                                        ? "input-error"
                                        : ""
                                    }`}
                                    value={firstName}
                                    onChange={handleFirstNameChange}
                                  />
                                  {firstNameError.length > 0 && (
                                    <span className="text-danger">
                                      {" "}
                                      <i
                                        className="fa fa-exclamation-circle mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      {firstNameError}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-filed ">
                              <span
                                className="basic-text"
                                style={{ fontWeight: "400" }}
                              >
                                {t("Last Name")}
                              </span>
                              {!personalDetailEdit ? (
                                <div className="large-bold-text">
                                  {Util.isValidData(lastName) ? lastName : "-"}
                                </div>
                              ) : (
                                <>
                                  <input
                                    className={`form-control custom-input ${
                                      lastNameError.length > 0
                                        ? "input-error"
                                        : ""
                                    }`}
                                    value={lastName}
                                    onChange={handleLastNameChange}
                                  />
                                  {lastNameError.length > 0 && (
                                    <span className="text-danger">
                                      <i
                                        className="fa fa-exclamation-circle mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      {lastNameError}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-filed ">
                              <span
                                className="basic-text"
                                style={{ fontWeight: "400" }}
                              >
                                {t("Birth Place")}
                              </span>
                              {!personalDetailEdit ? (
                                <div className="large-bold-text">
                                  {Util.isValidData(BirthPlace)
                                    ? BirthPlace
                                    : "-"}
                                </div>
                              ) : (
                                <>
                                  <select
                                    name=""
                                    id=""
                                    className="select-dropdown form-select  custom-input "
                                    value={birthPlaceId}
                                    onChange={changeBirthPlace}
                                  >
                                    <option value={0}>
                                      {t("Select Birth Place")}
                                    </option>
                                    {getAllCountry?.map((item) => (
                                      <option
                                        // placeholder="grade"
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                  {/* {BirthPlaceError.length > 0 && (
                        <span className="text-danger">{BirthPlaceError}</span>
                      )} */}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-filed ">
                              <span
                                className="basic-text"
                                style={{ fontWeight: "400" }}
                              >
                                {t("Date of Birth")}
                              </span>
                              {!personalDetailEdit ? (
                                <div className="large-bold-text">
                                  {Util.isValidData(selectedDob)
                                    ? moment(selectedDob).format(
                                        Util.getMomentDateTimeFormat(
                                          school.date_format
                                        )
                                      )
                                    : "-"}
                                </div>
                              ) : (
                                <>
                                  <DatePicker
                                    ref={datepickerRef}
                                    className="datePicker"
                                    placeholderText="Select Date"
                                    selected={dateOfBirth}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onChange={changeDate}
                                    dateFormat={school.date_format}
                                    popperPlacement="bottom"
                                    maxDate={new Date()}
                                    customInput={<CustomInput />}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-filed ">
                              <span
                                className="basic-text"
                                style={{ fontWeight: "400" }}
                              >
                                {t("Race")}
                              </span>
                              {!personalDetailEdit ? (
                                <div className="large-bold-text">
                                  {Util.isValidData(selectedRace)
                                    ? selectedRace
                                    : "-"}
                                </div>
                              ) : (
                                <>
                                  <select
                                    name=""
                                    id=""
                                    className="select-dropdown form-select custom-input"
                                    value={raceId}
                                    onChange={(e) => {
                                      setRaceId(e.target.value);
                                    }}
                                  >
                                    <option value={0}>
                                      {t("Select Race")}
                                    </option>
                                    {RaceList?.map((item) => (
                                      <option
                                        placeholder="race"
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.race}
                                      </option>
                                    ))}
                                  </select>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-filed ">
                              <span className="basic-text ">
                                {t("Ethnicity")}
                              </span>
                              {!personalDetailEdit ? (
                                <div className="large-bold-text">
                                  {Util.isValidData(selectedEthnicity)
                                    ? selectedEthnicity
                                    : "-"}
                                </div>
                              ) : (
                                <>
                                  <select
                                    name=""
                                    id=""
                                    className="select-dropdown form-select custom-input"
                                    value={ethnicityId}
                                    onChange={changeEthnicityId}
                                  >
                                    <option value={0}>
                                      {t("Select Ethnicity")}
                                    </option>
                                    {ethnicityList?.map((item) => (
                                      <option
                                        placeholder="ethnicity"
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.ethnicity}
                                      </option>
                                    ))}
                                  </select>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-filed ">
                              <span className="basic-text">
                                {t("Nationality")}
                              </span>
                              {!personalDetailEdit ? (
                                <div className="large-bold-text">
                                  {Util.isValidData(selectedNationality)
                                    ? selectedNationality
                                    : "-"}
                                </div>
                              ) : (
                                <>
                                  <select
                                    name=""
                                    id=""
                                    className="select-dropdown form-select custom-input"
                                    value={nationalityId}
                                    onChange={(e) => {
                                      setNationalityId(e.target.value);
                                    }}
                                  >
                                    <option value={0}>
                                      {t("Select Nationality")}
                                    </option>
                                    {getAllCountry?.map((item) => (
                                      <option
                                        placeholder="grade"
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {personalDetailEdit && (
                      <div className="text-end">
                        {studentId > 0 && (
                          <button
                            className="secondary-lg-btn rounded-btn btn mr-2 m-0 px-2"
                            onClick={() => {
                              resetPersonalDetails();
                              setShowAccordian(false);
                            }}
                          >
                            {t("Cancel")}
                          </button>
                        )}
                        <button
                          className="primary-lg-btn rounded-btn btn"
                          onClick={handleSavePersonalDetails}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    )}
                  </div>
                  {studentId > 0 && (
                    <>
                      <hr style={{ backgroundColor: "#E5E7EB" }} />
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="medium-large-text font-color-primary py-2">
                            {t("Address")}
                          </div>
                          {studentId > 0 && (
                            <>
                              {!AddressDetailEdit && (
                                <div className="d-flex justify-content-center align-items-center">
                                  <span
                                    role="button"
                                    onClick={() => {
                                      setAddressDetailEdit(true);
                                      setPersonalDetailEdit(false);
                                      setDoctorDetailsEdit(false);
                                      setHealthDetailsEdit(false);
                                      setSchoolDetailsEdit(false);
                                      setPinCodeError("");
                                      setShowAddressAcc(true);
                                    }}
                                  >
                                    <img
                                      src={Edit}
                                      alt=""
                                      className="edit-svg "
                                    />
                                    {t("Edit")}
                                  </span>
                                  <div className="vertical-bar"></div>
                                  <div
                                    className="accoridan-btn"
                                    onClick={() =>
                                      setShowAddressAcc(!addressAcc)
                                    }
                                  >
                                    <i
                                      className={
                                        addressAcc
                                          ? "fa fa-angle-up"
                                          : "fa fa-angle-down"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        {addressAcc ? (
                          <div className="row mt-2">
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text ">
                                  {t("Address 1")}
                                </span>
                                {!AddressDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(addressLine1)
                                      ? addressLine1
                                      : "-"}
                                  </div>
                                ) : (
                                  <input
                                    className="form-control custom-input"
                                    value={addressLine1}
                                    onChange={handleAddressLine1Change}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text ">
                                  {t("Address 2")}
                                </span>
                                {!AddressDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(addressLine2)
                                      ? addressLine2
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <input
                                      className="form-control custom-input"
                                      value={addressLine2}
                                      onChange={handleAddressLine2Change}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text ">{t("City")}</span>
                                {!AddressDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(selectedCityName)
                                      ? selectedCityName
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <select
                                      className="select-dropdown form-select custom-input"
                                      value={cityId}
                                      onChange={(e) => {
                                        setCityId(e.target.value);
                                      }}
                                      disabled={!stateId}
                                    >
                                      <option value={0}>
                                        {isCountryLoading
                                          ? "Loading..."
                                          : "Select City"}
                                      </option>
                                      {city?.map((item) => {
                                        return (
                                          <option key={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text ">
                                  {t("Country")}
                                </span>
                                {!AddressDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(selectedCountryName)
                                      ? selectedCountryName
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <select
                                      className="form-select select-dropdown custom-input"
                                      value={countryId || ""}
                                      onChange={(e) => {
                                        setCountryId(e.target.value);
                                      }}
                                    >
                                      <option disabled value="">
                                        {t("Select Country")}
                                      </option>
                                      {getCountryData.map((item) => {
                                        return (
                                          <option key={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text ">
                                  {t("State")}
                                </span>
                                {!AddressDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(selectedStateName)
                                      ? selectedStateName
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <div className="form-group">
                                      <select
                                        className="select-dropdown form-select custom-input"
                                        value={stateId || ""}
                                        onChange={(e) => {
                                          setStateId(e.target.value);
                                        }}
                                        disabled={!countryId}
                                      >
                                        <option value="">
                                          {isCountryLoading
                                            ? t("Loading...")
                                            : t("Select State")}
                                        </option>
                                        {getStateData?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-filed ">
                                <span className="basic-text ">
                                  {t("PIN Code")}
                                </span>
                                {!AddressDetailEdit ? (
                                  <div className="large-bold-text">
                                    {Util.isValidData(pincode) ? pincode : "-"}
                                  </div>
                                ) : (
                                  <>
                                    <input
                                      className={`form-control custom-input ${
                                        pinCodeError.length > 0
                                          ? "input-error"
                                          : ""
                                      }`}
                                      value={pincode}
                                      onChange={(e) => {
                                        setPincode(e.target.value);
                                        setPinCodeError("");
                                      }}
                                    />
                                    {pinCodeError.length > 0 && (
                                      <span className="text-danger">
                                        <i
                                          className="fa fa-exclamation-circle mr-2"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {pinCodeError}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            {AddressDetailEdit && (
                              <div className="text-end">
                                {studentId > 0 && (
                                  <button
                                    className="secondary-lg-btn rounded-btn btn mr-2 m-0  px-2"
                                    onClick={() => {
                                      resetAddressDetails();
                                      setShowAddressAcc(false);
                                    }}
                                  >
                                    {t("Cancel")}
                                  </button>
                                )}
                                <button
                                  className="primary-lg-btn rounded-btn btn"
                                  onClick={handleSaveAddressEdit}
                                >
                                  {t("Save")}
                                </button>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <hr style={{ backgroundColor: "#E5E7EB" }} />
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="medium-large-text font-color-primary py-2">
                            {t("Health Details")}
                          </div>
                          {studentId > 0 && (
                            <>
                              {!healthDetailsEdit && (
                                <div className="d-flex justify-content-center align-items-center">
                                  <span
                                    role="button"
                                    onClick={() => {
                                      setHealthDetailsEdit(true);
                                      setPersonalDetailEdit(false);
                                      setAddressDetailEdit(false);
                                      setDoctorDetailsEdit(false);
                                      setSchoolDetailsEdit(false);
                                      setShowHealthAcc(true);
                                    }}
                                  >
                                    <img
                                      src={Edit}
                                      alt=""
                                      className="edit-svg "
                                    />
                                    {t("Edit")}
                                  </span>
                                  <div className="vertical-bar"></div>
                                  <div
                                    className="accoridan-btn"
                                    onClick={() => setShowHealthAcc(!healthAcc)}
                                  >
                                    <i
                                      className={
                                        healthAcc
                                          ? "fa fa-angle-up"
                                          : "fa fa-angle-down"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        {healthAcc ? (
                          <HealthDetailsCard
                            healthDetailsEdit={healthDetailsEdit}
                            studentId={studentId > 0 ? studentId : "-1"}
                            data={studentObject}
                            onSave={(msg) => {
                              setHealthDetailsEdit(false);
                              if (msg) {
                                setIsToast(msg);
                                setIsSubToast(
                                  t("Health Details Updated Successfully!")
                                );
                              }
                              StudentDetails();
                            }}
                            onCancel={() => {
                              setHealthDetailsEdit(false);
                              setShowHealthAcc(false);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      <hr style={{ backgroundColor: "#E5E7EB" }} />
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="medium-large-text font-color-primary py-2">
                            {t("Doctor Details")}
                          </div>
                          {studentId > 0 && (
                            <>
                              {!doctorDetailsEdit && (
                                <div className="d-flex justify-content-center align-items-center">
                                  <span
                                    role="button"
                                    onClick={() => {
                                      setDoctorDetailsEdit(true);
                                      setPersonalDetailEdit(false);
                                      setAddressDetailEdit(false);
                                      setHealthDetailsEdit(false);
                                      setSchoolDetailsEdit(false);
                                      setShowDoctorAcc(true);
                                    }}
                                  >
                                    <img
                                      src={Edit}
                                      alt=""
                                      className="edit-svg "
                                    />
                                    {t("Edit")}
                                  </span>
                                  <div className="vertical-bar"></div>
                                  <div
                                    className="accoridan-btn"
                                    onClick={() => setShowDoctorAcc(!doctorAcc)}
                                  >
                                    <i
                                      className={
                                        doctorAcc
                                          ? "fa fa-angle-up"
                                          : "fa fa-angle-down"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        {doctorAcc ? (
                          <DoctorDetailsCard
                            doctorDetailsEdit={doctorDetailsEdit}
                            studentId={studentId}
                            data={studentObject}
                            onSave={(msg) => {
                              if (msg) {
                                setIsToast(msg);
                                setIsSubToast(
                                  t("Doctor Details Updated Successfully!")
                                );
                              }
                              setDoctorDetailsEdit(false);
                              StudentDetails();
                            }}
                            onCancel={() => {
                              setDoctorDetailsEdit(false);
                              setShowDoctorAcc(false);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <hr style={{ backgroundColor: "#E5E7EB" }} />

                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="medium-large-text font-color-primary py-2">
                            {t("School Details")}
                          </div>
                          {studentId > 0 && (
                            <>
                              {!schoolDetailsEdit && (
                                <div className="d-flex justify-content-center align-items-center">
                                  <span
                                    role="button"
                                    onClick={() => {
                                      setSchoolDetailsEdit(true);
                                      setAddressDetailEdit(false);
                                      setPersonalDetailEdit(false);
                                      setDoctorDetailsEdit(false);
                                      setHealthDetailsEdit(false);
                                      setShowSchoolAcc(true);
                                    }}
                                  >
                                    <img
                                      src={Edit}
                                      alt=""
                                      className="edit-svg "
                                    />
                                    {t("Edit")}
                                  </span>
                                  <div className="vertical-bar"></div>
                                  <div
                                    className="accoridan-btn"
                                    onClick={() =>
                                      setShowSchoolAcc(!schoolCardAcc)
                                    }
                                  >
                                    <i
                                      className={
                                        schoolCardAcc
                                          ? "fa fa-angle-up"
                                          : "fa fa-angle-down"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        {schoolCardAcc ? (
                          <SchoolDetailsCard
                            schoolDetailsEdit={schoolDetailsEdit}
                            studentId={studentId > 0 ? studentId : "-1"}
                            data={studentObject}
                            onSave={(msg) => {
                              if (msg) {
                                setIsToast(msg);
                                setIsSubToast(
                                  t("School Details Updated Successfully!")
                                );
                              }
                              StudentDetails();
                              setSchoolDetailsEdit(false);
                            }}
                            onCancel={() => {
                              setSchoolDetailsEdit(false);
                              setShowSchoolAcc(false);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="school-details my-4 p-4">
                <ContactDetailsCard
                  studentId={studentId > 0 ? studentId : "-1"}
                  data={studentObject}
                  notification={(msg) => {
                    if (msg) {
                      setIsToast(msg);
                      setIsSubToast(t("Contact Updated Successfully!"));
                    }
                  }}
                  onSave={() => {
                    StudentDetails();
                  }}
                />
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(AttendanceDetails);
