import React, { useContext, useEffect, useState } from "react";
import Util from "../Util";
import UserComponent from "./UserComponent";
import notificationWhite from "../assets/Icon/notification-unread-white.svg";
import notification from "../assets/Icon/notification-unread.svg";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStudent } from "../actions/SelectStudentAction";
import Select from "react-dropdown-select";
import { googleLogout } from "@react-oauth/google";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SocketContext } from "../context/SocketContext";
import ModalComponent from "../screens/Dashbord/ModalComponent";
import NotificationModal from "../screens/Dashbord/NotificationModal";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Toggle } from "../actions/ToggleAction";

export default function ParentTopbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = Util.getUser();
  const socket = useContext(SocketContext);
  const [childList, setChildList] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  const isShow = useSelector((state) => state.ToggleReducer);

  const makeUserOffline = () => {
    // console.log("makeUserOffline");
    if (Util.isValidData(user)) {
      socket.emit("offline", user?.id);
    }
  };

  const switchAsStaff = async () => {
    setShowPopup(false);
    await Util.removeUser();
    await Util.saveUser(user);
    await Util.saveParentMode(false);
    await Util.saveSelectedSchool(user.user_Schools[0].school);
    history.push("/Dashboard");
  };

  const handleBlur = () => {
    console.log("onBlur ***************");
    makeUserOffline();
  };
  const tabletSidebar = async () => {
    const leftSidebar = document.getElementById("left-sidebar");

    if (leftSidebar.style.left === "-260px") {
      leftSidebar.style.left = "0";

      // Toggle the class
      if (toggleMenu) {
        document.body.classList.remove("layout-fullwidth");
      } else {
        document.body.classList.add("layout-fullwidth");
      }
    }
    setToggleMenu(!toggleMenu);
    dispatch(Toggle(false));
  };

  const mainSidebar = () => {
    document.getElementById("left-sidebar").style.left = "0";
    let origionalSize = "100%";
    let reduceSize = "260px";
    let size = `calc(${origionalSize} - ${reduceSize})`;
    document.getElementById("main-content").style.width = size;
    dispatch(Toggle(false));
  };
  useEffect(() => {
    document.getElementById("main-content").style.float = "right";
    window.addEventListener("blur", handleBlur);
    const cleanup = async () => {
      makeUserOffline();
      window.removeEventListener("blur", handleBlur);
    };

    cleanup();
    return () => {};
  }, []);

  useEffect(() => {
    if (user.Parents.length > 0) {
      const studentId = user.Parents[0].Student.id;
      setChildList(user.Parents);
      dispatch(setSelectedStudent(studentId, user.Parents[0].Student));
    }
  }, []);
  return (
    <div className=" header-border  sticky-top topbar">
      <div className="top-header">
        <div className="d-flex justify-content-between align-items-center">
          {isShow.Clicked == true && (
            <div id="barIcon">
              <IconButton
                aria-label="close"
                size="small"
                className="btn-toggle-offcanvas full-screen-toggle  collops-main"
                onClick={mainSidebar}
              >
                <MenuIcon />
              </IconButton>

              <IconButton
                aria-label="close"
                size="small"
                className="btn-toggle-offcanvas collops-tablet"
                onClick={tabletSidebar}
              >
                <MenuIcon />
              </IconButton>
            </div>
          )}
          <div id="navbar-search" className="mt-3 ml-3">
            {childList.length > 0 && (
              <Select
                searchable={false}
                className="select-custom"
                options={[
                  ...childList.map((st) => ({
                    id: st.Student.id,
                    name: st.Student.first_name + " " + st.Student.last_name,
                    data: st.Student,
                  })),
                ]}
                style={{ minWidth: "250px", paddingInline: "10px" }}
                labelField="name"
                values={[
                  {
                    id: 0,
                    name:
                      user?.Parents[0].Student?.first_name +
                      " " +
                      user?.Parents[0].Student?.last_name,
                    data: "",
                  },
                ]}
                valueField="id"
                onChange={(values) => {
                  // console.log("setSelectedStudent",this.props.setSelectedStudent());
                  // this.props.setSelectedStudent(values[0].id, values[0].data);
                  dispatch(setSelectedStudent(values[0].id, values[0].data));
                }}
              />
            )}
          </div>
          <div className="d-flex justify-content-end align-items-center gap-2 mt-3">
            <a
              className={`dropdown-toggle icon-menu ${
                showNotification && "notification-circle"
              }`}
              onClick={() => {
                setShowNotification(true);
              }}
            >
              {showNotification ? (
                <div className="d-flex justify-content-center align-items-center ">
                  <img
                    src={notificationWhite}
                    width={"22px"}
                    className="img-responsive logo svg-icon mt-1"
                    // style={{ marginLeft: "8px", marginTop: "8px" }}
                  />
                </div>
              ) : (
                <img
                  src={notification}
                  alt="notification"
                  width={"24px"}
                  style={{ cursor: "pointer" }}
                />
              )}
            </a>
            <span className="divider"></span>
            <UserComponent
              switchToStaff={() => {
                setShowPopup(true);
              }}
              onLogoutClick={(e) => {
                e.preventDefault();
                history.push("/");
                makeUserOffline();
                Util.removeUser();
                googleLogout();
              }}
            />
          </div>
        </div>
      </div>
      {showPopup == true && (
        <ModalComponent
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
          }}
          title={"Switch As Staff"}
          size="sm"
          onCancel="Cancel"
          onConfirm="Confirm"
          onCancelClick={() => {
            setShowPopup(false);
          }}
          onConfirmClick={switchAsStaff}
        >
          <span className="">
            {t("Are you sure to Logout")}?
            <br /> {t("You will be redirected to the ")}{" "}
            <b>{t("Staff Dashboard")}</b> {t("after logout from this school")}.
          </span>
        </ModalComponent>
      )}
      {showNotification == true && (
        <NotificationModal
          show={showNotification}
          onHide={() => setShowNotification(false)}
        />
      )}
    </div>
  );
}
