import React, { useEffect, useState } from "react";
import Util from "../../../../Util";
import ToastHOC from "../../../HOC/ToastHOC";
import ApiCalling from "../../../../network/ApiCalling";
import { imageStudentList } from "../../../CommonImage";
import moment from "moment/moment";
import { Card } from "react-bootstrap";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { greenTagStyle } from "../../TagButtonStyle";
import TagButton from "../../TagButton";
import { Avatar } from "@mui/material";
import Loader from "../../../../components/common/Loader";

function LeaveApproval({ setIsToast, refreshApprove, setRefreshApprove }) {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const [approveList, setApproveList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const leaveApproveList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(
      `leave-request/get-approved-request/${school.id}`
    )
      .then((res) => {
        setIsLoading(false);
        setApproveList(res.data.response);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (refreshApprove) {
      leaveApproveList();
      setRefreshApprove(false);
    } else {
      leaveApproveList();
    }
  }, [refreshApprove, setRefreshApprove]);
 
  return (
    <div>
      {!isLoading ? (
        <>
          <div className="container-fluid">
            <div className="row p-3">
              {approveList.length > 0 ? (
                <>
                  {approveList.map((approve) => {
                    const formattedDate = moment(approve.start_date).format(
                      "MMMM DD ddd"
                    );
                    const formattedEndDate = moment(approve.end_date).format(
                      "MMMM DD ddd"
                    );
                    return (
                      <div className="col-6" key={approve.id}>
                        <div className="activity-card mb-4">
                          <div className="body">
                            <div className="post-header d-flex justify-content-start">
                              {Util.isValidData(approve.Student.photo) ? (
                                <img
                                  src={imageStudentList + approve.Student.photo}
                                  className="rounded-circle user-photo"
                                  style={{ width: "40px", height: "40px" }}
                                  alt=""
                                />
                              ) : (
                                <Avatar
                                  aria-label="recipe"
                                  className="avatar-font"
                                >
                                  {approve.Student.first_name.toUpperCase()[0] +
                                    approve.Student.last_name.toUpperCase()[0]}
                                </Avatar>
                              )}

                              <div className="post-details ">
                                <h4 className="large-text mx-2">
                                  {approve.Student.first_name +
                                    " " +
                                    approve.Student.last_name}
                                </h4>
                                <p className="small-text mx-2">
                                  {t("Requested On")}-
                                  {moment(approve.requested_on).format(
                                    Util.getMomentDateTimeFormat(
                                      school.date_format
                                    )
                                  )}
                                </p>
                              </div>
                              <div className="dots-button">
                                <TagButton
                                  size={"100px"}
                                  title={"Approved"}
                                  buttonStyle={greenTagStyle}
                                  icon="fa fa-circle"
                                  iconSize="6px"
                                />
                              </div>
                            </div>
                            <div className="alert alert-secondary d-flex justify-content-between align-items-center">
                              <div
                                style={{ display: "grid", textAlign: "center" }}
                              >
                                <h6>{formattedDate.split(" ")[0]}</h6>
                                <h3>{formattedDate.split(" ")[1]}</h3>
                                <span style={{ color: "gray" }}>
                                  {formattedDate.split(" ")[2]}
                                </span>
                              </div>
                              <i
                                className="fa fa-long-arrow-right "
                                aria-hidden="true"
                              ></i>

                              <div
                                style={{ display: "grid", textAlign: "center" }}
                              >
                                <h6>{formattedEndDate.split(" ")[0]}</h6>
                                <h3>{formattedEndDate.split(" ")[1]}</h3>
                                <span style={{ color: "gray" }}>
                                  {formattedEndDate.split(" ")[2]}
                                </span>
                              </div>
                            </div>
                            <div className="card-content-details">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <div className="large-text">
                                    {t("Leave Type")}
                                  </div>
                                  <p>{approve.Request_type  !== null ? approve.Request_type.type : "-"}</p>
                                </div>

                                <div>
                                  <div className="large-text">
                                    {t("Request By")}
                                  </div>
                                  <p>
                                    {approve.Requested_By.firstName +
                                      " " +
                                      approve.Requested_By.lastName}
                                  </p>
                                </div>
                              </div>

                              <div>
                                <div>
                                  <div className="large-text">{t("Note")}</div>
                                  <p
                                    className="wrapTwoLine"
                                    // style={{
                                    //   overflow: "hidden",
                                    //   display: "-webkit-box",
                                    //   WebkitLineClamp: 2,
                                    //   WebkitBoxOrient: "vertical",
                                    //   textOverflow: "ellipsis",
                                    //   whiteSpace: "normal", // This allows the text to wrap if it's less than two lines
                                    //   maxHeight: "3.6em", // You can adjust this value to control the height for two lines
                                    // }}
                                  >
                                    {Util.isValidData(approve.note)
                                      ? approve.note
                                      : t("no note added..")}
                                  </p>
                                </div>
                              </div>

                              <div className="large-text">
                                <h6>
                                  {t("Approved By")}{" "}
                                  {approve.Approved_By.firstName +
                                    " " +
                                    approve.Approved_By.lastName}{" "}
                                  {t("On")}{" "}
                                  {moment(approve.approved_on).format(
                                    Util.getMomentDateTimeFormat(
                                      school.date_format
                                    )
                                  ) +
                                    ", " +
                                    moment(approve.approved_on).format(
                                      "h:mm A"
                                    )}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="row p-5">
                  <div className="col-lg-4 offset-lg-4">
                    <p className="text-center text-muted">
                      {t("No Request found")}:-(
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
export default ToastHOC(LeaveApproval);
