import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import ApiCalling from "../../../network/ApiCalling";
import ToastHOC from "../../HOC/ToastHOC";
import Util from "../../../Util";
import { useHistory } from "react-router-dom";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import BreadCrumbComponent from "../../../components/BreadCrumbComponent";
import Close from "../../../assets/Icon/close.png";
import Loader from "../../../components/common/Loader";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function Teacher(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { setIsToast } = props;

  const roomDetails = props.location.roomData;
  const [showPopup, setShowPopup] = useState(false);
  const [showSubjectPopup, setShowSubjectPopup] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [classTeacher, setClassTeacher] = useState(false);
  const [coTeacher, setCoTeacher] = useState(false);
  const [staffId, setStaffId] = useState(null);
  const [staffIdError, setStaffIdError] = useState("");
  const [teacherData, setTeacherData] = useState([]);
  const [subjectId, setSubjectId] = useState(0);
  const [subjectIdError, setSubjectIdError] = useState("");
  const [subjectData, setSubjectData] = useState([]);
  const [subjectTeacherId, setSubjectTeacherId] = useState(0);
  const [subjectCoTeacherId, setSubjectCoTeacherId] = useState(0);
  const [subjectTeacherIdError, setSubjectTeacherIdError] = useState("");

  const [subjectDetails, setSubjectDetails] = useState([]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [subjectDeletePopup, setSubjectDeletePopup] = useState(false);
  const [subjectDeleteId, setSubjectDeleteId] = useState();
  const [editSubject, setEditSubject] = useState(null);
  const activeAcademicYear = useSelector(
    (state) => state?.academicReducer?.value?.title
  );

  const handleStaffChange = (e) => {
    setStaffId(e.target.value);
    setStaffIdError("");
  };
  const handleSubjectTeacherId = (e) => {
    const options = e.target.options;
    let selectedValues = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }

    setSubjectTeacherId(selectedValues);
    setSubjectTeacherIdError("");
  };
  const handleSubjectCoTeacherId = (e) => {
    const options = e.target.options;
    let selectedValues = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setSubjectCoTeacherId(selectedValues);
  };
  const handleSubjectChange = (e) => {
    setSubjectId(e.target.value);
    setSubjectIdError("");
  };
  const handleSaveSubject = (e) => {
    e.preventDefault();
    let error = false;
    setSubjectIdError("");
    setSubjectTeacherIdError("");
    if (subjectId === 0) {
      setSubjectIdError(t("Subject is required"));
      error = true;
    }
    if (subjectTeacherId == 0) {
      setSubjectTeacherIdError(t("Teacher is required"));
      error = true;
    }

    if (!error) {
      if (editSubject !== null) {
        const data = {
          id: editSubject.id,
          room_id: roomDetails && roomDetails?.room.id,
          user_ids: subjectTeacherId.join(","),
          co_user_ids: subjectCoTeacherId ? subjectCoTeacherId.join(",") : "",
          subject_id: subjectId,
        };
        ApiCalling.apiCallBodyDataPost(
          "class-subject/edit-subject-teacher",
          data
        )
          .then((res) => {
            setIsLoading(false);
            if (res.data.status === true) {
              setShowSubjectPopup(false);
              teacherList();
              subjectDetail();
              setIsToast(res.data.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      } else {
        const data = {
          room_id: roomDetails && roomDetails?.room.id,
          user_ids: subjectTeacherId.join(","),
          co_user_ids: subjectCoTeacherId ? subjectCoTeacherId.join(",") : "",
          subject_id: subjectId,
        };
        ApiCalling.apiCallBodyDataPost(
          "class-subject/add-subject-teacher",
          data
        )
          .then((res) => {
            setIsLoading(false);
            if (res.data.status === true) {
              setShowSubjectPopup(false);
              teacherList();
              subjectDetail();
              setSubjectId(0);
              setIsToast(res.data.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      }
    }
  };

  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`teacher/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setShowDeleteConfirmation(false);
        setIsToast(t("Data Deleted successfully!"));
        teacherList();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const handleSubjectDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`class-subject/${subjectDeleteId}`)
      .then((res) => {
        setIsLoading(false);
        setSubjectDeletePopup(false);
        setIsToast(t("Data Deleted successfully!"));
        subjectDetail();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const handleSave = (e) => {
    setStaffIdError("");
    e.preventDefault();
    let error = false;
    if (staffId === null) {
      setStaffIdError(t("Teacher is required"));
      error = true;
    }
    if (!error) {
      const data = {
        room_id: roomDetails && roomDetails?.room.id,
        user_id: staffId,
        is_co_teacher: coTeacher,
      };
      ApiCalling.apiCallBodyDataPost("teacher/add-class-teacher", data)
        .then((res) => {
          setIsLoading(false);
          if (res.data.status === true) {
            setShowPopup(false);
            teacherList();
            setStaffId(null);
          }
          setIsToast(res.data.message);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  const staffList = async () => {
    const data = {
      page_size: 1000,
      page_number: 1,
      role_id: 0,
      str_search: "",
    };
    ApiCalling.apiCallBodyDataPost("user/list", data)
      .then((res) => {
        setStaffData(res.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const subjectList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("subject/list")
      .then((res) => {
        setIsLoading(false);
        setSubjectData(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const subjectDetail = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(
      `class-subject/list-by-room/${roomDetails?.room.id}`
    )
      .then((res) => {
        setIsLoading(false);
        setSubjectDetails(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error");
      });
  };

  const teacherList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(
      `teacher/list-by-room/${roomDetails?.room.id}`
    )
      .then((res) => {
        // console.log("teacher data", res.data);

        setIsLoading(false);
        setTeacherData(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    teacherList();
    subjectDetail();
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
    </a>
  ));

  useEffect(() => {
    if (!roomDetails) {
      history.push("/section");
    }
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="container-80 mt-3">
          <BreadCrumbComponent
            Breadcrumb={[
              {
                name: "My School",
                navigate: () => {
                  history.push("/section");
                },
              },
              {
                name: `${t("Section")}`,
                navigate: () => {
                  history.push("/section");
                },
              },

              {
                name: `${t(`Subject and Teacher `)}`,
              },
            ]}
          />
          <div className="row mb-4 mt-2 ">
            <div className="col">
              <h2 className="page-head">
                {t(
                  `Subject and Teacher of ${roomDetails?.g?.title}-${roomDetails?.room?.name} and  Academic year ${activeAcademicYear}`
                )}
              </h2>
            </div>
          </div>
          <div>
            {!isLoading ? (
              <>
                <div className="row mt-3">
                  <div className="col-lg-12 col-md-12">
                    <div className="school-details my-2  p-4">
                      <div className="drawer-header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="medium-large-text font-color-primary ">
                            {t(`Class Teacher`)}
                          </div>
                          <button
                            className="link-button"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowPopup(true);
                              setClassTeacher(true);
                              setCoTeacher(false);
                            }}
                          >
                            <i className="fas fa-plus mr-1"></i> {t("Add")}
                          </button>
                        </div>
                      </div>

                      <div
                        className="table-wrapper table-div"
                        style={{ overflowX: "auto" }}
                      >
                        {teacherData.length > 0 ? (
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="pl-4">{t("Name")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {teacherData &&
                                teacherData
                                  .filter(
                                    (option) =>
                                      option.class_subject_id === null &&
                                      option.is_co_teacher === false
                                  )
                                  .map((teach, index) => {
                                    return (
                                      <tr key={teach.User.firstName}>
                                        <td>
                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex   gap-3">
                                              <div className="col-1 text-end">
                                                {Util.getUserAvatar(teach.User)}
                                              </div>
                                              <div className="col-8">
                                                <div className="">
                                                  {teach.User.firstName}{" "}
                                                  {teach.User.lastName}{" "}
                                                  {!teach.User
                                                    .email_verified && (
                                                    <span className="extra-small-text text-danger">
                                                      {t("Not Registered Yet")}
                                                    </span>
                                                  )}
                                                  <div className="extra-small-text">
                                                    {teach.User.email}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                position: "relative",
                                                right: 20,
                                              }}
                                            >
                                              <Dropdown>
                                                <Dropdown.Toggle
                                                  variant="none"
                                                  as={CustomToggle}
                                                  id="dropdown-basic"
                                                ></Dropdown.Toggle>

                                                <Dropdown.Menu className="dropdown-menu-right">
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      setShowDeleteConfirmation(
                                                        true
                                                      );
                                                      setDeleteId(teach.id);
                                                    }}
                                                  >
                                                    <i className="fa fa-trash-o text-danger"></i>{" "}
                                                    {t("Delete")}
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                            </tbody>
                          </table>
                        ) : (
                          <>
                            <table className="table">
                              <tfoot>
                                <tr>
                                  <td colSpan={8}>
                                    <p className="text-center px-3 mb-0 py-3">
                                      {t("Class Teacher not found for")}{" "}
                                      {roomDetails?.g?.title}-
                                      {roomDetails?.room?.name} ,{" "}
                                      {`Click the 'Add' button to create one.`}
                                    </p>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="school-details my-2  p-4">
                      <div className="drawer-header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="medium-large-text font-color-primary ">
                            {t(`Co Teacher `)}{" "}
                          </div>
                          <button
                            className="link-button"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowPopup(true);
                              setClassTeacher(false);
                              setCoTeacher(true);
                            }}
                          >
                            <i className="fas fa-plus mr-1"></i> {t("Add")}
                          </button>
                        </div>
                      </div>

                      <div
                        className="table-wrapper table-div"
                        // style={{ overflowX: "auto" }}
                      >
                        {teacherData.length > 0 ? (
                          <>
                            {teacherData?.filter(
                              (option) =>
                                option.class_subject_id === null &&
                                option.is_co_teacher === true
                            ).length > 0 ? (
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th className="pl-4">{t("Name")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {teacherData
                                    .filter(
                                      (option) =>
                                        option.class_subject_id === null &&
                                        option.is_co_teacher === true
                                    )
                                    .map((teach, index) => {
                                      return (
                                        <tr
                                          className="text-center"
                                          key={teach.User.firstName}
                                        >
                                          <td>
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div className="d-flex gap-3 ">
                                                <div className="col-1 text-end">
                                                  {Util.getUserAvatar(
                                                    teach.User
                                                  )}
                                                </div>
                                                <div className="col-10 text-start">
                                                  <div className="pl-2">
                                                    {teach?.User?.firstName}{" "}
                                                    {teach?.User?.lastName}{" "}
                                                    {!teach?.User
                                                      ?.email_verified && (
                                                      <span className="extra-small-text text-danger">
                                                        {t(
                                                          "Not Registered Yet"
                                                        )}
                                                      </span>
                                                    )}
                                                    <div className="extra-small-text">
                                                      {teach?.User.email}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  position: "relative",
                                                  right: 20,
                                                }}
                                              >
                                                <Dropdown>
                                                  <Dropdown.Toggle
                                                    variant="none"
                                                    as={CustomToggle}
                                                    id="dropdown-basic"
                                                  ></Dropdown.Toggle>

                                                  <Dropdown.Menu className="dropdown-menu-right">
                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        setShowDeleteConfirmation(
                                                          true
                                                        );
                                                        setDeleteId(teach.id);
                                                      }}
                                                    >
                                                      <i className="fa fa-trash-o text-danger"></i>{" "}
                                                      {t("Delete")}
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            ) : (
                              <table className="table">
                                <tfoot>
                                  <tr>
                                    <td colSpan={8}>
                                      <p className="text-center px-3 mb-0 py-3">
                                        {t("Co Teacher not found for")}{" "}
                                        {roomDetails?.g.title}-
                                        {roomDetails?.room.name},{" "}
                                        {`Click the 'Add' button to create one.`}
                                      </p>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            )}
                          </>
                        ) : (
                          <table className="table">
                            <tfoot>
                              <tr>
                                <td colSpan={8}>
                                  <p className="text-center px-3 mb-0 py-3">
                                    {t("Co Teacher not found for")}{" "}
                                    {roomDetails?.g.title}-
                                    {roomDetails?.room.name},{" "}
                                    {`Click the 'Add' button to create one.`}
                                  </p>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-12 col-md-12">
                    <div className="school-details my-2  p-4">
                      <div className="drawer-header ">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="medium-large-text font-color-primary ">
                            {t(`Subjects & Teachers`)}{" "}
                          </div>
                          <button
                            className="link-button"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowSubjectPopup(true);
                            }}
                          >
                            <i className="fas fa-plus mr-1"></i> {t("Add")}
                          </button>
                        </div>
                      </div>

                      <div
                        className="table-wrapper table-div"
                        style={{ overflowX: "auto" }}
                      >
                        {subjectDetails.length > 0 ? (
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">{t("Subject")}</th>
                                <th className="text-center">{t("Teacher")}</th>
                                <th className="text-center">
                                  {t("Co-Teacher")}
                                </th>

                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {subjectDetails.map((sub, index) => {
                                return (
                                  <>
                                    <tr
                                      key={index}
                                      role="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setShowSubjectPopup(true);
                                        setEditSubject(sub);
                                      }}
                                    >
                                      <td className="text-center">
                                        {index + 1}
                                      </td>
                                      <td className="text-center">
                                        <a href="#">{sub?.Subject?.name}</a>
                                      </td>
                                      <td className="text-center">
                                        {teacherData
                                          .filter(
                                            (val) =>
                                              val.class_subject_id === sub.id &&
                                              val.is_co_teacher === false
                                          )
                                          .map((data, index) => {
                                            return data.User.firstName;
                                          })
                                          .join(", ")}
                                      </td>
                                      <td className="text-center">
                                        {teacherData
                                          .filter(
                                            (val) =>
                                              val.class_subject_id === sub.id &&
                                              val.is_co_teacher === true
                                          )
                                          .map((data, index) => {
                                            return data.User.firstName;
                                          })
                                          .join(", ")}
                                      </td>
                                      <td>
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant="none"
                                            as={CustomToggle}
                                            id="dropdown-basic"
                                          ></Dropdown.Toggle>

                                          <Dropdown.Menu className="dropdown-menu-right">
                                            <Dropdown.Item
                                              onClick={() => {
                                                setSubjectDeletePopup(true);
                                                setSubjectDeleteId(sub.id);
                                              }}
                                            >
                                              <i className="fa fa-trash-o text-danger"></i>{" "}
                                              {t("Delete")}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <table className="table">
                            <tfoot>
                              <tr>
                                <td colSpan={8}>
                                  <p className="text-center px-3 mb-0 py-3">
                                    {t("Subjects & Teachers not found for")}{" "}
                                    {roomDetails?.g.title}-
                                    {roomDetails?.room.name},{" "}
                                    {`Click the 'Add' button to create one.`}
                                  </p>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>
          <Modal
            show={showSubjectPopup}
            onHide={() => {
              setShowSubjectPopup(false);
              setSubjectId(0);
              setSubjectIdError("");
              setSubjectTeacherId(0);
              setSubjectCoTeacherId(0);
              setSubjectTeacherIdError("");
              setEditSubject(null);
            }}
            size="lg"
            animation={true}
            centered={true}
            dialogClassName="modal-view"
            onShow={() => {
              if (editSubject !== null) {
                setSubjectId(editSubject.Subject.id);

                {
                  const selectedValues = teacherData
                    .filter(
                      (val) =>
                        val.class_subject_id === editSubject.id &&
                        val.is_co_teacher === false
                    )
                    .map((data) => data.User.id);
                  setSubjectTeacherId(selectedValues);
                }
                {
                  const selectedValues = teacherData
                    .filter(
                      (val) =>
                        val.class_subject_id === editSubject.id &&
                        val.is_co_teacher === true
                    )
                    .map((data) => data.User.id);
                  setSubjectCoTeacherId(selectedValues);
                }
              } else {
                setEditSubject(null);
                setSubjectId(0);
                setSubjectIdError("");
                setSubjectTeacherId(0);
                setSubjectCoTeacherId(0);
                setSubjectTeacherIdError("");
              }
              subjectList();
              staffList();
            }}
          >
            <div className="d-flex justify-content-between align-items center p-4 border-bottom">
              <h6>{t("Subject")}</h6>

              <button
                className="hidden-btn"
                onClick={() => {
                  setShowSubjectPopup(false);
                  setSubjectId(0);
                  setSubjectIdError("");
                  setSubjectTeacherId(0);
                  setSubjectCoTeacherId(0);
                  setSubjectTeacherIdError("");
                  setEditSubject(null);
                }}
              >
                <img src={Close} className="svg-icon" height={20} width={20} />
              </button>
            </div>
            <Modal.Body>
              {!isLoading ? (
                <>
                  <div className="input-filed text-start mb-4">
                    <span className="basic-text required">{t("Subject")}</span>
                    <select
                      className={`select-dropdown form-select custom-input ${
                        subjectIdError.length > 0 && "input-error"
                      }`}
                      value={subjectId || "0"}
                      onChange={handleSubjectChange}
                    >
                      <option value="0" disabled>
                        {t("Select Subject")}
                      </option>
                      {subjectData
                        .filter((s) => {
                          if (editSubject === null) {
                            return !subjectDetails.some(
                              (sub) => sub.Subject.id === s.id
                            );
                          } else {
                            return (
                              !subjectDetails.some(
                                (sub) => sub.Subject.id === s.id
                              ) || s.id === editSubject.Subject.id
                            );
                          }
                        })
                        .map((sub) => {
                          return (
                            <option
                              key={sub.id}
                              value={sub.id}
                              selected={
                                editSubject !== null &&
                                editSubject.Subject.id === sub.id
                              }
                            >
                              {sub.name}
                            </option>
                          );
                        })}
                    </select>
                    {subjectIdError.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {subjectIdError}
                      </span>
                    )}
                  </div>

                  <div className="input filed text-start mb-4">
                    <span className="basic-text required">{t("Teacher")}</span>
                    <select
                      multiple
                      className={`form-select custom-input ${
                        subjectTeacherIdError.length > 0 && "input-error"
                      }`}
                      value={subjectTeacherId}
                      onChange={handleSubjectTeacherId}
                    >
                      {staffData.map((staff) => {
                        return (
                          <option key={staff.id} value={staff.id}>
                            {staff.firstName} {staff.lastName}
                          </option>
                        );
                      })}
                    </select>
                    {subjectTeacherIdError.length > 0 && (
                      <span className="text-danger invalid-error">
                        {" "}
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {subjectTeacherIdError}
                      </span>
                    )}
                  </div>
                  <div className="input filed text-start mb-3">
                    <span className="basic-text">{t("Co Teacher")}</span>
                    <select
                      multiple
                      className="form-select"
                      value={subjectCoTeacherId}
                      onChange={handleSubjectCoTeacherId}
                    >
                      {staffData.map((staff) => {
                        return (
                          <option key={staff.id} value={staff.id}>
                            {staff.firstName} {staff.lastName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </>
              ) : (
                <Loader />
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn custom-primary"
                onClick={handleSaveSubject}
              >
                {t("Save")}
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showPopup}
            onHide={() => {
              setShowPopup(false);
              setStaffId(null);
              setStaffIdError("");
            }}
            size="lg"
            animation={true}
            centered={true}
            dialogClassName="modal-view"
            onShow={() => staffList()}
          >
            <div className="d-flex justify-content-between align-items center p-4 border-bottom">
              <h6>
                {classTeacher ? t("Class Teacher") : t("Co-Teacher")} {t("for")}{" "}
                {roomDetails ? roomDetails?.room.name : ""}
              </h6>

              <button
                className="hidden-btn"
                onClick={() => {
                  setShowPopup(false);
                  setStaffId(null);
                  setStaffIdError("");
                }}
              >
                <img src={Close} className="svg-icon" height={20} width={20} />
              </button>
            </div>
            <Modal.Body>
              <div className="input-filed text-start">
                <span className="basic-text required">
                  {classTeacher ? t("Class Teacher") : t("Co-Teacher")}
                </span>
                <select
                  className={`select-dropdown form-select custom-input ${
                    staffIdError.length > 0 && " input-error"
                  }`}
                  value={staffId || ""}
                  onChange={handleStaffChange}
                >
                  <option value="" disabled>
                    {t("Select")}
                  </option>
                  {staffData
                    .filter((staff) => {
                      return !teacherData.some(
                        (teacher) => teacher.User.id === staff.id
                      );
                    })
                    .map((staff) => {
                      return (
                        <option key={staff.id} value={staff.id}>
                          {staff.firstName} {staff.lastName}({staff.email})
                        </option>
                      );
                    })}
                </select>
                {staffIdError.length > 0 && (
                  <span className="text-danger invalid-error">
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                    {staffIdError}
                  </span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn custom-primary" onClick={handleSave}>
                {t("Save")}
              </button>
            </Modal.Footer>
          </Modal>

          <ModalComponent
            show={showDeleteConfirmation}
            onHide={() => {
              setShowDeleteConfirmation(false);
            }}
            title={t("Delete")}
            subTitle={"Are you sure, to remove this teacher?"}
            size="sm"
            onCancel={t("Cancel")}
            onConfirm={t("Confirm")}
            onCancelClick={() => {
              setShowDeleteConfirmation(false);
            }}
            onConfirmClick={handleDelete}
          />
          <ModalComponent
            show={subjectDeletePopup}
            onHide={() => {
              setSubjectDeletePopup(false);
            }}
            title={t("Delete")}
            subTitle={"Are you sure, to remove this subject & teacher?"}
            size="sm"
            onCancel={t("Cancel")}
            onConfirm={t("Confirm")}
            onCancelClick={() => {
              setSubjectDeletePopup(false);
            }}
            onConfirmClick={handleSubjectDelete}
          />
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(Teacher);
