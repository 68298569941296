import { USER_UPDATE  } from "../actions/NotifyUserUpdateAction";

const initialState = {
  isUpdate: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_UPDATE:
      return {
        ...state,
        isUpdate: action.payload,
      };
   
    default:
      return state;
  }
};