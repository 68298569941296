import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";
import { Rings } from "react-loader-spinner";
import moment from "moment/moment";
import TagButton from "../TagButton";
import { greenTagStyle, orangeTagStyle, redTagStyle } from "../TagButtonStyle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ApiCalling from "../../../network/ApiCalling";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Select from "react-dropdown-select";
import { InvoiceStatus } from "../../../Enums";
import { imageStudentList, invoiceDownload } from "../../CommonImage";
import { Avatar } from "@mui/material";
import InvoiceView from "../../ParentsDashboard/Billing/InvoiceView";
import GeneratePayment from "../BillingPlanStudents/AddPayment";
import ToastHOC from "../../HOC/ToastHOC";
import Search from "../../../assets/Icon/Search.svg";
import Loader from "../../../components/common/Loader";
import { set } from "date-fns";

function PendingInvoices({ message, InvoiceCount }) {
  const { t } = useTranslation();
  const history = useHistory();
  const school = Util.getSelectedSchool();
  const schoolId = school?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [allInvoices, setAllInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [isVisibleInvoice, setIsVisibleInvoice] = useState(false);
  const [invoiceId, setInvoiceId] = useState(0);
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [studentId, setStudentId] = useState(0);
  const [studentName, setStudentName] = useState("");

  const searchClicked = () => {
    setCurrentPage(1);
    setSearchClick(search);
  };
  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setSearchClick(search);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  useEffect(() => {
    let isInvoiceMounted = true;
    const getAllInvoices = () => {
      setIsLoading(true);
      const data = {
        school_id: schoolId,
        page: currentPage,
        size: recordPerPage,
        student_name: searchClick,
        start_date: "",
        fee_type: "",
        end_date: "",
        status: "0,1",
      };
      ApiCalling.apiCallBodyDataPost("billing-invoice/school", data)
        .then((res) => {
          if (isInvoiceMounted) {
            if (res.data) {
              setIsLoading(false);
              setTotalPages(res.data.total_page);
              InvoiceCount(res.data.total);
              setAllInvoices(res.data.response);
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    getAllInvoices();
    return () => {
      isInvoiceMounted = false;
    };
  }, [currentPage, recordPerPage, searchClick]);

  const DownloadInvoice = (id) => {
    ApiCalling.apiCallBodyDataGet(`billing-invoice/download-mail/${id}`)
      .then((res) => {
        if (res.data.status == true) {
          setTimeout(() => {
            window.open(invoiceDownload + res.data.invoice, "_blank");
          }, 2000);
        } else {
          message(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const sendReminder = (id) => {
    ApiCalling.apiCallBodyDataGet(`billing-invoice/send-mail/${id}`)
      .then((res) => {
        if (res.data.status == true) {
          message(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div className="mt-4">
      <div className={"table-div "}>
        <div className="d-flex gap-20 align-items-center table-sort-bar">
          <div className="custom-search-wrap">
            <div className="input-group">
              <input
                className="form-control search"
                placeholder="Search Student"
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => EnterHandler(e)}
              />
              <div className="input-group-prepend">
                <div className="search-icon" onClick={searchClicked}>
                  <img src={Search} className="svg-icon" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="table-wrapper">
              {!isLoading ? (
                <table
                  className="table" 
                  style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
                >
                  <thead style={{ backgroundColor: "#e5e7eb" }}>
                    <tr className="text-nowrap">
                      <th>{t("Student Name")}</th>
                      <th>{t("Invoice")}</th>
                      <th>{t("Fee Type")}</th>
                      <th>{t("Amount")}</th>
                      <th>{t("Status")}</th>
                      <th>{t("Balance")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  {allInvoices.length > 0 ? (
                    <tbody>
                      {allInvoices.map((a, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                {a.Student.photo ? (
                                  <img
                                    className="rounded-circle "
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                    }}
                                    src={imageStudentList + a.Student.photo}
                                    alt=""
                                  />
                                ) : (
                                  <Avatar
                                    aria-label="recipe"
                                    className="avatar-font"
                                  >
                                    {a.Student.first_name.toUpperCase()[0] +
                                      a.Student.last_name.toUpperCase()[0]}
                                  </Avatar>
                                )}
                                <a className="ml-2">
                                  {a.Student.first_name} {a.Student.last_name}
                                </a>
                              </div>
                            </td>
                            <td>
                              {Util.invoiceIdFormat(a.id)}
                              <div className="small-text">
                                {a.last_payment_date !== null
                                  ? moment(a.last_payment_date).format(
                                      Util.getMomentDateTimeFormat(
                                        school.date_format
                                      )
                                    )
                                  : ""}
                              </div>
                            </td>
                            <td>
                              {a.types.length > 0 ? (
                                <p style={{ whiteSpace: "pre-line" }}>
                                  {Util.getListDisplay(
                                    a.types.map((f) => f.Fee_Type),
                                    2,
                                    "\n"
                                  )}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>{Util.amountFormat(a.total)}</td>
                            <td>
                              <TagButton
                                style={"btn btn-secondary"}
                                size={"150px"}
                                title={Util.getInvoiceStatus(a.status)}
                                buttonStyle={
                                  a.status == 0
                                    ? redTagStyle
                                    : a.status == 1
                                    ? orangeTagStyle
                                    : greenTagStyle
                                }
                                icon="fa fa-circle"
                                iconSize="6px"
                              />
                            </td>

                            <td>{Util.invoiceFormat(a.due)}</td>
                            <td>
                              <div className="dropdown">
                                <button
                                  className="btn btn-lg custom-primary "
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{
                                    color: "#ffffff",
                                    padding: "5px, 10px",
                                  }}
                                >
                                  {t("Action")}
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  {InvoiceStatus.PARTIAL_PAID == a.status ||
                                    (InvoiceStatus.UNPAID == a.status && (
                                      <>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            target="_blank"
                                            role="button"
                                            onClick={() => sendReminder(a.id)}
                                          >
                                            {t("Send Reminder")}
                                          </a>
                                        </li>
                                      </>
                                    ))}
                                  <li>
                                    <a
                                      className="dropdown-item"
                                       role="button"
                                      onClick={() => {
                                        setIsVisibleInvoice(true);
                                        setInvoiceId(a.id);
                                      }}
                                    >
                                      {t("View")}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                       role="button"
                                      onClick={() => {
                                        DownloadInvoice(a.id);
                                      }}
                                    >
                                      {t("Download")}
                                    </a>
                                  </li>
                                  {InvoiceStatus.PARTIAL_PAID == a.status ||
                                    (InvoiceStatus.UNPAID == a.status && (
                                      <>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            role="button"
                                            onClick={() => {
                                              setInvoiceData(a);
                                              setIsVisiblePayment(true);
                                              setStudentId(a.Student.id);
                                              setStudentName(
                                                a.Student.first_name +
                                                  " " +
                                                  a.Student.last_name
                                              );
                                            }}
                                          >
                                            {t("Log a payment")}
                                          </a>
                                        </li>
                                      </>
                                    ))}
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tfoot>
                      <tr>
                        <td colSpan={8}>{t("Not Found!")}</td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              ) : (
                <Loader />
              )}
              <InvoiceView
                show={isVisibleInvoice}
                onClose={() => {
                  setIsVisibleInvoice(false);
                }}
                invoiceId={invoiceId}
              />
              <GeneratePayment
                show={isVisiblePayment}
                onClose={() => {
                  setIsVisiblePayment(false);
                }}
                onSave={(msg) => {
                  if (msg) {
                    message(msg);
                  }
                  setIsVisiblePayment(false);
                  setCurrentPage(1);
                }}
                studentIds={studentId}
                studentName={studentName}
                invoiceDetails={invoiceData}
              />
            </div>
            {totalPages > 0 && (
              <div className="row m-0 table-footer border-top mb-2">
                <div className="d-flex justify-content-end mt-2">
                  <Stack spacing={2} direction="row" alignItems="center">
                    <div
                      className={`link-button ${
                        currentPage == 1 ? "disabled" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handlePreviousClick();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <KeyboardArrowLeftIcon />
                      <span
                        className="basic-text"
                        style={{ fontWeight: "600" }}
                      >
                        {t("Previous")}
                      </span>
                    </div>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, page) => {
                        setCurrentPage(page);
                      }}
                      size="small"
                      hideNextButton
                      hidePrevButton
                    />
                    <div
                      className={`link-button ${
                        currentPage == totalPages ? "disabled" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleNextClick();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="basic-text"
                        style={{ fontWeight: "600" }}
                      >
                        {t("Next")}
                      </span>
                      <KeyboardArrowRightIcon />
                    </div>
                  </Stack>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(PendingInvoices);
