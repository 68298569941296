import React from "react";
import { Rings } from "react-loader-spinner";
import Lottie from "react-lottie";
import * as animationData from "../../assets/File/Animation - 1703568488870.json";

function Loader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "70vh", 
  };
  return (
    // <div style={containerStyle}>
      <Lottie options={defaultOptions} height={100} width={100} />
    // </div>
  );
}

export default Loader;
