import React, { useEffect, useState } from "react";
import ApiCalling from "../../../network/ApiCalling";
import successImg from "../../../assets/images/download (12).jpg"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";

export default function Success(props) {
  const { t } = useTranslation();
  const student = Util.getSelectedStudent();
  const schoolId = student.School.id
  const history = useHistory();
  const params = new URLSearchParams(props.location.search);
  const sessionId = params.get("session_id");
  const getSessionId = () => {
    const data = {
      session_id: sessionId,
    };
    ApiCalling.apiCallBodyDataPost("stripe/handle-success", data,schoolId)
      .then((res) => {
       // console.log("success");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (sessionId) {
      getSessionId();
    }
  }, [sessionId]);
  return (
    <div>
      <div className="text-center">
        <img className="img-responsive" src={successImg}></img>
        <h1> {t("Payment Successfully Done!")}</h1>
        <p>{t("Thank You ! We received your payment . Your Invoice is Settled.")} </p>
        <button className="btn custom-primary" onClick={()=>{
          history.push("/transaction")
        }}>{t("Back")}</button>
      </div>
    </div>
  );
}
