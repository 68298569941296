import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import ApiCalling from "../../network/ApiCalling";

import AttendenceParameterList from "./AttendenceParameterList";
import Util from "../../Util";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Box, SwipeableDrawer } from "@material-ui/core";
import Close from "../../assets/Icon/close.svg";
import Select from "react-dropdown-select";
import Setting from "../../assets/Icon/setting.svg";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import Loader from "../common/Loader";
import ToastHOC from "../../screens/HOC/ToastHOC";
import AcadamicList from "./AcadamicYearList";
import AcadamicYearList from "./AcadamicYearList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ModalComponent from "../../screens/Dashbord/ModalComponent";

function SchoolSetting(props) {
  console.log("props", props);

  const history = useHistory();
  const { setIsToast, setIsSubToast } = props;
  const isVisible = props.isVisible;

  const isClose = props.isClose;
  const notification = props.notification;
  const getLanguage = Util.getSelectedLanguage();
  const { t } = useTranslation();
  const [visibleAcadamicYearPopup, setVisibleAcadamicYearPopup] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const [languageName, setLanguageName] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [academicData, setAcadamicData] = useState(null);
  const [indexValue, setIndexValue] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const handleDateChange = (event) => {
    const value = event.target.value;
    setSelectedDate(value);
  };

  const handleTimeChange = (event) => {
    const value = event.target.value;
    setSelectedTime(value);
  };

  const today = new Date();
  const handleUpdate = () => {
    setIsLoading(true);
    const data = {
      date_format: selectedDate,
      time_format: selectedTime,
      lng: languageName,
      currency: currencySymbol,
    };
    ApiCalling.apiCallBodyDataPost("school/update-school-settings", data)
      .then((res) => {
        if (res.data.status == true) {
          setIsLoading(false);
          Util.saveSelectedSchool(res.data.school);
          isClose();
          notification(res.data.message);
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSchoolDetails = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("school")
      .then((res) => {
        setIsLoading(false);
        Util.saveSelectedSchool(res.data);
        setSelectedDate(res.data.date_format);
        setSelectedTime(res.data.time_format);
        setLanguageName(res.data.lng ? res.data.lng : "en");
        setCurrencySymbol(res.data.currency);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const languagelist = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("language")
      .then((res) => {
        setIsLoading(false);
        setLanguageList(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const academicList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("academic-year/getActiveOne")
      .then((res) => {
        setIsLoading(false);
        // console.log("List Academic Year Name", res.data);
        if (res.data) {
          setAcadamicData(res.data);
        } else {
          setAcadamicData(null);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  // new design

  const DateFormateList = [
    { value: "dd/MM/yyyy", name: "DD/MM/yyyy" },
    { value: "MM/dd/yyyy", name: "MM/DD/yyyy" },
    { value: "yyyy-MM-dd", name: "yyyy-MM-DD" },
    { value: "yyyy, MMMM, dd", name: "yyyy, MMMM, DD" },
    { value: "MMMM d, yyyy", name: "MMMM D, yyyy" },
  ];
  const TimeFormateList = [
    { value: "HH:mm", name: "HH:mm" },
    { value: "hh:mm a", name: "hh:mm a" },
  ];

  const getCurrencyList = () => {
    ApiCalling.apiCallBodyDataGet("currency/list")
      .then((res) => {
        setCurrencyList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCurrencyList();
    getSchoolDetails();
    languagelist();
    academicList();
    // attendenceList();
  }, []);

  const handleCloseDrawer = () => {
    isClose();
  };
  return (
    <>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={isVisible}
        onClose={() => {
          isClose();
          setIndexValue(0);
        }}
        onOpen={() => {}}
        disableEnforceFocus={true}
      >
        <div
          className="box-area"
          style={{
            position: "relative",
            width:
              (indexValue === 0 && "656px") || (indexValue === 1 && "800px"),
            height: "calc(100vh - 70px)",
            padding: 25,
          }}
        >
          <Box role="presentation">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between">
                <div className={`  drawer-header `}>
                  {indexValue === 1 && (
                    <IconButton
                      aria-label="close"
                      size="small"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        setIndexValue(0);
                      }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  )}

                  {t(
                    `${
                      indexValue === 0
                        ? "School Settings"
                        : indexValue === 1
                        ? "Academic Year List"
                        : ""
                    }`
                  )}
                </div>
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={() => {
                    isClose();
                    setIndexValue(0);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="body mt-3">
                <div className="drawer-sub-header">
                  {t(`${indexValue === 0 ? "School Information" : ""}`)}
                </div>

                {!isLoading ? (
                  <>
                    {indexValue === 0 && (
                      <div className="row mt-3">
                        <span className="basic-text">
                          {t("Current Academic Year / Session")}
                        </span>
                        <div className="col-lg mb-3">
                          <div
                            className="form-control custom-input p-2"
                            style={{ position: "relative" }}
                            tabIndex={0}
                          >
                            {academicData == null ? (
                              <span className="text-danger">
                                {t("No Active Session Found")}
                              </span>
                            ) : (
                              <span>{academicData.title}</span>
                            )}
                          </div>
                          <img
                            src={Setting}
                            onClick={() => {
                              setIndexValue(1);
                            }}
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>

                        <div className="input-filed mb-3">
                          <span className="basic-text mb-1 ">
                            {t("Language")}
                          </span>
                          <select
                            className="select-dropdown form-select custom-input"
                            name="Language"
                            onChange={(e) => setLanguageName(e.target.value)}
                            value={languageName}
                          >
                            <option value="" disabled>
                              {t("Select Language")}
                            </option>
                            {languageList.map((lang, index) => {
                              return (
                                <option key={index} value={lang.lng}>
                                  {lang.language}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="input-filed mb-3 ">
                          <span className="basic-text ">{t("Currency")}</span>

                          <select
                            className="select-dropdown form-select custom-input "
                            name="Language"
                            value={currencySymbol}
                            onChange={(e) => {
                              setCurrencySymbol(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              {t("Select ")}
                            </option>
                            {currencyList.map((c, index) => {
                              return (
                                <option key={index} value={c.symbol}>
                                  {c.currency}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="input-filed mb-3 ">
                          <span className="basic-text ">
                            {t("Date Format")}
                          </span>

                          <select
                            className="select-dropdown form-select custom-input "
                            value={selectedDate}
                            onChange={handleDateChange}
                          >
                            <option value="" disabled>
                              {t("Select Date")}
                            </option>
                            {DateFormateList.map((d, index) => {
                              return (
                                <option key={index} value={d.value}>
                                  {moment(today).format(d.name)}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="input-filed">
                          <span className="basic-text ">
                            {t("Time Format")}
                          </span>
                          <div className="row">
                            {TimeFormateList.map((t, index) => {
                              return (
                                <div className="col-6" key={index}>
                                  <div
                                    className="fancy-radio custom-radio-btn p-2"
                                    tabIndex={0}
                                  >
                                    <label
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "normal",
                                        margin: "12px",
                                      }}
                                    >
                                      <input
                                        name="gender"
                                        type="radio"
                                        onChange={handleTimeChange}
                                        value={t.value}
                                        checked={selectedTime === t.value}
                                      />
                                      <span>
                                        <i
                                          className={` ${
                                            selectedTime
                                              ? "radio-circle radio-border"
                                              : ""
                                          }`}
                                        ></i>
                                        {moment(today).format(t.name)}
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                    {indexValue === 1 && (
                      <>
                        <AcadamicYearList
                          handleCloseDrawer={handleCloseDrawer}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <Loader />
                )}

                {indexValue === 0 && (
                  <div
                    className="col-12 text-right mt-2  "
                    style={{
                      position: "absolute",
                      paddingBottom: "10px",
                      left: 0,
                      right: 0,
                      transform: "translateY(50px)",
                    }}
                  >
                    <button
                      className="secondary-md-btn btn mr-2"
                      onClick={isClose}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="primary-lg-btn btn"
                      onClick={handleUpdate}
                    >
                      {t("Save School")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
    </>
  );
}
export default ToastHOC(SchoolSetting);
