import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import ApiCalling from "../../../network/ApiCalling";
import PhoneInput from "react-phone-number-input";
import Loader from "../../../components/common/Loader";
import Invalid from "../../../assets/Icon/invalid.svg";

const DoctorDetailsCard = ({
  data,
  studentId,
  onSave,
  doctorDetailsEdit,
  onCancel,
  isStudentUpdate
}) => {
  const { t } = useTranslation();
  const [doctorName, setDoctorName] = useState("");
  const [phone, setPhone] = useState("");
  const [doctorMobile, setDoctorMobile] = useState("");
  const [stateId, setStateId] = useState(0);
  const [country, setCountry] = useState([]);
  const [getStateData, setStateData] = useState([]);
  const [doctorClinic, setDoctorClinic] = useState([]);
  const [cityId, setCityId] = useState(0);
  const [city, setCity] = useState([]);
  const [addressLine1, setAddressLine1] = useState("");
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [countryId, setCountryId] = useState(0);
  const [addressLine2, setAddressLine2] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [zipcodeErr, setzipcodeErr] = useState("");

  const [defaultCountry, setDefaultCountry] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data !== null) {
      setDoctorName(data?.doctor_name);
      setPhone(data?.doctor_contact);
      setDoctorMobile(data?.doctor_mobile);
      setDoctorClinic(data?.doctor_clinic);
      setAddressLine1(data?.doctor_address1);
      setAddressLine2(data?.doctor_address2);
      setZipcode(data?.doctor_zipcode);
      setSelectedCountryName(data?.Doctor_Country?.name);
      setSelectedCityName(data?.Doctor_City?.name);
      setSelectedStateName(data?.Doctor_State?.name);
      setCountryId(
        Util.isValidData(data?.doctor_country_id) ? data?.doctor_country_id : 0
      );
      setStateId(
        Util.isValidData(data?.doctor_state_id) ? data?.doctor_state_id : 0
      );
      setCityId(
        Util.isValidData(data?.doctor_city_id) ? data?.doctor_city_id : 0
      );
    }
  }, [data]);

  const countryList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet("country").then((res) => {
      setIsCountryLoading(false);
      setCountry(res?.data);
    });
  };
  const stateList = async (id) => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${countryId}`).then(
      (res) => {
        setIsCountryLoading(false);
        setStateData(res.data);
      }
    );
  };
  const cityList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res.data);
    });
  };
  useEffect(() => {
    countryList();
  }, []);
  useEffect(() => {
    if (countryId) {
      stateList();
    }
  }, [countryId]);
  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);
  const handleDoctorDetailEdit = (e) => {
    e.preventDefault();
    let error = false;
    setzipcodeErr("");
    if (zipcode && Util.Zip_regex.test(zipcode) === false) {
      setzipcodeErr(t("Please enter valid zip-code(i.e: 12345 or 12345-2563)"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const data = {
        id: studentId,
        doctor_name: doctorName,
        doctor_contact: phone,
        doctor_mobile: doctorMobile,
        doctor_clinic: doctorClinic,
        doctor_address1: addressLine1,
        doctor_address2: addressLine2,
        doctor_zipcode: zipcode,
        ...(countryId !== 0 && { doctor_country_id: countryId }),
        ...(stateId !== 0 && { doctor_state_id: stateId }),
        ...(cityId !== 0 && { doctor_city_id: cityId }),
      };
      ApiCalling.apiCallBodyDataPost("student/dynamic-update", data)
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            onSave(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);
  return (
    <>
      {!isLoading ? (
        <div className="row text-start mt-2">
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Name")}</span>
              {!doctorDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(doctorName) ? doctorName : "-"}
                </div>
              ) : (
                <input
                  className="form-control custom-input"
                  value={doctorName}
                  onChange={(e) => {
                    setDoctorName(e.target.value);
                    isStudentUpdate();
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Phone")}</span>
              {!doctorDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(phone) ? phone : "-"}
                </div>
              ) : (
                <div className="form-control custom-input" tabIndex={0}>
                  <PhoneInput
                  tabIndex="-1"
                    international
                    defaultCountry={defaultCountry}
                    value={phone}
                    onChange={(value) => {
                      setPhone(value);
                      isStudentUpdate();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">
                {t("Mobile Number")}
              </span>
              {!doctorDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(doctorMobile) ? doctorMobile : "-"}
                </div>
              ) : (
                <div className="form-control custom-input" tabIndex={0}>
                  <PhoneInput
                  tabIndex="-1"
                    international
                    defaultCountry={defaultCountry}
                    value={doctorMobile}
                    onChange={(value) => {
                      setDoctorMobile(value);
                      isStudentUpdate();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">
                {t("Clinic / Hospital")}
              </span>
              {!doctorDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(doctorClinic) ? doctorClinic : "-"}
                </div>
              ) : (
                <input
                  className="form-control custom-input"
                  value={doctorClinic}
                  onChange={(e) => {
                    setDoctorClinic(e.target.value);
                    isStudentUpdate();
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Address 1")}</span>
              {!doctorDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(addressLine1) ? addressLine1 : "-"}
                </div>
              ) : (
                <input
                  className="form-control custom-input"
                  value={addressLine1}
                  onChange={(e) => {
                    setAddressLine1(e.target.value);
                    isStudentUpdate();
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Address 2")}</span>
              {!doctorDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(addressLine2) ? addressLine2 : "-"}
                </div>
              ) : (
                <input
                  className="form-control custom-input"
                  value={addressLine2}
                  onChange={(e) => {
                    setAddressLine2(e.target.value);
                    isStudentUpdate();
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("Country")}</span>
              {!doctorDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(selectedCountryName)
                    ? selectedCountryName
                    : "-"}
                </div>
              ) : (
                <select
                  className="select-dropdown form-select custom-input"
                  value={countryId || ""}
                  onChange={(e) => {
                    setCountryId(e.target.value);
                    isStudentUpdate();
                  }}
                >
                  <option value="">
                    {isCountryLoading ? t("Loading...") : t("Select Country")}
                  </option>
                  {country.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
           
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text  ">{t("State")}</span>
              {!doctorDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(selectedStateName)
                    ? selectedStateName
                    : "-"}
                </div>
              ) : (
                <select
                  className="select-dropdown form-select custom-input"
                  value={stateId || ""}
                  onChange={(e) => {
                    setStateId(e.target.value);
                    isStudentUpdate();
                  }}
                  disabled={!countryId}
                >
                  <option value="">
                    {isCountryLoading ? t("Loading...") : t("Select State")}
                  </option>
                  {getStateData.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("City")}</span>
              {!doctorDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(selectedCityName) ? selectedCityName : "-"}
                </div>
              ) : (
                <select
                  className="select-dropdown form-select custom-input"
                  value={cityId || ""}
                  onChange={(e) => {
                    setCityId(e.target.value);
                    isStudentUpdate();
                  }}
                  disabled={!stateId}
                >
                  <option value="">
                    {isCountryLoading ? t("Loading...") : t("Select City")}
                  </option>
                  {city.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-filed ">
              <span className="basic-text ">{t("ZIP Code ")}</span>
              {!doctorDetailsEdit ? (
                <div className="large-bold-text">
                  {Util.isValidData(zipcode) ? zipcode : "-"}
                </div>
              ) : (
                <>
                  <input
                    className={`form-control custom-input ${
                      zipcodeErr.length > 0 ? "input-error" : ""
                    }`}
                    value={zipcode}
                    onChange={(e) => {
                      setZipcode(e.target.value);
                      setzipcodeErr("");
                      isStudentUpdate();
                    }}
                  />
                  {zipcodeErr.length > 0 && (
                    <span className="invalid-error text-danger">
                      {" "}
                      <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                      {zipcodeErr}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          
          {doctorDetailsEdit && (
              <div className="text-end mt-4">
                {studentId > 0 && (
                  <button
                    className="secondary-lg-btn rounded-btn btn mr-2 m-0  px-2"
                    onClick={() => {
                      onCancel();
                      setzipcodeErr("");
                    }}
                  >
                    {t("Cancel")}
                  </button>
                )}
                <button
                  className="btn primary-lg-btn rounded-btn "
                  onClick={handleDoctorDetailEdit}
                >
                  {t("Save")}
                </button>
              </div>
            )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default DoctorDetailsCard;
