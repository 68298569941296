import React, { useState } from "react";
import LeaveApproval from "./LeaveApproval";
import LeavePending from "./LeavePending";
import { useTranslation } from "react-i18next";
import ApiCalling from "../../../../network/ApiCalling";
import Util from "../../../../Util";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BreadCrumbComponent from "../../../../components/BreadCrumbComponent"

function LeaveTab() {
  const history = useHistory();
  const user = Util.getUser();
  const { t } = useTranslation();
  const [refreshApprove, setRefreshApprove] = useState(false);
  const onTabChange = (e) => {
    var tab1 = document.getElementById("bacicTab-1");
    tab1.classList.remove("active");
    tab1.children[0].classList.remove("active");
    var tab2 = document.getElementById("bacicTab-2");
    tab2.classList.remove("active");
    tab2.children[0].classList.remove("active");

    var actab = document.getElementById("bacicTab-" + e);
    actab.classList.remove("active");
    actab.children[0].classList.add("active");

    var tabpan1 = document.getElementById("bacicTabpan-1");
    tabpan1.classList.remove("active");
    var tabpan2 = document.getElementById("bacicTabpan-2");
    tabpan2.classList.remove("active");

    var actabpab = document.getElementById("bacicTabpan-" + e);
    actabpab.classList.add("active");
  };
 
  return (
    <div>
      <div className="container-fluid">
      
      {/* <BreadCrumbComponent
          Breadcrumb={[
            {
              name: "My School",
              navigate: () => {
                history.push("/Dashboard");
              },
            },
            {
              name: "Attendance",
              navigate: () => {
                history.push("/attendance-log");
              },
            },
            { name: "Leave Approval" },
          ]}
        /> */}
      <h2 className="page-head py-3">{t("Leave Approval")}</h2>
        <div className="body">
          <ul className="nav nav-tabs" role="tablist">
            <li
              className="nav-item active"
              id="bacicTab-1"
              role="presentation"
              onClick={() => {
                onTabChange(1);
              }}
            >
              <a className="nav-link active">{t("Pending")}</a>
            </li>

            <li
              className="nav-item mr-1"
              id="bacicTab-2"
              role="presentation"
              onClick={() => {
                onTabChange(2);
              }}
            >
              <a className="nav-link" data-toggle="tab">
                {t("Approved")}
              </a>
            </li>
          </ul>

          <div className="tab-content ">
            <div id="bacicTabpan-1" className="tab-pane active">
              <LeavePending setRefreshApprove={setRefreshApprove}/>
            </div>

            <div id="bacicTabpan-2" className="tab-pane">
              <LeaveApproval setRefreshApprove={setRefreshApprove} refreshApprove={refreshApprove}/>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default LeaveTab;
