import React, { useState, useEffect, useRef, useContext } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import ApiCalling from "../../network/ApiCalling";
import EmojiPicker from "emoji-picker-react";
import Util from "../../Util";
import ToastHOC from "../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import StaffList from "./StaffList";
import ParentList from "./ParentList";
import GroupList from "./GroupList";
import { SocketContext } from "../../context/SocketContext";
import socketIOClient from "socket.io-client";

import ChatMessage from "./ChatMessage";
import Smile from "../../assets/Icon/smile.svg";
import PaperClip from "../../assets/Icon/paperclip.svg";

import { SeparateDot } from "../../components/common/separateDot";
import student from "../../assets/Icon/user.svg";
import groupIcon from "../../assets/Icon/GroupIcon.svg";
import Search from "../../assets/Icon/Search.svg";
import chatPlus from "../../assets/images/chatPlus.svg";
import grayGroup from "../../assets/Icon/gray-group.svg";
import Parent from "../../assets/Icon/Parent.svg";
import building from "../../assets/Icon/building-school.png";
import NewParentChat from "./NewParentChat";
import NewStaffChat from "./NewStaffChat";
import NewGroup from "./NewGroup";
import Loader from "../../components/common/Loader";

import { AudioRecorder } from "react-audio-voice-recorder";
import { useSelector } from "react-redux";
import ParentsTeacherList from "./ParentsTeacherList";
import ParentsGroupList from "./ParentsGroupList";

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const NEW_CHAT_MESSAGE_EVENT = "events";
const NEW_CHAT_MESSAGE_READ = "read";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const BadgeCounter = ({ value, count }) => {
  return (
    <div className="d-flex gap-2 align-items-center mr-1">
      <div>{value}</div>
      {count > 0 && (
        <div className="counter-badge ">
          <span className="d-flex justify-content-center align-items-center ">
            {count}
          </span>
        </div>
      )}
    </div>
  );
};

const Message = (props) => {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const history = useHistory();
  const location = useLocation();
  const ParentMode = Util.getParentMode();
  const selected = useSelector((state) => state?.SelectedStudentReducer);

  const [tabSelection, setTabSelection] = useState(
    `${ParentMode ? "TEACHER" : "STAFF"}`
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [user, setUser] = useState({});

  const [groupRoom, setGroupRoom] = useState([]);
  const [staffRoom, setStaffRoom] = useState([]);
  const [parentRoom, setParentRoom] = useState([]);
  const [isNoStaffData, setIsNoStaffData] = useState(false);
  const [unreadStaffMsgCount, setUnreadStaffMsgCount] = useState(0);
  const [unreadParentMsgCount, setUnreadParentMsgCount] = useState(0);
  const [unreadGroupMsgCount, setUnreadGroupMsgCount] = useState(0);
  const [onlineUsersList, setOnlineUsersList] = useState([]);

  const [isChat, setIsChat] = useState(false);
  const [isChatType, setIsChatType] = useState("");
  const [roomId, setRoomId] = useState("");
  const [isActiveUser, setIsActiveUser] = useState(false);
  const [room_User, setRoom_User] = useState();
  const [room_Group, setRoom_Group] = useState();
  const [currentPage, setCurrentPage] = useState(2);
  const [totalPages, setTotalPages] = useState();
  const [messagesHistory, setMessagesHistory] = useState([]);
  const [userId, setUserId] = useState(0);
  const [groupId, setGroupId] = useState(0);
  const [search_New_Parent, setSearch_New_Parent] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [relationWithParent, setRelationWithParent] = useState("");
  const [unreadObject, setUnreadObject] = useState({
    unreadCount: 0,
    unreadIndex: -1,
  });
  const [search_Parent, setSearch_Parent] = useState("");
  const [filteredParentList, setFilteredParentList] = useState([]);
  const [search_Staff, setSearch_Staff] = useState("");
  const [filteredStaffList, setFilteredStaffList] = useState([]);
  const [search_Group, setSearch_Group] = useState("");
  const [filteredGroupList, setFilteredGroupList] = useState([]);
  const [newStaffChat, setNewStaffChat] = useState(false);
  const [newParentChat, setNewParentChat] = useState(false);
  const [newGroupChat, setNewGroupChat] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  // Parent Mode
  const [unreadParentsTeacherMsgCount, setUnreadParentsTeacherMsgCount] =
    useState(0);
  const [unreadParentsGroupMsgCount, setUnreadParentsGroupMsgCount] =
    useState(0);
  const [parentsGroupRoom, setParentsGroupRoom] = useState([]);
  const [filteredParentsGroupRoom, setFilteredParentsGroupRoom] = useState([]);
  const [parentsTeacherRoom, setParentsTeacherRoom] = useState([]);
  const [filteredParentsTeacherRoom, setFilteredParentsTeacherRoom] = useState(
    []
  );
  const [isNoParentsTeacherData, setIsNoParentsTeacherData] = useState(false);
  const [isNoParentsGroupData, setIsNoParentsGroupData] = useState(false);
  const [search_Parents_Teacher, setSearch_Parents_Teacher] = useState("");
  const [search_Parents_Group, setSearch_Parents_Group] = useState("");

  const socketRef = useRef();
  const socket = useContext(SocketContext);
  const messagesEnd = useRef(null);
  const messageListRef = useRef(null);
  const emojiPickerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    }

    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  // const searchParentClicked = () => {
  //   setSearchParentClick(search_Parent);
  // };
  // const EnterParentHandler = (event) => {
  //   if (event.key === "Enter") {
  //     setSearchParentClick(search_Parent);
  //   }
  // };
  // const searchStaffClicked = () => {
  //   setSearchStaffClick(search_Staff);
  // };
  // const EnterStaffHandler = (event) => {
  //   if (event.key === "Enter") {
  //     setSearchStaffClick(search_Staff);
  //   }
  // };
  // const searchGroupClicked = () => {
  //   setSearchGroupClick(search_Group);
  // };
  // const EnterGroupHandler = (event) => {
  //   if (event.key === "Enter") {
  //     setSearchGroupClick(search_Group);
  //   }
  // };

  const handleTabChange = (event, newSelection) => {
    setTabSelection(newSelection);
  };

  const scrollToBottom = () => {
    if (messagesEnd.current !== null) {
      messagesEnd.current.scrollIntoView({ block: "end" });
    }
  };

  useEffect(() => {
    (async () => {
      setUser(Util.getUser());
      if (!ParentMode) {
        getStaffRooms();
        getParentRooms();
        getMyGroups();
      } else {
        getParentsTeacherRoom();
        getParentsGroupRoom();
      }

      socket.on("new-message", () => {
        // console.log("new-message ***");
        if (!ParentMode) {
          getStaffRooms();
          getParentRooms();
          getMyGroups();
        } else {
          getParentsTeacherRoom();
          getParentsGroupRoom();
        }
      });
    })();
    let isGetUSerMounted = true;
    socket.on("get-users", (users) => {
      // console.log("get-users");
      if (isGetUSerMounted) {
        setOnlineUsersList(users);
      }
    });

    // Tab closed
    const handleBlur = () => {
      // socket.emit("offline", user.id);
    };

    const handleFocus = async () => {
      // socket.emit("new-user-add", user.id);
      socket.on("get-users", (users) => {
        // console.log("get-users on handle Focus");
        setOnlineUsersList(users);
      });
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
      isGetUSerMounted = false;
    };
  }, []);

  useEffect(() => {
    const tempStaffList = staffRoom;
    let newTempStaffList = tempStaffList?.map((obj) => {
      var online = false;
      online = onlineUsersList?.some(
        (onlineUsers) => onlineUsers.userId === obj?.user?.id
      );
      return { ...obj, isOnline: online };
    });
    setStaffRoom(newTempStaffList);

    const tempParentList = parentRoom;

    const newTempParentList = tempParentList?.map((obj) => {
      var online = false;
      online = onlineUsersList.some(
        (onlineUsers) => onlineUsers?.userId === obj?.user?.id
      );
      return { ...obj, isOnline: online };
    });

    setParentRoom(newTempParentList);

    let tempParentsTeacherList = parentsTeacherRoom;
    const newTempList = tempParentsTeacherList.map((obj) => {
      var online = false;
      online = onlineUsersList.some(
        (onlineUsers) => onlineUsers?.userId === obj?.user_id
      );
      return { ...obj, isOnline: online };
    });

    setParentsTeacherRoom(newTempList);
  }, [onlineUsersList]);

  const getStaffRooms = async () => {
    // setIsListLoading(true)
    ApiCalling.apiCallBodyDataGet("chatroom/staff-rooms").then((res) => {
      setIsListLoading(false);
      const tempStaffList = res?.data;
      if (res.data.length > 0) {
        setIsNoStaffData(false);
      } else {
        setIsNoStaffData(true);
      }
      let newTempStaffList = tempStaffList.map((obj) => {
        var online = false;
        online = onlineUsersList.some(
          (onlineUsers) => onlineUsers.userId === obj?.user?.id
        );
        return { ...obj, isOnline: online };
      });
      setUnreadStaffMsgCount(
        tempStaffList.filter((obj) => obj.unread > 0).length
      );
      setStaffRoom(newTempStaffList);
      setFilteredStaffList(newTempStaffList);
    });
  };

  const getParentRooms = async () => {
    // setIsListLoading(true)
    ApiCalling.apiCallBodyDataGet("chatroom/parent-rooms").then((res) => {
      setIsListLoading(false);

      const tempParentList = res?.data;

      const newTempParentList = tempParentList?.map((obj) => {
        var online = false;
        online = onlineUsersList.some(
          (onlineUsers) => onlineUsers?.userId === obj?.user?.id
        );
        return { ...obj, isOnline: online };
      });

      // const noCount = tempParentList.filter((obj) => obj.unread > 0);
      setUnreadParentMsgCount(
        tempParentList.filter((obj) => obj?.unread > 0).length
      );

      setParentRoom(newTempParentList);
      setFilteredParentList(newTempParentList);
    });
  };

  const getMyGroups = async () => {
    // setIsListLoading(true);
    ApiCalling.apiCallBodyDataGet("group/my-staff-groups").then((res) => {
      setIsListLoading(false);
      // console.log("res", res?.data);
      if (!res?.data && Util.isValidArray(res.data)) return;

      const tempGroupList = res?.data;

      // const noCount = res?.data?.filter((obj) => obj.unread > 0);
      setUnreadGroupMsgCount(
        tempGroupList.filter((obj) => obj?.unread > 0).length
      );
      setGroupRoom(res?.data);
      setFilteredGroupList(res?.data);
    });
  };

  useEffect(() => {
    if (roomId !== "") {
      socketRef.current = socketIOClient(BASE_URL, {
        query: { roomId },
      });

      // console.log("current:", socketRef.current);
      socketRef.current.emit("createRoom", { roomId: roomId });

      socketRef.current.on("roomCreated", function (data) {
        // console.log("data ->", data);
      });
      socketRef.current.on("exception", function (data) {
        // console.log("event exception", data);
      });
      socketRef.current.on("disconnect", function () {
        // console.log("Disconnected");
      });

      socketRef.current.on(NEW_CHAT_MESSAGE_EVENT, (message) => {
        if (message.chatroom_id === roomId) {
          const incomingMessage = {
            ...message,
            ownedByCurrentUser: message.senderId === socketRef.current.id,
          };
          setMessagesHistory((messages) => [...messages, incomingMessage]);
          if (message.sent_by !== user?.id) {
            socketRef.current.emit(NEW_CHAT_MESSAGE_READ, {
              senderId: socketRef.current.id,
              id: message.id,
            });
          }
          setTimeout(() => {
            scrollToBottom();
          }, 400);
        }
      });
      socketRef.current.on("read-done", function (data) {
        setMessagesHistory((prevState) => {
          const index = prevState.findIndex(({ id }) => id === data);
          let item = prevState[index];
          item.read_on = new Date();
          return [
            ...prevState.slice(0, index),
            { ...item },
            ...prevState.slice(index + 1),
          ];
        });
      });

      return () => {
        // console.log("disconnect")
        socketRef.current.disconnect();
      };
    }
  }, [roomId]);

  const startChatAndSetRelation = async (user1) => {
    await startChat(user1.user);
    await setRelationWithParent(user1.child[0]);
    if (user1.unread > 0) {
      await setUnreadParentMsgCount(0);
    }
  };
  const startChatForStaff = async (user1) => {
    const data = ParentMode ? user1 : user1.user;
    await startChat(data);
    if (user1.unread > 0) {
      await setUnreadStaffMsgCount(0);
    }
  };

  function startChat(user1) {
    let data = {
      user_id: ParentMode ? user1.user_id : user1.id,
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("chatroom/get-chatroom", data).then(
      (res) => {
        const isStaffMember =
          user1.user_Schools && user1.user_Schools.length > 0;
        setIsActiveUser(true);
        if (isStaffMember) {
          const desiredUser = user1.user_Schools.find(
            (school) => school.active === true
          );
          if (desiredUser == undefined) {
            setIsActiveUser(false);
          }
        }
        if (ParentMode) {
          setUserId(user1.user_id);
        } else {
          setUserId(user1.id);
        }
        setGroupId(0);
        if (res.data) {
          setIsLoading(false);
          setRoomId(res.data.data.chatroom.id);
          setRoom_User(user1);

          setMessagesHistory([]);
          setIsChatType("");
          setCurrentPage(2);
          if (res.data.data.messages.length > 0) {
            const newMessage = res.data.data.messages.reverse();
            const unreadIndex = newMessage.findIndex((i) => i.read_on == null);

            const unreadCount =
              unreadIndex == -1 ? 0 : newMessage.length - unreadIndex;

            setUnreadObject({
              unreadCount: unreadCount,
              unreadIndex: unreadIndex,
            });
            setMessagesHistory(newMessage);
          }
          setIsChat(true);
          setTimeout(() => {
            scrollToBottom();
          }, 400);

          setRoom_Group(null);
          setSearch_New_Parent(false);
        }
      }
    );
  }

  function createGroupRoom(grp) {
    setGroupId(grp.id);
    setUserId(0);
    setIsActiveUser(true);
    let data = {
      group_id: grp.id,
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("chatroom/get-group", data).then((res) => {
      if (res.data) {
        setIsLoading(false);
        setRoomId(res.data.data.chatroom.id);
        setRoom_Group(res.data.data.group);
        setMessagesHistory([]);
        setCurrentPage(2);
        if (res.data.data.messages.length > 0) {
          const groupMessage = res.data.data.messages.reverse();
          const unreadIndex = groupMessage.findIndex((i) => i.read_on == null);

          const unreadCount =
            unreadIndex == -1 ? 0 : groupMessage.length - unreadIndex;

          setUnreadObject({
            unreadCount: unreadCount,
            unreadIndex: unreadIndex,
          });
          setMessagesHistory(groupMessage);
        }
        setIsChatType("GROUP");
        setIsChat(true);
        setTimeout(() => {
          scrollToBottom();
        }, 400);
        setRoom_User(null);
        setSearch_New_Parent(false);
        if (grp.unread) {
          setUnreadGroupMsgCount(0);
        }
      }
    });
  }

  const handleAddEmoji = (emojiObject) => {
    const newMsg = newMessage + emojiObject.emoji;
    setNewMessage(newMsg);
  };

  const sendMessage = (messageBody) => {
    if (Util.isValidData(messageBody)) {
      socketRef.current?.emit(NEW_CHAT_MESSAGE_EVENT, {
        body: messageBody,
        senderId: socketRef.current.id,
        id: user.id,
      });
      setNewMessage("");
    }
  };

  const sendFile = async (file) => {
    socketRef.current?.emit(NEW_CHAT_MESSAGE_EVENT, {
      body: "",
      senderId: socketRef.current.id,
      id: user.id,
      attachment: file,
      fileName: file.name,
    });
    setNewMessage("");
  };

  function MarkAsRead(item) {
    if (item.read_on === null) {
      socketRef.current?.emit(NEW_CHAT_MESSAGE_READ, {
        senderId: socketRef.current.id,
        id: item.id,
      });
    }
  }
  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleScroll = () => {
    if (messageListRef !== null) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      if (scrollTop === 0) {
        if (currentPage <= totalPages || totalPages === undefined) {
          handlePagination();
        }
      }
    }
  };

  const handlePagination = () => {
    const pageData = {
      page: currentPage,
      type: userId !== 0 ? "user" : "group",
      user_id: userId,
      group_id: groupId,
    };
    ApiCalling.apiCallBodyDataPost("chatroom/get-paginated-chatroom", pageData)
      .then((res) => {
        // console.log("Pagination", res.data);
        setCurrentPage((prevPage) => prevPage + 1);
        setTotalPages(res.data.total_pages);
        const newMessages = res.data.data;
        setMessagesHistory((prevList) => [...newMessages, ...prevList]);
        setUnreadObject({
          unreadCount: 0,
          unreadIndex: -1,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Enterhandler = (event) => {
    if (event.key === "Enter") {
      sendMessage(newMessage);
      if (isChatType == "GROUP" && !ParentMode) {
        getMyGroups();
      } else if (!ParentMode && isChatType !== "GROUP") {
        getParentRooms();
        getStaffRooms();
      } else {
        getParentsTeacherRoom();
        getParentsGroupRoom();
      }
    }
  };

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.body.appendChild(audio);
  };

 
  // Parent Mode

  useEffect(() => {
    if (!selected?.selectedStudent?.id) {
      if (ParentMode) {
        
        history.push("/ParentsDashboard");
      }
    }
  }, [parentRoom, groupRoom, filteredGroupList, filteredParentList]);

  const getParentsTeacherRoom = async () => {
    ApiCalling.apiCallBodyDataGet(
      `chatroom/student-rooms/${selected?.selectedStudent?.id}`
    )
      .then((res) => {
        // console.log("chat -student-rooms", res.data);
        if (res?.data?.length > 0) {
          setIsNoParentsTeacherData(false);
        } else {
          setIsNoParentsTeacherData(true);
        }
        const tempList = res?.data;

        const newTempList = tempList?.map((obj) => {
          var online = false;
          online = onlineUsersList.some(
            (onlineUsers) => onlineUsers.userId === obj?.user?.user_id
          );
          return { ...obj, isOnline: online };
        });

        const noCount = tempList?.filter((obj) => obj.unread > 0);

        setUnreadParentsTeacherMsgCount(noCount.length);
        setParentsTeacherRoom(newTempList);
        setFilteredParentsTeacherRoom(newTempList);
      })
      .catch((err) => {
        setIsLoading(false);

        console.log("ERROR", err);
      });
  };

  const getParentsGroupRoom = async () => {
    ApiCalling.apiCallBodyDataGet(
      `group/my-parent-groups/${selected?.selectedStudent?.id}`
    )
      .then((res) => {
        if (res?.data?.length > 0) {
          setIsNoParentsGroupData(false);
        } else {
          setIsNoParentsGroupData(true);
        }
        const noCount = res?.data?.filter((obj) => obj?.unread > 0);
        setUnreadParentsGroupMsgCount(noCount?.length);
        setParentsGroupRoom(res?.data);
        setFilteredParentsGroupRoom(res?.data);
      })
      .catch((er) => {
        console.log("ERROR", er);
      });
  };

  useEffect(() => {
    if (ParentMode) {
      if (Util.isValidData(selected) && selected?.selectedStudent?.id > 0) {
        getParentsTeacherRoom();
        getParentsGroupRoom();
      }
    }
  }, [selected]);

  return (
    <>
      <div
      // style={{ flex: 1, overflowX: "hidden" }}
      // onClick={() => {
      //   document.body.classList.remove("offcanvas-active");
      // }}
      >
        <div className="container-fluid">
          <div className="body">
            <div className="row">
              <div
                className="col-4 people-list border-right"
                style={{ paddingRight: "0px", position: "relative" }}
              >
                <div className="row px-3 py-3">
                  <div className="col-8">
                    <h2 className="page-head">{t("Message")}</h2>
                  </div>
                  {/* <div className="col-4 text-right mt-2">
                  <i className="fa-solid fa-ellipsis" aria-hidden="true"></i>
                </div> */}
                </div>
                {!isListLoading ? (
                  <div className=" message-tabs-wrap">
                    <Box sx={{ width: "100%" }}>
                      {ParentMode ? (
                        <>
                          <Tabs value={tabSelection} onChange={handleTabChange}>
                            <Tab
                              sx={{
                                textTransform: "none",
                                fontSize: "14px",
                                "&.Mui-selected": {
                                  color: "#f07a80",
                                  fontWeight: "600",
                                },
                              }}
                              value="TEACHER"
                              label={
                                <BadgeCounter
                                  value={t("Teacher")}
                                  count={unreadParentsTeacherMsgCount}
                                />
                              }
                            />
                            <Tab
                              sx={{
                                textTransform: "none",
                                fontSize: "14px",
                                "&.Mui-selected": {
                                  color: "#f07a80",
                                  fontWeight: "600",
                                },
                              }}
                              value="PARENTSGROUP"
                              label={
                                <BadgeCounter
                                  value={t("Group")}
                                  count={unreadParentsGroupMsgCount}
                                />
                              }
                            />
                          </Tabs>
                        </>
                      ) : (
                        <>
                          <Tabs value={tabSelection} onChange={handleTabChange}>
                            <Tab
                              sx={{
                                textTransform: "none",
                                fontSize: "14px",
                                "&.Mui-selected": {
                                  color: "#f07a80",
                                  fontWeight: "600",
                                },
                              }}
                              value="PARENT"
                              label={
                                <BadgeCounter
                                  value={t("Parents")}
                                  count={unreadParentMsgCount}
                                />
                              }
                            />
                            <Tab
                              sx={{
                                textTransform: "none",
                                fontSize: "14px",
                                "&.Mui-selected": {
                                  color: "#f07a80",
                                  fontWeight: "600",
                                },
                              }}
                              value="STAFF"
                              label={
                                <BadgeCounter
                                  value={t("Staff")}
                                  count={unreadStaffMsgCount}
                                />
                              }
                            />
                            <Tab
                              sx={{
                                textTransform: "none",
                                fontSize: "14px",
                                "&.Mui-selected": {
                                  color: "#f07a80",
                                  fontWeight: "600",
                                },
                              }}
                              value="GROUP"
                              label={
                                <BadgeCounter
                                  value={t("Group")}
                                  count={unreadGroupMsgCount}
                                />
                              }
                            />
                          </Tabs>
                        </>
                      )}

                      {ParentMode ? (
                        <>
                          {tabSelection === "TEACHER" && (
                            <>
                              {!isNoParentsTeacherData ? (
                                <>
                                  <div className="input-group pt-3 pl-3 pr-3">
                                    <input
                                      className="form-control search "
                                      placeholder="Search"
                                      type="text"
                                      value={search_Parents_Teacher}
                                      onChange={(e) => {
                                        // setSearch_Staff(e.target.value);
                                        setSearch_Parents_Teacher(
                                          e.target.value
                                        );
                                        const searchValue = e.target.value;
                                        if (searchValue.trim() === "") {
                                          setFilteredParentsTeacherRoom(
                                            parentsTeacherRoom
                                          );
                                        } else {
                                          const filteredStaff =
                                            parentsTeacherRoom.filter((s) =>
                                              s.firstName
                                                .toLowerCase()
                                                .includes(
                                                  searchValue.toLowerCase()
                                                )
                                            );
                                          setFilteredParentsTeacherRoom(
                                            filteredStaff
                                          );
                                        }
                                      }}
                                      // onKeyPress={(e) => EnterStaffHandler(e)}
                                    />
                                    <div className="input-group-prepend">
                                      <div
                                        className="chat-search-icon"
                                        // onClick={searchStaffClicked}
                                      >
                                        <img
                                          src={Search}
                                          className="svg-icon"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="chat-wrapper">
                                    <ParentsTeacherList
                                      StaffList={filteredParentsTeacherRoom}
                                      selectedUserId={userId}
                                      onlineUsersList={onlineUsersList}
                                      startChat={(user1) => {
                                        startChatForStaff(user1);
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row chat-wrapper1">
                                    <div className="col-lg-12 offset-lg-12">
                                      <div className="d-flex text-center p-5">
                                        <i
                                          className=" card p-5 icon-book-open"
                                          style={{
                                            fontSize: "50px",
                                            opacity: "0.3",
                                          }}
                                        ></i>
                                      </div>
                                      <p className="text-center small text-muted">
                                        {t("Staff Not Found")}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}

                          {tabSelection === "PARENTSGROUP" && (
                            <>
                              {!isNoParentsGroupData ? (
                                <>
                                  <div className="input-group pt-3 pl-3 pr-3">
                                    <input
                                      className="form-control search "
                                      placeholder="Search"
                                      type="text"
                                      value={search_Parents_Group}
                                      onChange={(e) => {
                                        // setSearch_Group(e.target.value);
                                        setSearch_Parents_Group(e.target.value);
                                        const searchValue = e.target.value;
                                        if (searchValue.trim() === "") {
                                          setFilteredParentsGroupRoom(
                                            parentsGroupRoom
                                          );
                                        } else {
                                          const filteredGroup =
                                            parentsGroupRoom.filter((s) =>
                                              s.name
                                                .toLowerCase()
                                                .includes(
                                                  searchValue.toLowerCase()
                                                )
                                            );
                                          setFilteredParentsGroupRoom(
                                            filteredGroup
                                          );
                                        }
                                      }}
                                      // onKeyPress={(e) => EnterGroupHandler(e)}
                                    />
                                    <div className="input-group-prepend">
                                      <div
                                        className="chat-search-icon"
                                        // onClick={searchGroupClicked}
                                      >
                                        <img
                                          src={Search}
                                          className="svg-icon"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="chat-wrapper">
                                    <ParentsGroupList
                                      GroupList={filteredParentsGroupRoom}
                                      onlineUsersList={onlineUsersList}
                                      startChat={(user1) => {
                                        createGroupRoom(user1);
                                      }}
                                      selectedGroupId={groupId}
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="row chat-wrapper1">
                                  <div className="col-lg-12 offset-lg-12">
                                    <div className="d-flex text-center p-5">
                                      <i
                                        className=" card p-5 icon-book-open"
                                        style={{
                                          fontSize: "50px",
                                          opacity: "0.3",
                                        }}
                                      ></i>
                                    </div>
                                    <p className="text-center small text-muted">
                                      {t("Group Not Found")}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {tabSelection === "STAFF" && (
                            <>
                              {!isNoStaffData ? (
                                <>
                                  <div className="input-group pt-3 pl-3 pr-3">
                                    <input
                                      className="form-control search "
                                      placeholder="Search"
                                      type="text"
                                      value={search_Staff}
                                      onChange={(e) => {
                                        // setSearch_Staff(e.target.value);
                                        setSearch_Staff(e.target.value);
                                        const searchValue = e.target.value;
                                        if (searchValue.trim() === "") {
                                          setFilteredStaffList(staffRoom);
                                        } else {
                                          const filteredStaff =
                                            staffRoom.filter((s) =>
                                              s.user.firstName
                                                .toLowerCase()
                                                .includes(
                                                  searchValue.toLowerCase()
                                                )
                                            );
                                          setFilteredStaffList(filteredStaff);
                                        }
                                      }}
                                      // onKeyPress={(e) => EnterStaffHandler(e)}
                                    />
                                    <div className="input-group-prepend">
                                      <div
                                        className="chat-search-icon"
                                        // onClick={searchStaffClicked}
                                      >
                                        <img
                                          src={Search}
                                          className="svg-icon"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="chat-wrapper">
                                    <StaffList
                                      StaffList={filteredStaffList}
                                      selectedUserId={userId}
                                      onlineUsersList={onlineUsersList}
                                      startChat={(user1) => {
                                        startChatForStaff(user1);
                                      }}
                                    />

                                    <div className="chat-plus-icon">
                                      <div className="dropdown">
                                        <div
                                          className="chat-plus-button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i
                                            className="fas fa-plus"
                                            style={{
                                              color: "#ffffff",
                                              fontSize: 18,
                                            }}
                                          ></i>
                                        </div>

                                        <ul
                                          className="dropdown-menu plus-icon-dropdown"
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() => {
                                                setNewGroupChat(true);
                                              }}
                                            >
                                              <img
                                                src={grayGroup}
                                                className="mr-1"
                                              />
                                              {t("New Group")}
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() => {
                                                setNewParentChat(true);
                                              }}
                                            >
                                              <img
                                                src={Parent}
                                                className="mr-1"
                                              />
                                              {t("New Chat Parent")}
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() => {
                                                setNewStaffChat(true);
                                              }}
                                            >
                                              <img
                                                src={building}
                                                className="mr-1"
                                              />
                                              {t("New Chat Staff")}
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className=" chat-wrapper1">
                                  <div className="row">
                                    <div className="col-lg-12 offset-lg-12">
                                      <div className="d-flex text-center p-5">
                                        <i
                                          className=" card p-5 icon-book-open"
                                          style={{
                                            fontSize: "50px",
                                            opacity: "0.3",
                                          }}
                                        ></i>
                                      </div>
                                      <p className="text-center small text-muted">
                                        {t(
                                          "There is No staff registered, please add staff"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="chat-plus-icon">
                                    <div className="dropdown">
                                      <div
                                        className="chat-plus-button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fas fa-plus"
                                          style={{
                                            color: "#ffffff",
                                            fontSize: 18,
                                          }}
                                        ></i>
                                      </div>

                                      <ul
                                        className="dropdown-menu plus-icon-dropdown"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() => {
                                              setNewGroupChat(true);
                                            }}
                                          >
                                            <img
                                              src={grayGroup}
                                              className="mr-1"
                                            />
                                            {t("New Group")}
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() => {
                                              setNewParentChat(true);
                                            }}
                                          >
                                            <img
                                              src={Parent}
                                              className="mr-1"
                                            />
                                            {t("New Chat Parent")}
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() => {
                                              setNewStaffChat(true);
                                            }}
                                          >
                                            <img
                                              src={building}
                                              className="mr-1"
                                            />
                                            {t("New Chat Staff")}
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {tabSelection === "PARENT" && (
                            <>
                              {parentRoom.length > 0 ? (
                                <>
                                  <div className="input-group pt-3 pl-3 pr-3">
                                    <input
                                      className="form-control search "
                                      placeholder="Search"
                                      type="text"
                                      value={search_Parent}
                                      onChange={(e) => {
                                        // setSearch_Parent(e.target.value);
                                        setSearch_Parent(e.target.value);
                                        const searchValue = e.target.value;
                                        if (searchValue.trim() === "") {
                                          setFilteredParentList(parentRoom);
                                        } else {
                                          const filteredParent =
                                            parentRoom.filter((s) =>
                                              s.user.firstName
                                                .toLowerCase()
                                                .includes(
                                                  searchValue.toLowerCase()
                                                )
                                            );
                                          setFilteredParentList(filteredParent);
                                        }
                                      }}
                                      // onKeyPress={(e) => EnterParentHandler(e)}
                                    />
                                    <div className="input-group-prepend">
                                      <div
                                        className="chat-search-icon"
                                        // onClick={searchParentClicked}
                                      >
                                        <img
                                          src={Search}
                                          className="svg-icon"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="chat-wrapper">
                                    <ParentList
                                      ParentList={filteredParentList}
                                      onlineUsersList={onlineUsersList}
                                      startChat={(user1) => {
                                        startChatAndSetRelation(user1);
                                      }}
                                      selectedUserId={userId}
                                    />

                                    <div className="chat-plus-icon">
                                      <div className="dropdown">
                                        <div
                                          className="chat-plus-button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i
                                            className="fas fa-plus"
                                            style={{
                                              color: "#ffffff",
                                              fontSize: 18,
                                            }}
                                          ></i>
                                        </div>

                                        <ul
                                          className="dropdown-menu plus-icon-dropdown"
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() => {
                                                setNewGroupChat(true);
                                              }}
                                            >
                                              <img
                                                src={grayGroup}
                                                className="mr-1"
                                              />
                                              {t("New Group")}
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() => {
                                                setNewParentChat(true);
                                              }}
                                            >
                                              <img
                                                src={Parent}
                                                className="mr-1"
                                              />
                                              {t("New Chat Parent")}
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() => {
                                                setNewStaffChat(true);
                                              }}
                                            >
                                              <img
                                                src={building}
                                                className="mr-1"
                                              />
                                              {t("New Chat Staff")}
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="chat-wrapper1">
                                  <div className="row">
                                    <div className="col-lg-12 offset-lg-12">
                                      <div className="d-flex text-center p-5">
                                        <i
                                          className=" card p-5 icon-book-open"
                                          style={{
                                            fontSize: "50px",
                                            opacity: "0.3",
                                          }}
                                        ></i>
                                      </div>
                                      <p className="text-center small text-muted">
                                        {t("No parents found")}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="chat-plus-icon">
                                    <div className="dropdown">
                                      <div
                                        className="chat-plus-button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fas fa-plus"
                                          style={{
                                            color: "#ffffff",
                                            fontSize: 18,
                                          }}
                                        ></i>
                                      </div>

                                      <ul
                                        className="dropdown-menu plus-icon-dropdown"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() => {
                                              setNewGroupChat(true);
                                            }}
                                          >
                                            <img
                                              src={grayGroup}
                                              className="mr-1"
                                            />
                                            {t("New Group")}
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() => {
                                              setNewParentChat(true);
                                            }}
                                          >
                                            <img
                                              src={Parent}
                                              className="mr-1"
                                            />
                                            {t("New Chat Parent")}
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() => {
                                              setNewStaffChat(true);
                                            }}
                                          >
                                            <img
                                              src={building}
                                              className="mr-1"
                                            />
                                            {t("New Chat Staff")}
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {tabSelection === "GROUP" && (
                            <>
                              {groupRoom.length > 0 ? (
                                <>
                                  <div className="input-group pt-3 pl-3 pr-3">
                                    <input
                                      className="form-control search "
                                      placeholder="Search"
                                      type="text"
                                      value={search_Group}
                                      onChange={(e) => {
                                        // setSearch_Group(e.target.value);
                                        setSearch_Group(e.target.value);
                                        const searchValue = e.target.value;
                                        if (searchValue.trim() === "") {
                                          setFilteredGroupList(groupRoom);
                                        } else {
                                          const filteredGroup =
                                            groupRoom.filter((s) =>
                                              s.name
                                                .toLowerCase()
                                                .includes(
                                                  searchValue.toLowerCase()
                                                )
                                            );
                                          setFilteredGroupList(filteredGroup);
                                        }
                                      }}
                                      // onKeyPress={(e) => EnterGroupHandler(e)}
                                    />
                                    <div className="input-group-prepend">
                                      <div
                                        className="chat-search-icon"
                                        // onClick={searchGroupClicked}
                                      >
                                        <img
                                          src={Search}
                                          className="svg-icon"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="chat-wrapper">
                                    <GroupList
                                      GroupList={filteredGroupList}
                                      onlineUsersList={onlineUsersList}
                                      startChat={(user1) => {
                                        createGroupRoom(user1);
                                      }}
                                      selectedGroupId={userId}
                                    />

                                    <div className="chat-plus-icon">
                                      <div className="dropdown">
                                        <div
                                          className="chat-plus-button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i
                                            className="fas fa-plus"
                                            style={{
                                              color: "#ffffff",
                                              fontSize: 18,
                                            }}
                                          ></i>
                                        </div>

                                        <ul
                                          className="dropdown-menu plus-icon-dropdown"
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() => {
                                                setNewGroupChat(true);
                                              }}
                                            >
                                              <img
                                                src={grayGroup}
                                                className="mr-1"
                                              />
                                              {t("New Group")}
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() => {
                                                setNewParentChat(true);
                                              }}
                                            >
                                              <img
                                                src={Parent}
                                                className="mr-1"
                                              />
                                              {t("New Chat Parent")}
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() => {
                                                setNewStaffChat(true);
                                              }}
                                            >
                                              <img
                                                src={building}
                                                className="mr-1"
                                              />
                                              {t("New Chat Staff")}
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="chat-wrapper1">
                                  <div className="row">
                                    <div className="col-lg-12 offset-lg-12">
                                      <div className="d-flex text-center p-5">
                                        <i
                                          className=" card p-5 icon-book-open"
                                          style={{
                                            fontSize: "50px",
                                            opacity: "0.3",
                                          }}
                                        ></i>
                                      </div>
                                      <p className="text-center small text-muted">
                                        {t("No group found.")}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="chat-plus-icon">
                                    <div className="dropdown">
                                      <div
                                        className="chat-plus-button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fas fa-plus"
                                          style={{
                                            color: "#ffffff",
                                            fontSize: 18,
                                          }}
                                        ></i>
                                      </div>

                                      <ul
                                        className="dropdown-menu plus-icon-dropdown"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() => {
                                              setNewGroupChat(true);
                                            }}
                                          >
                                            <img
                                              src={grayGroup}
                                              className="mr-1"
                                            />
                                            {t("New Group")}
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() => {
                                              setNewParentChat(true);
                                            }}
                                          >
                                            <img
                                              src={Parent}
                                              className="mr-1"
                                            />
                                            {t("New Chat Parent")}
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() => {
                                              setNewStaffChat(true);
                                            }}
                                          >
                                            <img
                                              src={building}
                                              className="mr-1"
                                            />
                                            {t("New Chat Staff")}
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Box>
                    {!ParentMode && (
                      <>
                        <NewGroup
                          show={newGroupChat}
                          onClose={() => {
                            setNewGroupChat(false);
                          }}
                          onSave={(msg) => {
                            if (msg) {
                              setIsToast(msg);
                              setNewGroupChat(false);
                            }
                          }}
                          onGroupClick={(user1) => {
                            createGroupRoom(user1);
                            getMyGroups();
                          }}
                          onCreateGroup={() => {
                            setNewGroupChat(false);
                          }}
                        />
                        <NewStaffChat
                          show={newStaffChat}
                          onClose={() => {
                            setNewStaffChat(false);
                          }}
                          onStaffClick={(user1) => {
                            if (user1) {
                              setNewStaffChat(false);
                              startChat(user1);
                            }
                          }}
                        />
                        <NewParentChat
                          show={newParentChat}
                          onClose={() => {
                            setNewParentChat(false);
                          }}
                          onParentClick={(user1) => {
                            if (user1) {
                              setNewParentChat(false);
                              startChat(user1);
                              setRelationWithParent(user1.child[0]);
                            }
                          }}
                        />
                      </>
                    )}
                  </div>
                ) : (
                  <Loader />
                )}
              </div>

              <div className="col-8">
                {!isLoading ? (
                  <>
                    {isChat ? (
                      <>
                        <div className="chat">
                          <div className="chat-header d-flex clearfix">
                            {isChatType !== "GROUP" ? (
                              <>
                                <a
                                  data-target="#view_info"
                                  data-toggle="modal"
                                  className="left float-left"
                                >
                                  {Util.getUserAvatarW(room_User, "36px")}
                                </a>
                                <div className="chat-about">
                                  <div className="title">
                                    {room_User.firstName +
                                      " " +
                                      room_User.lastName}
                                  </div>
                                  <div className="subtitle">
                                    {ParentMode ? (
                                      room_User.designation
                                    ) : room_User.user_Schools ? (
                                      room_User.user_Schools[0].role.role
                                    ) : (
                                      <>
                                        {relationWithParent ? (
                                          <div className="d-flex align-items-center">
                                            <img
                                              className="mr-1"
                                              src={student}
                                              height={12}
                                              width={12}
                                            />
                                            {relationWithParent.Student
                                              .first_name +
                                              " " +
                                              relationWithParent.Student
                                                .last_name}
                                            <SeparateDot />
                                            {t("Class")}:{" "}
                                            {
                                              relationWithParent.Student
                                                .classroom.name
                                            }
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <a
                                  data-target="#view_info"
                                  data-toggle="modal"
                                  className="left float-left"
                                >
                                  <img
                                    className="mr-1"
                                    src={groupIcon}
                                    height={40}
                                    width={40}
                                  />
                                </a>

                                <div className="chat-about">
                                  <h6 className="m-b-0"> {room_Group.name}</h6>
                                  <small>
                                    {Util.getGroupListDisplay(
                                      room_Group.GroupPeople.map((val) => {
                                        if (val.Student) {
                                          return (
                                            val.Student.first_name +
                                            " " +
                                            val.Student.last_name
                                          );
                                        } else if (val.User) {
                                          return (
                                            val.User.firstName +
                                            " " +
                                            val.User.lastName
                                          );
                                        }
                                      }),
                                      3
                                    )}
                                  </small>
                                </div>
                              </>
                            )}
                          </div>

                          <div
                            className="chat-history chatBox"
                            ref={messageListRef}
                            onScroll={handleScroll}
                          >
                            <ul className="m-b-0">
                              {messagesHistory?.map((item, index) => {
                                return (
                                  <div key={index}>
                                    {unreadObject.unreadIndex == index &&
                                    item.sent_by !== user.id ? (
                                      <div className="unread-messages-container">
                                        <hr className="half-hr mr-2" />
                                        <div className="white-box">
                                          {unreadObject.unreadCount}{" "}
                                          {t("Unread Messages")}
                                        </div>
                                        <hr className="half-hr ml-2" />
                                      </div>
                                    ) : null}
                                    <ChatMessage
                                      message={item}
                                      currentUser={user}
                                      readAMessage={(msg) => {
                                        MarkAsRead(msg);
                                      }}
                                      isChatType={isChatType}
                                    />
                                  </div>
                                );
                              })}
                            </ul>
                            <div ref={messagesEnd}></div>
                          </div>

                          {showEmojiPicker && (
                            <div
                              style={{
                                position: "fixed",
                                bottom: "60px",
                                zIndex: "999",
                              }}
                              ref={emojiPickerRef}
                            >
                              <EmojiPicker
                                onEmojiClick={handleAddEmoji}
                                searchDisabled
                                onClickOutside={() => {
                                  // console.log("click outside");
                                }}
                              />
                            </div>
                          )}

                          {isActiveUser === true ? (
                            <>
                              <div className="chat-message clearfix">
                                <div className="input-group mb-0">
                                  <div className="input-group-prepend">
                                    <span
                                      className="mx-2"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setShowEmojiPicker(!showEmojiPicker);
                                      }}
                                    >
                                      <img
                                        src={Smile}
                                        alt=""
                                        className="svg-icon px-0 mt-2"
                                      />
                                    </span>
                                    <span
                                      className="mx-2"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setShowEmojiPicker(false);
                                        document.getElementById("file").click();
                                      }}
                                    >
                                      <img
                                        src={PaperClip}
                                        alt=""
                                        className="svg-icon px-0 mt-2 mr-2"
                                      />
                                    </span>
                                    <input
                                      className="sr-only"
                                      id="file"
                                      type="file"
                                      onChange={(e) => {
                                        // const newImageFiles = [...file];
                                        // for (let i = 0; i < files.length; i++) {
                                        //   newImageFiles.push(files[i]);
                                        // }
                                        sendFile(e.target.files[0]);
                                      }}
                                    />
                                  </div>
                                  <input
                                    className="form-control chat-input"
                                    placeholder={t("Send a message")}
                                    type="text"
                                    rows="3"
                                    value={newMessage}
                                    onChange={handleNewMessageChange}
                                    onKeyPress={(e) => Enterhandler(e)}
                                    autoFocus
                                  />

                                  {/* <span
                                  // className="input-group-text"
                                  onClick={() => {
                                    // sendMessage(newMessage);
                                    // setShowEmojiPicker(false);
                                  }}
                                >
                                 
                                  <AudioRecorder
                                    onRecordingComplete={addAudioElement}
                                    audioTrackConstraints={{
                                      noiseSuppression: true,
                                      echoCancellation: true,
                                    }}
                                    downloadOnSavePress={true}
                                    downloadFileExtension="webm"
                                  />
                                </span> */}
                                  {/* </div> */}
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="alert alert-warning text-center">
                              {t(
                                "This user is inactive,you can not chat with this user"
                              )}
                              .
                            </div>
                          )}
                        </div>
                      </>
                    ) : isChat == false && search_New_Parent == false ? (
                      <>
                        <div className="row ">
                          <div className="col-12 ">
                            <div className="text-muted text-center  d-flex justify-content-center align-items-center">
                              <p>{t("Select user to start chat")}</p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToastHOC(Message);
