import React, { useEffect } from "react";
import ApiCalling from "../../../network/ApiCalling";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import failureImage from "../../../assets/images/179386.png";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";

export default function Failure(props) {
  const history = useHistory();
  const params = new URLSearchParams(props.location.search);
  const student = Util.getSelectedStudent();
  const { t } = useTranslation();
  const schoolId = student?.School?.id;
  const sessionId = params.get("session_id");
  const getSessionId = () => {
    const data = {
      session_id: sessionId,
    };
    ApiCalling.apiCallBodyDataPost("stripe/handle-failure", data, schoolId)
      .then((res) => {
       // console.log("Failure");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (sessionId) {
      getSessionId();
    }
   // console.log("Failure!!");
  }, [sessionId]);
  return (
    <div>
      <div className="text-center p-5">
        <img
          className="img-responsive p-2 m-2"
          style={{ height: "200px" }}
          src={failureImage}
        ></img>
        <h1> {t("Payment Failed !")}</h1>

        <button
          className="btn custom-primary"
          onClick={() => {
            history.push("/transaction");
          }}
        >
         {t("Try Again")}
        </button>
      </div>
    </div>
  );
}
