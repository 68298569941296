import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import "../Dashbord/DashboardCustom.css";
import { Modal, Button } from "react-bootstrap";
import "../../screens/Dashbord/Modals.css";
import ApiCalling from "../../network/ApiCalling";
import Close from "../../assets/Icon/close.png";

const AddChild = () => {
  const [roomList, setRoomList] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showTwo, setShowTwo] = useState(false);
  const handleCloseTwo = () => setShowTwo(false);
  const handleShowTwo = () => setShowTwo(true);

  const roomsList = async () => {
    ApiCalling.apiCallParamsGet("classroom/get-classrooms").then((res) => {
      
      setRoomList(res.data);
    });
  };

  useEffect(() => {
    roomsList();
  }, []);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="Dashboard"
            Breadcrumb={[{ name: "My Children" }]}
          />

          <div className="d-wrapper">
            <div className="heading mt-5 pb-3 d-flex justify-content-between">
              <div>
                <h2>My Children</h2>
              </div>

              <div className="dropdown">
                <button
                  className="btn custom-primary "
                  type="button"
                  aria-expanded="false"
                  style={{ color: "#fff", padding: "5px, 10px" }}
                  onClick={handleShow}
                >
                  Add Child
                </button>
              </div>
              {/* Modal */}

              <Modal
                show={show}
                onHide={handleClose}
                animation={true}
                centered={true}
                className=""
                dialogClassName="modal-view"
              >
                <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                    <h6>
                    Add Child
                    </h6>

                    <button className="hidden-btn" onClick={handleClose}>
                      <img
                        src={Close}
                        className="svg-icon"
                        height={20}
                        width={20}
                      />
                    </button>
                  </div>
                <Modal.Body>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Child Name"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <hr />

            {roomList ? (
              <div className="row">
                <div className="col-lg-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roomList?.map((item) => (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>
                            <button
                              className="btn btn-dark btn-lg"
                              style={{
                                color: "#fff",
                                background: "#5A5A5A",
                              }}
                              onClick={handleShowTwo}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                      {/* Modal */}
                      <Modal
                        show={showTwo}
                        onHide={handleCloseTwo}
                        animation={true}
                        centered={true}
                        dialogClassName={"primaryModal"}
                      >
                         <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                    <h6>
                    Edit Child
                    </h6>

                    <button className="hidden-btn" onClick={handleCloseTwo}>
                      <img
                        src={Close}
                        className="svg-icon"
                        height={20}
                        width={20}
                      />
                    </button>
                  </div>
                        <Modal.Body>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Your Child"
                          />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleCloseTwo}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={handleCloseTwo}>
                            Save Changes
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-4 offset-lg-4">
                  <div className="d-flex text-center p-5">
                    <i
                      className=" card p-5 fa fa-solid fa-children"
                      style={{ fontSize: "50px", opacity: "0.3" }}
                    ></i>
                  </div>
                  <p className="text-center add-btn">
                    Get started by adding a Child
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddChild;
