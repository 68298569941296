import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Util from "../../../Util";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ApiCalling from "../../../network/ApiCalling";
import ToastHOC from "../../HOC/ToastHOC";
import { documentPost, imagePost, imageStudentList } from "../../CommonImage";
import CloseIcon from "@mui/icons-material/Close";
import facedetectionicon from "../../../assets/images/loading.gif";
import * as faceapi from "face-api.js";
import { useTranslation } from "react-i18next";
import { Rings } from "react-loader-spinner";
import IconButton from "@mui/material/IconButton";
import { Avatar, Box, SwipeableDrawer } from "@mui/material";
import Search from "../../../assets/Icon/Search.svg";
import emptyCheck from "../../../assets/Icon/check-box-empty.svg";
import check from "../../../assets/Icon/check-box.svg";
import Invalid from "../../../assets/Icon/invalid.svg";
import Loader from "../../../components/common/Loader";
import ExitAlert from "../../../components/ExitAlert";
import Close from "../../../assets/Icon/close.png";

function Post(props) {
  const { t } = useTranslation();
  const getLanguage = Util.getSelectedLanguage();
  // const pendingToast = props.history.location.pendingToast;
  // const { setIsToast } = props;
  const show = props.show;
  const onClose = props.onClose;
  const onSave = props.onSave;
  const onEdit = props.onEdit;

  const [stepper, setStepper] = useState(0);
  const [isData, setIsData] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [getActivityId, setActivityId] = useState(0);

  // step 1
  const [selectedStudentId, setSelectedStudentId] = useState([]);
  const [selectedStageId, setSelectedStageId] = useState([]);
  const [selectedGradeId, setSelectedGradeId] = useState([]);
  const [selectedStudentsError, setSelectedStudentsError] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [filteredStudentData, setFilteredStudentData] = useState([]);
  const [search, setSearch] = useState("");
  const [stageData, setStageData] = useState([]);
  const [gradeList, setGradeList] = useState([]);

  // step 2
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [imageFile, setImageFile] = useState([]);
  const [documentFile, setDocumentFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);

  const [isFaceDetection, setIsFaceDetection] = useState(false);
  const [faceDetail, setFaceDetail] = useState([]);
  const [detection, setDetection] = useState([]);

  const [activityMedia, setActivityMedia] = useState([]);
  const [deletedMediaId, setDeletedMediaId] = useState([]);

  const [getMyDraft, setGetMyDraft] = useState([]);
  const [showDraftPopup, setShowDraftPopup] = useState(false);

  const [tagPhotoPopup, setTagPhotoPopup] = useState(false);
  const [selectedTempStudentsForPhoto, setSelectedTempStudentsForPhoto] =
    useState([]);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [alertModal, setIsAlertModal] = useState(false);
  const [isStateUpdated, setIsStateUpdated] = useState(false);

  const photoREF = React.useRef([]);
  const canvasREF = React.useRef();
  const history = useHistory();
  const handleAllSelectedStudent = (e) => {
    if (e.target.checked) {
      setSelectedStudentId(studentData.map((item) => item.id));
    } else {
      setSelectedStudentId([]);
    }
    setIsStateUpdated(true);
  };

  const handleRowClick = (id) => {
    if (!selectedStudentId.includes(id)) {
      setSelectedStudentId([...selectedStudentId, id]);
    } else {
      const data = selectedStudentId.filter((s) => s !== id);
      setSelectedStudentId(data);
    }
    setSelectedStudentsError("");
    setIsStateUpdated(true);
  };

  const handleAllSelectedStage = (e) => {
    if (e.target.checked) {
      setSelectedStageId(stageData.map((item) => item.id));
    } else {
      setSelectedStageId([]);
    }
    setIsStateUpdated(true);
  };

  const handleStageRowClick = (id) => {
    if (!selectedStageId.includes(id)) {
      setSelectedStageId([...selectedStageId, id]);
    } else {
      const data = selectedStageId.filter((s) => s !== id);
      setSelectedStageId(data);
    }
    setSelectedStudentsError("");
    setIsStateUpdated(true);
  };
  const handleAllSelectedGrade = (e) => {
    if (e.target.checked) {
      setSelectedGradeId(gradeList.map((item) => item.id));
    } else {
      setSelectedGradeId([]);
    }
    setIsStateUpdated(true);
  };

  const handleGradeRowClick = (id) => {
    if (!selectedGradeId.includes(id)) {
      setSelectedGradeId([...selectedGradeId, id]);
    } else {
      const data = selectedGradeId.filter((s) => s !== id);
      setSelectedGradeId(data);
    }
    setSelectedStudentsError("");
    setIsStateUpdated(true);
  };
  const handleImageFile = (e) => {
    setIsStateUpdated(true);
    const files = e.target.files;
    const newImageFiles = [...imageFile];
    for (let i = 0; i < files.length; i++) {
      let imgFile = {};
      imgFile.file = files[i];
      imgFile.students = [];
      newImageFiles.push(imgFile);
    }
    setImageFile(newImageFiles);
    e.target.value = null;
  };
  const handleDocumentFile = (e) => {
    setDocumentFile(e.target.files[0]);
    setIsStateUpdated(true);
  };
  const handleVideoFile = (e) => {
    setVideoFile(e.target.files[0]);
    setIsStateUpdated(true);
  };
  const handleRemoveImage = (indexToRemove) => {
    setIsStateUpdated(true);
    const newImageFiles = [...imageFile];
    newImageFiles.splice(indexToRemove, 1);
    setImageFile(newImageFiles);
    setFaceDetail([]);
  };
  const handlePhotoSelection = (e) => {
    const tmp = imageFile[selectedPhotoIndex];
    tmp.students = selectedTempStudentsForPhoto;
    const newImageFiles = [...imageFile];
    newImageFiles.splice(selectedPhotoIndex, 1, tmp);
    setImageFile(newImageFiles);
    setTagPhotoPopup(false);
    if (tmp.students.length > 0) {
      tmp.students.map((val) => {
        if (val.id) {
          setSelectedStudentId([...selectedStudentId, val.id]);
        }
      });
    }
  };

  const handleRemoveDocument = (indexToRemove) => {
    // const newDocFiles = [...documentFile];
    // newDocFiles.splice(indexToRemove, 1);
    // setDocumentFile(newDocFiles);
    setDocumentFile(null);
    setIsStateUpdated(true);
  };
  const handleRemoveVideo = (indexToRemove) => {
    setVideoFile(null);
    setIsStateUpdated(true);
  };
  const handleImage = async () => {
    const detections = await faceapi
      .detectAllFaces(photoREF.current, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
      .withFaceDescriptors();
    setIsFaceDetection(false);
    setDetection(detections);
    if (detections !== undefined) {
      let temp = [];
      detections.map((det) => {
        temp.push(det.descriptor.toString());
      });
      setFaceDetail(temp);
      // console.log("detections", detections);

      // faceapi.matchDimensions(canvasREF.current, {
      //   width: 310,
      //   height: 200,
      // });
      // const resized = faceapi.resizeResults(detections, {
      //   width: 310,
      //   height: 200,
      // });
      // faceapi.draw.drawDetections(canvasREF.current, resized);
    }
  };
  useEffect(() => {
    if (imageFile.length !== 0) {
      setIsFaceDetection(true);
      const loadModels = () => {
        Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
          faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
          faceapi.nets.faceExpressionNet.loadFromUri("/models"),
        ]).then((values) => {
          handleImage();
        });
      };
      photoREF && loadModels();
    }
  }, [imageFile]);

  const studentFaceDetect = () => {
    ApiCalling.apiCallBodyDataPost("student/detectStudent", faceDetail)
      .then((res) => {
        // console.log("Detect face::;", res.data);
        if (res.data.length > 0) {
          res.data
            .filter((data) => data !== null)
            .map((data) => {
              setSelectedStudentId([...selectedStudentId, data.id]);

              // setSelectedStudents([...selectedStudents, data]);
            });
          if (photoREF.current) {
            faceapi.matchDimensions(photoREF.current, {
              width: 310,
              height: 200,
            });
          }
          res.data.map((val, index) => {
            if (val !== null) {
              const drawOptions = {
                label: val.first_name,
                lineWidth: 2,
              };
              const drawBox = new faceapi.draw.DrawBox(
                detection[index].detection._box,
                drawOptions
              );
              drawBox.draw(photoREF.current);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (Util.isValidData(faceDetail) && faceDetail.length > 0) {
      studentFaceDetect();
    }
  }, [faceDetail]);

  const reset = () => {
    setIsData("");
    setStepper(0);
    setSelectedStageId([]);
    setSelectedGradeId([]);
    setSelectedStudentId([]);
    setDescription("");
    setImageFile([]);
    setVideoFile(null);
    setDocumentFile(null);
    setSelectedStudentsError("");
    setDescriptionError("");
    setIsFaceDetection(false);
    setDetection([]);
    setFaceDetail([]);
    setDeletedMediaId([]);
    setActivityMedia([]);
    setSearch("");
  };
  const handleSave = (draft, e) => {
    setIsStateUpdated(false);
    e.preventDefault();
    setDescriptionError("");
    let error = false;

    if (description == "") {
      setDescriptionError(t("content is required"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const selectedStudentIds = selectedStudentId.join(",");
      const selectedGradeIds = selectedGradeId.join(",");
      const selectedStageIds = selectedStageId.join(",");
      if (getActivityId > 0) {
        const formData = new FormData();
        formData.append("id", getActivityId);
        formData.append("description", description);
        formData.append("is_draft", draft);
        formData.append("is_global", 1);
        formData.append("student_ids", selectedStudentIds);
        formData.append("stage_ids", selectedStageIds);
        formData.append("grade_ids", selectedGradeIds);
        for (let i = 0; i < imageFile.length; i++) {
          formData.append("activity", imageFile[i].file);
        }
        if (documentFile) {
          formData.append("activity", documentFile);
        }

        if (videoFile) {
          formData.append("activity", videoFile);
        }

        formData.append("deleted_mediaIds", deletedMediaId.toString());

        ApiCalling.apiCallBodyDataPost("activity/update-post", formData)
          .then((res) => {
            // console.log("update Post", res.data);
            if (res.data.status === true) {
              setIsLoading(false);
              onEdit(t("Post Modified Successfully"));
              reset();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const formData = new FormData();
        formData.append("description", description);
        formData.append("is_draft", draft);
        formData.append("is_global", 1);
        formData.append("student_ids", selectedStudentIds);
        formData.append("stage_ids", selectedStageIds);
        formData.append("grade_ids", selectedGradeIds);
        for (let i = 0; i < imageFile.length; i++) {
          formData.append("activity", imageFile[i].file);
        }
        if (documentFile) {
          formData.append("activity", documentFile);
        }

        if (videoFile) {
          formData.append("activity", videoFile);
        }

        ApiCalling.apiCallBodyDataPost("activity/add-post", formData)
          .then((res) => {
            // console.log("Save Post", res);

            if (res.data.success === false) {
              setIsLoading(false);
              onSave(t("video file must be less then 10MB"));
            } else {
              if (res.data.status === true) {
                setIsLoading(false);
                onSave(t("Post Created Successfully"));
                // window.location.reload();
                // onClose();
                reset();
              } else {
                setDescriptionError(res.data.message);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const stageList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("stage/list")
      .then((res) => {
        setIsLoading(false);
        setStageData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGradeList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("grade/list")
      .then((res) => {
        setIsLoading(false);
        setGradeList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStudent = async () => {
    setIsLoading(true);

    ApiCalling.apiCallBodyDataGet(`student/all?str=${search}`).then((res) => {
      setIsLoading(false);
      setStudentData(res.data);
      setFilteredStudentData(res.data);
    });
  };
  const getDraftActivity = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`activity/getMyDraftedActivities`)
      .then((res) => {
        setIsLoading(false);
        setGetMyDraft(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getActivityDetails = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`activity/getById/${getActivityId}`).then(
      (res) => {
        // console.log("GetDetails", res.data);
        if (res.data) {
          setIsLoading(false);
          if (
            res.data.ActivityStudents &&
            res.data.ActivityStudents.length > 0
          ) {
            let stu = [],
              sta = [],
              gra = [];
            res.data.ActivityStudents.map((val) => {
              if (val.Student) {
                stu.push(val.Student.id);
              } else if (val.Grade) {
                gra.push(val.Grade.id);
              } else if (val.Stage) {
                sta.push(val.Stage.id);
              }
            });
            if (gra.length > 0) {
              setIsData("grade");
            } else if (sta.length > 0) {
              setIsData("level");
            } else if (stu.length > 0) {
              setIsData("student");
            }
            setSelectedStudentId(stu);
            setSelectedGradeId(gra);
            setSelectedStageId(sta);
          }
          setDescription(res.data.description);
          setActivityMedia(res.data.ActivityMedia);
        }
      }
    );
  };
  useEffect(() => {
    if (getActivityId > 0) {
      setStepper(1);
      getActivityDetails();
    } else {
      setStepper(0);
      reset();
    }
  }, [getActivityId]);
  useEffect(() => {
    getStudent();
    stageList();
    getGradeList();
    getDraftActivity();
  }, []);

  useEffect(() => {
    setActivityId(props.activityId);
  }, [props]);

  function getIconClass(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "xls":
        return "fa fa-file-excel-o text-success";
      case "xlsx":
        return "fa fa-file-excel-o text-success";
      case "pdf":
        return "fa fa-file-pdf-o text-danger";
      case "doc":
        return "fa fa-file-word-o text-primary";
      case "docx":
        return "fa fa-file-word-o text-primary";
      default:
        return "fa fa-file text-info";
    }
  }

  const Category = [
    { value: "grade", name: "Grade" },
    { value: "level", name: "Level" },
    { value: "student", name: "Student" },
  ];
  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={show}
        onClose={() => {
          if (getActivityId > 0) {
            reset();
          }
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            onClose();
            setActivityId(0);
            reset();
            setIsStateUpdated(false);
          }
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <Box sx={{ width: "656px", padding: "20px" }} role="presentation">
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">{t("Add Post")}</div>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  if (getActivityId > 0) {
                    reset();
                    // setStepper(1);
                  }
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    onClose();
                    setActivityId(0);
                    reset();
                    setIsStateUpdated(false);
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <hr style={{ backgroundColor: "#E5E7EB" }} />
            <div className="body mt-3">
              <div className="row mt-2">
                <div className="d-flex justify-content-between align-items-center mb-1 large-bold-text">
                  <div>
                    {stepper == 0 ? (
                      t("Select Tag")
                    ) : stepper == 1 ? (
                      <>{`${t("Select")} ${isData}`}</>
                    ) : (
                      t("Post Details")
                    )}
                  </div>
                  <div>{stepper == 0 ? 1 : stepper == 1 ? 2 : 3}/3</div>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <div className="active-stepper"></div>
                  <div
                    className={
                      stepper == 1 || stepper == 2
                        ? "active-stepper"
                        : "inactive-stepper"
                    }
                  ></div>
                  <div
                    className={
                      stepper == 2 ? "active-stepper" : "inactive-stepper"
                    }
                  ></div>
                </div>
                {stepper == 0 ? (
                  <>
                    <div className="input-filed mt-3 text-start ">
                      <span className="">{t("Whom you want to Tag")}?</span>
                      <div className="row mt-2  ">
                        {Category.map((c, i) => {
                          return (
                            <div key={i} className=" mb-2 mt-2 ">
                              <button
                                className="primary-xl-btn btn "
                                onClick={() => {
                                  setIsData(c.value);
                                  setStepper((prv) => prv + 1);
                                }}
                              >
                                {c.name}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                ) : stepper == 1 ? (
                  <>
                    {!isLoading ? (
                      <div className="mt-3">
                        {isData === "student" && (
                          <>
                            <div className="drawer-sub-header">
                              {t("Students")}
                            </div>
                            <div className="input-group">
                              <input
                                className="form-control search"
                                placeholder={t("Search students")}
                                type="text"
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                  const searchValue = e.target.value;
                                  if (searchValue.trim() === "") {
                                    setFilteredStudentData(studentData);
                                  } else {
                                    const filteredStudent = studentData.filter(
                                      (s) =>
                                        s.first_name
                                          .toLowerCase()
                                          .includes(searchValue.toLowerCase())
                                    );
                                    setFilteredStudentData(filteredStudent);
                                  }
                                }}
                              />
                              <div className="input-group-prepend">
                                <div
                                  className="search-icon"
                                  style={{ paddingRight: "13px" }}
                                >
                                  <img
                                    src={Search}
                                    className="svg-icon"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="post-table-container mt-3">
                              <div className="table-wrapper">
                                <table className="table">
                                  <thead>
                                    <tr className="text-nowrap">
                                      <td
                                        scope="col"
                                        className="check-col"
                                        style={{
                                          paddingRight: "0px",
                                          width: 0,
                                        }}
                                      >
                                        <label className="fancy-checkbox element-left">
                                          <input
                                            type="checkbox"
                                            name="allselect"
                                            onChange={handleAllSelectedStudent}
                                            checked={
                                              selectedStudentId.length > 0 &&
                                              selectedStudentId.length ===
                                                filteredStudentData.length
                                            }
                                          />
                                          <span></span>
                                        </label>
                                      </td>
                                      <th scope="col">
                                        {selectedStudentId.length > 0
                                          ? selectedStudentId.length +
                                            " " +
                                            t("Student Selected")
                                          : t("Student")}
                                      </th>
                                    </tr>
                                  </thead>
                                  {filteredStudentData.length > 0 ? (
                                    <tbody>
                                      {filteredStudentData
                                        .sort((a, b) =>
                                          selectedStudentId.includes(a.id)
                                            ? -1
                                            : selectedStudentId.includes(b.id)
                                            ? 1
                                            : 0
                                        )
                                        .map((item) => {
                                          return (
                                            <tr
                                              key={item.id}
                                              style={{
                                                backgroundColor: `${
                                                  selectedStudentId.includes(
                                                    item.id
                                                  )
                                                    ? "rgba(100, 24, 195, 0.04)"
                                                    : ""
                                                }`,
                                              }}
                                              onClick={() =>
                                                handleRowClick(item.id)
                                              }
                                            >
                                              <td
                                                style={{
                                                  cursor: "pointer",
                                                  paddingRight: "0px",
                                                  width: 0,
                                                }}
                                              >
                                                <img
                                                  src={
                                                    selectedStudentId.includes(
                                                      item.id
                                                    )
                                                      ? check
                                                      : emptyCheck
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <div className="d-flex align-items-center">
                                                  {item.photo ? (
                                                    <img
                                                      className="rounded-circle "
                                                      style={{
                                                        width: "40px",
                                                        height: "40px",
                                                      }}
                                                      src={
                                                        imageStudentList +
                                                        item.photo
                                                      }
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <Avatar
                                                      aria-label="recipe"
                                                      className="avatar-font"
                                                    >
                                                      {item.first_name.toUpperCase()[0] +
                                                        item.last_name.toUpperCase()[0]}
                                                    </Avatar>
                                                  )}
                                                  <a
                                                    className="ml-2"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    // onClick={(e) => {
                                                    //   e.preventDefault();
                                                    //   history.push({
                                                    //     pathname: "/profileTabs",
                                                    //     studentId: item.id,
                                                    //   });
                                                    // }}
                                                  >
                                                    {item.first_name}{" "}
                                                    {item.last_name}
                                                  </a>
                                                </div>
                                              </td>
                                              <td></td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  ) : (
                                    <tfoot>
                                      <tr>
                                        <td colSpan={2}>
                                          {t("Student Not Found")}
                                        </td>
                                      </tr>
                                    </tfoot>
                                  )}
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                        {isData === "level" && (
                          <>
                            <div className="drawer-sub-header">
                              {t("Level")}
                            </div>
                            <div className="post-table-container">
                              <div className="table-wrapper">
                                <table className="table">
                                  <thead>
                                    <tr className="text-nowrap">
                                      <td
                                        scope="col"
                                        className="check-col"
                                        style={{
                                          cursor: "pointer",
                                          paddingRight: "0px",
                                          width: 0,
                                        }}
                                      >
                                        <label className="fancy-checkbox element-left">
                                          <input
                                            type="checkbox"
                                            name="allselect"
                                            onChange={handleAllSelectedStage}
                                            checked={
                                              selectedStageId.length > 0 &&
                                              selectedStageId.length ===
                                                stageData.length
                                            }
                                          />
                                          <span></span>
                                        </label>
                                      </td>
                                      <th scope="col">
                                        {selectedStageId.length > 0
                                          ? selectedStageId.length +
                                            " " +
                                            t("Level Selected")
                                          : t("Level")}
                                      </th>
                                    </tr>
                                  </thead>
                                  {stageData.length > 0 ? (
                                    <tbody>
                                      {stageData
                                        .sort((a, b) =>
                                          selectedStageId.includes(a.id)
                                            ? -1
                                            : selectedStageId.includes(b.id)
                                            ? 1
                                            : 0
                                        )
                                        .map((item) => {
                                          return (
                                            <tr
                                              key={item.id}
                                              style={{
                                                backgroundColor: `${
                                                  selectedStageId.includes(
                                                    item.id
                                                  )
                                                    ? "rgba(100, 24, 195, 0.04)"
                                                    : ""
                                                }`,
                                              }}
                                              onClick={() =>
                                                handleStageRowClick(item.id)
                                              }
                                            >
                                              <td
                                                style={{
                                                  cursor: "pointer",
                                                  paddingRight: "0px",
                                                  width: 0,
                                                }}
                                              >
                                                <img
                                                  src={
                                                    selectedStageId.includes(
                                                      item.id
                                                    )
                                                      ? check
                                                      : emptyCheck
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <div className="d-flex align-items-center">
                                                  <a
                                                    className="ml-2"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {item.stage}
                                                  </a>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  ) : (
                                    <tfoot>
                                      <tr>
                                        <td colSpan={2}>
                                          {t("Level Not Found")}
                                        </td>
                                      </tr>
                                    </tfoot>
                                  )}
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                        {isData === "grade" && (
                          <>
                            <div className="drawer-sub-header">
                              {t("Grade")}
                            </div>
                            <div className="post-table-container">
                              <div className="table-wrapper">
                                <table className="table">
                                  <thead>
                                    <tr className="text-nowrap">
                                      <td
                                        scope="col"
                                        className="check-col"
                                        style={{
                                          cursor: "pointer",
                                          paddingRight: "0px",
                                          width: 0,
                                        }}
                                      >
                                        <label className="fancy-checkbox element-left">
                                          <input
                                            type="checkbox"
                                            name="allselect"
                                            onChange={handleAllSelectedGrade}
                                            checked={
                                              selectedGradeId.length > 0 &&
                                              selectedGradeId.length ===
                                                gradeList.length
                                            }
                                          />
                                          <span></span>
                                        </label>
                                      </td>
                                      <th scope="col">
                                        {selectedGradeId.length > 0
                                          ? selectedGradeId.length +
                                            " " +
                                            t("Grade Selected")
                                          : t("Grade")}
                                      </th>
                                    </tr>
                                  </thead>
                                  {gradeList.length > 0 ? (
                                    <tbody>
                                      {gradeList
                                        .sort((a, b) =>
                                          selectedGradeId.includes(a.id)
                                            ? -1
                                            : selectedGradeId.includes(b.id)
                                            ? 1
                                            : 0
                                        )
                                        .map((item) => {
                                          return (
                                            <tr
                                              key={item.id}
                                              style={{
                                                backgroundColor: `${
                                                  selectedGradeId.includes(
                                                    item.id
                                                  )
                                                    ? "rgba(100, 24, 195, 0.04)"
                                                    : ""
                                                }`,
                                              }}
                                              onClick={() =>
                                                handleGradeRowClick(item.id)
                                              }
                                            >
                                              <td
                                                style={{
                                                  cursor: "pointer",
                                                  paddingRight: "0px",
                                                  width: 0,
                                                }}
                                              >
                                                <img
                                                  src={
                                                    selectedGradeId.includes(
                                                      item.id
                                                    )
                                                      ? check
                                                      : emptyCheck
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <div className="d-flex align-items-center">
                                                  <a
                                                    className="ml-2"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    // onClick={(e) => {
                                                    //   e.preventDefault();
                                                    //   history.push({
                                                    //     pathname: "/profileTabs",
                                                    //     studentId: item.id,
                                                    //   });
                                                    // }}
                                                  >
                                                    {item.title}
                                                  </a>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  ) : (
                                    <tfoot>
                                      <tr>
                                        <td colSpan={2}>
                                          {t("Grade Not Found")}
                                        </td>
                                      </tr>
                                    </tfoot>
                                  )}
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <Loader />
                    )}
                  </>
                ) : (
                  <>
                    {!isLoading ? (
                      <>
                        <div className="input-field text-start mt-3 mx-1">
                          <span className="basic-text px-0 required">
                            {t("Description")}
                          </span>
                          <textarea
                            className={`text-area auto-height ${
                              descriptionError.length > 0 ? "input-error" : " "
                            }`}
                            placeholder={t("Write")}
                            rows="4"
                            cols={4}
                            value={description}
                            onChange={(e) => {
                              setDescription(e.target.value);
                              setDescriptionError("");
                              setIsStateUpdated(true);
                            }}
                          ></textarea>
                          {descriptionError.length > 0 && (
                            <div className="invalid-error ">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {descriptionError}
                            </div>
                          )}
                        </div>
                        <div className="row">
                          {imageFile.map((file, index) => {
                            return (
                              <div key={index} className="col-6 text-center">
                                {isFaceDetection && (
                                  <img
                                    key={index}
                                    style={{
                                      zIndex: "1001",
                                      position: "absolute",
                                      height: "170px",
                                    }}
                                    src={facedetectionicon}
                                  />
                                )}

                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                  className="m-2"
                                >
                                  <div style={{ position: "relative" }}>
                                    <img
                                      crossOrigin="anonymous"
                                      style={{
                                        position: "relative",
                                        width: "310px",
                                        height: "200px",
                                      }}
                                      ref={photoREF}
                                      src={URL.createObjectURL(file.file)}
                                    />
                                    <a
                                      style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                      }}
                                      onClick={() => handleRemoveImage(index)}
                                    >
                                      <button className="btn btn-link">
                                        <CloseIcon />
                                      </button>
                                    </a>

                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: 0,
                                        left: 0,
                                        background: "rgba(0, 0, 0, 0.5)",
                                        color: "#fff",
                                        padding: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setSelectedPhotoIndex(index);
                                        setSelectedTempStudentsForPhoto(
                                          imageFile[index].students
                                        );
                                        setTagPhotoPopup(true);
                                      }}
                                    >
                                      <i
                                        className="fa fa-pencil fa-fw"
                                        aria-hidden="true"
                                      ></i>
                                      {t("Tag Photo")}{" "}
                                      {file.students.length > 0 ? (
                                        <>{`(${file.students.length})`}</>
                                      ) : null}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          {documentFile !== null && (
                            <div className="col-lg-6 col-md-6 col-sm-12 mt-4 ">
                              <div className="card p-2 mb-0">
                                <div className="file">
                                  <a>
                                    <div className="hover">
                                      <button
                                        type="button"
                                        className="btn btn-icon btn-danger float-right"
                                        onClick={handleRemoveDocument}
                                      >
                                        <i className="fa fa-trash-o"></i>
                                      </button>
                                    </div>

                                    <div className="icon">
                                      <i
                                        className={getIconClass(
                                          documentFile.name
                                        )}
                                      ></i>
                                    </div>

                                    <div className="file-name">
                                      <p className="m-b-5 text-muted">
                                        {documentFile.name}
                                      </p>
                                      <small>
                                        {t("Size")}: {documentFile.size}{" "}
                                      </small>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}

                          {videoFile !== null && (
                            <div className="col-6 text-center m-1">
                              <div className="d-flex ">
                                <video
                                  src={URL.createObjectURL(videoFile)}
                                  style={{ maxHeight: "250px" }}
                                  controls
                                  className="p-3"
                                >
                                  <source
                                    src={URL.createObjectURL(videoFile)}
                                    type="video/*"
                                  />
                                </video>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={handleRemoveVideo}
                                >
                                  <CloseIcon />
                                </a>
                              </div>
                            </div>
                          )}

                          {activityMedia.map((i, index) => {
                            const isImage = i.name.match(
                              /\.(jpg|jpeg|png|gif)$/i
                            );
                            const isVideo = i.name.match(/\.(mp4|mkv)$/i);
                            return (
                              <>
                                {isImage ? (
                                  <>
                                    <div className="col-6 col-md-6" key={index}>
                                      <div className="d-flex">
                                        <img
                                          src={imagePost + i.name}
                                          style={{ maxHeight: "150px" }}
                                          className="p-3 img-fluid"
                                        />
                                        <a
                                          style={{
                                            float: "right",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            if (
                                              activityMedia.some(
                                                (s) => s.id === i.id
                                              )
                                            ) {
                                              setActivityMedia(
                                                activityMedia.filter(
                                                  (s) => s.id !== i.id
                                                )
                                              );
                                            }
                                            let c = [...deletedMediaId];
                                            c.push(i.id);
                                            setDeletedMediaId(c);
                                          }}
                                        >
                                          <CloseIcon />
                                        </a>
                                      </div>
                                    </div>
                                  </>
                                ) : isVideo ? (
                                  <>
                                    <div className="col-6 col-md-6" key={index}>
                                      <div className="d-flex">
                                        <video
                                          src={documentPost + i.name}
                                          style={{ maxHeight: "250px" }}
                                          controls
                                          className="p-3"
                                        >
                                          <source
                                            src={documentPost + i.name}
                                            type="video/*"
                                          />
                                        </video>
                                        <a
                                          style={{
                                            float: "right",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            if (
                                              activityMedia.some(
                                                (s) => s.id === i.id
                                              )
                                            ) {
                                              setActivityMedia(
                                                activityMedia.filter(
                                                  (s) => s.id !== i.id
                                                )
                                              );
                                            }
                                            let c = [...deletedMediaId];
                                            c.push(i.id);
                                            setDeletedMediaId(c);
                                          }}
                                        >
                                          <CloseIcon />
                                        </a>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="col-lg-6 col-md-6 col-sm-12 mt-4"
                                      key={index}
                                    >
                                      <div className="card p-2 mb-0">
                                        <div className="file">
                                          <a>
                                            <div className="hover">
                                              <button
                                                type="button"
                                                className="btn btn-icon btn-danger float-right"
                                                onClick={() => {
                                                  if (
                                                    activityMedia.some(
                                                      (s) => s.id === i.id
                                                    )
                                                  ) {
                                                    setActivityMedia(
                                                      activityMedia.filter(
                                                        (s) => s.id !== i.id
                                                      )
                                                    );
                                                  }
                                                  let c = [...deletedMediaId];
                                                  c.push(i.id);
                                                  setDeletedMediaId(c);
                                                }}
                                              >
                                                <i className="fa fa-trash-o"></i>
                                              </button>
                                            </div>

                                            <div className="icon">
                                              <i
                                                className={getIconClass(i.name)}
                                              ></i>
                                            </div>

                                            <div className="file-name">
                                              <p className="m-b-5 text-muted">
                                                {i.name}
                                              </p>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            );
                          })}
                        </div>

                        <div className="row mx-1 px-0 mt-4">
                          <div className="col">
                            <input
                              accept="image/png, image/jpg, image/jpeg"
                              multiple
                              type="file"
                              id="imgupload"
                              style={{ display: "none" }}
                              onChange={handleImageFile}
                              disabled={
                                documentFile !== null || videoFile !== null
                              }
                            />

                            <button
                              disabled={
                                documentFile !== null || videoFile !== null
                              }
                              style={{
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                                outline: "none",
                                padding: "0",
                              }}
                            >
                              <i
                                className="fa fa-picture-o fa-lg mr-2"
                                aria-hidden="true"
                                onClick={() => {
                                  document.getElementById("imgupload").click();
                                }}
                                style={{
                                  color:
                                    documentFile !== null || videoFile !== null
                                      ? "inherit"
                                      : "gray",
                                }}
                              ></i>
                            </button>

                            <input
                              accept="video/mp4 , video/mkv"
                              type="file"
                              id="videoUpload"
                              style={{ display: "none" }}
                              onChange={handleVideoFile}
                              disabled={
                                imageFile.length !== 0 || documentFile !== null
                              }
                            />

                            <button
                              disabled={
                                imageFile.length !== 0 || documentFile !== null
                              }
                              style={{
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                                outline: "none",
                                padding: "0",
                              }}
                            >
                              <i
                                className="fa fa-file-video-o fa-lg mx-2"
                                aria-hidden="true"
                                onClick={() => {
                                  // document.getElementById("videoUpload").click();
                                  const fileInput =
                                    document.getElementById("videoUpload");
                                  fileInput.value = null;
                                  fileInput.removeEventListener(
                                    "change",
                                    handleVideoFile
                                  );
                                  fileInput.addEventListener(
                                    "change",
                                    handleVideoFile
                                  );
                                  fileInput.click();
                                }}
                                style={{
                                  color:
                                    imageFile.length !== 0 &&
                                    documentFile !== null
                                      ? "inherit"
                                      : "gray",
                                }}
                              ></i>
                            </button>

                            <input
                              accept=".xlsx, .xls, .pdf ,.doc, .docx"
                              type="file"
                              id="docUpload"
                              style={{ display: "none" }}
                              onChange={handleDocumentFile}
                              disabled={
                                imageFile.length !== 0 || videoFile !== null
                              }
                            />

                            <button
                              disabled={
                                imageFile.length !== 0 || videoFile !== null
                              }
                              style={{
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                                outline: "none",
                                padding: "0",
                              }}
                            >
                              <i
                                className="fa fa-file-o fa-lg ml-2"
                                aria-hidden="true"
                                onClick={() => {
                                  // document.getElementById("docUpload").click();
                                  const fileInput =
                                    document.getElementById("docUpload");
                                  fileInput.value = null;
                                  fileInput.removeEventListener(
                                    "change",
                                    handleDocumentFile
                                  );
                                  fileInput.addEventListener(
                                    "change",
                                    handleDocumentFile
                                  );
                                  fileInput.click();
                                }}
                                style={{
                                  color:
                                    imageFile.length !== 0 || videoFile !== null
                                      ? "inherit"
                                      : "gray",
                                }}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </>
                )}
              </div>
            </div>

            <div
              className="border-top "
              style={{
                position: "fixed",
                width: "656px",
                bottom: "0px",
                right: getLanguage !== "ar" && "0px",
                background: "#ffffff",
                padding: "10px",
              }}
            >
              {stepper == 0 ? (
                <button
                  className="secondary-md-btn btn ml-2"
                  onClick={() => {
                    onClose();
                    // reset();
                  }}
                >
                  {t("Cancel")}
                </button>
              ) : (
                <button
                  className="secondary-md-btn btn mr-2"
                  onClick={() => {
                    setStepper((prv) => prv - 1);
                    setSelectedStudentsError("");
                  }}
                >
                  {t("Previous")}
                </button>
              )}

              {stepper == 0 ? (
                <>
                  {/* {getActivityId == 0 && getMyDraft?.length > 0 && (
                    <button
                      style={{ float: "right" }}
                      className="primary-lg-btn btn mr-1"
                      onClick={() => {
                        setShowDraftPopup(true);
                      }}
                    >
                      Get Draft {`(${getMyDraft.length})`}
                    </button>
                  )} */}
                </>
              ) : stepper == 1 ? (
                <>
                  <button
                    style={{ float: "right" }}
                    className="primary-lg-btn btn"
                    onClick={() => {
                      if (
                        selectedStudentId.length > 0 ||
                        selectedGradeId.length > 0 ||
                        selectedStageId.length > 0
                      ) {
                        setStepper((prv) => prv + 1);
                      } else {
                        setSelectedStudentsError(
                          `${t("Please select")} ${isData}!`
                        );
                      }
                    }}
                  >
                    {t("Continue")}
                  </button>
                  {selectedStudentsError.length > 0 && (
                    <span
                      className="text-danger"
                      style={{ float: "right", margin: "12px" }}
                    >
                      {selectedStudentsError}
                    </span>
                  )}
                </>
              ) : (
                <>
                  <button
                    //className="btn cbtn btn-outline-secondary"
                    style={{ float: "right" }}
                    className="btn primary-xl-btn"
                    type="button"
                    onClick={(e) => {
                      handleSave("false", e);
                    }}
                  >
                    {t("Submit Post")}
                  </button>
                </>
                // <Dropdown
                //   style={{ float: "right", height: "48px", width: "120px" }}
                //   className="input-group-append dropdown"
                // >
                //   <button
                //     //className="btn cbtn btn-outline-secondary"
                //     className="btn custom-primary"
                //     type="button"
                //     onClick={(e) => {
                //       handleSave("false", e);
                //     }}
                //   >
                //     Post
                //   </button>
                //   <Dropdown.Toggle
                //     variant="success"
                //     as="div"
                //     id="dropdown-basic"
                //   >
                //     <button
                //       aria-haspopup="true"
                //       className=" btn primary-post-btn dropdown-toggle-split dropdown-toggle"
                //       ngbdropdowntoggle=""
                //       type="button"
                //       aria-expanded="false"
                //     >
                //       <i
                //         className="fa fa-chevron-down"
                //         aria-hidden="true"
                //         color="white"
                //       ></i>
                //     </button>
                //   </Dropdown.Toggle>

                //   <Dropdown.Menu as="div">
                //     <div
                //       className="dropdown-item"
                //       onClick={(e) => {
                //         handleSave("true", e);
                //       }}
                //     >
                //       Save as draft
                //     </div>
                //     {Util.isValidData(getActivityId) &&
                //       getMyDraft?.length > 0 && (
                //         <div
                //           className="dropdown-item"
                //           onClick={() => {
                //             handleDelete(getActivityId);
                //           }}
                //         >
                //           Delete
                //         </div>
                //       )}
                //   </Dropdown.Menu>
                // </Dropdown>
              )}
            </div>
          </Box>
        </div>
      </SwipeableDrawer>
      <Modal
        show={tagPhotoPopup}
        onHide={() => setTagPhotoPopup(false)}
        size="lg"
        animation={true}
        centered={true}
        dialogClassName="modal-view"
      >
        <div className="d-flex justify-content-between align-items center p-4 border-bottom">
          <h6>{t("Tag Photo")}</h6>

          <button
            className="hidden-btn"
            onClick={() => setTagPhotoPopup(false)}
          >
            <img src={Close} className="svg-icon" height={20} width={20} />
          </button>
        </div>
        <Modal.Body>
          {/* <div className="row">
            <div className="input-group">
              <input
                className="form-control search"
                placeholder="Search students"
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  const searchValue = e.target.value;
                  if (searchValue.trim() === "") {
                    setFilteredStudentData(studentData);
                  } else {
                    const filteredStudent = studentData.filter((s) =>
                      s.first_name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    );
                    setFilteredStudentData(filteredStudent);
                  }
                }}
              />
              <div className="input-group-prepend">
                <div className="search-icon" style={{ paddingRight: "13px" }}>
                  <img src={Search} className="svg-icon" alt="" />
                </div>
              </div>
            </div>
          </div> */}
          <div className="text-muted text-start p-2">{t("Tagged")}</div>
          <div className="text-start">
            {selectedTempStudentsForPhoto.map((item) => {
              return (
                <span className="badge badge-primary" key={item.id}>
                  <i className="fa fa-user mr-1" aria-hidden="true"></i>{" "}
                  {item.first_name} {item.last_name}
                  <i
                    className="fa fa-times fa-fw"
                    style={{ cursor: "pointer" }}
                    aria-hidden="true"
                    onClick={() => {
                      if (
                        selectedTempStudentsForPhoto.some(
                          (s) => s.id === item.id
                        )
                      ) {
                        setSelectedTempStudentsForPhoto(
                          selectedTempStudentsForPhoto.filter(
                            (s) => s.id !== item.id
                          )
                        );
                      }
                    }}
                  ></i>
                </span>
              );
            })}
          </div>
          {filteredStudentData.length > 0 ? (
            <div className="row">
              {filteredStudentData
                .filter((student) => {
                  return !selectedTempStudentsForPhoto.some(
                    (selectedStudent) => selectedStudent.id === student.id
                  );
                })
                .map((student) => {
                  return (
                    <div
                      key={student.id}
                      className="col-4"
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        className="btn btn-link d-flex align-items-center gap-3"
                        onClick={() => {
                          setSelectedTempStudentsForPhoto([
                            ...selectedTempStudentsForPhoto,
                            student,
                          ]);
                        }}
                      >
                        <input
                          onChange={() => {}}
                          type="checkbox"
                          checked={selectedTempStudentsForPhoto.includes(
                            student.id
                          )}
                        />
                        <span></span>
                        <strong>
                          {student.first_name} {student.last_name}
                        </strong>
                      </a>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="row">
              <div className="text-center text-muted p-2">
                {t("Student Not Found")}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn custom-primary" onClick={handlePhotoSelection}>
            {t("Done")}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDraftPopup}
        onHide={() => {
          setShowDraftPopup(false);
        }}
        size="md"
        animation={true}
        centered={true}
        dialogClassName="modal-view"
      >
        <div className="d-flex justify-content-between align-items center p-4 border-bottom">
          <h6>{t("Choose Draft")}</h6>

          <button
            className="hidden-btn"
            onClick={() => {
              setShowDraftPopup(false);
            }}
          >
            <img src={Close} className="svg-icon" height={20} width={20} />
          </button>
        </div>
        <Modal.Body>
          <div>
            {getMyDraft.map((draft, index) => {
              return (
                <div key={index}>
                  <div className="d-flex gap-2 align-items-center">
                    <div>{index + 1}.</div>
                    <div className="card mb-0">
                      <div
                        className="card-header h6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowDraftPopup(false);
                          setActivityId(draft.id);

                          // history.push({
                          //   pathname: "/Post",
                          //   activityId: draft.id,
                          // });
                        }}
                      >
                        {draft.description}
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
      <ExitAlert
        visible={alertModal}
        onClose={() => {
          setIsAlertModal(false);
          setIsStateUpdated(false);
        }}
        onOk={() => {
          setIsAlertModal(false);
          setIsStateUpdated(false);
          onClose();
          reset();
        }}
      />
    </div>
  );
}
export default ToastHOC(Post);
