import React, { useEffect, useState } from "react";
import ReportingVerticalTabs from "./ReportingVerticalTabs";
import { useTranslation } from "react-i18next";
import SummaryReport from "./DailyAttendanceSummryReport/SummaryReport";
import CheckInReport from "./CheckInReport/CheckInReport";
import AgeReport from "./AgeReport/AgeReport";
import ActivityReport from "./ActivityReport/ActivityReport";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import StudentReport from "./StudentReport/StudentReport";
import ToastHOC from "../../HOC/ToastHOC";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, m: 1 }}>
          <Typography style={{ fontFamily: "Public Sans" }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

 function Reporting(props) {
  const { setIsToast } = props;
  const pendingToast = props.location.pendingToast;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (pendingToast) {
      setIsToast(pendingToast);
    }
  }, []);
  return (
    <div className="container-fluid">
      <div className="body mx-4">
      <div className="row ">
          <div className="col-lg">
            <h2 className="page-head mt-3 position-fixed">{t("Reporting")}</h2>
          </div>
        </div>
      <div className="row">
        <div className=" col-lg-2 col-md-3 tablet-specific-col  p-0 m-0">
          
          {/* <div className="left-vr-tab "> */}
            <ReportingVerticalTabs
              handleChange={handleChange}
              value={value}
              setValue={setValue}
              a11yProps={a11yProps}
              TabPanel={TabPanel}
            />
          {/* </div> */}
        </div>
        <div className=" col-lg-9 p-0 m-0 offset-lg-1 col-md-9 tablet-specific">
         
            <TabPanel value={value} index={0}>
              <SummaryReport />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CheckInReport />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AgeReport />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ActivityReport />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <StudentReport />
            </TabPanel>
          
        </div>
      </div>
      </div>
    </div>
  );
}
export default ToastHOC(Reporting);