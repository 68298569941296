import React, { useEffect, useState } from "react";

import { t } from "i18next";

import ToastHOC from "../../HOC/ToastHOC";
import { Modal, Button } from "react-bootstrap";

import Invalid from "../../../assets/Icon/invalid.svg";

import Sort from "../../../assets/Icon/Short.svg";

import { close } from "../../Admission/Admission";
import Switch from "@mui/material/Switch";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Const from "../../../Const";
import Util from "../../../Util";
import Loader from "../../../components/common/Loader";
import ModalComponent from "../ModalComponent";
import ApiCalling from "../../../network/ApiCalling";

const AssignmentCategory = (props) => {
  const { setIsToast } = props;
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");

  const [assignmentList, setAssignmentList] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [isNotificationSend, setIsNotificationSend] = useState(false);
  const [isSubjectBased, setIsSubjectBased] = useState(false);
  const [editValue, setEditValue] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [categoryErr, setCategoryErr] = useState("");

  function handleSubject(event) {
    console.log(event.target.checked);
    setIsSubjectBased(event.target.checked);
  }
  function handleSendNotification(event) {
    console.log(event.target.checked);
    setIsNotificationSend(event.target.checked);
  }

  function handleOpenModal() {
    setShow(true);
  }

  const handleAddCategory = async () => {
    setCategoryErr("");
    let error = false;

    if (!title) {
      setCategoryErr("Category name is required");
      error = true;
    }

    if (error) {
      return;
    }
    const data = {
      title: title,
      send_notification: isNotificationSend,
      subject_based: isSubjectBased,
    };
    try {
      ApiCalling.apiCallBodyDataPost("assignment-category/create/", data).then(
        (res) => {
          console.log("assignment-category", res.data);
          if (res?.data?.status) {
            setIsToast(res?.data?.message);
            handleCloseModal();
            getAssignment();
          }
        }
      );
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const handleUpdateCategory = async () => {
    const data = {
      id: editValue.id,
      title: title,
      subject_based: isSubjectBased,
      send_notification: isNotificationSend,
    };
    try {
      ApiCalling.apiCallBodyDataPost("assignment-category/update/", data).then(
        (res) => {
          if (res.data.status === true) {
            setIsLoading(false);
            setIsToast(res?.data?.message);
            setShow(false);
            getAssignment();
          } else {
            alert(res.data.message);
          }
        }
      );
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  function handleCloseModal() {
    setShow(false);
    setTitle("");
    setEditValue(null);
    setCategoryErr("");
    setIsNotificationSend(false);
    setIsSubjectBased(false);
  }

  const handleSaveAndClose = async () => {
    if (editValue) {
      await handleUpdateCategory();
    } else {
      await handleAddCategory();
    }
  };

  const handleDelete = () => {
    ApiCalling.apiCallDelete(`assignment-category/delete/${deleteId}`)
      .then((res) => {
        if (res?.data?.status === true) {
          setIsToast(res?.data?.message);
        } else if (res.data.status == false) {
          setIsToast(t("Somewhere used ,You Can't Delete"));
        }
        setShowDeleteConfirmation(false);
        getAssignment();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEdit = (item) => {
    console.log("item", item);
    setEditValue(item);
    setTitle(item?.title);

    setIsSubjectBased(item?.subject_based);
    setIsNotificationSend(item?.send_notification);

    setShow(true);
  };

  const getAssignment = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("assignment-category/list/")
      .then((res) => {
        setAssignmentList(res?.data);

        setIsLoading(false);
      })
      .catch((err) => {
        console.error("ERROR", err);
      });
  };

  useEffect(() => {
    getAssignment();
  }, []);

  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="header text-right mb-3 px-0">
              <button
                className={`${
                  Util.hasPermission("MASTERS_ADD")
                    ? "btn custom-primary"
                    : "no-permission"
                }`}
                type="button"
                aria-expanded="false"
                style={{ color: "#fff", padding: "5px, 10px" }}
                onClick={handleOpenModal}
              >
                {t("Add New Category ")}
              </button>
              <Modal
                show={show}
                onHide={handleCloseModal}
                animation={true}
                centered={true}
                dialogClassName="modal-view"
                onShow={handleOpenModal}
              >
                <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                  <h6>
                    {editValue ? t("Edit Category") : t("Add New Category")}
                  </h6>

                  <button className="hidden-btn" onClick={handleCloseModal}>
                    <img
                      src={close}
                      className="svg-icon"
                      height={20}
                      width={20}
                    />
                  </button>
                </div>
                <Modal.Body>
                  <div className="input-filed ">
                    <span className="basic-text required">
                      {t("Category Name")}
                    </span>

                    <input
                      className={`form-control  ${
                        categoryErr.length > 0 ? "input-error" : "custom-input"
                      }`}
                      type="text"
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                    />
                    {categoryErr.length > 0 && (
                      <span className=" invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {categoryErr}
                      </span>
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <SwitchButton
                      label="Subject Based"
                      value={isSubjectBased}
                      onChange={handleSubject}
                      // checked={isSubjectBased}
                    />
                    <SwitchButton
                      label="Send Notification"
                      value={isNotificationSend}
                      onChange={handleSendNotification}
                      // checked={isNotificationSend}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btn custom-primary"
                    onClick={handleSaveAndClose}
                  >
                    {t("Save")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="row clearfix">
            <div className="table-div overflow-hidden m-0 p-0">
              <div className="col-lg-12 col-md-12 p-0">
                <div className="body">
                  {!isLoading ? (
                    <>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">
                              {t("Title")}
                              {/* <img src={Sort} className="ml-1 fa-sort" alt="" /> */}
                            </th>
                            <th scope="col">
                              {t("Subject  Based")}
                              {/* <img src={Sort} className="ml-1 fa-sort" alt="" /> */}
                            </th>
                            <th scope="col">
                              {t("Send Notification")}{" "}
                              {/* <img src={Sort} className="ml-1 fa-sort" alt="" /> */}
                            </th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        {assignmentList.length > 0 ? (
                          <tbody>
                            {assignmentList.map((item, index) => (
                              <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.title}</td>
                                <td>{item?.subject_based ? "Yes" : "No"}</td>
                                <td>
                                  {item?.send_notification ? "Yes" : "No"}
                                </td>
                                <td>
                                  <a
                                    className={`${
                                      !Util.hasPermission("MASTERS_EDIT") &&
                                      "no-permission"
                                    }`}
                                  >
                                    <i
                                      className="fa fa-edit cursor"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleEdit(item);
                                      }}
                                    ></i>
                                  </a>
                                  <a
                                    className={`${
                                      Util.hasPermission("MASTERS_DELETE")
                                        ? "pl-5"
                                        : "no-permission"
                                    }`}
                                  >
                                    <i
                                      className="fa fa-trash-o text-danger cursor"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setShowDeleteConfirmation(true);
                                        setDeleteId(item.id);
                                      }}
                                    ></i>
                                  </a>
                                </td>
                                <td></td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tfoot>
                            <tr>
                              <td colSpan={4}>
                                {t(
                                  "There are no Assignment Category. Click the 'Add Assignment Category' button to create one."
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                      {assignmentList.length > 0 && (
                        <>
                          <div className="small text-muted mb-3 pl-3 border-top py-3">
                            <i>
                              {assignmentList.length}{" "}
                              {t("Assignment Category found")}.
                            </i>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
                <ModalComponent
                  show={showDeleteConfirmation}
                  onHide={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  title={t("Delete")}
                  onCancel={t("Cancel")}
                  onConfirm={t("Confirm")}
                  subTitle={t(
                    "Are you sure, to delete this Assignment Category?"
                  )}
                  size="sm"
                  onCancelClick={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  onConfirmClick={handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(AssignmentCategory);

function SwitchButton({ value, label = "label", onChange }) {
  return (
    <>
      <div
        className="form-switch  "
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "45px",
          paddingLeft: "0px",
        }}
      >
        <div>
          <span tabIndex={0}>{label}</span>
        </div>
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckChecked"
            value={value}
            checked={value}
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
}
