import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader";
import { Modal, Button } from "react-bootstrap";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import { Rings } from "react-loader-spinner";
import ToastHOC from "../../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import Sort from "../../../assets/Icon/Short.svg";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import Close from "../../../assets/Icon/close.png";
import Loader from "../../../components/common/Loader";

const PickupInfo = (props) => {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [pickupList, setPickupList] = useState([]);
  const [newName, setNewName] = useState("");
  const [nameError, setNameError] = useState("");
  const [editValue, setEditValue] = useState(null);
  const [showPickupModal, SetShowPickupModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [sortColumn, setSortColumn] = useState("Pickup");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedPickupList = [...pickupList].sort((a, b) => {
    if (sortColumn === "Pickup") {
      return sortDirection === "asc"
        ? a.title.localeCompare(b.title)
        : b.title.localeCompare(a.title);
    } else if (sortColumn === "Student opted") {
      return sortDirection === "asc"
        ? a.Students.length - b.Students.length
        : b.Students.length - a.Students.length;
    }
    return 0;
  });

  useEffect(() => {
    getData();
  }, []);
  const handleModalClose = () => {
    SetShowPickupModal(false);
    setNewName("");
    setNameError("");
  };
  const handleSaveAndClose = () => {
    setNameError("");
    let error = false;
    if (newName === "") {
      setNameError(t("Title is required"));
      error = true;
    }
    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      if (editValue !== null) {
        const data = {
          id: editValue.id,
          title: newName,
        };

        ApiCalling.apiCallBodyDataPost("pickup-info/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              getData();
              setIsToast(res.data.message);
              SetShowPickupModal(false);
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      } else {
        const data = {
          title: newName,
        };
        ApiCalling.apiCallBodyDataPost("pickup-info/add", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              getData();
              setIsToast(res.data.message);
              SetShowPickupModal(false);
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      }
    }
  };

  const handleAddModalShow = () => {
    SetShowPickupModal(true);
    setEditValue(null);
    setNewName("");
    setNameError("");
  };

  const getData = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("pickup-info/list").then((res) => {
      setIsLoading(false);
      setPickupList(res.data);
    });
  };

  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`pickup-info/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setShowDeleteConfirmation(false);
        setIsToast(t("Pickup Info Deleted Successfully"));
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div
        style={{ flex: 1 }}
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid">
            {/* <PageHeader
              HeaderText="Dashboard"
              Breadcrumb={[{ name: "Pickup Info" }]}
            /> */}
            <div className="row">
              <div className="header text-right mb-2 px-0">
                <button
                  className={`${
                    Util.hasPermission("MASTERS_ADD")
                      ? "btn custom-primary"
                      : "no-permission"
                  }`}
                  type="button"
                  aria-expanded="false"
                  style={{ color: "#fff", padding: "5px, 10px" }}
                  onClick={handleAddModalShow}
                >
                  {t("Add new Pickup Type")}
                </button>
                <Modal
                  show={showPickupModal}
                  onHide={handleModalClose}
                  animation={true}
                  centered={true}
                  dialogClassName="modal-view"
                  onShow={() => {
                    setIsClickedOnce(false);
                    if (editValue !== null) {
                      setNewName(editValue.title);
                    }
                  }}
                >
                  <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                    <h6>
                      {editValue !== null
                        ? t("Edit Pickup type")
                        : t("Add Pickup type")}
                    </h6>

                    <button className="hidden-btn" onClick={handleModalClose}>
                      <img
                        src={Close}
                        className="svg-icon"
                        height={20}
                        width={20}
                      />
                    </button>
                  </div>
                  <Modal.Body>
                    {!isLoading ? (
                      <div className="input-filed text-start">
                        <label className="basic-text required">
                          {t("Title")}
                        </label>
                        <input
                          className={`form-control ${
                            nameError.length > 0 && "input-error"
                          }`}
                          placeholder={t("Title")}
                          type="text"
                          value={newName}
                          onChange={(event) => {
                            setNewName(event.target.value);
                            setNameError("");
                          }}
                        />
                        {nameError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {nameError}
                          </span>
                        )}
                      </div>
                    ) : (
                      <Loader />
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn custom-primary"
                      onClick={handleSaveAndClose}
                    >
                      {t("Save")}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 px-0">
                <div className="table-div overflow-hidden mt-2">
                  <div className="body">
                    {!isLoading ? (
                      <>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">
                                {t("Pickup Type")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("Pickup");
                                  }}
                                />
                              </th>
                              <th scope="col">
                                {t("Student opted")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("Student opted");
                                  }}
                                />
                              </th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          {pickupList.length > 0 ? (
                            <tbody>
                              {sortedPickupList?.map((item, index) => (
                                <tr key={item.id}>
                                  <td>{index + 1}</td>
                                  <td>{item.title}</td>
                                  <td>{item.Students.length}</td>
                                  <td>
                                    <a
                                      role="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setEditValue(item);
                                        SetShowPickupModal(true);
                                      }}
                                      className={`${
                                        !Util.hasPermission("MASTERS_EDIT") &&
                                        "no-permission"
                                      }`}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </a>
                                  </td>
                                  <td>
                                    {item.Students.length === 0 ? (
                                      <a
                                        role="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setShowDeleteConfirmation(true);
                                          setDeleteId(item.id);
                                        }}
                                        className={`${
                                          Util.hasPermission("MASTERS_DELETE")
                                            ? "pl-5"
                                            : "no-permission"
                                        }`}
                                      >
                                        <i className="fa fa-trash-o text-danger"></i>
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tfoot>
                              <tr>
                                <td colSpan={5}>
                                  {t(
                                    "There are no PickupInfo. Click the 'Add new Pickup Type' button to create one."
                                  )}
                                </td>
                              </tr>
                            </tfoot>
                          )}
                        </table>
                        {pickupList.length > 0 && (
                          <div className="small text-mute mb-3 pl-3 border-top py-3">
                            <i>
                              {pickupList.length} {t("Pickup type found")}.
                            </i>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader />
                    )}
                  </div>
                  <ModalComponent
                    show={showDeleteConfirmation}
                    onHide={() => {
                      setShowDeleteConfirmation(false);
                    }}
                    title={t("Delete")}
                    onCancel={t("Cancel")}
                    onConfirm={t("Confirm")}
                    subTitle={t("Are you sure, to delete this Pickup?")}
                    size="sm"
                    onCancelClick={() => {
                      setShowDeleteConfirmation(false);
                    }}
                    onConfirmClick={handleDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default ToastHOC(PickupInfo);
