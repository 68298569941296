import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Util from "../Util";
import { imageMyProfile } from "../screens/CommonImage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LogoutOutlined } from "@mui/icons-material";
import ChevronDown from "../assets/Icon/chevron-down gray.svg";
import { useTranslation } from "react-i18next";
import ApiCalling from "../network/ApiCalling";
import { useSelector } from "react-redux";

export default function UserComponent(props) {
  const { t } = useTranslation();
  const user = Util.getUser();
  const getLanguage = Util.getSelectedLanguage();

  const history = useHistory();
  const parentMode = Util.getParentMode();

  const isUserUpdate = useSelector((state) => state.notifyUserUpdateReducer);

  const [userDetail, setUserDetail] = useState(user);

  const getUserData = () => {
    ApiCalling.apiCallBodyDataGet(`user/get-user-byId/${user.id}`)
      .then((res) => {
        setUserDetail(res.data);
        Util.saveUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isUserUpdate.isUpdate == true) {
      getUserData();
    }
  }, [isUserUpdate]);

  return (
    <div>
      {Util.isValidData(userDetail) && (
        <>
          <div className="dropdown">
            <span
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ cursor: "pointer" }}
            >
              <div className="user-account mt-1">
                <img
                  src={
                    userDetail?.avatar !== null
                      ? imageMyProfile + userDetail?.avatar
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                  }
                  className="rounded-circle user-photo"
                />
                <div>
                  <p className="mb-0">
                    {userDetail?.firstName + " " + userDetail?.lastName}
                  </p>

                  <p className="mt-0 mb-0 text-bold-secondary">
                    {parentMode
                      ? "Parent"
                      : userDetail?.user_Schools[0].role.role}
                  </p>
                  <img
                    src={ChevronDown}
                    alt=""
                    style={{
                      position: "absolute",
                      right: getLanguage !== "ar" && "-17px",
                      top: "10px",
                      cursor: "pointer",
                      height: "18px",
                      width: "18px",
                      left: getLanguage == "ar" && "-17px",
                    }}
                  />
                </div>
              </div>
            </span>
            <ul
              className="dropdown-menu user-dropdown"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <div
                className="user-dropdown-option"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/my-profile");
                  }}
                >
                  <i className="icon-user mr-2"></i>
                  {t("My Profile")}
                </div>
              </li>
              {parentMode ? (
                <>
                  {userDetail?.is_parent == true &&
                  userDetail?.is_staff == true ? (
                    <>
                      <li>
                        <div className="user-dropdown-option" onClick={props.switchToStaff}>
                          <i className="icon-login"> </i>
                          {t("Switch as Staff")}
                        </div>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {userDetail?.is_parent && (
                    <>
                      <li>
                        <div className="user-dropdown-option" onClick={props.LoginAsParent}>
                          <i className="icon-power mr-2"></i>
                          {t("Login as Parent")}
                        </div>
                      </li>
                    </>
                  )}
                </>
              )}
              <li>
                <div className="user-dropdown-option" onClick={props.onLogoutClick}>
                  <i>
                    <LogoutOutlined
                      fontSize="small"
                      className="svg-icon mr-1"
                    />
                  </i>{" "}
                  {t("Log Out")}
                </div>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
