import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import upload from "../../../assets/Icon/upload.svg";
import CustomFileUpload from "../CustomFileUpload";
import ApiCalling from "../../../network/ApiCalling";
import fileIcon from "../../../assets/Icon/file.svg";
import { studentInsurance } from "../../CommonImage";
import Loader from "../../../components/common/Loader";

const HealthDetailsCard = ({
  data,
  studentId,
  onSave,
  healthDetailsEdit,
  onCancel,
  isStudentUpdate
}) => {
  const { t } = useTranslation();

  const [insuranceNo, setInsuranceNo] = useState("");
  const [insuranceProvide, setInsuranceProvide] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [allergies, setAllergies] = useState("");
  const [medication, setMedication] = useState("");
  const [specialDietry, setSpecialDietry] = useState("");
  const [healthNotes, setHealthNotes] = useState("");
  const [insuranceUpload, setInsuranceUpload] = useState(null);
  const [insuranceURL, setInsuranceURL] = useState("");
  const [insuranceFileName, setInsuranceFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInsuranceDragOver = (e) => {
    e.preventDefault();
  };

  const handleInsuranceDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };

  const handleFileChange = (file) => {
    if (file) {
      const maxSize = 25 * 1024 * 1024;
      if (file.size <= maxSize) {
        setInsuranceUpload(file);
        setInsuranceURL(URL.createObjectURL(file));
      } else {
        onSave(t("File size exceeds the limit of 25MB"));
        
      }
    }
  };

  const handleInsuranceUpload = (e) => {
    const file = e.target.files[0];
    handleFileChange(file);
    isStudentUpdate();
  };

  useEffect(() => {
    if (data) {
      setInsuranceNo(data?.insurance_number);
      setInsuranceProvide(data?.insurance_provider);
      setBloodGroup(
        Util.isValidData(data?.blood_group) ? data?.blood_group : ""
      );
      setAllergies(data?.allergies);
      setMedication(data?.medication);
      setSpecialDietry(data?.dietary_consideration);
      setHealthNotes(data?.health_notes);
      setInsuranceFileName(
        Util.isValidData(data.insurance) ? data.insurance : ""
      );
    }
  }, [data]);

  const handleSaveHealth = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("id", studentId);
    if (insuranceNo) {
      formData.append("insurance_number", insuranceNo);
    }
    if (insuranceProvide) {
      formData.append("insurance_provider", insuranceProvide);
    }
    if (bloodGroup) {
      formData.append("blood_group", bloodGroup);
    }
    if (allergies) {
      formData.append("allergies", allergies);
    }
    if (medication) {
      formData.append("medication", medication);
    }
    if (specialDietry) {
      formData.append("dietary_consideration", specialDietry);
    }
    if (healthNotes) {
      formData.append("health_notes", healthNotes);
    }
    if (insuranceUpload) {
      formData.append("insurance", insuranceUpload);
    }
    ApiCalling.apiCallBodyDataPost("student/dynamic-update", formData)
      .then((res) => {
        if (res.data.status == true) {
          setIsLoading(false);
          onSave(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <div>
        {!isLoading ? (
          <>
            <div className="row text-start mt-2">
              <div className="col-lg-6">
              <div className="input-filed ">
                  <span
                    className="basic-text "
                    style={{ fontWight: "400" }}
                  >
                    {t("Insurance Number")}
                  </span>
                  {!healthDetailsEdit ? (
                    <div className="large-bold-text">
                      {Util.isValidData(insuranceNo) ? insuranceNo : "-"}
                    </div>
                  ) : (
                    <>
                      <input
                        className="form-control custom-input"
                        value={insuranceNo}
                        onChange={(e) => {
                          setInsuranceNo(e.target.value);
                          isStudentUpdate();
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
              <div className="input-filed ">
                  <span className="basic-text" >
                    {t("Insurance Provider")}
                  </span>
                  {!healthDetailsEdit ? (
                    <div className="large-bold-text">
                      {Util.isValidData(insuranceProvide)
                        ? insuranceProvide
                        : "-"}
                    </div>
                  ) : (
                    <>
                      <input
                        className="form-control custom-input"
                        value={insuranceProvide}
                        onChange={(e) => {
                          setInsuranceProvide(e.target.value);
                          isStudentUpdate();
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
              <div className="input-filed ">
                  <span className="basic-text" >
                    {t("Blood Group")}
                  </span>
                  {!healthDetailsEdit ? (
                    <div className="large-bold-text">
                      {Util.isValidData(bloodGroup) ? bloodGroup : "-"}
                    </div>
                  ) : (
                    <>
                      <select
                        className="select-dropdown form-select custom-input"
                        value={bloodGroup}
                        onChange={(e) => {
                          setBloodGroup(e.target.value);
                          isStudentUpdate();
                        }}
                      >
                        <option value="">{t("Select Blood Group")}</option>
                        <option value="A+">{t("A+")}</option>
                        <option value="A-">{t("A-")}</option>
                        <option value="B+">{t("B+")}</option>
                        <option value="B-">{t("B-")}</option>
                        <option value="O+">{t("O+")}</option>
                        <option value="O-">{t("O-")}</option>
                        <option value="AB+">{t("AB+")}</option>
                        <option value="AB-">{t("AB-")}</option>
                      </select>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
              <div className="input-filed ">
                  <span className="basic-text" >
                    {t("Allergies")}
                  </span>
                  {!healthDetailsEdit ? (
                    <div className="large-bold-text">
                      {Util.isValidData(allergies) ? allergies : "-"}
                    </div>
                  ) : (
                    <>
                      <input
                        className="form-control custom-input"
                        value={allergies}
                        onChange={(e) => {
                          setAllergies(e.target.value);
                          isStudentUpdate();
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
              <div className="input-filed ">
                  <span className="basic-text" >
                    {t("Medication")}
                  </span>
                  {!healthDetailsEdit ? (
                    <div className="large-bold-text">
                      {Util.isValidData(medication) ? medication : "-"}
                    </div>
                  ) : (
                    <>
                      <input
                        className="form-control custom-input"
                        value={medication}
                        onChange={(e) => {
                          setMedication(e.target.value);
                          isStudentUpdate();
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
              <div className="input-filed ">
                  <span className="basic-text ">
                    {t("Special Dietary Considerations")}
                  </span>
                  {!healthDetailsEdit ? (
                    <div className="large-bold-text">
                      {Util.isValidData(specialDietry) ? specialDietry : "-"}
                    </div>
                  ) : (
                    <>
                      <input
                        className="form-control custom-input"
                        value={specialDietry}
                        onChange={(e) => {
                          setSpecialDietry(e.target.value);
                          isStudentUpdate();
                        }}
                      />
                    </>
                  )}
                </div>
              </div>

            </div>
            <div className="row text-start">
              <div className={healthDetailsEdit ? "col-12 " : "col-6 "}>
                <div className="input-filed ">
                  <span className="basic-text">
                    {t("Special Health Notes")}
                  </span>
                  {!healthDetailsEdit ? (
                    <div className="large-bold-text" style={{ whiteSpace: "pre-line" }}>
                      {Util.isValidData(healthNotes) ? healthNotes : "-"}
                    </div>
                  ) : (
                    <>
                      <textarea
                        style={{ width: "100%", minHeight: "120px" }}
                        className="form-control custom-input"
                        value={healthNotes}
                        onChange={(e) => {setHealthNotes(e.target.value);isStudentUpdate()}}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className={healthDetailsEdit ? "col-12 " : "col-6 "}>
                <div className="input-filed ">
                  <span className="basic-text ">
                    {t("Insurance Uploaded")}
                  </span>
                  {!healthDetailsEdit ? (
                    <>
                      {Util.isValidData(insuranceFileName) ? (
                        <div className="d-flex align-items-center">
                          <img
                            src={fileIcon}
                            className="img-responsive"
                            style={{
                              background: "#F3F4F6",
                              padding: "5px",
                              margin: "3px",
                              borderRadius: "8px",
                              marginLeft: 0,
                            }}
                          ></img>
                          <a
                            target="_blank"
                            className="large-bold-text mx-1"
                            href={studentInsurance + insuranceFileName}
                          >
                            {insuranceFileName}
                          </a>
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </>
                  ) : (
                    <>
                      <CustomFileUpload
                        onChange={handleInsuranceUpload}
                        fileName={
                          insuranceUpload
                            ? insuranceUpload.name
                            : insuranceFileName
                        }
                        showFile={
                          insuranceURL.length > 0
                            ? insuranceURL
                            : studentInsurance + insuranceFileName
                        }
                        onDragOver={handleInsuranceDragOver}
                        onDrop={handleInsuranceDrop}
                        alertMessage={
                          insuranceFileName.length > 0 &&
                          t("(incase you select new file it will replace old)")
                        }
                        acceptedDocument={t("PDF,Doc (max file 25mb)")}
                        
                        accept={"application/pdf,.docs,.docx"}
                        className={"custom-file"}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>

            {healthDetailsEdit && (
              <div className="text-end mt-4">
                {studentId > 0 && (
                  <button
                    className="secondary-lg-btn rounded-btn  mr-2 m-0  px-2"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    {t("Cancel")}
                  </button>
                )}
                <button
                  className="primary-lg-btn rounded-btn "
                  onClick={handleSaveHealth}
                >
                  {t("Save")}
                </button>
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default HealthDetailsCard;
