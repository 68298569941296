import { SET_STUDENT_ID } from "../actions/ToggleAction";

const initialState = {
    studentId: null,
  };
  
  const studentReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_STUDENT_ID:
        return {
          ...state,
          studentId: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default studentReducer;