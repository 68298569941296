import React from "react";
import trendUp from "../../../assets/Icon/trend-up.svg";

export default function StudentCountCard({
  title,
  count,
  pr,
  duration,
  bgColor,
  titleIcon,
  className,
  prColor
}) {
  return (
 
      <div className={className} style={{ backgroundColor: bgColor }}>
        <div className="d-flex justify-content-between">
          <div className="text-secondary">{title}</div>
          <img src={titleIcon} height={24} width={24} />
        </div>
        <div className="extra-large-text">{count}</div>
        <div className="d-flex align-items-center">
          <img src={trendUp} height={16} width={16} />
          <div className={prColor} >{pr}</div>
          <div className="sm-text ml-1">{duration}</div>
        </div>
      </div>
   
  );
}
