import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import { useHistory } from "react-router-dom";
import fileIcon from "../../assets/Icon/file.svg";
import ParentAvtar from "../../assets/Icon/Parent-avtar.svg";
import Edit from "../../assets/Icon/Edit-blue.svg";
import Util, { StatusLead } from "../../Util";
import Invalid from "../../assets/Icon/invalid.svg";
import DatePicker from "react-datepicker";
import ApiCalling from "../../network/ApiCalling";
import { CustomInput } from "./NewAdmisson";
import moment from "moment";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import ToastHOC from "../HOC/ToastHOC";
import Loader from "../../components/common/Loader";
import { Box, Tab, Tabs } from "@mui/material";
import Activity from "./Activity";
import Const from "../../Const";

const statusList = [
  { value: 0, title: "Pending" },
  { value: 1, title: "Converted" },
  { value: 2, title: "Closed" },
];

const AdmissionDetails = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [firstName, setFirstName] = useState("");

  const [lastNameError, setLastNameError] = useState("");

  const [isEditableDetails, setIsEditableDetails] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [leadProgramList, setLeadProgramList] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState("");
  const [leadProgramId, setLeadProgramId] = useState(null);
  const [contactway, setContactway] = useState([]);
  const [contactId, setContactId] = useState(null);
  const [selectedContactVia, setSelectedContactVia] = useState("");
  const [contactwayErr, setContactwayErr] = useState("");
  const datepickerRef = useRef(null);
  const [dateErr, setDateErr] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [date, setDate] = useState(null);
  const school = Util.getSelectedSchool();
  const [internalComments, setInternalComments] = useState("");
  const [isParentDetails, setIsParentDetails] = useState(false);
  const [isShowDetails, setIsShowDetails] = useState(true);

  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [fatherPhoneNo, setFatherPhoneNo] = useState("");
  const [fatherPhoneNoErr, setFatherPhoneNoErr] = useState("");
  const [motherPhoneNo, setMotherPhoneNo] = useState("");
  const [fatherEmail, setFatherEmail] = useState("");
  const [fatherEmailError, setFatherEmailError] = useState("");

  const [motherEmail, setMotherEmail] = useState("");
  const [motherEmailError, setMotherEmailError] = useState("");

  const [fatherWork, setFatherWork] = useState("");
  const [motherWork, setMotherWork] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState(null);
  const [state, setState] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [isMotherEmailValid, setIsMotherEmailValid] = useState(true);
  const [isFatherMotherFatherEmailValid, setIsFatherEmailValid] =
    useState(true);
  const [motherPhoneNoErr, setMotherPhoneNoErr] = useState("");
  const [pincode, setPincode] = useState(null);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [city, setCity] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState("AE");
  const [cityId, setCityId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [studentId, setStudentId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setIsToast } = props;
  const [statusId, setStatusId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState();

  const [editAddress, setEditAddress] = useState(false);
  const [showAddress, setShowAddress] = useState(true);
  const [programErr, setProgramErr] = useState("");
  const [tabSelection, setTabSelection] = useState("General Information");
  const [studentData, setStudentData] = useState([]);

  useEffect(() => {
    if (location?.state && location?.state?.studentId) {
      setStudentId(location?.state?.studentId);
    }
  }, [location.state]);

  const getStudentDetails = () => {
    if (studentId) {
      setIsLoading(true);
      ApiCalling.apiCallBodyDataGet(`lead/list/${studentId}`)
        .then((res) => {
          console.log("student details", res?.data);
          const data = res?.data;
          setIsLoading(false);
          setStudentData(data);
          setFirstName(data?.student_first_name);
          setLastName(data?.student_last_name);
          setSelectedProgram(data?.Lead_Program?.title);
          setLeadProgramId(data?.lead_program_id);
          setSelectedContactVia(data?.Contact_Way?.title);
          setContactId(data?.Contact_Way.id);
          setDate(data?.date ? new Date(data?.date) : null);
          setSelectedDate(data?.date);
          setSelectedStatus(data?.lead_status);
          setStatusId(data?.lead_status);
          setInternalComments(data?.internal_comments);
          setFatherName(data?.father_name);
          setMotherName(data?.mother_name);
          setFatherWork(data?.father_work_title);
          setMotherWork(data?.mother_work_title);
          setFatherPhoneNo(data?.father_phone_number);
          setMotherPhoneNo(data?.mother_phone_number);
          setFatherEmail(data?.father_email);
          setMotherEmail(data?.mother_email);
          setAddress1(data?.address1);
          setAddress2(data?.address2);
          setCountryId(data?.country_id);
          setSelectedCountry(data?.Country?.name);
          setStateId(data?.state_id);
          setSelectedState(data?.State?.name);
          setCityId(data?.city_id);
          setSelectedCity(data?.City?.name);
          setPincode(data?.pincode);
        })
        .catch((error) => {
          console.error("ERROR", error);
        });
    }
  };
  useEffect(() => {
    getStudentDetails();
  }, [studentId]);

  const getLeadProgram = () => {
    ApiCalling.apiCallBodyDataGet("lead-program/list/")
      .then((res) => {
        if (res.status === 200) {
          setLeadProgramList(res.data);
        }
      })
      .catch((error) => console.log(error));
  };

  const getContactWay = () => {
    ApiCalling.apiCallBodyDataGet("lead-contact-way/list/")
      .then((res) => {
        // console.log("CONTACT WAY", res);
        if (res.status === 200) {
          setContactway(res.data);
        } else {
          console.error("ERROR");
        }
      })
      .catch((error) => {
        console.error("ERROR", error);
      });
  };

  const changeDate = (date) => {
    setDate(date);
  };
  const handleTabChange = (event, newSelection) => {
    setTabSelection(newSelection);
  };
  const countryList = () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet("country").then((res) => {
      // console.log("COUNTRY", res);
      setIsCountryLoading(false);
      setCountry(res?.data);
    });
  };
  const cityList = (id) => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res?.data);
    });
  };

  useEffect(() => {
    if (countryId) {
      let isStateMounted = true;
      const stateList = (id) => {
        setIsCountryLoading(true);
        ApiCalling.apiCallBodyDataGet(`state/get-states/${countryId}`).then(
          (res) => {
            if (isStateMounted) {
              setIsCountryLoading(false);
              setState(res?.data);
            }
          }
        );
      };
      stateList();
      return () => {
        isStateMounted = false;
      };
    }
  }, [countryId]);
  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((res) => {
        // console.log("FETCH COUNTRY:::", res);
        setDefaultCountry(res.data.country);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    getLeadProgram();
    getContactWay();
    countryList();
  }, []);

  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);

  const handleFatherEmailChange = (e) => {
    const email = e.target.value;
    setFatherEmail(email);
    if (Util.email_regex.test(email)) {
      setIsFatherEmailValid(true);
    } else {
      setIsFatherEmailValid(false);
    }
  };
  const handleMotherEmailChange = (e) => {
    const email = e.target.value;
    setMotherEmail(email);
    if (Util.email_regex.test(email)) {
      setIsMotherEmailValid(true);
    } else {
      setIsMotherEmailValid(false);
    }
  };

  function savePersonalDetails() {
    setFirstNameError("");
    setLastNameError("");
    setDateErr("");
    setProgramErr("");
    setContactwayErr("");

    let error = false;

    // Validate first name
    if (firstName === "") {
      setFirstNameError("First name is required");
      error = true;
    }

    // Validate last name
    if (lastName === "") {
      setLastNameError("Last name is required");
      error = true;
    }

    if (leadProgramId === null || "") {
      setProgramErr("Program is required");
      error = true;
    }
    // Validate date of birth
    if (date === null || date === "") {
      setDateErr("DOB is required");
      error = true;
    }
    // Validate contact way
    if (contactId === null || contactId === "") {
      setContactwayErr("Contact way is required");
      error = true;
    }
    if (error) {
      return;
    }
    const formData = {
      id: studentId,
      student_first_name: firstName,
      student_last_name: lastName,
      contact_way_id: contactId,
      date: date,
      lead_status: statusId,
      lead_program_id: leadProgramId,
      internal_comments: internalComments,
    };
    ApiCalling.apiCallBodyDataPost("lead/update/", formData)
      .then((res) => {
        if (res.data.status == true) {
          console.log("updated personal details", res);
          setIsToast(t("Updated Personal Details"));
          setIsParentDetails(false);
          setIsEditableDetails(false);
          getStudentDetails();
        }
      })
      .catch((err) => {
        console.error("ERROR", err);
      });
  }
  function saveParentDetails() {
    setFatherEmailError("");
    setMotherEmailError("");
    setFatherPhoneNoErr("");
    setMotherPhoneNoErr("");
    // Track if there are any errors
    let error = false;

    if (!fatherPhoneNo && !motherPhoneNo) {
      setMotherPhoneNoErr(
        "Either Mother's or Father's mobile number is required."
      );
      return false;
    }

    if (error) {
      return;
    }
    const formData = {
      id: studentId,
      father_name: fatherName,
      mother_name: motherName,
      father_phone_number: fatherPhoneNo,
      mother_phone_number: motherPhoneNo,
      father_work_title: fatherWork,
      mother_work_title: motherWork,
      father_email: fatherEmail,
      mother_email: motherEmail,
    };
    ApiCalling.apiCallBodyDataPost("lead/update/", formData)
      .then((res) => {
        if (res.data.status == true) {
          console.log("updated personal details", res);
          setIsToast(t("Updated Parent Details"));
          setIsParentDetails(false);
          setIsEditableDetails(false);
          getStudentDetails();
        }
      })
      .catch((err) => {
        console.error("ERROR", err);
      });
  }
  function saveAddressDetails() {
    const formData = {
      id: studentId,
      address1: address1,
      address2: address2,
      country_id: countryId,
      state_id: stateId,
      city_id: cityId,
      pincode: pincode,
    };

    ApiCalling.apiCallBodyDataPost("lead/update/", formData)
      .then((res) => {
        if (res.data.status == true) {
          console.log("updated personal details", res);
          setIsToast(t("Updated Address Details"));
          setIsParentDetails(false);
          setIsEditableDetails(false);
          getStudentDetails();
        }
      })
      .catch((err) => {
        console.error("ERROR", err);
      });
  }

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape" || event.key === "Esc") {
        setIsParentDetails(false);
        setIsEditableDetails(false);
        setEditAddress(false);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  return (
    <div className="container-80">
      <div className="row mt-3 mb-0">
        <BreadCrumbComponent
          Breadcrumb={[
            {
              name: "Admission",
              navigate: () => {
                history.push("/admission");
              },
            },

            { name: `${t("Lead Details")}` },
          ]}
          history={history}
        />

        <div className="col-md-4">
          <h2 className="page-head mb-4">{t("Lead Details")}</h2>
        </div>
        <Box sx={{ width: "100%", marginBottom: "2%" }}>
          <Tabs
            value={tabSelection}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: Const.primaryColor,
                height: 2,
              },
            }}
          >
            <Tab
              sx={{
                fontWeight: 600,
                marginRight: "20px",
                letterSpacing: 0,
                textTransform: "capitalize",
                fontFamily: Const.fontfamily,
                padding: "5px",
                height: "40px",
                "&.Mui-selected": {
                  color: Const.primaryColor,
                  fontWeight: "600",
                },
              }}
              value="General Information"
              label={t("General Information")}
            />
            <Tab
              sx={{
                fontWeight: 600,
                letterSpacing: 0,
                height: "40px",
                textTransform: "capitalize",
                fontFamily: Const.fontfamily,
                "&.Mui-selected": {
                  color: Const.primaryColor,
                  fontWeight: "600",
                  marginLeft: "0px",
                },
              }}
              value="Activity"
              label={t("Activity")}
            />
          </Tabs>
        </Box>
      </div>

      {tabSelection === "General Information" && (
        <>
          {!isLoading ? (
            <div className="school-details my-2 ">
              <div className="p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="medium-large-text font-color-primary py-2">
                    {t("Personal Details")}
                  </div>
                  {!isEditableDetails && (
                    <>
                      <div className="d-flex justify-content-center align-items-center">
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: Const.primaryColor,
                            cursor: "pointer",
                          }}
                          className="link-button"
                          onClick={() => {
                            setIsEditableDetails(true);
                            setShowDetails(true);
                            setIsParentDetails(false);
                            setEditAddress(false);
                          }}
                        >
                          <img src={Edit} alt="" className="edit-svg " />
                          {t("Edit")}
                        </span>
                        <div className="vertical-bar"></div>
                        <div
                          className="accoridan-btn"
                          onClick={() => setShowDetails(!showDetails)}
                        >
                          <i
                            className={
                              showDetails
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {showDetails ? (
                  <div className=" text-start">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("First Name")}
                          </span>

                          {!isEditableDetails ? (
                            <div className="large-bold-text">
                              {Util.isValidData(firstName) ? firstName : "-"}
                            </div>
                          ) : (
                            <>
                              <input
                                className={`form-control custom-input ${
                                  firstNameError.length > 0 ? "input-error" : ""
                                }`}
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                  setFirstNameError("");
                                }}
                              />
                              {firstNameError.length > 0 && (
                                <span className="text-danger">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {firstNameError}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Last Name")}
                          </span>

                          {!isEditableDetails ? (
                            <div className="large-bold-text">
                              {Util.isValidData(lastName) ? lastName : "-"}
                            </div>
                          ) : (
                            <>
                              <input
                                className={`form-control custom-input ${
                                  lastNameError.length > 0 ? "input-error" : ""
                                }`}
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                  setLastNameError("");
                                }}
                              />
                              {lastNameError.length > 0 && (
                                <span className="text-danger">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {lastNameError}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 ">
                        <div className="input-filed">
                          <span className="basic-text required">
                            {t("Program")}
                          </span>
                          {!isEditableDetails ? (
                            <div className="large-bold-text">
                              {Util.isValidData(selectedProgram)
                                ? selectedProgram
                                : "-"}
                            </div>
                          ) : (
                            <>
                              <select
                                value={leadProgramId}
                                onChange={(e) => {
                                  setLeadProgramId(e.target.value);
                                }}
                                className={`select-dropdown form-select custom-input `}
                              >
                                <option value="">{t("Select Program ")}</option>
                                {leadProgramList?.map((item) => (
                                  <option key={item.id} value={item?.id}>
                                    {item?.title}
                                  </option>
                                ))}
                              </select>
                            </>
                          )}
                        </div>
                        {programErr.length > 0 && (
                          <span className=" invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {programErr}
                          </span>
                        )}
                      </div>
                      <div className="col-6">
                        <div className="input-filed">
                          <span className="basic-text required">
                            {t("Contact via")}
                          </span>
                          {!isEditableDetails ? (
                            <div className="large-bold-text">
                              {Util.isValidData(selectedContactVia)
                                ? selectedContactVia
                                : "-"}
                            </div>
                          ) : (
                            <select
                              value={contactId}
                              onChange={(e) => {
                                setContactId(e.target.value);
                              }}
                              className={`select-dropdown form-select custom-input ${
                                contactwayErr.length > 0 ? "input-error" : ""
                              }`}
                            >
                              <option value="">{t("Select Contact")}</option>
                              {contactway?.map((item) => (
                                <option key={item.id} value={item?.id}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>
                          )}
                          {contactwayErr.length > 0 && (
                            <span className=" invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {contactwayErr}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="input-filed">
                          <span className="basic-text">
                            {t("Date of Birth*")}
                          </span>
                          {!isEditableDetails ? (
                            <div className="large-bold-text">
                              {Util.isValidData(selectedDate)
                                ? moment(selectedDate).format(
                                    Util.getMomentDateTimeFormat(
                                      school?.date_format
                                    )
                                  )
                                : "-"}
                            </div>
                          ) : (
                            <>
                              <DatePicker
                                ref={datepickerRef}
                                className={`form-control custom-input ${
                                  dateErr?.length > 0 ? "input-error" : ""
                                }`}
                                placeholderText="Select Date"
                                selected={date}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onChange={changeDate}
                                dateFormat={school?.date_format}
                                popperPlacement="bottom"
                                maxDate={new Date()}
                                customInput={<CustomInput error={dateErr} />}
                              />
                              {dateErr?.length > 0 && (
                                <span className=" invalid-error">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {dateErr}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-filed">
                          <span className="basic-text  ">{t("Status")}</span>
                          {!isEditableDetails ? (
                            <div className="large-bold-text">
                              {StatusLead[statusId]
                                ? StatusLead[statusId]
                                : "-"}
                            </div>
                          ) : (
                            <select
                              value={statusId}
                              onChange={(e) => {
                                setStatusId(e.target.value);
                              }}
                              className={`select-dropdown form-select custom-input `}
                            >
                              <option value="">{t("Select Status")}</option>
                              {statusList?.map((item) => (
                                <option key={item?.value} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 ">
                        <div className="input-filed">
                          <span className="basic-text">
                            {t("Internal Comments")}
                          </span>
                          {!isEditableDetails ? (
                            <div className="large-bold-text">
                              {Util.isValidData(internalComments)
                                ? internalComments
                                : "-"}
                            </div>
                          ) : (
                            <textarea
                              rows={20}
                              className={`form-control custom-input `}
                              value={internalComments}
                              onChange={(e) =>
                                setInternalComments(e.target.value)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {isEditableDetails && (
                      <div className="text-end mt-3">
                        <button
                          className=" btn secondary-lg-btn rounded-btn mr-2 m-0 px-2"
                          onClick={() => {
                            // resetPersonalDetails();
                            setIsParentDetails(false);
                            setIsEditableDetails(false);
                          }}
                        >
                          {t("Cancel")}
                        </button>

                        <button
                          className=" btn primary-lg-btn rounded-btn"
                          //   onClick={handleSavePersonalDetails}
                          onClick={savePersonalDetails}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <hr style={{ backgroundColor: "#E5E7EB" }} className="mt-4" />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="medium-large-text font-color-primary py-2">
                    {t("Parent Details")}
                  </div>
                  {!isParentDetails && (
                    <>
                      <div className="d-flex justify-content-center align-items-center">
                        <span
                          role="button"
                          className="link-button"
                          onClick={() => {
                            setIsShowDetails(true);
                            setIsParentDetails(true);
                            setIsEditableDetails(false);
                            setEditAddress(false);
                          }}
                        >
                          <img src={Edit} alt="" className="edit-svg " />
                          {t("Edit")}
                        </span>
                        <div className="vertical-bar"></div>
                        <div
                          className="accoridan-btn"
                          onClick={() => setIsShowDetails(!isShowDetails)}
                        >
                          <i
                            className={
                              isShowDetails
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {isShowDetails ? (
                  <>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">
                            {t("Father's Name")}
                          </span>

                          {!isParentDetails ? (
                            <div className="large-bold-text">
                              {Util.isValidData(fatherName) ? fatherName : "-"}
                            </div>
                          ) : (
                            <>
                              <input
                                className={`form-control custom-input `}
                                value={fatherName}
                                onChange={(e) => {
                                  setFatherName(e.target.value);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">
                            {t("Mother's Name")}
                          </span>

                          {!isParentDetails ? (
                            <div className="large-bold-text">
                              {Util.isValidData(motherName) ? motherName : "-"}
                            </div>
                          ) : (
                            <>
                              <input
                                className={`form-control custom-input `}
                                value={motherName}
                                onChange={(e) => {
                                  setMotherName(e.target.value);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">
                            {t("Father's occupation")}
                          </span>

                          {!isParentDetails ? (
                            <div className="large-bold-text">
                              {Util.isValidData(fatherWork) ? fatherWork : "-"}
                            </div>
                          ) : (
                            <>
                              <input
                                className={`form-control custom-input ${
                                  firstNameError.length > 0 ? "input-error" : ""
                                }`}
                                value={fatherWork}
                                onChange={(e) => {
                                  setFatherWork(e.target.value);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">
                            {t("Mother's occupation")}
                          </span>

                          {!isParentDetails ? (
                            <div className="large-bold-text">
                              {Util.isValidData(motherWork) ? motherWork : "-"}
                            </div>
                          ) : (
                            <>
                              <input
                                className={`form-control custom-input `}
                                value={motherWork}
                                onChange={(e) => {
                                  setMotherWork(e.target.value);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-6">
                        <span className="basic-text ">
                          {t("Father's Email")}
                        </span>
                        {!isParentDetails ? (
                          <div className="large-bold-text">
                            {Util.isValidData(fatherEmail) ? fatherEmail : "-"}
                          </div>
                        ) : (
                          <>
                            <input
                              className={`form-control custom-input ${
                                isFatherMotherFatherEmailValid
                                  ? ""
                                  : "is-invalid"
                              }`}
                              value={fatherEmail}
                              onChange={handleFatherEmailChange}
                            />
                            {!isFatherMotherFatherEmailValid && (
                              <span className="invalid-error">
                                Please enter a valid email address.
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      <div className="col-6">
                        <span className="basic-text ">
                          {t("Mother's Email")}
                        </span>
                        {!isParentDetails ? (
                          <div className="large-bold-text">
                            {Util.isValidData(motherEmail) ? motherEmail : "-"}
                          </div>
                        ) : (
                          <>
                            <input
                              className={`form-control custom-input ${
                                isMotherEmailValid ? "" : "is-invalid"
                              }`}
                              value={motherEmail}
                              onChange={handleMotherEmailChange}
                            />
                            {!isMotherEmailValid && (
                              <span className="invalid-error">
                                Please enter a valid email address.
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-6">
                        <span className="basic-text ">
                          {t("Father Mobile No.")}
                        </span>
                        {!isParentDetails ? (
                          <div className="large-bold-text">
                            {Util.isValidData(fatherPhoneNo)
                              ? fatherPhoneNo
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <div className={`form-control `}>
                              <PhoneInput
                                tabIndex="-1"
                                international
                                limitMaxLength={10}
                                defaultCountry={defaultCountry}
                                value={fatherPhoneNo}
                                onChange={(value) => {
                                  setFatherPhoneNo(value);
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-6">
                        <span className="basic-text ">
                          {t("Mother Mobile No.")}
                        </span>
                        {!isParentDetails ? (
                          <div className="large-bold-text">
                            {Util.isValidData(motherPhoneNo)
                              ? motherPhoneNo
                              : "-"}
                          </div>
                        ) : (
                          <div className={`form-control `}>
                            <PhoneInput
                              tabIndex="-1"
                              international
                              defaultCountry={defaultCountry}
                              value={motherPhoneNo}
                              onChange={(value) => {
                                setMotherPhoneNo(value);
                              }}
                            />
                          </div>
                        )}
                        {motherPhoneNoErr.length > 0 && (
                          <span className="invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                              alt="Invalid Icon"
                            />
                            {motherPhoneNoErr}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {isParentDetails && (
                  <div className="text-end mt-3">
                    <button
                      className=" btn secondary-lg-btn rounded-btn mr-2 m-0 px-2"
                      onClick={() => {
                        // resetPersonalDetails();
                        setIsParentDetails(false);
                        setEditAddress(false);
                        setIsEditableDetails(false);
                        // setIsShowDetails(false);
                      }}
                    >
                      {t("Cancel")}
                    </button>

                    <button
                      className=" btn primary-lg-btn rounded-btn"
                      //   onClick={handleSavePersonalDetails}
                      onClick={() => {
                        saveParentDetails();
                        setIsParentDetails(false);
                      }}
                    >
                      {t("Save")}
                    </button>
                  </div>
                )}

                <hr style={{ backgroundColor: "#E5E7EB" }} className="mt-4" />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="medium-large-text font-color-primary py-2">
                    {t("Address Details")}
                  </div>
                  {!editAddress && (
                    <>
                      <div className="d-flex justify-content-center align-items-center">
                        <span
                          role="button"
                          className="link-button"
                          onClick={() => {
                            setShowAddress(true);
                            setEditAddress(true);
                            setIsEditableDetails(false);
                            setIsParentDetails(false);
                          }}
                        >
                          <img src={Edit} alt="" className="edit-svg " />
                          {t("Edit")}
                        </span>
                        <div className="vertical-bar"></div>
                        <div
                          className="accoridan-btn"
                          onClick={() => setShowAddress(!showAddress)}
                        >
                          <i
                            className={
                              isShowDetails
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {showAddress ? (
                  <>
                    <div className="row ">
                      <div className="col-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("Address1")}</span>
                          {!editAddress ? (
                            <div className="large-bold-text">
                              {Util.isValidData(address1) ? address1 : "-"}
                            </div>
                          ) : (
                            <input
                              className={`form-control custom-input `}
                              value={address1}
                              onChange={(e) => setAddress1(e.target.value)}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("Address2")}</span>
                          {!editAddress ? (
                            <div className="large-bold-text">
                              {Util.isValidData(address2) ? address2 : "-"}
                            </div>
                          ) : (
                            <input
                              className={`form-control custom-input `}
                              value={address2}
                              onChange={(e) => setAddress2(e.target.value)}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("Country")}</span>
                          {!editAddress ? (
                            <div className="large-bold-text">
                              {Util.isValidData(selectedCountry)
                                ? selectedCountry
                                : "-"}
                            </div>
                          ) : (
                            <select
                              value={countryId}
                              onChange={(e) => setCountryId(e.target.value)}
                              className={`select-dropdown form-select custom-input `}
                            >
                              <option value="">{t("Select Country ")}</option>
                              {country?.map((item) => (
                                <option key={item.id} value={item?.id}>
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("State")}</span>
                          {!editAddress ? (
                            <div className="large-bold-text">
                              {Util.isValidData(selectedState)
                                ? selectedState
                                : "-"}
                            </div>
                          ) : (
                            <select
                              className="select-dropdown form-select custom-input"
                              value={stateId || ""}
                              onChange={(e) => {
                                setStateId(e.target.value);
                                setIsStateUpdated(true);
                              }}
                              disabled={!countryId}
                            >
                              <option value="">
                                {isCountryLoading
                                  ? t("Loading...")
                                  : t("Select State")}
                              </option>
                              {state?.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item?.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("City")}</span>
                          {!editAddress ? (
                            <div className="large-bold-text">
                              {Util.isValidData(selectedCity)
                                ? selectedCity
                                : "-"}
                            </div>
                          ) : (
                            <select
                              className="select-dropdown form-select custom-input"
                              value={cityId}
                              onChange={(e) => {
                                setCityId(e.target.value);
                                setIsStateUpdated(true);
                              }}
                              disabled={!stateId}
                            >
                              <option value={0}>
                                {isCountryLoading
                                  ? "Loading..."
                                  : "Select City"}
                              </option>
                              {city?.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("Pincode")}</span>
                          {!editAddress ? (
                            <div className="large-bold-text">
                              {Util.isValidData(pincode) ? pincode : "-"}
                            </div>
                          ) : (
                            <input
                              className={`form-control custom-input `}
                              value={pincode}
                              onChange={(e) => setPincode(e.target.value)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {editAddress && (
                      <div className="text-end mt-3">
                        <button
                          className=" btn secondary-lg-btn rounded-btn mr-2 m-0 px-2"
                          onClick={() => {
                            // resetPersonalDetails();
                            setEditAddress(false);
                            setIsEditableDetails(false);
                            setIsParentDetails(false);
                            // setIsShowDetails(false);
                          }}
                        >
                          {t("Cancel")}
                        </button>

                        <button
                          className=" btn primary-lg-btn rounded-btn"
                          onClick={() => {
                            saveAddressDetails();
                            setEditAddress(false);
                          }}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </>
      )}
      {tabSelection === "Activity" && (
        <Activity lead_id={studentId} data={studentData} />
      )}
    </div>
  );
};

export default ToastHOC(AdmissionDetails);
