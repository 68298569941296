import React, { useEffect, useState } from "react";
import ApiCalling from "../../../network/ApiCalling";
import { useHistory } from "react-router-dom";
import Select from "react-dropdown-select";
import { Rings } from "react-loader-spinner";
import { Modal, Button } from "react-bootstrap";
import ToastHOC from "../../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import { imageMyProfile } from "../../CommonImage";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TagButton from "../TagButton";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Call from "../../../assets/Icon/call.svg";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ModalComponent from "../ModalComponent";
import Sort from "../../../assets/Icon/Short.svg";
import Search from "../../../assets/Icon/Search.svg";
import close from "../../../assets/Icon/close.svg";
import {
  activeTagStyle,
  greenTagStyle,
  inactiveTagStyle,
  orangeTagStyle,
} from "../TagButtonStyle";
import Loader from "../../../components/common/Loader";
import Invalid from "../../../assets/Icon/invalid.svg";

const Staff = (props) => {
  const { t } = useTranslation();
  const { setIsToast, setIsSubToast } = props;
  const userId = props.userId;
  const pendingToast = props.location?.pendingToast;
  const getLanguage = Util.getSelectedLanguage();
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [role, setRole] = useState([]);
  const [roleId, setRoleId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [selectedUserId, setSelectedUserId] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [invitationPopup, setInvitationPopup] = useState(false);
  const [invitationAlert, setInvitationAlert] = useState(false);
  const [invitationMailNote, setInvitationMailNote] = useState("");
  const [invitationMailNoteError, setInvitationMailNoteError] = useState("");
  const [rolePopup, setRolePopup] = useState(false);
  const [editRole, setEditRole] = useState("");
  const [editRoleError, setEditRoleError] = useState("");
  const [editRoleName, setEditRoleName] = useState("");

  // const [roleChangeById, setRoleChangeById] = useState(null);
  const [userSchoolId, setUserSchoolId] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [roleAlert, setRoleAlert] = useState(false);

  const history = useHistory();

  const changeCheck = (data) => {
    setCurrentPage(1);
    setRoleId(data[0].id);
  };
  const handleAllSelectedUser = (e) => {
    if (e.target.checked) {
      setSelectedUserId(user.map((item) => item.id));
      setSelectedUser(user);
    } else {
      setSelectedUserId([]);
      setSelectedUser([]);
    }
  };
  const userChecked = (event) => {
    const id = Number(event.target.value);
    const us = user.find((item) => item.id === id);
    if (event.target.checked) {
      setSelectedUserId([...selectedUserId, id]);
      setSelectedUser([...selectedUser, us]);
    } else {
      const us1 = selectedUser.filter((s) => s !== us);
      const data = selectedUserId.filter((s) => s !== id);
      setSelectedUserId(data);
      setSelectedUser(us1);
    }
  };

  // const searchClicked = () => {
  //   setCurrentPage(1);
  //   setSearchClick(search);
  // };
  // const EnterHandler = (event) => {
  //   if (event.key === "Enter") {
  //     setSearchClick(search);
  //   }
  // };
  const searchClicked = () => {
    if (search.length > 0) {
      setSearch("");
      setSearchClick("");
    } else {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };
  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };
  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };
  const invitationSent = () => {
    setInvitationPopup(false);
    setSelectedUserId([]);
    setSelectedUser([]);
  };
  const RoleList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("role/list")
      .then((res) => {
        setRole(res.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    RoleList();
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  const staffUser = async () => {
    const data = {
      page_size: recordPerPage,
      page_number: currentPage,
      role_id: roleId,
      str_search: searchClick,
      order_by: sortBy,
      ascdesc: sortOrder ? "desc" : "asc",
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("user/list", data)
      .then((res) => {
        setUser(res.data.data);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    staffUser();
  }, [sortBy, sortOrder, recordPerPage, searchClick, currentPage, roleId]);

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const openInvitationNotePopup = () => {
    const allUsersUnverified = selectedUser.every(
      (user) => !user.email_verified
    );
    if (allUsersUnverified) {
      setInvitationPopup(true);
    } else {
      setInvitationAlert(true);
    }
  };
  const handleRoleChange = (e) => {
    const id = Number(e.target.value);
    setEditRole(e.target.value);
    role
      .filter((s) => s.id === id)
      .map((d) => {
        setEditRoleName(d.role);
      });
  };
  const handleEditRole = (e) => {
    e.preventDefault();
    let error = false;
    setEditRoleError("");
    if (editRole == "") {
      setEditRoleError(t("Role is required"));
      error = true;
    }
    if (!error) {
      setRoleAlert(true);
      setRolePopup(false);
    }
  };
  const handleConfirm = (e) => {
    e.preventDefault();
    let updateData = {
      userschool_id: userSchoolId,
      roleId: editRole,
    };
    ApiCalling.apiCallBodyDataPost("user/edit-role", updateData)
      .then((res) => {
        if (res.data.status == true) {
          setIsToast(t("Role Staff Changed"));
          setIsSubToast(t("You have successfully change staff role"));
          setRolePopup(false);
          setRoleAlert(false);
          staffUser();
          setEditRoleError("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const inviteAllSelected = async () => {
    if (invitationMailNote === "") {
      setInvitationMailNoteError(t("Please enter Note"));
      return;
    }
    const data = {
      note: invitationMailNote,
      UserIds: selectedUserId,
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("user/send-invitation", data)
      .then((res) => {
        if (res.data.success == true) {
          setIsToast(t("Invitation(s) Sent Successfully"));
          setInvitationPopup(false);
          setSelectedUserId([]);
          setSelectedUser([]);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   if (roleChangeById == null) {
  //     setEditRole("");
  //   } else {
  //     setEditRole(roleChangeById);
  //   }
  // }, [roleChangeById]);
  return (
    <>
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div className="container-fluid">
          <div className="body">
            <div className="col-lg col-md-12 p-3">
              <div className="row py-3">
                <div className="col-md-4 text-start">
                  <h2 className="page-head">{t("My Staff")}</h2>
                </div>
                <div
                  className={`col-md-8 ${getLanguage !== "ar" && "text-end"}`}
                >
                  {selectedUserId.length > 0 ? (
                    <button
                      className="btn secondary-sm-btn"
                      onClick={openInvitationNotePopup}
                    >
                      {t("Send Invite")}
                    </button>
                  ) : (
                    <div className="d-flex justify-content-end align-items-center gap-2">
                      <button
                        className={`${
                          Util.hasPermission("STAFF_ADD")
                            ? "btn secondary-sm-btn"
                            : "no-permission"
                        }`}
                        // className="btn secondary-sm-btn"
                        type="button"
                        onClick={() => {
                          history.push({
                            pathname: "/BulkStaff",
                          });
                        }}
                      >
                        {t("Add Staff in Bulk")}
                      </button>
                      <button
                        className={`${
                          Util.hasPermission("STAFF_ADD")
                            ? "btn primary-sm-btn"
                            : "no-permission"
                        }`}
                        type="button"
                        onClick={() => {
                          history.push({
                            pathname: "/new-staff",
                          });
                        }}
                      >
                        {t("Add New Staff")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className={"table-div"}>
                <div className="d-flex gap-20 align-items-center table-sort-bar">
                  <div className="custom-search-wrap">
                    <div className="input-group">
                      <input
                        className="form-control search"
                        placeholder="Search Staff"
                        type="text"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyPress={(e) => EnterHandler(e)}
                      />
                      <div className="input-group-prepend">
                        <div className="search-icon">
                          <img
                            src={search.length > 0 ? close : Search}
                            className="svg-icon"
                            alt=""
                            onClick={searchClicked}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mr-2">
                    <Select
                      searchable={false}
                      className="form-control select-custom"
                      options={[{ id: 0, role: "Role" }, ...role]}
                      onChange={(values) => changeCheck(values)}
                      style={{ width: "100%" }}
                      labelField="role"
                      values={[{ id: 0, role: "Role" }]}
                      valueField="id"
                    />
                  </div>
                </div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="table-wrapper">
                        <table className="table">
                          <thead>
                            <tr className="text-nowrap">
                              <th
                                scope="col"
                                style={{
                                  paddingRight: "0px",
                                  width: 0,
                                }}
                              >
                                <label className="fancy-checkbox element-left">
                                  <input
                                    type="checkbox"
                                    name="allselect"
                                    onChange={handleAllSelectedUser}
                                    checked={selectedUserId.length >0 && selectedUserId.length == user.length}
                                  />
                                  <span></span>
                                </label>
                              </th>
                              <th
                                style={{
                                  paddingLeft: "0px",
                                  width: 0,
                                }}
                              >
                                {t("Staff Name")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("user.firstName");
                                  }}
                                />
                              </th>
                              <th>
                                {t("Staff ID")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("user.id");
                                  }}
                                />
                              </th>
                              <th>
                                {t("Email")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("user.email");
                                  }}
                                />
                              </th>
                              <th>
                                {t("Role")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("role.role");
                                  }}
                                />
                              </th>
                              <th>
                                {t("Registration")}
                                <img
                                  src={Sort}
                                  className="ml-1 fa-sort"
                                  alt=""
                                  onClick={() => {
                                    handleSort("user.email_verified");
                                  }}
                                />
                              </th>
                            </tr>
                          </thead>
                          {user.length > 0 ? (
                            <tbody>
                              {user.map((data, index) => {
                                return (
                                  <tr
                                    key={data.id}
                                    style={{
                                      backgroundColor: `${
                                        selectedUserId.includes(data.id)
                                          ? "rgba(100, 24, 195, 0.04)"
                                          : ""
                                      }`,
                                    }}
                                  >
                                    <td
                                      style={{
                                        paddingRight: "0px",
                                        width: 0,
                                      }}
                                    >
                                      {/* {!data.email_verified && ( */}
                                      <label className="fancy-checkbox element-left">
                                        <input
                                          type="checkbox"
                                          onChange={userChecked}
                                          value={data.id}
                                          checked={selectedUserId.includes(
                                            data.id
                                          )}
                                        />
                                        <span></span>
                                      </label>
                                      {/* )} */}
                                    </td>

                                    <td
                                      style={{
                                        paddingLeft: "0px",
                                        width: 0,
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        {data.avatar ? (
                                          <img
                                            className="rounded-circle "
                                            style={{
                                              width: "40px",
                                              height: "40px",
                                            }}
                                            src={imageMyProfile + data.avatar}
                                            alt=""
                                          />
                                        ) : (
                                          <Avatar
                                            aria-label="recipe"
                                            className="avatar-font"
                                          >
                                            {data.firstName.toUpperCase()[0] +
                                              data.lastName.toUpperCase()[0]}
                                          </Avatar>
                                        )}
                                        <div>
                                          <a
                                            className="ml-3"
                                            href="#"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              history.push({
                                                pathname: "/staff-update",
                                                userId: data.id,
                                              });
                                            }}
                                          >
                                            {data.firstName} {data.lastName}
                                          </a>
                                          {data.mobile &&
                                            data.mobile != "null" && (
                                              <div
                                                style={{ fontWeight: "400" }}
                                              >
                                                <img
                                                  src={Call}
                                                  alt=""
                                                  className="ml-3"
                                                  width={"16px"}
                                                />
                                                {data.mobile}
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>{Util.staffIdFormat(data.id)}</td>
                                    <td>
                                      <i
                                        className="fa fa-envelope-o mr-1"
                                        aria-hidden="true"
                                      ></i>
                                      {data.email}
                                    </td>
                                    <td className="requested">
                                      {data.user_Schools[0].RoleId !== null ? (
                                        data.user_Schools[0].role.role
                                      ) : (
                                        <i className="text-warning">
                                          {t("Requested")}
                                        </i>
                                      )}
                                      <span className="table__button-group">
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setRolePopup(true);
                                            setUserSchoolId(
                                              data.user_Schools[0].id
                                            );
                                            // data.user_Schools[0].RoleId !== null
                                            //   ? <>{setRoleChangeById(
                                            //     data.user_Schools[0].RoleId
                                            //   )}</>
                                            //   : setRoleChangeById(null);
                                            data.user_Schools[0].RoleId !== null
                                              ? setEditRole(
                                                  data.user_Schools[0].RoleId
                                                )
                                              : setEditRole("");
                                          }}
                                        >
                                          <i className="fa fa-pen ml-2"></i>
                                        </a>
                                      </span>
                                    </td>
                                    <td>
                                      <TagButton
                                        style={"btn btn-secondary"}
                                        size={"100px"}
                                        title={`${
                                          data.email_verified
                                            ? "Completed"
                                            : "Pending"
                                        }`}
                                        buttonStyle={
                                          data.email_verified
                                            ? greenTagStyle
                                            : orangeTagStyle
                                        }
                                        icon="fa fa-circle"
                                        iconSize="6px"
                                      />
                                      {/* {data.email_verified ? (
                                      <i className="fa fa-check"></i>
                                    ) : (
                                      <span className="text-danger">
                                        {t("Not Registered Yet")}
                                      </span>
                                    )} */}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          ) : (
                            <tfoot>
                              <tr>
                                <td colSpan={6}>
                                  {t(
                                    "No Staff Member Found! Please recheck the filter or Create One"
                                  )}
                                </td>
                              </tr>
                            </tfoot>
                          )}
                        </table>
                      </div>
                      {totalPages > 0 && (
                        <div
                          className="row m-0 table-footer  border-top"
                          style={{ height: "72px" }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center gap-2">
                              <div>{t("Students Per Page")}</div>
                              <select
                                style={{ width: "72px", cursor: "pointer" }}
                                className="select-dropdown custom-input"
                                value={recordPerPage}
                                onChange={(e) => {
                                  setRecordPerPage(e.target.value);
                                }}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                            <div>
                              <Stack
                                spacing={2}
                                direction="row"
                                alignItems="center"
                              >
                                <div
                                  className={`link-button ${
                                    currentPage == 1 ? "disabled" : ""
                                  }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handlePreviousClick();
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <KeyboardArrowLeftIcon />
                                  <span
                                    className="basic-bold-text"
                                  >
                                    {t("Previous")}
                                  </span>
                                </div>
                                <Pagination
                                  count={totalPages}
                                  page={currentPage}
                                  onChange={(event, page) => {
                                    setCurrentPage(page);
                                  }}
                                  size="small"
                                  hideNextButton
                                  hidePrevButton
                                />
                                <div
                                  className={`link-button ${
                                    currentPage == totalPages ? "disabled" : ""
                                  }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleNextClick();
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    className="basic-bold-text"
                                  >
                                    {t("Next")}
                                  </span>
                                  <KeyboardArrowRightIcon />
                                </div>
                              </Stack>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <SwipeableDrawer
                anchor={getLanguage == "ar" ? "left" : "right"}
                open={rolePopup}
                onClose={() => {
                  setRolePopup(false);
                  setEditRoleError("");
                }}
                onOpen={()=>{}}
              >
                <div
                  className="box-area"
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{ width: "656px", padding: "20px" }}
                    role="presentation"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                    <div className="drawer-header">{t("Change Role")}</div>
                    <i
                      className="fa fa-times"
                      onClick={() => {
                        setRolePopup(false);
                        setEditRoleError("");
                      }}
                    ></i>
                    </div>
                    <div className="mt-2 ">
                      <span className="basic-text required">{t("Role")}</span>
                      <select
                        className={`form-select select-dropdown custom-input ${
                          editRoleError.length > 0 && "input-error"
                        }`}
                        value={editRole}
                        onChange={handleRoleChange}
                      >
                        <option value="">{t("Select Role")}</option>
                        {role.map((option) => {
                          if (editRole == option.role) {
                            return (
                              <option
                                selected
                                value={option.id}
                                key={option.id}
                              >
                                {option.role}
                              </option>
                            );
                          } else {
                            return (
                              <option value={option.id} key={option.id}>
                                {option.role}
                              </option>
                            );
                          }
                        })}
                      </select>
                      {editRoleError.length > 0 && (
                        <span className="invalid-error text-danger">
                          <img
                            src={Invalid}
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                          />
                          {editRoleError}
                        </span>
                      )}
                    </div>
                    <div
                      className="col-12 text-right mt-2  "
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        left: 0,
                        right: 0,
                      }}
                    >
                      <button
                        style={{ width: "78px", height: "48px" }}
                        className="cancel-btn btn mr-2"
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        style={{ width: "120px", height: "48px" }}
                        className="confirm-btn btn"
                        onClick={handleEditRole}
                      >
                        {t("Save Role")}
                      </button>
                    </div>
                  </Box>
                </div>
              </SwipeableDrawer>
              <ModalComponent
                show={roleAlert}
                onHide={() => {
                  setRoleAlert(false);
                  setRolePopup(false);
                }}
                title={t("Change Role Staff")}
                subTitle={`${t(
                  "Are you sure want to change role"
                )} ${editRoleName}?`}
                size="sm"
                onCancel={t("Cancel")}
                onConfirm={t("Confirm")}
                onCancelClick={() => {
                  setRoleAlert(false);
                  setRolePopup(false);
                }}
                onConfirmClick={handleConfirm}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={invitationPopup}
        onHide={invitationSent}
        animation={true}
        centered={true}
        className="modal-view"
        onShow={() => {
          setInvitationMailNoteError("");
        }}
      >
        <div className="d-flex justify-content-between align-items center p-4 border-bottom ">
          <strong>{t("Add Note")}</strong>

          <button className="hidden-btn" onClick={invitationSent}>
            <img src={close} className="svg-icon" height={20} width={20} />
          </button>
        </div>
        <Modal.Body>
          <div className="input-filed text-start">
            <span className="basic-text required">{t("Note")}</span>
            <textarea
              style={{ width: "100%", minHeight: "120px" }}
              className={`form-control custom-input ${
                invitationMailNoteError.length > 0 && "input-error"
              }`}
              type="text"
              placeholder="Enter Note"
              value={invitationMailNote}
              onChange={(event) => {
                setInvitationMailNote(event.target.value);
                setInvitationMailNoteError("");
              }}
            />
            {invitationMailNoteError.length > 0 && (
              <span className="invalid-error text-danger">
                <img
                  src={Invalid}
                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                />
                {invitationMailNoteError}
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button
            className="btn btn-lg custom-primary"
            onClick={inviteAllSelected}
          >
            {t("Invite All Selected Staff")} ({selectedUserId.length})
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        style={{ borderRadius: "8px" }}
        size="sm"
        onHide={() => {
          setInvitationAlert(false);
        }}
        show={invitationAlert}
        animation={true}
        centered={true}
        className="custom-attendance-modal"
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>

        <Modal.Body>
          <div className="text-center ">
            <h5>{t("Can Not Send Invitation")}</h5>

            <div className="basic-text">
              {t(
                "Looks like you have selected a user who has already Registered"
              )}
              .
            </div>

            <p>{t("please check the user list and try again")}.</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ToastHOC(Staff);
