import React from "react";
import ToastHOC from "../HOC/ToastHOC";
import ApiCalling from "../../network/ApiCalling";
import Password from "./Password";
import WorkInfo from "./WorkInfo";
import Util from "../../Util";
import { withTranslation } from "react-i18next";
import MyProfileStaff from "./MyProfileStaff";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import moment from "moment/moment";

class MyProfileTab extends React.Component {
  constructor(props) {
    super(props);
    this.user = Util.getUser();
    this.state = {
      getUserDetails: null,
    };
  }
  getUserData = async () => {
    ApiCalling.apiCallParamsGet(
      "user/get-userdetail-byid/" + this.user.id
    ).then((res) => {
      this.setState({ getUserDetails: res.data.user });
    });
  };

  componentDidMount() {
    const { setIsToast } = this.props;
    const pendingToast = this.props.location?.pendingToast;
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
    this.getUserData();
  }
  onTabChange = (e) => {
    var tab1 = document.getElementById("bacicTab-1");
    tab1.classList.remove("active");
    tab1.children[0].classList.remove("active");
    var tab2 = document.getElementById("bacicTab-2");
    tab2.classList.remove("active");
    tab2.children[0].classList.remove("active");
    var tab3 = document.getElementById("bacicTab-3");
    tab3.classList.remove("active");
    tab3.children[0].classList.remove("active");

    // var tab4 = document.getElementById("bacicTab-4");
    // tab4.classList.remove("active");
    // tab4.children[0].classList.remove("active");

    var actab = document.getElementById("bacicTab-" + e);
    actab.classList.remove("active");
    actab.children[0].classList.add("active");

    var tabpan1 = document.getElementById("bacicTabpan-1");
    tabpan1.classList.remove("active");
    var tabpan2 = document.getElementById("bacicTabpan-2");
    tabpan2.classList.remove("active");
    var tabpan3 = document.getElementById("bacicTabpan-3");
    tabpan3.classList.remove("active");
    // var tabpan4 = document.getElementById("bacicTabpan-4");
    // tabpan4.classList.remove("active");

    var actabpab = document.getElementById("bacicTabpan-" + e);
    actabpab.classList.add("active");
  };

  render() {
    const { t } = this.props;
    const ParentMode = Util.getParentMode();
    const { history } = this.props;
    return (
      <div>
        <div className="container-fluid">
          <div className="body">
            <div className="container-80">
              <div className="row mb-4 mt-2 ">
                <div className="col-md-6">
                  <h2 className="page-head">{t("My Profile")}</h2>
                </div>
              </div>
              <ul className="nav nav-tabs" role="tablist">
                <li
                  className="nav-item active"
                  id="bacicTab-1"
                  role="presentation"
                  onClick={() => {
                    this.onTabChange(1);
                  }}
                >
                  <a className="nav-link active">{t("My Profile")}</a>
                </li>

                <li
                  className="nav-item mr-1"
                  id="bacicTab-2"
                  role="presentation"
                  onClick={() => {
                    this.onTabChange(2);
                  }}
                >
                  <a className="nav-link" data-toggle="tab">
                    {t("Password")}
                  </a>
                </li>
                
                {ParentMode ? (
                  <li
                    className="nav-item mr-1"
                    id="bacicTab-3"
                    role="presentation"
                    onClick={() => {
                      this.onTabChange(3);
                    }}
                  >
                    <a className="nav-link" data-toggle="tab">
                      {t("Work Info")}
                    </a>
                  </li>
                ) : (
                  <li
                    className="nav-item mr-1"
                    id="bacicTab-3"
                    role="presentation"
                    onClick={() => {
                      this.onTabChange(3);
                    }}
                  >
                    <a className="nav-link" data-toggle="tab"/>
                      
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="tab-content">
            <div id="bacicTabpan-1" className="tab-pane active">
            <MyProfileStaff props={this.props} userDetails={this.state.getUserDetails}/>
            </div>

            <div id="bacicTabpan-2" className="tab-pane">
              <Password props={this.props} />
            </div>
            {ParentMode ? (
              <div id="bacicTabpan-3" className="tab-pane">
                <WorkInfo
                  props={this.props}
                  userDetails={this.state.getUserDetails}
                />
              </div>
            ) : (
              <div id="bacicTabpan-3" className="tab-pane"/>
                
            )}
           
          </div>
        </div>
      </div>
    );
  }
}

export default ToastHOC(withTranslation()(MyProfileTab));
