import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Divider } from "./Staff/StaffDetails";
import Util from "../../Util";
import unknownImg from "../../assets/images/Frame 1000004000.png";
import close from "../../assets/Icon/close.svg";
import { imageStudentList } from "../CommonImage";
import rotate from "../../assets/Icon/Rotate.svg";
import ApiCalling from "../../network/ApiCalling";
import facedetectionicon from "../../assets/images/loading.gif";
import StudentAvtar from "../../assets/Icon/StudentAvtar.svg";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Slider } from "@mui/material";
import Loader from "../../components/common/Loader";
import Const from "../../Const";

const EditPhotoModal = ({
  show,
  onClose,
  title,
  subTitle,
  des,
  onChange,
  onSave,
  // ProfilePhoto,
  Id,
  isFaceDetection,
  photoREF,
  canvasREF,
  photoURL,
  handlePhotoChange,
  handleSavePhoto,
  faceDetail,
  faceError,
  clearFaceError,
  defaultAvtar,
  isLoading,
}) => {
  const getLanguage = Util.getSelectedLanguage();
  const containerSize = 300;
  const containerSize1 = 200;
  const containerSize2 = 140;
  const containerSize3 = 80;
  const containerSize4 = 52;
  // const [profilePhoto, setProfilePhoto] = useState("");
  const [zoomLevel, setZoomLevel] = useState(10);
  const [rotationAngle, setRotationAngle] = useState(0);

  const handleZoomChange = (event, newValue) => {
    setZoomLevel(newValue);
  };
  const handleRotateClick = () => {
    setRotationAngle(rotationAngle + 90);
  };

  return (
    <>
      <Modal
        style={{ borderRadius: "8px" }}
        size="lg"
        onHide={() => {
          onClose("msg");
        }}
        show={show}
        animation={true}
        centered={true}
        className="custom-photo-modal "
        onShow={() => {
          if (faceError) {
            clearFaceError();
          }
        }}
      >
        <div className="row ">
          <div className="">
            <div className="header-row px-3 py-2 mt-1 mb-0 d-flex justify-content-between align-items-center">
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  marginBottom: "0px",
                }}
              >
                {title}
              </p>
              <div className="close-div">
                <IconButton
                  aria-label="close"
                  size="small"
                  className="float-right"
                  onClick={() => {
                    onClose("msg");
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <hr style={{ backgroundColor: "#E5E7EB", margin: "0px 0" }} />
            {!isLoading ? (
              <Modal.Body>
                <div className="d-flex px-2">
                  <div className="clickable-div">
                    {photoURL !== null && photoURL !== undefined && (
                      <>
                        {isFaceDetection && (
                          <>
                            <img
                              style={{
                                zIndex: "1001",
                                position: "absolute",
                                right: getLanguage == "ar" && "0px",
                                left: getLanguage !== "ar" && "0px",
                                top: "0px",
                              }}
                              src={facedetectionicon}
                            />
                          </>
                        )}
                      </>
                    )}

                    {photoURL !== null && photoURL !== undefined && (
                      <>
                        <div
                          className="match-profile"
                          style={{
                            position: "relative",
                            borderRadius: "50%",
                            border: "2px solid #FFFFFF",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            className="match-inner-div"
                            style={{
                              position: "absolute",
                              top: "-4px",
                              left: "4px",
                              // width: containerSize,
                              // height: containerSize,

                              borderRadius: "50%",
                              // transform: `scale(${
                              //   zoomLevel / 10
                              // }) rotate(${rotationAngle}deg)`,
                              transform: `rotate(${rotationAngle}deg)`,
                              transformOrigin: "center",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              alt="User"
                              className="img-responsive  mt-1"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              src={photoURL}
                              ref={photoREF && photoREF}
                            />
                          </div>

                          {!faceError && (
                            <canvas
                              style={{ position: "absolute" }}
                              ref={canvasREF && canvasREF}
                              // width={containerSize}
                              // height={containerSize}
                              width={"340px"}
                              height={"340px"}
                            />
                          )}
                        </div>
                        <div className="d-flex justify-content-center align-items-center gap-2 mt-2">
                          <Slider
                            value={zoomLevel}
                            onChange={handleZoomChange}
                            aria-labelledby="zoom-slider"
                            min={10}
                            max={200}
                            step={10}
                            sx={{color:Const.primaryColor}}
                          />
                          <img
                            src={rotate}
                            style={{
                              cursor: "pointer"
                            }}
                            alt=""
                            className="svg-icon rotate-img"
                            onClick={handleRotateClick}
                          />
                          <span style={{ color: Const.primaryColor }}>Rotate</span>
                        </div>
                      </>
                    )}
                    {faceError && (
                      <div className="text-danger">{faceError}</div>
                    )}
                  </div>

                  <div className="mx-3 ml-5">
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#07112B",
                      }}
                    >
                      {subTitle}
                      <br />
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#07112B",
                        }}
                      >
                        {des ? des : " Must be an actual photo of you"}
                      </span>
                    </p>
                    <div className="d-flex justify-content-center align-items-end gap-4">
                      {photoURL !== null && photoURL !== undefined && (
                        <>
                          <div
                            className="inherit-div"
                            style={{
                              position: "relative",

                              // width: containerSize1,
                              // height: containerSize1,
                              borderRadius: "50%",
                              border: "2px solid #FFFFFF",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "-4px",
                                left: "4px",
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                                transform: `scale(${
                                  zoomLevel / 10
                                }) rotate(${rotationAngle}deg)`,
                                transformOrigin: "center",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                alt="User"
                                className="img-responsive  mt-1"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={photoURL}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {photoURL !== null && photoURL !== undefined && (
                        <>
                          <div
                            style={{
                              position: "relative",

                              borderRadius: "50%",
                              border: "2px solid #FFFFFF",
                              overflow: "hidden",
                            }}
                            className="inherit-div-two"
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "-4px",
                                left: "4px",
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                                transform: `scale(${
                                  zoomLevel / 10
                                }) rotate(${rotationAngle}deg)`,
                                transformOrigin: "center",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                alt="User"
                                className="img-responsive  mt-1"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={photoURL}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {photoURL !== null && photoURL !== undefined && (
                        <div
                          style={{
                            position: "relative",

                            borderRadius: "50%",
                            border: "2px solid #FFFFFF",
                            overflow: "hidden",
                          }}
                          className="inherit-div-third"
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "-4px",
                              left: "4px",
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              transform: `scale(${
                                zoomLevel / 10
                              }) rotate(${rotationAngle}deg)`,
                              transformOrigin: "center",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              alt="User"
                              className="img-responsive  mt-1"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              src={photoURL}
                            />
                          </div>
                        </div>
                      )}
                      {photoURL !== null && photoURL !== undefined && (
                        <div
                          style={{
                            position: "relative",

                            borderRadius: "50%",
                            border: "2px solid #FFFFFF",
                            overflow: "hidden",
                          }}
                          className="inherit-div-four"
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "-4px",
                              left: "4px",
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              transform: `scale(${
                                zoomLevel / 10
                              }) rotate(${rotationAngle}deg)`,
                              transformOrigin: "center",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              alt="User"
                              className="img-responsive  mt-1"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              src={photoURL}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
            ) : (
              <Loader />
            )}

            <hr style={{ backgroundColor: "#E5E7EB", margin: "0px 0" }} />
            <Modal.Footer className="border-0">
              <button
                style={{ width: "126px", height: "48px" }}
                className="secondary-modal-btn btn"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("student").click();
                }}
              >
                {onChange}
              </button>
              <input
                className="sr-only"
                id="student"
                type="file"
                accept="image/*"
                onChange={handlePhotoChange}
              />
              <button
                className="primary-modal-btn btn"
                onClick={handleSavePhoto}
                disabled={faceDetail === null || photoURL.length === 0}
              >
                {onSave}
              </button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditPhotoModal;
