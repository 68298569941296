import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader";
import { Modal, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import ApiCalling from "../../../network/ApiCalling";
import { Rings } from "react-loader-spinner";
import ToastHOC from "../../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import Sort from "../../../assets/Icon/Short.svg";
import ModalComponent from "../ModalComponent";
import { useSelector } from "react-redux";
import Loader from "../../../components/common/Loader";

const Role = (props) => {
  const history = useHistory();
  const { setIsToast } = props;
  const isUpdate = useSelector((state) => state.SwitchTabReducer);

  const changeTab = props.changeTab;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [sortColumn, setSortColumn] = useState("Role");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedRoleList = [...roleList].sort((a, b) => {
    if (sortColumn === "Role") {
      return sortDirection === "asc"
        ? a.role.localeCompare(b.role)
        : b.role.localeCompare(a.role);
    } else if (sortColumn === "Staff Count") {
      return sortDirection === "asc"
        ? a.userSchools.length - b.userSchools.length
        : b.userSchools.length - a.userSchools.length;
    }
    return 0;
  });

  const getData = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("role/list").then((res) => {
      setRoleList(res.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  const handleDelete = () => {
    ApiCalling.apiCallDelete(`role/${deleteId}`)
      .then((res) => {
        setIsToast(t("Role Deleted Successfully"));
        setShowDeleteConfirmation(false);
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="heading text-right mb-3 px-0">
              <button
                className={`${
                  Util.hasPermission("MASTERS_ADD")
                    ? "btn custom-primary"
                    : "no-permission"
                }`}
                type="button"
                aria-expanded="false"
                onClick={() => {
                  history.push({
                    pathname: "/permission",
                    role: null,
                    isAddRole: true,
                  });
                }}
              >
                {t("Add Role")}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="table-div overflow-hidden p-0">
              <div className="col-lg-12 m-0 p-0">
                <div className="body p-0">
                  {!isLoading ? (
                    <>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th scope="col">
                              {t("Role")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("Role");
                                }}
                              />
                            </th>
                            <th scope="col">
                              {t("Total Staff")}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                onClick={() => {
                                  handleSort("Staff Count");
                                }}
                              />
                            </th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        {roleList?.length > 0 ? (
                          <tbody>
                            {sortedRoleList?.map((item, index) => (
                              <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      history.push({
                                        pathname: "/permission",
                                        role: item,
                                        isAddRole: false,
                                      });
                                    }}
                                  >
                                    {item.role}
                                  </a>
                                </td>
                                <td>{item.userSchools?.length}</td>
                                <td>
                                  <a
                                    role="button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      history.push({
                                        pathname: "/permission",
                                        role: item,
                                        isAddRole: false,
                                      });
                                    }}
                                  >
                                    {!item.readonly ? (
                                      <i className="fa fa-edit"></i>
                                    ) : (
                                      <i className="fa fa-eye"></i>
                                    )}
                                  </a>

                                  {item.userSchools.length === 0 &&
                                  !item.readonly ? (
                                    <a
                                     role="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setShowDeleteConfirmation(true);
                                        setDeleteId(item.id);
                                      }}
                                      className={`${
                                        Util.hasPermission("MASTERS_DELETE")
                                          ? "pl-5"
                                          : "no-permission"
                                      }`}
                                    >
                                      <i className="fa fa-trash-o text-danger"></i>
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tfoot>
                            <tr>
                              <td colSpan={4}>
                                {t(
                                  "There are no Role. Click the 'Add Role' button to create one"
                                )}
                                .
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                      {roleList.length > 0 && (
                        <div className="small text-muted pl-3 mb-3 border-top py-3">
                          <i>
                            {roleList.length} {t("role found")}.
                          </i>
                        </div>
                      )}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalComponent
          show={showDeleteConfirmation}
          onHide={() => {
            setShowDeleteConfirmation(false);
          }}
          title={t("Delete")}
          onCancel={t("Cancel")}
          onConfirm={t("Confirm")}
          subTitle={t("Are you sure, to delete this Role?")}
          size="sm"
          onCancelClick={() => {
            setShowDeleteConfirmation(false);
          }}
          onConfirmClick={handleDelete}
        />
      </div>
    </>
  );
};

export default ToastHOC(Role);
