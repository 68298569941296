import React, { useEffect, useState } from "react";
import Util from "../../Util";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import student from "../../assets/Icon/user.svg";
import { SeparateDot } from "../../components/common/separateDot";
import { useTranslation } from "react-i18next";
import check from "../../assets/Icon/check-double.svg";
export const SeprateDot = () => {
  return <span className="sepration-dot"></span>;
};
export default function ParentList(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const parentUsers = props?.ParentList;
  const searchClick = props?.searchParentClick;
  const [selectedUserId, setSelectedUserId] = useState(props?.selectedUserId);
  // const [parentUsers,setParentUsers]=useState([]);

  function createRoom(user1) {
    setSelectedUserId(user1.user.id);
    props.startChat(user1);
  }

  return (
    <>
      {parentUsers != undefined && parentUsers.length > 0 ? (
        <ul className="list-unstyled chat-list mt-2 mb-0">
          {parentUsers
            .sort((a, b) => {
              if (a.unread > 0 || b.unread > 0)
                return b.unread > a.unread ? 1 : -1;
              else if (a.last_message_on !== "" && b.last_message_on !== "") {
                return new Date(b.last_message_on) > new Date(a.last_message_on)
                  ? 1
                  : -1;
              } else if (a.last_message_on === "" && b.last_message_on === "") {
                return a.user.firstName < b.user.firstName ? -1 : 1;
              } else {
                return b.last_message_on.length - a.last_message_on.length;
              }
            })
            .map((item,index) => {
              const name = Util.getShortString(
                item.user.firstName + " " + item.user.lastName,
                17
              );
              return (
             
                  <li
                    key={index}
                    className={selectedUserId == item?.user?.id ? "active" : ""}
                  >
                    <a
                      onClick={() => {
                        createRoom(item);
                      }}
                      className="d-flex"
                    >
                      {Util.getUserAvatar(item.user)}
                      <div
                        className={`row m-0 about ${item.unread > 0 ? "unread" : ""}`}
                      >
                        <div className="col-9 name text-start">
                          {item.unread > 0 ? (
                            <strong>{name}</strong>
                          ) : (
                            <>{name}</>
                          )}
                        </div>
                        <div className="col-3 last-message-time text-end">
                          {Util.getDateDisplay_ChatMessage(
                            item.last_message_on
                          )}
                        </div>
                        

                        <div className="child d-flex align-items-center">
                          <img
                            className="mr-1"
                            src={student}
                            height={12}
                            width={12}
                          />
                          {item.child[0].Student.first_name +
                            " " +
                            item.child[0].Student.last_name}
                          <SeparateDot />
                          {t("Class")}: {item.child[0].Student.classroom.name}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            className="last-message text-start"
                            style={{
                              overflow: "hidden",
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              textOverflow: "ellipsis",
                              whiteSpace: "normal", // This allows the text to wrap if it's less than two lines
                              maxHeight: "3.6em", // You can adjust this value to control the height for two lines
                            }}
                          >
                            {Util.isValidData(item.last_message)
                              ? item.last_message
                              : ""}
                          </div>

                          {item.isOnline && (
                            <div className="online text-right">
                              <img src={check} />
                            </div>
                          )}
                        </div>
                      </div>
                    </a>
                  </li>
               
              );
            })}
        </ul>
      ) : (
        <div className="row">
          <div className="col-lg-12 offset-lg-12">
            <div className="d-flex text-center p-5">
              <i
                className=" card p-5 icon-book-open"
                style={{
                  fontSize: "50px",
                  opacity: "0.3",
                }}
              ></i>
            </div>
            <p className="text-center small text-muted">{t("Parents Not Found")}</p>
          </div>
        </div>
      )}
    </>
  );
}
