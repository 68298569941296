import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import Util from "../../Util";
import { Dropdown, Modal } from "react-bootstrap";
import { imageStudentList, imageUserFeed } from "../../screens/CommonImage";
import clock from "../../assets/Icon/clock.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ActivityCell(props, { loadComp, setLoadComp }) {
  const { t } = useTranslation();
  const history = useHistory();
  const is_staff = props.staff;
  const user = Util.getUser();
  const school = Util.getSelectedSchool();
  const [data, setData] = useState(null);
  const [deleteActivityPopup, setDeleteActivityPopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [toast, setToast] = useState("");
  const handleShowDeleteActivity = () => {
    setDeleteActivityPopup(true);
  };
  const handleCloseDeleteActivity = () => {
    setDeleteActivityPopup(false);
  };
  useEffect(() => {
    setData(props.activity);
  }, []);

  useEffect(() => {
    setData(props.activity);
  }, [props]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
    </a>
  ));
  return (
    <>
      <div className="vertical-line">
        {data ? (
          <>
            <div className="activity-card mb-2 ">
             
                <div className="post-header d-flex justify-content-start align-items-center">
                  
                    {Util.getIconActivity(data.Category)}
                  
                  
                    <div className="post-details ">
                      <h4 className="medium-lg-text mx-2">
                        {data.Category.title}
                      </h4>

                      <div className="d-flex align-items-center mx-2 mt-1">
                        <img src={clock} height={"16px"} width={"16px"} />
                        <span className="sm-text mx-1">
                          {moment(data?.created_on).format("hh:mm A")}
                        </span>
                      </div>
                    </div>
                    <div className="dots-button">
                {is_staff === true ? (
                  <>
                    {data.created_by === user.id ? (
                      <div
                        className={`${
                          Util.hasPermission("ACTIVITY_EDIT") ||
                          Util.hasPermission("ACTIVITY_DELETE")
                            ? ""
                            : "no-permission"
                        }`}
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            as={CustomToggle}
                            id="dropdown-basic"
                            className="user-name"
                          ></Dropdown.Toggle>

                          <Dropdown.Menu
                            className="dropdown-menu-right account"
                            style={{
                              backgroundColor: "white",
                            }}
                          >
                            <Dropdown.Item
                              onClick={() => {
                                props.editActivity();
                              }}
                              style={{
                                display:
                                  !Util.hasPermission("ACTIVITY_EDIT") &&
                                  "none",
                              }}
                            >
                              {" "}
                              <i className="fa-regular fa-edit mr-2"></i>
                              {t("Edit")}
                            </Dropdown.Item>
                            <hr color="black" />
                            <Dropdown.Item
                              onClick={() => {
                                handleShowDeleteActivity();
                                // history.push({
                                //   deleteActivityId: data.id,
                                // });
                              }}
                              style={{
                                display:
                                  !Util.hasPermission("ACTIVITY_DELETE") &&
                                  "none",
                              }}
                            >
                              <i className="fa-regular fa-trash-can mr-2"></i>
                              {t("Delete")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
                </div>
                <div className="card-content-details">
                  <div className="activity-description text-start">{data.description}</div>
                </div>
                <div className="students students-flex ">
                  {data?.ActivityStudents.slice(0, 5).map((students,index) => {
                    return (
                      <div key={index}>
                        {students.Student !== null ? (
                          <>
                            <span
                              className="badge badge-activity"
                              key={students.Student.id}
                            >
                              <img
                                className="rounded-circle mr-2"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                src={
                                  students.Student.photo
                                    ? imageStudentList + students.Student.photo
                                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                }
                                alt=""
                              />
                              {students.Student.first_name +
                                " " +
                                students.Student.last_name}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}

                  {data?.ActivityStudents.length > 5 && (
                    <>
                      <div className="dropdown">
                        <span
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          className="badge badge-activity badge-activity-count"
                        >
                          {data?.ActivityStudents.length - 5 + "+"}
                        </span>

                        <ul
                          className="dropdown-menu more-student-dropdown"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          {data.ActivityStudents.slice(4).map(
                            (student, index) => (
                              <li key={index}>
                                <a className="dropdown-item">
                                  <div className="d-flex align-items-center gap-2">
                                    <img
                                      className="rounded-circle"
                                      height={20}
                                      width={20}
                                      src={
                                        student.Student.photo
                                          ? imageUserFeed +
                                            student.Student.photo
                                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                      }
                                    />
                                    <div>
                                      {student.Student.first_name +
                                        " " +
                                        student.Student.last_name}
                                    </div>
                                  </div>
                                </a>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              
            </div>
            <Modal
              size="md"
              show={deleteActivityPopup}
              onHide={handleCloseDeleteActivity}
              animation={true}
              centered={true}
              dialogClassName="modal-view"
            >
              <Modal.Header closeButton>
                <h6>{t("Delete Activity")}?</h6>
              </Modal.Header>

              <Modal.Body>
                <strong>
                  {t("Are you sure you want to delete this Activity")}?
                </strong>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-link"
                  onClick={handleCloseDeleteActivity}
                >
                  {t("No")}
                </button>
                <button
                  className="btn custom-primary"
                  onClick={() => {
                    props.deleteActivity();
                    handleCloseDeleteActivity();
                    setToast(t("Activity Deleted Successfully"));
                  }}
                >
                  {t("Yes")}
                </button>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
