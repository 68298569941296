import React from "react";
import ApiCalling from "./network/ApiCalling";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: "",
      error: null,
      stack: "",
    };
  }

  getErrorlog = () => {
    const data = {
      message: this.state.errorMessage,
      stack: this.state.stack,
      url: this.props.location.pathname,
      type: "BROWSER",
    };
    ApiCalling.apiCallBodyDataPost("error-logs/add", data)
      .then((res) => {
        console.log("Error", res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidCatch(error, errorInfo) {
    console.error(
      "error error error ::::::",
      errorInfo.componentStack,
      this.props.location.pathname
    );
    this.setState(
      {
        hasError: true,
        errorMessage: error.message,
        stack: errorInfo.componentStack,
      },
      () => {
        this.getErrorlog();
      }
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
        
          {/* <div>
            <h1>Something went wrong!</h1>
          </div> */}
        </>
      );
    } else {
      return this.props.children;
    }
  }
}
export default withRouter(ErrorBoundary);
