  
import { SET_ACADEMIC_YEAR } from "../actions/ToggleAction";
import Util from "../Util";

// Initial State
const initialState = {
  value: Util.getAcadmicYear(),
};

// Reducer
const academicYearReducer = (state = initialState, action) => {
  
  
  switch (action.type) {
    case SET_ACADEMIC_YEAR:
      Util.saveAcadmicYear(action.payload); // Save to localStorage
      return {
        ...state,
        value: action.payload,
      };
    default:
      return state;
  }
};

export default academicYearReducer;
