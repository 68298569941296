import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import ApiCalling from "../../../network/ApiCalling";

import Util from "../../../Util";
import Loader from "../../../components/common/Loader";
import Invalid from "../../../assets/Icon/invalid.svg";

const WorkInfo = ({ workInfo, UserId, onSave, workInfoEdit, onCancel }) => {
  const { t } = useTranslation();

  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneAlt, setPhoneAlt] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState(0);
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [stateList, setStateList] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState("");
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  const countryList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet("country").then((res) => {
      setIsCountryLoading(false);
      setCountry(res?.data);
    });
  };

  const cityList = async (id) => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res?.data);
    });
  };

  const getStateList = async (id) => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${countryId}`).then(
      (res) => {
        setIsCountryLoading(false);
        setStateList(res?.data);
      }
    );
  };

  const handleSaveWorkDetails = () => {
    let error = false;
    setZipCodeError("");
    if (zipCode !== "" && Util.Zip_regex.test(zipCode) === false) {
      setZipCodeError(
        t("Please enter valid Pin-code(i.e: 12345 or 12345-2563)")
      );
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("id", UserId);
      if (companyName) {
        formData.append("work_company", companyName);
      }
      if (phone) {
        formData.append("work_phone", phone);
      }
      if (phoneAlt) {
        formData.append("work_phone_alternate", phoneAlt);
      }
      if (address) {
        formData.append("work_address1", address);
      }
      if (countryId) {
        formData.append("work_country_id", countryId);
      }
      if (stateId) {
        formData.append("work_state_id", stateId);
      }
      if (cityId) {
        formData.append("work_city_id", cityId);
      }
      if (zipCode) {
        formData.append("work_zip", zipCode);
      }
      ApiCalling.apiCallBodyDataPost("user/dynamic-update", formData)
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            setZipCodeError("");
            onSave(res.data.message);
          } else {
            onSave(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  useEffect(() => {
    countryList();
  }, []);
  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);

  useEffect(() => {
    if (countryId) {
      getStateList();
    }
  }, [countryId]);

  useEffect(() => {
    if (workInfo !== null) {
      setCompanyName(workInfo?.User?.work_company);
      setPhone(workInfo?.User?.work_phone);
      setPhoneAlt(workInfo?.User?.work_phone_alternate);
      setAddress(workInfo?.User?.work_address1);
      setSelectedCity(workInfo?.User?.Work_City);
      setSelectedCountry(workInfo?.User?.Work_Country);
      setSelectedState(workInfo?.User?.Work_State);
      setCityId(
        Util.isValidData(workInfo?.User?.work_city_id)
          ? workInfo?.User?.work_city_id
          : 0
      );
      setStateId(
        Util.isValidData(workInfo?.User?.work_state_id)
          ? workInfo?.User?.work_state_id
          : 0
      );
      setCountryId(
        Util.isValidData(workInfo?.User?.work_country_id)
          ? workInfo?.User?.work_country_id
          : 0
      );
      setZipCode(Util.isValidData(workInfo?.User?.work_zip) ? workInfo?.User?.work_zip : "");
    }
  }, [workInfo]);
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);
  return (
    <>
      <div className="row text-start">
        {!isLoading ? (
          <>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span
                  className="basic-text "
                  style={{ fontWeight: "400" }}
                >
                  {t("Company Name")}
                </span>
                {!workInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(companyName) ? companyName : "-"}
                  </div>
                ) : (
                  <>
                    <input
                      className="form-control custom-input"
                      value={companyName}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text">
                  {t("Work Phone Number")}
                </span>
                {!workInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(phone) ? phone : "-"}
                  </div>
                ) : (
                  <>
                    <div className="form-control custom-input" tabIndex={0}>
                      <PhoneInput
                      tabIndex="-1"
                        international
                        defaultCountry={defaultCountry}
                        value={phone}
                        onChange={(value) => {
                          setPhone(value);
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text" style={{ fontWeight: "400" }}>
                  {t("Alternate Phone Number")}
                </span>
                {!workInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(phoneAlt) ? phoneAlt : "-"}
                  </div>
                ) : (
                  <>
                    <div className="form-control custom-input" tabIndex={0}>
                      <PhoneInput
                      tabIndex="-1"
                        international
                        defaultCountry={defaultCountry}
                        value={phoneAlt}
                        onChange={(value) => {
                          setPhoneAlt(value);
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span
                  className="basic-text "
                >
                  {t("Address 1")}
                </span>
                {!workInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(address) ? address : "-"}
                  </div>
                ) : (
                  <>
                    <input
                      className="form-control custom-input"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text ">{t("Country")}</span>
                {!workInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(selectedCountry) ? selectedCountry : "-"}
                  </div>
                ) : (
                  <>
                    <select
                      className="select-dropdown form-select custom-input"
                      value={countryId}
                      onChange={(e) => {
                        setCountryId(e.target.value);
                      }}
                    >
                      <option value={0}>
                        {isCountryLoading ? t("Loading...") : t("Select Country")}
                      </option>
                      {country.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text ">{t("State")}</span>
                {!workInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(selectedState) ? selectedState : "-"}
                  </div>
                ) : (
                  <>
                    <select
                      className="select-dropdown form-select custom-input"
                      value={stateId}
                      onChange={(e) => {
                        setStateId(e.target.value);
                      }}
                      disabled={countryId == 0}
                    >
                      <option disabled value={0}>
                        {isCountryLoading ? t("Loading...") : t("Select State")}
                      </option>
                      {stateList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span className="basic-text ">{t("City")}</span>
                {!workInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(selectedCity) ? selectedCity : "-"}
                  </div>
                ) : (
                  <>
                    <select
                      className="select-dropdown form-select custom-input"
                      value={cityId}
                      onChange={(e) => {
                        setCityId(e.target.value);
                      }}
                      disabled={stateId === 0}
                    >
                      <option value={0}>
                        {isCountryLoading ?  t("Loading...") : t("Select City")}
                      </option>
                      {city.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-filed ">
                <span
                  className="basic-text "
                >
                  {t("Zip")}
                </span>
                {!workInfoEdit ? (
                  <div className="large-bold-text">
                    {Util.isValidData(zipCode) ? zipCode : "-"}
                  </div>
                ) : (
                  <>
                    <input
                      className={`form-control custom-input ${
                        zipCodeError.length > 0 ? "input-error" : ""
                      }`}
                      value={zipCode}
                      onChange={(e) => {
                        setZipCode(e.target.value);
                        setZipCodeError("");
                      }}
                    />
                    {zipCodeError.length > 0 && (
                      <span className="invalid-error text-danger">
                      <img
                        className="fa fa-exclamation-circle mr-2 invalid-svg"
                        src={Invalid}
                      />
                        {zipCodeError}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="mt-2 mb-3">
              {workInfoEdit && (
                <div className="text-end">
                  <button
                    className=" btn secondary-lg-btn rounded-btn mr-2 m-0 px-2"
                    onClick={() => {
                      onCancel();
                      setZipCodeError("");
                    }}
                  >
                    {t("Cancel")}
                  </button>

                  <button
                    className=" btn primary-lg-btn rounded-btn"
                    onClick={handleSaveWorkDetails}
                  >
                    {t("Save")}
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default WorkInfo;
