import React, { useEffect, useState } from "react";
import Util from "../../../Util";
import { t } from "i18next";
import Loader from "../../../components/common/Loader";
import { Modal, Button } from "react-bootstrap";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { IconButton } from "@material-ui/core";
import Invalid from "../../../assets/Icon/invalid.svg";
import ApiCalling from "../../../network/ApiCalling";
import ModalComponent from "../ModalComponent";
import Sort from "../../../assets/Icon/Short.svg";
import ToastHOC from "../../HOC/ToastHOC";
import { close } from "../../Admission/Admission";

const AdmissionProgram = (props) => {
  
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [academicYearData, setAcademicYearData] = useState([]);
  const [academicYearId, setAcademicYearId] = useState(null);
  const [leadList, setLeadList] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { setIsToast } = props;
  const [editValue, setEditValue] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [programErr, setProgramErr] = useState("");
  const [academicYearErr, setAcademicYearErr] = useState("");

  function handleOpenModal() {
    setShow(true);
  }

  useEffect(() => {
    const academicList = async () => {
      setIsLoading(true);
      ApiCalling.apiCallBodyDataGet("academic-year/getBySchoolId")
        .then((res) => {
          setAcademicYearData(res?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    academicList();
  }, []);

  const handleAddLeads = async () => {
    setProgramErr("");
    setAcademicYearErr("");
    let error = false;

    if (!title) {
      setProgramErr("Program name is required");
      error = true;
    }
    if (academicYearId === null) {
      setAcademicYearErr("Academic Year is required");
      error = true;
    }
    if (error) {
      return;
    }
    const data = {
      title: title,
      academic_year_id: academicYearId,
    };
    try {
      ApiCalling.apiCallBodyDataPost("lead-program/create/", data).then(
        (res) => {
          // console.log("lead-program", res);
          setIsToast(res?.data?.message);
          handleCloseModal();
          getLeadList();
        }
      );
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const handleUpdateLead = async () => {
    const data = {
      id: editValue.id,
      title: title,
      academic_year_id: academicYearId,
    };
    try {
      ApiCalling.apiCallBodyDataPost("lead-program/update/", data).then(
        (res) => {
          if (res.data.status === true) {
            setIsLoading(false);
            setIsToast(res?.data?.message);
            setShow(false);
            getLeadList();
          } else {
            alert(res.data.message);
          }
        }
      );
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  function handleCloseModal() {
    setShow(false);
    setTitle("");
    setAcademicYearId(null);
    setEditValue(null);
    setProgramErr("");
    setAcademicYearErr("");
  }

  const handleSaveAndClose = async () => {
    if (editValue) {
      await handleUpdateLead();
    } else {
      await handleAddLeads();
    }
  };

  const handleDelete = () => {
    ApiCalling.apiCallDelete(`lead-program/delete/${deleteId}`)
      .then((res) => {
        if (res.data.status == true) {
          setIsToast(res.data.message);
        } else if (res.data.status == false) {
          setIsToast(t("Somewhere used ,You Can't Delete"));
        }
        setShowDeleteConfirmation(false);
        getLeadList();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEdit = (lead) => {
    setEditValue(lead);
    setTitle(lead?.title);
    setAcademicYearId(lead?.academic_year_id);
    setShow(true);
  };

  const getLeadList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("lead-program/list/")
      .then((res) => {
        setLeadList(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("ERROR", err);
      });
  };

  useEffect(() => {
    getLeadList();
  }, []);

  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="header text-right mb-3 px-0">
              <button
                className={`${
                  Util.hasPermission("MASTERS_ADD")
                    ? "btn custom-primary"
                    : "no-permission"
                }`}
                type="button"
                aria-expanded="false"
                style={{ color: "#fff", padding: "5px, 10px" }}
                onClick={handleOpenModal}
              >
                {t("Add New Program ")}
              </button>
              <Modal
                show={show}
                onHide={handleCloseModal}
                animation={true}
                centered={true}
                dialogClassName="modal-view"
                onShow={handleOpenModal}
              >
                <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                  <h6>
                    {editValue ? t("Edit Program") : t("Add New Program")}
                  </h6>

                  <button className="hidden-btn" onClick={handleCloseModal}>
                    <img
                      src={close}
                      className="svg-icon"
                      height={20}
                      width={20}
                    />
                  </button>
                </div>
                <Modal.Body>
                  <div className="input-filed ">
                    <span className="basic-text required">
                      {t("Program Name")}
                    </span>

                    <input
                      className={`form-control  ${
                        programErr.length > 0 ? "input-error" : "custom-input"
                      }`}
                      type="text"
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                    />
                    {programErr.length > 0 && (
                      <span className=" invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {programErr}
                      </span>
                    )}
                  </div>
                  <div className="input-filed">
                    <span className="basic-text required">
                      {t("Academic year")}
                    </span>
                    <select
                      value={academicYearId}
                      onChange={(e) => {
                        setAcademicYearId(e.target.value);
                      }}
                      className={`select-dropdown form-select custom-input ${
                        academicYearErr.length > 0 ? "input-error" : ""
                      }`}
                    >
                      <option value="">{t("Academic year")}</option>
                      {academicYearData?.map((year) => (
                        <option key={year.id} value={year?.id}>
                          {year?.title}
                        </option>
                      ))}
                    </select>
                    {academicYearErr.length > 0 && (
                      <span className=" invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {academicYearErr}
                      </span>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btn custom-primary"
                    onClick={handleSaveAndClose}
                  >
                    {t("Save")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="row clearfix">
            <div className="table-div overflow-hidden m-0 p-0">
              <div className="col-lg-12 col-md-12 p-0">
                <div className="body">
                  {!isLoading ? (
                    <>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">
                              {t("Title")}
                              {/* <img src={Sort} className="ml-1 fa-sort" alt="" /> */}
                            </th>
                            <th scope="col">
                              {t("Academic year")}
                              {/* <img src={Sort} className="ml-1 fa-sort" alt="" /> */}
                            </th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        {leadList.length > 0 ? (
                          <tbody>
                            {leadList.map((lead, index) => (
                              <tr key={lead.id}>
                                <td>{index + 1}</td>
                                <td>{lead.title}</td>
                                <td>{lead?.Academic_Year?.title}</td>
                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleEdit(lead);
                                    }}
                                    className={`${
                                      !Util.hasPermission("MASTERS_EDIT") &&
                                      "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-edit cursor"></i>
                                  </a>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowDeleteConfirmation(true);
                                      setDeleteId(lead.id);
                                    }}
                                    className={`${
                                      Util.hasPermission("MASTERS_DELETE")
                                        ? "pl-5"
                                        : "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-trash-o text-danger cursor"></i>
                                  </a>
                                </td>
                                <td></td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tfoot>
                            <tr>
                              <td colSpan={4}>
                                {t(
                                  "There are no Program. Click the 'Add Program' button to create one."
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                      {leadList.length > 0 && (
                        <>
                          <div className="small text-muted mb-3 pl-3 border-top py-3">
                            <i>
                              {leadList.length} {t("Program found")}.
                            </i>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
                <ModalComponent
                  show={showDeleteConfirmation}
                  onHide={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  title={t("Delete")}
                  onCancel={t("Cancel")}
                  onConfirm={t("Confirm")}
                  subTitle={t("Are you sure, to delete this Lead Program?")}
                  size="sm"
                  onCancelClick={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  onConfirmClick={handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastHOC(AdmissionProgram);
