import Util from "../../Util";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ApiCalling from "../../network/ApiCalling";
import CloseIcon from "@mui/icons-material/Close";
import ToastHOC from "../../screens/HOC/ToastHOC";
import { imageSchoolDetails } from "../../screens/CommonImage";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import unknownImg from "../../assets/images/Frame 1000004000.png";
import camera from "../../assets/Icon/camera-icon.svg";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import Location from "../../assets/Icon/location.svg";
import Edit from "../../assets/Icon/Edit-blue.svg";
import DefaultSchool from "../../assets/Icon/defaultSchool.png";
import EditPhotoModal from "../../screens/Dashbord/EditPhotoModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Invalid from "../../assets/Icon/invalid.svg";
import Loader from "../common/Loader";

function SchoolDetails(props) {
  const { setIsToast, setIsSubToast } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [schoolDetail, setSchoolDetails] = useState();
  const [name, setName] = useState("");
  const [schoolCountryName, setSchoolCountryName] = useState("");

  const [schoolType_id, setSchoolType_id] = useState(0);
  const [schoolType_idError, setSchoolType_idError] = useState("");
  const [schoolType, setSchoolType] = useState([]);
  const [schoolName, setSchoolName] = useState("");
  const [schoolNameError, setSchoolNameError] = useState("");
  const [logo, setLogo] = useState("");
  const [logoURL, setLogoURL] = useState("");
  const [isLogoDelete, setIsLogoDelete] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [affiliationNumber, setAffiliationNumber] = useState("");
  const [capacity, setCapacity] = useState("");
  const [capacityError, setCapacityError] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [getCountryData, setCountryData] = useState([]);
  const [country, setCountry] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [getStateData, setStateData] = useState([]);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [cityId, setCityId] = useState(0);
  const [city, setCity] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("");
  const [schoolInfo, setSchoolInfo] = useState(false);
  const [schoolAddress, setSchoolAddress] = useState(false);

  const [schoolAccordian, setSchoolAccordian] = useState(true);
  const [addressAccordian, setAddressAccordian] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedSchoolType, setSelectedSchoolType] = useState("");

  const handleSchoolNameChange = (e) => {
    setSchoolName(e.target.value);
    setSchoolNameError("");
  };
  const handleLogoChange = (e) => {
    if (e.target.files[0]) {
      setShowEditModal(true);
    }

    setLogo(e.target.files[0]);
    setLogoURL(URL.createObjectURL(e.target.files[0]));

    setIsLogoDelete(false);
  };
  const handleContactNumberChange = (e) => {
    setContactNumber(e.target.value);
    setContactNumberError("");
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };
  const handleAffilationNumberChange = (e) => {
    setAffiliationNumber(e.target.value);
  };
  const handleCapacityChange = (e) => {
    setCapacity(e.target.value);
    setCapacityError("");
  };
  const handleAddressLine1Change = (e) => {
    setAddressLine1(e.target.value);
  };
  const handleAddressLine2Change = (e) => {
    setAddressLine2(e.target.value);
  };
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };
  const changeState = (e) => {
    e.preventDefault();
    setStateId(e.target.value);
  };
  const handleCityChange = (e) => {
    setCityId(e.target.value);
  };
  const handleZipCodeChange = (e) => {
    setZipCode(e.target.value);
    setPinCodeError("");
  };

  const resetSchoolInfo = () => {
    setSchoolType_idError("");
    setContactNumberError("");
    setSchoolNameError("");
    setEmailError("");
    setCapacityError("");
  };

  const resetSchoolAddress = () => {
    setPinCodeError("");
  };

  const handleSaveSchoolInformation = (e) => {
    e.preventDefault();
    setSchoolType_idError("");
    setContactNumberError("");
    setSchoolNameError("");
    setEmailError("");
    setCapacityError("");
    let error = false;
    if (schoolType_id == 0) {
      setSchoolType_idError(t("Type is required"));
      error = true;
    }

    if (schoolName == "") {
      setSchoolNameError(t("Name is required"));
      error = true;
    }
    if (Util.isValidData(contactNumber) == false) {
      setContactNumberError(t("contact Number is required"));
      error = true;
    }
    if (email == "") {
      setEmailError(t("Email is required"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    }

    if (capacity == "") {
      setCapacityError(t("Capacity is required"));
      error = true;
    }

    if (!error) {
      setIsLoading(true);
      const formdata = new FormData();
      if (schoolType_id) {
        formdata.append("school_type_id", schoolType_id);
      }

      if (schoolName) {
        formdata.append("name", schoolName);
      }
      if (capacity) {
        formdata.append("capacity", capacity);
      }

      if (contactNumber) {
        formdata.append("contact_phone", contactNumber);
      }

      if (email) {
        formdata.append("contact_email", email);
      }

      if (affiliationNumber) {
        formdata.append("affiliation_number", affiliationNumber);
      }

      if (logo) {
        formdata.append("logo", logo);
      }
      if (isLogoDelete) {
        formdata.append("isDeleteLogo", isLogoDelete);
      }

      ApiCalling.apiCallBodyDataPost("school/update", formdata)
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            setIsToast(t("School Information Success Updated!"));
            setIsSubToast(t("You have successfully Update a School"));
            Util.saveSelectedSchool(res.data.school);
            getSchoolDetails();
            setSchoolInfo(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleSaveSchoolAddress = (e) => {
    e.preventDefault();
    setPinCodeError("");
    let error = false;

    if (zipCode && Util.Zip_regex.test(zipCode) === false) {
      setPinCodeError(
        t("Please enter valid zip-code(i.e: 12345 or 12345-2563)")
      );
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const data = {
        address1: addressLine1,
        address2: addressLine2,
        city_id: cityId,
        state_id: stateId,
        country_id: parseInt(country),
        pincode: zipCode,
      };

      ApiCalling.apiCallBodyDataPost("school/update-address", data)
        .then((res) => {
          // setIsToast("School updated Successfully!");
          if (res.data.status == true) {
            setIsLoading(false);
            setIsToast(t("Address Information Success Updated!"));
            setIsSubToast(t("You have successfully Update a School"));
            Util.saveSelectedSchool(res.data.school);
            getSchoolDetails();
            setSchoolAddress(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getCountry = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet("country")
      .then((res) => {
        setIsCountryLoading(false);
        setCountryData(res.data);
      })
      .catch((error) => {
        setIsCountryLoading(false);
        console.log(error);
      });
  };
  const stateList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${country}`).then((res) => {
      setIsCountryLoading(false);
      setStateData(res.data);
    });
  };
  const cityList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res.data);
    });
  };

  const getSchoolTypeId = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("school_type")
      .then((res) => {
        setIsLoading(false);
        setSchoolType(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSchoolDetails = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("school")
      .then((res) => {
        setIsLoading(false);
        if (Util.isValidData(res.data)) {
          console.log("school details", res.data);
          setSchoolDetails(res.data);
          setSchoolInfo(true);
          setSchoolAddress(true);

          setSchoolType_id(
            Util.isValidData(res.data.School_Type.id)
              ? res.data.School_Type.id
              : 0
          );
          setName(Util.isValidData(res.data.name) ? res.data.name : "");
          setSchoolCountryName(
            Util.isValidData(res.data?.Country?.name)
              ? res.data?.Country?.name
              : ""
          );
          setSchoolName(Util.isValidData(res.data.name) ? res.data.name : "");
          setEmail(
            res.data.contact_email === null ? "" : res.data.contact_email
          );
          setAffiliationNumber(
            res.data.affiliation_number == "null"
              ? ""
              : res.data.affiliation_number
          );
          setContactNumber(
            Util.isValidData(res.data.contact_phone)
              ? res.data.contact_phone
              : ""
          );
          setCapacity(res.data.capacity == "null" ? "" : res.data.capacity);
          setAddressLine1(res.data.address1 == "null" ? "" : res.data.address1);

          setStateId(
            Util.isValidData(res.data.state_id) ? res.data.state_id : 0
          );
          setAddressLine2(res.data.address2 == "null" ? "" : res.data.address2);
          setCityId(Util.isValidData(res.data.city_id) ? res.data.city_id : 0);

          setCountry(
            Util.isValidData(res.data.country_id) ? res.data.country_id : 0
          );
          setZipCode(
            Util.isValidData(res.data.pincode) ? res.data.pincode : ""
          );
          setLogoURL(
            Util.isValidData(res.data.logo)
              ? imageSchoolDetails + res.data.logo
              : ""
          );
          setSelectedCity(res.data?.City?.name);
          setSelectedCountry(res.data?.Country?.name);
          setSelectedState(res.data?.State?.name);
          setSelectedSchoolType(res.data?.School_Type.type);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (country) {
      stateList();
    }
  }, [country]);
  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);
  useEffect(() => {
    getSchoolTypeId();
    getCountry();
    getSchoolDetails();
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);
  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-80">
        <div className="mt-4">
          <BreadCrumbComponent
            Breadcrumb={[
              {
                name: "Dashboard",
                navigate: () => {
                  history.push("/");
                },
              },
              { name: `${t("School Details")}` },
            ]}
          />
          <h2 className="page-head">{t("School Details")}</h2>
        </div>
        {!isLoading ? (
          <div className="school-details my-4">
            <div className="p-4">
              <div
                className="d-flex align-items-center gap-4"
                style={{ marginBottom: "24px" }}
              >
                {logoURL.length > 0 ? (
                  <div
                    className="profile-area"
                    style={{
                      position: "relative",
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      alt="User"
                      className="rounded-circle user-photo img-responsive  mt-1 "
                      src={logoURL}
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                      }}
                    />
                    {!schoolInfo && (
                      <span
                        className={`${
                          Util.hasPermission("SCHOOL_EDIT")
                            ? "choose-profile"
                            : "no-permission"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          // document.getElementById("photo").click();
                          setShowEditModal(true);
                        }}
                      >
                        <img src={camera} alt="" />
                      </span>
                    )}
                  </div>
                ) : (
                  <div
                    className="profile-area"
                    style={{
                      position: "relative",
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      alt="User"
                      className="img-responsive  mt-1"
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                        position: "relative",
                      }}
                      src={DefaultSchool}
                    />
                    {!schoolInfo && (
                      <span
                        className={`${
                          Util.hasPermission("SCHOOL_EDIT")
                            ? "choose-profile"
                            : "no-permission"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          document.getElementById("photo").click();
                        }}
                      >
                        <img src={camera} alt="" />
                      </span>
                    )}
                  </div>
                )}
                <input
                  className="sr-only"
                  id="photo"
                  type="file"
                  accept="image/*"
                  onChange={handleLogoChange}
                />
                <div>
                  <div
                    style={{
                      fontSize: "24px",
                      fontWeight: "700",
                      color: "#07112b",
                    }}
                  >
                    {name}
                  </div>
                  {Util.isValidData(schoolCountryName) && (
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4B5563",
                      }}
                    >
                      <img src={Location} alt="" className="mr-1" />
                      {schoolCountryName}
                    </div>
                  )}
                </div>
              </div>

              <EditPhotoModal
                show={showEditModal}
                onClose={(msg) => {
                  setShowEditModal(false);
                  setLogoURL(
                    Util.isValidData(schoolDetail.logo)
                      ? imageSchoolDetails + schoolDetail.logo
                      : ""
                  );
                  setLogo("");
                }}
                title={t("Edit Photo")}
                subTitle={t(
                  "Show profile picture the best version of yourself"
                )}
                onChange={t("Change Image")}
                onSave={t("Save Photo")}
                // Id={userId}
                onConfirmClick={() => {
                  setShowEditModal(false);
                }}
                photoURL={logoURL}
                ProfilePhoto={logoURL}
                handlePhotoChange={handleLogoChange}
                defaultAvtar={DefaultSchool}
                handleSavePhoto={() => {
                  setShowEditModal(false);
                }}
              />

              <hr className="divider-line" />
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="medium-large-text">
                    {t("School Information")}
                  </div>
                  {schoolInfo && (
                    <div className="d-flex justify-content-center align-items-center">
                      <span
                        className={`${
                          Util.hasPermission("SCHOOL_EDIT")
                            ? "edit-btn"
                            : "no-permission"
                        }`}
                        onClick={() => {
                          setSchoolInfo(!schoolInfo);
                          setSchoolAccordian(true);
                          setAddressAccordian(false);
                          // resetSchoolInfo();
                          setSchoolAddress(true);
                        }}
                      >
                        <img src={Edit} alt="" className="edit-svg " />
                        {t("Edit")}
                      </span>
                      <div className="vertical-bar"></div>
                      <div
                        className="accoridan-btn"
                        onClick={() => setSchoolAccordian(!schoolAccordian)}
                      >
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  )}
                </div>

                {schoolAccordian ? (
                  <>
                    <div className="row text-start mt-2">
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("School Name")}
                          </span>
                          {schoolInfo ? (
                            <div className="large-bold-text">
                              {Util.isValidData(schoolName) ? schoolName : "-"}
                            </div>
                          ) : (
                            <>
                              <input
                                className={`form-control custom-input ${
                                  schoolNameError.length > 0
                                    ? "input-error"
                                    : ""
                                }`}
                                value={schoolName}
                                onChange={handleSchoolNameChange}
                              />
                              {schoolNameError.length > 0 && (
                                <span className=" invalid-error">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {schoolNameError}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Capacity")}
                          </span>
                          {schoolInfo ? (
                            <div className="large-bold-text">
                              {Util.isValidData(capacity) ? capacity : "-"}
                            </div>
                          ) : (
                            <>
                              <input
                                className={`form-control custom-input ${
                                  capacityError.length > 0 ? "input-error" : ""
                                }`}
                                value={capacity}
                                onChange={handleCapacityChange}
                              />
                              {capacityError.length > 0 && (
                                <span className=" invalid-error">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {capacityError}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Email")}
                          </span>
                          {schoolInfo ? (
                            <div className="large-bold-text">
                              {Util.isValidData(email) ? email : "-"}
                            </div>
                          ) : (
                            <>
                              <input
                                className={`form-control custom-input ${
                                  emailError.length > 0 ? "input-error" : ""
                                }`}
                                value={email}
                                onChange={handleEmailChange}
                              />
                              {emailError.length > 0 && (
                                <span className=" invalid-error">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {emailError}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Phone Number")}
                          </span>
                          {schoolInfo ? (
                            <div className="large-bold-text">
                              {Util.isValidData(contactNumber)
                                ? contactNumber
                                : "-"}
                            </div>
                          ) : (
                            <>
                              <div
                                className={`form-control ${
                                  contactNumberError.length > 0
                                    ? "input-error"
                                    : "custom-input"
                                }`}
                                tabIndex={0}
                              >
                                <PhoneInput
                                  tabIndex="-1"
                                  international
                                  defaultCountry={defaultCountry}
                                  value={contactNumber}
                                  onChange={(value) => {
                                    setContactNumber(value);
                                    setContactNumberError("");
                                  }}
                                />
                              </div>
                              {contactNumberError.length > 0 && (
                                <span className="invalid-error">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {contactNumberError}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text">
                            {t("Affiliation Number")}
                          </span>
                          {schoolInfo ? (
                            <div className="large-bold-text">
                              {Util.isValidData(affiliationNumber)
                                ? affiliationNumber
                                : "-"}
                            </div>
                          ) : (
                            <>
                              <input
                                className="form-control custom-input"
                                value={affiliationNumber}
                                onChange={handleAffilationNumberChange}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Type")}
                          </span>
                          {schoolInfo ? (
                            <div className="large-bold-text">
                              {Util.isValidData(selectedSchoolType)
                                ? selectedSchoolType
                                : "-"}
                            </div>
                          ) : (
                            <>
                              <select
                                className={`form-select custom-input select-dropdown ${
                                  schoolType_idError.length > 0
                                    ? "input-error"
                                    : ""
                                }`}
                                value={schoolType_id}
                                onChange={(e) => {
                                  setSchoolType_id(e.target.value);
                                  setSchoolType_idError("");
                                }}
                              >
                                <option disabled value={0}>
                                  {t("Select")}
                                </option>
                                {schoolType.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.type}
                                    </option>
                                  );
                                })}
                              </select>

                              {schoolType_idError.length > 0 && (
                                <span className="invalid-error mt-2">
                                  <img
                                    className="fa fa-exclamation-circle mr-2 invalid-svg"
                                    src={Invalid}
                                  />
                                  {schoolType_idError}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {!schoolInfo && (
                      <div className="text-end my-3">
                        <button
                          className="secondary-lg-btn rounded-btn btn mr-2 m-0 px-2"
                          onClick={() => {
                            // cancelSchoolInfoClick();
                            // resetSchoolInfo();
                            setSchoolAccordian(false);
                            setSchoolInfo(true);
                          }}
                        >
                          {t("Cancel")}
                        </button>

                        <button
                          className="primary-lg-btn rounded-btn btn"
                          onClick={handleSaveSchoolInformation}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <hr className="divider-line" />
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="medium-large-text">{t("Address")}</div>
                  {schoolAddress && (
                    <div className="d-flex justify-content-between align-items-center">
                      <span
                        className={`${
                          Util.hasPermission("SCHOOL_EDIT")
                            ? "edit-btn"
                            : "no-permission"
                        }`}
                        onClick={() => {
                          setSchoolAddress(!schoolAddress);
                          setPinCodeError("");
                          setAddressAccordian(true);
                          setSchoolAccordian(false);
                          setSchoolInfo(true);
                        }}
                      >
                        <img src={Edit} alt="" className="edit-svg " />
                        {t("Edit")}
                      </span>
                      <div className="vertical-bar"></div>
                      <div
                        className="accoridan-btn"
                        onClick={() => setAddressAccordian(!addressAccordian)}
                      >
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {addressAccordian ? (
                <>
                  <div className="row text-start mt-2">
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text ">{t("Address 1")}</span>
                        {schoolAddress ? (
                          <div className="large-bold-text">
                            {Util.isValidData(addressLine1)
                              ? addressLine1
                              : "-"}
                          </div>
                        ) : (
                          <input
                            className="form-control custom-input"
                            value={addressLine1}
                            onChange={handleAddressLine1Change}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text ">{t("Address 2")}</span>
                        {schoolAddress ? (
                          <div className="large-bold-text">
                            {Util.isValidData(addressLine2)
                              ? addressLine2
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <input
                              className="form-control custom-input"
                              value={addressLine2}
                              onChange={handleAddressLine2Change}
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text ">{t("Country")}</span>
                        {schoolAddress ? (
                          <div className="large-bold-text">
                            {Util.isValidData(selectedCountry)
                              ? selectedCountry
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <select
                              className="form-select custom-input"
                              value={country}
                              onChange={handleCountryChange}
                            >
                              <option disabled value={0}>
                                {t("Select")}
                              </option>
                              {getCountryData.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text ">{t("State")}</span>
                        {schoolAddress ? (
                          <div className="large-bold-text">
                            {Util.isValidData(selectedState)
                              ? selectedState
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <div className="form-group">
                              <select
                                className="select-dropdown form-select  custom-input"
                                value={stateId}
                                onChange={changeState}
                                disabled={!country}
                              >
                                <option disabled value={0}>
                                  {isCountryLoading
                                    ? t("Loading...")
                                    : t("Select State")}
                                </option>
                                {getStateData.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text ">{t("City")}</span>
                        {schoolAddress ? (
                          <div className="large-bold-text">
                            {Util.isValidData(selectedCity)
                              ? selectedCity
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <select
                              className="select-dropdown form-select  custom-input"
                              value={cityId}
                              onChange={handleCityChange}
                              disabled={!stateId}
                            >
                              <option value={0}>
                                {isCountryLoading
                                  ? t("Loading...")
                                  : t("Select City")}
                              </option>
                              {city.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text ">{t("PIN Code")}</span>
                        {schoolAddress ? (
                          <div className="large-bold-text">
                            {Util.isValidData(zipCode) ? zipCode : "-"}
                          </div>
                        ) : (
                          <>
                            <input
                              className={`form-control custom-input ${
                                pinCodeError.length > 0 && "input-error"
                              }`}
                              value={zipCode}
                              onChange={handleZipCodeChange}
                            />
                            {pinCodeError.length > 0 && (
                              <span className="invalid-error">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {pinCodeError}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {!schoolAddress && (
                      <div className="text-end my-3">
                        <button
                          className="secondary-lg-btn rounded-btn btn mr-2 m-0 px-2"
                          onClick={() => {
                            // cancelSchoolAddressClick();
                            // resetSchoolAddress();
                            setAddressAccordian(false);
                            setSchoolAddress(true);
                          }}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          className="primary-lg-btn rounded-btn btn"
                          onClick={handleSaveSchoolAddress}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}
export default ToastHOC(SchoolDetails);

// <Modal
//         size="lg"
//         show={isVisible}
//         onHide={isClose}
//         animation={true}
//         centered={true}
//         dialogClassName="modal-view"
//         onShow={() => {
//           getCountry();
//           getSchoolTypeId();
//           getSchoolDetails();
//           setSchoolType_idError("");
//           setContactNumberError("");

//           setSchoolNameError("");
//           setEmailError("");
//           setCapacityError("");
//           setPinCodeError("");
//         }}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>{t("Update School Profile")}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <>
//             {!isLoading ? (
//               <div>
//                 <div className="row">
//                   <div className="col-lg-6">
//                     <label>{t("Name")}</label>
//                     <input
//                       className="form-control"
//                       value={schoolName}
//                       onChange={handleSchoolNameChange}
//                     />
//                     {schoolNameError.length > 0 ? (
//                       <span className="text-danger">{schoolNameError}</span>
//                     ) : (
//                       ""
//                     )}
//                     <br />
//                     <label>{t("Contact Number")}</label>
//                     {/* <input
//                       className="form-control"
//                       value={contactNumber}
//                       onChange={handleContactNumberChange}
//                     /> */}
//                     <div className="form-control">
//                       <PhoneInput
//                         international
//                         defaultCountry={defaultCountry}
//                         value={contactNumber}
//                         onChange={(value) => {
//                           setContactNumber(value);
//                           setContactNumberError("");
//                         }}
//                       />
//                     </div>
//                     {contactNumberError.length > 0 ? (
//                       <span className="text-danger">{contactNumberError}</span>
//                     ) : (
//                       ""
//                     )}

//                     <br />
//                     <label>{t("Affiliation Number")}</label>
//                     <input
//                       className="form-control"
//                       value={affiliationNumber}
//                       onChange={handleAffilationNumberChange}
//                     />
//                     <br />
//                     <label>{t("Type")}</label>
//                     <select
//                       className="form-select"
//                       value={schoolType_id}
//                       onChange={(e) => {
//                         setSchoolType_id(e.target.value);
//                         setSchoolType_idError("");
//                       }}
//                     >
//                       <option disabled value={0}>
//                         {t("Select")}
//                       </option>
//                       {schoolType.map((item) => {
//                         return (
//                           <option value={item.id} key={item.id}>
//                             {item.type}
//                           </option>
//                         );
//                       })}
//                     </select>
//                     {schoolType_idError.length > 0 ? (
//                       <span className="text-danger">{schoolType_idError}</span>
//                     ) : (
//                       ""
//                     )}
//                   </div>

//                   <div className="col-lg-6">
//                     <label>{t("Logo")}</label>
//                     {logoURL.length === 0 ? (
//                       <>
//                         <div
//                           className="custom-file"
//                           style={{ marginBottom: "7%" }}
//                         >
//                           <input
//                             accept="image/*"
//                             type="file"
//                             className="custom-file-input"
//                             id="customFileLangHTML"
//                             onChange={handleLogoChange}
//                           />
//                           <label
//                             className="custom-file-label"
//                             htmlFor="customFileLangHTML"
//                             data-browse="choose File"
//                           >
//                             {t("Logo")}
//                           </label>
//                         </div>
//                       </>
//                     ) : (
//                       <div
//                         className="input-filed"
//                         style={{
//                           display: "flex",
//                           justifyContent: "space-between",
//                           // gap: 350,
//                           alignItems: "center",
//                         }}
//                       >
//                         <img
//                           src={logoURL}
//                           className="rounded-circle user-photo"
//                           style={{ width: "35px", height: "35px" }}
//                           alt=""
//                         />
//                         <CloseIcon
//                           onClick={() => {
//                             setIsLogoDelete(true)
//                             setLogo("");
//                             setLogoURL("");
//                           }}
//                         />
//                       </div>
//                     )}
//                     <br />
//                     <label>{t("Email")}</label>
//                     <input
//                       className="form-control"
//                       value={email}
//                       onChange={handleEmailChange}
//                     />
//                     {emailError.length > 0 ? (
//                       <span className="text-danger">{emailError}</span>
//                     ) : (
//                       ""
//                     )}
//                     <br />
//                     <label>{t("Capacity")}</label>
//                     <input
//                       className="form-control"
//                       value={capacity}
//                       onChange={handleCapacityChange}
//                     />
//                     {capacityError.length > 0 ? (
//                       <span className="text-danger">{capacityError}</span>
//                     ) : (
//                       ""
//                     )}
//                     <br />
//                   </div>
//                 </div>
//                 <br />
//                 <div style={{ fontWeight: "bold", fontSize: "16px" }}>
//                   {t("School Address")}
//                 </div>
//                 <br />
//                 <div className="row">
//                   <div className="col-lg-6">
//                     <label>{t("Address 1")}</label>
//                     <input
//                       className="form-control"
//                       value={addressLine1}
//                       onChange={handleAddressLine1Change}
//                     />

//                     <br />
//                     <label>{t("Address 2")}</label>
//                     <input
//                       className="form-control"
//                       value={addressLine2}
//                       onChange={handleAddressLine2Change}
//                     />

//                     <br />
//                     <label>{t("Pincode")}</label>
//                     <input
//                       className="form-control"
//                       value={zipCode}
//                       onChange={handleZipCodeChange}
//                     />
//                     {pinCodeError.length > 0 && (
//                       <span className="text-danger">{pinCodeError}</span>
//                     )}
//                     <br />
//                   </div>

//                   <div className="col-lg-6">
//                     <label>{t("Country")}</label>
//                     <select
//                       className="form-select"
//                       value={country || ""}
//                       onChange={handleCountryChange}
//                     >
//                       <option disabled value="">
//                         {t("Select")}
//                       </option>
//                       {getCountryData.map((item) => {
//                         return (
//                           <option key={item.id} value={item.id}>
//                             {item.name}
//                           </option>
//                         );
//                       })}
//                     </select>
//                     <br />
//                     <div className="input-filed mb-3">
//                       <label>{t("State")}</label>
//                       <select
//                         className="select-dropdown"
//                         value={stateId || ""}
//                         onChange={changeState}
//                         disabled={!country}
//                       >
//                         <option disabled value="">
//                           {isCountryLoading
//                             ? t("Loading...")
//                             : t("Select State")}
//                         </option>
//                         {getStateData.map((item) => {
//                           return (
//                             <option key={item.id} value={item.id}>
//                               {item.name}
//                             </option>
//                           );
//                         })}
//                       </select>
//                     </div>

//                     <label>{t("City")}</label>
//                     <select
//                       className="select-dropdown"
//                       value={cityId}
//                       onChange={handleCityChange}
//                       disabled={!stateId}
//                     >
//                       <option value={0}>
//                         {isCountryLoading ? "Loading..." : "Select City"}
//                       </option>
//                       {city.map((item) => {
//                         return (
//                           <option key={item.id} value={item.id}>
//                             {item.name}
//                           </option>
//                         );
//                       })}
//                     </select>
//                     <br />
//                   </div>
//                 </div>
//               </div>
//             ) : (
//               <Loading />
//             )}
//           </>
//         </Modal.Body>
//         <Modal.Footer>
//           <button className="btn custom-primary" onClick={handleSave}>
//             {t("Save")}
//           </button>
//         </Modal.Footer>
//       </Modal>
