import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ApiCalling from "../../../../network/ApiCalling";
import { Rings } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-number-input";
import Util from "../../../../Util";
import { imageMyProfile } from "../../../CommonImage";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import ToastHOC from "../../../HOC/ToastHOC";
import Loader from "../../../../components/common/Loader";

function ParentProfile(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const ParentDetails = props.ParentDetails;
  const { setIsToast } = props;
  const pendingToast = props.props.location?.pendingToast;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isDeletePhoto, setIsDeletePhoto] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [photo, setPhoto] = useState(null);
  const [gender, setGender] = useState(0);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [cityId, setCityId] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  const [country, setCountry] = useState([]);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [state, setState] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [dateOfBirth, setDate] = useState(null);
  const [age, setAge] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [avatarURL, setAvatarURL] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [city, setCity] = useState([]);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameError("");
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameError("");
  };
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const changeDate = (date) => {
    if (date === null) {
      setDate(null);
      setAge(0);
    } else {
      setDate(date);
      const calculatedAge = Util.calculateAge(date);
      setAge(calculatedAge);
    }
  };
  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]);
    setAvatarURL(URL.createObjectURL(e.target.files[0]));
    setIsDeletePhoto(false);
  };
  const handleAddress1Change = (e) => {
    setAddress1(e.target.value);
  };
  const handleAddress2Change = (e) => {
    setAddress2(e.target.value);
  };
  const handleCityChange = (e) => {
    setCityId(e.target.value);
  };
  const changeState = (e) => {
    e.preventDefault();
    setStateId(e.target.value);
  };
  const changeCountry = (e) => {
    e.preventDefault();
    setCountryId(e.target.value);
  };
  const changePinCode = (e) => {
    e.preventDefault();
    setPinCode(e.target.value);
    setPinCodeError("");
  };

  useEffect(() => {
    if (ParentDetails) {
      setFirstName(ParentDetails.User.firstName);
      setLastName(ParentDetails.User.lastName);
      setGender(ParentDetails.User.gender === null ? 0 : ParentDetails.User.gender);
      setDate(ParentDetails.User.dob === null ? null : new Date(ParentDetails.User.dob));
      if (ParentDetails.User.dob !== null) {
        const calculatedAge = Util.calculateAge(new Date(ParentDetails.User.dob));
        setAge(calculatedAge);
      } else {
        setAge(0);
      }
      setMobile(
        Util.isValidData(ParentDetails.User.mobile) ? ParentDetails.User.mobile : ""
      );
      setEmail(ParentDetails.User.email);
      setAvatarURL(
        ParentDetails.User.avatar === null
          ? ""
          : imageMyProfile + ParentDetails.User.avatar
      );
      setAddress1(
        ParentDetails.User.address1 === null ? "" : ParentDetails.User.address1
      );
      setAddress2(
        ParentDetails.User.address2 === null ? "" : ParentDetails.User.address2
      );
      setCityId(
        Util.isValidData(ParentDetails.User.city_id) ? ParentDetails.User.city_id : ""
      );
      setPinCode(ParentDetails.User.zip === null ? "" : ParentDetails.User.zip);
      setCountryId(
        ParentDetails.User.country_id === null ? null : ParentDetails.User.country_id
      );
      setStateId(
        ParentDetails.User.state_id === null ? null : ParentDetails.User.state_id
      );
    }
  }, [ParentDetails]);
  const handleSave = (e) => {
    e.preventDefault();
    setFirstNameError("");
    setLastNameError("");
    setMobileError("");
    setPinCodeError("");
    let error = false;
    if (firstName === "") {
      setFirstNameError(t("FirstName is required"));
      error = true;
    }
    if (lastName === "") {
      setLastNameError(t("LastName is required"));
      error = true;
    }

    if (Util.isValidData(mobile) == false) {
      setMobileError(t("Mobile No is required"));
      error = true;
    }
    if (pinCode && Util.Zip_regex.test(pinCode) === false) {
      setPinCodeError(t("Please enter valid zip-code(i.e: 12345 or 12345-2563)"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const formdata = new FormData();
      formdata.append("user_id", ParentDetails.user_id);
      formdata.append("email", email);
      formdata.append("firstName", firstName);
      formdata.append("lastName", lastName);
      formdata.append("mobile", mobile);
      if (photo) {
        formdata.append("avatar", photo);
      }
      if (isDeletePhoto) {
        formdata.append("isDeletePhoto", isDeletePhoto);
      }
      if (gender) {
        formdata.append("gender", parseInt(gender));
      }
      if (dateOfBirth) {
        formdata.append("dob", dateOfBirth);
      }
      if (address1) {
        formdata.append("address1", address1);
      }
      if (address2) {
        formdata.append("address2", address2);
      }
      if (cityId) {
        formdata.append("city_id", cityId);
      }
      if (pinCode) {
        formdata.append("zip", pinCode);
      }
      if (stateId) {
        formdata.append("state_id", stateId);
      }
      if (countryId) {
        formdata.append("country_id", countryId);
      }
      ApiCalling.apiCallBodyDataPost("user/edit-profile", formdata)
        .then((res) => {
          setIsLoading(false);
          if (res.data.status === true) {
            setIsToast(t("profile updated successfully!"));

            // setUserDetails(res.data.user)
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    countryList();
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  useEffect(() => {
    if (countryId) {
      stateList();
    }
  }, [countryId]);

  const countryList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet("country").then((res) => {
      setIsCountryLoading(false);
      setCountry(res.data);
    });
  };
  const stateList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${countryId}`).then(
      (res) => {
        setIsCountryLoading(false);
        setState(res.data);
      }
    );
  };
  const cityList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      setIsCountryLoading(false);
      setCity(res.data);
    });
  };
  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);

  
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
  }, []);
  return (
    <div>
      <div className="row clearfix">
        <div className="col-lg-12">
          {!isLoading ? (
            <div className="body">
              <div className="body">
                <h6>{t("Profile Photo")}</h6>
                <div className="media">
                  <div className="media-left m-r-15">
                    {avatarURL.length === 0 ? (
                      <img
                        alt="User"
                        className="user-photo media-object "
                        style={{ maxHeight: "140px" }}
                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                      />
                    ) : (
                      <img
                        alt="User"
                        className="user-photo media-object "
                        style={{ maxHeight: "140px" }}
                        src={avatarURL}
                      />
                    )}
                  </div>
                  <div className="media-body">
                    <p>
                      {t("Upload your photo")}. <br />
                      <em>{t("Image should be at least 140px x 140px")}</em>
                    </p>
                    <a
                      href="#"
                      className="m-3"
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById("filePhoto").click();
                      }}
                    >
                      {t("Upload Photo")}
                    </a>
                    <input
                      className="sr-only"
                      id="filePhoto"
                      type="file"
                      accept="image/*"
                      onChange={handlePhotoChange}
                    />
                    <br />
                    {avatarURL.length !== 0 && (
                      <a
                        href="#"
                        className="m-3"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsDeletePhoto(true);
                          setAvatarURL("");
                        }}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <h6 className="mt-2">{t("Basic Information")}</h6>
              <div className="row clearfix">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>{t("First Name")}</label>
                    <input
                      className="form-control"
                      placeholder={`${t("First Name")}*`}
                      type="text"
                      value={firstName}
                      onChange={handleFirstNameChange}
                    />
                    {firstNameError.length > 0 && (
                      <span className="text-danger">{firstNameError}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>{t("Last Name")}</label>
                    <input
                      className="form-control"
                      placeholder={`${t("Last Name")}*`}
                      type="text"
                      value={lastName}
                      onChange={handleLastNameChange}
                    />
                    {lastNameError.length > 0 && (
                      <span className="text-danger">{lastNameError}</span>
                    )}
                  </div>
                  <div className="form-group p-1">
                    <label>{t("Gender")}</label>
                    <div>
                      <label className="fancy-radio">
                        <input
                          name="gender2"
                          type="radio"
                          value={1}
                          onChange={handleGenderChange}
                          checked={gender == 1}
                        />
                        <span>
                          <i></i>
                          {t("Male")}
                        </span>
                      </label>
                      <label className="fancy-radio">
                        <input
                          name="gender2"
                          type="radio"
                          value={2}
                          onChange={handleGenderChange}
                          checked={gender == 2}
                        />
                        <span>
                          <i></i>
                          {t("Female")}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("Date of birth")}</label>
                    <DatePicker
                      placeholderText={t("Date of birth")}
                      selected={dateOfBirth}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onChange={changeDate}
                      //  dateFormat={school.date_format}
                      className="datePicker"
                      popperPlacement="top"
                      maxDate={new Date()}
                    />
                    {age !== 0 && (
                      <span className="extra-small-text float-right">
                        {t("Age")}: {age.years} {t("years")} {age.months}{" "}
                        {t("months")}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label>{t("Email")}</label>
                    <input
                      className="form-control"
                      placeholder={t("Email")}
                      type="email"
                      value={email}
                      disabled
                    />
                  </div>
                  <label>{t("Mobile Number")}</label>
                  <div className="form-control" tabIndex={0}>
                    <PhoneInput
                    tabIndex="-1"
                      international
                      placeholder={`${t("Mobile Number")}*`}
                      defaultCountry={defaultCountry}
                      value={mobile}
                      onChange={(value) => {
                        setMobile(value);
                        setMobileError("");
                      }}
                    />
                    {mobileError.length > 0 && (
                      <div className="text-danger mt-2">{mobileError}</div>
                    )}
                  </div>
                  <br />
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>{t("Address Line 1")}</label>
                    <input
                      className="form-control"
                      placeholder={t("Address Line 1")}
                      type="text"
                      value={address1}
                      onChange={handleAddress1Change}
                    />
                  </div>
                  <div className="form-group">
                    <label>{t("Address Line 2")}</label>
                    <input
                      className="form-control"
                      placeholder={t("Address Line 2")}
                      type="text"
                      value={address2}
                      onChange={handleAddress2Change}
                    />
                  </div>

                  <div className="form-group">
                    <label>{t("Country")}</label>
                    <select
                      className="select-dropdown"
                      value={countryId || ""}
                      onChange={changeCountry}
                    >
                      <option disabled value="">
                        {t("Select Country")}
                      </option>

                      {country.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>{t("State")}</label>
                    <select
                      className="select-dropdown"
                      value={stateId || ""}
                      onChange={changeState}
                      disabled={!countryId}
                    >
                      <option disabled value="">
                        {isCountryLoading ? t("Loading...") : t("Select State")}
                      </option>
                      {state.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>{t("city")}</label>
                    <select
                      className="select-dropdown"
                      value={cityId || ""}
                      onChange={handleCityChange}
                      disabled={!stateId}
                    >
                      <option disabled value="">
                        {isCountryLoading ? t("Loading...") : t("Select city")}
                      </option>
                      {city.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>{t("Zipcode")}</label>
                    <input
                      className="form-control"
                      placeholder={t("Zipcode")}
                      value={pinCode}
                      onChange={changePinCode}
                    />
                    {pinCodeError.length > 0 && (
                        <span className="text-danger">{pinCodeError}</span>
                      )}
                  </div>
                </div>
              </div>
              <button
                type="button"
                onClick={handleSave}
                className="btn custom-primary"
              >
                {t("Update")}
              </button>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(ParentProfile);
