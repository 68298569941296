import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import moment from "moment/moment";
import { Rings } from "react-loader-spinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { t } from "i18next";
import Loader from "../../../components/common/Loader";
import Const from "../../../Const";

export default function BillingPlans(props) {
  const studentId = props.Student;
  const history = useHistory();
  const school = Util.getSelectedSchool();

  const [billingPlanList, setBillingPlanList] = useState([]);
  const [value, setValue] = React.useState("Active");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const BillingPlan = () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet(`billing-plans/student/${studentId}`)
      .then((res) => {
        setIsLoading(false);
        setBillingPlanList(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (Util.isValidData(studentId)) {
      BillingPlan();
    } else {
      history.push("/billing-plan-list");
    }
  }, [studentId]);
  const activePlans = billingPlanList.filter((b) => b.active === true);
  const inactivePlans = billingPlanList.filter((b) => b.active === false);

  return (
    <div className="container-fluid mt-2">
      <div className="d-flex justify-content-between align-items-center">
        <div className="medium-large-text">{t("Billing Plans")}</div>
        <div>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#f07a80",
                  height: 2,
                },
              }}
            >
              <Tab
                sx={{
                  letterSpacing: 0,
                  textTransform: "capitalize",
                  fontFamily: Const.fontfamily,
                  "&.Mui-selected": {
                    color: "#f07a80",
                    fontWeight: "600",
                  },
                }}
                value="Active"
                label={`${t("Active")} (${activePlans.length})`}
              />
              <Tab
                sx={{
                  letterSpacing: 0,
                  textTransform: "capitalize",
                  fontFamily: Const.fontfamily,
                  "&.Mui-selected": {
                    color: "#f07a80",
                    fontWeight: "600",
                  },
                }}
                value="Inactive"
                label={`${t("Inactive")} (${inactivePlans.length})`}
              />
            </Tabs>
          </Box>
        </div>
      </div>
      {!isLoading ? (
        <table
          className="table mt-2"
          style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
        >
          <thead style={{ backgroundColor: "#e5e7eb" }}>
            <tr>
              <th>#</th>
              <th>{t("Name")}</th>
              <th>{t("Starts/ends")}</th>
              <th>{t("Next Invoice Date")}</th>
              <th>{t("Amount")}</th>
            </tr>
          </thead>
          {value === "Active" ? (
            <tbody>
              {billingPlanList
                .filter((b) => b.active == true)
                .map((b, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{b.Billing_Plan.name}</td>
                      <td>
                        {moment(b.Billing_Plan.start_date).format(
                          Util.getMomentDateTimeFormat(school.date_format)
                        )}
                        {b.Billing_Plan.end_date !== null && (
                          <>
                            -
                            {moment(b.Billing_Plan.end_date).format(
                              Util.getMomentDateTimeFormat(school.date_format)
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        {moment(b.next_invoice_date).format(
                          Util.getMomentDateTimeFormat(school.date_format)
                        )}
                      </td>
                      <td>{Util.amountFormat(b.Billing_Plan.amount)}</td>
                    </tr>
                  );
                })}
            </tbody>
          ) : value === "Inactive" ? (
            <tbody>
              {billingPlanList
                .filter((b) => b.active == false)
                .map((b, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{b.Billing_Plan.name}</td>
                      <td>
                        {moment(b.Billing_Plan.start_date).format(
                          Util.getMomentDateTimeFormat(school.date_format)
                        )}
                        {b.Billing_Plan.end_date !== null && (
                          <>
                            -
                            {moment(b.Billing_Plan.end_date).format(
                              Util.getMomentDateTimeFormat(school.date_format)
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        {moment(b.next_invoice_date).format(
                          Util.getMomentDateTimeFormat(school.date_format)
                        )}
                      </td>
                      <td>{Util.amountFormat(b.Billing_Plan.amount)}</td>
                    </tr>
                  );
                })}
            </tbody>
          ) : (
            ""
          )}
          {value === "Active" && (
            <tfoot>
              {billingPlanList.filter((b) => b.active === true).length ===
                0 && (
                <tr>
                  <td colSpan="6">{t("No active plans")}</td>
                </tr>
              )}
            </tfoot>
          )}
          {value === "Inactive" && (
            <tfoot>
              {billingPlanList.filter((b) => b.active === false).length ===
                0 && (
                <tr>
                  <td colSpan="6">{t("No Inactive plans")}</td>
                </tr>
              )}
            </tfoot>
          )}
        </table>
      ) : (
        <Loader />
      )}
    </div>
  );
}
