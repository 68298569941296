import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ApiCalling from "../../network/ApiCalling";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Util from "../../Util";
import { useTranslation } from "react-i18next";
import Invalid from "../../assets/Icon/invalid.svg";
import ModalComponent from "../../screens/Dashbord/ModalComponent";
import Loader from "../common/Loader";
import Close from "../../assets/Icon/close.png";
import ToastHOC from "../../screens/HOC/ToastHOC";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, DialogTitle } from "@material-ui/core";
import Group from "../../assets/images/Group 691314497.png";
import Const from "../../Const";

function AcadamicYearList(props, { handleCloseDrawer }) {
 

  const history = useHistory();
  const { t } = useTranslation();
  const { setIsToast } = props;
  const school = Util.getSelectedSchool();
  const [isLoading, setIsLoading] = useState(false);
  const [editView, setEditView] = useState(false);
  const [editId, setEditId] = useState();
  const [academicYearData, setAcademicYearData] = useState([]);
  const [nameError, setNameError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [updateNameError, setUpdateNameError] = useState("");
  const [updateStartDateError, setUpdateStartDateError] = useState("");
  const [updateEndDateError, setUpdateEndDateError] = useState("");
  const [updateStatusError, setUpdateStatusError] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  
  const [fields, setFields] = useState([
    {
      id: 0,
      name: "",
      startDate: null,
      endDate: null,
      status: "",
    },
  ]);
  const [editFields, setEditFields] = useState([
    {
      updateId: 0,
      updateName: "",
      updateStartDate: null,
      updateEndDate: null,
      updateStatus: "",
    },
  ]);

  const CustomInput = ({ value, onClick, icon }) => (
    <div className="d-flex" onClick={onClick}>
      <input
        className={`custom-input__field ${
          startDateError.length > 0 && "datePicker-error"
        }`}
        placeholder="Start Date"
        value={value}
        readOnly
      />
    </div>
  );
  const CustomEndDateInput = ({ value, onClick, icon }) => (
    <div className="d-flex" onClick={onClick}>
      <input
        className={`custom-input__field ${
          endDateError.length > 0 && "datePicker-error"
        }`}
        placeholder="End Date"
        value={value}
        readOnly
      />
    </div>
  );
  const CustomUpdateStartDate = ({ value, onClick, icon }) => (
    <div className="d-flex" onClick={onClick}>
      <input
        className={`custom-input__field ${
          updateStartDateError.length > 0 && "datePicker-error"
        }`}
        placeholder="Start Date"
        value={value}
        readOnly
      />
    </div>
  );
  const CustomUpdateEndDate = ({ value, onClick, icon }) => (
    <div className="d-flex" onClick={onClick}>
      <input
        className={`custom-input__field ${
          updateEndDateError.length > 0 && "datePicker-error"
        }`}
        placeholder="End Date"
        value={value}
        readOnly
      />
    </div>
  );
  const handleEditInputChange = (index, event, date) => {
    const newField = [...editFields];
    newField[index][event.target.name] = event.target.value;
    setEditFields(newField);
    switch (event.target.name) {
      case "updateName":
        setUpdateNameError("");
        break;
      case "updateStartDate":
        setUpdateStartDateError("");
        break;
      case "updateEndDate":
        setUpdateEndDateError("");
        break;
      case "updateStatus":
        setUpdateStatusError("");
        break;
      default:
        break;
    }
  };
  const handleEditStatusChange = (index, event) => {
    const newFields = [...editFields];
    newFields[index][event.target.name] = event.target.value == "true";
    setFields(newFields);
  };
  const handleInputChange = (index, event, date) => {
    const newFields = [...fields];
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
    switch (event.target.name) {
      case "name":
        setNameError("");
        break;
      case "startDate":
        setStartDateError("");
        break;
      case "endDate":
        setEndDateError("");
        break;
      case "status":
        setStatusError("");
        break;
      default:
        break;
    }
  };

  const handleEditById = (e, value) => {
    e.preventDefault();
    // setVisibleAcadamicYearPopup(true);
    setEditView(true);
    setEditId(value.id);
    setEditFields([
      {
        updateId: value.id,
        updateName: value.title,
        updateStartDate: new Date(value.start_on),
        updateEndDate: new Date(value.end_on),
        updateStatus: value.active,
      },
    ]);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setNameError("");
    setStartDateError("");
    setEndDateError("");
    setStatusError("");
    var error = false;
    fields.map((field) => {
      if (field.name === "") {
        setNameError(t("Name is required"));
        error = true;
      }
      if (field.startDate === null) {
        setStartDateError(t("Start Date is required"));
        error = true;
      }
      if (field.endDate === null) {
        setEndDateError(t("End Date is required"));
        error = true;
      }

      if (field.status === "") {
        setStatusError(t("Status is required"));
        error = true;
      }

      if (!error) {
        setIsLoading(true);
        const insertData = {
          title: field.name,
          active: field.status,
          start_on: field.startDate,
          end_on: field.endDate,
        };
        ApiCalling.apiCallBodyDataPost("academic-year/add", insertData)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              setIsToast(t(res?.data?.message));
              AcademicList();

              setFields([
                {
                  id: 1,
                  name: "",
                  startDate: null,
                  endDate: null,
                  status: "",
                },
              ]);
              setTimeout(() => {
                props.handleCloseDrawer();
                history.push("/");
              }, 1000);
            } else {
              setEndDateError(res.data.message);
               
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setUpdateNameError("");
    setUpdateStartDateError("");
    setUpdateEndDateError("");
    setUpdateStatusError("");
    var error = false;
    editFields.map((f) => {
      if (f.updateName === "") {
        setUpdateNameError(t("Name is required"));
        error = true;
      }
      if (f.updateStartDate === null) {
        setUpdateStartDateError(t("Start Date is required"));
        error = true;
      }
      if (f.updateEndDate === null) {
        setUpdateEndDateError(t("End Date is required"));
        error = true;
      }

      if (f.updateStatus === "") {
        setUpdateStatusError(t("Status is required"));
        error = true;
      }

      if (!error) {
        setIsLoading(true);
        const data = {
          id: editId,
          title: f.updateName,
          active: f.updateStatus,
          start_on: f.updateStartDate,
          end_on: f.updateEndDate,
        };
        ApiCalling.apiCallBodyDataPost("academic-year/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsLoading(false);
              setIsToast(t(res?.data?.message));
              setEditView(false);
              AcademicList();
              setTimeout(() => {
                props.handleCloseDrawer();
                history.push("/");
              }, 1000);

              setEditFields([
                {
                  updateId: 0,
                  updateName: "",
                  updateStartDate: null,
                  updateEndDate: null,
                  updateStatus: "",
                },
              ]);
            } else {
              setUpdateEndDateError(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`academic-year/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setIsToast(t(res?.data?.message));
        setShowDeleteConfirmation(false);
        AcademicList();
        setTimeout(() => {
          props.handleCloseDrawer();
          history.push("/");
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const AcademicList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("academic-year/getBySchoolId")
      .then((res) => {
        setAcademicYearData(res?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    AcademicList();
  }, []);

  return (
    <div>
      <div className={"table-div"}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{t("Title")}</th>
              <th scope="col">{t("Start Date")}</th>
              <th scope="col">{t("End Date")}</th>
              <th scope="col">{t("Status")}</th>
              <th scope="col"> </th>
              <th scope="col"> </th>
            </tr>
          </thead>
          <tbody>
            {academicYearData?.map((data, index) => {
              let clas = "";
              if (data.active) {
                clas = "text-success";
              }
              return (
                <>
                  {editView == true && data.id === editId ? (
                    <>
                      {editFields.map((f, index) => {
                        return (
                          <tr key={f.id}>
                            <td></td>
                            <td>
                              <div className="input-filed">
                                <input
                                  name="updateName"
                                  className={`form-control custom-input ${
                                    updateNameError.length > 0 && "input-error"
                                  }`}
                                  placeholder={t("Title")}
                                  value={f.updateName}
                                  onChange={(event) =>
                                    handleEditInputChange(index, event)
                                  }
                                />
                                {updateNameError.length > 0 && (
                                  <span className="text-danger invalid-error">
                                    <img
                                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                                      src={Invalid}
                                    />
                                    {updateNameError}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="input-filed">
                                <DatePicker
                                  placeholderText={t("Start Date")}
                                  selected={editFields[index].updateStartDate}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  onChange={(date) =>
                                    handleEditInputChange(index, {
                                      target: {
                                        name: "updateStartDate",
                                        value: date,
                                      },
                                    })
                                  }
                                  dateFormat={school.date_format}
                                  popperPlacement="top"
                                  customInput={<CustomUpdateStartDate />}
                                />
                                {updateStartDateError.length > 0 && (
                                  <span className="text-danger invalid-error">
                                    <img
                                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                                      src={Invalid}
                                    />
                                    {updateStartDateError}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="input-filed">
                                <DatePicker
                                  placeholderText={t("End Date")}
                                  selected={editFields[index].updateEndDate}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  onChange={(date) =>
                                    handleEditInputChange(index, {
                                      target: {
                                        name: "updateEndDate",
                                        value: date,
                                      },
                                    })
                                  }
                                  dateFormat={school.date_format}
                                  popperPlacement="top"
                                  customInput={<CustomUpdateEndDate />}
                                />
                                {updateEndDateError.length > 0 && (
                                  <span className="text-danger invalid-error">
                                    <img
                                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                                      src={Invalid}
                                    />
                                    {updateEndDateError}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td style={{ width: "140px" }}>
                              <div className="input-filed">
                                <select
                                  className={`form-select w-100 ${
                                    updateStatusError.length > 0 &&
                                    "input-error"
                                  }`}
                                  name="updateStatus"
                                  value={f.updateStatus}
                                  onChange={(event, i) =>
                                    handleEditStatusChange(index, event)
                                  }
                                >
                                  <option value="" disabled>
                                    {t("Status")}
                                  </option>
                                  <option value={true}>{t("Active")}</option>
                                  <option value={false}>{t("Inactive")}</option>
                                </select>
                                {updateStatusError.length > 0 && (
                                  <span className="text-danger invalid-error">
                                    <img
                                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                                      src={Invalid}
                                    />
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="input-filed">
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    handleEdit(e);
                                  }}
                                >
                                  <i
                                    className="fa fa-floppy-o fa-lg"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </td>
                            <td>
                              <div className="input-filed">
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEditView(false);
                                  }}
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr key={data.id} className={clas}>
                      <th>{index + 1}</th>

                      <td>{data.title}</td>
                      <td>
                        {moment(data.start_on).format(
                          Util.getMomentDateTimeFormat(school.date_format)
                        )}
                      </td>
                      <td>
                        {moment(data.end_on).format(
                          Util.getMomentDateTimeFormat(school.date_format)
                        )}
                      </td>
                      <td>{data.active ? t("Active") : t("Inactive")}</td>
                      <td>
                        <a
                          href="#"
                          onClick={(e) => {
                            handleEditById(e, data);
                          }}
                        >
                          <i className="fa fa-edit"></i>
                        </a>
                      </td>
                      <td>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowDeleteConfirmation(true);
                            setDeleteId(data.id);
                          }}
                        >
                          <i className="fa fa-trash-o" aria-hidden="true"></i>
                        </a>
                      </td>
                    </tr>
                  )}
                </>
              );
            })}

            {fields.map((field, index) => {
              return (
                <tr key={field.id}>
                  <td></td>
                  <td>
                    <div className="input-filed">
                      <input
                        name="name"
                        className={`form-control custom-input ${
                          nameError.length > 0 && "input-error"
                        }`}
                        placeholder={t("Title")}
                        value={field.name}
                        onChange={(event) => handleInputChange(index, event)}
                      />
                      {nameError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {nameError}
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="input-filed">
                      <DatePicker
                        placeholderText={t("Start Date")}
                        selected={fields[index].startDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={(date) =>
                          handleInputChange(index, {
                            target: {
                              name: "startDate",
                              value: date,
                            },
                          })
                        }
                        dateFormat={school.date_format}
                        popperPlacement="top"
                        customInput={<CustomInput />}
                      />

                      {startDateError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {startDateError}
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="input-filed">
                      <DatePicker
                        placeholderText={t("End Date")}
                        selected={fields[index].endDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={(date) =>
                          handleInputChange(index, {
                            target: { name: "endDate", value: date },
                          })
                        }
                        dateFormat={school.date_format}
                        popperPlacement="top"
                        customInput={<CustomEndDateInput />}
                      />

                      {endDateError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {endDateError}
                        </span>
                      )}
                    </div>
                  </td>
                  <td style={{ width: "140px" }}>
                    <div className="input-filed">
                      <select
                        className={`form-select custom-input ${
                          statusError.length > 0 && "input-error"
                        }`}
                        name={t("status")}
                        value={field.status}
                        onChange={(event, i) => handleInputChange(index, event)}
                      >
                        <option value="" disabled>
                          {t("Status")}
                        </option>
                        <option value={true}>{t("Active")}</option>
                        <option value={false}>{t("Inactive")}</option>
                      </select>
                      {statusError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                          />
                          {statusError}
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="input-filed">
                      <a href="#" onClick={handleSave}>
                        <i
                          className="fa fa-floppy-o fa-lg"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-center w-100">
        <Dialog
          sx={{
            borderRadius: "10px",

            padding: "30px",
            fontFamily: Const.fontfamily,
          }}
          open={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontFamily: Const.fontfamily,
            }}
          >
            <div className="d-flex justify-content-end align-items center p-1 border-0">
              <button
                className="hidden-btn"
                onClick={() => setShowDeleteConfirmation(false)}
              >
                <img src={Close} className="svg-icon" height={20} width={20} />
              </button>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="d-flex justify-content-center">
              <img src={Group} alt="group" className="img-responsive" />
            </div>
            <div className="modal-contents text-center">
              <h3
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "#07112B",
                }}
              >
                Delete
              </h3>
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#4B5563",
                }}
              >
                <DialogContentText
                  id="alert-dialog-description"
                  sx={{ fontFamily: Const.fontfamily }}
                >
                  <span>
                    {t("Are you sure, to delete this Academic Year")}?
                    <br />
                    {t("With this deletion all data will be deleted")}.
                  </span>
                </DialogContentText>
              </span>
            </div>
          </DialogContent>
          <DialogActions>
            <button
              className="secondary-modal-btn btn"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </button>
            <button
              autoFocus
              className="primary-modal-lg-btn btn"
              onClick={handleDelete}
            >
              Confirm
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
export default ToastHOC(AcadamicYearList);
