import React, { useEffect, useState } from "react";
import ApiCalling from "../../../../network/ApiCalling";
import { useTranslation } from "react-i18next";
import Sort from "../../../../assets/Icon/Short.svg";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Select from "react-dropdown-select";
import Loader from "../../../../components/common/Loader";
import Calender from "../../../../assets/Icon/calendar.svg";
import DatePicker from "react-datepicker";
import Util from "../../../../Util";
import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";

export default function AgeReport() {
  const { t } = useTranslation();
  const forwardRef = React.forwardRef();
  const school = Util.getSelectedSchool();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [gradeData, setGradeData] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [roomData, setRoomData] = useState([]);
  const [roomId, setRoomId] = useState(0);
  const [studentStatus, setStudentStatus] = useState("");
  const [minAge, setMinAge] = useState(0);
  const [maxAge, setMaxAge] = useState(0);
  const [ageAsOfToday, setAgeAsOfToday] = useState(new Date());
  const [studentAgeList, setStudentAgeList] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };
  const CustomInput = ({ value, onClick, icon }, ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
        ref={ref}
        className="custom-input__field "
        value={value}
        readOnly
        placeholder="Date"
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  );
  const ageList = [
    { value: 1, age: 1 },
    { value: 2, age: 2 },
    { value: 3, age: 3 },
    { value: 4, age: 4 },
    { value: 5, age: 5 },
    { value: 6, age: 6 },
    { value: 7, age: 7 },
    { value: 8, age: 8 },
    { value: 9, age: 9 },
    { value: 10, age: 10 },
    { value: 11, age: 11 },
    { value: 12, age: 12 },
    { value: 13, age: 13 },
    { value: 14, age: 14 },
    { value: 15, age: 15 },
  ];

  const statusList = [
    { value: "all", title: "All" },
    { value: "true", title: "Active" },
    { value: "false", title: "Inactive" },
  ];

  const ChangeDateRange = (range) => {
    if (range) {
      setStartDate(new Date(range[0]));
      setEndDate(new Date(range[1]));
    }
  };
  const handleCleanDate = () => {
    setStartDate();
    setEndDate();
  };

  const changeGrade = (data) => {
    setGradeId(data[0].id);
  };

  const changeRoom = (data) => {
    setRoomId(data[0].id);
  };
  const changeStatus = (data) => {
    setStudentStatus(data[0].value);
  };
  const changeMinAge = (data) => {
    setMinAge(data[0].value);
  };
  const changeMaxAge = (data) => {
    setMaxAge(data[0].value);
  };

  const changeAgeAsOfToday = (dt) => {
    setAgeAsOfToday(dt);
  };
  const gradeList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("grade/list")
      .then((res) => {
        setIsLoading(false);
        setGradeData(res.data);
      })
      .catch((err) => {
        return err;
      });
  };

  const roomList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("classroom/list")
      .then((res) => {
        setIsLoading(false);
        const modifiedClassList = res.data.map((c) => ({
          id: c.id,
          name: c.name,
        }));
        setRoomData(modifiedClassList);
      })
      .catch((err) => {
        return err;
      });
  };
  const getStudentAgeList = () => {
    const data = {
      date: moment(ageAsOfToday).format("YYYY-MM-DD"),

      grade_id: gradeId,
      classroom_id: roomId,
      minAge: minAge,
      maxAge: maxAge,
      ascdesc: sortOrder ? "desc" : "asc",
      order_by: sortBy,
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("student/get-age-reports", data)
      .then((res) => {
        console.log("getCheckIn report",res)
        setIsLoading(false);
        // setStudentAgeList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const exportToExcel = () => {
    const dataForExport = studentAgeList.map((val) => ({
      date: moment(ageAsOfToday).format("YYYY-MM-DD"),
      minAge: minAge,
      maxAge: maxAge,
      StudentStatus:
        studentStatus == true
          ? "Active"
          : studentStatus == false
          ? "InActive"
          : "All",
      StudentID: val.id,
      StudentName: val.first_name + " " + val.last_name,
      ...(gradeId > 0 && { grade: gradeId }),
      ...(roomId > 0 && { classRoom: roomId }),

      Age: val?.age !== null ? val.age : "-",
      DateOfBirth:
        val?.dob !== null ? moment(val.dob).format("YYYY-MM-DD") : "-",
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataForExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "student Age Report");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const newTab = window.open(url, "_blank");

    newTab.addEventListener("load", () => {
      URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    if (ageAsOfToday !== null) {
      getStudentAgeList();
    }
  }, [ageAsOfToday, gradeId, roomId, minAge, maxAge, sortBy, sortOrder]);
  useEffect(() => {
    gradeList();
    roomList();
  }, []);
  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div className="container-fluid summary">
        <div className="row">
          <div className="col-lg-10 col-md-10 p-1">
            <div className="d-flex flex-wrap align-items-center gap-2">
              <div>
                <div>
                  <DateRangePicker
                    className="custom-range-picker"
                    onChange={(range) => ChangeDateRange(range)}
                    onClean={handleCleanDate}
                    placeholder="Select Date"
                  />
                </div>
              </div>
              <div>
                <Select
                  className="select-custom"
                  options={[{ id: 0, title: "Grade" }, ...gradeData]}
                  labelField="title"
                  valueField="id"
                  values={[{ id: 0, title: "Grade" }]}
                  onChange={(values) => changeGrade(values)}
                />
              </div>
              <div>
                <Select
                  className="select-custom"
                  options={[{ id: 0, name: "Room" }, ...roomData]}
                  labelField="name"
                  valueField="id"
                  values={[{ id: 0, name: "Room" }]}
                  onChange={(values) => changeRoom(values)}
                />
              </div>
              <div>
                <Select
                  className="select-custom"
                  options={[...statusList]}
                  labelField="title"
                  valueField="value"
                  values={[{ value: "all", title: "Student Status" }]}
                  onChange={(values) => changeStatus(values)}
                />
              </div>
              <div>
                <Select
                  className="select-custom"
                  options={[...ageList]}
                  labelField="age"
                  valueField="value"
                  values={[{ value: "all", age: "Min Age" }]}
                  onChange={(values) => changeMinAge(values)}
                />
              </div>
              <div>
                <Select
                  className="select-custom"
                  options={[...ageList]}
                  labelField="age"
                  valueField="value"
                  values={[{ value: "all", age: "Max Age" }]}
                  onChange={(values) => changeMaxAge(values)}
                />
              </div>
              <div style={{ minWidth: "150px" }}>
                <DatePicker
                  placeholderText="Date"
                  className="text-nowrap"
                  selected={ageAsOfToday}
                  onChange={changeAgeAsOfToday}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  popperPlacement="bottom"
                  customInput={<CustomInput icon={Calender} ref={forwardRef} />}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2  text-right">
            <button
              className="btn btn-lg text-nowrap custom-primary m-1"
              type="button"
              onClick={exportToExcel}
            >
              {t("Export Excel")}
            </button>
          </div>
        </div>
        <div className="table-div overflow-hidden mt-3 ">
          <div className="col-lg-12 col-md-12 p-0 m-0 ">
            <div className="">
              <div className="body">
                {!isLoading ? (
                  <>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            {t("Student Id")}
                            <img
                              src={Sort}
                              className="ml-1 fa-sort"
                              alt=""
                              onClick={() => {
                                handleSort("id");
                              }}
                            />
                          </th>
                          <th scope="col">
                            {t("Student Name")}{" "}
                            <img
                              src={Sort}
                              className="ml-1 fa-sort"
                              alt=""
                              onClick={() => {
                                handleSort("first_name");
                              }}
                            />
                          </th>
                          <th scope="col">
                            {t("Date of Birth")}{" "}
                            <img
                              src={Sort}
                              className="ml-1 fa-sort"
                              alt=""
                              onClick={() => {
                                handleSort("dob");
                              }}
                            />
                          </th>
                          <th scope="col">
                            {t("Age as of")}{" "}
                            {moment(ageAsOfToday).format(
                              Util.getMomentDateTimeFormat(school.date_format)
                            )}
                            <img
                              src={Sort}
                              className="ml-1 fa-sort"
                              alt=""
                              onClick={() => {
                                handleSort("age");
                              }}
                            />
                          </th>
                        </tr>
                      </thead>
                      {studentAgeList?.length > 0 ? (
                        <>
                          {studentAgeList?.map((val, index) => {
                            return (
                              <tbody key={index}>
                                <tr>
                                  <td>{Util.studentIdFormat(val.id)}</td>

                                  <td>
                                    {val.first_name + " " + val.last_name}
                                  </td>
                                  <td>
                                    {val.dob !== null
                                      ? moment(val.dob).format(
                                          Util.getMomentDateTimeFormat(
                                            school.date_format
                                          )
                                        )
                                      : "-"}
                                  </td>
                                  <td>{val.age !== null ? val.age : "-"}</td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </>
                      ) : (
                        <tfoot>
                          <tr>
                            <td colSpan={7}>{t("No Records found")}</td>
                          </tr>
                        </tfoot>
                      )}
                    </table>
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
