import React from "react";
import ToastHOC from "../../HOC/ToastHOC";
import ChildAbout from "./ChildAbout";
import ChildContact from "./ChildContact";
import ChildAttendence from "./ChildAttendence";
import { withTranslation } from "react-i18next";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";

class ChildProfileTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      getStudentDetails: null,
    };
  }
  getStudentData = async () => {
    ApiCalling.apiCallBodyDataGet(
      `student/${this.props.childInfo.Student.id}`,
      this.props.childInfo.Student.School.id
    ).then((res) => {
      this.setState({ getStudentDetails: res.data });
    });
  };
  componentDidMount() {
    const { setIsToast } = this.props;
    const pendingToast = this.props.location?.pendingToast;
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
    if(Util.isValidData(this.props.childInfo)){
      this.getStudentData();
    }
    
  }
  onTabChange = (e) => {
    var tab1 = document.getElementById("bacicTab2-1");
    tab1.classList.remove("active");
    tab1.children[0].classList.remove("active");
    var tab2 = document.getElementById("bacicTab2-2");
    tab2.classList.remove("active");
    tab2.children[0].classList.remove("active");
    var tab3 = document.getElementById("bacicTab2-3");
    tab3.classList.remove("active");
    tab3.children[0].classList.remove("active");

    var actab = document.getElementById("bacicTab2-" + e);
    actab.classList.remove("active");
    actab.children[0].classList.add("active");

    var tabpan1 = document.getElementById("bacicTab2pan-1");
    tabpan1.classList.remove("active");
    var tabpan2 = document.getElementById("bacicTab2pan-2");
    tabpan2.classList.remove("active");
    var tabpan3 = document.getElementById("bacicTab2pan-3");
    tabpan3.classList.remove("active");

    var actabpab = document.getElementById("bacicTab2pan-" + e);
    actabpab.classList.add("active");
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="container-fluid">
          <div className="body">
            <ul className="nav nav-tabs-new2" role="tablist">
              <li
                className="nav-item active"
                id="bacicTab2-1"
                role="presentation"
                onClick={() => {
                  this.onTabChange(1);
                }}
              >
                <a className="nav-link active">
                  <i className="fa fa-smile-o fa-fw" aria-hidden="true"></i>{" "}
                  {t("About")}
                </a>
              </li>

              <li
                className="nav-item mr-1"
                id="bacicTab2-2"
                role="presentation"
                onClick={() => {
                  this.onTabChange(2);
                }}
              >
                <a className="nav-link" data-toggle="tab">
                  <i className="fa fa-address-card fa-fw" aria-hidden="true"></i>{" "}
                  {t("Contact")}
                </a>
              </li>

              <li
                className="nav-item mr-1"
                id="bacicTab2-3"
                role="presentation"
                onClick={() => {
                  this.onTabChange(3);
                }}
              >
                <a className="nav-link" data-toggle="tab">
                  <i className="fa fa-bar-chart fa-fw" aria-hidden="true"></i>{" "}
                  {t("Attendance")}
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div id="bacicTab2pan-1" className="tab-pane show active">
                <ChildAbout
                  props={this.props}
                  studentDetails={this.state.getStudentDetails}
                />
              </div>

              <div id="bacicTab2pan-2" className="tab-pane">
                <ChildContact props={this.props} />
              </div>

              <div id="bacicTab2pan-3" className="tab-pane">
                <ChildAttendence props={this.props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ToastHOC(withTranslation()(ChildProfileTab));
