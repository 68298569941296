import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Rings } from "react-loader-spinner";
import ApiCalling from "../../../../network/ApiCalling";
import ToastHOC from "../../../HOC/ToastHOC";
import Util from "../../../../Util";
import { useHistory } from "react-router-dom";
import Loader from "../../../../components/common/Loader";

 function GeneralDetail(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { setIsToast } = props;
  const ParentDetails = props.ParentDetails;
  const [relationListOfParent, setRelationListOfParent] = useState([]);
  const [relationError, setRelationError] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);
  const [relation, setRelation] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    if(ParentDetails){
        setRelation(ParentDetails.student_parent_relation_type_id);
        setIsPrimary(ParentDetails.is_primary ? true : false);
    }
  },[ParentDetails])
  
  const handleSave = (e) => {
    e.preventDefault();
    setRelationError("");
    let error = false;

    if (relation === 0) {
      setRelationError("Please Select Relation");
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const data = {
        user_id: ParentDetails.user_id,
        is_primary: isPrimary ? "true" : "false",
        relation: relation,
      };
      ApiCalling.apiCallBodyDataPost("parent/edit-parent", data)
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            setIsToast(res.data.message);
            setRelationError("")
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleRelation = (e) => {
    setRelation(e.target.value);
    setRelationError("");
  };
  const handleIsPrimaryContact = (e) => {
    setIsPrimary(e.target.checked);
  };

  const relationList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("student-parent-relation-type").then((res) => {
      setIsLoading(false);
      setRelationListOfParent(res.data);
    });
  };
  useEffect(() => {
    relationList();
  }, []);
 
 
  return (
    <div>
      <div className="container-fluid">
        {!isLoading ? (
          <div className="row clearfix">
            <div className="col-lg-6">
              <div className="input-filed">
                <label>{t("Relation")}*</label>
                <select
                  name=""
                  id=""
                  className="select-dropdown"
                  value={relation}
                  onChange={handleRelation}
                >
                  <option selected disabled value="0">
                    {t("Select")}
                  </option>
                  {relationListOfParent?.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.type}
                    </option>
                  ))}
                </select>
                {relationError ? (
                  <span className="text-danger">{relationError}</span>
                ) : (
                  ""
                )}
              </div>
              <div className="fancy-checkbox mb-2">
                <label>
                  <input
                    name="primary"
                    type="checkbox"
                    checked={isPrimary}
                    onChange={handleIsPrimaryContact}
                  />
                  <span tabIndex={0}>
                    <strong>{t("is Primary Contact")}</strong>
                  </span>
                </label>
              </div>
              <button
                className="btn custom-primary btn-lg"
                type="button"
                onClick={handleSave}
              >
                {t("Save")}
              </button>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}
export default ToastHOC(GeneralDetail)