import React, { useEffect, useState } from "react";
import ApiCalling from "../../../../network/ApiCalling";
import {
  imageMyProfile,
  imagePost,
  imageStudentList,
  studentIdentity,
  userIdentity,
} from "../../../CommonImage";
import Search from "../../../../assets/Icon/Search.svg";
import Loader from "../../../../components/common/Loader";
import { useTranslation } from "react-i18next";

export default function StaffMedia(props) {

  const { t } = useTranslation();
  const userId = props.userId;
  const [staffMedia, setStaffMedia] = useState([]);
  const [filteredMedia, setFilteredMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const getStaffMedia = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`activity/staff-media/${userId}`)
      .then((res) => {
        setStaffMedia(res.data);
        setFilteredMedia(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    if (userId > 0) {
      getStaffMedia();
    }
  }, [userId]);
  function getIconClass(fileName) {
    const isPdf = fileName.match(/\.(pdf)$/i);
    const isDoc = fileName.match(/\.(doc|docx)$/i);
    const isEXL = fileName.match(/\.(xls|xlsx)$/i);

    if (isPdf) {
      return "fa fa-file-pdf-o fa-4x text-danger";
    } else if (isDoc) {
      return "fa fa-file-word-o fa-4x text-primary";
    } else if (isEXL) {
      return "fa fa-file-excel-o fa-4x text-success";
    }
  }
  return (
    <div className="">
      <div className="school-details my-2">
        <div className="p-4">
          {!isLoading ? (
            <div className="row">
              {staffMedia.length > 0 ? (
                <>
                  <div className="input-group mb-4">
                    <input
                      className="form-control search "
                      placeholder="Search"
                      type="text"
                      value={search}
                      onChange={(e) => {
                        // setSearch(e.target.value);
                        setSearch(e.target.value);
                        const searchValue = e.target.value;
                        if (searchValue.trim() === "") {
                          setFilteredMedia(staffMedia);
                        } else {
                          const filteredStaff = staffMedia.filter((s) =>
                            s.image
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                          );
                          setFilteredMedia(filteredStaff);
                        }
                      }}
                    />
                    <div className="input-group-prepend">
                      <div className="search-icon">
                        <img src={Search} className="svg-icon" alt="" />
                      </div>
                    </div>
                  </div>
                  {
                    filteredMedia.length > 0 ?
                    <>
                    {filteredMedia.map((st,index) => {
                    const isImage = st.image.match(/\.(jpg|jpeg|png|gif)$/i);
                    const img =
                      st.reference == "USER_PROFILE"
                        ? imageMyProfile + st.image
                        : st.reference == "USER_MEDIA"
                        ? imagePost + st.image
                        : st.reference == "USER_IDENTITY" && studentIdentity + st.image;
                    return (
                      <div className="col-lg-4 col-md-8 mb-4" key={index}>
                        <div className="media-container">
                          <div className="media-img-container">
                            {isImage ? (
                              <img src={img} alt="" className="img-fluid" />
                            ) : (
                              <i style={{marginTop:"65px"}} className={getIconClass(st.image)} />
                            )}
                          </div>
                          <div className="row media-file-container">
                            <div className="col-10 p-0">
                              <div className="large-text text-start wrapTwoLine">
                                {st.image}
                              </div>
                            </div>
                            <div className="col-2 text-end p-0">
                              <div className="dropdown">
                                <i
                                  className="fa fa-ellipsis-h"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  aria-hidden="true"
                                ></i>

                                <ul
                                  className="dropdown-menu plus-icon-dropdown"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li>
                                    <a className="dropdown-item" target="_blank" href={img}>{t("Download")}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                    </>
                    :
                    <div>{t("No Data Available")}</div>
                  }
                  
                </>
              ) : (
                <div>{t("No Data Found")}</div>
              )}
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
}
