import React, { useEffect, useState } from "react";
import ApiCalling from "../../../network/ApiCalling";
import Loader from "../../../components/common/Loader";
import Util from "../../../Util";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

export default function BillPlanLibrary() {

  const {t} = useTranslation();
  const school = Util.getSelectedSchool();
  const [billPlanList, setBillPlanList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getBillPlanList = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("billing-plans/saved")
      .then((res) => {
        setIsLoading(false);
        setBillPlanList(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getBillPlanList();
  }, []);
  return (
    <div>
      <div className="row py-3">
        <div className="col-md-6">
          <h2 className="page-head">{t("Billing Plan Library")}</h2>
        </div>
        <div className="col-md-8"></div>
      </div>

      <div className="table-div">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-wrapper">
              {!isLoading ? (
                <table
                  className="table"
                  style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
                >
                  <thead>
                    <tr>
                      <th>{t("Billing Plan")}</th>
                      <th>{t("Starts/ends")}</th>
                      <th>{t("Fees Type")}</th>
                      <th>{t("Amount")}</th>
                      <th>{t("Created On")}</th>
                      <th>{t("Total Students")}</th>
                    </tr>
                  </thead>
                  {billPlanList.length > 0 ? (
                    <tbody>
                      {billPlanList.map((a, index) => {
                        return (
                          <>
                            <tr key={a.id}>
                              <td>{a.name}</td>
                              <td>
                                {moment(a.start_date).format(
                                  Util.getMomentDateTimeFormat(
                                    school.date_format
                                  )
                                )}
                                {a.end_date !== null && (
                                  <>
                                    -
                                    {moment(a.end_date).format(
                                      Util.getMomentDateTimeFormat(
                                        school.date_format
                                      )
                                    )}
                                  </>
                                )}
                              </td>
                              <td>{a.Fee_Type.type}</td>
                              <td>
                                {Util.isValidData(a.amount) &&
                                  Util.amountFormat(a.amount)}
                              </td>
                              <td>{`${moment(a.created_on).fromNow()}`}</td>
                              <td>{a.students.length}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tfoot>
                      <tr>
                        <td colSpan={6}>{t("Not Found!")}</td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
