import React from "react";

// import logo from "../../assets/Icon/logo-black.svg";
// import logoWhite from "../../assets/Icon/logo-white.svg";

// import logo from "../../assets/Icon/LOGO_BLACK (1).svg";
import logo from "../../assets/Icon/schooltalk_Blacklogo.png";

import logoWhite from "../../assets/Icon/schooltalk_whitelogo.png";

export function Logo({ src, wrapperClass, style }) {
  return (
    <div className={wrapperClass ? wrapperClass : "navbar-brand"}>
      <img
        src={src || logo}
        alt="SchoolTalks"
        className="img-responsive"
        style={{ ...style }}
        loading="lazy"
      />
    </div>
  );
}

export function LogoWhite({ wrapperClass }) {
  return (
    <div className={wrapperClass ? wrapperClass : "navbar-brand"}>
      <img
        src={logoWhite}
        alt="SchoolTalks"
        className="img-responsive"
        
      />
    </div>
  );
}

export default Logo;
