import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";
import { Rings } from "react-loader-spinner";
import moment from "moment/moment";
import TagButton from "../TagButton";
import { greenTagStyle, orangeTagStyle, redTagStyle } from "../TagButtonStyle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ApiCalling from "../../../network/ApiCalling";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Select from "react-dropdown-select";
import { Avatar } from "@mui/material";
import { imageStudentList } from "../../CommonImage";
import PaymentView from "../../ParentsDashboard/Billing/PaymentView";
import Loader from "../../../components/common/Loader";

export default function PendingCredits() {
  const { t } = useTranslation();
  const history = useHistory();
  const school = Util.getSelectedSchool();
  const schoolId = school?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [allCreditList, setAllCreditList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);
  const [paymentId, setPaymentId] = useState(0);

  const searchClicked = () => {
    setCurrentPage(1);
    setSearchClick(search);
  };
  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setSearchClick(search);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const getAllCredits = () => {
    setIsLoading(true);
    const data = {
      school_id: schoolId,
      page: currentPage,
      size: recordPerPage,
      method: "",
      student_name: searchClick,
      status: "0,1",
    };
    ApiCalling.apiCallBodyDataPost("payment/school", data)
      .then((res) => {
        if (res.data.status == true) {
          setIsLoading(false);
          setAllCreditList(res.data.response);
          setTotalPages(res.data.total_page);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getAllCredits();
  }, [currentPage, recordPerPage, searchClick]);

 
  return (
    <div className="mt-4">
      <div className={"table-div"}>
        <div className="d-flex gap-20 align-items-center table-sort-bar">
          <div className="custom-search-wrap">
            <div className="input-group">
              <input
                className="form-control search"
                placeholder="Search Student"
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => EnterHandler(e)}
              />
              <div className="input-group-prepend">
                <div className="search-icon" onClick={searchClicked}>
                  <i className="fa fa-search"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="table-wrapper">
              {!isLoading ? (
                <table
                  className="table"
                  style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
                >
                  <thead style={{ backgroundColor: "#e5e7eb" }}>
                    <tr className="text-nowrap">
                      <th>{t("Student Name")}</th>
                      <th>{t("Payer")}</th>
                      <th>{t("Date")}</th>
                      <th>{t("adjusted against invoice")}</th>
                      <th>{t("Amount")}</th>
                      <th>{t("Status")}</th>
                      <th>{t("Balance")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  {allCreditList.length > 0 ? (
                    <tbody>
                      {allCreditList.map((c, index) => {
                        const invoice_settled_ids = c?.invoice_settled.map(
                          (c) => c.invoice_id
                        );

                        const invoice_settled = invoice_settled_ids.map(
                          (id) => {
                            return Util.invoiceIdFormat(id);
                          }
                        );

                        return (
                          <>
                            <tr key={index}>
                              <td>
                                <div className="d-flex align-items-center">
                                  {c.Student.photo ? (
                                    <img
                                      className="rounded-circle "
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                      }}
                                      src={imageStudentList + c.Student.photo}
                                      alt=""
                                    />
                                  ) : (
                                    <Avatar
                                      aria-label="recipe"
                                      className="avatar-font"
                                    >
                                      {c.Student.first_name.toUpperCase()[0] +
                                        c.Student.last_name.toUpperCase()[0]}
                                    </Avatar>
                                  )}
                                  <a className="ml-2">
                                    {c.Student.first_name} {c.Student.last_name}
                                  </a>
                                </div>
                              </td>
                              <td>
                                {c.payment_method}
                                <div className="small-text">
                                  {t("By")}{" "}
                                  {c.User.firstName + " " + c.User.lastName}
                                </div>
                              </td>
                              <td>
                                {moment(c.date).format(
                                  Util.getMomentDateTimeFormat(
                                    school.date_format
                                  )
                                )}
                              </td>

                              <td>
                                {invoice_settled.length > 0 ? (
                                  <p style={{ whiteSpace: "pre-line" }}>
                                    {Util.getListDisplay(
                                      invoice_settled,
                                      2,
                                      "\n"
                                    )}
                                  </p>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>{Util.amountFormat(c.amount)}</td>
                              <td>
                                <TagButton
                                  style={"btn btn-secondary"}
                                  size={"150px"}
                                  title={Util.getPaymentStatus(c.status)}
                                  buttonStyle={
                                    c.status == 0
                                      ? redTagStyle
                                      : c.status == 1
                                      ? orangeTagStyle
                                      : greenTagStyle
                                  }
                                  icon="fa fa-circle"
                                  iconSize="6px"
                                />
                              </td>
                              <td>{Util.balanceFormat(c.not_used_amount)}</td>
                              <td>
                                <div className="dropdown">
                                  <button
                                    className="btn btn-lg custom-primary"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{
                                      color: "#ffffff",
                                      padding: "5px, 10px",
                                    }}
                                  >
                                    {t("Action")}
                                  </button>
                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        role="button"
                                        onClick={() => {
                                          setIsVisiblePayment(true);
                                          setPaymentId(c.id);
                                        }}
                                      >
                                        {t("View")}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tfoot>
                      <tr>
                        <td colSpan={7}>{t("Not Found!")}</td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              ) : (
                <Loader />
              )}
              <PaymentView
                show={isVisiblePayment}
                onClose={() => {
                  setIsVisiblePayment(false);
                }}
                PaymentId={paymentId}
              />
            </div>
            {totalPages > 0 && (
              <div className="row m-0 table-footer border-top mb-2">
                <div className="d-flex justify-content-end mt-2">
                  <Stack spacing={2} direction="row" alignItems="center">
                    <div
                      className={`link-button ${
                        currentPage == 1 ? "disabled" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handlePreviousClick();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <KeyboardArrowLeftIcon />
                      <span
                        className="basic-text"
                        style={{ fontWeight: "600" }}
                      >
                        {t("Previous")}
                      </span>
                    </div>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, page) => {
                        setCurrentPage(page);
                      }}
                      size="small"
                      hideNextButton
                      hidePrevButton
                    />
                    <div
                      className={`link-button ${
                        currentPage == totalPages ? "disabled" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleNextClick();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="basic-text"
                        style={{ fontWeight: "600" }}
                      >
                        {t("Next")}
                      </span>
                      <KeyboardArrowRightIcon />
                    </div>
                  </Stack>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
