import React, { useEffect, useRef, useState } from "react";
import EditPhotoModal from "../Dashbord/EditPhotoModal";
import Location from "../../assets/Icon/location.svg";
import camera from "../../assets/Icon/camera-icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { SeprateDot } from "../Dashbord/Student/StudentDetails";
import StaffPersonalInfo from "../Dashbord/Staff/StaffPersonalInfo";
import Calander from "../../assets/Icon/calendar.svg";
import Util from "../../Util";
import PhoneInput from "react-phone-number-input";
import StaffAddress from "../Dashbord/Staff/StaffAddress";
import fileIcon from "../../assets/Icon/file.svg";
import CustomFileUpload from "../Dashbord/CustomFileUpload";
import StaffAvtar from "../../assets/Icon/Parent-avtar.svg";
import Edit from "../../assets/Icon/Edit-blue.svg";
import moment from "moment/moment";
import ApiCalling from "../../network/ApiCalling";
import {
  imageMyProfile,
  studentIdentity,
  studentInsurance,
  userIdentity,
} from "../CommonImage";
import ToastHOC from "../HOC/ToastHOC";
import Invalid from "../../assets/Icon/invalid.svg";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { userUpdate } from "../../actions/NotifyUserUpdateAction";

const MyProfileStaff = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setIsToast, setIsSubToast } = props;
  const datepickerRef = useRef(null);
  const user = Util.getUser();
  const userId = user?.id;
  const school = Util.getSelectedSchool();
  const parentMode = Util.getParentMode();
  const selected = useSelector((state) => state.SelectedStudentReducer);

  const [showPersnoalAcc, setShowPersonalAcc] = useState(true);
  const [showaddressAcc, setShowAddressAcc] = useState(false);
  const [informationAcc, setInformationAcc] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [userDetails, setUserDetails] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const [photo, setPhoto] = useState("");

  const [personalDetailEdit, setPersonalDetailEdit] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [dob, setDob] = useState(null);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contactNumber, setContactNumber] = useState(null);
  const [contactNumberError, setContactNumberError] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("");
  const [gender, setGender] = useState(0);

  const [AddressEdit, setAddressEdit] = useState(false);

  const [informationEdit, setInformationEdit] = useState(false);
  const [birthPlaceId, setBirthPlaceId] = useState(0);
  const [raceId, setRaceId] = useState(0);
  const [ethnicityId, setEthnicityId] = useState(0);
  const [nationalityId, setNationalityId] = useState(0);
  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [insuranceProvider, setInsuranceProvider] = useState("");
  const [raceList, setRaceList] = useState([]);
  const [ethnicityList, setEthnicityList] = useState([]);
  const [country, setCountry] = useState([]);
  const [identityUpload, setIdentityUpload] = useState("");
  const [identityURL, setIdentityURL] = useState("");
  const [identityFileName, setIdentityFileName] = useState("");
  const [notes, setNotes] = useState("");

  const [selectedBirthPlace, setSelectedBirthPlace] = useState("");
  const [selectedNationality, setSelectedNationality] = useState("");
  const [selectedEthnicity, setSelectedEthnicity] = useState("");
  const [selectedRace, setSelectedRace] = useState("");
  const [isImageLoading,setIsImageLoading] = useState(false);

  const getUserData = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("user/get-userdetail-byid/" + userId).then(
      (res) => {
        if (Util.isValidData(res.data)) {
          setIsLoading(false);
          setUserDetails(res?.data?.user);
          const data = res?.data?.user;
          setPhotoURL(
            Util.isValidData(data?.avatar) ? imageMyProfile + data?.avatar : ""
          );
          setFirstName(data?.firstName);
          setLastName(data?.lastName);
          if (Util.isValidData(data?.dob)) {
            setDob(new Date(data?.dob));
          }
          setEmail(data?.email);
          setContactNumber(Util.isValidData(data?.mobile) ? data?.mobile : null);
          setGender(data?.gender);
          setBirthPlaceId(
            Util.isValidData(data?.birth_place_id) ? data?.birth_place_id : 0
          );
          setRaceId(Util.isValidData(data?.race_id) ? data?.race_id : 0);
          setNationalityId(
            Util.isValidData(data?.nationality_id) ? data?.nationality_id : 0
          );
          setEthnicityId(
            Util.isValidData(data?.ethnicity_id) ? data?.ethnicity_id : 0
          );
          setInsuranceNumber(data?.insurance_number);
          setInsuranceProvider(data?.insurance_provider);
          setNotes(data?.notes);
          setIdentityFileName(
            Util.isValidData(data.identity) ? data.identity : ""
          );
          setSelectedBirthPlace(data?.birth_place);
          setSelectedEthnicity(data?.ethnicity);
          setSelectedNationality(data?.race);
          setSelectedRace(data?.nationality);
        }
      }
    );
  };

  useEffect(() => {
    if (userId > 0) {
      getUserData();
    } else {
      history.push("/ParentDashboard");
    }
  }, [userId]);

  const racelist = async () => {
    ApiCalling.apiCallBodyDataGet(
      "race",
      parentMode && selected.selectedStudent.student.School.id
    )
      .then((res) => {

        setRaceList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ethnicitylist = async () => {
    ApiCalling.apiCallBodyDataGet(
      "ethnicity",
      parentMode && selected.selectedStudent.student.School.id
    )
      .then((res) => {

        setEthnicityList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllCountryList = () => {
    ApiCalling.apiCallBodyDataGet("country/list").then((res) => {

      setCountry(res?.data);
    });
  };

  const handleLogoChange = (e) => {
    if (e.target.files[0]) {
      setShowEditModal(true);
    }
    setPhoto(e.target.files[0]);
    setPhotoURL(URL.createObjectURL(e.target.files[0]));
  };

  const toggleDatePicker = () => {
    datepickerRef.current.setOpen(true);
  };

  const handleSavePhoto = () => {
    setIsImageLoading(true);
    const formData = new FormData();
    formData.append("id", userId);
    if (photo) {
      formData.append("avatar", photo);
    }
    ApiCalling.apiCallBodyDataPost(
      "user/dynamic-update",
      formData,
      parentMode && selected.selectedStudent.student.School.id
    )
      .then((res) => {
        if (res.data.status == true) {
          setIsImageLoading(false);
          dispatch(userUpdate(true));
          setShowEditModal(false);
          setIsToast(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleGenderChange = (event) => {
    setGender(Number(event.target.value));
  };

  const resetPersonalInfo = () => {
    setFirstNameErr("");
    setLastNameErr("");
    setEmailError("");
    setContactNumberError("");
  };

  const handleSavePersonalDetails = (e) => {
    e.preventDefault();
    setFirstNameErr("");
    setLastNameErr("");
    setEmailError("");
    setContactNumberError("");
    let error = false;
    if (firstName == "") {
      setFirstNameErr(t("First Name is required"));
      error = true;
    }
    if (lastName == "") {
      setLastNameErr(t("Last Name is required"));
      error = true;
    }
    if (email == "") {
      setEmailError(t("Email is required"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    }
    if (contactNumber == undefined || contactNumber == null) {
      setContactNumberError(t("Phone is required"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("id", userId);
      if (firstName) {
        formData.append("firstName", firstName);
      }
      if (lastName) {
        formData.append("lastName", lastName);
      }
      if (dob) {
        formData.append("dob", dob.toISOString());
      }
      if (email) {
        formData.append("email", email);
      }
      if (contactNumber) {
        formData.append("mobile", contactNumber);
      }
      if (gender) {
        formData.append("gender", parseInt(gender));
      }
      ApiCalling.apiCallBodyDataPost(
        "user/dynamic-update",
        formData,
        parentMode && selected.selectedStudent.student.School.id
      )
        .then((res) => {
          if (res.data.status == true) {
            dispatch(userUpdate(true));
            setIsLoading(false);
            resetPersonalInfo();
            setIsToast(t("User Updated Successfully"))
          setIsSubToast(t("You have successfully change Personal Details"));
            getUserData();
            setPersonalDetailEdit(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const handleSaveInformation = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", userId);
    if (insuranceNumber) {
      formData.append("insurance_number", insuranceNumber);
    }
    if (insuranceProvider) {
      formData.append("insurance_provider", insuranceProvider);
    }
    if (birthPlaceId) {
      formData.append("birth_place_id", birthPlaceId);
    }
    if (raceId) {
      formData.append("race_id", raceId);
    }
    if (ethnicityId) {
      formData.append("ethnicity_id", ethnicityId);
    }
    if (nationalityId) {
      formData.append("nationality_id", nationalityId);
    }
    if (notes) {
      formData.append("notes", notes);
    }
    if (identityUpload) {
      formData.append("identity", identityUpload);
    }
    ApiCalling.apiCallBodyDataPost(
      "user/dynamic-update",
      formData,
      parentMode && selected.selectedStudent.student.School.id
    )
      .then((res) => {
        if (res.data.status == true) {
          setIsLoading(false);
          setIsToast(t("User Updated Successfully"))
          setIsSubToast(t("You have successfully change Personal Details"));
          getUserData();
          setInformationEdit(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleInsuranceDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };

  const handleInsuranceDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileChange = (file) => {
    if (file) {
      const maxSize = 25 * 1024 * 1024;
      if (file.size <= maxSize) {
        setIdentityUpload(file);
        setIdentityURL(URL.createObjectURL(file));
      } else {
        setIsToast(t("File size exceeds the limit of 25MB"));
      }
    }
  };

  const handleInsuranceUpload = (e) => {
    const file = e.target.files[0];
    handleFileChange(file);
  };

  const changeDate = (date) => {
    setDob(date);
    // const calculatedAge = Util.calculateAge(date);
    // setAge(calculatedAge);
  };
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let countryCode = "";
    if (browserLanguage) {
      if (browserLanguage.includes("-")) {
        countryCode = browserLanguage.split("-")[1].toUpperCase();
      } else {
        countryCode = browserLanguage.toUpperCase();
      }
      setDefaultCountry(countryCode);
    }
    getAllCountryList();
  }, []);
  useEffect(() => {
    if (parentMode) {
      if (selected !== null) {
        racelist();
        ethnicitylist();
      }
    } else {
      racelist();
      ethnicitylist();
    }
  }, [selected]);

  const CustomInput = React.forwardRef(({ value, onClick },ref) => (
    <div className="d-flex" onClick={onClick}>
      <input ref={ref} className="custom-input__field" value={value} readOnly />
      <div className="custom-input__icon">
        <img src={Calander} alt="Calander" className="svg-icon calender-icon" />
      </div>
    </div>
  ));

  return (
    <>
      <div className="container-80">
        <div className="school-details my-4">
          {!isLoading ? (
            <div className="p-4">
              <div className="d-flex align-items-center gap-4">
                {photoURL.length > 0 ? (
                  <div
                    className="profile-area"
                    style={{
                      position: "relative",
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      alt="User"
                      className="rounded-circle user-photo img-responsive  mt-1 "
                      src={photoURL}
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                      }}
                    />{" "}
                    <span
                      className="choose-profile"
                      onClick={(e) => {
                        e.preventDefault();
                        // document.getElementById("photo").click();
                        setShowEditModal(true);
                      }}
                    >
                      <img src={camera} alt="" />
                    </span>
                  </div>
                ) : (
                  <div
                    className="profile-area"
                    style={{
                      position: "relative",
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      alt="User"
                      className="img-responsive  mt-1"
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                        position: "relative",
                      }}
                      src={StaffAvtar}
                    />
                    <span
                      className="choose-profile"
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById("photo").click();
                      }}
                    >
                      <img src={camera} alt="" />
                    </span>
                    <input
                        className="sr-only"
                        id="photo"
                        type="file"
                        accept="image/*"
                        onChange={handleLogoChange}
                      />
                  </div>
                )}

                <EditPhotoModal
                  show={showEditModal}
                  onClose={(msg) => {
                    setShowEditModal(false);
                    setPhotoURL(
                      Util.isValidData(userDetails?.avatar)
                        ? imageMyProfile + userDetails?.avatar
                        : ""
                    );
                  }}
                  title={t("Edit Photo")}
                  subTitle={t("Show profile picture the best version of yourself")}
                  onChange={t("Change Image")}
                  onSave={t("Save Photo")}
                  Id={userId}
                  onConfirmClick={() => setShowEditModal(false)}
                  photoURL={photoURL}
                  ProfilePhoto={photoURL}
                  handlePhotoChange={handleLogoChange}
                  defaultAvtar={StaffAvtar}
                  handleSavePhoto={handleSavePhoto}
                  isLoading={isImageLoading}
                />

                <div>
                  <>
                    <h2 className="extra-large-text font-color-primary">
                      {userDetails?.firstName + " " + userDetails?.lastName}
                    </h2>

                    <span>
                      {userDetails?.Country && (
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#4B5563",
                          }}
                        >
                          <img src={Location} className="mr-2" alt="" />
                          {userDetails?.Country ? userDetails?.Country : ""}
                        </div>
                      )}
                    </span>
                  </>
                </div>
              </div>
              <hr style={{ backgroundColor: "#E5E7EB" }} />

              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="medium-large-text font-color-primary py-2">
                    {t("Personal Details")}
                  </div>

                  {!personalDetailEdit && (
                    <div className="d-flex justify-content-center align-items-center">
                      <span
                        role="button"
                        onClick={() => {
                          setPersonalDetailEdit(!personalDetailEdit);
                          setShowPersonalAcc(true);
                          setAddressEdit(false);
                          setInformationEdit(false);
                        }}
                        className="link-button"
                      >
                        <img src={Edit} alt="" className="edit-svg " />
                        {t("Edit")}
                      </span>
                      <div className="vertical-bar"></div>
                      <div
                        className="accoridan-btn"
                        onClick={() => setShowPersonalAcc(!showPersnoalAcc)}
                      >
                        <i
                          className={
                            showPersnoalAcc
                              ? "fa fa-angle-up"
                              : "fa fa-angle-down"
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  )}
                </div>
                {showPersnoalAcc && (
                  <div className="row text-start">
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text required">
                          {t("First Name")}
                        </span>
                        {!personalDetailEdit ? (
                          <div className="large-bold-text">
                            {Util.isValidData(firstName) ? firstName : "-"}
                          </div>
                        ) : (
                          <>
                            <input
                              className={`form-control custom-input ${
                                firstNameErr.length > 0 ? "input-error" : ""
                              }`}
                              value={firstName}
                              onChange={(e) => {
                                setFirstName(e.target.value);
                                setFirstNameErr("");
                              }}
                            />
                            {firstNameErr.length > 0 && (
                              <span className="text-danger invalid-error">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {firstNameErr}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text required">
                          {t("Last Name")}
                        </span>
                        {!personalDetailEdit ? (
                          <div className="large-bold-text">
                            {Util.isValidData(lastName) ? lastName : "-"}
                          </div>
                        ) : (
                          <>
                            <input
                              className={`form-control custom-input ${
                                lastNameErr.length > 0 ? "input-error" : ""
                              }`}
                              value={lastName}
                              onChange={(e) => {
                                setLastName(e.target.value);
                                setLastNameErr("");
                              }}
                            />
                            {lastNameErr.length > 0 && (
                              <span className="text-danger invalid-error">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {lastNameErr}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6" style={{ cursor: "pointer" }}>
                      <div className="form-group">
                        <span className="basic-text ">{t("Gender")}</span>
                        {!personalDetailEdit ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "#07112b",
                            }}
                          >
                            {Util.isValidData(gender) && (
                              <>
                                {gender == 1
                                  ? t("Male")
                                  : gender == 2
                                  ? t("Female")
                                  : "-"}
                              </>
                            )}
                          </div>
                        ) : (
                          <>
                            <div className="mt-2 d-flex align-items-center gap-4">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  value={1}
                                  onChange={handleGenderChange}
                                  checked={gender == 1}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault1"
                                >
                                  {t("Male")}
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  value={2}
                                  onChange={handleGenderChange}
                                  checked={gender == 2}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault1"
                                >
                                  {t("Female")}
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text required">
                          {t("Date of Birth")}
                        </span>
                        {!personalDetailEdit ? (
                          <div className="large-bold-text">
                            {Util.isValidData(dob)
                              ? moment(dob).format("DD/MM/YYYY")
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <DatePicker
                              ref={datepickerRef}
                              placeholderText="Select Date"
                              selected={dob}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              onChange={changeDate}
                              // dateFormat={school.date_format}
                              className="datePicker"
                              popperPlacement="bottom"
                              maxDate={new Date()}
                              customInput={<CustomInput />}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text required">
                          {t("Email")}
                        </span>
                        {!personalDetailEdit ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "#07112b",
                            }}
                          >
                            {Util.isValidData(email) ? email : "-"}
                          </div>
                        ) : (
                          <>
                            <input
                              className={`form-control custom-input ${
                                emailError.length > 0 ? "input-error" : ""
                              }`}
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailError("");
                              }}
                              disabled
                            />
                            {emailError.length > 0 && (
                              <span className="text-danger invalid-error">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {emailError}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text required">
                          {t("Phone Number")}
                        </span>
                        {!personalDetailEdit ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "#07112b",
                            }}
                          >
                            {Util.isValidData(contactNumber)
                              ? contactNumber
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <div
                              className={`form-control custom-input ${
                                contactNumberError.length > 0
                                  ? "input-error"
                                  : ""
                              }`}
                              tabIndex={0}
                            >
                              <PhoneInput
                                tabIndex="-1"
                                international
                                defaultCountry={defaultCountry}
                                value={contactNumber}
                                onChange={(value) => {
                                  setContactNumber(value);
                                  setContactNumberError("");
                                }}
                              />
                            </div>
                            {contactNumberError.length > 0 && (
                              <span className="text-danger mt-2 invalid-error">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {contactNumberError}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {personalDetailEdit && (
                      <div className="text-end mt-2">
                        <button
                          className="btn secondary-lg-btn rounded-btn  mr-2  m-0 px-2"
                          onClick={() => {
                            // resetPersonalDetails();
                            setShowPersonalAcc(false);
                            setPersonalDetailEdit(false);
                          }}
                        >
                          {t("Cancel")}
                        </button>

                        <button
                          className="primary-lg-btn btn rounded-btn"
                          onClick={handleSavePersonalDetails}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <hr style={{ backgroundColor: "#E5E7EB" }} />

              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="medium-large-text font-color-primary py-2">
                    {t("Address")}
                  </div>

                  <>
                    {!AddressEdit && (
                      <div className="d-flex justify-content-center align-items-center">
                        <span
                          role="button"
                          onClick={() => {
                            setAddressEdit(true);
                            setShowAddressAcc(true);
                            setShowPersonalAcc(false);
                            setInformationAcc(false);
                            setPersonalDetailEdit(false);
                            setInformationEdit(false);
                          }}
                          className="link-button"
                        >
                          <img src={Edit} alt="" className="edit-svg " />
                          {t("Edit")}
                        </span>
                        <div className="vertical-bar"></div>
                        <div
                          className="accoridan-btn"
                          onClick={() => setShowAddressAcc(!showaddressAcc)}
                        >
                          <i
                            className={
                              showaddressAcc
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    )}
                  </>
                </div>
                {showaddressAcc ? (
                  <StaffAddress
                    AddressEdit={AddressEdit}
                    data={userDetails}
                    userId={userId > 0 ? userId : "-1"}
                    notification={(msg) => {
                      if (msg) {
                        setIsToast(msg);
                        setIsSubToast(t("You have successfully change Address Details"));
                        getUserData();
                        setAddressEdit(false);
                      }
                    }}
                    onCancel={() => {
                      setAddressEdit(false);
                      setShowAddressAcc(false);
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              <hr style={{ backgroundColor: "#E5E7EB" }} />

              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="medium-large-text font-color-primary py-2">
                    {t("Information")}
                  </div>
                  {!informationEdit && (
                    <div className="d-flex justify-content-center align-items-center">
                      <span
                        role="button"
                        className="link-button"
                        onClick={() => {
                          setInformationEdit(!informationEdit);
                          setInformationAcc(true);
                          setShowPersonalAcc(false);
                          setShowAddressAcc(false);
                          setAddressEdit(false);
                        setPersonalDetailEdit(false);
                        }}
                      >
                        <img src={Edit} alt="" className="edit-svg " />
                        {t("Edit")}
                      </span>
                      <div className="vertical-bar"></div>
                      <div
                        className="accoridan-btn"
                        onClick={() => setInformationAcc(!informationAcc)}
                      >
                        <i
                          className={
                            informationAcc
                              ? "fa fa-angle-up"
                              : "fa fa-angle-down"
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  )}
                </div>
                {informationAcc ? (
                  <div className="row text-start">
                    <div className="col-lg-6 mb-0">
                      <div className="input-filed ">
                        <span className="basic-text ">{t("Birth Place")}</span>
                        {!informationEdit ? (
                          <div className="large-bold-text">
                            {Util.isValidData(selectedBirthPlace)
                              ? selectedBirthPlace
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <div className="form-group">
                              <select
                                className="select-dropdown form-select  custom-input"
                                value={birthPlaceId}
                                onChange={(e) => {
                                  setBirthPlaceId(e.target.value);
                                }}
                              >
                                <option disabled value={0}>
                                  {t("Select BirthPlace")}
                                </option>
                                {country?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 mb-0">
                      <div className="input-filed ">
                        <span className="basic-text ">{t("Race")}</span>
                        {!informationEdit ? (
                          <div className="large-bold-text">
                            {Util.isValidData(selectedRace)
                              ? selectedRace
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <div className="form-group">
                              <select
                                className="select-dropdown form-select  custom-input"
                                value={raceId}
                                onChange={(e) => {
                                  setRaceId(e.target.value);
                                }}
                              >
                                <option disabled value={0}>
                                  {t("Select Race")}
                                </option>
                                {raceList?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item?.race}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text ">{t("Nationality")}</span>
                        {!informationEdit ? (
                          <div className="large-bold-text">
                            {Util.isValidData(selectedNationality)
                              ? selectedNationality
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <div className="form-group">
                              <select
                                className="select-dropdown form-select  custom-input"
                                value={nationalityId}
                                onChange={(e) => {
                                  setNationalityId(e.target.value);
                                }}
                              >
                                <option disabled value={0}>
                                  {t("Select Nationality")}
                                </option>
                                {country?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text ">{t("Ethnicity")}</span>
                        {!informationEdit ? (
                          <div className="large-bold-text">
                            {Util.isValidData(selectedEthnicity)
                              ? selectedEthnicity
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <div className="form-group">
                              <select
                                className="select-dropdown form-select  custom-input"
                                value={ethnicityId}
                                onChange={(e) => {
                                  setEthnicityId(e.target.value);
                                }}
                              >
                                <option disabled value={0}>
                                  {t("Select Ethnicity")}
                                </option>
                                {ethnicityList?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item?.ethnicity}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span
                          className="basic-text"
                          style={{ fontWeight: "400" }}
                        >
                          {t("Insurance Number")}
                        </span>
                        {!informationEdit ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "#07112b",
                            }}
                          >
                            {Util.isValidData(insuranceNumber)
                              ? insuranceNumber
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <input
                              className={`form-control custom-input`}
                              value={insuranceNumber}
                              onChange={(e) => {
                                setInsuranceNumber(e.target.value);
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-filed ">
                        <span className="basic-text">
                          {t("Insurance Provider")}
                        </span>
                        {!informationEdit ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "#07112b",
                            }}
                          >
                            {Util.isValidData(insuranceProvider)
                              ? insuranceProvider
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <input
                              className={`form-control custom-input`}
                              value={insuranceProvider}
                              onChange={(e) => {
                                setInsuranceProvider(e.target.value);
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <div className={informationEdit ? "col-12" : "col-6"}>
                      <div className="input-filed ">
                        <span
                          className="basic-text"
                        >
                          {t(" Notes")}
                        </span>
                        {!informationEdit ? (
                          <div className="large-bold-text">
                            {Util.isValidData(notes) ? notes : "-"}
                          </div>
                        ) : (
                          <>
                            <textarea
                              style={{ width: "100%", minHeight: "120px" }}
                              className="form-control custom-input"
                              value={notes}
                              onChange={(e) => setNotes(e.target.value)}
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <div className={informationEdit ? "col-12" : "col-6"}>
                      <div className="input-filed ">
                        <span className="basic-text ">
                          {t("Identity Upload")}
                        </span>
                        {!informationEdit ? (
                          <>
                            {Util.isValidData(identityFileName) ? (
                              <div className="d-flex align-items-center">
                                <img
                                  src={fileIcon}
                                  className="img-responsive"
                                  style={{
                                    background: "#F3F4F6",
                                    padding: "5px",
                                    margin: "3px",
                                    borderRadius: "8px",
                                    marginLeft: 0,
                                  }}
                                ></img>
                                <a
                                  target="_blank"
                                  className="large-bold-text mx-1"
                                >
                                  {identityFileName}
                                </a>
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </>
                        ) : (
                          <>
                            <CustomFileUpload
                              onChange={handleInsuranceUpload}
                              fileName={
                                identityUpload
                                  ? identityUpload.name
                                  : identityFileName
                              }
                              showFile={
                                identityURL.length > 0
                                  ? identityURL
                                  : userIdentity + identityFileName
                              }
                              onDragOver={handleInsuranceDragOver}
                              onDrop={handleInsuranceDrop}
                              alertMessage={
                                identityFileName.length > 0 &&
                                t("(incase you select new file it will replace old)")
                              }
                              className={"custom-file"}
                            />
                          </>
                        )}
                      </div>
                    </div>

                    {informationEdit && (
                      <div className="text-end mt-2">
                        <button
                          className="secondary-lg-btn btn rounded-btn mr-2 m-0 px-2"
                          onClick={() => {
                            // resetPersonalDetails();
                            setInformationAcc(false);
                            setInformationEdit(false);
                            
                          }}
                        >
                          {t("Cancel")}
                        </button>

                        <button
                          className="primary-lg-btn btn rounded-btn"
                          onClick={handleSaveInformation}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default ToastHOC(MyProfileStaff);
